import { Directive, HostListener } from '@angular/core';
import { AppConstant } from '../constants/app.constants';

@Directive({
  selector: '[appBlockCopyPaste]'
})
export class BlockCopyPasteDirective {

  constructor() { }

  @HostListener(AppConstant.PASTE, ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener(AppConstant.COPY, ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener(AppConstant.CUT, ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }

}
