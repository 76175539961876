<div class="section-container custom_container" id="master-team">
    <div>
        <h1 class="section-title">{{'premier_team' | translate}}</h1>
    </div>

    <div class="team-card">
        <div *ngFor="let member of team">
            <div class="team-info">
                <img mat-card-image src="{{member.photo}}" alt="{{member.name}}">
                <div class="member-info">
                    <div class="member-info--name">
                        <h3>{{member.name}}</h3>
                        <span>{{member.designation}}</span>
                    </div>
                    <a href="{{member.linkedin}}" target="_blank">
                        <img src="../../../../../assets/images/landing-page/team/linkedin.svg" alt="linkedin" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>