<div id="payment-info" class='md-padding' layout="row" flex>
    <div layout="row" layout-xs="column" class="container" flex>
        <form name="createPaymentInformationForm" [formGroup]="createPaymentInformationForm" class="form-content">
            <h4>{{'how_would_you_like_to_pay_this_employee'|translate}}<span class="mandatory">*</span></h4>
            <mat-divider></mat-divider>
            <mat-radio-group aria-label="{{'select_an_option'|translate}}" formControlName="paymentMode">
                <div class="payment">
                    <mat-icon>payment</mat-icon>
                    <div>
                        {{'direct_deposit'|translate}}
                    </div>
                    <mat-radio-button value="Direct Deposit" class="checkCircle"></mat-radio-button>
                </div>
                <mat-divider></mat-divider>
                <div class="payment">
                    <mat-icon>swap_horizontal_circle</mat-icon>
                    <div>
                        {{'bank_transfer'|translate}}
                    </div>
                    <mat-radio-button value="Bank Transfer" class="checkCircle"></mat-radio-button>
                </div>
            </mat-radio-group>
            <mat-divider></mat-divider>
            <app-loader></app-loader>
            <button matStepperPrevious class="back-button">{{ 'back' | translate }}</button>
            <button class="button-right" type="submit" (click)="addPaymentInformation()">
                {{'submit'|translate}}
            </button>
            <button class="button-right cancel-btn" (click)="backToHome()">{{'cancel'|translate}}</button>
        </form>
    </div>

</div>