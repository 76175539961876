import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, debounceTime, distinctUntilChanged, of, switchMap } from 'rxjs';
import { AppConstant } from 'src/app/constants/app.constants';
import { LogTime } from 'src/app/model/attendance/attendance/attendance.module';
import { AttendanceService } from 'src/app/services/attendance/attendance.service';
import { CompanyProfileService } from 'src/app/services/company-profile/company-profile.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-log-time',
  templateUrl: './log-time.component.html',
  styleUrls: ['./log-time.component.scss'],
  providers: [DatePipe]
})
export class LogTimeComponent {
  @Input() logDetails: any;
  @Output() logTimeUpdated: EventEmitter<LogTime> = new EventEmitter<LogTime>();
  public createAttendanceForm!: FormGroup;
  filteredIssues: string[] = [];
  issues!: string[];
  showStartEndTimeFields: boolean = false;
  showAddPopup: boolean = true;
  isEditMode: boolean = false;
  showOptions: boolean = false;
  searchControl = new FormControl();
  dropDownResults!: Observable<string[]>;
  searchResults!: Observable<string[]>;
  selectedCategory: string = '';
  employeeId: any;
  numericalDuration: any;
  duration: any;
  timeOptions: string[] = [];
  companyRegistrationDate: any;
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private attendanceService: AttendanceService,
    private dialog: MatDialog,
    private companyProfileService: CompanyProfileService,
    private router: Router
  ) {
    this.initCreateAttendanceForm();
    this.logDetails = data?.logDetails || {};
    this.generateTimeOptions();
  }

  ngOnInit() {
    this.companyDetails();
    if (this.route.snapshot.queryParamMap.get('employeeId') != null) {
      this.employeeId = this.route.snapshot.queryParamMap.get('employeeId');
      this.manager_under_employee_category_list();
    } else {
      this.employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
      this.getCategoryList();
    }

    if (this.data) {
      this.isEditMode = true;
      this.selectedCategory = this.data.logDetails.projectCode.trim();
      this.searchControl.setValue(this.selectedCategory);
      this.createAttendanceForm.patchValue({
        date: new Date(this.data.logDetails.selectedDate),
      });
      if (this.logDetails.startTime && this.logDetails.endTime) {
        this.showStartEndTimeFields = true;
        this.createAttendanceForm.patchValue({
          startTime: this.logDetails.startTime,
          endTime: this.logDetails.endTime,
        });
      }
    }

    this.searchResults = this.searchControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(query => this.searchCategories(query))
    );
  }
  companyDetails(): any {
    this.companyProfileService.getCompanyData(this.companyId).subscribe(
      (data: any) => {
        this.companyRegistrationDate = data.data.companyRegistrationDate
      },
    );
  }
  initCreateAttendanceForm() {
    this.createAttendanceForm = this.fb.group({
      'categoryCode': [''],
      'date': ['', [Validators.required]],
      'startTime': [''],
      'endTime': [''],
      'logHours': ['', [Validators.required, this.validateDuration]],
      'categoryDescription': ['']
    });

    this.createAttendanceForm.get('startTime')?.valueChanges.subscribe((startTime: string) => {
      this.calculateDuration();
    });

    this.createAttendanceForm.get('endTime')?.valueChanges.subscribe((endTime: string) => {
      this.calculateDuration();
    });
  }

  searchCategories(query: string): Observable<string[]> {
    const categories: string[] = this.filteredIssues;
    const filteredCategories = categories.filter(category =>
      category.toLowerCase().includes(query.toLowerCase())
    );
    if (filteredCategories.length > 0) {
      this.showOptions = true;
    }
    return of(filteredCategories.slice(0, 9));
  }

  onOptionClick(category: string) {
    this.selectedCategory = category;
    this.searchControl.setValue(category);
    this.showOptions = false;
  }

  toggleOptions() {
    this.showOptions = !this.showOptions;
  }

  onCategorySearch(event: Event): void {
    const searchQuery = (event.target as HTMLInputElement).value;
    this.searchControl.setValue(searchQuery);
  }

  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumber(event);
  }

  calculateDuration() {
    const startTime = this.createAttendanceForm.get('startTime')?.value;
    const endTime = this.createAttendanceForm.get('endTime')?.value;
    if (startTime && endTime) {
      const start = new Date(`01/01/2000 ${startTime}`);
      const end = new Date(`01/01/2000 ${endTime}`);
      const durationInMilliseconds = end.getTime() - start.getTime();
      const hours = Math.floor(durationInMilliseconds / 3600000);
      const minutes = Math.floor((durationInMilliseconds % 3600000) / 60000);
      const formattedDuration = `${hours} hr ${minutes} m`;
      this.createAttendanceForm.get('logHours')?.setValue(formattedDuration);
    } else {
      this.createAttendanceForm.get('logHours')?.setValue('');
    }
  }

  logTime() {
    if (this.createAttendanceForm.valid) {
      let createLogTimeRequest: LogTime = {
        "categoryCode": this.selectedCategory,
        "date": this.datePipe.transform(this.createAttendanceForm.value.date, 'yyyy-MM-ddTHH:mm:ss.SSSSSSSSS'),
        "startTime": this.createAttendanceForm.value.startTime,
        "endTime": this.createAttendanceForm.value.endTime,
        "logHours": this.duration || this.createAttendanceForm.value.logHours,
        "categoryDescription": this.createAttendanceForm.value.categoryDescription
      };

      const companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
      const employeeId = this.route.snapshot.queryParamMap.get('employeeId') || sessionStorage.getItem(AppConstant.EMPLOYEE_ID);

      this.attendanceService.createLogTime(companyId, employeeId, createLogTimeRequest).subscribe((res: any) => {
        this.createAttendanceForm.clearAsyncValidators();
        if (res.status === 200) {
          this.showAddPopup = false;
          this.dialog.closeAll();
          this.logTimeUpdated.emit(createLogTimeRequest);
          this.attendanceService.callSelectDate();
          this.createAttendanceForm.reset();
        }
      });
    } else {
      this.createAttendanceForm.markAllAsTouched();
    }
  }

  toggleAddPopup(): void {
    this.showAddPopup = false;
  }

  getCategoryList() {
    this.attendanceService.getCategory(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe((data: any) => {
      this.issues = data.map((item: { projectCode: any; }) => item.projectCode);
      this.filteredIssues = [...this.issues];
      this.dropDownResults = of(this.filteredIssues);
    });
  }

  manager_under_employee_category_list() {
    this.attendanceService.getCategory(sessionStorage.getItem(AppConstant.COMPANY_ID), this.employeeId).subscribe((data: any) => {
      this.issues = data.map((item: { projectCode: any; }) => item.projectCode);
      this.filteredIssues = [...this.issues];
      this.dropDownResults = of(this.filteredIssues);
    });
  }

  toggleStartEndTimeFields() {
    this.showStartEndTimeFields = !this.showStartEndTimeFields;
    if (this.showStartEndTimeFields) {
      this.createAttendanceForm.get('logHours')?.disable();
      this.createAttendanceForm.get('startTime')?.setValidators([Validators.required]);
      this.createAttendanceForm.get('endTime')?.setValidators([Validators.required]);
    } else {
      this.createAttendanceForm.get('logHours')?.enable();
    }
  }

  validateDuration(control: FormControl) {
    const hours = control.value;
    if (hours && hours > 12) {
      return { maxHoursExceeded: true };
    }
    return null;
  }

  handleTimeChange() {
    const startTime = this.createAttendanceForm?.get('startTime')?.value;
    const endTime = this.createAttendanceForm?.get('endTime')?.value;

    if (startTime && endTime) {
      const start = this.convertTo24HourFormat(startTime);
      const end = this.convertTo24HourFormat(endTime);

      const durationInMilliseconds = end.getTime() - start.getTime();
      const durationInHours = durationInMilliseconds / (1000 * 60 * 60);

      this.duration = Math.round(durationInHours * 100) / 100;
      this.createAttendanceForm.get('logHours')?.setValue(this.duration);
    } else {
      this.duration = null;
      this.createAttendanceForm.get('logHours')?.setValue('');
    }
  }

  convertTo24HourFormat(time: string): Date {
    const [hourMinute, period] = time.split(' ');
    let [hours, minutes] = hourMinute.split(':').map(Number);

    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    return new Date(2000, 0, 1, hours, minutes);
  }

  generateTimeOptions() {
    const times = [];
    for (let i = 0; i < 24; i++) {
      const hour = i % 12 === 0 ? 12 : i % 12;
      const period = i < 12 ? 'AM' : 'PM';
      times.push(`${hour}:00 ${period}`);
      times.push(`${hour}:30 ${period}`);
    }
    this.timeOptions = times;
  }

  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

}

