
import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { ProjectManagementService } from 'src/app/services/project-management/project-management.service';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-board-popup',
  templateUrl: './board-popup.component.html',
  styleUrls: ['./board-popup.component.scss']
})
export class BoardPopupComponent implements OnInit {
  @ViewChild('select') select: MatSelect | any;
  startDateMin!: Date;
  startDateMax!: Date;
  endDateMin!: Date;
  endDateMax!: Date;
  boardForm!: FormGroup;
  addcollaboratorsForm!: FormGroup;
  projectCodeOptions: any[] = [];
  selectedProjectId: any;
  managerList: any;
  employeeData: any[] = [];
  selectedEmployeeType: string = AppConstant.ACTIVE_EMPLOYEE;
  allSelected: boolean = false;
  role = sessionStorage.getItem(AppConstant.LOGIN_PAGE);
  dashboardId: number | undefined;
  selectedProject: any;
  showPopup = false;
  allSelectedInPopup = false;
  employeeList: any[] = [];
  issueList: any[] = [];
  sprintList: any[] = [];
  projectId!: number;
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  constructor(
    private formBuilder: FormBuilder, private router: Router,
    public dialogRef: MatDialogRef<BoardPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastrService: ToastrService, private datePipe: DatePipe, private employeeService: EmployeeDetailsService,
    private projectService: ProjectManagementService, private employeeDetailsService: EmployeeDetailsService,
  ) {
    this.dashboardId = data ? data.dashboardId : null;
    this.createBoard();
  }

  ngOnInit(): void {
    const currentDate = new Date();
    const startDateMin = new Date(currentDate);
    startDateMin.setFullYear(startDateMin.getFullYear() - 1);
    this.startDateMin = startDateMin;
    this.startDateMax = new Date(currentDate.getFullYear() + 2, currentDate.getMonth(), currentDate.getDate());
    this.endDateMin = currentDate;
    this.endDateMax = new Date(currentDate.getFullYear() + 10, currentDate.getMonth(), currentDate.getDate());
    this.getProjectList();
    this.initManagerList();
    if (this.dashboardId) {
      this.viewDashboardById();
    }
    this.addNewCollaborators();
  }

  addNewCollaborators() {
    this.addcollaboratorsForm = this.formBuilder.group({
      addNewcollaborators: ['']
    })
  }

  createBoard() {
    this.boardForm = this.formBuilder.group({
      projectId: ['', [Validators.required]],
      boardName: ['', [Validators.required, ValidationUtil.noOnlySpacesValidator()]],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required],
      description: ['', Validators.required],
      collaborators: [[], Validators.required],
      boardOwnerEmpId: ['', Validators.required]
    });
  }

  viewDashboardById() {
    this.projectService.getProjectDashboard(this.dashboardId).subscribe((projectDashboardData: any) => {
      this.boardForm.patchValue({
        projectId: projectDashboardData.data.projectId,
        boardName: projectDashboardData.data.boardName,
        startDate: new Date(projectDashboardData.data.startDate),
        endDate: new Date(projectDashboardData.data.endDate),
        description: projectDashboardData.data.description,
        collaborators: projectDashboardData.data.collaborators,
        boardOwnerEmpId: projectDashboardData.data.boardOwnerEmpId
      });
      this.optionClicked();
    });
  }

  getProjectList() {
    this.projectService.getProjectList().subscribe(
      (projectList: any) => {
        if (projectList.status === 200) {
          this.projectCodeOptions = projectList.data;
        }
      }
    );
  }

  initManagerList() {
    this.employeeDetailsService.getManagerList(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((managerList: any) => {
      this.managerList = managerList;
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  selectNewCollaborators() {
    const selectedValues = this.addcollaboratorsForm.get('addNewcollaborators')?.value || [];
    this.allSelectedInPopup = selectedValues.length === this.employeeData.length;
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.boardForm.controls['collaborators'].patchValue(this.employeeData.map(employee => employee.id));
    } else {
      this.boardForm.controls['collaborators'].patchValue([]);
    }
  }

  optionClicked() {
    const selectedCount = this.boardForm.controls['collaborators'].value.length;
    this.allSelected = selectedCount === this.employeeData.length;
  }

  saveBoard() {
    this.prepareBoardRequest().subscribe(
      (boardRequest: any) => {
        if ('id' in boardRequest) {
          this.projectService.updateProjectBoard(boardRequest).subscribe(
            (data: any) => this.handleBoardResponse(data)
          );
        } else {
          this.projectService.addProjectBoard(boardRequest).subscribe(
            (data: any) => this.handleBoardResponse(data)
          );
        }
      }
    );
  }

  prepareBoardRequest(): Observable<any> {
    const boardRequest: any = {
      projectId: this.boardForm.value.projectId,
      boardName: this.boardForm.value.boardName,
      startDate: this.boardForm.value.startDate,
      endDate: this.boardForm.value.endDate,
      description: this.boardForm.value.description,
      collaborators: this.boardForm.value.collaborators,
      boardOwnerEmpId: this.boardForm.value.boardOwnerEmpId
    };

    if (this.dashboardId) {
      boardRequest.id = this.dashboardId;
    }

    return of(boardRequest);
  }

  handleBoardResponse(data: any) {
    if (data.status === 200) {
      this.toastrService.clear();
      this.toastrService.success(data.message);
      this.dialogRef.close();
      if (!this.dashboardId) {
        this.router.navigate([AppConstant.SEPERATOR + AppConstant.TASK_MANAGEMENT_SIDENAV + AppConstant.SEPERATOR + AppConstant.PROJECT_DASHBOARD]);
      }
    } else {
      this.toastrService.clear();
      this.toastrService.error(data.message);
      this.dialogRef.close();
    }
  }

  characterNumberAndSpaceAndHyphenAllowed(event: any) {
    return ValidationUtil.characterNumberAndSpaceAndHyphenAllowed(event);
  }

  onProjectSelect(event: any): void {
    this.selectedProject = event.value;
    this.showProjectDetails();
  }

  showProjectDetails(): void {
    this.projectService.showProjectDetails(this.selectedProject).subscribe((response: any) => {
      if (response.status === 200 && response.data && response.data.length > 0) {
        const projectDetails = response.data[0];
        this.employeeData = projectDetails.resourseList.map((employee: any) => {
          return {
            id: employee.employeeId,
            employeeName: employee.employeeName
          }
        });
        this.patchFormValues(projectDetails);
      }
    });
  }

  patchFormValues(projectDetails: any): void {
    const projectStartDate = new Date(projectDetails.startDate);
    const projectEndDate = new Date(projectDetails.endDate);

    this.startDateMin = projectStartDate;
    this.endDateMax = projectEndDate;
    const collaboratorIds = projectDetails.resourseList.map((resource: any) => resource.id);

    this.boardForm.patchValue({
      boardName: projectDetails.projectName,
      startDate: projectStartDate,
      endDate: projectEndDate,
      boardOwnerEmpId: this.getManagerId(projectDetails.projectManager),
      collaborators: collaboratorIds
    });
  }

  getManagerId(managerName: string): string {
    const manager = this.managerList.find((m: { firstName: any; lastName: any; }) => `${m.firstName} ${m.lastName}` === managerName);
    return manager ? manager.id : '';
  }

  toggleAllSelectionInPopup() {
    if (this.allSelectedInPopup) {
      this.addcollaboratorsForm.get('addNewcollaborators')?.patchValue(this.employeeList.map(employee => employee.id));
    } else {
      this.addcollaboratorsForm.get('addNewcollaborators')?.patchValue([]);
    }
  }

  openAddCollaboratorsPopup(select: MatSelect) {
    this.getEmployeeList();
    select.close();
    this.showPopup = true;
  }

  closePopup() {
    this.showPopup = false;
  }

  saveCollaborators() {
    if (this.addcollaboratorsForm.value.addNewcollaborators) {
      const newCollaborators = {
        projectId: this.selectedProject,
        resourcesList: this.addcollaboratorsForm.value.addNewcollaborators,
      }
      this.projectService.addNewCollaborators(newCollaborators).subscribe((newCollaboratorsdetails: any) => {
        if (newCollaboratorsdetails.status === 200) {
          this.showProjectDetails();
          this.toastrService.clear();
          this.toastrService.success(newCollaboratorsdetails.message);
        } else {
          this.toastrService.clear();
          this.toastrService.error(newCollaboratorsdetails.message);
        }
      })
    }
    this.closePopup();
  }

  getEmployeeList() {
    this.employeeService.getEmployeeList(this.selectedEmployeeType, this.companyId).subscribe(
      (employeeData: any) => {
        if (employeeData.status === 200) {
          this.employeeList = employeeData.data.filter((employee: any) => {
            return !this.employeeData.some((e: any) => e.id === employee.id);
          });
        }
      });
  }

}
