<div class="graph">
    <mat-card>
        <div class="title">
            <b>{{'recently_added_customers'|translate}}</b>
            <div class="select-container">
                <mat-form-field appearance="outline">
                    <mat-select [(ngModel)]="selectedTimeRange" (ngModelChange)="onTimeRangeChange()">
                        <mat-option value="monthly">{{'monthly'|translate}}</mat-option>
                        <mat-option value="yearly">{{'yearly'|translate}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
        </div>
        <apx-chart class="graph" [series]="newCustomer.series" [chart]="newCustomer.chart"
            [dataLabels]="newCustomer.dataLabels" [plotOptions]="newCustomer.plotOptions" [yaxis]="newCustomer.yaxis"
            [xaxis]="newCustomer.xaxis" [title]="newCustomer.title"></apx-chart>
        <div class="view"><a (click)="viewReport()">{{'view'|translate}}</a></div>

    </mat-card>
</div>