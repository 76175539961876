<div class="setting__main_container">
  <div class="setting__heading_container">
    <h3>{{'event_management' | translate}}</h3>
    <div class="setting__header_button_container">
      <button (click)="addEvent()">{{"add_event" | translate}}</button>
    </div>
  </div>

  <ag-grid-angular (gridReady)="onGridReady($event)" style="height: 27rem; width: 100%;" class="ag-theme-alpine"
    [rowData]="eventData" [animateRows]="true" [columnDefs]="eventColumnDef" [defaultColDef]="defaultColDef"
    [enableSorting]="true" [enableFilter]="true" [pagination]="true" [paginationPageSize]="10">
  </ag-grid-angular>
</div>