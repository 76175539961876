import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AttendanceService } from 'src/app/services/attendance/attendance.service';
import { LogTimeComponent } from '../log-time/log-time.component';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { AttendanceApproval, AttendanceData } from 'src/app/model/attendance/attendance/attendance.module';
import { AppConstant } from 'src/app/constants/app.constants';
import { ValidationUtil } from 'src/app/utils/validators.util';
function getDayName(day: any): string {
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  return daysOfWeek[day];
}

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss'],
  providers: [DatePipe]
})

export class AttendanceComponent implements OnInit {
  @Output() timeSelected = new EventEmitter<string>();
  @Output() logTimeUpdated: EventEmitter<void> = new EventEmitter<void>();
  public createAttendanceForm!: FormGroup;
  public selectDateForm!: FormGroup;
  public addCategory!: FormGroup;
  selectedTime: Date = new Date();
  selectedDate!: any;
  columnDefs: any[] = [];
  rowData: any = [];
  rowApiData: any = [] = [];
  filteredIssues: string[] = [];
  dropdownOpen: boolean = false;
  isManager: boolean = true;
  employeeAttendance: boolean = true;
  selectTime!: string;
  employeeName: any;
  employeeId: any;
  month: any;
  year: any;
  name: any;
  attendanceHistory: any;
  attendanceColDef: any;
  attendanceHistoryData: AttendanceData[] | undefined;
  showMore: boolean = false;
  showStartEndTimeFields: boolean = false;
  isProgressBarVisible: boolean = false;
  selectedIssue: string | null = null;
  previousMonth: any;
  previousProgress: any;
  monthData: any;
  issues!: string[];
  logDetails !: any;
  categoryName!: any;
  categoryCode!: any;
  categoryDesription!: any;
  isOtherPopupVisible: boolean = false;
  showTablePopup: boolean = false;
  startDateValue!: Date;
  endDateValue!: Date;
  showLogTimePopup: boolean = false;
  currentLabelName: any;
  priviousLabelName: any;
  secondLastLabelName: any;
  lastThirdLabelName: any;
  logDetailsToEdit: any;
  showSubmmitionHistory: boolean = false;
  isAttendanceHideNavbar: boolean = false;

  staticColumns: any[] = [
    { headerName: 'Project Name', field: 'projectName', width: 150, pinned: 'left' },
    { headerName: 'Project Code', field: 'projectCode', width: 130, pinned: 'left' },
    { headerName: 'Log Hour', field: 'LogHour', width: 90, pinned: 'left' },
  ];
  attendanceTableCol = [
    { field: "id", width: 130, filter: 'agTextColumnFilter' },
    { field: "firstName", width: 200, filter: 'agTextColumnFilter' },
    { field: "lastName", width: 200, filter: 'agTextColumnFilter' },
    { field: "workEmail", width: 300, filter: 'agTextColumnFilter' },
    { field: "fromDate", width: 150, filter: 'agTextColumnFilter' },
    { field: "toDate", width: 150, filter: 'agTextColumnFilter' },
  ];
  monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  tableHead = ['Date', 'User', 'Issue', 'Description', 'Logged', 'Actions'];

  public employeeList: any[] | undefined;
  unselected: any[] | undefined;
  selected: any[] = [];
  submittedAttendanceStatus: any;
  constructor(private router: Router, private datePipe: DatePipe, private route: ActivatedRoute, private fb: FormBuilder, private toastrService: ToastrService, private dialog: MatDialog, private cdr: ChangeDetectorRef, private attendanceService: AttendanceService) {
    this.selectDateForm = new FormGroup({
      startDate: new FormControl(),
      endDate: new FormControl(),
    });
    this.selected = [];
    this.attendanceColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };
  }

  ngOnInit() {
    const sessionValue = sessionStorage.getItem('attendancehideNavBar');
    const isSessionHide = sessionValue === 'true';

    this.route.url.subscribe(() => {
      const currentUrl = this.router.url;
      const isAttendanceUrl =
        currentUrl.startsWith('/approvals/employee-attendance-details/') &&
        this.route.snapshot.queryParams['employeeId'] &&
        this.route.snapshot.queryParams['month'] &&
        this.route.snapshot.queryParams['year'];

      this.isAttendanceHideNavbar = isSessionHide || isAttendanceUrl;
    });
  

    this.getApplyAttendaceHistory();
    if (this.route.snapshot.queryParamMap.get('employeeId') !== null) {
      this.employeeId = this.route.snapshot.queryParamMap.get('employeeId');
      if (this.route.snapshot.queryParamMap.get('month') !== null || this.route.snapshot.queryParamMap.get('year') !== null) {
        this.employeeAttendance = false;
        this.month = this.route.snapshot.queryParamMap.get('month');
        this.year = this.route.snapshot.queryParamMap.get('year');
        this.startDateValue = new Date(this.year, this.month - 1, 1);
        this.endDateValue = new Date(this.year, this.month, 0);
        this.selectDateForm.get('startDate')?.valueChanges.subscribe((startDate) => {
          if (startDate) {
            this.startDateValue = startDate;
          }
        });
        this.selectDateForm.get('endDate')?.valueChanges.subscribe((endDate) => {
          if (endDate) {
            this.endDateValue = endDate;
            this.selectDate();
          }
        });
        this.selectDateAPi(this.startDateValue, this.endDateValue, this.employeeId);
        this.getName();
      }
      else {
        this.getCurrentWeekDates();
        this.selectDateForm.get('startDate')?.valueChanges.subscribe((startDate) => {
          if (startDate) {
            this.startDateValue = startDate;
          }
        });
        this.selectDateForm.get('endDate')?.valueChanges.subscribe((endDate) => {
          if (endDate) {
            this.endDateValue = endDate;
            this.selectDate();
          }
        });
        this.selectDateAPi(this.startDateValue, this.endDateValue, this.employeeId);
        this.getName();
      }
    }
    else {
      this.employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
      this.getCurrentWeekDates();
      this.attendanceService.setSelectDateCallback(() => this.selectDate());
      this.selectDateForm.get('startDate')?.valueChanges.subscribe((startDate) => {
        if (startDate) {
          this.startDateValue = startDate;
        }
      });
      this.selectDateForm.get('endDate')?.valueChanges.subscribe((endDate) => {
        if (endDate) {
          this.endDateValue = endDate;
          this.selectDate();
        }
      });
      this.getName();
    }
    this.getEmployeeList();
    this.monthData = {
      currentMonth: {},
      previousMonth: {},
      secondLastMonth: {},
      lastThirdMonth: {}
    };

  }

  getApplyAttendaceHistory() {
    sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION);
    this.attendanceService.getAttendanceHistory(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID), "employee")
      .subscribe((data: any) => {
        this.attendanceHistoryData = data.data.map((row: {
          employeeBasicDetailsModel: any;
          id: { toString: () => string; };
          firstName: string;
          workEmail: string;
          lastName: string;
          fromDate: string;
          toDate: string;
        }) => {
          const formatDate = (dateString: string) => {
            const date = new Date(dateString);
            return date.toLocaleDateString('en-GB');
          };
          return {
            ...row,
            id: row.employeeBasicDetailsModel.id,
            firstName: row.employeeBasicDetailsModel.firstName,
            lastName: row.employeeBasicDetailsModel.lastName,
            workEmail: row.employeeBasicDetailsModel.workEmail,
            fromDate: formatDate(row.fromDate),
            toDate: formatDate(row.toDate),
          };
        });
        this.attendanceHistory = this.attendanceHistoryData;
      });
  }


  onTimeSet(time: Date) {
    this.selectedTime = time;
  }

  private sortContactOperator(a: any, b: any) {
    return (a.firstName.localeCompare(b.firstName));

  }

  getEmployeeList() {
    this.attendanceService.getEmployeeList(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
      (data: any) => {
        this.employeeList = data.data;
        this.unselected = this.employeeList;
      })
  }

  approveAttendance() {
    if (this.route.snapshot.queryParamMap.get('employeeId') != null) {
      this.employeeId = this.route.snapshot.queryParamMap.get('employeeId');
    }
    else {
      this.employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
    }
    let approveAteeendance: AttendanceApproval = {
      "managerId": sessionStorage.getItem(AppConstant.EMPLOYEE_ID),
      "employeeId": this.employeeId,
      "companyId": sessionStorage.getItem(AppConstant.COMPANY_ID),
    };
    this.attendanceService.approveAteeendance(approveAteeendance).subscribe(
      (data: any) => {
        if (data.status == 200) {
          this.toastrService.success(data.message)
        }
        else {
          this.toastrService.clear();
          this.toastrService.error(data.message);
        }
      }
    )
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  rejectAttendance() {
    if (this.route.snapshot.queryParamMap.get('employeeId') != null) {
      this.employeeId = this.route.snapshot.queryParamMap.get('employeeId');
    }
    else {
      this.employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
    }
    let approveAteeendance: AttendanceApproval = {
      "managerId": sessionStorage.getItem(AppConstant.EMPLOYEE_ID),
      "employeeId": this.employeeId,
      "companyId": sessionStorage.getItem(AppConstant.COMPANY_ID),
    };

    this.attendanceService.rejectAteeendance(approveAteeendance).subscribe(
      (data: any) => {
        if (data.status == 200) {
          this.toastrService.success(data.message)
        }
        else {
          this.toastrService.clear();
          this.toastrService.error(data.message);
        }
      }
    )
  }

  getCurrentWeekDates() {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const firstDayOfWeek = new Date(currentDate);
    firstDayOfWeek.setDate(currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1));
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
    this.startDateValue = firstDayOfWeek;
    this.endDateValue = lastDayOfWeek;
    this.selectDateAPi(this.startDateValue, this.endDateValue, this.employeeId);
  }

  getDaysCalculationWithoutRows() {
    this.rowData = [];
    const dynamicColumns = [];
    const startDate = new Date(this.startDateValue);
    const endDate = new Date(this.endDateValue);
    while (startDate <= endDate) {
      const dayString = startDate.getDate().toString().padStart(2, '0');
      const monthName = this.monthNames[startDate.getMonth()];
      const dayName = getDayName(startDate.getDay());
      dynamicColumns.push({
        headerName: `${dayString} ${monthName} ${dayName}`,
        field: `${dayString} ${monthName} ${dayName}`,
        width: 120,
        cellRenderer: this.customCellRenderer,
      });
      startDate.setDate(startDate.getDate() + 1);
    }
    const allColumns = [...this.staticColumns, ...dynamicColumns];
    this.columnDefs = allColumns;
  }

  onCellClicked(event: any) {
    const inputDate = event.colDef.field;
    const parts = inputDate.split(' ');
    const day = parts[0];
    const month = parts[1];
    const year = new Date().getFullYear();
    const fullDate = `${year} ${month} ${day}`;
    this.selectedDate = this.datePipe.transform(fullDate, 'yyyy-MM-dd');
    if (event.colDef.field) {
      const cellValue = event.data[event.colDef.field];
      if (cellValue === null || cellValue === '') {
        this.openLogTimePopup(this.selectedDate, event);
      }
      else {
        if (this.route.snapshot.queryParamMap.get('employeeId') != null) {
          this.employeeId = this.route.snapshot.queryParamMap.get('employeeId');
          this.getCategory(event.data.projectCode);
        }
        else {
          this.employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
          this.getCategory(event.data.projectCode);
        }
        this.showTablePopup = true;
      }
    }
  }

  openLogTimePopup(selectedDate: any, event: any) {
    const dto = {
      selectedDate: selectedDate,
      projectCode: event.data.projectCode,
    }
    this.dialog.open(LogTimeComponent, {
      data: {
        logDetails: dto,
        isOtherPopupVisible: this.isOtherPopupVisible,
      },
    });
  }

  getName() {
    this.attendanceService.getEmployeeDetails(sessionStorage.getItem(AppConstant.COMPANY_ID), this.employeeId).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.isManager = data.data.employeeBasicDetail.manager;
          this.employeeName = data.data.employeeBasicDetail.firstName + " " + data.data.employeeBasicDetail.lastName;
          this.name = data.data.employeeBasicDetail.firstName + " " + data.data.employeeBasicDetail.lastName;
        }
      },)
    const name = this.employeeName;
    if (name) {
      const [firstName, lastName] = name.split(' ');
      const formattedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
      const formattedLastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);
      this.name = `${formattedFirstName} ${formattedLastName}`;
    }
  }

  selectDate() {
    const formattedStartDate = this.datePipe.transform(this.startDateValue, 'yyyy-MM-ddTHH:mm:ss.SSSSSSSSS');
    const formattedEndDate = this.datePipe.transform(this.endDateValue, 'yyyy-MM-ddTHH:mm:ss.SSSSSSSSS');
    this.selectDateAPi(formattedStartDate, formattedEndDate, this.employeeId);
  }

  selectDateAPi(startDate: any, endDate: any, employeeId: any) {
    const formattedStartDate = this.datePipe.transform(startDate, 'yyyy-MM-ddTHH:mm:ss.SSSSSSSSS');
    const formattedEndDate = this.datePipe.transform(endDate, 'yyyy-MM-ddTHH:mm:ss.SSSSSSSSS');
    this.attendanceService.getTimeSheetByRange(sessionStorage.getItem(AppConstant.COMPANY_ID), employeeId, formattedStartDate, formattedEndDate).subscribe(
      (data: any) => {
        if (data.data === null || data.data === " " || data.data.length === 0) {
          this.getDaysCalculationWithoutRows();
        }
        else {
          this.rowApiData = data.data;
          this.getDaysCalculation();
        }
      },
    );
  }

  getInitials(name: string): any {
    if (name) {
      const nameArray = name.split(' ');
      if (nameArray.length >= 2) {
        return nameArray[0].charAt(0).toUpperCase() + nameArray[1].charAt(0).toUpperCase();
      } else if (nameArray.length === 1) {
        return nameArray[0].charAt(0).toUpperCase();
      } else {
        return '';
      }
    }
  }

  toggleAddPopup(): void {
    const dialogRef = this.dialog.open(LogTimeComponent, {
    });
  }

  customCellRenderer(params: any) {
    const cellValue = params.data[params.colDef.field];
    return `<div>${cellValue}</div>`;
  }

  toggleStartEndTimeFields() {
    this.showStartEndTimeFields = !this.showStartEndTimeFields;
    if (this.showStartEndTimeFields) {
      this.createAttendanceForm.get('logHours')?.disable();
    } else {
      this.createAttendanceForm.get('logHours')?.enable();
    }
  }


  getCategory(projectCode: any) {
    this.attendanceService.getCategoryData(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID), this.selectedDate, projectCode).subscribe(
      (data: any) => {
        this.logDetails = data.data;
        this.formatDates();
      });
  }

  formatDates() {
    const datePipe = new DatePipe('en-US');
    this.logDetails.date = datePipe.transform(this.logDetails.date, 'yyyy-MM-dd');
  }


  closePopup() {
    this.showTablePopup = !this.showTablePopup;
  }

  deleteAttendanceRecord(date: string) {
    this.attendanceService.deleteAttendanceRecord(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID), date).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.selectDate();
          this.closePopup();
        }
      })
  }

  editLogDetails(logDetails: any) {
    this.logDetailsToEdit = logDetails;
    this.showLogTimePopup = true;
    this.closePopup();
  }

  closeLogTimePopup() {
    this.showLogTimePopup = false;
  }

  isProgressBarIncomplete(): boolean {
    return false;
  }

  submmitionHistoryToggle() {
    this.showSubmmitionHistory = !this.showSubmmitionHistory;
  }

  submitLastPeriod() {
    const monthlyAttendanceDto = {
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      employeeId: sessionStorage.getItem(AppConstant.EMPLOYEE_ID),
      fromDate: this.datePipe.transform(this.startDateValue, 'yyyy-MM-ddTHH:mm:ss.SSSSSSSSS'),
      toDate: this.datePipe.transform(this.endDateValue, 'yyyy-MM-ddTHH:mm:ss.SSSSSSSSS'),
    }
    this.attendanceService.submitAttendanceApi(monthlyAttendanceDto).subscribe(
      (res: any) => {
        if (res.status === 404) {
          this.toastrService.clear()
          return this.toastrService.error(res.message);
        }
        this.toastrService.clear()
        return this.toastrService.success(res.message);
      }
    )
  }

  getDaysCalculation() {
    let rowData = [];
    const startDate = new Date(this.startDateValue);
    const endDate = new Date(this.endDateValue);
    for (const data of this.rowApiData) {
      const dynamicColumns = [];
      const row: any = {};
      row.projectName = data.projectName;
      row.projectCode = data.projectCode;
      row.LogHour = data.totalLogHours;
      const currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        const dayString = currentDate.getDate().toString().padStart(2, '0');
        const monthName = this.monthNames[currentDate.getMonth()];
        const dayName = this.getDayOfWeek(currentDate);
        for (const dataItem of data.list) {
          const dataDate = new Date(dataItem.date);
          if (dataDate.getFullYear() === currentDate.getFullYear() && dataDate.getMonth() === currentDate.getMonth() && dataDate.getDate() === currentDate.getDate()) {
            row[`${dayString} ${monthName} ${dayName}`] = dataItem.logHours || '';
            break;
          }
        }
        if (!row[`${dayString} ${monthName} ${dayName}`]) {
          row[`${dayString} ${monthName} ${dayName}`] = '';
        }
        dynamicColumns.push({
          headerName: `${dayString} ${monthName} ${dayName}`,
          field: `${dayString} ${monthName} ${dayName}`,
          width: 120,
          cellRenderer: this.customCellRenderer,
        });
        currentDate.setDate(currentDate.getDate() + 1);
      }
      rowData.push(row);
      const allColumns = [...this.staticColumns, ...dynamicColumns];
      this.columnDefs = allColumns;
    }
    this.rowData = rowData;
  }

  getDayOfWeek(date: Date): string {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return daysOfWeek[date.getDay()];
  }
  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

  goBack() {
    const isAttendanceHideNavbar = sessionStorage.getItem('attendancehideNavBar') === 'true';

    if (isAttendanceHideNavbar) {
      this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.MANAGERS_EMPLOYEE_LIST]);
      sessionStorage.removeItem('attendancehideNavBar');
    } else {
      (sessionStorage.getItem('login') === 'admin')
        ? this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE])
        : this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
    }
  }

}

