import { Component, Renderer2 } from '@angular/core';
import { AppConstant } from 'src/app/constants/app.constants';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SuperAdminDeclationConfigurationServiceService } from 'src/app/services/super-admin-declaration-configuration-service/super-admin-declation-configuration-service.service';
import { AddRegimeSlabPopupComponent } from '../add-regime-slab-popup/add-regime-slab-popup.component';

@Component({
  selector: 'app-regime-slab',
  templateUrl: './regime-slab.component.html',
  styleUrls: ['./regime-slab.component.scss']
})
export class RegimeSlabComponent {
  regimeList: any[] = [];
  defaultColDef = {
    flex: 1,
    sortable: true,
    filter: true,
    resizable: true,
  };

  columnDefs = [
    { headerName: 'Regime Name', field: 'regimeName' },
    { headerName: 'Regime Type', field: 'regimeType' },
    { headerName: 'From Year', field: 'slabFromYear' },
    { headerName: 'To Year', field: 'slabToYear' },
    { headerName: 'Minimun Age', field: 'minimumAge' },
    { headerName: 'Maximum Age', field: 'maximumAge' },
    { headerName: 'Percentage', field: 'percentage' },
    { headerName: 'Minimum Amount', field: 'minimumAmount' },
    { headerName: 'Maximum Amount', field: 'maximumAmount' }
  ];

  constructor(private dialog: MatDialog, private router: Router, private renderer: Renderer2, private superAdminDeclarationConfigurationService: SuperAdminDeclationConfigurationServiceService) { }

  ngOnInit(): void {
    this.getAllSectionList();
  }

  openAddSectionPopup(): void {
    const dialogRef = this.dialog.open(AddRegimeSlabPopupComponent, {
      width: 'auto',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getAllSectionList();
    });
  }

  getAllSectionList() {
    this.superAdminDeclarationConfigurationService.getAllRegimeList().subscribe(
      (data: any) => {
        this.regimeList = data.data;
      })
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  viewCategoryButton(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      if (icon === 'delete') {
        this.renderer.setStyle(iconElement, 'margin-right', '15px');
      }
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };
    const viewIconClickHandler = () => {
      sessionStorage.setItem(AppConstant.SECTION_ID, params.data.id);
      this.router.navigate([`${AppConstant.ADMIN_SIDEBAR + AppConstant.SEPERATOR + AppConstant.TAX_DECLARATION}/${params.data.id}`]);
    };
    createButton('visibility', 'green', viewIconClickHandler);
    return cell;
  };

}
