import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { AppConstant } from 'src/app/constants/app.constants';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private http: HttpClient) { }
  addRole(role: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_ROLES, role).pipe(
      map((res: any) => {
        return res;
      })
    )

  }

  updateRole(role: any): Observable<any> {
    return this.http.put(environment.apiUrl + API_END_POINTS.UPDATE_ROLE, role).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  deleteRole(companyyId: any, roleId: any) {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_ROLE + companyyId + AppConstant.SEPERATOR + roleId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getAllRole(companyId: any) {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_ROLE + companyId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getRoleByEmployeeId(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_ROLE_BY_EMPLOYEE_ID + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  saveAllRoleByEmployeeId(dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.SAVE_ALL_ROLE_BY_EMPLOYEE_ID, dto).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

}
