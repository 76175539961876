import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { CreateLoginRequest } from 'src/app/model/login.model';
import { SubscriptionService } from 'src/app/services/admin-model-services/subscription/subscription.service';
import { CompanyProfileService } from 'src/app/services/company-profile/company-profile.service';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { LoginService } from 'src/app/services/login/loginService';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginForm: FormGroup;
  hide = true;

  submitted: boolean = false;
  formSubmitted: boolean = false;
  companyId: any;
  employeeId: any;
  adminId: any;
  subscriptionId: any;
  emmployeePortalAccess!: boolean;

  constructor(private readonly fb: FormBuilder, private readonly loginService: LoginService, private readonly toastrService: ToastrService, private readonly subscriptionService: SubscriptionService, private readonly router: Router, private readonly loader: LoaderService, private readonly employeeService: EmployeeDetailsService, private readonly companyProfileService: CompanyProfileService) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });
  }

  onLogin() {
    sessionStorage.clear();
    this.submitted = true;
    this.formSubmitted = true;
    const loginEmail = this.loginForm.value.email;
    sessionStorage.setItem('loginEmail', loginEmail);
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }
    let createLoginRequest: CreateLoginRequest = {
      "email": this.loginForm.value.email,
      "password": this.loginForm.value.password,
    };

    this.loginService.login(createLoginRequest).subscribe(
      (res: any) => {
        if (res.status === 200) {
          const token: any = sessionStorage.getItem(AppConstant.TOKEN)
          const tokenData = JSON.parse(atob(token.split('.')[1]));
          this.companyId = res.data.companyId;
          this.employeeId = res.data.employeeId;
          this.adminId = res.data.adminId;
          this.subscriptionId = res.data.subscriptionId;
          this.emmployeePortalAccess = res.data.employeePortalAccess;
          this.companyDetails(res.data.companyId);          

          if (tokenData.roles.includes(AppConstant.ADMIN)) {
            sessionStorage.setItem(AppConstant.COMPANY_ID, res.data.companyId);
            sessionStorage.setItem(AppConstant.ADMIN_ID, res.data.adminId);
            sessionStorage.setItem(AppConstant.SUBSCRIPTION_ID, res.data.subscriptionId);
            sessionStorage.setItem(AppConstant.EMPLOYEE_ID, res.data.employeeId);
            sessionStorage.setItem(AppConstant.ROLE_FOR_SESSION, AppConstant.ADMIN);
            this.getSubscriptionAccess();
            this.toastrService.clear();
            this.toastrService.success(res.message);
            this.router.navigate([AppConstant.MOBILE + AppConstant.SEPERATOR + AppConstant.MOBILE_DASHBOARD]);
          } else {//employee role
            sessionStorage.setItem(AppConstant.COMPANY_ID, res.data.companyId);
            sessionStorage.setItem(AppConstant.EMPLOYEE_ID, res.data.employeeId);
            sessionStorage.setItem(AppConstant.ROLE_FOR_SESSION, AppConstant.EMPLOYEE);
            this.getSubscriptionAccess();

            //getting employee data by companyId and employeeId
            this.employeeService.getEmployeeById(res.data.companyId, res.data.employeeId).subscribe(
              (data: any) => {
                sessionStorage.setItem(AppConstant.MANAGER_ID, data.employeeBasicDetail.managerId);
                if (data.employeeBasicDetail.manager) {
                  sessionStorage.setItem(AppConstant.MANAGER_SIDENAV, AppConstant.EMPLOYEE_ROLE);
                }
                sessionStorage.setItem(AppConstant.ASSIGN_ROLES, data.employeeBasicDetail.roles);
                sessionStorage.setItem(AppConstant.EMPLOYEE_DETAILS, data.employeeBasicDetail);
                this.toastrService.clear();
                this.toastrService.success(res.message);
                this.router.navigate([AppConstant.MOBILE + AppConstant.SEPERATOR + AppConstant.MOBILE_DASHBOARD]);
              });
          }

          this.router.navigate([AppConstant.MOBILE + AppConstant.SEPERATOR + AppConstant.MOBILE_DASHBOARD]);

        } else {
          this.toastrService.clear();
          this.toastrService.error(res.message);
          this.router.navigate([AppConstant.MOBILE]);
        }
      });

  }

  companyDetails(companyId: string): any {
    this.companyProfileService.getCompanyData(companyId).subscribe({
      next: (data: any) => {
        sessionStorage.setItem(AppConstant.COMPANY_REGISTRATION_DATE, data.data.companyRegistrationDate)
        sessionStorage.setItem(AppConstant.LOGIN_COMPANY_NAME, data.data.companyName)
      },
      error: (error: any) => {
      }
    });
  }

  getSubscriptionAccess() {
    this.subscriptionService.getSubscriptionAccess().subscribe(
      (data: any) => {
        sessionStorage.setItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS, JSON.stringify(data.data));
      }
    )
  }
}
