import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private applyResignationSource = new BehaviorSubject<boolean>(false);
  applyResignation$ = this.applyResignationSource.asObservable();

  setApplyResignation(value: boolean) {
    this.applyResignationSource.next(value);
  }
}
