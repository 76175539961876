<div class="dashboard-container">
    <mat-tab-group animationDuration="2000ms" headerPosition="below">

        <mat-tab *ngIf="!adminRole || userRole">
            <ng-template mat-tab-label>
                <mat-icon>calendar_month</mat-icon>
            </ng-template>
            <app-mobile-attendance></app-mobile-attendance>
        </mat-tab>

        <mat-tab *ngIf="adminRole || managerRole">
            <ng-template mat-tab-label>
                <mat-icon>check_circle</mat-icon>
            </ng-template>
            <app-mobile-approval></app-mobile-approval>
        </mat-tab>
    </mat-tab-group>
</div>