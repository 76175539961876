import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppConstant } from 'src/app/constants/app.constants';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Messages } from 'src/app/constants/messages.constant';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionService } from 'src/app/services/admin-model-services/subscription/subscription.service';
import { CompanyProfileService } from 'src/app/services/company-profile/company-profile.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})

export class SideNavComponent {
  @Output() menuItemSelect = new EventEmitter<void>();
  @ViewChild('fileInput') fileInput: ElementRef | undefined;
  @ViewChild('notificationsSidenav') notificationsSidenav!: MatSidenav;
  isEmployee: boolean = false;
  showDefaultMenu: boolean = true;
  myFormGroup: FormGroup;
  currentUserId = AppConstant.ROLE;
  isExpanded: boolean = true;
  isShowing: boolean = false;
  showAddPopup: boolean = false;
  assignManagerPopup: boolean = false;
  isMenuItemSelected: boolean = false;
  employeeName: any;
  designation: any;
  empImageError: string | null = null;
  selectedImage: string = '';
  isExpand: boolean = true;
  showSubmenu: boolean = false;
  isShow: boolean = false;
  showSubSubMenu: boolean = false;
  doj: any;
  badgeValue = 0;
  showNotifications: boolean = false;
  notifications: any[] = [];
  addCategory!: FormGroup;
  showOptions!: boolean;
  isManager: boolean = false;
  payRunSubscription: boolean = false;
  showHomeIcon: boolean = false;
  hideSetting: boolean = true;
  isAvailable: boolean = false;
  isEmployeeAvailable: boolean = false;
  supportRole: boolean = false;
  companyLogo: any;
  companyName: any;
  hrRole: boolean = false;
  accountantRole: boolean = false;
  declarationApprovalWindow: boolean = false;

  constructor(public dialog: MatDialog, private companyProfileService: CompanyProfileService, private subscriptionService: SubscriptionService, private fb: FormBuilder, public employeeService: EmployeeDetailsService, private toastrService: ToastrService, private router: Router, private translate: TranslateService) {
    this.myFormGroup = this.fb.group({
      logo: ['']
    });
  }

  ngOnInit() {
    const currentMonth = new Date().getMonth();
    if (currentMonth === 11) {
      this.declarationApprovalWindow = true;
    }
    const roles = sessionStorage.getItem(AppConstant.ASSIGN_ROLES)
    this.isManager = roles?.includes('Manager') ? true : false;
    this.supportRole = roles?.includes('Support') ? true : false;
    this.hrRole = roles?.includes('HR') ? true : false;
    this.accountantRole = roles?.includes('Accountant') ? true : false;
    const settingSideBar: boolean = sessionStorage.getItem("settingSideBar") === "true";
    this.showDefaultMenu = !this.showDefaultMenu;
    if (settingSideBar) {
      this.hideSetting = false;
      this.showHomeIcon = true;
    } else {
      this.showHomeIcon = false;
      this.hideSetting = true
    }
    this.showDefaultMenu = !settingSideBar
    this.getCompanyLogo();
    this.getSubscriptionAccess();
    this.initRole();
  }

  onMenuItemSelected() {
    this.isMenuItemSelected = true;
  }

  goToPayRun() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.payRun) {
      this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.PAY_RUN]);
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }

  goToApprisalProcess() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.performanceManagement) {
      this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.UPLOAD_EXCEL]);
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }

  goToAssetManagement() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.assetManagement) {
      this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.ASSET_MANAGEMENT]);
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }

  goToEventManagement() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.assetManagement) {
      this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.ASSET_MANAGEMENT]);
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }

  goToAddtraining() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.trainingManagement) {
      this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_TRAINING]);
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }

  onSelectMenuItem() {
    this.menuItemSelect.emit();
  }

  initRole() {
    if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.EMPLOYEE_ROLE) {
      this.isEmployee = true;
      this.getEmployeeImage();
      this.getEmployeeNotification();
    } else {
      this.isEmployee = false;
      this.getAdminNotification();
    }
  }

  getCompanyLogo() {
    this.companyProfileService.getCompanyData(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        this.companyLogo = 'data:image/png;base64,' + data.data.logo;
        this.companyName = data.data.companyName
      }
    )
  }

  switchLanguage = (event: any) => {
    this.translate.use(event.target.value);
  }

  logout() {
    this.isMenuItemSelected = true;
    sessionStorage.clear();
    this.router.navigateByUrl(AppConstant.LOGIN_PAGE);
  }

  employeeLogout() {
    sessionStorage.clear();
    this.router.navigateByUrl(AppConstant.LOGIN_PAGE);
  }

  onClickFileInputButton(): void {
    if (this.fileInput) {
      this.fileInput.nativeElement.click();
    }
  }

  onChangeFileInput(event: any): void {
    const file = event.target.files[0];
    if (file && this.checkLogoFileType(file)) {
      this.uploadImage(file);
    }
  }

  checkLogoFileType(file: File): boolean {
    const allowedExtensions = Messages.FILE_EXTENSION;
    const ext = file.name.toLowerCase().substring(file.name.lastIndexOf('.'));
    if (allowedExtensions.includes(ext)) {
      if (file.size <= 2 * 1024 * 1024) {
        this.empImageError = null;
        return true;
      } else {
        this.empImageError = Messages.SUPPORTED_FILE_SIZE;
        return false;
      }
    } else {
      this.empImageError = Messages.SUPPORTED_FILE_EXTENSIONS;
      return false;
    }
  }

  getEmployeeImage() {
    if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.HR_ROLE) {
      return;
    }
    this.employeeService.getEmployeeImage(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
      (res: any) => {
        this.selectedImage = 'data:image/png;base64,' + res.image;
        this.employeeName = res.firstName + " " + res.lastName;
        this.designation = res.designationModel.designation;
        this.doj = res.doj;
        sessionStorage.setItem('doj', this.doj);
        sessionStorage.setItem('employeeName', this.employeeName);
      });
  }


  onSettingsIconClick() {
    sessionStorage.setItem("settingSideBar", "true")
    this.showDefaultMenu = !this.showDefaultMenu;
    this.showHomeIcon = !this.showDefaultMenu;
    this.hideSetting = false;
  }

  onHomeIconClick() {
    sessionStorage.setItem("settingSideBar", "false")
    this.showDefaultMenu = !this.showDefaultMenu;
    this.showHomeIcon = !this.showDefaultMenu;
    this.hideSetting = true
    this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.DASHBOARD]);
  }
  getEmployeeNotification() {
    if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.HR_ROLE) {
      return;
    }
    this.employeeService.getEmployeeNotification(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
      (res: any[]) => {
        this.notifications = res;
        this.badgeValue = this.notifications.length;
      });
  }

  getAdminNotification() {
    if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.EMPLOYEE_ROLE) {
      return;
    }
    this.employeeService.getAdminNotification(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.ADMIN_ID)).subscribe(
      (res: any[]) => {
        this.notifications = res;
        this.badgeValue = this.notifications.length;
      });
  }

  uploadImage(file: File): void {
    const formData = new FormData();
    formData.append('image', file);
    this.employeeService.putEmployeeImage(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID), formData).subscribe(
      (response: any) => {
        this.selectedImage = 'data:image/png;base64,' + response.data.image;
        this.getEmployeeImage();
      });
  }

  toggleNotifications() {
    this.showNotifications = this.notifications.length > 0;
    this.notificationsSidenav.toggle();
  }

  closeNotification(notification: any) {
    const index = this.notifications.indexOf(notification);
    if (notification.id === null) {
      this.notifications.splice(index, 1);
      if (this.badgeValue > 0) {
        this.badgeValue--;
      }
      if (this.badgeValue <= 0) {
        this.showNotifications = false;
        this.notificationsSidenav.toggle();
      }
    }
    else if (index !== -1) {
      this.employeeService.deleteNotification(sessionStorage.getItem(AppConstant.COMPANY_ID), notification.id).subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.notifications.splice(index, 1);
            if (this.badgeValue > 0) {
              this.badgeValue--;
            }
            if (this.badgeValue <= 0) {
              this.showNotifications = false;
              this.notificationsSidenav.toggle();
            }
          }
          else {
          }
        });
    }
  }

  closeAllNotification() {
    if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.EMPLOYEE_ROLE) {
      this.employeeService.deleteAllEmployeeNotification(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.notifications = [];
            this.badgeValue = 0;
            this.showNotifications = false;
            this.notificationsSidenav.toggle();
          }
        });
    }
    else if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.HR_ROLE) {
      this.employeeService.deleteAllAdminNotification(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.notifications = [];
            this.badgeValue = 0;
            this.showNotifications = false;
            this.notificationsSidenav.toggle();
          }
        },
        (error: any) => {
        });
    }
  }

  showPopup() {
    this.showAddPopup = true;
  }

  hidePopup() {
    this.showAddPopup = false;
  }

  showAssignManagerPopup() {
    this.assignManagerPopup = true;
  }

  hideAssignManagerPopup() {
    this.assignManagerPopup = false;
  }

  navigateToManagerTab() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.attendance || JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.leaveManagement) {
      if (sessionStorage.getItem(AppConstant.MANAGER_SIDENAV) === AppConstant.EMPLOYEE_ROLE) {
        this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.MANAGERS_EMPLOYEE_LIST]);
      }
      else {
        this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_LEAVE_DETAILS]);
      }
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }

  approveAttendanceButton() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.attendance) {
      sessionStorage.setItem('viewAttendanceButton', 'true');
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_ATTENDANCE]);
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }

  payrun() {
    const subscriptionData = sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS);
    if (subscriptionData) {
      const parsedData = JSON.parse(subscriptionData);
      if (parsedData?.payRun) {
        this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.PAY_RUN]);
      } else {
        this.toastrService.clear();
        this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
      }
    } else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }

  asset() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.assetManagement) {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.ASSET_MANAGEMENT]);
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }

  appraisal() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.performanceManagement) {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_EMPLOYEE_APPRISAL]);
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }

  viewEmpTraining() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.trainingManagement) {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_TRAINING]);
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }

  helpDesk() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.helpDesk) {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.HELPDESK]);
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }



  project() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.projectManagement) {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.PROJECT_MANAGEMENT]);
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }

  goToLeaveHistory() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.leaveManagement) {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.LEAVE_HISTORY]);
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }

  itDeclaration() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.taxDeclaration) {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.IT_DECLARATION + AppConstant.SEPERATOR + AppConstant.DECLARED_FORM]);
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }

  navigate(menuName: string) {
    this.showSubmenu = !this.showSubmenu;
    this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.getMenuName(menuName)]);
  }

  getSubscriptionAccess() {
    this.subscriptionService.getSubscriptionAccess().subscribe(
      (data: any) => {
        sessionStorage.setItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS, JSON.stringify(data.data));
      }
    )
  }

  setting() {
    this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.VIEW_ALL_DEPARTMENT]);
  }

  hrmsSupport() {
    this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.SUPPORT]);
  }

  employeeSideTaskManagement() {
    return this.router.navigate([AppConstant.TASK_MANAGEMENT_SIDENAV + AppConstant.SEPERATOR + AppConstant.LANDING_PAGE]);
  }
}