import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { AssetManagementDashboardService } from 'src/app/services/asset-management/asset-management-dashboard/asset-management-dashboard.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-order-for-asset-popup',
  templateUrl: './order-for-asset-popup.component.html',
  styleUrls: ['./order-for-asset-popup.component.scss']
})
export class OrderForAssetPopupComponent implements OnInit {
  requestAssetForm !: FormGroup;
  isLaptop: Boolean = false;
  supplierDataList: any[] = [];
  requestAssetsList: string[] = [];
  ramList: string[] = ['2', '4', '8', '16', '32', '64', '128', '256', '512'];
  ssdList: string[] = ['128GB', '256GB', '512GB', '1TB', '2TB', '4TB'];
  processorList: string[] = ['i3', 'i5', 'i7', 'i9', 'Ryzen 3', 'Ryzen 5', 'Ryzen 7', 'Ryzen 9', 'Threadripper'];
  modelName: string[] = ['HP', 'Dell', 'Lenovo', 'Acer', 'Apple', 'Asus',];

  constructor(private router: Router, private loaderService: LoaderService, private matDialog: MatDialog, private toasterService: ToastrService, private formBuilder: FormBuilder, private assetManagemanetService: AssetManagementDashboardService) { }

  ngOnInit(): void {
    this.requestAssetForm = this.formBuilder.group({
      supplierName: ['', [Validators.required]],
      assetType: ['', [Validators.required]],
      modelName: ['', [Validators.required]],
      quantity: ['', [Validators.required, Validators.maxLength(10), ValidationUtil.quantityGreaterThanZero()]],
      contactNumber: ['', [Validators.required]],
      specification: ['',],
      ram: ['',],
      ssd: ['',],
      processor: ['',],
    });

    this.requestAssetForm.get('assetType')?.valueChanges.subscribe
      ((value: any) => {
        if (value === 'Laptop') {
          this.isLaptop = true;
        }
        else {
          this.isLaptop = false;
        }
      });

    this.getSupplierList();
    this.getSupplierAssetTypeList();
  }

  getSupplierAssetTypeList() {
    this.assetManagemanetService.getSupplierAssetTypeList().subscribe(
      (data: any) => {
        if (data.status) {
          this.requestAssetsList = data.data;
        }
      }
    );
  }
  goBack() {
    this.matDialog.closeAll();
  }
  closeDialog() {
    this.matDialog.closeAll();
  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  requestAsset() {
    this.loaderService.show();
    if (this.requestAssetForm.valid) {
      const dto = {
        companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
        supplierId: this.requestAssetForm.value.supplierName,
        assetType: this.requestAssetForm.value.assetType,
        modelName: this.requestAssetForm.value.modelName,
        emailId: this.requestAssetForm.value.email,
        quantity: this.requestAssetForm.value.quantity,
        contactNo: this.requestAssetForm.value.contactNumber,
        specification: this.requestAssetForm.value.specification,
        ram: this.requestAssetForm.value.ram,
        ssd: this.requestAssetForm.value.ssd,
        processor: this.requestAssetForm.value.processor,
      };
      this.assetManagemanetService.requestAssetSupplier(dto).subscribe(
        (data: any) => {
          if (data.status == 200) {
            this.loaderService.hide();
            this.toasterService.clear();
            this.toasterService.success(data.message);
            this.closeDialog();
          }
          else {
            this.loaderService.hide();
            this.toasterService.clear()
            this.toasterService.error(data.message);
          }
        });
    }
  }

  getSupplierList() {
    this.assetManagemanetService.getSupplierList().subscribe(
      (data: any) => {
        if (data.status) {
          this.supplierDataList = data.data;
        }
      });
  }

  validateNumberAndSpaceAndHyphenAllowed(event: any) {
    return ValidationUtil.characterNumberAndSpaceAndHyphenAllowed(event);
  }

}
