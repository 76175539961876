import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ButtonStateService {
  private buttonStateSubject = new BehaviorSubject<boolean>(true);
  buttonState$ = this.buttonStateSubject.asObservable();

  constructor() {}

  updateButtonState(newState: boolean) {
    this.buttonStateSubject.next(newState);
  }
}
