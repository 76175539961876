import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { BoardPopupComponent } from 'src/app/shared/board-popup/board-popup.component';
import { ProjectManagementService } from 'src/app/services/project-management/project-management.service';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationPopupComponent } from 'src/app/shared/confirmation-popup/confirmation-popup.component';

@Component({
  selector: 'app-view-project-dashboard',
  templateUrl: './view-project-dashboard.component.html',
  styleUrls: ['./view-project-dashboard.component.scss']
})

export class ViewProjectDashboardComponent implements OnInit {
  projectColDef: any;
  role = sessionStorage.getItem(AppConstant.LOGIN_PAGE);
  isManagerLogin = sessionStorage.getItem(AppConstant.ASSIGN_ROLES);
  projectDashboardList: any[] = [];
  isManager: boolean = false;
  constructor(private router: Router, private dialog: MatDialog, private projectService: ProjectManagementService, private toastrService: ToastrService) { }

  ngOnInit(): void {
    if (this.isManagerLogin == AppConstant.ISMANAGER) {
      this.isManager = true;
    }
    this.projectColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.getAllDashboardList();
  }

  createProjectDashboard() {
    const dialogRef = this.dialog.open(BoardPopupComponent, {
      disableClose: true,
    });
    this.getAllDashboardList();
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  projectTableCol = [
    { field: "boardName", width: 40, floatingFilter: true, unSortIcon: true },
    { headerName: 'Owner Name', field: "boardOwnerName", floatingFilter: true, unSortIcon: true, width: 30 },
    { headerName: 'Board Start Date', field: "startDate", floatingFilter: true, unSortIcon: true, width: 30, cellRenderer: ValidationUtil.dateFormatter },
    { headerName: 'Board End Date', field: "endDate", floatingFilter: true, unSortIcon: true, width: 30, cellRenderer: ValidationUtil.dateFormatter },
    { headerName: 'Status', field: "status", floatingFilter: true, unSortIcon: true, width: 30 },
    {
      headerName: 'Collaborators',
      field: "collaborators",
      cellRendererFramework: CollaboratorsRendererComponent,
      floatingFilter: true,
      unSortIcon: true,
      width: 30
    },
    {
      field: "Action",
      cellRenderer: this.actionCellRenderer.bind(this),
      width: 115
    }
  ];

  editProjectDashboard(dashboardId: number) {
    const dialogRef = this.dialog.open(BoardPopupComponent, {
      data: { dashboardId: dashboardId }
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  endProject(dashboardId: number) {
  }

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');
    cell.classList.add('action-cell');
    const createButton = (label: string, color: string, tooltip: string, clickHandler: (event: Event) => void) => {
      const button = document.createElement('button');
      button.addEventListener('click', clickHandler);
      button.style.backgroundColor = color;
      button.style.color = 'white';
      button.style.border = 'none';
      button.style.width = 'auto';
      button.style.height = 'auto';
      button.style.borderRadius = '5px';
      button.style.marginRight = '10px';
      button.textContent = label;
      button.title = tooltip;
      cell.appendChild(button);
    };

    const endProjectClickHandler = (event: Event) => {
      event.stopPropagation();
      this.confirmDelete(params.data);
    };

    createButton('View Sprint', '#34abeb', 'View Sprint', () => this.viewSprint(params.data.dashboardId));
    createButton('Edit Board', '#eb9834', 'Edit Board', () => this.editProjectDashboard(params.data.dashboardId));
    createButton('Delete Board', '#eb3434', 'Delete Board', endProjectClickHandler);
    return cell;
  }

  confirmDelete(rowData: any) {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      disableClose: true,
      data: AppConstant.DELETE_DASHBOARD_CONFIRMATION_MESSAGE
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.data) {
        this.dashboardData(rowData);
      }
    });
  }

  dashboardData(rowData: any) {
    this.projectService.deleteProjectDashbaord(rowData.dashboardId).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.toastrService.clear();
          this.toastrService.success(data.message);
          this.getAllDashboardList();
        } else {
          this.toastrService.clear();
          this.toastrService.error(data.message);
        }
      }
    );
  }


  viewSprint(dashboardId: number) {
    return this.router.navigate([`${AppConstant.TASK_MANAGEMENT_SIDENAV}${AppConstant.SEPERATOR}${AppConstant.VIEW_SPRINT}${AppConstant.SEPERATOR}${dashboardId}`]);
  }

  getAllDashboardList() {
    this.projectService.getAllProjectDashboard().subscribe((dashboardList: any) => {
      this.projectDashboardList = dashboardList.data;
    });
  }

  goToDashboard() {
    this.role == AppConstant.ADMIN ?
      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.TASK_MANAGEMENT]) :
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.TASK_MANAGEMENT]);
  }
}

@Component({
  selector: 'app-collaborators-renderer',
  template: `
    <div *ngFor="let collaborator of params.value; let i = index" [ngStyle]="{'background-color': getColor(i)}" class="circle" matTooltip="{{ collaborator.employeeName }}">
      {{ getInitials(collaborator.employeeName) }}
    </div>
  `,
  styles: [`
    .circle {
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      color: black;
      text-align: center;
      line-height: 24px;
      margin-right: 4px;
    }
  `]
})
export class CollaboratorsRendererComponent implements ICellRendererAngularComp {
  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  getInitials(name: string): string {
    const names = name.split(' ');
    const initials = names.map(n => n[0]).join('');
    return initials;
  }

  getColor(index: number): string {
    const colors = ['yellow', 'orange', 'purple', 'gray'];
    return colors[index % colors.length];
  }
}