<div class="title">
    <mat-form-field appearance="outline">
        <mat-select [(ngModel)]="selectedTimeRange" (ngModelChange)="montlyYearlyBasis()">
            <mat-option value="monthly">{{'monthly'|translate}}</mat-option>
            <mat-option value="yearly">{{'yearly'|translate}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div *ngIf="showPayment" class="paymentTable">
    <ag-grid-angular (gridReady)="onGridReady($event)" style="height: 27rem; width: 100%;" class="ag-theme-alpine"
        [rowData]="paymentData" [rowSelection]="'multiple'" [animateRows]="true" [columnDefs]="paymentTableCol"
        [defaultColDef]="paymentColDef" [pagination]="true" [paginationPageSize]="10">
    </ag-grid-angular>
</div>

<div *ngIf="showProfitLoss" class="paymentTable">
    <ag-grid-angular (gridReady)="onGridReady($event)" style="height: 27rem; width: 100%;" class="ag-theme-alpine"
        [rowData]="profitLossData" [rowSelection]="'multiple'" [animateRows]="true" [columnDefs]="profitLossTableCol"
        [defaultColDef]="profitLossColDef" [pagination]="true" [paginationPageSize]="10">
    </ag-grid-angular>
</div>

<div *ngIf="showNewUser" class="paymentTable">
    <ag-grid-angular (gridReady)="onGridReady($event)" style="height: 32rem;width: 100%;" class="ag-theme-alpine"
        [rowData]="newUserData" [rowSelection]="'multiple'" [animateRows]="true" [columnDefs]="newUserTableCol"
        [defaultColDef]="newUserColDef" [pagination]="true" [paginationPageSize]="10">
    </ag-grid-angular>
</div>