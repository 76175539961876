import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Subject, debounceTime, delay, filter, map, takeUntil, tap } from 'rxjs';
import { AppConstant } from 'src/app/constants/app.constants';
import { HelpDeskService } from 'src/app/services/help-desk/help-desk.service';
import { Employees } from '../../admin/add-approver/add-approver.component';
import { ApprovalService } from 'src/app/services/approval/approval.service';

@Component({
  selector: 'app-view-service-request',
  templateUrl: './view-service-request.component.html',
  styleUrls: ['./view-service-request.component.scss']
})
export class ViewServiceRequestComponent implements OnInit {
  viewRequest!: FormGroup;
  search = false;
  employeeList: Employees[] = [];
  filterEmployeeList: Employees[] = [];
  personSearchFilter: FormControl<any> = new FormControl<any>('');
  filteredServerSide: ReplaySubject<Employees[]> = new ReplaySubject<Employees[]>(1);
  _onDestroy = new Subject<string>();
  searching: boolean = false; requestId: any;
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
  resolutionView: boolean = false;
  showIncidence: boolean = false;
  data: any;

  requestStatus: string[] = [
    'Pending',
    'Inprogress',
    'Done',
  ];

  priority: string[] = [
    'High',
    'Medium',
    'Low',
  ];

  constructor(private approvalService: ApprovalService, private toasterService: ToastrService, private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private helpDeskService: HelpDeskService) {
    this.route.params.subscribe(params => {
      this.requestId = params['id'];
      this.getRequestDetails(this.requestId);
    });

    this.route.queryParamMap.subscribe(params => {
      const type = params.get('type');
      if (type === 'resolution') {
        this.resolutionView = true;
        this.initService();
      } else {
        this.initDisableService();
      }
    });
  }

  ngOnInit(): void {
    this.personSearchFilter.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(2000),
        map(search => {
          this.search = search;
          if (!this.employeeList) {
            return [];
          }
          return this.employeeList.filter(employee => employee.employeeName.toLowerCase().indexOf(search) > -1);
        }),
        delay(500),
        takeUntil(this._onDestroy)
      )
      .subscribe(filteredEmployees => {
        if (filteredEmployees.length != 0) {
          this.searching = false;
          this.filteredServerSide.next(filteredEmployees);
        } else {
          this.approvalService.searchEmployee(sessionStorage.getItem(AppConstant.COMPANY_ID), this.search).subscribe((res: any) => {
            if (res.status === 200 && res.data && res.data.length > 0) {
              this.searching = false;
              this.filteredServerSide.next(res.data);
            } else {
              this.searching = false;
            }
          })
        }

      },
        error => {
          this.searching = false;
        });
  }

  updateStatus() {
    const dto = {
      companyId: this.companyId,
      requestId: this.requestId,
      reportFor: sessionStorage.getItem(AppConstant.EMPLOYEE_ID),
      assignTo: this.viewRequest.value.assignTo === null ? this.data.employeeId : this.viewRequest.value.assignTo.substring(3),
      priority: this.viewRequest.value.priority,
      status: this.viewRequest.value.status,
      comments: null
    }
    this.helpDeskService.updateStatus(dto).subscribe(
      (data: any) => {
        this.toasterService.clear();
        if (data.status === 200) {
          this.toasterService.success(data.message);
        } else {
          this.toasterService.error(data.message);
        }
      }
    );
  }

  initDisableService() {
    this.viewRequest = this.formBuilder.group({
      number: [{ value: '', disabled: true }],
      openDate: [{ value: '', disabled: true }],
      requestFor: [{ value: '', disabled: true }],
      employeeId: [{ value: '', disabled: true }],
      priority: [{ value: '', disabled: true }],
      status: [{ value: '', disabled: true }],
      type: [{ value: '', disabled: true }],
      contactNumber: [{ value: '', disabled: true }],
      service: [{ value: '', disabled: true }],
      location: [{ value: '', disabled: true }],
      category: [{ value: '', disabled: true }],
      subCategory: [{ value: '', disabled: true }],
      shortDescription: [{ value: '', disabled: true }],
      longDescription: [{ value: '', disabled: true }],
      comment: [{ value: '', disabled: true }],
    });
  }

  initService() {
    this.viewRequest = this.formBuilder.group({
      number: [{ value: '', disabled: this.resolutionView }],
      openDate: [{ value: '', disabled: this.resolutionView }],
      requestFor: [{ value: '', disabled: this.resolutionView }],
      employeeId: [{ value: '', disabled: this.resolutionView }],
      priority: [''],
      status: [''],
      assignTo: [null],
      type: [{ value: '', disabled: this.resolutionView }],
      contactNumber: [{ value: '', disabled: this.resolutionView }],
      service: [{ value: '', disabled: this.resolutionView }],
      location: [{ value: '', disabled: this.resolutionView }],
      category: [{ value: '', disabled: this.resolutionView }],
      subCategory: [{ value: '', disabled: this.resolutionView }],
      shortDescription: [{ value: '', disabled: this.resolutionView }],
      longDescription: [{ value: '', disabled: this.resolutionView }],
      comment: [{ value: '', disabled: this.resolutionView }],
    });
  }

  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  goToBack() {
    if (this.resolutionView) {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.RESOLUTION]);
    } else {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_REQUESTOR_INFORMATION]);
    }
  }

  getRequestDetails(requestId: any) {
    this.helpDeskService.viewRequestBsedOnEmployeeId(requestId).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.data = data.data;
          if (data.data.type === 'Incidence') {
            this.showIncidence = true;
          }
          this.viewRequest.patchValue({
            number: data.data.id,
            openDate: data.data.openDate,
            requestFor: data.data.requestFor,
            employeeId: data.data.employeeId,
            priority: (data.data.priority ?? '').trim(),
            status: (data.data.status ?? '').trim(),
            type: data.data.type,
            contactNumber: data.data.contactNo,
            service: data.data.serviceRequestTypeName,
            location: data.data.location,
            category: data.data.serviceRequestCategoryName,
            subCategory: data.data.serviceRequestSubCategoryName,
            shortDescription: data.data.shortDescription,
            longDescription: data.data.detailedDescription,
            comment: data.data.comment,
          });
        }
      }
    );
  }
}
