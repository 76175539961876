<div class="tab-container">
    <div class="tab-header">
        <h4>{{ selectedTab | translate }}</h4>
        <app-menu-list></app-menu-list>
    </div>
    <mat-tab-group [(selectedIndex)]="selectedTabIndex" class="tab-group-approval">
        <mat-tab label="{{ 'attendance' | translate }}">
            <app-employee-attendance></app-employee-attendance>
        </mat-tab>

        <!-- <mat-tab label="{{ 'leave' | translate }}"></mat-tab> -->
    </mat-tab-group>
</div>