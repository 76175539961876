import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { AppConstant } from 'src/app/constants/app.constants';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminDeclationConfigurationServiceService {

  constructor(private http: HttpClient) { }

  addSection(dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_SECTION, dto).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getAllSectionList() {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_SECTION_LIST).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  deleteSection(sectionId: any): Observable<any> {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_SECTION + sectionId).pipe(
      map((res: any) => {
        return (res);
      }))
  }

  deleteCategoryBySectionIdAndCategoryId(sectionId: any, categoryId: any): Observable<any> {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_CATEGORY + sectionId + AppConstant.SEPERATOR + categoryId).pipe(
      map((res: any) => {
        return (res);
      }))
  }

  addCategory(dto: any) {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_CATEGORY, dto).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getAllCategoryList() {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_CATEGORY_LIST + sessionStorage.getItem(AppConstant.SECTION_ID)).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  addRegime(dto: any) {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_REGIME, dto).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getAllRegimeList() {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_REGIME_LIST).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

}
