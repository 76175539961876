import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { AttendanceService } from 'src/app/services/attendance/attendance.service';

@Component({
  selector: 'app-mobile-attendance',
  templateUrl: './mobile-attendance.component.html',
  styleUrls: ['./mobile-attendance.component.scss']
})
export class MobileAttendanceComponent implements OnInit {

  @ViewChild('video', { static: false }) video!: ElementRef;
  @ViewChild('canvas', { static: false }) canvas!: ElementRef;
  @ViewChild('capturedImage', { static: false }) capturedImage!: ElementRef;

  imageUrl: string | null = null;
  selectedProject: string | null = null;
  selectedShift: string | null = null;
  attendanceRecords: any[] = [];

  constructor(private readonly attendanceService: AttendanceService, private readonly router: Router) { }

  ngOnInit(): void {
    this.getAttendanceHistory();
  }

  getAttendanceHistory() {
    this.attendanceService.getMobileAttendanceHistory().subscribe(
      (attendanceHistoryResponse: any) => {
        if (attendanceHistoryResponse.status === 200) {
          this.attendanceRecords = attendanceHistoryResponse.data.sort(
            (a: any, b: any) => new Date(b.punchInTime).getTime() - new Date(a.punchInTime).getTime()
          );
        }
      }
    );
  }

  punchInOrOut(punchType: string) {
    this.router.navigate([AppConstant.MOBILE + AppConstant.SEPERATOR + AppConstant.SELFIE_CAPTURE], { queryParams: { type: punchType } });
  }
}


