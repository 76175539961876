<div class="tax-declaration">
    <div class="tax_heading_container">
        <span matTooltip="{{'back_to_dashboard' | translate}}" (click)="goToBack()">
            <mat-icon svgIcon="arrow-back-double" class="rewind"></mat-icon>
        </span>
        <div>
            <h3>{{ 'category_list' | translate }}</h3>
        </div>
    </div>
    <div class="buttonDiv">
        <button color="primary" (click)="openAddCategoryPopup()">{{ 'add_category' | translate }}</button>
    </div>
</div>
<div class="section-list-table">
    <ag-grid-angular (gridReady)="onGridReady($event)" style="height: 28rem;  width: 100%;" [rowData]="categoryList"
        class="ag-theme-alpine" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [suppressCellSelection]="true"
        [animateRows]="true">
    </ag-grid-angular>
</div>

<app-loader></app-loader>