export class ColumnConfig {
    public static customerColumns = [
        { field: 'companyName', width: 300, floatingFilter: true, unSortIcon: true },
        { field: 'onboardingDate', width: 300, floatingFilter: true, unSortIcon: true },
        { field: 'adminName', width: 300, floatingFilter: true, unSortIcon: true },
        { field: 'activeSubscription', width: 280, floatingFilter: true, unSortIcon: true }
    ];

    public static paymentColumns = [
        { field: 'month', headerName: 'Month', width: 120, floatingFilter: true, unSortIcon: true },
        { field: 'companyName', width: 210, floatingFilter: true, unSortIcon: true },
        { field: 'adminName', width: 210, floatingFilter: true, unSortIcon: true },
        { field: 'byDate', width: 250, floatingFilter: true, unSortIcon: true },
        { field: 'subscriptionType', width: 200, floatingFilter: true, unSortIcon: true },
        { field: 'expireDate', width: 200, floatingFilter: true, unSortIcon: true },
    ];

    public static profitLossColumns = [
        { field: 'month', headerName: 'Month', width: 130, floatingFilter: true, unSortIcon: true },
        { field: 'companyName', width: 280, floatingFilter: true, unSortIcon: true },
        { field: 'adminName', width: 280, floatingFilter: true, unSortIcon: true },
        { field: 'totalAmount', width: 290, floatingFilter: true, unSortIcon: true },
        { field: 'email', width: 320, floatingFilter: true, unSortIcon: true },
    ];

    public static newUserColumns = [
        { field: 'month', headerName: 'Month', width: 180, floatingFilter: true, unSortIcon: true },
        { field: 'companyName', width: 300, floatingFilter: true, unSortIcon: true },
        { field: 'adminName', width: 300, floatingFilter: true, unSortIcon: true },
        { field: 'email', width: 300, floatingFilter: true, unSortIcon: true },
    ];


    public static newVoucherColumns =[
        { field: 'month', headerName: 'Month', width: 180, floatingFilter: true, unSortIcon: true },
        { field: 'companyName', width: 300, floatingFilter: true, unSortIcon: true },
        { field: 'mobileNo', width: 300, floatingFilter: true, unSortIcon: true },
        { field: 'email', width: 300, floatingFilter: true, unSortIcon: true },
        { field: 'startDate', width: 300, floatingFilter: true, unSortIcon: true },
        { field: 'expiryDate', width: 300, floatingFilter: true, unSortIcon: true },

    ]
}
