import { Component, ElementRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { TraningService } from 'src/app/services/traning/traning.service';
import { FormatUtil } from 'src/app/utils/format.utils';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-add-traning',
  templateUrl: './add-traning.component.html',
  styleUrls: ['./add-traning.component.scss']
})
export class AddTraningComponent {
  selectedFiles: FileList | null = null;
  selectedThumbnail: FileList | null = null;
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  @ViewChild('fileInput')
  fileInput!: ElementRef;
  minDate: Date | undefined;
  maxDate: Date | undefined;
  maxStartDate: Date | any;
  maxEndDate: Date | any;
  @ViewChild('thumbnailInput')
  thumbnailInput!: ElementRef;
  fileNameError: boolean = false;
  addTrainingForm!: FormGroup;
  fileError: any;
  thumbnailNameError: boolean = false;

  constructor(
    private fb: FormBuilder,
    private trainingService: TraningService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit() {
    this.minDate = new Date();
    this.maxDate = new Date();
    this.maxStartDate = new Date();
    this.maxStartDate.setMonth(this.maxStartDate.getMonth() + 1);
    this.maxEndDate = new Date(new Date().getFullYear(), 11, 31);
    this.maxDate.setMonth(this.maxDate.getMonth() + 1);
    this.addTrainingForm = this.fb.group({
      trainingName: ['', [Validators.required, this.trainingNameValidator]],
      trainingType: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      shortDescription: ['', [Validators.required]],
      longDescription: ['', [Validators.required]],
    });

    this.addTrainingForm?.get('startDate')?.valueChanges.subscribe(startDate => {
      this.updateEndDateValidators(startDate);
    });
  }
  
  updateEndDateValidators(startDate: Date) {
    const endDateControl = this.addTrainingForm.get('endDate');
    if (startDate) {
      endDateControl?.setValidators([
        Validators.required,
        Validators.min(startDate.getTime()),
        Validators.max(this.maxEndDate.getTime())
      ]);
    } else {
      endDateControl?.setValidators([Validators.required]);
    }
    endDateControl?.updateValueAndValidity();
  }

  selectFile(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files.item(0);
      this.fileNameError = file ? file.name.length > 20 : false;
      if (!this.fileNameError) {
        this.selectedFiles = input.files;
      }
    }
  }

  selectThumbnail(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files.item(0);
      this.thumbnailNameError = file ? file.name.length > 20 : false;
      if (!this.thumbnailNameError) {
        this.selectedThumbnail = input.files;
      }
    }
  }

  addCourse() {
    if (this.addTrainingForm.invalid) {
      return;
    }

    let startDate: any;
    if (typeof (this.addTrainingForm.get('startDate')?.value) == 'string') {
      startDate = FormatUtil.adjustDateForTimeOffset(new Date(this.addTrainingForm.get('startDate')?.value));
    } else {
      startDate = FormatUtil.adjustDateForTimeOffset(this.addTrainingForm.get('startDate')?.value);
    }

    const formData = new FormData();
    const trainingDto = {
      id: 1,
      companyId: this.companyId,
      trainingName: this.addTrainingForm.value.trainingName,
      trainingType: this.addTrainingForm.value.trainingType,
      trainingShortDescription: this.addTrainingForm.value.shortDescription,
      trainingLongDescription: this.addTrainingForm.value.longDescription,
      startDate: startDate,
      endDate: this.addTrainingForm.value.endDate,
      createdDate: new Date().toISOString(),
      lastUpdateDate: new Date().toISOString(),
      isActive: true
    };

    formData.append('trainingDto', JSON.stringify(trainingDto));

    if (this.selectedFiles) {
      formData.append('file', this.selectedFiles[0]);
    }

    if (this.selectedThumbnail) {
      formData.append('thumbnail', this.selectedThumbnail[0]);
    }

    this.trainingService.addCourse(formData).subscribe((data: any) => {
      if (data.status == 200) {
        this.toastr.success(data.message);
        this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_TRAINING]);
      } else {
        this.toastr.error(data.message);
      }
    });
  }

  assets: string[] = [
    'Compulsory',
    'Optional',
  ];

  cancelTraining() {
    this.addTrainingForm.reset();
    this.selectedFiles = null;
    this.selectedThumbnail = null;
    this.fileNameError = false;
    this.thumbnailNameError = false;
    this.fileInput.nativeElement.value = '';
    this.thumbnailInput.nativeElement.value = '';
    this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR +AppConstant.DASHBOARD])
  }

  trainingNameValidator(control: AbstractControl): ValidationErrors | null {
    const allowedCharacters = /^[a-zA-Z0-9 .:|{}&<>/()]*$/;
    const value = control.value;
    if (value && !allowedCharacters.test(value)) {
      return { invalidCharacters: true };
    }
    return null;
  }

  alphanumeric(event: KeyboardEvent) {
    ValidationUtil.allowedAlphNumericAndSomeSymbols(event);
  }

  goToBack() {
    this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_TRAINING])
  }
}
