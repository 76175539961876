<div class="setting__main_container">
    <div class="setting__heading_container">
        <h3>{{ 'departments' | translate }}</h3>
        <div class="setting__header_button_container">
            <button color="primary" (click)="toggleAddPopup()">{{ 'add-department' | translate
                }}</button>
        </div>
    </div>
    <ag-grid-angular (gridReady)="onGridReady($event)" style="height: 27rem; width: 100%;" class="ag-theme-alpine"
        [rowData]="departmentData" [rowSelection]="'multiple'" [animateRows]="true" [columnDefs]="departmentTableCol"
        [defaultColDef]="departmentColDef" [enableSorting]="true" [enableFilter]="true" [pagination]="true"
        [paginationPageSize]="10">
    </ag-grid-angular>
</div>

<div class="overlay" *ngIf="showAddPopup">
    <div class="add-popup">
        <div class="container">
            <div class="header">
                <h3 class="heading">{{ (addDepartmentFlag ? 'add-department': 'update_department') | translate }}</h3>
            </div>
            <form [formGroup]="departmentForm" id="departmentForm">
                <div>
                    <mat-form-field class="form-field" appearance="outline">
                        <mat-label>{{ 'department_name' | translate }}</mat-label>
                        <input matInput formControlName="departmentName"
                            (keypress)="validateCharacterNumberAndSpace($event)" [maxLength]="50" type="text" />
                        <mat-error *ngIf="!departmentForm.controls['departmentName'].valid">
                            {{ 'department_is_required'| translate }}</mat-error>
                    </mat-form-field>
                </div>
                <app-loader></app-loader>
                <div class="pupup-buttons">
                    <button (click)="toggleAddPopup()" class="closeDialog cancel-btn">
                        {{'cancel' | translate}}</button>
                    <button (click)="isAddMode ? addDepartment() : updateDepartment()">
                        {{ isAddMode ? ('save' | translate) : ('update' | translate) }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>