import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { AppConstant } from 'src/app/constants/app.constants';
import { environment } from 'src/environments/enviroment';

@Injectable({
  providedIn: 'root'
})
export class ToDoTaskServiceService {

  constructor(private http: HttpClient) { }

  getTaskList(companyId: any, adminId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_TO_DO_TASK + companyId + AppConstant.SEPERATOR + adminId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  deleteById(id: any): Observable<any> {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_TO_DO_TASK + id).pipe(
      map((res: any) => {
        return res
      })
    );
  }

  refresh(companyId: any, adminId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.REFRESH_TO_DO_TASK + companyId + AppConstant.SEPERATOR + adminId).pipe(
      map((res: any) => {
        return res
      })
    );
  }

}
