import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { TaxDeclarationServiceService } from 'src/app/services/tax-declaration-service/tax-declaration-service.service';
import { FormatUtil } from 'src/app/utils/format.utils';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-house-rent-details',
  templateUrl: './house-rent-details.component.html',
  styleUrls: ['./house-rent-details.component.scss']
})
export class HouseRentDetailsComponent implements OnInit {
  @Output() houseRentDetailsChange = new EventEmitter<any>();

  formGroup!: FormGroup;

  constructor(private builder: FormBuilder, private stepper: MatStepper, private router: Router, private toastrService: ToastrService, private taxDeclarationSerice: TaxDeclarationServiceService) {
    this.formGroup = this.builder.group({
      'fromDate': ['', Validators.required],
      'toDate': ['', [Validators.required, ValidationUtil.toDateBeforeFromDateValidator('fromDate', 'toDate')]],
      'city': ['', Validators.required],
      'landlordName': ['', Validators.required],
      'landLordPan': ['', [Validators.required, Validators.pattern("^([A-Z]){5}([0-9]){4}([A-Z]){1}$")]],
      'rentedHouseAddress': ['', Validators.required],
      'rentPerMonthDeclared': ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.getHouseRentDetails();

    this.formGroup.get('fromDate')?.valueChanges.subscribe(fromDate => {
      if (fromDate) {
        const endOfFinancialYear = this.getEndOfFinancialYear(new Date(fromDate));
        this.formGroup.get('toDate')?.setValue(endOfFinancialYear);
      }
    });
  }

  getEndOfFinancialYear(date: Date): Date {
    const currentYear = date.getFullYear();
    const endOfFinancialYear = new Date(currentYear + 1, 2, 31);
    return endOfFinancialYear;
  }

  currentYearDateFilter = (date: Date | null): boolean => {
    if (!date) {
      return false;
    }
    const currentYear = new Date().getFullYear();
    const startOfFinancialYear = new Date(currentYear, 3, 1);
    const endOfFinancialYear = new Date(currentYear + 1, 2, 31);
    return date >= startOfFinancialYear && date <= endOfFinancialYear;
  };


  getMaximunDate(): Date {
    const fromDate = this.formGroup.controls['fromDate'].value;
    if (fromDate) {
      const date = new Date(fromDate);
      date.setMonth(date.getMonth() + 1);
      return date;
    }
    return new Date();
  }

  private addOneYear(date: Date): Date {
    const newDate = new Date(date);
    newDate.setFullYear(newDate.getFullYear() + 1);
    return newDate;
  }

  getHouseRentDetails() {
    this.taxDeclarationSerice.getHouseRentDetails(sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.formGroup.patchValue({
            fromDate: data.data.fromDate,
            toDate: data.data.toDate,
            city: data.data.city,
            landlordName: data.data.landlordName,
            landLordPan: data.data.landLordPan,
            rentedHouseAddress: data.data.rentedHouseAddress,
            rentPerMonthDeclared: data.data.rentPerMonthDeclared,
          });
        }
      }
    );
  }

  submitHouseDetails() {
    if (this.formGroup.valid) {
      const fromDate = new Date(this.formGroup.value.fromDate);
      const toDate = new Date(this.formGroup.value.toDate);

      if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
        this.toastrService.error('Invalid date format');
        return;
      }

      const dto = {
        companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
        employeeId: sessionStorage.getItem(AppConstant.EMPLOYEE_ID),
        fromDate: FormatUtil.adjustDateForTimeOffset(fromDate),
        toDate: FormatUtil.adjustDateForTimeOffset(toDate),
        city: this.formGroup.value.city,
        landlordName: this.formGroup.value.landlordName,
        landLordPan: this.formGroup.value.landLordPan,
        rentedHouseAddress: this.formGroup.value.rentedHouseAddress,
        rentPerMonthDeclared: this.formGroup.value.rentPerMonthDeclared,
      };

      this.taxDeclarationSerice.submitHouseDetails(dto).subscribe(
        (data: any) => {
          if (data.status === 200) {
            this.toastrService.clear();
            this.toastrService.success(data.message);
            this.houseRentDetailsChange.emit(dto);
            this.stepper.next();
          } else {
            this.toastrService.clear();
            this.toastrService.error(data.message);
          }
        }
      );
    }
  }

  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

  numberValidation(event: any) {
    return ValidationUtil.validateNumber(event);
  }
}
