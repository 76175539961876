<div class="container">
  <div class="exit-history-container">
    <span><b>{{'exit_history'|translate}}</b></span>
    <mat-icon (click)="closepopup()">close</mat-icon>
  </div>
  <table>
    <tr>
      <th>{{'date_of_joining'|translate}}</th>
      <th>{{'last_working_date'|translate}}</th>
      <th>{{'final_settlement_date'|translate}}</th>
    </tr>
    <tr>
      <td>{{dateOfJoining}}</td>
      <td>{{lastWorkingDate}}</td>
      <td>{{finalSettlementDate}}</td>
    </tr>
  </table>
</div>