<div class="main-container">
  <div class="back-button">
    <mat-icon matTooltip="{{'back_to_dashboard' | translate}}" class="back-arrow-icon" (click)="goToBack()"
      svgIcon="arrow-back-double" class="rewind"></mat-icon>
    <h3 class="headerName">{{ 'project_cost_details' | translate }}</h3>
  </div>
  <mat-divider></mat-divider>
  <div class="project-summary">
    <div *ngFor="let data of costingDetails" class="summary-item">
      <label class="label-name"><strong>{{ convertLabel(data.label) }}</strong> :</label>
      <span *ngIf="data.label === 'projectBudget'" class="value">{{ data.value | number: '1.2-2' }}</span>
      <span *ngIf="data.label !== 'projectBudget'" class="value">{{ data.value }}</span>
    </div>
  </div>
  <div class="costingDetail">
    <form [formGroup]="projectCostingForm">
      <mat-form-field appearance="outline" class="date">
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate formControlName="startDate" (dateChange)="onDateChange()">
          <input matEndDate formControlName="endDate" (dateChange)="onDateChange()">
        </mat-date-range-input>
        <div class="calender">
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </div>
      </mat-form-field>
    </form>

    <table mat-table [dataSource]="resourceCostingDetail">
      <ng-container matColumnDef="resourceName">
        <th mat-header-cell *matHeaderCellDef><strong>{{'resource_name' | translate}}</strong></th>
        <td mat-cell *matCellDef="let element">{{ element.resourceName }}</td>
      </ng-container>
      <ng-container matColumnDef="hourlyRate">
        <th mat-header-cell *matHeaderCellDef><strong>{{'hourly_rate' |translate}}</strong></th>
        <td mat-cell *matCellDef="let element">{{ element.hourlyRate | number:'1.2-2' }}</td>
      </ng-container>
      <ng-container matColumnDef="totalWorkingHours">
        <th mat-header-cell *matHeaderCellDef><strong>{{'total_working_hours'|translate}}</strong></th>
        <td mat-cell *matCellDef="let element">{{ element.totalWorkingHours }}</td>
      </ng-container>
      <ng-container matColumnDef="payableAmount">
        <th mat-header-cell *matHeaderCellDef><strong>{{'payable_amount'|translate}}</strong></th>
        <td mat-cell *matCellDef="let element" class="right-align">{{ element.payableAmount | number:'1.2-2' }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="remaining-budget">
      <span> <strong>{{'remaining_budget' |translate }}</strong></span>
      <span>{{'budget_calculation' |translate }}=</span>
      <span>{{ remainingBudget | number:'1.2-2' }}</span>
    </div>
  </div>
</div>