
<div class="setting__main_container">
    <span matTooltip="{{'back_to_dashboard' | translate}}" (click)="goToDashboard()">
        <mat-icon svgIcon="arrow-back-double" class="rewind"></mat-icon>
    </span>
    <div class="setting__heading_container">
        <h3>{{'resignation_details'|translate}}</h3>
    </div>
</div>

<div class='md-padding' layout="row" flex>
    <div layout="row" layout-xs="column" flex>
        <div class="select-admin-dropdown" *ngIf="admin">
            <mat-form-field appearance="outline" class="resignation-type">
                <mat-label>{{'employee_resignation'|translate}}</mat-label>
                <mat-select [(ngModel)]="selectedEmployeeResignationType"
                    (selectionChange)="getAllEmployeeResignation()">
                    <mat-option
                        value="employeeAppliedResignation">{{'employee_resignation_type'|translate}}</mat-option>
                    <mat-option value="withdrawalResignation">{{'withdraw_by_employee'|translate}}</mat-option>
                    <mat-option value="ManagerApprovedResignation">{{'approved_by_manager'|translate}}</mat-option>
                    <mat-option value="ManagerRejectedResignation">{{'rejected_by_manager'|translate}}</mat-option>

                    <mat-option value="hrApprovedResignation">{{'approved_by_hr'|translate}}</mat-option>
                    <mat-option value="hrRejectedResignation">{{'rejected_by_hr'|translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="select-dropdown" *ngIf="!admin">
            <mat-form-field appearance="outline" class="resignation-type">
                <mat-label>{{'employee_resignation'|translate}}</mat-label>
                <mat-select [(ngModel)]="selectedEmployeeResignationType"
                    (selectionChange)="getAllEmployeeResignation()">
                    <mat-option
                        value="employeeAppliedResignation">{{'employee_resignation_type'|translate}}</mat-option>
                    <mat-option value="withdrawalResignation">{{'withdraw_by_employee'|translate}}</mat-option>
                    <mat-option value="ManagerApprovedResignation">{{'approved'|translate}}</mat-option>
                    <mat-option value="ManagerRejectedResignation">{{'rejected'|translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="employeeList">
            <ag-grid-angular (gridReady)="onGridReady($event)" #agGride style="height: 27rem; width:100%;"
                class="ag-theme-alpine" [rowData]="employeeData" [rowSelection]="'multiple'" [animateRows]="true"
                [columnDefs]="hrTableCol" [defaultColDef]="hrColDef" [enableSorting]="true" [enableFilter]="true"
                [pagination]="true" [paginationPageSize]="10">
            </ag-grid-angular>
        </div>
    </div>
</div>