import { Component } from '@angular/core';
import { LandingPageMasterData } from 'src/app/constants/master-data/landing-page-master-data';

@Component({
  selector: 'app-master-team',
  templateUrl: './master-team.component.html',
  styleUrls: ['./master-team.component.scss']
})
export class MasterTeamComponent {
  team: MasterTeam[] = LandingPageMasterData.MASTER_TEAM_DATA;
}

export interface MasterTeam {
  photo: string;
  name: string;
  designation: string;
  linkedin: string;
}
