import { ChartOptions } from "chart.js";
import { AppConstant } from "../app.constants";
export class MasterData {
public chartOptions: Partial<ChartOptions> | undefined;


public static readonly chartOptions = {
    series: [0, 0, 0],
    chart: {
        type: "donut"
    },
    labels: [AppConstant.PENDING, AppConstant.SUCCESS, AppConstant.FAILURE],
    responsive: [
        {
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: "bottom"
                }
            }
        }
    ]
};

public static readonly subscription = {
    series: [0],
    chart: {
        height: 350,
        type: "radialBar",
        offsetY: -10
    },
    plotOptions: {
        radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
                name: {
                    fontSize: "16px",
                    color: undefined,
                    offsetY: 120
                },
                value: {
                    offsetY: 76,
                    fontSize: "22px",
                    color: undefined,
                    formatter: function (val: any) {
                        return val + "%";
                    }
                }
            }
        }
    },
    fill: {
        type: "gradient",
        gradient: {
            shade: "dark",
            shadeIntensity: 0.15,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 91]
        }
    },
    stroke: {
        dashArray: 4
    },
    labels: [AppConstant.TOTAL_SUBSCRIPTION]
};

public static readonly profitLoss = {
    series: [
        {
            name: "profit",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
    ],
    chart: {
        height: 350,
        type: "line"
    },
    stroke: {
        width: 7,
        curve: "smooth"
    },
    xaxis: {
        type: "datetime",
        categories: [
            "1/11/2023",
            "2/11/2023",
            "3/11/2023",
            "4/11/2023",
            "5/11/2023",
            "6/11/2023",
            "7/11/2023",
            "8/11/2023",
            "9/11/2023",
            "10/11/2023",
            "11/11/2023",
            "12/11/2023",
           
        ]
       
    },
    title: {
        align: "left",
        style: {
            fontSize: "16px",
            color: "#666"
        }
    },
    fill: {
        type: "gradient",
        gradient: {
            shade: "dark",
            gradientToColors: ["#FDD835"],
            shadeIntensity: 1,
            type: "horizontal",
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100]
        }
    },
    markers: {
        size: 4,
        colors: ["#FFA41B"],
        strokeColors: "#fff",
        strokeWidth: 2,
        hover: {
            size: 7
        }
    },
    yaxis: {
        min: -0.1,
        max: 100000,
        title: {
            text: "Profit Loss "
        }
    }
};

}