
import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RefreshTokenComponent } from 'src/app/components/shared/refresh-token/refresh-token.component';
import { AppConstant } from 'src/app/constants/app.constants';
import { TokenService } from '../token/token.service';
import { SessionService } from '../session-service/session.service';
import { RefreshToken } from 'src/app/model/token/token.module';

@Injectable({
  providedIn: 'root'
})
export class ActivityMonitorService {
  private lastActivityTime: number;
  private monitoringInterval: any;
  private refreshTokenInProgress: boolean = false;
  remainingTime: any;

  constructor(
    private ngZone: NgZone,
    private sessionService: SessionService,
    private dialog: MatDialog,
    private tokenRefreshService: TokenService,
    private router: Router,
  ) {
    this.lastActivityTime = Date.now();
    this.initActivityListener();
  }

  private initActivityListener() {
    this.ngZone.runOutsideAngular(() => {
      const events = [AppConstant.MOUSE_MOVE, AppConstant.KEY_DOWN, AppConstant.WHEEL, AppConstant.TOUCHSTART];
      events.forEach(event => {
        document.addEventListener(event, () => {
          this.lastActivityTime = Date.now();
        });
      });
    });

    this.startMonitoring();
  }

  startMonitoring() {
    if (!this.monitoringInterval) {
      this.monitoringInterval = setInterval(() => {
        this.handleUserActivity();
      }, 100000);//token check after 10 sec
    }
  }

  stopMonitoring() {
    if (this.monitoringInterval) {
      clearInterval(this.monitoringInterval);
      this.monitoringInterval = null;
    }
  }

  private handleUserActivity() {
    let token = sessionStorage.getItem(AppConstant.TOKEN);

    if (token != "undefined" && token != null) {
      const idleTime = Date.now() - this.lastActivityTime;
      const idleThreshold = 10 * 60 * 1000;//idle time of 10 min
      this.remainingTime = this.sessionService.getTimeRemainingForTokenExpiry();

      //Show popup before 5 min of token expiration
      if (idleTime >= idleThreshold && this.remainingTime >= 0 && this.remainingTime <= 300 && !this.refreshTokenInProgress) {
        this.showTokenAboutToExpireDialog();
      } else if (idleTime < idleThreshold && this.remainingTime >= 0 && this.remainingTime <= 120 && !this.refreshTokenInProgress) {
        this.refreshToken();//auto refresh in non-idle state before 2 min of token expire
      }
    }
  }

  private refreshToken() {
    this.refreshTokenInProgress = true;

    let token: RefreshToken = {
      "token": sessionStorage.getItem(AppConstant.TOKEN)
    };

    this.tokenRefreshService.refreshToken(token).subscribe(
      (data: any) => {
        this.dialog.closeAll();
        if (data.status == 401) {
          this.stopMonitoring();
          this.router.navigate([AppConstant.LOGIN_PAGE]);
        } else {
          const token = data.headers.get(AppConstant.AUTHORIZATION);
          if (token) {
            sessionStorage.setItem(AppConstant.TOKEN, token);
          }
        }
      },
      error => {
        console.error(error);
      }
    ).add(() => {
      this.refreshTokenInProgress = false;
    });
  }

  private showTokenAboutToExpireDialog(): void {
    const dialogRef = this.dialog.open(RefreshTokenComponent, {
      data: {
        message: AppConstant.SESSION_EXPIRE,
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      window.location.reload();
    })
    this.stopMonitoring();
  }
}

