
<div class="employee-basic-goal">
  <mat-toolbar>
    <mat-icon class="back" (click)="back()">keyboard_arrow_left</mat-icon> <h1>{{ 'basic_information_of_goal' | translate }}</h1>
  </mat-toolbar>
</div>
<div>
  <mat-card class="cardContainer">
    <form [formGroup]="basicGoalForm">
          <table mat-table [dataSource]="dataArray">
        <ng-container matColumnDef="key">
          <th mat-header-cell *matHeaderCellDef> {{ 'fields' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{ element.key | translate }} </td>
        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef> {{ 'value' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{ element.value }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['key', 'value']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['key', 'value'];"></tr>
      </table>
    </form>
  </mat-card>
</div>

