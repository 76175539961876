<h2 mat-dialog-title class="headerName">{{action}} Leave</h2>

<mat-dialog-content>
    <div class="form">
        <div *ngIf="action != 'Delete'; else elseTemplate" class="input_fields_container">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'leave_type' | translate }}</mat-label>
                <input matInput [(ngModel)]="leaveData.leaveType" required
                    (keypress)="characterNumberAndSpaceAllowed($event)" maxlength="50" minlength="5" />
                <mat-error *ngIf="leaveData.leaveType?.length == 0">{{'leave_type_is_required'|translate}}</mat-error>
                <mat-error *ngIf="leaveData.leaveType?.length < 5">{{'leave_type_should_be_min_5_char'|translate}}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'leave_description' | translate }}</mat-label>
                <input matInput [(ngModel)]="leaveData.leaveDescription" required
                    (keypress)="characterNumberAndSpaceAllowed($event)" maxlength="100" minlength="5" />
                    <mat-error *ngIf="leaveData.leaveDescription?.length == 0">{{'leave_description_is_required'|translate}}</mat-error>
                    <mat-error *ngIf="leaveData.leaveDescription?.length < 5">{{'leave_description_should_be_min_5_char'|translate}}</mat-error>                    
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'leave_count' | translate }}</mat-label>
                <input matInput [(ngModel)]="leaveData.leaveCount" required  type="number" max="50" min="1"/>
                <mat-error *ngIf="leaveData.leaveCount == undefined || leaveData.leaveCount == 0">{{'leave_count_is_required'|translate}}</mat-error>
                <mat-error *ngIf="leaveData.leaveCount > 50">{{'max_50_leaves_allowed'|translate}}</mat-error>
            </mat-form-field>
        </div>
        <ng-template #elseTemplate>
            {{'delete_leave_confirmation_message' | translate}} <b>{{leaveData.leaveType}}</b>?
        </ng-template>
    </div>

    <div class="buttons">
        <button class="cancel-btn" type="reset" (click)="closeDialog()">{{'cancel' | translate}}</button>
        <button (click)="addUpdateEvent()">{{'save' | translate}}</button>
    </div>

</mat-dialog-content>
<app-loader></app-loader>