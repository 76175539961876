<div class='md-padding' layout="row" flex>
    <div layout="row" layout-xs="column" flex>
        <div class="training-table">
            <div class="header">
                <span (click)="goToDashboard()">
                    <mat-icon svgIcon="arrow-back-double" class="rewind"></mat-icon>
                </span>
                <h3>{{'training_assign_to the_employee'|translate}}</h3>
            </div>
            <mat-divider class="divider"></mat-divider>
            <ag-grid-angular (gridReady)="onGridReady($event)" #agGride style="height: 29rem; width:100%;"
                class="ag-theme-alpine" [rowData]="trainingData" [rowSelection]="'multiple'" [animateRows]="true"
                [columnDefs]="trainingTableCol" [defaultColDef]="empColDef" [enableSorting]="true" [enableFilter]="true"
                [pagination]="true" [paginationPageSize]="10">
            </ag-grid-angular>
        </div>
    </div>
</div>