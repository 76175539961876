import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { environment } from 'src/environments/enviroment';

@Injectable({
  providedIn: 'root'
})
export class MasterDataService {

  constructor(private http: HttpClient) { }

  getAllCountries(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_COUNTRY_LIST).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  getAllState(countryName: string): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_STATE_LIST + countryName).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }
  getAllCity(stateName: string): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_CITY_LIST + stateName).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }
  allCountries(): Observable<any> {
    return this.http.get(API_END_POINTS.COUNTRY_STATE_CITY);
  }
}
