import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-instructions-dialog-component',
  templateUrl: './instructions-dialog.component.html',
  styleUrls: ['./instructions-dialog.component.scss']
})
export class InstructionsDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<InstructionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
}
