<div class="main-container">
    <h3> {{ 'create_sprint' | translate }} </h3>
    <form [formGroup]="createSprintForm">
        <div class="sprint-content">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{'sprint_name'|translate}}</mat-label>
                <input class="Input-field" matInput type="text" maxlength="30"
                    (keypress)="characterNumberAndSpaceAndHyphenAllowed($event)" formControlName="sprintName">
                <mat-error
                    *ngIf="createSprintForm.get('sprintName')?.hasError('required') || createSprintForm.get('sprintName')?.hasError('noOnlySpaces')">
                    {{ 'sprint_name_is_required' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'duration' | translate }}</mat-label>
                <mat-select formControlName="sprintDuration">
                    <mat-option *ngFor="let duration of durationList" [value]="duration.id">
                        {{ duration.projectCode }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="createSprintForm.get('sprintDuration')?.hasError('required')">
                    {{ 'duration_is_required' | translate }}
                </mat-error>
            </mat-form-field>

        </div>

        <div class="sprint-row">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'start_date' | translate }}</mat-label>
                <input matInput [matDatepicker]="startPicker" [min]="startDateMin" [max]="startDateMax"
                    formControlName="sprintStartDate" />
                <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                <mat-datepicker #startPicker></mat-datepicker>
                <mat-error *ngIf="createSprintForm.get('sprintStartDate')?.hasError('required')">
                    {{ 'start_date_is_required' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'end_date' | translate }}</mat-label>
                <input matInput [matDatepicker]="endPicker" [min]="endDateMin" [max]="endDateMax"
                    formControlName="sprintEndDate" />
                <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                <mat-datepicker #endPicker></mat-datepicker>
                <mat-error *ngIf="createSprintForm.get('sprintEndDate')?.hasError('required')">
                    {{ 'end_date_is_required' | translate }}
                </mat-error>
            </mat-form-field>
        </div>

        <mat-form-field class="sprint-row" appearance="outline">
            <mat-label>{{'sprint_goal'|translate}}</mat-label>
            <textarea matInput formControlName="sprintGoal" rows="2"></textarea>
        </mat-form-field>

    </form>

    <div class="button-div">
        <button (click)="closeDialog()" class="cancel-btn">{{'cancel'|translate}}</button>
        <button (click)="createUpdateSprint()">{{ (sprintId ? 'update' : 'save') | translate }}</button>
    </div>

    <app-loader></app-loader>
</div>