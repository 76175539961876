<div *ngIf="!isEmployeeSelected" class="employee-list">
    <div *ngFor="let employee of employeeAttendanceList" class="employee-card"
        (click)="fetchAttendance(employee.employeeId)">
        <div class="attendance-info">
            <mat-icon class="profile-icon">account_circle</mat-icon>
            <div class="employee-details">
                <span><strong>{{ 'emp_id' | translate }}:</strong> {{ employee.employeeId }}</span>
                <span><strong>{{ 'name' | translate }}:</strong> {{ employee.employeeName }}</span>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isEmployeeSelected" class="attendance-container">
    <div class="header">
        <mat-icon class="back-icon" (click)="goBack()">arrow_back</mat-icon>
        <h3>{{ 'approve_attendance' | translate }}</h3>
    </div>

    <div *ngFor="let record of attendanceRecords" class="attendance-card">
        <div class="attendance-info">
            <div class="status-bar" [ngClass]="{
                    'status-pending': record.status === 'Submitted',
                    'status-approved': record.status === 'approved',
                    'status-rejected': record.status === 'rejected'
                }"></div>

            <div class="attendance-details">
                <span><strong>{{ 'project_code' | translate }}:</strong> {{ record.projectCode }}</span>
                <span><strong>{{ 'shift' | translate }}:</strong> {{ record.shift }}</span>
                <span><strong>{{ 'date' | translate }}:</strong> {{ record.date | date: 'MMMM d, yyyy' }}</span>
                <span><strong>{{ 'punch_in' | translate }}:</strong> {{ record.timeIn }}</span>
                <span><strong>{{ 'punch_out' | translate }}:</strong> {{ record.timeOut }}</span>
            </div>
        </div>

        <div class="actions">
            <span class="status-pill" [ngClass]="{
                    'pending': record.status === 'Submitted',
                    'approved': record.status === 'approved',
                    'rejected': record.status === 'rejected'
                }">
                {{ record.status | translate }}
            </span>
            <div class="action-trigger" *ngIf="record.status === 'Submitted'">
                <mat-icon class="reject-icon" (click)="approveRejectAttendance(record, 'reject')">
                    cancel
                </mat-icon>
                <mat-icon class="approve-icon" (click)="approveRejectAttendance(record, 'approve')">
                    check_circle
                </mat-icon>
            </div>
        </div>
    </div>
</div>