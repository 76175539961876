<div class="section-container custom_container" id="solutions">
    <div>
        <h1 class="section-title">{{'solutions' | translate}}</h1>
    </div>

    <mat-tab-group [@slideInLeft] class="solution-tab">
        <mat-tab class="tab-title" label="{{'small_scale'|translate}}">
            <div>
                <div class="card-container">
                    <mat-accordion class="solution-type">
                        <mat-expansion-panel (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>gamepad</mat-icon> <span class="title">{{'easy_payroll' |
                                        translate}}</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <b *ngIf="panelOpenState1">{{'interface:'| translate}}</b>
                            <p *ngIf="panelOpenState1">{{'easy_payroll_paragraph' | translate}}</p>

                            <b *ngIf="panelOpenState1">{{'automated_calculation' |translate}}</b>
                            <p *ngIf="panelOpenState1">{{'automated_calculation_paragraph' | translate}}</p>

                            <b *ngIf="panelOpenState1">{{'customization_option' | translate}}</b>
                            <p *ngIf="panelOpenState1">{{'customization_option_paragraph'| translate}}</p>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion class="solution-type">
                        <mat-expansion-panel (opened)="panelOpenState2 = true" (closed)="panelOpenState2 = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>account_balance</mat-icon> <span class="title">{{'bank_integration'
                                        |translate}}</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <b *ngIf="panelOpenState2">{{'real_time_configuration' | translate}} </b>
                            <p *ngIf="panelOpenState2">{{'real_time_configuration_paragraph' | translate}}</p>

                            <b *ngIf="panelOpenState2"> {{'user_friendly_interface' | translate}}</b>
                            <p *ngIf="panelOpenState2">{{'user_friendly_paragraph' | translate}}</p>

                            <b *ngIf="panelOpenState2">{{'customized_payment_setting' | translate}}</b>
                            <p *ngIf="panelOpenState2">{{'customized_payment_setting_paragraph' | translate}} </p>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <div class="card-container">
                    <mat-accordion class="solution-type">
                        <mat-expansion-panel (opened)="panelOpenState3 = true" (closed)="panelOpenState3 = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>date_range</mat-icon> <span class="title">{{'attendance_management' |
                                        translate}}</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <p *ngIf="panelOpenState3">

                                <b *ngIf="panelOpenState3">{{'set_your_rules' | translate}}</b>
                            <p *ngIf="panelOpenState3">{{'rules_paragraph'|translate}}</p>


                            <b *ngIf="panelOpenState3"> {{'use_it_anyware' | translate}}</b>
                            <p *ngIf="panelOpenState3"> {{'use_it_anyware_paragraph' | translate}}</p>

                            <b *ngIf="panelOpenState3">{{'well_work' | translate}}</b>
                            <p *ngIf="panelOpenState3">{{'well_work_paragraph'| translate}}</p>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion class="solution-type">
                        <mat-expansion-panel (opened)="panelOpenState4 = true" (closed)="panelOpenState4 = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon> list_alt</mat-icon> <span class="title">{{'leave_management' |
                                        translate}}</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>


                            <b *ngIf="panelOpenState4">{{'balance_leave' | translate}}</b>
                            <p *ngIf="panelOpenState4">{{'balance_leave_paragraph' | translate}}</p>

                            <b *ngIf="panelOpenState4">{{'approval_workflow' | translate}}</b>
                            <p *ngIf="panelOpenState4"> {{'approval_workflow_paragraph'| translate}}</p>

                            <b *ngIf="panelOpenState4">{{'automatic_calculation' | translate}}</b>
                            <p *ngIf="panelOpenState4"> {{'automatic_calculation_paragraph' | translate}}</p>

                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </mat-tab>

        <mat-tab class="tab-title" label="{{'mid_scale'|translate}}">
            <div>
                <div class="card-container">
                    <mat-accordion class="solution-type">
                        <mat-expansion-panel (opened)="panelOpenState5 = true" (closed)="panelOpenState5 = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>receipt</mat-icon> <span class="title">{{'tax_complient' |
                                        translate}}</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>


                            <b *ngIf="panelOpenState5">{{'tax_calculation' | translate}}</b>
                            <p *ngIf="panelOpenState5">{{'tax_calculation_paragraph' | translate}}</p>

                            <b *ngIf="panelOpenState5">{{'tax_regulation'|translate }}</b>
                            <p *ngIf="panelOpenState5">{{'tax_regulation_paragraph' | translate}}</p>

                            <b *ngIf="panelOpenState5"> {{'secure_data' | translate}}</b>
                            <p *ngIf="panelOpenState5"> {{'secure_data_paragraph' | translate}}</p>

                            <b *ngIf="panelOpenState5">{{'reporting' | translate}}</b>
                            <p *ngIf="panelOpenState5">{{'reporting_paragraph' | translate}}</p>

                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion class="solution-type">
                        <mat-expansion-panel (opened)="panelOpenState6 = true" (closed)="panelOpenState6 = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>report</mat-icon>

                                    <span class="title"> {{'reportings' | translate}}</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <b *ngIf="panelOpenState6">{{'customized_report' | translate}} </b>
                            <p *ngIf="panelOpenState6">{{'customized_report_paragraph' | translate}}</p>

                            <b *ngIf="panelOpenState6">{{'real_time_data' | translate}}</b>
                            <p *ngIf="panelOpenState6">{{'real_time_data_paragraph' | translate}}</p>

                            <b *ngIf="panelOpenState6">{{'visual_analytics' |translate}}</b>
                            <p *ngIf="panelOpenState6">{{'visual_paragraph' | translate}}</p>

                            <b *ngIf="panelOpenState6"> {{'sheduled_report' | translate}}</b>
                            <p *ngIf="panelOpenState6">{{'sheduled_report_paragraph' | translate}}</p>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <div class="card-container">
                    <mat-accordion class="solution-type">
                        <mat-expansion-panel (opened)="panelOpenState7 = true" (closed)="panelOpenState7 = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>group</mat-icon>
                                    <span class="title">{{'customized_group_payrun' | translate}}</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>


                            <b *ngIf="panelOpenState7">{{'payroll_configuration' | translate}}</b>
                            <p *ngIf="panelOpenState7">{{'payroll_configuration_paragraph' | translate}}</p>

                            <b *ngIf="panelOpenState7">{{'group_management:' | translate}}</b>
                            <p *ngIf="panelOpenState7">{{'group_management_paragraph'|translate}}</p>

                            <b *ngIf="panelOpenState7">{{'flexible_payrun' | translate}}</b>
                            <p *ngIf="panelOpenState7">{{'flexible_payrun_paragraph' | translate}}</p>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion class="solution-type">
                        <mat-expansion-panel (opened)="panelOpenState8 = true" (closed)="panelOpenState8 = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>people_outline</mat-icon><span class="title">{{'contractor_payrun' |
                                        translate}}</span>

                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <b *ngIf="panelOpenState8">{{'contractor_onboarding' | translate}}</b>
                            <p *ngIf="panelOpenState8">{{'contractor_onboarding_paragraph' | translate}}</p>

                            <b *ngIf="panelOpenState8">{{'automatic_payment_calculations' | translate}}</b>
                            <p *ngIf="panelOpenState8"> {{'automatic_payment_calculations_paragraph'| translate}}</p>

                            <b *ngIf="panelOpenState8">{{'trance_record' | translate}}</b>
                            <p *ngIf="panelOpenState8">{{'trance_record_paragraph' | translate}}</p>

                            <b *ngIf="panelOpenState8">{{'tax_compliance' | translate}}:</b>
                            <p *ngIf="panelOpenState8">{{'tax_compliance_paragraph' | translate}} </p>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>

            </div>
        </mat-tab>

        <mat-tab class="tab-title" label="{{'role_based'|translate}}">
            <div>
                <div class="card-container">
                    <mat-accordion class="solution-type">
                        <mat-expansion-panel (opened)="panelOpenState9 = true" (closed)="panelOpenState9 = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon> attach_money</mat-icon><span class="title">{{'cash_flow_management' |
                                        translate}}</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>


                            <b *ngIf="panelOpenState9"> {{'expence_tracking' | translate}}</b>
                            <p *ngIf="panelOpenState9">{{'tracking_paragraph' | translate}}</p>

                            <b *ngIf="panelOpenState9">{{'income_tracking' | translate}}</b>
                            <p *ngIf="panelOpenState9"> {{'income_tracking_paragraph' | translate}}</p>

                            <b *ngIf="panelOpenState9">{{'customized_dashboard' | translate}}</b>
                            <p *ngIf="panelOpenState9"> {{'customized_dashboard_paragraph' | translate}}</p>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion class="solution-type">
                        <mat-expansion-panel (opened)="panelOpenState10 = true" (closed)="panelOpenState10 = false">

                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>recent_actors</mat-icon> <span class="title">{{'employee_start_management'
                                        | translate}}</span>

                                </mat-panel-title>
                            </mat-expansion-panel-header>



                            <b *ngIf="panelOpenState10">{{'document_management' | translate}}</b>
                            <p *ngIf="panelOpenState10">{{'document_paragraph' | translate}}</p>

                            <b *ngIf="panelOpenState10"> {{'performance_tracking' | translate }} </b>
                            <p *ngIf="panelOpenState10">{{'performance_tracking_paragraph' | translate}}</p>

                            <b *ngIf="panelOpenState10">{{'leave_and_attendance' | translate}} </b>
                            <p *ngIf="panelOpenState10">{{'leave_and_attendance_paragraph' | translate}}</p>

                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
                <div class="card-container">
                    <mat-accordion class="solution-type">
                        <mat-expansion-panel (opened)="panelOpenState11 = true" (closed)="panelOpenState11 = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>control_camera</mat-icon><span class="title">{{'project_management' |
                                        translate}}</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>

                            <b *ngIf="panelOpenState11"> {{'initiative_task' | translate}}</b>
                            <p *ngIf="panelOpenState11"> {{'initiative_task_paragraph' | translate}}</p>

                            <b *ngIf="panelOpenState11"> {{'grant_chart' | translate}}</b>
                            <p *ngIf="panelOpenState11"> {{'grant_chart_paragraph' | translate}}</p>
                            <b *ngIf="panelOpenState11">{{'progress_tracking' | translate}} </b>
                            <p *ngIf="panelOpenState11">{{'progress_tracking_paragraph' | translate}}</p>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion class="solution-type">
                        <mat-expansion-panel (opened)="panelOpenState12 = true" (closed)="panelOpenState12 = false">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <mat-icon>equalizer</mat-icon><span class="title"> {{'performance_management' |
                                        translate}}</span>
                                </mat-panel-title>
                            </mat-expansion-panel-header>


                            <b *ngIf="panelOpenState12"> {{'real_time_feedback' | translate}} </b>
                            <p *ngIf="panelOpenState12">{{'feedback_paragraph' | translate}}</p>


                            <b *ngIf="panelOpenState12">{{'skill_tracking' | translate}}</b>
                            <p *ngIf="panelOpenState12">{{'skill_tracking_paragraph' | translate}}
                            <p>

                                <b *ngIf="panelOpenState12">{{'performance' | translate}} </b>
                            <p *ngIf="panelOpenState12">{{'performance_tracking_paragraphs' | translate}}</p>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>