import { HttpClient } from "@angular/common/http";
import { CreateLoginRequest, CreateLoginResponse } from "../../model/login.model";
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from "../../config/api-endpoint.config";
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment.dev";
import { AppConstant } from "src/app/constants/app.constants";
import { EncryptionService } from "../encryption/encryption.service";

@Injectable({ providedIn: 'root' })
export class LoginService {

  dialogRef: any;

  constructor(private http: HttpClient, private encryptionService: EncryptionService) { }

  login(loginRequest: CreateLoginRequest): Observable<CreateLoginResponse> {
    loginRequest.password = this.encryptionService.encrypt(loginRequest.password);
    return this.http.post(environment.apiUrl + API_END_POINTS.LOGIN_PAGE, loginRequest).pipe(
      map((res: any) => {
        return res;
      }))
  }

  adminLogin(loginRequest: CreateLoginRequest): Observable<CreateLoginRequest> {
    loginRequest.password = this.encryptionService.encrypt(loginRequest.password);
    return this.http.post(environment.apiUrl + API_END_POINTS.SUPER_ADMIN_LOGIN, loginRequest).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  setUserRole(role: any): void {
    if (sessionStorage.getItem(AppConstant.ASSIGN_ROLES) === role) {
      sessionStorage.setItem(AppConstant.ASSIGN_ROLES, role)
    };
  }

  getUserRole() {
    return AppConstant.ROLE;
  }

  getCompanyId(employeeId: any): Observable<CreateLoginResponse[]> {
    return this.http.get(environment.apiUrl + API_END_POINTS.VIEWPROFILE + employeeId).pipe(
      map((res: any) => {
        return res.data;
      }),
    );
  }

}