import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { ResignationService } from 'src/app/services/resignation/resignation.service';

@Component({
  selector: 'app-final-settlement-payrun-details',
  templateUrl: './final-settlement-payrun-details.component.html',
  styleUrls: ['./final-settlement-payrun-details.component.scss']
})

export class FinalSettlementPayrunDetailsComponent implements OnInit {
  fnfData: any;
  lastWorkingMonthYear: any;
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
  employeeName: string | undefined;
  lastName: string | undefined;
  constructor(private router: Router, private employeeService: EmployeeDetailsService, private resignationService: ResignationService) { }

  ngOnInit() {
    this.employeeFinalSettlementDetails();
    this.getEmployeeExitDetails();
  }

  employeeFinalSettlementDetails() {
    this.resignationService.finalSettlementDetails(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem('employeeId'))
      .subscribe((data: any) => {
        this.fnfData = data.data;
        if (this.fnfData.lastWorkingDate) {
          this.fnfData.lastWorkingDate = this.formatDate(this.fnfData.lastWorkingDate);
        }
        if (this.fnfData.payDay) {
          this.fnfData.payDay = this.formatDate(this.fnfData.payDay);
        }
        const payDayDate = new Date(this.fnfData.payDay);
        const payDayMonth = payDayDate.toLocaleString('en-US', { month: 'long' });
        const payDayYear = payDayDate.getFullYear();
        this.lastWorkingMonthYear = payDayMonth + ' ' + payDayYear;
      });
  }

  sortedEarningList(data: any) {
    return data.sort((a: any, b: any) => {
      return a.name.localeCompare(b.name);
    });
  }

  convertLabel(label: string): string {
    if (label.toLowerCase() === 'hra') {
      return 'HRA';
    }

    let formattedLabel = label.replace(/([a-z])([A-Z])/g, '$1 $2');
    formattedLabel = formattedLabel.replace(/([a-zA-Z\s])([0-9])/g, '$1 $2');
    return formattedLabel.charAt(0).toUpperCase() + formattedLabel.slice(1);
  }

  formatDate(dateStr: string): string {
    const date = new Date(dateStr);
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  }

  getEmployeeExitDetails() {
    this.employeeService.getActiveEmployeeDetails(this.companyId, this.employeeId).subscribe((data: any) => {
      this.employeeName = data?.employeeBasicDetail?.firstName;
      this.lastName = data?.employeeBasicDetail?.lastName;

    });
  }

  goToBack() {
    this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACTIVE_EMPLOYEES]);
  }

}
