import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, debounceTime, filter, map, takeUntil, tap } from 'rxjs';

interface Currency {
  id: any;
  countryName: any;
  currency: any;
}

@Component({
  selector: 'app-common-search-input',
  templateUrl: './common-search-input.component.html',
  styleUrls: ['./common-search-input.component.scss']
})
export class CommonSearchInputComponent implements OnInit, OnDestroy {
  @Input() data: Currency[] = [];
  @Output() selectResult = new EventEmitter<Currency>();
  searchControl: FormControl = new FormControl();
  searching: boolean = false;
  filteredResults: Currency[] = [];
  private _onDestroy = new Subject<void>();

  ngOnInit() {
    this.searchControl.valueChanges.pipe(
      filter(search => !!search),
      tap(() => this.searching = true),
      debounceTime(300),
      map(search => search.toLowerCase()),
      takeUntil(this._onDestroy)
    ).subscribe(search => {
      this.filteredResults = this.data.filter(item => item.currency.toLowerCase().includes(search));
      this.searching = false;
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}