import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { AccountType, BankDetails } from 'src/app/model/bankDetails.model';
import { BankDetailsService } from 'src/app/services/bank-details/bank-details.service';
import { ValidationUtil } from 'src/app/utils/validators.util';
@Component({
  selector: 'app-company-bank-details',
  templateUrl: './company-bank-details.component.html',
  styleUrls: ['./company-bank-details.component.scss']
})
export class CompanyBankDetailsComponent {
  public bankDetailsForm !: FormGroup;
  hide: boolean = true;
  error: string | undefined;
  accountType: AccountType[] = [
    { accountType: AppConstant.SALARY, selectedAccount: AppConstant.SALARY },
    { accountType: AppConstant.CURRENT, selectedAccount: AppConstant.CURRENT }
  ];

  constructor(private router: Router, private fb: FormBuilder, private bankDetailsService: BankDetailsService, private toastrService: ToastrService) { }

  ngOnInit() {
    this.initAddBankDetailsForm();
  }

  initAddBankDetailsForm() {
    this.bankDetailsForm = this.fb.group({
      'id': ['', [Validators.required]],
      'bankAccountNo': ['', [Validators.required, Validators.pattern(/^\d{8,}$/)]],
      'ifscCode': ['', [Validators.required]],
      'branch': ['', [Validators.required]],
      'accountType': ['', [Validators.required]],
      'bankName': ['', [Validators.required]],
    })
  }

  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }


  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumber(event);
  }

  validateCharacter(event: any) {
    return ValidationUtil.validateCharacter(event);
  }

  alphanumericAllowed(event: any) {
    ValidationUtil.onlyAlphanumeric(event);
  }

  addBankDetails() {
    let bankDetailsRequest: BankDetails = {
      "id": sessionStorage.getItem(AppConstant.COMPANY_ID),
      "bankAccountNo": this.bankDetailsForm.value.bankAccountNo,
      "ifscCode": this.bankDetailsForm.value.ifscCode,
      "branch": this.bankDetailsForm.value.branch,
      "accountType": this.bankDetailsForm.value.accountType,
      "bankName": this.bankDetailsForm.value.bankName,
    };
    this.bankDetailsService.companyBankDetails(bankDetailsRequest).subscribe((data: any) => {
      this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.DASHBOARD]);
    })
  }

}

