export class MONTHS {
    public static JAN: string = 'Jan';
    public static FEB: string = 'Feb';
    public static MARCH: string = 'March';
    public static APRIL: string = 'April';
    public static MAY: string = 'May';
    public static JUNE: string = 'June';
    public static JULY: string = 'July';
    public static AUG: string = 'Aug';
    public static SEPT: string = 'Sept';
    public static OCT: string = 'Oct';
    public static NOV: string = 'Nov';
    public static DEC: string = 'Dec';

}