<div class='container' layout="row" flex>
    <div layout="row" layout-xs="column" class="form-field" flex>
        <div class="header">
            <h3>{{'employee_profile' | translate}}</h3>
        </div>

        <mat-tab-group>
            <!-- employee details tab start -->
            <mat-tab label="{{'employee_details'|translate}}">
                <form name="viewEmployeeForm" [formGroup]="viewEmployeeForm">
                    <div>
                        <h5 class="heading-content">{{'employee-basic-details' | translate}}</h5>

                        <div *ngIf="!isEditable">
                            <div>
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'name' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.firstName}}</span>
                                    <span class="data-value">{{readonlyData.middleName}}</span>
                                    <span class="data-value">{{readonlyData.lastName}}</span>
                                </div>
                            </div>

                            <div>
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'work_email' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.workEmail}}</span>
                                </div>
                            </div>
                            <div class="readonly__basic">
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'gender' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.gender}}</span>
                                </div>

                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'date_of_joining' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.doj | date:
                                        'dd/MM/yyyy'}}</span>
                                </div>
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'designation' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.designationName}}</span>
                                </div>
                            </div>
                            <div class="readonly__basic">
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'work_location' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.workLocation}}</span>
                                </div>
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'department_name' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.departmentName}}</span>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="isEditable">
                            <div class="form-field-gap">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label class="form-label">{{'first_name'|translate}}</mat-label>
                                    <input matInput type="text"
                                        [(ngModel)]="employeeDetails.employeeBasicDetail.firstName" class="input"
                                        formControlName="firstName" (keypress)="validateCharacterNumberAndSpace($event)"
                                        required />
                                </mat-form-field>
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label class="form-label">{{'middle_name'|translate}}</mat-label>
                                    <input matInput [(ngModel)]=" employeeDetails.employeeBasicDetail.middleName"
                                        type="text" class="input" formControlName="middleName"
                                        (keypress)="validateCharacterNumberAndSpace($event)" />
                                </mat-form-field>
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label class="form-label">{{'last_name'|translate}}</mat-label>
                                    <input matInput type="text" class="input"
                                        [(ngModel)]=" employeeDetails.employeeBasicDetail.lastName"
                                        formControlName="lastName"
                                        (keypress)="validateCharacterNumberAndSpace($event)" />
                                </mat-form-field>
                            </div>
                            <div class="form-field-gap">
                                <mat-form-field appearance="outline">
                                    <mat-label>{{ 'gender' | translate }}</mat-label>
                                    <mat-select placeholder="{{ 'gender' | translate }}" formControlName="gender"
                                        [(ngModel)]="employeeDetails?.employeeBasicDetail.gender">
                                        <mat-option value="Male">{{'male' | translate}}</mat-option>
                                        <mat-option value="Female">{{'female' | translate}}</mat-option>
                                        <mat-option value="Other">{{'other' | translate}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label class="form-label">{{'date_of_joining'|translate}}</mat-label>
                                    <input matInput formControlName="doj"
                                        [(ngModel)]="employeeDetails?.employeeBasicDetail.doj" [matDatepicker]="picker"
                                        (keypress)="$event.preventDefault()" [min]="dojMinLength"
                                        [max]="futureSixMonths" required>
                                    <mat-datepicker-toggle matIconSuffix [for]="picker"
                                        [disabled]="!isEditable"></mat-datepicker-toggle>
                                    <mat-datepicker #picker [disabled]="!isEditable"></mat-datepicker>
                                </mat-form-field>

                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label class="form-label">{{'designation'|translate}}</mat-label>
                                    <input matInput class="input"
                                        [(ngModel)]="employeeDetails?.employeeBasicDetail.designationName"
                                        formControlName="designationName"
                                        (keypress)="validateCharacterNumberAndSpace($event)" />
                                </mat-form-field>
                            </div>
                            <div class="form-field-gap7">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label class="form-label">{{'work_email'|translate}}</mat-label>
                                    <input matInput class="input"
                                        [(ngModel)]="employeeDetails.employeeBasicDetail.workEmail"
                                        formControlName="workEmail" readonly="true" />
                                </mat-form-field>

                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label class="form-label">{{'work_location'|translate}}</mat-label>
                                    <input matInput type="text" class="input"
                                        [(ngModel)]="employeeDetails.employeeBasicDetail.workLocation"
                                        formControlName="workLocation"
                                        (keypress)="validateCharacterNumberAndSpace($event)" />
                                </mat-form-field>
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label class="form-label">{{'department_name'|translate}}</mat-label>
                                    <input matInput type="text" class="input"
                                        [(ngModel)]="employeeDetails.employeeBasicDetail.departmentName"
                                        formControlName="departmentName"
                                        (keypress)="validateCharacterNumberAndSpace($event)" />
                                </mat-form-field>
                            </div>
                        </div>

                        <a class="hide-show" (click)="showBasicDetails()"><u>{{ showBasicDetail ? 'Show Less' :
                                'Show More...'}}</u></a>
                    </div>
                    <div *ngIf="showBasicDetail" class="section">
                        <h5 class="heading-content">{{'employee_personal_details' | translate}}</h5>

                        <div *ngIf="!isEditable">
                            <div>
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'personal_email' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.email}}</span>
                                </div>
                            </div>
                            <div class="readonly__basic">
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'mobile_number' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.mobileNo}}</span>
                                </div>
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'date_of_birth' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.dob | date:
                                        'dd/MM/yyyy'}}</span>
                                </div>

                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'pan' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.panNo}}</span>
                                </div>
                            </div>
                            <div>
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'address1' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.addressLine1}}</span>
                                </div>
                            </div>
                            <div *ngIf="employeeDetails.employeePersonalDetail.addressLine2">
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'address2' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.addressLine2}}</span>
                                </div>
                            </div>
                            <div class="address">
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'country' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.country}}</span>
                                </div>
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'state' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.state}}</span>
                                </div>
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'city' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.city}}</span>
                                </div>
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'pincode' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.pinCode}}</span>
                                </div>
                            </div>
                            <div class="readonly__basic">
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'uan' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.uan}}</span>
                                </div>
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'pf_number' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.pfNumber}}</span>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="isEditable">
                            <div class="form-field-gap4">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label class="form-label">{{'personal_email'|translate}}</mat-label>
                                    <input matInput class="input"
                                        [(ngModel)]="employeeDetails.employeePersonalDetail.email"
                                        formControlName="email" required />
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label class="form-label">{{'mobile_number'|translate}}</mat-label>
                                    <input matInput formControlName="mobileNo" (keypress)="validateNumber($event)"
                                        minlength="10" maxlength="10"
                                        [(ngModel)]="employeeDetails.employeePersonalDetail.mobileNo" class="mat-input"
                                        required />
                                </mat-form-field>
                            </div>
                            <div class="form-field-gap4">
                                <mat-form-field appearance="outline" class="dob">
                                    <mat-label class="form-label">{{'date_of_birth'|translate}}</mat-label>
                                    <input matInput formControlName="dob"
                                        [(ngModel)]="employeeDetails.employeePersonalDetail.dob" class="mat-input"
                                        [matDatepicker]="picker1" [min]="sixtyYearsOldDate" [max]="ageOfMaturityDate"
                                        (keypress)="$event.preventDefault()" required/>
                                    <mat-datepicker-toggle matIconSuffix [for]="picker1"
                                        [disabled]="!isEditable"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1 [disabled]="!isEditable"></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label class="form-label">{{'pan'|translate}}</mat-label>
                                    <input matInput formControlName="panNo" (keypress)="alphanumericAllowed($event)"
                                        appUppercase [maxLength]="10"
                                        [(ngModel)]="employeeDetails.employeePersonalDetail.panNo" class="mat-input" />
                                </mat-form-field>
                            </div>
                            <div class="form-field-gap3">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label class="form-label">{{'address1'|translate}}</mat-label>
                                    <input matInput type="text" class="input"
                                        [(ngModel)]="employeeDetails.employeePersonalDetail.addressLine1"
                                        formControlName="addressLine1" (keypress)="addressVerification($event)" />
                                </mat-form-field>
                            </div>
                            <div class="form-field-gap3">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label class="form-label">{{'address2'|translate}}</mat-label>
                                    <input matInput type="text" class="input"
                                        [(ngModel)]="employeeDetails.employeePersonalDetail.addressLine2"
                                        formControlName="addressLine2" (keypress)="addressVerification($event)">
                                </mat-form-field>
                            </div>
                            <div class="form-field-gap5">
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label class="form-label">{{'country' | translate}}</mat-label>
                                    <mat-select matInput formControlName="country" class="select_mat_input" required
                                        [(ngModel)]="employeeDetails.employeePersonalDetail.country"
                                        (selectionChange)="getState($event.value)">
                                        <mat-option [disabled]="!isEditable" *ngFor="let country of countryList;"
                                            [value]="country">{{country}}</mat-option>
                                    </mat-select>
                                </mat-form-field>


                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label class="form-label">{{'state' | translate}}</mat-label>
                                    <mat-select matInput formControlName="state" class="select_mat_input" required
                                        selected [(ngModel)]="employeeDetails.employeePersonalDetail.state"
                                        (selectionChange)="getCity($event.value)">
                                        <mat-option [disabled]="!isEditable" *ngFor="let state of stateList;"
                                            [value]="state">
                                            {{state}}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label class="form-label">{{'city' | translate}}</mat-label>
                                    <mat-select matInput formControlName="city" class="select_mat_input" required
                                        selected [(ngModel)]="employeeDetails.employeePersonalDetail.city"
                                        (selectionChange)="selectedCityName($event.value)">
                                        <mat-option *ngFor="let city of cityList;" [value]="city">{{city}}</mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label class="form-label">{{'pincode'|translate}}</mat-label>
                                    <input matInput formControlName="pinCode" class="mat-input"
                                        (keypress)="validateNumber($event)" maxlength="6"
                                        [(ngModel)]="employeeDetails.employeePersonalDetail.pinCode" />
                                </mat-form-field>
                            </div>
                            <div class="form-field-gap4">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label class="form-label">{{'uan'|translate}}</mat-label>
                                    <input matInput type="text" class="input" formControlName="uan"
                                        (keypress)="validateNumber($event)" [maxLength]="12"
                                        [(ngModel)]="employeeDetails.employeePersonalDetail.uan" />
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label class="form-label">{{'pf_number'|translate}}</mat-label>
                                    <input matInput formControlName="pfNumber" class="mat-input" [maxlength]="22"
                                        [minLength]="9" [(ngModel)]="employeeDetails.employeePersonalDetail.pfNumber"
                                        appUppercase (keypress)="onlyAlphanumericAndBackSlash($event)" />
                                </mat-form-field>
                            </div>
                        </div>

                        <a class="hide-show" (click)="showPersonalDetails()">
                            <u>{{ showPersonalDetail ? 'Show Less' :'Show More...'}}</u>
                        </a>
                    </div>

                    <div *ngIf="showPersonalDetail" class="section">
                        <h5 class="heading-content">{{'employee_salary_details'| translate}}</h5>
                        <div *ngIf="!isEditable">
                            <div class="readonly__basic">
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'annual_ctc' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.annualCtc}}</span>
                                </div>

                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'payment_mode' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.paymentMode}}</span>
                                </div>
                            </div>
                            <div class="readonly__basic">
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'account_number' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.bankAccountNo}}</span>
                                </div>

                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'ifsc_code' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.ifscCode}}</span>
                                </div>
                            </div>
                            <div class="readonly__basic">
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'bank_name' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.bankName}}</span>
                                </div>

                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'branch_name' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.branch}}</span>
                                </div>
                                <div class="employee-info">
                                    <div class="heading-icon">
                                        <h4 class="data-key">{{ 'account_type' | translate }} : </h4>
                                    </div>
                                    <span class="data-value">{{readonlyData.accountType}}</span>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="isEditable">
                            <div class="form-field-gap4">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label class="form-label">{{'annual_ctc'|translate}}</mat-label>
                                    <input matInput type="text" class="input" formControlName="annualCtc"
                                        [(ngModel)]="employeeDetails.employeeSalaryDetail.annualCtc"
                                        (keypress)="anuualCTCValidation($event)" />
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label class="form-label">{{'payment_mode'|translate}}</mat-label>
                                    <input matInput formControlName="paymentMode"
                                        [(ngModel)]="employeeDetails.employeeSalaryDetail.paymentMode" class="mat-input"
                                        (keypress)="characterAndSpaceAllowed($event)" />
                                </mat-form-field>
                            </div>
                            <div class="form-field-gap4">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label class="form-label">{{'account_number'|translate}}</mat-label>
                                    <input matInput type="text" class="input" minlength="8" [maxLength]="18"
                                        [(ngModel)]="employeeDetails.employeeBankDetail.bankAccountNo"
                                        (keypress)="validateNumber($event)" formControlName="bankAccountNo" />
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="full-width">
                                    <mat-label class="form-label">{{'ifsc_code'|translate}}</mat-label>
                                    <input matInput formControlName="ifscCode" [maxLength]="11"
                                        [(ngModel)]="employeeDetails.employeeBankDetail.ifscCode" class="mat-input"
                                        (keypress)="alphanumericAllowed($event)" />
                                </mat-form-field>
                            </div>
                            <div class="form-field-gap">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label class="form-label">{{'bank_name'|translate}}</mat-label>
                                    <input matInput type="text" class="input" formControlName="bankName"
                                        [maxLength]="30" [(ngModel)]="employeeDetails.employeeBankDetail.bankName"
                                        (keypress)="validateCharacterNumberAndSpace($event)" />
                                </mat-form-field>
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label class="form-label">{{'branch_name'|translate}}</mat-label>
                                    <input matInput type="text" class="input" formControlName="branch"
                                        [(ngModel)]="employeeDetails.employeeBankDetail.branch" [maxLength]="30"
                                        (keypress)="validateCharacterNumberAndSpace($event)" />
                                </mat-form-field>
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label class="form-label">{{'account_type'|translate}}</mat-label>
                                    <mat-select class="select-mat-input" formControlName="accountType" required
                                        [(ngModel)]="employeeDetails.employeeBankDetail.accountType" selected>
                                        <mat-option *ngFor="let accountType of accountType"
                                            [value]="accountType.accountType">
                                            {{accountType.selectedAccount}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <button class="update-button" [ngClass]="isEditable ? 'cancel-btn' : ''"
                        [attr.value]="isEditable ? 'Cancel' : 'Update'"
                        (click)="updateProfileButton($event)">{{isEditable ?
                        'Cancel' : 'Update' | translate }}</button>

                    <button class="update-button" (click)="updateProfile()" *ngIf="isEditable">{{'update'
                        |translate}}</button>

                </form>
            </mat-tab>
            <!-- employee details tab start -->

            <!-- organization hierarchy tab start -->
            <mat-tab label="{{'team'|translate}}">
                <app-org-hierarchy></app-org-hierarchy>
            </mat-tab>
            <!-- organization hierarchy tab start -->

            <!-- payslip and form tab start -->
            <mat-tab label="{{'payslip_and_form'|translate}}">
                <app-payslipAndForms></app-payslipAndForms>
            </mat-tab>
            <!-- payslip and form tab end -->

            <!-- payslip and form tab start -->
            <mat-tab label="{{'assets'|translate}}">
                <app-employee-asset-details></app-employee-asset-details>
            </mat-tab>
            <!-- payslip and form tab end -->

            <!-- Family and Emergency contacts tab start -->
             <mat-tab label="{{'emergency_contacts' | translate}}">
                <app-family-and-emergency-contact-info></app-family-and-emergency-contact-info>
             </mat-tab>
            <!-- Family and Emergency contacts tab end -->

        </mat-tab-group>
    </div>
</div>

<app-loader></app-loader>