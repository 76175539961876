<div class="main-container">
  <div class="header">
    <h3>{{'report'|translate}}</h3>
    <div class=data-container>
      <div class="dashboard-dropdown">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label class="formLabel"> {{ 'dashboards' | translate }}</mat-label>
          <mat-select class="select-mat-input" [(ngModel)]="selectedBoardId"
            (selectionChange)="onDashboardChange($event)">
            <mat-option *ngFor="let board of boardList" [value]="board.dashboardId">
              {{ board.boardName }}
            </mat-option>
            <mat-divider class="divider"></mat-divider>
            <mat-option (click)="viewAllDashboards()">
              <span class="add-dashboard">{{'view_all_boards'|translate}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="sprint-dropdown">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label class="formLabel">{{ 'select_sprint' | translate }}</mat-label>
          <mat-select multiple class="select-mat-input" [(ngModel)]="selectedSprintId"
            (selectionChange)="onSprintChange($event)">
            <div class="select-all">
              <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                (change)="selectAllSprints(selectedSprintId)">
                {{'select_all'|translate}}
              </mat-checkbox>
            </div>
            <mat-option *ngFor="let sprint of sprints" [value]="sprint.sprintId">
              {{ sprint.sprintName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="button">
        <button class="export-btn" mat-button [matMenuTriggerFor]="menu"><mat-icon>file_upload</mat-icon>{{'export' |
          translate}}</button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="downloadExcel()">{{'download_as_excel' | translate}}</button>
          <button mat-menu-item (click)="downloadCSV()">{{'download_as_csv' | translate}}</button>
        </mat-menu>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="reportsGrid">
    <ag-grid-angular (gridReady)="onGridReady($event)" style="height: 460px; width:  100%;" class="ag-theme-alpine"
      [rowData]="showAllReportData" [rowSelection]="'multiple'" [animateRows]="true" [columnDefs]="reportscolumnDefs"
      [enableSorting]="true" [enableFilter]="true" [pagination]="true" [paginationPageSize]="10">
    </ag-grid-angular>
  </div>