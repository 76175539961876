import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { AccountType, EmployeeBankDetails } from 'src/app/model/bankDetails.model';
import { BankDetailsService } from 'src/app/services/bank-details/bank-details.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-add-bank-details',
  templateUrl: './add-bank-details.component.html',
  styleUrls: ['./add-bank-details.component.scss']
})

export class AddBankDetailsComponent implements OnInit {

  public bankDetailsForm !: FormGroup;
  hidePassword: boolean = true;
  accountType: AccountType[] = [
    { accountType: AppConstant.SALARY, selectedAccount: AppConstant.SALARY },
    { accountType: AppConstant.CURRENT, selectedAccount: AppConstant.CURRENT },
    { accountType: AppConstant.SAVING, selectedAccount: AppConstant.SAVING }
  ];

  constructor(private router: Router, private fb: FormBuilder, private bankDetailsService: BankDetailsService, private toastrService: ToastrService) { }

  ngOnInit() {
    this.initAddBankDetailsForm();
  }

  initAddBankDetailsForm() {
    this.bankDetailsForm = this.fb.group({
      'id': ['', [Validators.required]],
      'bankAccountNo': ['', [Validators.required]],
      'ifscCode': ['', [Validators.required]],
      'branch': ['', ValidationUtil.onlySpacesOrNumbersValidator()],
      'accountType': ['', [Validators.required]],
      'bankName': ['', ValidationUtil.onlySpacesOrNumbersValidator()],
      'companyId': ['', [Validators.required]],
      'ibanCode': ['', Validators.required],
      'confirmBankAccountNo': ['', Validators.required],
    })
  }

  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumbers(event);
  }

  validateCharacter(event: any) {
    return ValidationUtil.validateCharacter(event);
  }

  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

  addBankDetails() {
    let bankDetailsRequest: EmployeeBankDetails = {
      "id": this.bankDetailsForm.value.id,
      "bankAccountNo": this.bankDetailsForm.value.bankAccountNo,
      "ifscCode": this.bankDetailsForm.value.ifscCode,
      "branch": this.bankDetailsForm.value.branch,
      "accountType": this.bankDetailsForm.value.accountType,
      "bankName": this.bankDetailsForm.value.bankName,
      "companyId": sessionStorage.getItem(AppConstant.COMPANY_ID),
      "ibanCode": this.bankDetailsForm.value.ibanCode,
      "confirmBankAccountNo": this.bankDetailsForm.value.confirmBankAccountNo
    };
    this.bankDetailsService.addBankDetails(bankDetailsRequest).subscribe((data: any) => {
      if (data.status == 200) {
        this.toastrService.clear();
        this.toastrService.success(data.message);
        this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.DASHBOARD]);
      }
      else {
        this.toastrService.clear();
        this.toastrService.error(data.message);
        this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ADD_BANK_DETAILS]);
      }
    })
  }

  validateAccountNumberMatch() {
    const accountNumber = this.bankDetailsForm.value.bankAccountNo;
    const confirmAccountNumber = this.bankDetailsForm.value.confirmBankAccountNo;
    const cleanAccountNumber = accountNumber.replace(/\D/g, '');
    const cleanConfirmAccountNumber = confirmAccountNumber.replace(/\D/g, '');
    if (cleanAccountNumber !== cleanConfirmAccountNumber) {
      this.bankDetailsForm.get('confirmBankAccountNo')?.setErrors({ 'mismatch': true });
    } else {
      this.bankDetailsForm.get('confirmBankAccountNo')?.setErrors(null);
    }
  }

  alphanumericAllowed(event: any) {
    ValidationUtil.onlyAlphanumeric(event);
  }

}
