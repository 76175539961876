<div class="container">
  <h3>PF Configuration</h3>
  <form [formGroup]="pfForm" (ngSubmit)="onSubmit()">
    <div class="form-field-gap1">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Employee Contribution Rate</mat-label>
        <mat-select #selectRef formControlName="employeeContributionRate">
          <mat-option *ngFor="let rate of employeeContributionRates" [value]="rate">{{ rate }}</mat-option>
        </mat-select>
      </mat-form-field>
      
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Employer Contribution Rate</mat-label>
        <mat-select formControlName="employerContributionRate">
          <mat-option *ngFor="let rate of employerContributionRates" [value]="rate">{{ rate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Minimun deduction amount</mat-label>
        <mat-select formControlName="employeeContributionRate">
          <mat-option *ngFor="let rate of minimunDeducionAmount" [value]="rate">{{ rate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Minimun deduction Range</mat-label>
        <mat-select formControlName="employeeContributionRate">
          <mat-option *ngFor="let rate of minimunDeducionRange" [value]="rate">{{ rate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Deduction Cycle</mat-label>
        <mat-select formControlName="deductionCycle">
          <mat-option *ngFor="let cycle of deductionCycles" [value]="cycle">{{ cycle }}</mat-option>
        </mat-select>
      </mat-form-field>
      
    </div>

    <button class="button-right" color="primary" type="submit" [disabled]="pfForm.invalid">Save</button>
  </form>
</div>