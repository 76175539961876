<h3 mat-dialog-title>{{'select_regime_type'| translate}}</h3>
<mat-dialog-content>
    <p>{{'please_select_regime_type'| translate}}</p>
    <div class="regime-details">
        <p>{{'here_are_the_details_for_each_regime' | translate}}</p>
        <ul>
            <li><strong>{{'new_regime'|translate}} :</strong>{{'new_regime_content' | translate}}</li>
            <li><strong>{{'old_regime'|translate}} :</strong>{{'old_regime_content' | translate}}</li>
        </ul>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button (click)="onSelect('new')">{{'new_regime'|translate}}</button>
    <button (click)="onSelect('old')">{{'old_regime'|translate}}</button>
</mat-dialog-actions>