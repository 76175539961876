<div class="regime-main">
    <div class="regime-header">
        <h3>{{ 'regime_slab' | translate }}</h3>
        <div class="buttonDiv">
            <button color="primary" (click)="openAddSectionPopup()">{{ 'add_slab' | translate }}</button>
        </div>
    </div>
    <div class="section-list-table">
        <ag-grid-angular (gridReady)="onGridReady($event)" style="height: 28rem;  width: 100%;" [rowData]="regimeList"
            class="ag-theme-alpine" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [suppressCellSelection]="true"
            [animateRows]="true">
        </ag-grid-angular>
    </div>
</div>