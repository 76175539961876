
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { MasterData } from 'src/app/constants/master-data/master-data.constant';
import { AttendanceConfiguration, LOPReport, LeaveCycle, probationPeriod } from 'src/app/model/setting/setting.module';
import { LeaveService } from 'src/app/services/leave-application/employee-side/leave-history/leave.service';
import { SettingService } from 'src/app/services/setting/setting.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-setting-configuration',
  templateUrl: './setting-configuration.component.html',
  styleUrls: ['./setting-configuration.component.scss']
})
export class SettingConfigurationComponent implements OnInit {
  leaveTypeError: boolean = false;
  panelOpenState1: boolean = false;
  panelOpenState2: boolean = false;
  panelOpenState3: boolean = false;
  panelOpenState4: boolean = false;
  panelOpenState5: boolean = false;
  panelOpenState6: boolean = false;
  panelOpenState7: boolean = false;

  panelLeaveOpenState: boolean = false;
  public probationPeriodForm!: FormGroup;
  public leaveCycleForm!: FormGroup;
  public probationPeriodRequest!: probationPeriod;
  public createLOPform!: FormGroup;
  public createAttendanceform!: FormGroup;
  noticePeriodForm!: FormGroup;
  employeeIdConfigurationForm!: FormGroup;
  appraisalForm!: FormGroup;

  noticePeriodupdateMode: boolean = false;
  noticePeriodupdate: boolean = false;
  noticePeriodeditMode: boolean = false;
  noticePeriodUpdate: boolean = false;

  employeeIdupdateMode: boolean = false;
  employeeIdupdate: boolean = false;
  employeeIdUpdate: boolean = false;
  employeeIdEditMode: boolean = false;


  appraisalCycleupdateMode: boolean = false;
  appraisalCycleupdate: boolean = false;
  appraisalCycleeditMode: boolean = false;
  appraisalCycleUpdate: boolean = false;

  leaveCycleupdateMode: boolean = false;
  leaveCycleupdate: boolean = false;
  leaveCycleeditMode: boolean = false;
  showLeaveCycleUpdate: boolean = false;


  probationPeriodupdateMode: boolean = false;
  peobationPeriodupdate: boolean = false;
  probationPeriodeditMode: boolean = false;
  showProbationUpdate: boolean = false;


  lopupdateMode: boolean = false;
  lopupdate: boolean = false;
  lopeditMode: boolean = false;
  showLopUpdate: boolean = false;


  attendanceupdateMode: boolean = false;
  attendanceupdate: boolean = false;
  attendanceEditMode: boolean = false;
  showAttendanceUpdate: boolean = false;

  leaveType: any[] = [];

  constructor(private router: Router, private fb: FormBuilder, private leaveService: LeaveService, private settingService: SettingService, private toastrService: ToastrService) { }
  ngOnInit(): void {
    this.initProbationperiodForm();
    this.initLeaveCycleForm();
    this.initLOPForm();
    this.initAttendanceForm();
    this.initNoticePeriodForm();
    this.initEmployeeIdConfiguration();
    this.initAppraisalForm();
    this.getAllSettingData();
    this.listOfLeaves();
  }

  initNoticePeriodForm() {
    this.noticePeriodForm = this.fb.group({
      noticePeriodDays: [null, [Validators.required]]
    })
  }

  initEmployeeIdConfiguration(): void {
    this.employeeIdConfigurationForm = this.fb.group({
      displayId: [null, [Validators.required, Validators.maxLength(6)]]
    });
  }

  maxLengthValidator(maxLength: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value && value.length > maxLength) {
        return { maxLength: true };
      }
      return null;
    };
  }



  initAppraisalForm() {
    this.appraisalForm = this.fb.group({
      appraisalCycle: [null, [Validators.required]]
    })
  }

  probationPeriodEditMode() {
    this.probationPeriodupdateMode = false;
    this.peobationPeriodupdate = true;
    this.probationPeriodeditMode = true;
    this.showProbationUpdate = true;
  }

  leaveEditMode() {
    this.leaveCycleupdate = true;
    this.leaveCycleupdateMode = false;
    this.showLeaveCycleUpdate = true;
    this.leaveCycleeditMode = true;

  }

  LOPEditMode() {
    this.lopupdate = true;
    this.lopupdateMode = false;
    this.lopeditMode = true;
    this.showLopUpdate = true;
  }

  attendanceeditMode() {
    this.attendanceupdateMode = false;
    this.attendanceupdate = true;
    this.attendanceEditMode = true;
    this.showAttendanceUpdate = true;
  }


  appraisalCycleEditMode() {
    this.appraisalCycleupdate = true;
    this.appraisalCycleupdateMode = false;
    this.appraisalCycleUpdate = true;
    this.appraisalCycleeditMode = true;
  }

  noticePeriodEditMode() {
    this.noticePeriodupdate = true;
    this.noticePeriodupdateMode = false;
    this.noticePeriodUpdate = true;
    this.noticePeriodeditMode = true;
  }

  employeeIdConfigEditMode() {
    this.employeeIdupdate = true;
    this.employeeIdupdateMode = false;
    this.employeeIdUpdate = true;
    this.employeeIdEditMode = true;
  }

  initProbationperiodForm() {
    this.probationPeriodForm = this.fb.group({
      'probationPeriod': ['', [Validators.required, Validators.max(365)]],
      'probationPeriodType': ['', Validators.required]
    })
  }

  initLeaveCycleForm() {
    this.leaveCycleForm = this.fb.group({
      'leaveCycle': ['', Validators.required],
      'leaveApplyPastDates': ['', Validators.required],
      'leaveApproveNextDays': ['', Validators.required]
    })
  }

  initLOPForm() {
    this.createLOPform = this.fb.group({
      leaveType: this.fb.array([], Validators.required),
      'unpaidLeaveMarking': ['', Validators.required],
      'maxLossOfPayCount': ['', Validators.required]
    })
  }


  onCheckboxChange(event: any) {
    const leaveTypeArray: FormArray = this.createLOPform.get('leaveType') as FormArray;
    if (event.target.checked) {
      leaveTypeArray.push(this.fb.control(event.target.value));
    } else {
      const index = leaveTypeArray.controls.findIndex(x => x.value === event.target.value);
      leaveTypeArray.removeAt(index);
    }
    this.leaveTypeError = leaveTypeArray.length === 0;
  }


  initAttendanceForm() {
    this.createAttendanceform = this.fb.group({
      'minHrsPerDay': ['', Validators.required],
      'maxHrsPerDay': ['', Validators.required],
      'attendanceSubmittedIn': ['', Validators.required],
    }, { validator: this.hourValidator });
  }

  maxDaysValidator(control: AbstractControl): { [key: string]: any } | null {
    const maxValue = 200;
    if (control.value > maxValue) {
      return { 'maxDaysExceeded': { value: control.value } };
    }
    return null;
  }


  hourValidator(group: FormGroup) {
    const minHrs = group?.get('minHrsPerDay')?.value;
    const maxHrs = group?.get('maxHrsPerDay')?.value;

    if (minHrs !== null && maxHrs !== null && minHrs >= maxHrs) {
      group?.get('maxHrsPerDay')?.setErrors({ 'invalidRange': true });
    } else {
      group?.get('maxHrsPerDay')?.setErrors(null);
    }

    return null;
  }

  probationPeriod() {
    if (this.probationPeriodForm.invalid) {
      this.probationPeriodForm.markAllAsTouched();
      return;
    }
    const probationPeriodRequest: probationPeriod = {
      "probationPeriod": this.probationPeriodForm.value.probationPeriod,
      "probationPeriodType": this.probationPeriodForm.value.probationPeriodType,
      "companyId": sessionStorage.getItem(AppConstant.COMPANY_ID)
    };

    this.settingService.probationPeriod(probationPeriodRequest).subscribe((data: any) => {
      if (data.status == 200) {
        this.toastrService.success(data.message);
        this.probationPeriodupdateMode = true;
        this.getAllSettingData()
      }
    })
  }

  leaveCycle() {
    if (this.leaveCycleForm.invalid) {
      this.leaveCycleForm.markAllAsTouched();
      return;
    }

    const leaveCycle: LeaveCycle = {
      "leaveCycle": this.leaveCycleForm.value.leaveCycle,
      "leaveApplyPastDates": this.leaveCycleForm.value.leaveApplyPastDates,
      "leaveApproveNextDays": this.leaveCycleForm.value.leaveApproveNextDays,
      "companyId": sessionStorage.getItem(AppConstant.COMPANY_ID)
    };

    this.settingService.LeaveCycle(leaveCycle).subscribe((data: any) => {
      if (data.status == 200) {
        this.toastrService.success(data.message);
        this.leaveCycleupdateMode = true
        this.getAllSettingData()
      }
    })
  }

  submitLOPForm() {
    this.leaveTypeError = (this.createLOPform.get('leaveType') as FormArray).length === 0;

    if (this.createLOPform.invalid || this.leaveTypeError) {
      this.createLOPform.markAllAsTouched();
      return;
    }

    const LOPType: LOPReport = {
      "leaveType": this.createLOPform.value.leaveType,
      "unpaidLeaveMarking": this.createLOPform.value.unpaidLeaveMarking,
      "maxLossOfPayCount": this.createLOPform.value.maxLossOfPayCount,
      "companyId": sessionStorage.getItem(AppConstant.COMPANY_ID)

    };

    this.settingService.createLOPReport(LOPType).subscribe((data: any) => {
      if (data.status == 200) {
        this.lopupdateMode = true;
        this.toastrService.success(data.message);
        this.getAllSettingData()
      }
    })
  }

  submitAttendanceForm() {

    if (this.createAttendanceform.invalid) {
      this.createAttendanceform.markAllAsTouched();
      return;
    }

    const attendanceConfiguration: AttendanceConfiguration = {
      "minHrsPerDay": this.createAttendanceform.value.minHrsPerDay,
      "maxHrsPerDay": this.createAttendanceform.value.maxHrsPerDay,
      "attendanceSubmittedIn": this.createAttendanceform.value.attendanceSubmittedIn,
      "companyId": sessionStorage.getItem(AppConstant.COMPANY_ID),
      "offWeekendConfig": ""
    };

    this.settingService.attendanceConfiguration(attendanceConfiguration).subscribe((data: any) => {
      if (data.status == 200) {
        this.toastrService.success(data.message);
        this.attendanceupdateMode = true;
        this.getAllSettingData()

      }
    })
  }

  getAllSettingData() {
    this.settingService.getAllSettings(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((data: any) => {
      if (data.status == 200 && data.data != null) {
        const { probationPeriod, probationPeriodType, leaveCycle, leaveApplyPastDates, leaveApproveNextDays, minHrsPerDay, maxHrsPerDay, attendanceSubmittedIn, offWeekendConfig,
          leaveType, unpaidLeaveMarking, maxLossOfPayCount, appraisalCycle, noticePeriodDays, employeeDisplayId } = data.data;

        this.probationPeriodForm.patchValue({
          probationPeriod: probationPeriod,
          probationPeriodType: probationPeriodType
        });

        this.leaveCycleForm.patchValue({
          leaveCycle: leaveCycle,
          leaveApplyPastDates: leaveApplyPastDates,
          leaveApproveNextDays: leaveApproveNextDays
        })

        this.createAttendanceform.patchValue({
          minHrsPerDay: minHrsPerDay,
          maxHrsPerDay: maxHrsPerDay,
          attendanceSubmittedIn: attendanceSubmittedIn,
          offWeekendConfig: offWeekendConfig
        })

        this.createLOPform.patchValue({
          unpaidLeaveMarking: unpaidLeaveMarking,
          maxLossOfPayCount: maxLossOfPayCount,
        })


        this.createLOPform.patchValue({
          unpaidLeaveMarking: unpaidLeaveMarking,
          maxLossOfPayCount: maxLossOfPayCount,
        })
        const leaveTypeArray: FormArray = this.createLOPform.get('leaveType') as FormArray;
        leaveTypeArray.clear();
        leaveType.forEach((leave: string) => {
          leaveTypeArray.push(new FormControl(leave));
        });

        this.appraisalForm.patchValue({
          appraisalCycle: appraisalCycle
        })

        this.noticePeriodForm.patchValue({
          noticePeriodDays: noticePeriodDays
        })

        this.employeeIdConfigurationForm.patchValue({
          displayId: employeeDisplayId
        })

        if (appraisalCycle != null) {
          this.appraisalCycleupdateMode = true;
        }

        if (noticePeriodDays != null) {
          this.noticePeriodupdateMode = true;
        }


        if (employeeDisplayId != null) {
          this.employeeIdupdateMode = true;
        }

        if (probationPeriod !== null || probationPeriodType !== null) {
          this.probationPeriodupdateMode = true;
        }

        if (leaveCycle !== null || leaveApplyPastDates !== null || leaveApproveNextDays !== null) {
          this.leaveCycleupdateMode = true;
        }

        if (minHrsPerDay !== null || maxHrsPerDay !== null || attendanceSubmittedIn !== null || offWeekendConfig !== null) {
          this.attendanceupdateMode = true;
        }

        if (leaveType !== null || unpaidLeaveMarking !== null || maxLossOfPayCount !== null) {
          this.lopupdateMode = true;

        }
      }
    });
  }

  updateProbationPeriod() {
    if (this.probationPeriodForm.invalid) {
      this.probationPeriodForm.markAllAsTouched();
      return;
    }
    const probationPeriodRequest = {
      probationPeriod: this.probationPeriodForm.value.probationPeriod,
      probationPeriodType: this.probationPeriodForm.value.probationPeriodType,
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID)
    };

    this.settingService.probationPeriod(probationPeriodRequest).subscribe((data: any) => {
      if (data.status == 200) {
        this.toastrService.success(data.message);
        this.getAllSettingData();
        this.probationPeriodeditMode = false;
        this.showProbationUpdate = false;
      }
    });
  }

  updateLeaveCycle() {
    const leaveCycleRequest = {
      leaveCycle: this.leaveCycleForm.value.leaveCycle,
      leaveApplyPastDates: this.leaveCycleForm.value.leaveApplyPastDates,
      leaveApproveNextDays: this.leaveCycleForm.value.leaveApproveNextDays,
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID)
    };

    this.settingService.LeaveCycle(leaveCycleRequest).subscribe((data: any) => {
      if (data.status == 200) {
        this.toastrService.success(data.message);
        this.getAllSettingData();
        this.showLeaveCycleUpdate = false;
        this.leaveCycleeditMode = false;
      }
    });
  }

  updateAttendanceConfiguration() {
    const offWeekendConfigArray = this.createAttendanceform.value.offWeekendConfig || [];
    const offWeekendConfigString = offWeekendConfigArray.join(',');

    const attendanceConfiguration = {
      minHrsPerDay: this.createAttendanceform.value.minHrsPerDay,
      maxHrsPerDay: this.createAttendanceform.value.maxHrsPerDay,
      attendanceSubmittedIn: this.createAttendanceform.value.attendanceSubmittedIn,
      offWeekendConfig: offWeekendConfigString,
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID)
    };

    this.settingService.attendanceConfiguration(attendanceConfiguration).subscribe((data: any) => {
      if (data.status == 200) {
        this.toastrService.success(data.message);
        this.getAllSettingData();
        this.attendanceEditMode = false;
        this.showAttendanceUpdate = false;
      }
    });
  }

  lossOfPayUpdate() {
    const lossOfPay = {
      leaveType: this.createLOPform.value.leaveType,
      unpaidLeaveMarking: this.createLOPform.value.unpaidLeaveMarking,
      maxLossOfPayCount: this.createLOPform.value.maxLossOfPayCount,
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID)
    };

    this.settingService.createLOPReport(lossOfPay).subscribe((data: any) => {
      if (data.status == 200) {
        this.toastrService.success(data.message);
        this.getAllSettingData();
        this.lopeditMode = false;
        this.showLopUpdate = false;
      }
    });
  }

  cancelProbationPeriod() {
    this.settingService.getAllSettings(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((data: any) => {
      if (data.status == 200) {
        const { probationPeriod, probationPeriodType
        } = data.data;

        this.probationPeriodForm.patchValue({
          probationPeriod: probationPeriod,
          probationPeriodType: probationPeriodType
        });
      }

    })
    this.probationPeriodupdateMode = false;
  }

  cancelLOP() {
    this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.GENERAL_SETTING])
    this.settingService.getAllSettings(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((data: any) => {
      if (data.status == 200) {
        const { leaveType, unpaidLeaveMarking, maxLossOfPayCount } = data.data;
        this.createLOPform.patchValue({
          leaveType: leaveType,
          unpaidLeaveMarking: unpaidLeaveMarking,
          maxLossOfPayCount: maxLossOfPayCount,
        })
      }

    })
    this.lopupdateMode = false;
  }

  cancelAttendace() {
    this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.GENERAL_SETTING])
    this.settingService.getAllSettings(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((data: any) => {
      if (data.status == 200) {
        const { minHrsPerDay, maxHrsPerDay, attendanceSubmittedIn, offWeekendConfig } = data.data;

        this.createAttendanceform.patchValue({
          minHrsPerDay: minHrsPerDay,
          maxHrsPerDay: maxHrsPerDay,
          attendanceSubmittedIn: attendanceSubmittedIn,
          offWeekendConfig: offWeekendConfig,

        })
      }
    })
    this.attendanceupdateMode = false;
  }

  cancelLeaveCycle() {
    this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.GENERAL_SETTING])
    this.settingService.getAllSettings(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((data: any) => {
      if (data.status == 200) {
        const { leaveCycle, leaveApplyPastDates, leaveApproveNextDays } = data.data;
        this.leaveCycleForm.patchValue({
          leaveCycle: leaveCycle,
          leaveApplyPastDates: leaveApplyPastDates,
          leaveApproveNextDays: leaveApproveNextDays
        })
      }
    })
    this.leaveCycleupdateMode = false;
  }

  onlyNumberKey(event: any) {
    return ValidationUtil.validateNumber(event);
  }

  AallowedNumberAndChar(event: any) {
    return ValidationUtil.allowedOnlyCharacterAndNumber(event);
  }

  onlyNumberKeys(event: any) {
    const value = event.target.value;

    if (parseInt(value + event.key, 10) > 200) {
      event.preventDefault();
    }
  }

  updateMinHoursError() {
    const minHrsPerDayControl = this.createAttendanceform.get('minHrsPerDay');
    if (minHrsPerDayControl?.value > 24) {
      minHrsPerDayControl?.setErrors({ 'maxHoursExceeded': true });
    } else {
      minHrsPerDayControl?.setErrors(null);
    }
  }


  hoursValidator(formGroup: FormGroup) {
    const minHrsPerDay = formGroup.get('minHrsPerDay')?.value;
    const maxHrsPerDay = formGroup.get('maxHrsPerDay')?.value;

    if (minHrsPerDay && maxHrsPerDay && maxHrsPerDay < minHrsPerDay) {
      formGroup.get('maxHrsPerDay')?.setErrors({ 'maxLessThanMin': true });
    }
  }

  updateMaxHoursError() {
    const minHrsPerDay = this.createAttendanceform?.get('minHrsPerDay')?.value;
    const maxHrsPerDayControl = this.createAttendanceform.get('maxHrsPerDay');
    const maxHrsPerDayValue = maxHrsPerDayControl?.value;

    if (maxHrsPerDayValue > 24) {
      maxHrsPerDayControl?.setErrors({ 'maxHoursExceeded': true });
    } else {
      maxHrsPerDayControl?.setErrors(null);
    }

    if (minHrsPerDay && maxHrsPerDayValue && maxHrsPerDayValue < minHrsPerDay) {
      maxHrsPerDayControl?.setErrors({ 'maxLessThanMin': true });
    } else if (!maxHrsPerDayControl?.hasError('maxHoursExceeded')) {
      maxHrsPerDayControl?.setErrors(null);
    }
  }

  maxGreaterThanMinValidator(formGroup: FormGroup) {
    const minHrsPerDay = formGroup.get('minHrsPerDay')?.value;
    const maxHrsPerDay = formGroup.get('maxHrsPerDay')?.value;

    if (minHrsPerDay && maxHrsPerDay && maxHrsPerDay < minHrsPerDay) {
      formGroup.get('maxHrsPerDay')?.setErrors({ 'maxLessThanMin': true });
    }
  }

  saveAppraisal() {
    if (this.appraisalForm.invalid) {
      this.appraisalForm.markAllAsTouched();
      return;
    }
    const dto: any = {
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      appraisalCycle: this.appraisalForm.value.appraisalCycle
    }
    this.settingService.saveAppraisal(dto).subscribe(
      (data: any) => {
        if (data.status == 200) {
          this.toastrService.success(data.message);
          this.getAllSettingData();
          this.appraisalCycleUpdate = false;
          this.appraisalCycleeditMode = false;
        }
      }
    )
  }

  cancelAappraisalCycle() {
    this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.GENERAL_SETTING])
    this.settingService.getAllSettings(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((data: any) => {
      if (data.status == 200) {
        const { appraisalCycle } = data.data;
        this.appraisalForm.patchValue({
          appraisalCycle: appraisalCycle
        })
      }
    })
    this.appraisalCycleupdateMode = false;
  }

  saveNoticePeriod() {
    if (this.noticePeriodForm.invalid) {
      this.noticePeriodForm.markAllAsTouched();
      return;
    }
    const dto: any = {
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      noticePeriodDays: this.noticePeriodForm.value.noticePeriodDays
    }
    this.settingService.saveNoticePeriod(dto).subscribe(
      (data: any) => {
        if (data.status == 200) {
          this.toastrService.success(data.message);
          this.getAllSettingData();
          this.noticePeriodUpdate = false;
          this.noticePeriodeditMode = false;
        }
      }
    )
  }

  saveEmployeeIdConfiguration() {
    if (this.employeeIdConfigurationForm.invalid) {
      this.employeeIdConfigurationForm.markAllAsTouched();
      return;
    }
    const dto: any = {
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      displayId: this.employeeIdConfigurationForm.value.displayId
    }
    this.settingService.saveEmployeeIdConfiguration(dto).subscribe(
      (data: any) => {
        if (data.status == 200) {
          this.toastrService.success(data.message);
          this.getAllSettingData();
          this.employeeIdUpdate = false;
          this.employeeIdEditMode = false;
        }
      }
    )
  }

  cancelNoticePeriod() {
    this.settingService.getAllSettings(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((data: any) => {
      if (data.status == 200) {
        const { noticePeriodDays } = data.data;
        this.noticePeriodForm.patchValue({
          noticePeriodDays: noticePeriodDays
        })
      }
    })
    this.noticePeriodupdateMode = false;
  }

  cancelEmployeeIdConfig() {
    this.settingService.getAllSettings(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((data: any) => {
      if (data.status == 200) {
        const { employeeDisplayId } = data.data;
        this.employeeIdConfigurationForm.patchValue({
          displayId: employeeDisplayId
        })
      }
    })
    this.employeeIdupdateMode = false;
  }

  listOfLeaves() {
    this.leaveService.getAllLeave(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((res: any) => {
      if (res.length > 0) {
        this.leaveType = res;
      } else {
        this.leaveType = MasterData.USER_DATA;
      }
    });
  }

  isLeaveTypeSelected(leaveType: string): boolean {
    const leaveTypeArray: FormArray = this.createLOPform.get('leaveType') as FormArray;
    return leaveTypeArray.controls.some(control => control.value === leaveType);
  }

}