<div class="setting__main_container">
    <div class="setting__heading_container">
        <h3>{{ 'role_list' | translate }}</h3>
        <div class="setting__header_button_container">
            <button color="primary" (click)="toggleAddPopup()">{{ 'add_roles' | translate
                }}</button>
        </div>
    </div>
    <ag-grid-angular (gridReady)="onGridReady($event)" style="height: 27rem; width: 100%;" class="ag-theme-alpine"
        [rowData]="roleData" [rowSelection]="'multiple'" [animateRows]="true" [columnDefs]="roleTableCol"
        [defaultColDef]="roleColDef" [enableSorting]="true" [enableFilter]="true" [pagination]="true"
        [paginationPageSize]="10">
    </ag-grid-angular>
</div>

<div class="overlay" *ngIf="showAddPopup">
    <div class="add-popup">
        <div class="container">
            <div class="header">
                <h3 class="heading">{{ ( addRoleHeader ? 'add_roles' : 'update_roles' ) | translate }} </h3>
            </div>
            <form [formGroup]="addRoleForm" id="departmentForm">
                <div>
                    <mat-form-field class="form-field" appearance="outline">
                        <mat-label>{{ 'role_name' | translate }}</mat-label>
                        <input matInput formControlName="roleName" type="text"
                            (keypress)="validateCharacterNumberAndSpace($event)" [maxLength]="30" />
                        <mat-error *ngIf="!addRoleForm.controls['roleName'].valid">
                            {{ 'role_name_is_required'| translate }}</mat-error>
                    </mat-form-field>
                </div>

                <div class="role-container">
                    <mat-form-field class="form-field" appearance="outline">
                        <mat-label>{{ 'role_description' | translate }}</mat-label>
                        <input matInput formControlName="roleDescription" type="text"
                            (keypress)="validateCharacterNumberAndSpace($event)" [maxLength]="30" />
                        <mat-error *ngIf="!addRoleForm.controls['roleDescription'].valid">
                            {{ 'role_description_is_required'| translate }}</mat-error>
                    </mat-form-field>
                </div>

                <div class="role-container">
                    <mat-form-field class="form-field" appearance="outline">
                        <mat-label>{{ 'report_to' | translate }}</mat-label>
                        <mat-select matInput formControlName="roleReportTo" class="select-mat-input">
                            <mat-option *ngFor="let roleName of roleList" [value]="roleName.id">
                                {{ roleName.roleName }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="!addRoleForm.controls['roleReportTo'].valid">
                            {{ 'role_reporting_is_required'| translate }}</mat-error>
                    </mat-form-field>
                </div>
                <app-loader></app-loader>
                <div class="pupupButtons">
                    <button (click)="toggleAddPopup()" class="closeDialog cancel-btn">
                        {{'cancel' | translate}}</button>
                    <button (click)="isAddMode ? addRoles() : updateRole()">
                        {{ isAddMode ? ('save' | translate) : ('update' | translate) }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>