import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_END_POINTS } from '../../config/api-endpoint.config';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/enviroment';
import { UpdateDto } from '../../model/salary-structure.model';
import { AppConstant } from 'src/app/constants/app.constants';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SalaryStructureService {

  constructor(private http: HttpClient, private toastrService: ToastrService) { }

  getSalaryStructure(companyId: any, typeOfStructure: string): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_SALARY_STRUCTURE + companyId + AppConstant.SEPERATOR + typeOfStructure).pipe(
      map((res: any) => {
        if (res.status === 404) {
          this.toastrService.clear();
          return this.toastrService.error(res.message);
        }
        else {
          return res.data;
        }
      }));
  }

  toggleStatus(update: UpdateDto): Observable<any> {
    return this.http.put(environment.apiUrl + API_END_POINTS.UPDATE_STATUS, update).pipe(
      map((res: any) => { return (res) }));
  }

  addComponents(update: UpdateDto): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_COMPONENT, update).pipe(
      map((res: any) => { return (res) }));
  }

  updateColumnValue(update: UpdateDto) {
    return this.http.put(environment.apiUrl + API_END_POINTS.UPDATE_COLUMN, update).pipe(
      map((res: any) => { return (res) }));
  }

  delete(update: UpdateDto) {
    return this.http.put(environment.apiUrl + API_END_POINTS.DELETE_COLUMN, update).pipe(
      map((res: any) => { return (res) }));
  }

  salaryBreakupData(companyId: any, empId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.SALARY_BREAKUP_DATA + companyId + AppConstant.SEPERATOR + empId).pipe(
      map((res: any) => { return (res.data) }));
  }
}