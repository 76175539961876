import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { CompanyProfileService } from 'src/app/services/company-profile/company-profile.service';
import { AppConstant } from 'src/app/constants/app.constants';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(private translate: TranslateService,private companyProfileService:CompanyProfileService) {  }
  companyLogo: any;
  companyName: any;
  
ngOnInit(): void {
  this.getCompanyLogo();
}

  switchLanguage = (event: any) => {
    this.translate.use(event.target.value);
  }
  getCompanyLogo() {
    this.companyProfileService.getCompanyData(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        this.companyLogo = 'data:image/png;base64,' + data.data.logo;
        this.companyName = data.data.companyName
      }
    )
  }
}
