import { HttpClient } from "@angular/common/http";
import { AfterViewInit, Component, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AppConstant } from "src/app/constants/app.constants";
import { AttendanceService } from "src/app/services/attendance/attendance.service";
import { ProjectManagementService } from "src/app/services/project-management/project-management.service";

@Component({
  selector: 'app-selfie-capture',
  templateUrl: './selfie-capture.component.html',
  styleUrls: ['./selfie-capture.component.scss']
})

export class SelfieCaptureComponent implements AfterViewInit {

  @ViewChild('video', { static: false }) video!: ElementRef;
  @ViewChild('canvas', { static: false }) canvas!: ElementRef;
  imageUrl: string | null = null;
  private stream: MediaStream | null = null;
  selectedProject: string | null = null;
  selectedShift: string | null = null;
  liveLocation: string = '';
  selfieConfirmed: boolean = false;
  projects: any;
  shifts = ['Morning', 'Afternoon', 'Night'];
  punchType: any;

  constructor(private readonly toasterService: ToastrService, private readonly route: ActivatedRoute,private readonly attendanceService: AttendanceService, private readonly projectManagementService: ProjectManagementService, private readonly router: Router, private readonly http: HttpClient) { }

  ngAfterViewInit() {
    this.startCamera();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.punchType = params['type'] || 'PUNCH_IN';
    });
    this.getAllProject();
    this.getLiveLocation();
  }

  getAllProject() {
    this.projectManagementService.getAllProject().subscribe((data: any) => {
      if (data.status === 200) {
        this.projects = data.data;
      }
    })
  }

  startCamera() {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        this.stream = stream;
        this.video.nativeElement.srcObject = stream;
      })
  }

  stopCamera() {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      this.stream = null;
    }
  }

  capturePhoto() {
    if (this.selfieConfirmed) return;
    const videoElement = this.video.nativeElement;
    const canvasElement = this.canvas.nativeElement;
    const context = canvasElement.getContext('2d');
    canvasElement.width = videoElement.videoWidth;
    canvasElement.height = videoElement.videoHeight;
    context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);
    this.imageUrl = canvasElement.toDataURL('image/png');
    this.stopCamera();
  }

  retakePhoto() {
    if (this.selfieConfirmed) return;

    this.imageUrl = null;
    this.startCamera();
  }

  confirmSelfie() {
    this.selfieConfirmed = true;
  }

  cancel() {
    this.stopCamera();
    this.router.navigate([AppConstant.MOBILE + AppConstant.SEPERATOR + AppConstant.MOBILE_DASHBOARD]);
  }

  getLiveLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.liveLocation = `${position.coords.latitude},${position.coords.longitude}`;
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }

  dataURLtoFile(dataUrl: string, filename: string): File {
    const arr = dataUrl.split(',');
    const mimeMatch = RegExp(/:(.*?);/).exec(arr[0]);
    if (!mimeMatch) {
      throw new Error("Invalid data URL format");
    }
    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }


  submitPunchInOut() {
    if (!this.imageUrl || !this.liveLocation) {
      alert("Please allow location and camera access.");
      return;
    }
    if (this.punchType === "PUNCH_IN") {
      if (!this.selectedShift) {
        alert("Please add a project and shift.");
        return;
      }
    }
    let currentDatetime = new Date();
    const adjustedDateTime = new Date(currentDatetime.getTime() + (5.5 * 60 * 60 * 1000));

    const formData = new FormData();
    formData.append("companyId", String(sessionStorage.getItem(AppConstant.COMPANY_ID)));
    formData.append("employeeId", String(sessionStorage.getItem(AppConstant.EMPLOYEE_ID)));
    formData.append("status", this.punchType);
    formData.append("dateTime", adjustedDateTime.toISOString());
    formData.append("liveLocation", this.liveLocation);
    formData.append("project", this.selectedProject || '0');
    formData.append("shift", this.selectedShift || '');
    const selfieFile = this.dataURLtoFile(this.imageUrl, "selfie.png");
    formData.append("selfie", selfieFile);
    this.attendanceService.punchInOrOut(formData).subscribe(
      (punchInOrOutResponse: any) => {
        if (punchInOrOutResponse.status === 200) {
          this.toasterService.clear();
          this.toasterService.success(punchInOrOutResponse.message);
          this.router.navigate([AppConstant.MOBILE + AppConstant.SEPERATOR + AppConstant.MOBILE_DASHBOARD]);
        } else {
          this.toasterService.clear();
          this.toasterService.error(punchInOrOutResponse.message);
        }
      }
    )
  }
}
