import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { requestorInformationModule } from 'src/app/model/help-desk/help-desk.model';
import { ApprovalService } from 'src/app/services/approval/approval.service';
import { HelpDeskService } from 'src/app/services/help-desk/help-desk.service';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { Employees } from '../../admin/add-approver/add-approver.component';
import { ReplaySubject, Subject, debounceTime, delay, filter, map, takeUntil, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-requestor-information',
  templateUrl: './requestor-information.component.html',
  styleUrls: ['./requestor-information.component.scss']
})
export class RequestorInformationComponent implements OnInit {
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  requestType = [
    'Contractor',
    'Non Contractor',
    'Project Management'
  ]
  businessService = [
    'Asset Requirement',
    'Document Requirement',
    'Desk Allocation',
    'Surrender Assets'
  ]
  requestorInformationForm!: FormGroup;
  search = false;
  employeeList: Employees[] = [];
  filterEmployeeList: Employees[] = [];
  personSearchFilter: FormControl<any> = new FormControl<any>('');
  _onDestroy = new Subject<string>();
  searching: boolean = false;
  public filteredServerSide: ReplaySubject<Employees[]> = new ReplaySubject<Employees[]>(1);
  constructor(private toasterService:ToastrService, private approvalService: ApprovalService,private fb: FormBuilder, private router: Router, private helpDeskService: HelpDeskService) { }
  projectList: any;
  ngOnInit(): void {
        //get employeeList
        const companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
        const employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
        this.approvalService.getEmployees(companyId, employeeId).subscribe((res: any) => {
          if (res.status == 200 && res.data) {
            this.employeeList = res.data;
            this.filterEmployeeList = res.data;
          }
        });
    
        this.personSearchFilter.valueChanges
          .pipe(
            filter(search => !!search),
            tap(() => this.searching = true),
            takeUntil(this._onDestroy),
            debounceTime(2000),
            map(search => {
              this.search = search;
              if (!this.employeeList) {
                return [];
              }
              return this.employeeList.filter(employee => employee.employeeName.toLowerCase().indexOf(search) > -1);
            }),
            delay(500),
            takeUntil(this._onDestroy)
          )
          .subscribe(filteredEmployees => {
            if (filteredEmployees.length != 0) {
              this.searching = false;
              this.filteredServerSide.next(filteredEmployees);
            } else { 
              this.approvalService.searchEmployee(companyId, this.search).subscribe((res: any) => {
                if (res.status == 200 && res.data && res.data.length > 0) {
                  this.searching = false;
                  this.filteredServerSide.next(filteredEmployees);
                } else {
                  this.searching = false;
                }
              })
            }
    
          },
            error => {
              this.searching = false;
            });
    
    this.initCreateVoucher();
    this.getProjectList();
  }
  initCreateVoucher() {
    this.requestorInformationForm = this.fb.group({
      'requestFor': ['', Validators.required],
      'contactNo': ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10), ValidationUtil.validMobileNumber()]],
      'projectId': [''],
      'location': ['', Validators.required],
      'businessService': ['', [Validators.required]],
      'requestType': ['', Validators.required],
      'detailedDescription': ['', Validators.required]
    })
  }

  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumber(event);
  }

  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

  goBack() {
    this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.HELPDESK]);
  }

  submit() {
    if (this.requestorInformationForm.valid) {
      const employeeId = this.requestorInformationForm.value.requestFor.replace('EMP', '');

      const requestorInformation: requestorInformationModule = {
        "companyId": this.companyId,
        "type": "Request",
        "requestFor":employeeId,
        "contactNo": this.requestorInformationForm.value.contactNo,
        "projectId": this.requestorInformationForm.value.projectId,
        "location": this.requestorInformationForm.value.location,
        "businessService": this.requestorInformationForm.value.businessService,
        "requestType": this.requestorInformationForm.value.requestType,
        "detailedDescription": this.requestorInformationForm.value.detailedDescription
      };

      this.helpDeskService.requestInformation(requestorInformation).subscribe((data: any) => {
        if (data.status == 200) {
          this.toasterService.success(data.message);
          this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_REQUESTOR_INFORMATION]);
        }else{
          this.toasterService.error(data.message);
        }
      })
    }
  }

  getProjectList() {
    this.helpDeskService.getAllProjectList(this.companyId).subscribe((data: any) => {
      this.projectList = data.data;
    })
  }
}
