import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { TraningService } from 'src/app/services/traning/traning.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-view-training-details',
  templateUrl: './view-training-details.component.html',
  styleUrls: ['./view-training-details.component.scss']
})
export class ViewTrainingDetailsComponent implements OnInit {
  trainingId: any;
  trainingDetails: any;
  fileUrl!: SafeResourceUrl; // Change the type to SafeResourceUrl
  ratingForm!: FormGroup;
  comments: any[] = [];
  displayComments: any[] = [];
  showComments: boolean = false;
  rating!: number;
  stars = [
    { id: 1, icon: 'star', class: 'star-gray star' },
    { id: 2, icon: 'star', class: 'star-gray star' },
    { id: 3, icon: 'star', class: 'star-gray star' },
    { id: 4, icon: 'star', class: 'star-gray star' },
    { id: 5, icon: 'star-gray star' }
  ];
  showMoreComment: boolean = false;
  commentsToShow: number = 5;
  showPdf: boolean = false;

  constructor(
    private toasterService: ToastrService,
    private router: Router,
    private trainingService: TraningService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer // Add DomSanitizer to the constructor
  ) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.trainingId = params['id'];
      this.getTrainingDetails(this.trainingId);
      this.getComments(this.trainingId, this.showMoreComment);
      this.getCommentByEmployeeId();
    });

    this.ratingForm = this.fb.group({
      comment: ['', Validators.required]
    });
  }

  onRatingChange(rating: number) {
    if (rating > 0) {
      this.showComments = true;
      this.rating = rating;
    }
  }

  getCommentByEmployeeId() {
    this.trainingService.getCommentByEmployeeId(this.trainingId).subscribe(
      (data: any) => {
        if (data.status === 200) {

        }
      });
  }

  getTrainingDetails(trainingId: any) {
    this.trainingService.getTrainingDetails(trainingId).subscribe(
      (data: any) => {
        if (data && data.status === 200 && data.data) {
          this.trainingDetails = data.data;
          if (this.trainingDetails?.file?.fileType === 'application/pdf') {
            this.showPdf = true;
          }
          this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.trainingDetails?.file?.url);
        }
      });
  }

  getComments(trainingId: any, showMoreComment: boolean): void {
    this.trainingService.getComments(trainingId, showMoreComment).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.displayComments = data.data;
        }
      });
  }

  goToBack() {
    this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_DETAILS_OF_TRAINING + AppConstant.SEPERATOR + this.trainingId]);
  }

  cancel() {
    this.ratingForm.patchValue({
      rating: 0,
    });
  }

  onSubmit(): void {
    if (this.ratingForm.valid) {
      const dto = {
        rating: this.rating,
        companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
        empId: sessionStorage.getItem(AppConstant.EMPLOYEE_ID),
        trainingId: this.trainingId,
        comments: this.ratingForm.value.comment,
      };
      this.trainingService.saveRatingAndComment(dto).subscribe(
        (data: any) => {
          if (data.status === 200) {
            this.getCommentByEmployeeId();
            this.toasterService.clear();
            this.toasterService.success(data.message);
          }
          else {
            this.toasterService.clear();
            this.toasterService.error(data.message);
          }
        }
      );
    }
  }

  showMoreComments(): void {
    this.showMoreComment = !this.showMoreComment;
    if (this.showMoreComment) {
      this.getComments(this.trainingId, this.showMoreComment);
    }
  }
}
