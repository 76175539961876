import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { FamilyAndEmergencyContactsService } from 'src/app/services/family-and-emergency-contacts/family-and-emergency-contacts.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationPopupComponent } from 'src/app/shared/confirmation-popup/confirmation-popup.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-family-and-emergency-contact-info',
  templateUrl: './family-and-emergency-contact-info.component.html',
  styleUrls: ['./family-and-emergency-contact-info.component.scss']
})

export class FamilyAndEmergencyContactInfoComponent implements OnInit {
  familyAndEmergencyContactInformation: any[] = [];
  emergencyContactId: any;
  isEditMode: boolean = true;
  @Input() showAddButton: boolean = true;
  @Input() showActionColumn: boolean = true;
  familyAndEmergencycolumnDefs: any;

  constructor(private router: Router, public dialog: MatDialog, private renderer: Renderer2, private route: Router, private familyAndEmergencyContactService: FamilyAndEmergencyContactsService, private toasterService: ToastrService, private translateService : TranslateService) { }

  ngOnInit(): void {
    this.getFamilyAndEmergencyContacts();
    this.setupGridColumns();
  }

  getFamilyAndEmergencyContacts() {
    this.familyAndEmergencyContactService.getEmployeeFamilyAndEmergencyContacts(sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
      (emergencyContactData: any) => {
        this.familyAndEmergencyContactInformation = emergencyContactData.data;
      });
  }

  setupGridColumns() {
    this.familyAndEmergencycolumnDefs = [
      { field: "fullName", headerName: "Name", filter: true, floatingFilter: true, sortable: true, unSortIcon: true, resizable: true, flex: 1, valueGetter: (params: any) => `${params.data.firstName} ${params.data.lastName}` },
      { field: "relationship", headerName: "Relationship", filter: true, floatingFilter: true, sortable: true, unSortIcon: true, resizable: true, flex: 1 },
      { field: "contactNumber", headerName: "Contact Number", filter: true, floatingFilter: true, sortable: true, unSortIcon: true, resizable: true, flex: 1 },
      { field: "email", headerName: "Email", filter: true, floatingFilter: true, sortable: true, unSortIcon: true, resizable: true, flex: 1 },
      { field: "emergencyContact", headerName: "Emergency Contact", filter: true, floatingFilter: true, sortable: true, unSortIcon: true, resizable: true, flex: 1, valueGetter: (params: any) => params.data.isEmergencyContact ? 'Yes' : 'No' }
    ];

    if (this.showActionColumn) {
      this.familyAndEmergencycolumnDefs.push({
        headerName: "Action",
        cellRenderer: this.actionCellRenderer.bind(this),
      });
    }
  }

  addFamilyAndEmergencyContacts() {
    this.route.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.ADD_FAMILY_AND_EMERGENCY_CONTACTS]);
  }

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      if (icon === 'delete') {
        this.renderer.setStyle(iconElement, 'margin-right', '15px');
      }
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setAttribute(button, 'MatTooltipModule', 'toolTipText');
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };

    const editClickHandler = () => {
      this.emergencyContactId = params.data.id;
      this.isEditMode = false;
      this.router.navigate([`${AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.ADD_FAMILY_AND_EMERGENCY_CONTACTS + AppConstant.SEPERATOR + params.data.id}`], {
      });
    };

    const visibilityClickHandler = () => {
      this.router.navigate([`${AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_FAMILY_AND_EMERGENCY_DETAILS + AppConstant.SEPERATOR + params.data.id}`], {
      });
    };

    const deleteClickHandler = () => {
      this.deleteEmergencyContact(params.data.id);
    };

    createButton('visibility', 'green', visibilityClickHandler);
    createButton('edit', '#59595b', editClickHandler,);
    createButton('delete', 'red', () => this.deleteFamilyMemberContact(params.data.id));
    return cell;
  }

  deleteFamilyMemberContact(currentEditingRow: any) {
    const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
      disableClose: true,
      data: this.translateService.instant('are_you_sure_you_want_to_delete_this_emergency_contact')
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.data) { this.deleteEmergencyContact(currentEditingRow); }
    });
  }

  deleteEmergencyContact(contactId: any) {
    this.familyAndEmergencyContactService.deleteEmergencyContact(contactId).subscribe(
      (emergencyContactData: any) => {
        if (emergencyContactData.status == 200) {
          this.getFamilyAndEmergencyContacts();
          this.toasterService.clear();
          this.toasterService.success(emergencyContactData.message);
        }
        else {
          this.toasterService.clear()
          this.toasterService.error(emergencyContactData.message);
        }
      }
    );
  }
}


