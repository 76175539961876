import { Component, Inject, Input } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-add-bulk-employee',
  templateUrl: './add-bulk-employee.component.html',
  styleUrls: ['./add-bulk-employee.component.scss']
})
export class AddBulkEmployeeComponent {
  @Input() value: number | undefined;
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }
  truncateProgress(progress: number): number {
    return Math.floor(progress);
  }
}
