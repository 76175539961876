import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { ResignationService } from 'src/app/services/resignation/resignation.service';

@Component({
  selector: 'app-exit-history',
  templateUrl: './exit-history.component.html',
  styleUrls: ['./exit-history.component.scss']
})
export class ExitHistoryComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<ExitHistoryComponent>, private resignationService: ResignationService, private datePipe: DatePipe, private employeeService: EmployeeDetailsService, private router: Router) { }
  lastWorkingDate: any;
  finalSettlementDate: any;
  showFinalSettelemtDate: any;
  showLastWorkingDate: any;
  activeEmployeeData: any;
  dateOfJoining: any;
  ngOnInit(): void {
    this.getResignagtionDetails();
    this.getActiveEmployeeDetails();
  }
  getResignagtionDetails() {
    this.resignationService.getEmployeeResignationInfo(sessionStorage.getItem('companyId'), sessionStorage.getItem('employeeId')).subscribe((data: any) => {
      this.lastWorkingDate = new Date(data.data.lastWorkingDay);
      this.finalSettlementDate = this.datePipe.transform(data.data.finalSettlementDate, 'dd/MM/yyyy');
      this.lastWorkingDate = this.datePipe.transform(data.data.lastWorkingDay, 'dd/MM/yyyy');

      this.showFinalSettelemtDate = data.data.finalSettlementDate;
      this.showLastWorkingDate = data.data.lastWorkingDay;
    })
  }

  getActiveEmployeeDetails() {
    this.employeeService.getActiveEmployeeDetails(sessionStorage.getItem('companyId'), sessionStorage.getItem('employeeId')).subscribe((data: any) => {
      this.dateOfJoining = this.datePipe.transform(data?.employeeBasicDetail?.doj, 'dd/MM/yyyy');
    });
  }


  closepopup(): void {
    this.dialogRef.close();
  }
}
