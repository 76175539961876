import { Component, OnInit } from '@angular/core';
import { AppConstant } from 'src/app/constants/app.constants';
import { EmployeeDetailsForDeclaration } from 'src/app/model/employeeDetails';
import { SalaryService } from 'src/app/services/salary-details/salaryService';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-view-tax-declaration',
  templateUrl: './view-tax-declaration.component.html',
  styleUrls: ['./view-tax-declaration.component.scss']
})
export class ViewTaxDeclarationComponent implements OnInit {

  employeeDetails: EmployeeDetailsForDeclaration | undefined;

  constructor(private datePipe: DatePipe, private employeeService: SalaryService) { }

  ngOnInit(): void {
    this.getEmployeeDetails();
  }

  getEmployeeDetails() {
    this.employeeService.getEmployeeDetails(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
      (data: any) => {
        const dto: EmployeeDetailsForDeclaration = {
          employeeName: data.data.employeeBasicDetail?.firstName + " " + data.data.employeeBasicDetail?.lastName,
          dateOfJoining: this.datePipe.transform(data.data.employeeBasicDetail?.doj, 'MM/dd/yyyy'),
          dateOfBirth: this.datePipe.transform(data.data.employeePersonalDetail?.dob, 'MM/dd/yyyy'),
          gender: data.data.employeeBasicDetail?.gender,
          pan: data.data.employeePersonalDetail?.panNo,
          employementStatus: data.data.employeeBasicDetail?.active
        }
        this.employeeDetails = dto;
      }
    )
  }

  toggleExpansionPanel(item: any): void {
    item.expanded = !item.expanded;
  }

  getInitials(name: string): any {
    if (name) {
      const nameArray = name.split(' ');
      if (nameArray.length >= 2) {
        return nameArray[0].charAt(0).toUpperCase() + nameArray[1].charAt(0).toUpperCase();
      } else if (nameArray.length === 1) {
        return nameArray[0].charAt(0).toUpperCase();
      } else {
        return '';
      }
    }
  }

}
