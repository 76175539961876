import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Subject, debounceTime, filter, map, takeUntil, tap } from 'rxjs';
import { AppConstant } from 'src/app/constants/app.constants';
import { AssetManagementDashboardService } from 'src/app/services/asset-management/asset-management-dashboard/asset-management-dashboard.service';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'app-assign-assets-popup',
  templateUrl: './assign-assets-popup.component.html',
  styleUrls: ['./assign-assets-popup.component.scss']
})
export class AssignAssetsPopupComponent implements OnInit {
  assignedAssetForm !: FormGroup
  employeeList: any[] = [];
  assets: string[] = ['Laptop', 'Mouse', 'Keyboard', 'Monitor'];
  assetList: any[] = [];
  searchControl: FormControl = new FormControl();
  searching: boolean = false;
  filteredResults: any[] = [];
  private _onDestroy = new Subject<void>();

  constructor(private formBuilder: FormBuilder, private loader: LoaderService, private employeeService: EmployeeDetailsService, private matDialog: MatDialog, private addAssetService: AssetManagementDashboardService, private toasterService: ToastrService,) { }

  ngOnInit(): void {
    this.getAllActiveEmployee();
    this.getAssetTypeList();
    this.assignedAssetForm = this.formBuilder.group({
      employeeId: ["", [Validators.required]],
      assetType: ["", [Validators.required]],
      modelNo: ["", [Validators.required]],
    });

    this.assignedAssetForm.get('assetType')?.valueChanges.subscribe
      ((value: any) => {
        this.getAssetListByAssetType(value);
      })

    this.searchControl.valueChanges.pipe(
      filter(search => !!search),
      tap(() => this.searching = true),
      debounceTime(300),
      map(search => search.toLowerCase()),
      takeUntil(this._onDestroy)
    ).subscribe(search => {
      this.filteredResults = this.employeeList.filter(item => item.name.toLowerCase().includes(search));
      this.searching = false;
    });
  }

  closeDialog() {
    this.matDialog.closeAll();
  }

  getAssetTypeList() {
    this.addAssetService.getAssetTypeList().subscribe(
      (data: any) => {
        if (data.status) {
          this.assets = data.data;
        }
      }
    );
  }

  getAssetListByAssetType(assetType: any) {
    this.addAssetService.getAssetListByAssetType(assetType).subscribe(
      (data: any) => {
        if (data.status) {
          this.assetList.push(...data.data);
        }
      }
    );
  }

  getAllActiveEmployee() {
    this.employeeService.getEmployeeList('activeEmployee', sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.employeeList = data.data.map((employee: any) => {
            return {
              id: employee.id,
              name: employee.firstName + " " + employee.lastName
            }
          });
        }
      }
    )
  }

  assignAsset() {
    if (this.assignedAssetForm.valid) {
      this.loader.show();
      const dto = {
        employeeId: this.assignedAssetForm.value.employeeId,
        companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
        assetType: this.assignedAssetForm.value.assetType,
        modelNo: this.assignedAssetForm.value.modelNo,
      };
      this.addAssetService.assignAssetForm(dto).subscribe(
        (data: any) => {
          if (data.status == 200) {
            this.loader.hide();
            this.closeDialog();
            this.toasterService.clear();
            this.toasterService.success(data.message);
          }
          else {
            this.loader.hide();
            this.toasterService.clear()
            this.toasterService.error(data.message);
          }
        });
    }
  }
}
