import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appMasking]'
})
export class MaskingDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    const value = input.value;

    const maskedValue = 'x'.repeat(Math.max(0, value.length - 4)) + value.slice(-4);
    input.value = maskedValue;
  }

}
