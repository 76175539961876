import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ButtonStateService } from 'src/app/services/button-state.service';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-bank-integrtaion-setup',
  templateUrl: './bank-integrtaion-setup.component.html',
  styleUrls: ['./bank-integrtaion-setup.component.scss']
})
export class BankIntegrtaionSetupComponent {
  public bankIntegrationForm!: FormGroup;
  hidePassword: boolean = true;
  countryList: any;
  stateList: any;
  cityList: any;
  selectedCountry: string = "";
  selectedState: string = "";
  selectedCity: string = "";
  verifiedIFSC: boolean = false;
  isLoading: boolean = false;
  errorMessage: any;
  bankName: any;
  bankLogo: any;

  constructor(private buttonStateService: ButtonStateService, private EmployeeDetailsService: EmployeeDetailsService, private fb: FormBuilder, private masterDataService: MasterDataService) { }

  ngOnInit() {
    this.bankName = JSON.stringify(sessionStorage.getItem("bankName"));
    this.bankLogo = sessionStorage.getItem("bankLogo");
    this.initBankIntigration();
  }

  initBankIntigration() {
    this.bankIntegrationForm = this.fb.group({
      'name': ['', ValidationUtil.onlySpacesOrNumbersValidator()],
      'name_on_bank': ['', Validators.required],
      'email': ['', [Validators.email, Validators.required]],
      'phone': ['', [Validators.required]],
      'business_name': ['', Validators.required],
      'state': ['', Validators.required],
      'nature_of_business': ['', Validators.required],
      'business_address': ['', Validators.required],
      'bank_name': ['', [Validators.required]],
      'branch_name': ['', [Validators.required]],
      'account_number': ['', [Validators.required, Validators.minLength(8), Validators.maxLength(18)]],
      'account_ifsc': ['', Validators.required],
      'category_code': ['', Validators.required],
      'pan_number': ['', [
        Validators.required,
        Validators.pattern("^([A-Z]){5}([0-9]){4}([A-Z]){1}$")
      ]],
      'business_type_code': ['', Validators.required],
      'address': ['', Validators.required],
      'city': [''],
      'pincode': ['', Validators.required],
      'entity_type': ['', Validators.required],
      'gstin': ['', Validators.required],
    })
  }

  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumber(event);
  }

  alphanumericAllowed(event: any) {
    ValidationUtil.onlyAlphanumeric(event);
  }

  getCountryList() {
    this.masterDataService.getAllCountries().subscribe(
      (data: any) => {
        if (data.status === 200)
          this.countryList = data.data;
      }
    )
  }

  getState(country: any) {
    this.selectedCountry = country;
    this.masterDataService.getAllState(country).subscribe(
      (data: any) => {
        this.stateList = data.data;
      }
    )
  }

  getCity(state: any) {
    this.selectedState = state;
    this.masterDataService.getAllCity(state).subscribe(
      (data: any) => {
        this.cityList = data.data;
      }
    )
  }


  selectedCityName(city: any) {
    this.selectedCity = city;
  }

  verifyIFSC() {
    this.isLoading = true;
    this.errorMessage = '';
    this.EmployeeDetailsService.verifyIFSC(this.bankIntegrationForm.value.account_ifsc).subscribe(
      (response: any) => {
        if (response.status === 200) {
          this.verifiedIFSC = true;
          this.isLoading = false;
          this.bankIntegrationForm.controls['account_ifsc'].setErrors(null);
          const data = JSON.parse(response.data);
          const bankName = data.BANK;
          const branchName = data.BRANCH;
          this.bankIntegrationForm.patchValue({
            bank_name: bankName,
            branch_name: branchName
          });
        } else {
          this.isLoading = false;
          this.verifiedIFSC = false;
          this.bankIntegrationForm.controls['account_ifsc'].setErrors({ 'invalidIFSC': true });
        }
      },
      (error: any) => {
        this.isLoading = false;
      }
    );
  }

  saveBank() {
    this.buttonStateService.updateButtonState(false);
  }

  resetIFSCFieldCheck() {
    this.verifiedIFSC = false;
  }

  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }
  
  addressVerification(event: any) {
    return ValidationUtil.addressVerification(event);
  }
}



