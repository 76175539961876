import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-profile-popup',
  templateUrl: './profile-popup.component.html',
  styleUrls: ['./profile-popup.component.scss']
})
export class ProfilePopupComponent {
  
  profileFields = [
    { label: 'emp_id', value: this.employee.employeeId, icon: 'badge' },
    { label: 'emp_designation', value: this.employee.designation, icon: 'work' },
    { label: 'emp_department', value: this.employee.department, icon: 'apartment' },
    { label: 'emp_work_email', value: this.employee.workEmail, icon: 'email' },
    
    // { label: 'emp_personal_email', value: this.employee.personalMail, icon: 'alternate_email' },
    // { label: 'emp_pay_type', value: this.employee.payType, icon: 'payments' },
    // { label: 'emp_salary', value: this.employee.amount, icon: 'attach_money' },
    // { label: 'emp_mobile', value: this.employee.mobileNumber, icon: 'phone' },
    // { label: 'emp_address', value: `${this.employee.addressLine1}, ${this.employee.addressLine2}`, icon: 'home' },
    // { label: 'emp_country', value: this.employee.country, icon: 'public' },
    // { label: 'emp_city', value: this.employee.city, icon: 'location_city' },
    // { label: 'emp_state', value: this.employee.state, icon: 'location_on' },
    // { label: 'emp_pincode', value: this.employee.pincode, icon: 'pin' },
    // { label: 'emp_branch_id', value: this.employee.branchId, icon: 'business' }
  ];

  constructor(
    public dialogRef: MatDialogRef<ProfilePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public employee: any
  ) { }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
