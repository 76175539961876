import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CardData } from 'src/app/model/task-management/task-management/task-management.module';
import { DashboardMock } from 'src/app/constants/master-data/dashboardMock';
import { AppConstant } from 'src/app/constants/app.constants';
import { ProjectManagementService } from 'src/app/services/project-management/project-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskManagementService } from 'src/app/services/task-management/task-management.service';
import { ToastrService } from 'ngx-toastr';
import { TaskComponent } from '../task/task.component';

interface Card {
  name: string;
  cardData: CardData[];
}

@Component({
  selector: 'app-board-tracking',
  templateUrl: './board-tracking.component.html',
  styleUrls: ['./board-tracking.component.scss']
})
export class BoardTrackingComponent implements OnInit {
  toDo: Card[] = [];
  interviewRound1: Card[] = [];
  interviewRound2: Card[] = [];
  finalDiscussion: Card[] = [];
  selectedProject: any;
  selectedBoardId: any;
  selectedSprintId: any;
  boardList: any;
  sprints: any[] = [];
  phases: any[] = [];
  reportType!: string;

  constructor(private toasterService: ToastrService, private router: Router, private route: ActivatedRoute, public dialog: MatDialog, private projectService: ProjectManagementService, private taskService: TaskManagementService) { }

  ngOnInit(): void {
    this.getboardList();
  }

  drop(event: CdkDragDrop<any>, phase: any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      const movedTask = event.previousContainer.data[event.previousIndex];
      const newStatus = this.getStatusFromPhase(phase);
      this.changePhases(movedTask.taskId, newStatus);
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  getStatusFromPhase(phase: any): string {
    if (phase.name === 'TO DO') {
      return 'toDo';
    } else if (phase.name === 'IN PROGRESS') {
      return 'inProgress';
    } else if (phase.name === 'DONE') {
      return 'done';
    }
    return '';
  }

  viewAllDashboards() {
    this.router.navigate([AppConstant.TASK_MANAGEMENT_SIDENAV + AppConstant.SEPERATOR + AppConstant.PROJECT_DASHBOARD]);
  }

  getDashboard(board: any) {
    this.selectedBoardId = board.dashboardId;
    this.getBacklogData(this.selectedBoardId);
  }

  getSprint(sprint: any) {
    this.selectedSprintId = sprint.sprintId
    this.changeTaskStatus();
  }

  getboardList() {
    this.projectService.getAllProjectDashboard().subscribe(
      (dashboardList: any) => {
        if (dashboardList.status === 200) {
          this.boardList = dashboardList.data;
          if (this.boardList && this.boardList.length > 0) {
            if (this.route.snapshot.params['dashboardId'] !== undefined) {
              this.selectedBoardId = Number(this.route.snapshot.params['dashboardId']);
              sessionStorage.setItem(AppConstant.DASHBOARD_ID, this.selectedBoardId);
            } else {
              this.selectedBoardId = this.boardList[0].dashboardId;
              sessionStorage.setItem(AppConstant.DASHBOARD_ID, this.selectedBoardId);
            }
            this.getBacklogData(this.selectedBoardId);
          }
        }
      })
  }

  getBacklogData(dashboardId: any) {
    this.taskService.getBacklogData(dashboardId).subscribe(
      (backlogData: any) => {
        if (backlogData.status === 200) {
          this.sprints = backlogData.data.sprintDetails;
          if (this.route.snapshot.params['sprintId'] !== undefined) {
            this.selectedSprintId = Number(this.route.snapshot.params['sprintId']);
          } else {
            this.selectedSprintId = this.sprints[0].sprintId;
          }
          this.changeTaskStatus();
        }
      }
    );
  }

  changeTaskStatus() {
    this.taskService.getTaskList(this.selectedBoardId, this.selectedSprintId).subscribe((taskStatus: any) => {
      if (taskStatus.status === 200) {
        this.phases = [
          { name: 'TO DO', cardData: taskStatus.data.toDo },
          { name: 'IN PROGRESS', cardData: taskStatus.data.inProgress },
          { name: 'DONE', cardData: taskStatus.data.done }
        ];
        
      }
    })
  }

  downloadExcel() {
    this.reportType = "Excel";
    this.exportReport();
  }

  downloadCSV() {
    this.reportType = "CSV"
    this.exportReport();
  }

  openTaskDialog(item: any): void {
    const dialogRef = this.dialog.open(TaskComponent, {
      width: '600px',
      height:'450px',
      data: {
        dashboardId: this.selectedBoardId,
        taskId: item.taskId
      },
      disableClose: true
    });
  
    dialogRef.afterClosed().subscribe(result => {
    });
  }
  
  
  exportReport() {
    if (this.selectedBoardId && this.selectedSprintId) {
      const showSprintDetails = {
        dashboardId: this.selectedBoardId,
        sprintIds: [this.selectedSprintId],
        reportType: this.reportType
      };

      this.taskService.reportDetails(showSprintDetails).subscribe(
        (response: Blob) => {
          const fileExtension = this.reportType === 'Excel' ? 'xlsx' : 'csv';
          const fileName = `report.${fileExtension}`;
          const blob = new Blob([response], { type: response.type });
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        },
      );
    }
  }

  getInitials(name: string): string {
    if (!name) return '';
    const initials = name.split(' ').map(part => part[0]).join('');
    return initials.toUpperCase();
  }

  changePhases(taskId: number, status: string) {
    const phasesStatusDto = {
      taskId: taskId,
      status: status
    };
    this.taskService.changetaskPhases(phasesStatusDto).subscribe((phasesStatus: any) => {
      if (phasesStatus.status === 200) {
        this.toasterService.clear();
        this.toasterService.success(phasesStatus.message)
      } else {
        this.toasterService.clear();
        this.toasterService.error(phasesStatus.message)
      }
    });
  }
}

