import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminDeclationConfigurationServiceService } from 'src/app/services/super-admin-declaration-configuration-service/super-admin-declation-configuration-service.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-add-regime-slab-popup',
  templateUrl: './add-regime-slab-popup.component.html',
  styleUrls: ['./add-regime-slab-popup.component.scss']
})
export class AddRegimeSlabPopupComponent {
  years: number[] = [];
  section!: FormGroup;
  showAgeFilter: boolean = false;

  constructor(private toastrService: ToastrService, private superAdminDeclarationConfigurationService: SuperAdminDeclationConfigurationServiceService, private dialogRef: MatDialogRef<AddRegimeSlabPopupComponent>, private formBuilder: FormBuilder) {
    const currentYear = new Date().getFullYear();
    for (let year = currentYear + 2; year >= 1900; year--) {
      this.years.push(year);
    }
  }

  ngOnInit(): void {
    this.section = this.formBuilder.group({
      'regimeName': ['', Validators.required],
      'regimeType': ['', Validators.required],
      'regimeMinimumAge': [''],
      'regimeMaximumAge': [''],
      'regimePercentage': [''],
      'fromYear': ['', Validators.required],
      'toYear': ['', Validators.required],
      'regimeMinimumAmount': ['', Validators.required],
      'regimeMaximumAmount': ['', Validators.required],
    });

    this.section.get('regimeType')?.valueChanges.subscribe(value => {
      if (value === 'oldRegime') {
        this.showAgeFilter = true;
        this.section.get('regimeMinimumAge')?.setValidators([Validators.required]);
        this.section.get('regimeMaximumAge')?.setValidators([Validators.required]);
      } else {
        this.showAgeFilter = false;
        this.section.get('regimeMinimumAge')?.clearValidators();
        this.section.get('regimeMaximumAge')?.clearValidators();
      }
      this.section.get('regimeMinimumAge')?.updateValueAndValidity();
      this.section.get('regimeMaximumAge')?.updateValueAndValidity();
    });
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  addSection() {
    if (this.section.valid) {
      const dto = {
        regimeName: this.section.get('regimeName')?.value,
        regimeType: this.section.get('regimeType')?.value,
        minimumAge: this.section.get('regimeMinimumAge')?.value,
        maximumAge: this.section.get('regimeMaximumAge')?.value,
        percentage: this.section.get('regimePercentage')?.value,
        slabFromYear: this.section.get('fromYear')?.value,
        slabToYear: this.section.get('toYear')?.value,
        minimumAmount: this.section.get('regimeMinimumAmount')?.value,
        maximumAmount: this.section.get('regimeMaximumAmount')?.value
      }
      this.superAdminDeclarationConfigurationService.addRegime(dto).subscribe(
        (data: any) => {
          if (data.status === 200) {
            this.toastrService.clear();
            this.toastrService.success(data.message);
            this.dialogRef.close();
          }
        });
    } else {
      this.toastrService.error('Please fill out the form correctly.');
    }
  }

  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }
}
