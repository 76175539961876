<app-navbar></app-navbar>

<div class="container">
    <mat-card *ngIf="!isQRPresent &&!isEmail" class="custom-card">
        <mat-card-content>
            <h3 class="custom-heading">{{'compolete_your_payment' | translate}}
            </h3>
            <mat-chip-list class="chips-list">
                <mat-chip class="note-chip"> <span class="exlametry"><i class="fa fa-info-circle"></i>
                    </span><b>{{'note' | translate}}</b>
                    {{'note_contents' | translate}}
                    <br>
                    <div class="reverse-timestamp" style="margin-top: 4px;"> <b>{{'qr_valid'|translate}} </b><b
                            class="timer"> {{ remainingTime}} {{'min' | translate}}</b></div>
                </mat-chip>
            </mat-chip-list>

            <div class="payment-content">
                <span class="correct-icon"> <i class="fa fa-check-circle" style="font-size:18px"></i></span>
                {{'scan_and_pay' | translate}}
            </div>
            <div class="icon-container" *ngIf="!isQRPresent">
                <img [src]="qrCodeBase64" alt="QR Code" class="qr-code-image">
            </div>
        </mat-card-content>
    </mat-card>

    <div class="spinner-overlay" *ngIf="isQRPresent && !isEmail">
        <mat-spinner></mat-spinner>
    </div>

    <mat-card *ngIf="isQRPresent && isEmail" class="email-card">
        <mat-card-content>
            <h3 class="custom-heading">{{'compolete_your_payment' | translate}}</h3>
            <mat-chip-list class="chips-list">
                <mat-chip class="note-chip"> <span class="exlametry"><i class="fa fa-info-circle"></i>
                    </span><b>{{'note' | translate}}</b>
                    {{'note_contents' | translate}}
                    <br>
                </mat-chip> </mat-chip-list>
            <div class="icon-container">
                <div class="rupee-circle">
                    <i class="fa fa-inr"></i>
                </div>
                <div class="direction-icon">
                    <i class="fa fa-arrow-right"></i>
                </div>
                <div class="mobile-icon">
                    <mat-icon>mobile_friendly</mat-icon>
                </div>
            </div>

            <div class="payment-options">
                <p class="email-content"><b><span class="direction"><i class="fa fa-arrow-circle-right"
                                style="font-size:18px"></i></span>
                        {{'payment_ways'|translate}}</b></p>
                <ol>
                    <li class="instructions">
                        {{'payment_way_1' | translate}}
                    </li>
                    <li class="instructions">
                        {{'payment_way_2' | translate}} <a href="#" (click)="sendLinkOnMail($event)">{{'proced_to_pay' |
                            translate}}</a>.
                    </li>
                </ol>
            </div>
        </mat-card-content>
    </mat-card>
</div>