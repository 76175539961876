import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ColumnConfig } from 'src/app/config/coloum-config';
import { AppConstant } from 'src/app/constants/app.constants';
import { VoucherService } from 'src/app/services/admin-model-services/voucher.service';

@Component({
  selector: 'app-view-voucher',
  templateUrl: './view-voucher.component.html',
  styleUrls: ['./view-voucher.component.scss']
})
export class ViewVoucherComponent implements OnInit {
  public voucherData: any[] = [];
  public newUserTableCol: any[];
  public newUserColDef: any;

  constructor(private voucherService: VoucherService, private router: Router) {
    this.newUserTableCol = [
      { headerName: 'Company Name', field: 'companyName', sortable: true, filter: true, floatingFilter: true },
      { field: 'email', sortable: true, filter: true, floatingFilter: true },
      { headerName: 'Mobile Number', field: 'mobileNo', sortable: true, filter: true, floatingFilter: true },
      { headerName: 'Start Date', field: 'startDate', sortable: true, filter: true, floatingFilter: true, valueFormatter: this.dateFormatter },
      { headerName: 'Expiry Date', field: 'expiredDate', sortable: true, filter: true, floatingFilter: true, valueFormatter: this.dateFormatter },
      { headerName: 'Discount in %', field: 'amount', sortable: true, filter: true, floatingFilter: true },
      { headerName: 'Status', field: 'active', sortable: true, filter: true, floatingFilter: true, valueFormatter: this.statusFormatter }
    ];

    this.newUserColDef = {
      resizable: true,
      sortable: true,
      filter: true,
      floatingFilter: true,
      unSortIcon: true,
      flex: 1,
      minWidth: 100
    };
  }

  ngOnInit(): void {
    this.getVoucherData();
  }

  getVoucherData() {
    this.voucherService.getVoucherDetails().subscribe((response: any) => {
      if (response.status === 200 && response.message === 'success') {
        this.voucherData = response.data;
      }
    });
  }

  dateFormatter(params: any) {
    return moment(params.value).format('DD/MM/YYYY');
  }

  statusFormatter(params: any) {
    return params.value ? 'Active' : 'Redeemed';
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  addVoucher() {
    this.router.navigate([AppConstant.ADMIN_SIDEBAR + AppConstant.SEPERATOR + AppConstant.VOUCHER]);
  }
}
