import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent {
  imageUrl: string;
  imageName: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { imageUrl: string, documentName: string }, private dialog: MatDialog, private snackBar: MatSnackBar) {
    this.imageUrl = data.imageUrl;
    const fileNameWithoutExtension = data.documentName.replace(/\.[^/.]+$/, "");
    this.imageName = fileNameWithoutExtension.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  close(): void {
    this.dialog.closeAll();
  }

  download(): void {
    const anchor = document.createElement('a');
    anchor.style.display = 'none';
    anchor.href = this.imageUrl;
    anchor.download = this.imageName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    this.snackBar.open('Downloading image...', 'Dismiss', {
      duration: 2000,
    });
  }

}
