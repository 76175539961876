<div class="setting__main_container">
    <div class="setting__heading_container">
        <h3>{{ 'setup_payrun-approval' | translate }}</h3>
        <div class="setting__header_button_container" *ngIf="showAddApproverBtn">
            <button color="primary" (click)="toggleAddPopup()">{{ 'add_approver' | translate}}</button>
        </div>
    </div>

    <div *ngIf="!payrunInProgress">
        <div class="approval-selection">
            <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="selectedApprovalType"
                (click)="onApprovalTypeChange()">
                <mat-button-toggle value="Direct_Approval">{{'direct_approval' | translate}}</mat-button-toggle>
                <mat-button-toggle value="Standard_Approval">{{'standard_approval' | translate}}</mat-button-toggle>
                <mat-button-toggle value="Hierarchical_Approval">{{'hierarchical_approval' |
                    translate}}</mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <ag-grid-angular *ngIf="showAddApproverBtn" (gridReady)="onGridReady($event)"
            style="height: 27rem; width: 100%;" class="ag-theme-alpine" [rowData]="payrunRowData" [animateRows]="true"
            [columnDefs]="payrunColumnDefs" [defaultColDef]="departmentColDef" [enableSorting]="true"
            [enableFilter]="true" [pagination]="true" [paginationPageSize]="10">
        </ag-grid-angular>
    </div>
    <div *ngIf="payrunInProgress" class="no_approver_change">
        <span class="note">{{'note' | translate}}</span>
        <span class="message">{{'currently_payrun_is_in_process_hence_cannot_change_approvers' | translate}}</span>
    </div>
</div>

<!-- Add approver popup -->
<div class="overlay" *ngIf="showAddPopup">
    <div class="add-popup">
        <div class="container">
            <div class="header">
                <h3 class="heading">{{ 'add_approver' | translate }}</h3>
            </div>
            <form [formGroup]="approverForm" id="approverForm">
                <div>
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'search' | translate}}</mat-label>
                        <mat-select formControlName="approverName" #singleSelect>
                            <mat-option>
                                <ngx-mat-select-search [placeholderLabel]="'Search'" [formControl]="personSearchFilter"
                                    noEntriesFoundLabel="" [searching]="searching"
                                    [preventHomeEndKeyPropagation]="true"></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let employee of filteredServerSide | async"
                                [value]="employee.employeeId">
                                {{employee.employeeName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full-width" appearance="outline" *ngIf="hierarchicalApproval">
                        <mat-label>{{'priority'| translate}}</mat-label>
                        <mat-select required="*" formControlName="priority">
                            <div *ngFor="let priority of priorities">
                                <mat-option [value]="priority">{{priority}}</mat-option>
                            </div>
                        </mat-select>
                        <mat-error *ngIf="approverForm.get('priority')?.hasError('required')">{{'priority_required'|
                            translate}}</mat-error>
                    </mat-form-field>
                </div>
                <app-loader></app-loader>
                <div class="pupup-buttons">
                    <button (click)="toggleAddPopup()" class="closeDialog cancel-btn">
                        {{'cancel' | translate}}</button>
                    <button (click)="addApprover()">
                        {{ 'save' | translate}}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>