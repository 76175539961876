<div class="container">
    <h3>{{ 'download_bank_advice' | translate }}</h3>
    <div>
        <form [formGroup]="bankAdviceForm">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'bank_statement_format' | translate }}</mat-label>
                <mat-select formControlName="format">
                    <mat-option value="Standard Format">{{ 'standard_format' | translate }}</mat-option>
                    <mat-option value="ICICI Bank">{{ 'icici_bank' | translate }}</mat-option>
                    <mat-option value="Kotak Mahindra Bank">{{ 'kotak_mahindra_bank' | translate }}</mat-option>
                    <mat-option value="HDFC Bank">{{ 'hdfc_bank' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'download_as' | translate }}</mat-label>
                <mat-select formControlName="downloadAs">
                    <mat-option value="XLS (Microsoft Excel 1997-2004 Compatible)">{{ 'xls_format' | translate
                        }}</mat-option>
                    <mat-option value="XLSX (Microsoft Excel)">{{ 'xlsx_format' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
    <div class="button-div">
        <button color="primary" (click)="onDownload()">{{ 'download' | translate }}</button>
    </div>
</div>