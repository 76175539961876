import { Component, ViewChild } from '@angular/core';
import { MasterData } from 'src/app/constants/admin-module-constant/admin-master-data.constant';
import { ProfitLossService } from 'src/app/services/admin-model-services/profit-loss/profit-loss.service';
import { ChartComponent, ApexAxisChartSeries, ApexChart, ApexXAxis, ApexDataLabels, ApexTitleSubtitle, ApexStroke, ApexGrid, ApexFill, ApexMarkers, ApexYAxis } from "ng-apexcharts";
import { AppConstant } from 'src/app/constants/app.constants';
import { Router } from '@angular/router';

export type ChartOptions = {
  series: ApexAxisChartSeries | any;
  chart: ApexChart | any;
  xaxis: ApexXAxis | any;
  dataLabels: ApexDataLabels | any;
  grid: ApexGrid | any;
  fill: ApexFill | any;
  markers: ApexMarkers | any;
  yaxis: ApexYAxis | any;
  stroke: ApexStroke | any;
  title: ApexTitleSubtitle | any;
};

@Component({
  selector: 'app-profit-loss-chart',
  templateUrl: './profit-loss-chart.component.html',
  styleUrls: ['./profit-loss-chart.component.scss']
})

export class ProfitLossChartComponent {
  @ViewChild("chart") chart: ChartComponent | any;
  selectedRange: string = 'monthly';
  profitLoss: any;
  monthly: any;
  constructor(private profitLossService: ProfitLossService, private router: Router) {
    this.profitLoss = MasterData.profitLoss;
  }

  ngOnInit() {
    this.profitLossData();
  }

  viewReport() {
    sessionStorage.setItem('report', 'ProfitLoss')
    this.router.navigate([AppConstant.ADMIN_SIDEBAR + AppConstant.SEPERATOR + AppConstant.PROFIT_LOSS_REPORT])

  }
  profitLossData() {
    this.profitLossService.getProfitLoss(this.selectedRange).subscribe((data: any) => {
      this.monthly = data.data;

      if (this.selectedRange.toLowerCase() === 'monthly') {
        this.profitLoss.series[0].data = [
          this.monthly.JANUARY, this.monthly.FEBRUARY, this.monthly.MARCH,
          this.monthly.APRIL, this.monthly.MAY, this.monthly.JUNE,
          this.monthly.JULY, this.monthly.AUGUST, this.monthly.SEPTEMBER,
          this.monthly.OCTOBER, this.monthly.NOVEMBER, this.monthly.DECEMBER
        ];
      } else if (this.selectedRange.toLowerCase() === 'yearly') {
        const years = Object.keys(this.monthly);
        const yearlyData = years.map(year => this.monthly[year]);
        this.profitLoss.series[0].data = yearlyData;

      }
    });
  }

}
