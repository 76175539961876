<div class="role">
    <div class="mat-form-field">
        <mat-form-field appearance="outline">
            <mat-label>{{ 'role' | translate }}</mat-label>
            <mat-select (selectionChange)="selectRole($event)">
                <mat-option *ngFor="let role of roleList" [value]="role.id">
                    {{ role.roleName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="role-table" *ngIf="selectedRoleList.length > 0">
        <table mat-table [dataSource]="dataSource">

            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef>{{ 'id' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element?.id }}</td>
            </ng-container>

            <ng-container matColumnDef="roleName">
                <th mat-header-cell *matHeaderCellDef>{{ 'role_name' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element?.roleName }}</td>
            </ng-container>

            <ng-container matColumnDef="roleDescription">
                <th mat-header-cell *matHeaderCellDef>{{ 'role_description' | translate }}</th>
                <td mat-cell *matCellDef="let element">{{ element?.roleDescription }}</td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>{{ 'action' | translate }}</th>
                <td mat-cell *matCellDef="let element; let i = index">
                    <button mat-button (click)="removeSelectedRoleList(i)">Remove</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <div class="button-div">
        <button matStepperPrevious class="back-button">{{ 'back' | translate }}</button>
        <div class="submit-button">
            <button class="cancel-btn" type="reset">{{'cancel'|translate}}</button>
            <button (click)="saveRole()" type="submit">{{'save'|translate}}</button>
        </div>
    </div>
</div>