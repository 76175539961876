import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { AttendanceService } from 'src/app/services/attendance/attendance.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-attendance-configuration',
  templateUrl: './attendance-configuration.component.html',
  styleUrls: ['./attendance-configuration.component.scss']
})

export class AttendanceConfigurationComponent {
  attendanceConfigurationForm!: FormGroup;
  setTimeForm!: any;
  categoryData: any[] = [];
  showAddPopup: boolean = false;
  addDepartmentFlag: boolean = true;
  columnDefs = [
    { headerName: 'Category ID', field: 'categoryId', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { headerName: 'Category Code', field: 'categoryCode', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { headerName: 'Category Name', field: 'categoryName', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true }
  ];

  constructor(private fb: FormBuilder, private attendanceService: AttendanceService, private toastrService: ToastrService) { }

  ngOnInit() {
    this.attendanceConfiguration()
    this.getAllCategoryList();
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  attendanceConfiguration() {
    this.attendanceConfigurationForm = this.fb.group({
      'categoryName': ['', [Validators.required]],
      'categoryCode': ['', [Validators.required]],
      'categoryDescription': ['', [Validators.required]]
    })
  }

  addCategory() {
    if (this.attendanceConfigurationForm.invalid) {
      return;
    }

    if (this.attendanceConfigurationForm.get('categoryName')?.value != "") {
      this.attendanceConfigurationForm.get('categoryName')?.setErrors({ 'required': true });
    }

    if (this.attendanceConfigurationForm.get('categoryCode')?.value != "") {
      this.attendanceConfigurationForm.get('categoryCode')?.setErrors({ 'required': true });
    }

    if (this.attendanceConfigurationForm.get('categoryDescription')?.value != "") {
      this.attendanceConfigurationForm.get('categoryDescription')?.setErrors({ 'required': true });
    }

    const categoryDto = {
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      categoryName: this.attendanceConfigurationForm.get('categoryName')?.value,
      categoryCode: this.attendanceConfigurationForm.get('categoryCode')?.value,
      description: this.attendanceConfigurationForm.get('categoryDescription')?.value,
    };

    if (this.categoryData.some(category => category.categoryCode === categoryDto.categoryCode)) {
      this.attendanceConfigurationForm.get('categoryCode')?.setErrors({ 'duplicate': true });
      return;
    }

    this.attendanceService.attendanceConfiguration(categoryDto).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.toastrService.success(data.message);
          this.getAllCategoryList();
          this.showAddPopup = false;
          this.attendanceConfigurationForm.reset();
        }
      }
    );
  }

  getAllCategoryList() {
    this.attendanceService.getAllCategoryList(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.categoryData = data.data.map((category: any) => ({
            categoryName: category.categoryName,
            categoryId: category.id,
            categoryCode: category.categoryCode
          }))
        }
      }
    )
  }

  validateNumberAndSpaceAndHyphenAllowed(event: any) {
    return ValidationUtil.characterNumberAndSpaceAndHyphenAllowed(event);
  }

  addAttendanceCategory() {
    if (this.showAddPopup) {
      this.attendanceConfigurationForm.reset();
      this.addDepartmentFlag = true;
    }
    this.showAddPopup = !this.showAddPopup;
  }

  cancelPopup() {
    this.showAddPopup = false;
  }
}
