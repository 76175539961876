import { Component, OnInit } from '@angular/core';
import { AppConstant } from 'src/app/constants/app.constants';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  managerRole: boolean = false;
  adminRole: boolean = false;
  userRole: boolean = false;

  ngOnInit(): void {
    const token: string | null = sessionStorage.getItem(AppConstant.TOKEN);
    if (token) {
      const tokenData = JSON.parse(atob(token.split('.')[1]).toLocaleLowerCase());
      if (tokenData.roles && Array.isArray(tokenData.roles)) {
        this.managerRole = tokenData.roles.includes("manager");
        this.adminRole = tokenData.roles.includes("admin");
        this.userRole = tokenData.roles.includes("user");
      }
    }
  }

}
