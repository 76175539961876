import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService} from 'ngx-toastr';
import { BookDemo } from 'src/app/model/book-demo/book-demo.model';
import { BookDemoService } from 'src/app/services/book-demo/book-demo.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-book-demo',
  templateUrl: './book-demo.component.html',
  styleUrls: ['./book-demo.component.scss']
})
export class BookDemoComponent {

  BookDemoForm: FormGroup | any;

  constructor(private fb: FormBuilder, private demoService: BookDemoService, private toastrService: ToastrService) { }

  ngOnInit() {
    this.InitBookDemo()
  }

  InitBookDemo() {
    this.BookDemoForm = this.fb.group({
      "companyName": ['', Validators.required],
      'email': ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      'mobileNumber': ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10), ValidationUtil.validMobileNumber()]],
    })
  }

  onSubmit() {
    if (this.BookDemoForm.valid) {
      let bookDemo: BookDemo = {
        "companyName": this.BookDemoForm.value.companyName,
        "email": this.BookDemoForm.value.email,
        "mobileNumber": this.BookDemoForm.value.mobileNumber,
      }
      this.demoService.bookDemo(bookDemo).subscribe((data: any) => {
        if (data.status == 200) {
          this.toastrService.success(data.message);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
        else {
          this.toastrService.clear()
          this.toastrService.error(data.message);
        }
      })
    }
  }

  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumber(event);
  }
  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }
}