import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ChartComponent } from 'chart.js';
import { MasterData } from 'src/app/constants/admin-module-constant/admin-master-data.constant';
import { AppConstant } from 'src/app/constants/app.constants';
import { PaymentDataService } from 'src/app/services/admin-model-services/payment-data/payment-data.service';

@Component({
  selector: 'app-payment-received-graph',
  templateUrl: './payment-received-graph.component.html',
  styleUrls: ['./payment-received-graph.component.scss']
})
export class PaymentReceivedGraphComponent {
  @ViewChild("chart") chart: ChartComponent | undefined;
  selectedTimeRange: string = 'monthly';
  chartOptions: any;
  paymentData: any;
  constructor(private router: Router, private paymentDataService: PaymentDataService) {
    this.chartOptions = MasterData.chartOptions;
  }

  ngOnInit() {
    this.paymentGraph();
  }

  viewReport() {
    sessionStorage.setItem('report', 'paymentReceived')
    this.router.navigate([AppConstant.ADMIN_SIDEBAR + AppConstant.SEPERATOR + AppConstant.PAYMENT_RECEIVED_REPORT])
  }

  montlyYearlyBasis() {
    this.paymentGraph();
  }

  paymentGraph() {
    this.paymentDataService.getPaymentData(this.selectedTimeRange).subscribe((data: any) => {
      if (this.selectedTimeRange == 'monthly') {
        this.paymentData = data.data.monthly;
      }
      else {
        this.paymentData = data.data.yearly;
      }
      this.chartOptions.series = [this.paymentData.pending, this.paymentData.success, this.paymentData.failure];
    })
  }
}

