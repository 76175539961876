import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { ToastrService } from 'ngx-toastr';
import { FamilyAndEmergencyContactsService } from 'src/app/services/family-and-emergency-contacts/family-and-emergency-contacts.service';
import { SalutationData } from 'src/app/constants/master-data/salutation.constant';
import { Messages } from 'src/app/constants/messages.constant';

@Component({
  selector: 'app-add-family-and-emergency-contacts',
  templateUrl: './add-family-and-emergency-contacts.component.html',
  styleUrls: ['./add-family-and-emergency-contacts.component.scss'],
})

export class AddFamilyAndEmergencyContactsComponent implements OnInit {
  createFamilyAndEmergencyForm!: FormGroup;
  tempAddressDTO: any;
  countryList: any;
  stateList: any;
  cityList: any;
  selectedCountry: string = "";
  selectedState: string = "";
  selectedCity: string = "";
  salutations: any = SalutationData.SALUTATIONS;
  isEditMode: boolean = false;
  selectedOption: string = AddressTypes.EMPLOYEE_EXISTING_ADDRESS;
  existingAddressFlag: boolean = true;
  newAddressFlag: boolean = false;
  familyAndEmergencyContactsData: any;
  emergencyContactId: any;
  useMyAddress: any;
  addUpdateContactInfoRequest: any;
  employeeDob = sessionStorage.getItem(AppConstant.DOB);
  employeeEmergencyContactInfo: string[] = Messages.EMPLOYEE_FAMILY_RELATION;

  constructor(private router: Router, private route: ActivatedRoute, private fb: FormBuilder, private masterDataService: MasterDataService, private toasterService: ToastrService, private familyAndEmergencyContactService: FamilyAndEmergencyContactsService) { }

  ngOnInit(): void {
    this.emergencyContactId = this.route.snapshot.params['id']; //emergency contact emergencyContactId
    this.employeeBasicInformation();
    if (this.emergencyContactId != null || this.emergencyContactId != undefined) {
      this.getFamilyAndEmergencyContacts(this.emergencyContactId);
    }
    this.getCountryList();
  }

  employeeBasicInformation() {
    this.createFamilyAndEmergencyForm = this.fb.group({
      'salutation': ['Mr.', Validators.required],
      'firstName': ['', ValidationUtil.onlySpacesOrNumbersValidator()],
      'middleName': ['', ValidationUtil.onlySpacesOrNumbersValidator()],
      'lastName': ['', ValidationUtil.onlySpacesOrNumbersValidator()],
      'gender': ['', [Validators.required]],
      'relationship': ['', [Validators.required]],
      'relationshipStartDate': ['', [Validators.required]],
      'contactNo': ['', [Validators.required]],
      'emailAddress': ['', [Validators.required, Validators.email]],
      'emergencyContactCheck': [''],
      'addressType': [AddressTypes.EMPLOYEE_EXISTING_ADDRESS, Validators.required],
      'addressLine1': ['', Validators.required],
      'addressLine2': [''],
      'state': ['', Validators.required],
      'city': [''],
      'country': ['', Validators.required],
      'pinCode': ['', [Validators.required, Validators.pattern(/^[0-9]*$/)
        , Validators.maxLength(6), Validators.minLength(6)]
      ]
    });
  }

  getFamilyAndEmergencyContacts(emergencyContactId: any) {
    this.familyAndEmergencyContactService.getFamilyAndEmergencyContacts(emergencyContactId, sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
      (emergencyContactData: any) => {
        if (emergencyContactData.status == 200) {
          this.newAddressFlag = !emergencyContactData.data.employeeExistingAddress;
          this.getState(emergencyContactData.data.country);
          this.getCity(emergencyContactData.data.state);
          this.createFamilyAndEmergencyForm?.get('emergencyContactCheck')?.setValue(emergencyContactData.data.isEmergencyContact);
          this.createFamilyAndEmergencyForm?.get('addressType')?.setValue(emergencyContactData.data.employeeExistingAddress ? 'employeeExistingAddress' : 'newAddress');

          this.createFamilyAndEmergencyForm.patchValue({
            salutation: emergencyContactData.data.salutation,
            firstName: emergencyContactData.data.firstName,
            middleName: emergencyContactData.data.middleName,
            lastName: emergencyContactData.data.lastName,
            gender: emergencyContactData.data.gender,
            relationship: (emergencyContactData.data.relationship).toLowerCase(),
            relationshipStartDate: emergencyContactData.data.relationshipStartDate,
            emailAddress: emergencyContactData.data.email,
            contactNo: emergencyContactData.data.contactNumber,
            emergencyContactCheck: emergencyContactData.data.isEmergencyContact,
            addressLine2: (this.newAddressFlag ? emergencyContactData.data.addressLine1 : ''),
            addressLine1: (this.newAddressFlag ? emergencyContactData.data.addressLine2 : ''),
            country: (this.newAddressFlag ? this.selectedCountry : ''),
            state: (this.newAddressFlag ? this.selectedState : ''),
            city: (this.newAddressFlag ? emergencyContactData.data.city : ''),
            pinCode: (this.newAddressFlag ? emergencyContactData.data.pinCode : '')
          })
          this.isEditMode = true;
          this.familyAndEmergencyContactsData = emergencyContactData.data;

        }
        else {
          this.toasterService.clear()
          this.toasterService.error(emergencyContactData.message);
        }
      }
    );
  }

  getCountryList() {
    this.masterDataService.getAllCountries().subscribe(
      (countryData: any) => {
        if (countryData.status === 200)
          this.countryList = countryData.data;
      }
    )
  }

  getState(country: any) {
    this.selectedCountry = country;
    this.masterDataService.getAllState(country).subscribe(
      (stateData: any) => {
        this.stateList = stateData.data;
      }
    )
  }

  getCity(state: any) {
    this.selectedState = state;
    this.masterDataService.getAllCity(state).subscribe(
      (cityData: any) => {
        this.cityList = cityData.data;
      }
    )
  }

  selectedCityName(city: any) {
    this.selectedCity = city;
  }

  employeeExistingAddressChecked() {
    this.selectedOption = AddressTypes.EMPLOYEE_EXISTING_ADDRESS;
    this.existingAddressFlag = true;
    this.newAddressFlag = false;
    this.useMyAddress = true;
  }

  newAddressChecked() {
    this.selectedOption = AddressTypes.NEW_ADDRESS;
    this.newAddressFlag = true;
    this.existingAddressFlag = false;
  }

  addUpdateFamilyAndEmergencyContactInformation() {
    this.isEditMode ? this.updateFamilyAndEmergencyContact() : this.addFamilyAndEmergencyContactInformation();
  }

  addFamilyAndEmergencyContactInformation() {
    this.byPassFormValidationForExistingAddress();

    if (this.createFamilyAndEmergencyForm.valid) {
      this.prepareAddUpdateContactRequest();

      this.familyAndEmergencyContactService.addFamilyAndEmergencyContactInfo(this.addUpdateContactInfoRequest).subscribe(
        (emergencyContactData: any) => {
          if (emergencyContactData.status == 200) {
            this.backToEmployeeProfile();
            this.toasterService.clear();
            this.toasterService.success(emergencyContactData.message);
          }
          else {
            this.toasterService.clear()
            this.toasterService.error(emergencyContactData.message);
          }
        }
      );
    }
  }

  updateFamilyAndEmergencyContact() {
    this.byPassFormValidationForExistingAddress();

    if (this.createFamilyAndEmergencyForm.valid) {
      this.prepareAddUpdateContactRequest();

      this.familyAndEmergencyContactService.updateFamilyAndEmergencyContactInfo(this.addUpdateContactInfoRequest).subscribe(
        (emergencyContactData: any) => {
          if (emergencyContactData.status == 200) {
            this.backToEmployeeProfile();
            this.toasterService.clear();
            this.toasterService.success(emergencyContactData.message);
          }
          else {
            this.toasterService.clear()
            this.toasterService.error(emergencyContactData.message);
          }
        }
      );
    }
  }

  byPassFormValidationForExistingAddress() {
    //patch empty values for employee exiting contact info as the values will be auto added from BE. Adding null to pass form validation
    if (this.createFamilyAndEmergencyForm.value.addressType == AddressTypes.EMPLOYEE_EXISTING_ADDRESS) {
      this.createFamilyAndEmergencyForm.patchValue({
        employeeExistingAddress: false,
        addressLine1: "null",
        addressLine2: "null",
        country: "null",
        state: "null",
        city: "null",
        pinCode: 0,
        emergencyContactCheck: (this.createFamilyAndEmergencyForm.value.emergencyContactCheck == "") ? false : this.createFamilyAndEmergencyForm.value.emergencyContactCheck
      });
    }
  }

  prepareAddUpdateContactRequest() {
    if (this.createFamilyAndEmergencyForm.value.addressType == AddressTypes.EMPLOYEE_EXISTING_ADDRESS) {
      this.tempAddressDTO = {
        employeeExistingAddress: true,
        addressLine1: "",
        addressLine2: "",
        country: "",
        state: "",
        city: "",
        pinCode: ""
      }
    } else if (this.createFamilyAndEmergencyForm.value.addressType == AddressTypes.NEW_ADDRESS) {
      this.tempAddressDTO = {
        employeeExistingAddress: false,
        addressLine1: this.createFamilyAndEmergencyForm.value.addressLine1,
        addressLine2: this.createFamilyAndEmergencyForm.value.addressLine2,
        country: this.createFamilyAndEmergencyForm.value.country,
        state: this.createFamilyAndEmergencyForm.value.state,
        city: this.createFamilyAndEmergencyForm.value.city,
        pinCode: this.createFamilyAndEmergencyForm.value.pinCode,
      }
    }

    this.addUpdateContactInfoRequest = {
      id: this.emergencyContactId,
      employeeId: sessionStorage.getItem(AppConstant.EMPLOYEE_ID),
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      salutation: this.createFamilyAndEmergencyForm.value.salutation,
      firstName: this.createFamilyAndEmergencyForm.value.firstName,
      middleName: this.createFamilyAndEmergencyForm.value.middleName,
      lastName: this.createFamilyAndEmergencyForm.value.lastName,
      email: this.createFamilyAndEmergencyForm.value.emailAddress,
      contactNumber: this.createFamilyAndEmergencyForm.value.contactNo,
      gender: this.createFamilyAndEmergencyForm.value.gender,
      relationship: this.createFamilyAndEmergencyForm.value.relationship,
      relationshipStartDate: this.createFamilyAndEmergencyForm.value.relationshipStartDate,
      isEmergencyContact: (this.createFamilyAndEmergencyForm.value.emergencyContactCheck == "") ? false : this.createFamilyAndEmergencyForm.value.emergencyContactCheck,
      employeeExistingAddress: this.tempAddressDTO?.employeeExistingAddress,
      addressLine1: this.tempAddressDTO?.addressLine1,
      addressLine2: this.tempAddressDTO?.addressLine2,
      country: this.tempAddressDTO?.country,
      state: this.tempAddressDTO?.state,
      city: this.tempAddressDTO?.city,
      pinCode: this.tempAddressDTO?.pinCode,
    };
  }

  backToEmployeeProfile() {
    this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_PROFILE]);
  }

  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumber(event);
  }

  addressVerification(event: any) {
    return ValidationUtil.addressVerification(event);
  }
}

class AddressTypes {
  public static EMPLOYEE_EXISTING_ADDRESS: string = "employeeExistingAddress";
  public static NEW_ADDRESS: string = "newAddress";
}
