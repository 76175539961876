<div class="setting__main_container">
    <div class="setting__heading_container">
        <h3>{{ 'general_setting_configuration' | translate }}</h3>
    </div>
</div>
<div class="setting-container">
    <div class="setting-container" [ngClass]="{ 'expanded': panelOpenState1 }">
        <mat-accordion class="solution-type">
            <mat-expansion-panel (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon>hourglass_empty</mat-icon> <span class="title">{{'probation_period' |
                            translate}}</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="panelOpenState1">
                    <form [formGroup]="probationPeriodForm">
                        <div class="probation-content">
                            <mat-icon *ngIf="probationPeriodupdateMode;
                            " class="edit-icon" matTooltip="Edit" (click)="probationPeriodEditMode()">edit</mat-icon>
                            <span>{{"probation_period_as_per"|translate}}</span>
                            <mat-radio-group formControlName="probationPeriodType" required
                                [disabled]="probationPeriodupdateMode">
                                <mat-radio-button [disabled]="probationPeriodupdateMode"
                                    value="calendar">{{"calender_days"|translate}}</mat-radio-button>
                                <mat-radio-button [disabled]="probationPeriodupdateMode"
                                    value="business">{{"business_day"|translate}}</mat-radio-button>
                            </mat-radio-group><br>
                            <mat-error class="probation-error"
                                *ngIf="probationPeriodForm.get('probationPeriodType')?.invalid && probationPeriodForm.get('probationPeriodType')?.touched">
                                {{"probation_period_is_required"|translate}}
                            </mat-error>
                        </div>
                        <div class="probation-content">
                            <label>
                                {{"initial_value_during_probation"|translate}}
                                <input (keypress)="onlyNumberKey($event)" type="text" class="notice-input"
                                    formControlName="probationPeriod" [readonly]="probationPeriodupdateMode"
                                    maxlength="3">
                            </label><br>
                            <mat-error
                                *ngIf="probationPeriodForm.get('probationPeriod')?.invalid && probationPeriodForm.get('probationPeriod')?.value < 365 && probationPeriodForm.get('probationPeriod')?.touched">
                                {{"probation_duration_is_required"|translate}}
                            </mat-error>
                            <mat-error *ngIf="probationPeriodForm.get('probationPeriod')?.value > 365">
                                {{"probation_duration"|translate}}
                            </mat-error>
                        </div>
                        <div class="probation-note">
                            <mat-chip color="primary" selected>
                                <i class="fa fa-info-circle"></i>
                                <span>{{ 'probation_note' | translate }}</span>
                            </mat-chip>
                        </div>

                        <button *ngIf="!probationPeriodupdateMode && !probationPeriodeditMode" class="submit-button"
                            (click)="probationPeriod()" type="button">{{ 'save' | translate }}</button>
                        <button *ngIf="!probationPeriodupdateMode && !probationPeriodeditMode"
                            class="cancel-button cancel-btn">{{ 'cancel' |
                            translate }}</button>

                        <button *ngIf="peobationPeriodupdate && showProbationUpdate" class="submit-button"
                            (click)="updateProbationPeriod()" type="button">{{ 'update' | translate }}</button>
                        <button *ngIf="peobationPeriodupdate && showProbationUpdate" class="cancel-button  cancel-btn"
                            (click)="cancelProbationPeriod()">{{ 'cancel' |
                            translate }}</button>
                    </form>
                </div>

            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="setting-container" [ngClass]="{ 'expanded': panelOpenState2 }">
        <mat-accordion class="solution-type">
            <mat-expansion-panel (opened)="panelOpenState2 = true" (closed)="panelOpenState2 = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon>event_available</mat-icon> <span class="title">{{'leave_cycle'
                            |translate}}</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="panelOpenState2">
                    <form [formGroup]="leaveCycleForm">
                        <div class="probation-content">
                            <mat-icon *ngIf="leaveCycleupdateMode" class="edit-icon" matTooltip="Edit"
                                (click)="leaveEditMode()">edit</mat-icon>
                            <span>{{"select_leave_cycle"|translate}}</span>
                            <mat-radio-group formControlName="leaveCycle" required [disabled]="leaveCycleupdateMode">
                                <mat-radio-button [disabled]="leaveCycleupdateMode"
                                    value="April to March">{{"april_to_march"|translate}}</mat-radio-button>
                                <mat-radio-button [disabled]="leaveCycleupdateMode"
                                    value="Jan to December">{{"jan_to_dec"|translate}}</mat-radio-button>
                            </mat-radio-group><br>
                            <mat-error class="leave-error"
                                *ngIf="leaveCycleForm.get('leaveCycle')?.invalid && leaveCycleForm.get('leaveCycle')?.touched">
                                {{"please_select_leave_cycle"|translate}}
                            </mat-error>
                        </div>
                        <div class="probation-content">
                            <label>
                                {{"apply_past_leave"|translate}}
                                <input type="text" class="notice-input" [readonly]="leaveCycleupdateMode"
                                    formControlName="leaveApplyPastDates" (keypress)="onlyNumberKey($event)"
                                    maxlength="2">
                            </label><br>
                            <mat-error
                                *ngIf="leaveCycleForm.get('leaveApplyPastDates')?.invalid && leaveCycleForm.get('leaveApplyPastDates')?.touched">
                                {{"required"|translate}}
                            </mat-error>
                        </div>

                        <div class="approveLeave">
                            <label>
                                {{"system_approval"|translate}}
                                <input type="text" class="notice-input" [readonly]="leaveCycleupdateMode"
                                    formControlName="leaveApproveNextDays" (keypress)="onlyNumberKey($event)"
                                    maxlength="2">
                            </label><br>
                            <mat-error
                                *ngIf="leaveCycleForm.get('leaveApproveNextDays')?.invalid && leaveCycleForm.get('leaveApproveNextDays')?.touched">
                                {{"required"|translate}}
                            </mat-error>
                        </div>
                        <button *ngIf="!leaveCycleupdateMode && !leaveCycleeditMode" class="submit-button"
                            (click)="leaveCycle()" type="button">{{ 'save' | translate }}</button>
                        <button *ngIf="!leaveCycleupdateMode && !leaveCycleeditMode" class="cancel-button cancel-btn"
                            type="reset">{{ 'cancel' |
                            translate }}</button>

                        <button *ngIf="leaveCycleupdate && showLeaveCycleUpdate" class="submit-button"
                            (click)="updateLeaveCycle()" type="button">{{ 'update' | translate }}</button>
                        <button *ngIf="leaveCycleupdate && showLeaveCycleUpdate" class="cancel-button cancel-btn"
                            (click)="cancelLeaveCycle()">{{ 'cancel' |
                            translate }}</button>
                    </form>
                </div>

            </mat-expansion-panel>
        </mat-accordion>

    </div>

    <!-- <div class="setting-container" [ngClass]="{ 'expanded': panelOpenState3 }">

        <mat-accordion class="solution-type">
            <mat-expansion-panel (opened)="panelOpenState3 = true" (closed)="panelOpenState3 = false">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <mat-icon>insert_invitation</mat-icon>
                    <span class="title">{{ 'lop_reporting' | translate }}</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="panelOpenState3">
                  <form [formGroup]="createLOPform">
                    <mat-icon *ngIf="lopupdateMode" class="edit-icon" matTooltip="Edit" (click)="LOPEditMode()">edit</mat-icon>
                    <p>{{ "select_leave_type" | translate }}</p>
                    <div class="full-width leaveType">
                      <label *ngFor="let leave of leaveType">
                        <input [disabled]="lopupdateMode" type="checkbox" [value]="leave.leaveType" [checked]="isLeaveTypeSelected(leave.leaveType)" (change)="onCheckboxChange($event)" />
                        {{ leave.leaveType }}
                    </label>
                      <br />
                      <mat-error *ngIf="leaveTypeError">{{ 'leave_type_is_required' | translate }}</mat-error>
                    </div>
                     <span>{{ "unpaid_leave" | translate }}</span>
                    <mat-radio-group [disabled]="lopupdateMode" formControlName="unpaidLeaveMarking" required>
                      <mat-radio-button [disabled]="lopupdateMode" value="Carry Over to Next Pay">{{ "carry_over_next_pay" | translate }}</mat-radio-button>
                      <mat-radio-button [disabled]="lopupdateMode" value="Loss of Pay">{{ "loss_of_pay" | translate }}</mat-radio-button>
                    </mat-radio-group>
                    <br /> 
                    <mat-error class="lop-leave-error" *ngIf="createLOPform.get('unpaidLeaveMarking')?.invalid && createLOPform.get('unpaidLeaveMarking')?.touched">
                      {{ "required" | translate }}
                    </mat-error>
                    <div class="probation-content">
                      <label>
                        {{ "maximum_lop_count" | translate }}
                        <input type="text" class="notice-input" [readonly]="lopupdateMode" formControlName="maxLossOfPayCount" (keypress)="onlyNumberKey($event)" maxlength="3" />
                      </label>
                      <br />
                      <mat-error *ngIf="createLOPform.get('maxLossOfPayCount')?.invalid && createLOPform.get('maxLossOfPayCount')?.touched">
                        {{ "max_lop_count_is_required" | translate }}
                      </mat-error>
                    </div>
                    <button *ngIf="!lopupdateMode && !lopeditMode" class="submit-button" (click)="submitLOPForm()" type="button">{{ 'save' | translate }}</button>
                    <button *ngIf="!lopupdateMode && !lopeditMode" class="cancel-button cancel-btn" type="reset">{{ 'cancel' | translate }}</button>
                    <button *ngIf="lopupdate && showLopUpdate" class="submit-button" (click)="lossOfPayUpdate()" type="button">{{ 'update' | translate }}</button>
                    <button *ngIf="lopupdate && showLopUpdate" class="cancel-button cancel-btn" (click)="cancelLOP()">{{ 'cancel' | translate }}</button>
                  </form>
                </div>
              </mat-expansion-panel>
              
              
        </mat-accordion>
    </div> -->

    <div class="setting-container" [ngClass]="{ 'expanded': panelOpenState4 }">
        <mat-accordion class="solution-type">
            <mat-expansion-panel (opened)="panelOpenState4 = true" (closed)="panelOpenState4 = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon>date_range</mat-icon> <span class="title">{{'attendance_configuration' |
                            translate}}</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div *ngIf="panelOpenState4">
                    <form [formGroup]="createAttendanceform">
                        <div class="min-hrs">
                            <mat-icon *ngIf="attendanceupdateMode" class="edit-icon" matTooltip="Edit"
                                (click)="attendanceeditMode()">edit</mat-icon>
                            <label>
                                {{"minimum_hr_required"|translate}}
                                <input type="number" (keypress)="onlyNumberKey($event)" [readonly]="attendanceupdateMode"
                                    class="notice-input" formControlName="minHrsPerDay" maxlength="2" min="1" max="24"
                                    (input)="updateMinHoursError()">
                            </label><br>
                            <mat-error
                                *ngIf="createAttendanceform.get('minHrsPerDay')?.errors && (createAttendanceform.get('minHrsPerDay')?.dirty || createAttendanceform.get('minHrsPerDay')?.touched)">
                                <span
                                    *ngIf="createAttendanceform.get('minHrsPerDay')?.errors?.['required']">{{"min_is_required"|translate}}</span>
                                <span *ngIf="createAttendanceform.get('minHrsPerDay')?.errors?.['max']">{{'max_hr_allowed'|translate}}</span>
                                <span
                                    *ngIf="createAttendanceform.get('minHrsPerDay')?.errors?.['maxHoursExceeded']">{{'max_hr_allowed'|translate}}</span>
                            </mat-error>
                        </div>

                        <div class="max-hrs">
                            <label>
                                {{"max_hr_is_required"|translate}}
                                <input type="number" class="notice-input" [readonly]="attendanceupdateMode"
                                    formControlName="maxHrsPerDay" (keypress)="onlyNumberKey($event)" min="1" max="24"
                                    maxlength="2" (input)="updateMaxHoursError()">
                            </label><br>
                            <mat-error
                                *ngIf="createAttendanceform.get('maxHrsPerDay')?.errors && (createAttendanceform.get('maxHrsPerDay')?.dirty || createAttendanceform.get('maxHrsPerDay')?.touched)">
                                <span
                                    *ngIf="createAttendanceform.get('maxHrsPerDay')?.errors?.['required']">{{"max_is_required"|translate}}</span>
                                <span *ngIf="createAttendanceform.get('maxHrsPerDay')?.errors?.['max']">{{'max_hr_allowed'|translate}}</span>
                                <span
                                    *ngIf="createAttendanceform.get('maxHrsPerDay')?.errors?.['maxHoursExceeded']">{{'max_hr_allowed'|translate}}</span>
                                <span *ngIf="createAttendanceform.get('maxHrsPerDay')?.errors?.['maxLessThanMin']">{{'max_must_greater_than_min'|translate}}</span>
                                <span *ngIf="createAttendanceform.hasError('invalidMaxHours')">{{'max_must_greater_than_min'|translate}}</span>
                            </mat-error>
                        </div>
                        <span>{{"attendance_submitted_in"|translate}}</span>
                        <mat-radio-group [disabled]="attendanceupdateMode" formControlName="attendanceSubmittedIn"
                            required>
                            <mat-radio-button [disabled]="attendanceupdateMode"
                                value="weekly">{{"weekly"|translate}}</mat-radio-button>
                            <mat-radio-button value="fortnight">{{"fortnight" | translate}}</mat-radio-button>
                            <mat-radio-button [disabled]="attendanceupdateMode" value="monthly">{{"monthly" |
                                translate}}</mat-radio-button>
                        </mat-radio-group>
                        <mat-error class="leave-error"
                            *ngIf="createAttendanceform.get('attendanceSubmittedIn')?.invalid && createAttendanceform.get('attendanceSubmittedIn')?.touched">
                            {{"required"|translate}}
                        </mat-error>
                        <div class="buttons">
                            <button *ngIf="!attendanceupdateMode && !attendanceEditMode" class="submit-button"
                                (click)="submitAttendanceForm()" type="button">{{ 'save' | translate }}</button>
                            <button *ngIf="!attendanceupdateMode && !attendanceEditMode"
                                class="cancel-button cancel-btn" type="reset">{{ 'cancel' |
                                translate }}</button>

                            <button *ngIf="attendanceupdate && showAttendanceUpdate" class="submit-button"
                                (click)="updateAttendanceConfiguration()" type="button">{{ 'update' | translate
                                }}</button>
                            <button *ngIf="attendanceupdate && showAttendanceUpdate" class="cancel-button cancel-btn"
                                (click)="cancelAttendace()">{{ 'cancel' |
                                translate }}</button>
                        </div>
                    </form>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div class="setting-container" [ngClass]="{ 'expanded': panelOpenState5 }">

        <mat-accordion class="solution-type">
            <mat-expansion-panel (opened)="panelOpenState5 = true" (closed)="panelOpenState5 = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon>star</mat-icon> <span class="title">
                            {{'company_configuration' | translate}}</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="panelOpenState5">
                    <form [formGroup]="appraisalForm">
                        <div class="probation-content">
                            <mat-icon *ngIf="appraisalCycleupdateMode" class="edit-icon" matTooltip="Edit"
                                (click)="appraisalCycleEditMode()">edit</mat-icon>
                            <span>{{"select_company_annual_cycle"|translate}}</span>
                            <mat-radio-group formControlName="appraisalCycle" required
                                [disabled]="appraisalCycleupdateMode">
                                <mat-radio-button [disabled]="appraisalCycleupdateMode"
                                    value="april to march">{{"april_to_march"|translate}}</mat-radio-button>
                                <mat-radio-button [disabled]="appraisalCycleupdateMode"
                                    value="january to december">{{"jan_to_dec"|translate}}</mat-radio-button>
                            </mat-radio-group><br>
                            <mat-error class="leave-error"
                                *ngIf="appraisalForm.get('appraisalCycle')?.invalid && appraisalForm.get('appraisalCycle')?.touched">
                                {{"please_select_company_annual_cycle"|translate}}
                            </mat-error>
                        </div>

                        <div class="button-div">
                            <div>
                                <button *ngIf="!appraisalCycleupdateMode && !appraisalCycleeditMode" class="cancel-btn"
                                    type="reset">
                                    {{'cancel' |translate }}
                                </button>
                                <button *ngIf="!appraisalCycleupdateMode && !appraisalCycleeditMode"
                                    (click)="saveAppraisal()" type="button">
                                    {{ 'save' | translate }}
                                </button>
                                <button *ngIf="appraisalCycleupdate && appraisalCycleUpdate" class="cancel-btn"
                                    (click)="cancelAappraisalCycle()">{{ 'cancel' |translate }}
                                </button>
                                <button *ngIf="appraisalCycleupdate && appraisalCycleUpdate" (click)="saveAppraisal()"
                                    type="button">{{ 'update' | translate }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

            </mat-expansion-panel>
        </mat-accordion>
    </div>

    <div class="setting-container" [ngClass]="{ 'expanded': panelOpenState6 }">
        <mat-accordion class="solution-type">
            <mat-expansion-panel (opened)="panelOpenState6 = true" (closed)="panelOpenState6 = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon>schedule</mat-icon>
                        <span class="title">{{ 'notice_period' | translate }}</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="panelOpenState6">
                    <form [formGroup]="noticePeriodForm">
                        <div class="approveLeave">
                            <mat-icon *ngIf="noticePeriodupdateMode" class="edit-icon" matTooltip="Edit"
                                (click)="noticePeriodEditMode()">edit</mat-icon>
                            <label>
                                {{ 'notice_period_days' | translate }}
                                <input type="text" class="notice-input" [readonly]="noticePeriodupdateMode"
                                    formControlName="noticePeriodDays" (keypress)="onlyNumberKey($event)"
                                    maxlength="3" />
                                {{ 'days' | translate }}
                            </label>
                            <br />
                            <mat-error
                                *ngIf="noticePeriodForm.get('noticePeriodDays')?.invalid && noticePeriodForm.get('noticePeriodDays')?.touched">
                                {{ 'required' | translate }}
                            </mat-error>
                        </div>

                        <div class="button-div">
                            <div>
                                <button *ngIf="!noticePeriodupdateMode && !noticePeriodeditMode" class="cancel-btn"
                                    type="reset">
                                    {{ 'cancel' | translate }}
                                </button>
                                <button *ngIf="!noticePeriodupdateMode && !noticePeriodeditMode"
                                    (click)="saveNoticePeriod()" type="button">
                                    {{ 'save' | translate }}
                                </button>
                                <button *ngIf="noticePeriodupdate && noticePeriodUpdate" class="cancel-btn"
                                    (click)="cancelNoticePeriod()">
                                    {{ 'cancel' | translate }}
                                </button>
                                <button *ngIf="noticePeriodupdate && noticePeriodUpdate" (click)="saveNoticePeriod()"
                                    type="button">
                                    {{ 'update' | translate }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="setting-container" [ngClass]="{ 'expanded': panelOpenState7 }">
        <mat-accordion class="solution-type">
            <mat-expansion-panel (opened)="panelOpenState7 = true" (closed)="panelOpenState7 = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon>person</mat-icon>
                        <span class="title">{{'employee_id_configuration' | translate }}</span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngIf="panelOpenState7">
                    <form [formGroup]="employeeIdConfigurationForm">
                        <div class="id-configurations">
                            <mat-icon *ngIf="employeeIdupdateMode" class="edit-icon" matTooltip="Edit"
                                (click)="employeeIdConfigEditMode()">edit</mat-icon>
                            <label>
                                {{ 'id_configuration' | translate }}
                                <input type="text" class="idconfiguration-input" [readonly]="employeeIdupdateMode"
                                    formControlName="displayId" (keypress)="AallowedNumberAndChar($event)"
                                    maxlength="6" />
                            </label>
                            <br />
                            <mat-error
                                *ngIf="employeeIdConfigurationForm.get('displayId')?.invalid && employeeIdConfigurationForm.get('displayId')?.touched">
                                {{ 'required' | translate }}
                            </mat-error>
                            <br>
                            <span class="id-configuration">{{'id_config_content'|translate}}</span>
                        </div>

                        <div class="button-div">
                            <div>
                                <button *ngIf="!employeeIdupdateMode && !employeeIdEditMode" class="cancel-btn"
                                    type="reset">
                                    {{ 'cancel' | translate }}
                                </button>
                                <button *ngIf="!employeeIdupdateMode && !employeeIdEditMode"
                                    (click)="saveEmployeeIdConfiguration()" type="button">
                                    {{ 'save' | translate }}
                                </button>
                                <button *ngIf="employeeIdupdate && employeeIdUpdate" class="cancel-btn"
                                    (click)="cancelEmployeeIdConfig()">
                                    {{ 'cancel' | translate }}
                                </button>
                                <button *ngIf="employeeIdupdate && employeeIdUpdate"
                                    (click)="saveEmployeeIdConfiguration()" type="button">
                                    {{ 'update' | translate }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>