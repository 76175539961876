import { Component, OnInit } from '@angular/core';
import { ColumnConfig } from 'src/app/config/coloum-config';
import { CustomerService } from 'src/app/services/admin-model-services/customer/customer.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})

export class CustomerComponent implements OnInit {

  customerColDef: any;
  customerData!: any;
  customerTableCol = ColumnConfig.customerColumns;

  constructor(private customerService: CustomerService) { }

  ngOnInit(): void {
    this.customerColDef = {
      sortable: true,
      filter: true,
      resizable: true
    };
    this.getCustomerData();
  }

  getCustomerData() {
    this.customerService.getCustomerData().subscribe((data: any) => {
      this.customerData = data.data;
    });
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

}
