import { Component } from '@angular/core';
import { BirthdayListService } from 'src/app/services/birthday-list/birthday-list.service';

@Component({
  selector: 'app-birthday-list',
  templateUrl: './birthday-list.component.html',
  styleUrls: ['./birthday-list.component.scss']
})
export class BirthdayListComponent {
  birthdayList: Birthday[] = [];
constructor (private birthdayListService:BirthdayListService) {
  this.birthdayListService.getBirthdayList().subscribe(birthday => {
    this.birthdayList = birthday.data;
  });

}
}

export interface Birthday {
  employeeName: string,
  employeeId: any,
  birthDate: Date
}