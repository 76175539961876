import { NgModule } from '@angular/core';
import { AddEmployeeComponent } from './components/admin/add-employee/add-employee/add-employee.component';
import { BasicDetailsComponent } from './components/admin/add-employee/basic-details/basic-details.component';
import { PaymentInformationComponent } from './components/admin/add-employee/payment-information/payment-information.component';
import { PersonalInformationComponent } from './components/admin/add-employee/personal-information/personal-information.component';
import { RegistrationComponent } from './components/admin/registration/registration.component';
import { PageNotFoundComponent } from './components/shared/page-not-found/page-not-found.component';
import { AppConstant } from './constants/app.constants';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { AgGridComponent } from './components/ag-grid/ag-grid.component';
import { SalaryDetailsComponent } from './components/admin/add-employee/salary-details/salary-details.component';
import { ChangePasswordComponent } from './components/forgot-password/change-password/change-password.component';
import { DashboardPageComponent } from './components/admin/dashboard-page/dashboard-page.component';
import { CompanyProfileComponent } from './components/company-profile/company-profile.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password/forgot-password.component';
import { RouterModule, Routes } from '@angular/router';
import { AddBankDetailsComponent } from './components/admin/add-bank-details/add-bank-details.component';
import { SalaryStructureComponent } from './components/admin/salary-structure/salary-structure.component';
import { ViewEmployeeProfileComponent } from './components/view-employee-profile/view-employee-profile.component';
import { CompanyLeaveStuctureComponent } from './components/leave-application/hr-side/company-leave-stucture/company-leave-stucture.component';
import { ViewDepartmentComponent } from './components/admin/view-department/view-department.component';
import { ViewDesignationComponent } from './components/admin/view-designation/view-designation.component';
import { EmployeeLeaveDetailsComponent } from './components/leave-application/hr-side/employee-leave-details/employee-leave-details.component';
import { SalaryBreakupComponent } from './components/salary-breakup/salary-breakup.component';
import { LeaveHistoryComponent } from './components/leave-application/employee-side/leave-history/leave-history.component';
import { ApplyLeaveComponent } from './components/leave-application/employee-side/apply-leave/apply-leave.component';
import { AdminGuard } from './services/route-guard/admin.guard';
import { VerifyEmailComponent } from './components/verify-email/verify-email/verify-email.component';
import { CompanyBankDetailsComponent } from './components/admin/company-bank-details/company-bank-details.component';
import { EmployeeDetailsComponent } from './components/admin/add-employee/employee-details/employee-details.component';
import { PayRunComponent } from './components/pay-run/pay-run/pay-run.component';
import { AttendanceComponent } from './components/attendance/employee/attendance/attendance.component';
import { ManagersEmployeeListComponent } from './components/attendance/ag-grid/managers-employee-list/managers-employee-list.component';
import { AttendanceConfigurationComponent } from './components/attendance/attendance-configuration/attendance-configuration.component';
import { ManagerSidenavComponent } from './components/shared/manager-approvals/manager-sidenav/manager-sidenav.component';
import { ViewEmployeeAttendanceComponent } from './components/shared/manager-approvals/view-employee-attendance/view-employee-attendance.component';
import { SupportComponent } from './components/admin/support/support/support.component';
import { UserSubscriptionViewComponent } from './components/admin/user-subscription-view/user-subscription-view/user-subscription-view.component';
import { TransactionHistoryComponent } from './components/admin/transactionHistory/transaction-history/transaction-history.component';
import { SubscriptionPlanComponent } from './components/admin/subscription-plan/subscription-plan/subscription-plan.component';
import { PaymentReceivedViewComponent } from './components/shared/subscriptions/components/dashboard/components/payment-received-view/payment-received-view.component';
import { CustomerComponent } from './components/shared/subscriptions/components/dashboard/containers/customer/customer.component';
import { CreateSubscriptionsComponent } from './components/shared/subscriptions/components/create-subscriptions/create-subscriptions.component';
import { AdminDashboardComponent } from './components/shared/subscriptions/components/dashboard/containers/admin-dashboard/admin-dashboard.component';
import { ReportComponent } from './components/shared/subscriptions/components/dashboard/containers/report/report.component';
import { AdminModuleSidebarComponent } from './components/shared/admin-module-sidebar/admin-module-sidebar.component';
import { LandingPageComponent } from './components/shared/landing-page/landing-page/landing-page.component';
import { BookDemoComponent } from './components/shared/landing-page/book-demo/book-demo.component';
import { PrivacyPolicyComponent } from './components/shared/landing-page/policy/privacy-policy/privacy-policy.component';
import { EmailConfigurationComponent } from './components/admin/email-configuration/email-configuration.component';
import { DirectDepositComponent } from './components/direct-deposit/direct-deposit.component';
import { BankIntegrtaionSetupComponent } from './components/bank-integrations/bank-integrtaion-setup/bank-integrtaion-setup.component';
import { ViewIntegratedBankComponent } from './components/bank-integrations/view-integrated-bank/view-integrated-bank.component';
import { AddBulkEmployeeComponent } from './components/admin/add-employee/add-bulk-employee/add-bulk-employee.component';
import { ChoosePaymentComponent } from './components/admin/subscription-plan/choose-payment/choose-payment.component';
import { PaymentDiscriptionComponent } from './components/admin/subscription-plan/payment-discription/payment-discription.component';
import { GodaddySslComponent } from './components/shared/godaddy-ssl/godaddy-ssl.component';
import { TermsAndConditionsComponent } from './components/shared/landing-page/policy/terms-and-conditions/terms-and-conditions.component';
import { CancellationRefundPolicyComponent } from './components/shared/landing-page/policy/cancellation-refund-policy/cancellation-refund-policy.component';
import { ShippingDeliveryPolicyComponent } from './components/shared/landing-page/policy/shipping-delivery-policy/shipping-delivery-policy.component';
import { AssignManagerComponent } from './components/admin/assign-manager/assign-manager.component';
import { TaxDeclarationConfigurationComponent } from './components/shared/tax-declaration-configuration/tax-declaration-configuration.component';
import { ViewCategoryBySectionIdComponent } from './components/shared/view-category-by-section-id/view-category-by-section-id.component';
import { RegimeSlabComponent } from './components/shared/regime-slab/regime-slab.component';
import { ViewTaxDeclarationComponent } from './components/tax-declaration/view-tax-declaration/view-tax-declaration/view-tax-declaration.component';
import { DeclaredFormComponent } from './components/tax-declaration/declared-form/declared-form.component';
import { StartFreshComponent } from './components/tax-declaration/start-fresh/start-fresh.component';
import { SettingConfigurationComponent } from './components/admin/setting-configuration/setting-configuration.component';
import { AddRolesComponent } from './components/admin/add-roles/add-roles.component';
import { ExitProcessComponent } from './components/exit-process/employee-side/exit-process.component';
import { ViewResignationComponent } from './components/exit-process/employee-side/view-resignation/view-resignation.component';
import { EmployeeResignationsComponent } from './components/shared/manager-approvals/employee-resignations/employee-resignations.component';
import { ViewEmployeeResignationComponent } from './components/shared/manager-approvals/view-employee-resignation/view-employee-resignation.component';
import { ExitDetailsComponent } from './components/admin/exit-process/exit-details/exit-details.component';
import { FinalSettlementComponent } from './components/admin/exit-process/final-settlement/final-settlement.component';
import { FinalSettlementPayrunDetailsComponent } from './components/admin/exit-process/final-settlement-payrun-details/final-settlement-payrun-details.component';
import { DeclarationApprovalComponent } from './components/tax-declaration/declaration-approval/declaration-approval.component';
import { ViewDeclaredListComponent } from './components/tax-declaration/view-declared-list/view-declared-list.component';
import { AssetManagementDashboardComponent } from './components/asset-management/asset-management-dashboard/asset-management-dashboard/asset-management-dashboard.component';
import { AssetDetailsComponent } from './components/asset-management/asset-details/asset-details.component';
import { HrViewEmployeeResignationComponent } from './components/shared/hr-resignation-approval/hr-view-employee-resignation/hr-view-employee-resignation.component';
import { AddGoalExcelComponent } from './components/appraisal/components/hr/add-goal-excel/add-goal-excel.component';
import { EmployeeListUnderManagerComponent } from './components/appraisal/components/manager-side/employee-list-under-manager/employee-list-under-manager.component';
import { EmployeeBasicGoalsComponent } from './components/appraisal/components/employee-side/employee-basic-goals/employee-basic-goals.component';
import { EmployeeAppraisalViewComponent } from './components/appraisal/components/employee-side/employee-appraisal-view/employee-appraisal-view.component';
import { EmployeeAppraisalEvolutionComponent } from './components/appraisal/components/employee-side/employee-appraisal-evolution/employee-appraisal-evolution.component';
import { EmployeeGoalsPlanComponent } from './components/appraisal/components/manager-side/employee-goals-plan/employee-goals-plan.component';
import { AddGoalsComponent } from './components/appraisal/components/employee-side/add-goals/add-goals.component';
import { EmployeePerformanceGoalComponent } from './components/appraisal/components/employee-side/employee-performance-goal/employee-performance-goal.component';
import { SupplierDetailsComponent } from './components/asset-management/supplier-details/supplier-details.component';
import { SupplierInfoComponent } from './components/asset-management/supplier-info/supplier-info.component';
import { EventManagementComponent } from './components/event-management/event-management/event-management.component';
import { AddEventComponent } from './components/event-management/add-event/add-event.component';
import { ViewTimelineComponent } from './components/asset-management/view-timeline/view-timeline.component';
import { EmployeeAssetDetailsComponent } from './components/asset-management/employee-asset-details/employee-asset-details.component';
import { AddSupplierComponent } from './components/asset-management/add-supplier/add-supplier.component';
import { OrderForAssetComponent } from './components/asset-management/order-for-asset/order-for-asset.component';
import { AddApproverComponent } from './components/admin/add-approver/add-approver.component';
import { ViewAllProjectComponent } from './components/project-management/view-all-project/view-all-project.component';
import { AddProjectPopupComponent } from './components/project-management/add-project-popup/add-project-popup.component';
import { ViewCostingDetailsComponent } from './components/project-management/view-costing-details/view-costing-details.component';
import { CreateVoucherComponent } from './components/shared/create-voucher/create-voucher.component';
import { ViewVoucherComponent } from './components/shared/view-voucher/view-voucher.component';
import { ChooseVoucherComponent } from './components/shared/choose-voucher/choose-voucher.component';
import { IncidentRequestComponent } from './components/helpdesk/incident-request/incident-request.component';
import { RequestorInformationComponent } from './components/helpdesk/requestor-information/requestor-information.component';
import { ViewIncidentRequestComponent } from './components/helpdesk/view-incident-request/view-incident-request.component';
import { HelpDeskComponent } from './components/helpdesk/help-desk/help-desk.component';
import { ProjectApprovalComponent } from './components/project-management/project-approval/project-approval.component';
import { PayrunApprovalComponent } from './components/admin/payrun-approval/payrun-approval.component';
import { PayrunApprovalDetailsComponent } from './components/admin/payrun-approval-details/payrun-approval-details.component';
import { ViewTraningComponent } from './components/admin/traning-module/view-traning/view-traning.component';
import { AddTraningComponent } from './components/admin/traning-module/add-traning/add-traning.component';
import { ResolutionComponent } from './components/helpdesk/resolution/resolution.component';
import { ViewServiceRequestComponent } from './components/helpdesk/view-service-request/view-service-request.component';
import { ViewTrainingDetailsComponent } from './components/admin/traning-module/view-training-details/view-training-details.component';
import { ViewTrainingSummaryComponent } from './components/admin/traning-module/view-training-summary/view-training-summary.component';
import { EmployeeAssignTrainingDetailsComponent } from './components/admin/traning-module/employee-assign-training-details/employee-assign-training-details.component';
import { AssignTrainingToEmployeeComponent } from './components/shared/manager-approvals/assign-training-to-employee/assign-training-to-employee.component';
import { BirthdayListComponent } from './components/helpdesk/birthday-list/birthday-list.component';
import { PayRunGroupComponent } from './components/pay-run/pay-run-group/pay-run-group.component';
import { SalaryAgGridComponent } from './components/salary-ag-grid/salary-ag-grid.component';
import { PayRunEmployeeListComponent } from './components/pay-run-employee-list/pay-run-employee-list.component';
import { CompanyBankInfoComponent } from './components/company-bank-details/company-bank-info.component';
import { CreateNewPasswordComponent } from './components/forgot-password/create-new-password/create-new-password.component';
import { TaskManagementComponent } from './components/task-management/task-management/task-management.component';
import { BoardTrackingComponent } from './components/task-management/board-tracking/board-tracking.component';
import { ViewProjectDashboardComponent } from './components/task-management/view-project-dashboard/view-project-dashboard.component';
import { ViewSprintComponent } from './components/task-management/view-sprint/view-sprint.component';
import { BacklogComponent } from './components/task-management/backlog/backlog.component';
import { TaskManagementSidenavComponent } from './components/task-management/task-management-sidenav/task-management-sidenav.component';
import { Component } from 'ag-grid-community';
import { ViewReportComponent } from './components/task-management/view-report/view-report.component';
import { TaskComponent } from './components/task-management/task/task.component';
import { FamilyAndEmergencyContactInfoComponent } from './components/family-and-emergency-contact-info/family-and-emergency-contact-info/family-and-emergency-contact-info.component';
import { ViewFamilyAndEmergencyDetailsComponent } from './components/family-and-emergency-contact-info/view-family-and-emergency-details/view-family-and-emergency-details.component';
import { AddFamilyAndEmergencyContactsComponent } from './components/family-and-emergency-contact-info/add-family-and-emergency-contacts/add-family-and-emergency-contacts.component';

const adminRoutes: Routes = [
  { path: AppConstant.LOGIN_PAGE, component: DashboardPageComponent },
  { path: AppConstant.DASHBOARD, component: DashboardPageComponent },
  { path: AppConstant.ADD_BANK_DETAILS, component: AddBankDetailsComponent },
  { path: AppConstant.EMPLOYEE_PROFILE, component: ViewEmployeeProfileComponent },
  { path: AppConstant.PAY_RUN_GROUP, component: PayRunGroupComponent },
  { path: AppConstant.PAY_RUN, component: PayRunComponent },
  { path: AppConstant.PAY_RUN_EMPLOYEE_LIST + AppConstant.PARAMS_ID, component: PayRunEmployeeListComponent },
  { path: AppConstant.ADD_EMPLOYEE, component: AddEmployeeComponent },
  { path: AppConstant.BASIC_DETAILS, component: BasicDetailsComponent },
  { path: AppConstant.VIEW_EMPLOYEE + AppConstant.PARAMS_ID, component: EmployeeDetailsComponent },
  { path: AppConstant.SALARY_STRUCTURE, component: SalaryStructureComponent },
  { path: AppConstant.PERSONAL_INFORMATION, component: PersonalInformationComponent },
  { path: AppConstant.PAYMENT_INFORMATION, component: PaymentInformationComponent },
  { path: AppConstant.VIEW_COMPANY_PROFILE, component: CompanyProfileComponent },
  { path: AppConstant.SALARY_INFO, component: AgGridComponent },
  { path: AppConstant.ACTIVE_EMPLOYEES, component: AgGridComponent },
  { path: AppConstant.VIEW_ALL_DEPARTMENT, component: ViewDepartmentComponent },
  { path: AppConstant.VIEW_ALL_DESIGNATION, component: ViewDesignationComponent },
  { path: AppConstant.EMPLOYEE_LEAVE_DETAILS, component: EmployeeLeaveDetailsComponent },
  { path: AppConstant.ADD_BANK_DETAILS, component: AddBankDetailsComponent },
  { path: AppConstant.ADD_EMPLOYEE, component: AddEmployeeComponent },
  { path: AppConstant.ADD_BANK_DETAILS, component: AddBankDetailsComponent },
  { path: AppConstant.LEAVE_STRUCTURE, component: CompanyLeaveStuctureComponent },
  { path: AppConstant.ATTENDANCE_CONFIGURATION, component: AttendanceConfigurationComponent },
  { path: AppConstant.SUPPORT, component: SupportComponent },
  { path: AppConstant.BANK_INTEGRATION, component: BankIntegrtaionSetupComponent },
  { path: AppConstant.VIEW_INTEGRATED_BANK, component: ViewIntegratedBankComponent },
  { path: AppConstant.DIRECT_DEPOSIT, component: DirectDepositComponent },
  { path: AppConstant.EMAIL_CONFIGURATION, component: EmailConfigurationComponent },
  { path: AppConstant.USER_SUBSCRIPTION_VIEW, component: UserSubscriptionViewComponent },
  { path: AppConstant.TRANSACTION_HISTORY, component: TransactionHistoryComponent },
  { path: AppConstant.SUBSCRIPTION_PLAN, component: SubscriptionPlanComponent },
  { path: AppConstant.ADD_BULK_DATA, component: AddBulkEmployeeComponent },
  { path: AppConstant.ASSIGN_MANAGER, component: AssignManagerComponent },
  { path: AppConstant.ASSET_MANAGEMENT, component: AssetManagementDashboardComponent },
  { path: AppConstant.EVENT_MANAGEMENT, component: EventManagementComponent },
  { path: AppConstant.ADD_EVENT, component: AddEventComponent },
  { path: AppConstant.ASSET_DETAILS, component: AssetDetailsComponent },
  { path: AppConstant.SUPPLIER_DETAILS, component: SupplierDetailsComponent },
  { path: AppConstant.ADD_SUPPLIER_DETAILS, component: AddSupplierComponent },
  { path: AppConstant.OREDR_FOR_ASSET, component: OrderForAssetComponent },
  { path: AppConstant.VIEW_TIMELINE + AppConstant.PARAMS_ID, component: ViewTimelineComponent },
  { path: AppConstant.SUPPLIER_INFO + AppConstant.PARAMS_ID, component: SupplierInfoComponent },
  { path: AppConstant.GENERAL_SETTING, component: SettingConfigurationComponent },
  { path: AppConstant.ADD_ROLES, component: AddRolesComponent },
  { path: AppConstant.EXIT_DETAILS, component: ExitDetailsComponent },
  { path: AppConstant.FINAL_SETTLEMENT, component: FinalSettlementComponent },
  { path: AppConstant.SHOW_POI_DETAILS, component: FinalSettlementPayrunDetailsComponent },
  { path: AppConstant.DECLARATION_APPROVAL, component: DeclarationApprovalComponent },
  { path: AppConstant.UPLOAD_EXCEL, component: AddGoalExcelComponent },
  { path: AppConstant.ADD_APPROVALS, component: AddApproverComponent },
  { path: AppConstant.VIEW_TRAINING, component: ViewTraningComponent },
  { path: AppConstant.ADD_TRANING, component: AddTraningComponent },
  { path: AppConstant.VIEW_DETAILS_OF_TRAINING + AppConstant.SEPERATOR + AppConstant.REQUEST_ID, component: ViewTrainingSummaryComponent },
  { path: AppConstant.VIEW_EMPLOYEE_TRANING_DETAILS + AppConstant.SEPERATOR + AppConstant.REQUEST_ID, component: EmployeeAssignTrainingDetailsComponent },
  { path: AppConstant.COMPANY_BANK_DETAIL, component: CompanyBankInfoComponent },
  { path: AppConstant.TASK_MANAGEMENT, component: TaskManagementComponent },
  { path: AppConstant.PROJECT_DASHBOARD, component: ViewProjectDashboardComponent },
  { path: AppConstant.VIEW_SPRINT + AppConstant.PARAMS_ID, component: ViewSprintComponent },
  { path: AppConstant.VIEW_EMPLOYEE_FAMILY_AND_EMERGENCY_CONTACT_INFO, component: FamilyAndEmergencyContactInfoComponent },
  { path: 'view-employee-family-and-emergency-info/:id', component: FamilyAndEmergencyContactInfoComponent },
  {path:AppConstant.COMPANY_BANK_DETAIL,component:CompanyBankInfoComponent}
];

const employeeRoutes: Routes = [
  { path: AppConstant.DASHBOARD, component: DashboardPageComponent },
  { path: AppConstant.LEAVE_HISTORY, component: LeaveHistoryComponent },
  { path: AppConstant.ADD_EMPLOYEE, component: AddEmployeeComponent },
  { path: AppConstant.APPLY_LEAVE, component: ApplyLeaveComponent },
  { path: AppConstant.EMPLOYEE_PROFILE, component: ViewEmployeeProfileComponent },
  { path: AppConstant.SALARY_BREAKUP, component: SalaryBreakupComponent },
  { path: AppConstant.ACTIVE_EMPLOYEES, component: AgGridComponent },
  { path: AppConstant.MANAGERS_EMPLOYEE_LIST, component: ManagersEmployeeListComponent },
  { path: AppConstant.SALARY_INFO, component: SalaryAgGridComponent },
  { path: AppConstant.PAY_RUN, component: PayRunComponent },
  { path: AppConstant.DECLARATION_APPROVAL, component: DeclarationApprovalComponent },
  { path: AppConstant.EMPLOYEE_ATTENDANCE, component: AttendanceComponent },
  { path: AppConstant.PROJECT_MANAGEMENT, component: ViewAllProjectComponent },
  { path: AppConstant.ADD_PROJRCT, component: AddProjectPopupComponent },
  { path: AppConstant.UPDATE_PROJECT + AppConstant.PARAMS_ID, component: AddProjectPopupComponent },
  { path: AppConstant.PROJECT_COSTING + AppConstant.PARAMS_ID, component: ViewCostingDetailsComponent },
  { path: AppConstant.EVENT_MANAGEMENT, component: EventManagementComponent },
  { path: AppConstant.ASSET_MANAGEMENT, component: AssetManagementDashboardComponent },
  { path: AppConstant.EMPLOYEE_PROFILE_ASSET_MANAGEMENT, component: EmployeeAssetDetailsComponent },
  { path: AppConstant.FAMILY_AND_EMERGENCY_CONTACT_INFO, component: FamilyAndEmergencyContactInfoComponent },
  { path: AppConstant.ADD_FAMILY_AND_EMERGENCY_CONTACTS, component: AddFamilyAndEmergencyContactsComponent },
  { path: 'add-family-and-emergency-contacts/:id', component: AddFamilyAndEmergencyContactsComponent },
  { path: AppConstant.VIEW_FAMILY_AND_EMERGENCY_DETAILS, component: ViewFamilyAndEmergencyDetailsComponent },
  { path: 'view-family-and-emergency-details/:id', component: ViewFamilyAndEmergencyDetailsComponent },
  {
    path: AppConstant.VIEW_TAX_DECLARATION, component: ViewTaxDeclarationComponent, children: [
      { path: AppConstant.DECLARED_FORM, component: DeclaredFormComponent },
      { path: AppConstant.START_FRESH, component: StartFreshComponent }
    ]
  },
  { path: AppConstant.EXIT_PROCESS, component: ExitProcessComponent },
  { path: AppConstant.VIEW_RESIGNATION, component: ViewResignationComponent },
  { path: AppConstant.EMPLOYEE_BASIC_GOAL, component: EmployeeBasicGoalsComponent },
  { path: AppConstant.EMPLOYEE_BASIC_GOAL + AppConstant.PARAMS_ID, component: EmployeeBasicGoalsComponent },
  { path: AppConstant.PAYRUN_APPROVAL, component: PayrunApprovalComponent },
  { path: AppConstant.VIEW_EMPLOYEE_APPRISAL, component: EmployeeAppraisalViewComponent },
  { path: AppConstant.EMPLOYEE_EVOLUTION, component: EmployeeAppraisalEvolutionComponent },
  { path: AppConstant.GOAL_PLAN, component: EmployeeGoalsPlanComponent },
  { path: AppConstant.ADD_GOALS, component: AddGoalsComponent },
  { path: AppConstant.EMPLOYEE_PERFORMANCE_GOAL_PLAN, component: EmployeePerformanceGoalComponent },
  { path: AppConstant.INCIDENT_REQUEST, component: IncidentRequestComponent },
  { path: AppConstant.REQUESTOR_INFORMATION, component: RequestorInformationComponent },
  { path: AppConstant.VIEW_REQUESTOR_INFORMATION, component: ViewIncidentRequestComponent },
  { path: AppConstant.RESOLUTION, component: ResolutionComponent },
  { path: AppConstant.HELPDESK, component: HelpDeskComponent },
  { path: AppConstant.VIEW_EMPLOYEE_TRAINING + AppConstant.REQUEST_ID, component: ViewTrainingDetailsComponent },
  { path: AppConstant.VIEW_TRAINING, component: ViewTraningComponent },
  { path: AppConstant.VIEW_REQUEST_DETAILS + AppConstant.PARAMS_ID, component: ViewServiceRequestComponent },
  { path: AppConstant.BIRTHDAY_LIST, component: BirthdayListComponent },
  { path: AppConstant.VIEW_EMPLOYEE_TRANING_DETAILS + AppConstant.SEPERATOR + AppConstant.REQUEST_ID, component: EmployeeAssignTrainingDetailsComponent },
];

const taskManagement: Routes = [
  { path: AppConstant.LANDING_PAGE, component: TaskManagementComponent },
  { path: AppConstant.PROJECTS, component: ViewAllProjectComponent },
  { path: AppConstant.BOARD_TRACKING, component: BoardTrackingComponent },
  { path: AppConstant.BOARD_TRACKING + AppConstant.PARAMS_DASHBOARD_ID + AppConstant.PARAMS_SPRINT_ID, component: BoardTrackingComponent },
  { path: AppConstant.PROJECT_DASHBOARD, component: ViewProjectDashboardComponent },
  { path: AppConstant.VIEW_SPRINT, component: ViewSprintComponent },
  { path: AppConstant.VIEW_BACKLOG, component: BacklogComponent },
  { path: AppConstant.VIEW_SPRINT + AppConstant.PARAMS_ID, component: ViewSprintComponent },
  { path: AppConstant.VIEW_REPORT, component: ViewReportComponent },
  { path: AppConstant.VIEW_TASK, component: TaskComponent }
]

const managerRoutes: Routes = [
  { path: AppConstant.MANAGERS_EMPLOYEE_LIST, component: ManagersEmployeeListComponent },
  { path: AppConstant.EMPLOYEE_ATTENDANCE, component: AttendanceComponent },
  { path: AppConstant.EMPLOYEE_LEAVE_DETAILS, component: EmployeeLeaveDetailsComponent },
  { path: AppConstant.VIEW_EMPLOYEE + AppConstant.PARAMS_ID, component: EmployeeDetailsComponent },
  { path: AppConstant.LEAVE_HISTORY, component: LeaveHistoryComponent },
  { path: AppConstant.EMPLOYEE_LEAVE_DETAILS + AppConstant.PARAMS_ID, component: LeaveHistoryComponent },
  { path: AppConstant.EMPLOYEE_SUBMITTED_ATTENDANCE + AppConstant.PARAMS_ID, component: AttendanceComponent },
  { path: AppConstant.VIEW_EMPLOYEE_RESIGNATION_DETAILS + AppConstant.PARAMS_ID, component: ViewEmployeeResignationComponent },
  { path: AppConstant.HR_VIEW_EMPLOYEE_RESIGNATION_DETAILS + AppConstant.PARAMS_ID, component: HrViewEmployeeResignationComponent },
  { path: AppConstant.EMPLOYEE_ATTENDANCE_DETAILS, component: AttendanceComponent },
  { path: AppConstant.VIEW_EMPLOYEE_ATTENDANCE, component: ViewEmployeeAttendanceComponent },
  { path: AppConstant.EMPLOYEE_RESIGNATIONS, component: EmployeeResignationsComponent },
  { path: AppConstant.DECLARATION_APPROVAL, component: ViewDeclaredListComponent },
  { path: AppConstant.DECLARATION_APPROVAL + AppConstant.PARAMS_ID, component: DeclarationApprovalComponent },
  { path: AppConstant.EMPLOYEE_LIST_UNDER_MANAGER, component: EmployeeListUnderManagerComponent },
  { path: AppConstant.VIEW_EMPLOYEE_APPRISAL + AppConstant.PARAMS_ID, component: EmployeeAppraisalViewComponent },
  { path: AppConstant.GOAL_PLAN, component: EmployeeGoalsPlanComponent },
  { path: AppConstant.EMPLOYEE_BASIC_GOAL + AppConstant.PARAMS_ID, component: EmployeeBasicGoalsComponent },
  { path: AppConstant.EMPLOYEE_PERFORMANCE_GOAL_PLAN, component: EmployeePerformanceGoalComponent },
  { path: AppConstant.PAYRUN_APPROVAL_DETAILS + AppConstant.PARAMS_ID, component: PayrunApprovalDetailsComponent },
  { path: AppConstant.PROJECT_APPROVAL, component: ProjectApprovalComponent },
  { path: AppConstant.VIEW_PROJECT_DETAILS + AppConstant.PARAMS_ID, component: AddProjectPopupComponent },
  { path: AppConstant.ASSIGN_TRAINING_TO_THE_EMPLOYEE, component: AssignTrainingToEmployeeComponent }
]

const adminModule: Routes = [
  { path: AppConstant.SUBSCRIPTION_DASHBOARD, component: AdminDashboardComponent },
  { path: AppConstant.PROFIT_LOSS_REPORT, component: PaymentReceivedViewComponent },
  { path: AppConstant.VIEW_VOUCHER, component: ViewVoucherComponent },
  { path: AppConstant.NEW_USERS_REPORT, component: PaymentReceivedViewComponent },
  { path: AppConstant.PAYMENT_RECEIVED_REPORT, component: PaymentReceivedViewComponent },
  { path: AppConstant.REPORT, component: ReportComponent },
  { path: AppConstant.EMAIL_CONFIGURATION, component: EmailConfigurationComponent },
  { path: AppConstant.TAX_DECLARATION, component: TaxDeclarationConfigurationComponent },
  { path: AppConstant.REGIME_SLAB, component: RegimeSlabComponent },
  { path: AppConstant.VOUCHER, component: CreateVoucherComponent },
  { path: AppConstant.CUSTOMER, component: CustomerComponent },
  { path: AppConstant.TAX_DECLARATION + AppConstant.PARAMS_ID, component: ViewCategoryBySectionIdComponent },
  { path: AppConstant.SUBSCRIPTION, component: CreateSubscriptionsComponent },
  { path: AppConstant.SUBSCRIPTION_PLAN, component: SubscriptionPlanComponent },
]

const routes: Routes = [
  { path: '', redirectTo: AppConstant.LANDING_PAGE, pathMatch: 'full' },
  { path: AppConstant.LANDING_PAGE, component: LandingPageComponent },
  { path: AppConstant.BOOK_DEMO, component: BookDemoComponent },
  { path: AppConstant.PRIVACY_POLICY, component: PrivacyPolicyComponent },
  { path: AppConstant.TERMS_AND_CONDITION, component: TermsAndConditionsComponent },
  { path: AppConstant.CANCELLATION_AND_REFUND_POLICY, component: CancellationRefundPolicyComponent },
  { path: AppConstant.SHIPPING_AND_DELIVERY_POLICY, component: ShippingDeliveryPolicyComponent },
  { path: AppConstant.MANAGERS_EMPLOYEE_LIST, component: ManagersEmployeeListComponent },
  { path: AppConstant.ADMIN_SIDEBAR, component: AdminModuleSidebarComponent, children: adminModule, canActivate: [AdminGuard] },
  { path: AppConstant.LOGIN_PAGE, component: LoginPageComponent },
  { path: AppConstant.REGISTRATION, component: RegistrationComponent },
  { path: AppConstant.PAGE_NOT_FOUND, component: PageNotFoundComponent },
  { path: AppConstant.VIEW_COMPANY_PROFILE, component: CompanyProfileComponent },
  { path: AppConstant.SIDENAV, component: SideNavComponent, canActivate: [AdminGuard], data: { roles: [AppConstant.HR_ROLE] }, children: adminRoutes },
  { path: AppConstant.EMPLOYEE_SIDENAV, component: SideNavComponent, canActivate: [AdminGuard], data: { roles: [AppConstant.EMPLOYEE_ROLE] }, children: employeeRoutes, },
  { path: AppConstant.MANAGER_SIDENAV, component: ManagerSidenavComponent, children: managerRoutes, canActivate: [AdminGuard] },
  { path: AppConstant.TASK_MANAGEMENT_SIDENAV, component: TaskManagementSidenavComponent, children: taskManagement, canActivate: [AdminGuard] },
  { path: AppConstant.ACTIVE_EMPLOYEES, component: AgGridComponent },
  { path: AppConstant.DASHBOARD, component: DashboardPageComponent },
  { path: AppConstant.VIEW_EMPLOYEE, component: SalaryDetailsComponent },
  { path: AppConstant.FORGOT_PASSWORD, component: ForgotPasswordComponent },
  { path: AppConstant.CREATE_NEW_PASSWORD, component: CreateNewPasswordComponent },
  { path: AppConstant.CHANGE_PASSWORD, component: ChangePasswordComponent },
  { path: AppConstant.VERIFY_EMAIL, component: VerifyEmailComponent },
  { path: AppConstant.COMPANY_BANK_DETAILS, component: CompanyBankDetailsComponent },
  { path: AppConstant.SUBSCRIPTION_PLAN, component: SubscriptionPlanComponent },
  { path: AppConstant.CHOOSE_PAYMENT, component: ChoosePaymentComponent },
  { path: AppConstant.USE_VOUCHER, component: ChooseVoucherComponent },
  { path: AppConstant.PAYMENT_DISCRIPTION, component: PaymentDiscriptionComponent },
  { path: '.well-known/pki-validation/E6542D287AF53E7D93A6CA178E6D8E3A.txt', component: GodaddySslComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }