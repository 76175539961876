<h3 class="headerName">{{'assign_asset'|translate}}</h3>
<mat-dialog-content>
    <form [formGroup]="assignedAssetForm">
        <div class="assetForm">
            <div class="assets">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{'employee_name'|translate}}</mat-label>
                    <mat-select formControlName="employeeId" #singleSelect>
                        <mat-option>
                            <ngx-mat-select-search [placeholderLabel]="'Search'" [formControl]="searchControl"
                                noEntriesFoundLabel="" [searching]="searching"
                                [preventHomeEndKeyPropagation]="true"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let employee of filteredResults" [value]="employee.id">
                            {{employee.name}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="assignedAssetForm.get('employeeId')?.hasError('required')">
                        {{'employee_name_is_required' |translate}}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{'asset_type'|translate}}</mat-label>
                    <mat-select required="*" formControlName="assetType">
                        <div *ngFor="let asset of assets">
                            <mat-option [value]="asset">{{asset}}</mat-option>
                        </div>
                    </mat-select>
                    <mat-error *ngIf="assignedAssetForm.get('assetType')?.hasError('required')">
                        {{'asset_type_is_required'|translate}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="assets">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{'model_no'|translate}}</mat-label>
                    <mat-select required="*" formControlName="modelNo">
                        <div *ngFor="let asset of assetList">
                            <mat-option [value]="asset.modelNo">{{asset.modelNo}}</mat-option>
                        </div>
                    </mat-select>
                    <mat-error *ngIf="assignedAssetForm.get('modelName')?.hasError('required')">
                        {{'model_name_is_required'|translate}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="buttons">
                <button class="cancel-btn" (click)="closeDialog()">{{'cancel'|translate}}</button>
                <button (click)="assignAsset()">{{'save'|translate}}</button>
            </div>
        </div>
    </form>
</mat-dialog-content>
<app-loader></app-loader>