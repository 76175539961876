<app-navbar></app-navbar>
<h5>
  {{'add_your_bank_details' | translate}}
</h5>
{{'connect_bank_account' | translate}}
<div class="container">
  <div class="header">
    <h2>{{'add_bank_details' | translate}}</h2>
  </div>
  <form name="bankDetailsForm" [formGroup]="bankDetailsForm">
    <div>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>{{'bank_name' | translate}}</mat-label>
        <input matInput class="input" formControlName="bankName" (keypress)="validateCharacter($event)" [maxLength]="30"/>
        <mat-error *ngIf="!bankDetailsForm.controls['bankName'].valid">
          {{'bank_name_is_required' | translate}}
        </mat-error>
      </mat-form-field>

      <div class="form-field-gap">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{'branch_name' | translate}}</mat-label>
          <input matInput type="text" class="input" formControlName="branch" [maxLength]="30" (keypress)="validateCharacterNumberAndSpace($event)"
            required />
          <mat-error *ngIf="!bankDetailsForm.controls['branch'].valid">
            {{'branch_name_is_required' | translate}}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{'account_number' | translate}}</mat-label>
          <input matInput (keypress)="validateNumber($event)" class="input" formControlName="bankAccountNo"
          minlength="8" [maxLength]="18" />
          <mat-error *ngIf="!bankDetailsForm.controls['bankAccountNo'].valid">
            {{'account_number_required'| translate}}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-field-gap">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{'ifsc_code'| translate}}</mat-label>
          <input matInput type="text" class="input" [maxLength]="11" (keypress)="alphanumericAllowed($event)"
            formControlName="ifscCode" required />
          <mat-error *ngIf="!bankDetailsForm.controls['ifscCode'].valid">
            {{'ifsc_code_is_required'| translate}}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width" appearance="outline">
          <mat-label class="formLabel"> {{'account_type'| translate}}</mat-label>
          <mat-select class="select-mat-input" formControlName="accountType" required>
            <mat-option *ngFor="let accountType of accountType" [value]="accountType.accountType">
              {{accountType.selectedAccount}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="!bankDetailsForm controls['accountType'].valid">{{'account_type_is_required'|
            translate}}</mat-error>
        </mat-form-field>
      </div>
      <button class="button" (click)="addBankDetails()" type="submit" color="primary">{{'save_bank_details'|
        translate}}</button>
    </div>
  </form>
</div>