import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { FamilyAndEmergencyContactsService } from 'src/app/services/family-and-emergency-contacts/family-and-emergency-contacts.service';
import { ToastrService } from 'ngx-toastr';
import { FormatUtil } from 'src/app/utils/format.utils';

@Component({
  selector: 'app-view-family-and-emergency-details',
  templateUrl: './view-family-and-emergency-details.component.html',
  styleUrls: ['./view-family-and-emergency-details.component.scss']
})
export class ViewFamilyAndEmergencyDetailsComponent implements OnInit {
  familyEmergencyContact: any;
  contactId: any;

  constructor(private router: Router, private route: ActivatedRoute, private familyAndEmergencyContactService: FamilyAndEmergencyContactsService, private toasterService: ToastrService,) { }

  ngOnInit(): void {
    this.contactId = this.route.snapshot.params['id'];
    if (this.contactId != null || this.contactId != undefined) {
      this.getFamilyAndEmergencyContacts(this.contactId);
    }
  }

  getFamilyAndEmergencyContacts(id: any) {
    this.familyAndEmergencyContactService.getFamilyAndEmergencyContacts(id, sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
      (emergencyContactData: any) => {
        if (emergencyContactData.status == 200) {
          this.familyEmergencyContact = emergencyContactData.data;
        }
        else {
          this.toasterService.clear()
          this.toasterService.error(emergencyContactData.message);
        }
      }
    );
  }

  capitalizeFirstLetter(value: string) {
    return FormatUtil.capitalizeFirstLetter(value)
  }

  goBackToEmployeeProfile() {
    this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_PROFILE]);
  }
}