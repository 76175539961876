<mat-card id="chart">
    <div class="title">
<b>{{'subscription_view'|translate}}</b>
        <div class="select-container">
            <mat-form-field appearance="outline">
                <mat-select [(ngModel)]="selectedTimeRange" (ngModelChange)="onTimeRangeChange()">
                    <mat-option value="monthly">{{'monthly'|translate}}</mat-option>
                    <mat-option value="yearly">{{'yearly'|translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <apx-chart [series]="subscription.series" [chart]="subscription.chart" [plotOptions]="subscription.plotOptions"
        [labels]="subscription.labels" [fill]="subscription.fill" [stroke]="subscription.stroke"></apx-chart>
</mat-card>