import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-pf-configuration',
  templateUrl: './pf-configuration.component.html',
  styleUrls: ['./pf-configuration.component.scss']
})
export class PfConfigurationComponent {
  pfForm: FormGroup;
  employeeContributionRates: string[] = ['5%', '10%', '15%'];
  employerContributionRates: string[] = ['5%', '8%', '12%'];
  minimunDeducionAmount: string[] = ['1800', '2000', '3600'];
  minimunDeducionRange: string[] = ['10000','15000', '20000', '25000', '30000'];
  deductionCycles: string[] = ['Monthly', 'Quarterly', 'Annually'];

  constructor(private fb: FormBuilder) {
    this.pfForm = this.fb.group({
      employeeContributionRate: ['', Validators.required],
      employerContributionRate: ['', Validators.required],
      deductionCycle: ['', Validators.required]
    });
  }

  onSubmit() {
    if (this.pfForm.valid) {
    } else {
      this.markFormGroupTouched(this.pfForm);
    }
  }

  markFormGroupTouched(formGroup: FormGroup) {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
}