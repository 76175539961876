import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { useVoucher } from 'src/app/model/voucher/voucher.model';
import { VoucherService } from 'src/app/services/admin-model-services/voucher.service';
import { SubscriptionPlanComponent } from '../../admin/subscription-plan/subscription-plan/subscription-plan.component';
import { Subscription } from 'src/app/model/attendance-model/subscription.model';

@Component({
  selector: 'app-choose-voucher',
  templateUrl: './choose-voucher.component.html',
  styleUrls: ['./choose-voucher.component.scss'],
  providers: [SubscriptionPlanComponent]

})
export class ChooseVoucherComponent implements OnInit {
  isSelectVoucher: boolean = false;
  constructor(private subscriptionPlanComponent: SubscriptionPlanComponent, private router: Router, private voucherService: VoucherService, private fb: FormBuilder, private toasterService: ToastrService) { }
  createVoucherForm!: FormGroup;
  subscriptionPrice: any;
  subscriptionName: any;
  finalAmount: any;
  gst: any;
  discountAmount: any;
  verifiedVoucher: boolean = false;
  subscriptionPlan: any;
  discountedAmount: any;
  email: any;
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  ngOnInit(): void {
    const subscriptionData = JSON.parse(sessionStorage.getItem(AppConstant.SELECT_SUBSCRIPTION) || '{}');
    if (subscriptionData && Object.keys(subscriptionData).length > 0) {
      this.subscriptionPlan = subscriptionData;
    }

    this.subscriptionPrice = sessionStorage.getItem(AppConstant.SUBSCRIPTION_PRICE);
    this.subscriptionName = sessionStorage.getItem(AppConstant.SUBSCRIPTIONNAME);
    this.initVoucher();
    this.calculateGst();
  }

  initVoucher() {
    this.createVoucherForm = this.fb.group({
      'subscriptionPrice': [''],
      'voucherCode': ['']
    })
  }

  voucherCodeChanged() {
    this.verifiedVoucher = false;
  }

  applyVoucher() {
    const voucherCode = this.createVoucherForm.value.voucherCode;
    let totalPrice = this.subscriptionPrice;
    const registrationRequestString = sessionStorage.getItem(AppConstant.CREATE_REGISTRATION_REQUEST);

    if (registrationRequestString) {
      const registrationRequest = JSON.parse(registrationRequestString);
      this.email = registrationRequest.email;
    }
    if (this.companyId != null) {
      this.email = sessionStorage.getItem(AppConstant.LOGIN_EMAIL);
    }
    this.voucherService.applyVoucher(totalPrice, voucherCode, this.email).subscribe(
      data => {
        if (data.status == 200) {
          this.toasterService.success(data.message);

          this.discountAmount = data.data.voucherDiscount;
          this.discountedAmount = this.subscriptionPrice - this.discountAmount;
          this.gst = this.calculateTax(this.discountedAmount, 18);
          this.finalAmount = this.discountedAmount + this.gst;

          sessionStorage.setItem(AppConstant.SUBSCRIPTION_PRICE, this.finalAmount.toString());

          this.verifiedVoucher = true;
          this.isSelectVoucher = true;
        } else {
          this.toasterService.error(data.message);
        }
      }
    );

  }

  calculateTax(amount: number, taxRate: number): number {
    return (amount * taxRate) / 100;
  }

  proceed() {
    if (this.finalAmount == 0) {
      const plan: Subscription = {
        id: this.subscriptionPlan.id,
        subscriptionName: this.subscriptionName,
        price: this.finalAmount,
        subscriptionType: this.subscriptionPlan.subscriptionType,
        description: this.subscriptionPlan.description,
        validityDays: this.subscriptionPlan.validityDays,
        totalUser: this.subscriptionPlan.totalUser,
        leaveManagement: this.subscriptionPlan.leaveManagement,
        attendance: this.subscriptionPlan.attendance,
        payRun: this.subscriptionPlan.payRun,
        projectManagement: this.subscriptionPlan.projectManagement,
        employeeOnboarding: this.subscriptionPlan.employeeOnboarding,
        taxDeclaration: this.subscriptionPlan.taxDeclaration,
        approval: this.subscriptionPlan.approval,
        assetManagement: this.subscriptionPlan.assetManagement,
        helpDesk: this.subscriptionPlan.helpDesk,
        trainingManagement: this.subscriptionPlan.trainingManagement,
        performanceManagement:this.subscriptionPlan.performanceManagement,
        itdeclaration:this.subscriptionPlan.itdeclaration
    
      };
      this.subscriptionPlanComponent.subscribeForFreePlan(plan);
    } else {
      this.router.navigate([AppConstant.CHOOSE_PAYMENT]);
    }
  }

  calculateGst() {
    const subscriptionPriceStr = sessionStorage.getItem(AppConstant.SUBSCRIPTION_PRICE);
    this.subscriptionPrice = subscriptionPriceStr ? parseFloat(subscriptionPriceStr) : 0;
    this.gst = this.calculateTax(this.subscriptionPrice, 18);
    this.finalAmount = this.subscriptionPrice + this.gst;
    sessionStorage.setItem(AppConstant.SUBSCRIPTION_PRICE, this.finalAmount);
  }

}
