import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-approval',
  templateUrl: './mobile-approval.component.html',
  styleUrls: ['./mobile-approval.component.scss']
})
export class MobileApprovalComponent {
  selectedTab: string = 'mobile_approval';
  selectedTabIndex: number = 0;

  switchTab(index: number) {
    this.selectedTabIndex = index;
  }
}
