<div class="setting__main_container">
    <div class="setting__heading_container">
        <h3>{{'direct_deposits' | translate}}</h3>
    </div>

    <mat-card *ngFor="let bank of bankData" class="set-deposit">
        <mat-card-content>
            <div class="bank-info">
                <div class="images-container">
                    <img class="bank-img" [src]="bank.logo" alt="">
                </div>
                <div class="text-info">
                    {{ bank.bankName }}<br>
                    <span class="salary-transfer">{{'salary_transfer' | translate}}</span><br>
                </div>
                <button *ngIf="showSetUpNowButton" class="set-up-now" (click)="bankIntegration(bank)">{{'set_up_now' |
                    translate}}</button>
                <button *ngIf="!showSetUpNowButton" class="view-details" mat-stroked-button
                    (click)="viewDetails()">{{'view_details' |
                    translate}}</button>
            </div>
        </mat-card-content>
    </mat-card>
</div>