import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AssetManagementDashboardService } from 'src/app/services/asset-management/asset-management-dashboard/asset-management-dashboard.service';

@Component({
  selector: 'app-view-timeline',
  templateUrl: './view-timeline.component.html',
  styleUrls: ['./view-timeline.component.scss']
})
export class ViewTimelineComponent {
  id: any;
  timeLine: any[] = [];
  isAsset: boolean = false;

  constructor(private assetManagementService: AssetManagementDashboardService, private toasterService: ToastrService, private activeRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    this.activeRoute.queryParamMap.subscribe(params => {
      const type = params.get('type');
      if (type && this.id) {
        if (type === "asset") {
          this.isAsset = true;
          this.assetHistory(this.id);
        } else {
          this.isAsset = false;
          this.supplierHistory(this.id);
        }
      }
    });
  }

  assetHistory(paramId: any) {
    this.assetManagementService.getAssetHistoryById(paramId).subscribe(
      (data: any) => {
        if (data.status == 200) {
          this.timeLine = data.data;
        }
        else {
          this.toasterService.clear()
          this.toasterService.error(data.message);
        }
      }
    )
  }

  supplierHistory(paramId: any) {
    this.assetManagementService.getSupplierHistoryById(paramId).subscribe(
      (data: any) => {
        if (data.status == 200) {
          this.timeLine = data.data;
        }
        else {
          this.toasterService.clear()
          this.toasterService.error(data.message);
        }
      }
    )
  }

}
