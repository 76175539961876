<div class="container">
  <div class="header">
    <div class="back-button">
      <mat-icon class="back-arrow-icon" (click)="goToBack()" svgIcon="arrow-back-double"></mat-icon>
      <h3><span class="final-settlement">{{'final_settlement' | translate}}{{employeeName}} {{lastName}} ({{'emp' |
          translate}}{{employeeId}})</span>
        {{'poi_base'|translate}}</h3>
    </div>
  </div>

  <table class="view-details" *ngIf="isLastWorkigReadonly">
    <tr>
      <th>{{'pay_period'|translate}}</th>
      <th>{{'pay_date'| translate}}</th>
      <th>{{'last_working_day'|translate}}</th>
      <th class="editLWD"> <mat-icon class="icon" *ngIf="isLastWorkigReadonly"
          (click)="editLastWorkingDateAndFNFDate()">edit</mat-icon>
      </th>
    </tr>
    <tr>
      <td>{{payPeriod}}</td>
      <td>{{finalSettlementDate}}</td>
      <td>{{lastWorkingDate}}</td>
    </tr>
  </table>

  <form [formGroup]="changeLWDForm" *ngIf="!isLastWorkigReadonly">
    <div class="form-field-gap">
      <mat-form-field appearance="outline" class="lwdWidth">
        <mat-label>{{ 'last_working_day' | translate }}</mat-label>
        <input matInput type="text" class="input" formControlName="lastWorkingDay" matInput [matDatepicker]="picker"
          [min]="minDate" required (dateInput)="onLastWorkingDateChange($event)" (keypress)="$event.preventDefault()">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error class="error" *ngIf="changeLWDForm.get('lastWorkingDay')?.errors?.['required']">
          {{ 'last_working_day_required' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="fnf">
        <mat-label>{{ 'final_settlement_date' | translate }}</mat-label>
        <input matInput type="text" class="lwdWidth" formControlName="finalSettlementDate" matInput
          [matDatepicker]="picker1" [min]="minFinalSettlementDate" [max]="maxFinalSettlementDate"
          (keypress)="$event.preventDefault()" required>
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
        <mat-error class="error" *ngIf="changeLWDForm.get('finalSettlementDate')?.errors?.['required']">
          {{ 'final_settlement_date_ts required' | translate }}
        </mat-error>
      </mat-form-field>
      <button class="cancel-btn" (click)="cancel()">{{'cancel'|translate}}</button>
      <button class="submit-button" (click)="editLWDAndFNF()">{{'submit'|translate}}</button>
    </div>
  </form>

  <mat-divider></mat-divider>
  <form [formGroup]="finalSettlementForm" class="support-form">
    <div class="content" *ngIf="!fieldIsReasonlyMode">{{'organisation_day'|translate}}</div>
    <div class="input-container" *ngIf="!fieldIsReasonlyMode">
      <mat-form-field appearance="outline" class="payable-days-width">
        <input [readonly]="isReadOnly" formControlName="organizationPayableDays" matInput type="text" maxlength="2"
          (keypress)="onlyNumberKey($event)" required />
      </mat-form-field>
      {{'days'|translate}}

    </div>
    <div class="content" *ngIf="fieldIsReasonlyMode">{{'organisation_day'|translate}}</div> <span
      *ngIf="fieldIsReasonlyMode"><b class="payableDays">{{organizationPayableDays}} </b>{{'days'|translate}}</span>
    <br>
    <div class="content" *ngIf="!fieldIsReasonlyMode">{{'employee_payable_day'|translate}}</div>
    <div class="employee-container" *ngIf="!fieldIsReasonlyMode">
      <mat-form-field appearance="outline" class="employee-width">
        <input [readonly]="isReadOnly" formControlName="employeePayableDays" matInput type="text" maxlength="2" required
          (keypress)="onlyNumberKey($event)" />
        <mat-error class="error" *ngIf="finalSettlementForm.get('employeePayableDays')?.hasError('required')">
          {{ 'employeePayableDays_is_required' | translate }}
        </mat-error>
      </mat-form-field>
      {{'days'|translate}}

    </div>
    <div class="employee-content" *ngIf="fieldIsReasonlyMode">{{'employee_payable_day'|translate}}</div> <span
      *ngIf="fieldIsReasonlyMode"><b class="payableDays">{{employeePayableDays}} </b>{{'days'|translate}}</span><br>

    <div *ngIf="!fieldIsReasonlyMode" class="lop" (click)="toggleForm()">
      {{ 'add_lop' | translate }}
    </div>

    <div *ngIf="showForm&&!fieldIsReasonlyMode" class="show-lop">{{'lop_for'|translate}}</div>
    <div *ngIf="showForm&&!fieldIsReasonlyMode" class="lop-input-container">
      <mat-form-field appearance="outline" class="lop-width">
        <input [readonly]="isReadOnly" formControlName="lopDays" (keypress)="onlyNumberKey($event)" matInput type="text"
          maxlength="3" />
      </mat-form-field>
      <span class="lop-days"> {{'days'|translate}}</span>
    </div>

    <div *ngIf="fieldIsReasonlyMode" class="view-lop">{{'lop_days'|translate}}</div>
    <span *ngIf="fieldIsReasonlyMode"><b class="payableDays">{{lopDays}} </b></span>

    <mat-divider class="additiona-divider"></mat-divider>
    <div>
      <h3>{{'additional_earning'|translate}}</h3>
      <div>{{'additional_content' | translate}}</div>
      <div class="bonus-wrapper" *ngIf="!fieldIsReasonlyMode">
        <div class="bonus">{{'bonous' |translate}}</div>
        <input formControlName="bonus" [readonly]="isReadOnly" (keypress)="onlyNumberKey($event)" matInput
          class="bonus-input" type="text" maxLength="8">
      </div>

      <div class="bonus-wrapper" *ngIf="!fieldIsReasonlyMode">
        <div class="bonus">{{'comission' |translate}}</div>
        <input formControlName="commission" matInput class="comission-input" [readonly]="isReadOnly"
          (keypress)="onlyNumberKey($event)" maxLength="8" type="text">
      </div>

      <div class="bonus-wrapper" *ngIf="!fieldIsReasonlyMode">
        <div class="bonus">{{'leave_encashment' |translate}}</div>
        <input formControlName="leaveEncashment" matInput [readonly]="isReadOnly" class="leave_encashment-input"
          type="text" (keypress)="onlyNumberKey($event)" maxLength="8">
      </div>
      <div class="bonus-wrapper" *ngIf="!fieldIsReasonlyMode">
        <div class="bonus">{{'gratuity' |translate}}</div>
        <input formControlName="gratuity" matInput [readonly]="isReadOnly" class="gratuity-input" type="text"
          (keypress)="onlyNumberKey($event)" maxLength="8">
      </div>
    </div>
    <div *ngIf="fieldIsReasonlyMode" class="showTableInfo">
      <div class="heading">
        <b><span>{{ 'earning_name' | translate }}</span></b>
        <b><span>{{ 'amount' | translate }}</span></b>
      </div>

      <div class="heading" *ngFor="let earning of earnings">
        <span>{{ earning.name }}</span>
        <span>{{'rupiee'|translate}}{{ earning.amount | number: '2.2-2' }}</span>
      </div>
    </div>

    <mat-divider class="additiona-divider"></mat-divider>

    <div class="deductions">
      <h3>{{ 'deductions' | translate }}</h3>
      <div>{{ 'deduction_content' | translate }}</div>
      <div class="deduction" (click)="addDeduction()" *ngIf="!fieldIsReasonlyMode">
        <span (click)="addDeduction()">{{ 'add_deduction' | translate }}</span>
      </div>
      <table *ngIf="tempDeductionList && tempDeductionList.length > 0">
        <thead class="deduction-table">
          <tr>
            <th>{{'deduction_name' | translate}}</th>
            <th>{{'deduction_amount'| translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let deduction of tempDeductionList" class="deduction-tr">
            <td class="deduction-td">{{ deduction.name }}</td>
            <td class="deduction-td">{{ deduction.amount }}</td>
          </tr>
        </tbody>
      </table>
      <div *ngIf="addDeductions &&!fieldIsReasonlyMode">
        <div class="deduction-item" *ngFor="let deduction of deductionList.controls; let i = index">
          <mat-form-field appearance="outline" class="deduction-width">
            <mat-label>{{ 'add_deduction_name' | translate }}</mat-label>
            <input formControlName="deductionName" matInput type="text" (keypress)="validateCharacter($event)" />
          </mat-form-field>
          <mat-form-field appearance="outline" class="deduction-width">
            <mat-label>{{ 'amount' | translate }}</mat-label>
            <input formControlName="deductionAmount" matInput (keypress)="onlyNumberKey($event)" maxLength="8" />
          </mat-form-field>
          <button (click)="addDeductionAmount(i)" class="add">Add</button>
          <mat-icon class="close" (click)="closeDeduction()">close</mat-icon>
        </div>
      </div>
      <div *ngIf="fieldIsReasonlyMode" class="showTableInfo">
        <div class="heading">
          <span><b>{{'deductions_name'|translate}}</b></span>
          <span><b>{{'amount'|translate}}</b></span>
        </div>
        <div class="heading" *ngFor="let deduction of deductions">
          <span>{{deduction.name}}</span>
          <span>{{deduction.amount | currency:'INR'}}</span>
        </div>
      </div>
      <mat-divider class="reimb-divider"></mat-divider>
      <div class="deductions">
        <h3>{{ 'reimbursement' | translate }}</h3>
        <div>{{ 'reimbursements_content' | translate }}</div>
        <div class="reimbursement" (click)="addReimbursement()" *ngIf="!fieldIsReasonlyMode">
          <span (click)="addReimbursement()">{{ 'add_reimbursement' | translate }}</span>
        </div>
        <table *ngIf="tempReimbursementList && tempReimbursementList.length > 0">
          <thead class="deduction-table">
            <tr>
              <th>{{'reimbursement_name' | translate}}</th>
              <th>{{'reimbursement_amount'| translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let reimbursement of tempReimbursementList" class="deduction-tr">
              <td class="deduction-td">{{ reimbursement.name}}</td>
              <td class="deduction-td">{{ reimbursement.amount}}</td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="addReimbursements &&!fieldIsReasonlyMode">
          <div class="deduction-item" *ngFor="let reimbursement of reimbursementList.controls; let i = index">
            <mat-form-field appearance="outline" class="deduction-width">
              <mat-label>{{ 'add_reimbursement_name' | translate }}</mat-label>
              <input formControlName="name" matInput type="text" (keypress)="validateCharacter($event)" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="deduction-width">
              <mat-label>{{ 'amount' | translate }}</mat-label>
              <input formControlName="amount" matInput (keypress)="onlyNumberKey($event)" maxLength="8" />
            </mat-form-field>
            <button (click)="addReimbursementAmount(i)" class="add">Add</button>
            <mat-icon class="close" (click)="closeReimbursement()">close</mat-icon>
          </div>
        </div>

        <div *ngIf="fieldIsReasonlyMode" class="showTableInfo">
          <div class="heading">
            <span><b>{{'reimbursement_name'|translate}}</b></span>
            <span><b>{{'amount'|translate}}</b></span>
          </div>
          <div class="heading" *ngFor="let reimbursement of reimbursements">
            <span>{{reimbursement.name}}</span>
            <span class="reimb-amount">{{reimbursement.amount | currency:'INR'}}</span>
          </div>
        </div>
      </div>
      <mat-divider class="additiona-divider"></mat-divider>
      <h3>{{'notice_pay' | translate}}</h3>
      <mat-checkbox *ngIf="!fieldIsReasonlyMode" class="notice-pay" (change)="noticePay()" [(ngModel)]="isNoticePay">
        <b>{{ 'notice_pay_content' | translate }}</b>
      </mat-checkbox>
      <div *ngIf="!fieldIsReasonlyMode">{{'notice_pay_details' | translate}}</div>
      <div *ngIf="isNoticePay">
        <div class="amount" *ngIf="!fieldIsReasonlyMode">
          <span>{{ 'payable_amount' | translate }}</span>
          <mat-form-field class="form-input" appearance="outline">
            <input formControlName="noticePayAmount" [readonly]="isReadOnly" matInput type="text" maxLength="8"
              class="form-input" (keypress)="onlyNumberKey($event)" />
          </mat-form-field><span class="inr">{{'inr'|translate}}</span>
        </div>

      </div>
      <span *ngIf="fieldIsReasonlyMode">{{ 'payable_amount' | translate }}<span
          class="noticePayAmount">{{noticePayAmount}}</span><span class="inr"> {{'inr'|translate}}</span></span>

    </div>
  </form>

  <div class="save" *ngIf="!isReadOnly">
    <button class="cancel-btn" (click)="goBack()">{{'skip'|translate}}</button>
    <button (click)="showDetails()">{{'save' | translate}}</button>
  </div>

  <div class="save" *ngIf="isReadOnly">
    <button (click)="next()">{{'next' | translate}}</button>
  </div>
</div>
<app-loader></app-loader>