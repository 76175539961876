export class AppConstant {
  public static readonly IWEXE_LOCATION: string = 'Office 221, Suratwala Mark Plazzo, Hinjawadi, Pune, Maharashtra, India - 411057';
  public static readonly IWEXE_SUPPORT_EMAIL: string = 'admin@iwexe.com';
  public static readonly IWEXE_SUPPORT_PHONE: string = '+91 9322463679';
  public static readonly LANDING_PAGE: string = 'home';
  public static readonly PRIVACY_POLICY: string = 'privacy-policy';
  public static readonly TERMS_AND_CONDITION: string = 'terms-condition';
  public static readonly CANCELLATION_AND_REFUND_POLICY: string = 'cancellation-refund-policy';
  public static readonly SHIPPING_AND_DELIVERY_POLICY: string = 'shipping-delivery-policy';
  public static readonly LOGIN_PAGE: string = 'login';
  public static readonly REGISTRATION: string = 'signup';
  public static readonly VERIFY_EMAIL: string = 'verify';
  public static readonly EMPLOYEE_ADDED: string = 'Payment Information Added Successfully.';
  public static readonly COMPANY_BANK_DETAILS: string = 'bank-details';
  public static readonly SUBSCRIPTION_PLAN: string = 'subscription-plan';
  public static readonly GENERATE_PF_EXCEL: string = 'generate-pf-excel';
  public static readonly IMAGE_VIEWER: string = 'image-viewer';
  public static readonly EMPLOYEE_SIDENAV: string = 'employee';
  public static readonly NAVBAR: string = 'navbar';
  public static readonly PAGE_NOT_FOUND = 'page-not-found'
  public static readonly ACTIVE_EMPLOYEES: string = 'active-employees';
  public static readonly ADD_EMPLOYEE: string = 'add-employee';
  public static readonly DASHBOARD: string = 'dashboard-view';
  public static readonly BASIC_DETAILS: string = 'basic-details';
  public static readonly SALARY_DETAILS: string = 'salary-details/:id';
  public static readonly SALARY_DETAIL: string = 'salary-details';
  public static readonly VIEW_EMPLOYEE: string = 'view-employee';
  public static readonly VIEW_REQUEST_DETAILS: string = 'view-service-request-details';
  public static readonly PERSONAL_INFORMATION: string = 'personal-information';
  public static readonly PAYMENT_INFORMATION: string = 'payment-information';
  public static readonly VIEW_COMPANY_PROFILE: string = 'company-profile';
  public static readonly ADD_DEPARTMENT: string = 'add-department';
  public static readonly ADD_DESIGNATION: string = 'add-designation';
  public static readonly VIEW_ALL_DEPARTMENT: string = 'department';
  public static readonly VIEW_ALL_DESIGNATION: string = 'designation';
  public static readonly MANAGERS_EMPLOYEE_LIST: string = 'employee-list';
  public static readonly SALARY_INFO: string = 'salary-details';
  public static readonly EMPLOYEE_LEAVE_DETAILS: string = 'employee-leave-details';
  public static readonly SALARY_BREAKUP: string = 'salary-breakup';
  public static readonly CHANGE_PASSWORD: string = 'change-password';
  public static readonly FORGOT_PASSWORD: string = 'forgot-password';
  public static readonly CREATE_NEW_PASSWORD: string = 'create-new-password';
  public static readonly ADD_COMPANY_PROFILE: string = 'addCompanyInfo'
  public static readonly ADD_BANK_DETAILS: string = 'add-bank-details';
  public static readonly SALARY_STRUCTURE: string = 'salary-structure';
  public static readonly SUPPORT: string = 'support';
  public static readonly DIRECT_DEPOSIT: string = 'direct-deposit';
  public static readonly BANK_INTEGRATION: string = 'bank-integration-setup'
  public static readonly EMAIL_CONFIGURATION = "email-configuration";
  public static readonly TAX_DECLARATION = "tax-declaration";
  public static readonly USER_SUBSCRIPTION_VIEW: string = 'user-subscription-view';
  public static readonly TRANSACTION_HISTORY: string = 'transaction-history';
  public static readonly EARNING: string = 'earning';
  public static readonly VIEW_EMPLOYEE_ATTENDANCE: string = 'view-employee-attendance';
  public static readonly DEDUCTION: string = 'deduction';
  public static readonly REIMBURSEMENT: string = 'reimbursement';
  public static readonly ADD_COMPONENT: string = 'add-components';
  public static readonly LEAVE_STRUCTURE: string = 'leave-structure';
  public static readonly SALARY_SLIP: string = 'salary-slip';
  public static readonly ROLE = sessionStorage.getItem('role');
  public static readonly STATUS: string = '6';
  public static readonly ID = 1;
  public static readonly DEPARTMENT: string = 'department';
  public static readonly EMPLOYEE_ROLE: string = 'employee';
  public static readonly ADMIN: string = 'admin';
  public static readonly GSTKEY = "b6b148e8c2478be8e52c580f42cca861";
  public static readonly SALARY: string = 'Salary';
  public static readonly CURRENT: string = 'Current';
  public static readonly SAVING: string = 'Saving';
  public static readonly SEPERATOR: string = '/';
  public static readonly EMPLOYEE_PROFILE: string = 'employee-profile';
  public static readonly COMPANYNAME: string = '&companyId=';
  public static readonly COMPANY_NAME: string = '?companyId=';
  public static readonly MANAGER_ID_REQUEST: string = '?managerId=';
  public static readonly EMPLOYEE_ID_REQUEST: string = '?employeeId=';
  public static readonly EMPLOYEE_LIST: string = '&employeeList=';
  public static readonly EMPLOYEE_DETAILS: string = 'employee-details/:id';
  public static readonly PAY_RUN: string = 'pay-run';
  public static readonly PAY_RUN_GROUP: string = 'pay-run-group';
  public static readonly PAY_RUN_EMPLOYEE_LIST: string = 'pay-run-employee-list';
  public static readonly BASIC_DETAILS_ID: string = 'basicDetailsId';
  public static readonly NETPAY: string = 'Net Pay';
  public static readonly LEAVE_HISTORY: string = 'leave-history';
  public static readonly APPLY_LEAVE: string = 'apply-leave';
  public static readonly EMPLOYEE_ATTENDANCE: string = 'attendance';
  public static readonly EMPLOYEE_PROFILE_ASSET_MANAGEMENT: string = 'asset-details';
  public static readonly EMPLOYEE_SUBMITTED_ATTENDANCE: string = 'employee-attendance-details';
  public static readonly LOG_TIME: string = 'log-time';
  public static readonly ATTENDANCE_CONFIGURATION: string = 'attendance-configuration';
  public static readonly EMPLOYEE_ATTENDANCE_DETAILS: string = 'employee-attendance-details';
  public static readonly MANAGER_SIDENAV: string = 'approvals';
  public static readonly TASK_MANAGEMENT_SIDENAV: string = 'task-management-sidenav';
  public static readonly MANAGER: string = 'manager';
  public static readonly SUBSCRIPTION_DASHBOARD: string = 'dashboard';
  public static readonly PROFIT_LOSS_REPORT = 'profit-loss';
  public static readonly NEW_USERS_REPORT = 'recently-added-users';
  public static readonly PAYMENT_RECEIVED_REPORT = 'payment-received';
  public static readonly CUSTOMER: string = 'customer';
  public static readonly SUBSCRIPTION: string = 'subscription';
  public static readonly REPORT: string = 'report';
  public static readonly INVOICE: string = 'invoice';
  public static readonly SETTING: string = 'setting';
  public static readonly VIEW_ALL_SUBSCRIPTION: string = 'subscription-report';
  public static readonly SUCCESS: string = 'Success';
  public static readonly PENDING: string = 'Pending';
  public static readonly FAILURE: string = 'Failure';
  public static readonly RECENTLY_ADDED_CUSTOMERS: string = 'Recently Added Customers';
  public static readonly TOTAL_SUBSCRIPTION: string = 'Total Subscription';
  public static readonly ADMIN_SIDEBAR: string = 'superadmin';
  public static readonly DOMAIN: string = 'iwexe.com';
  public static readonly BOOK_DEMO: string = 'book-demo';
  public static readonly ADD_BULK_DATA: string = 'added-bulk-data';
  public static readonly SUBSCRIPTIONID: string = "subscriptionId";
  public static readonly INVALID_ACCESSS = "Invalid Portal Access";
  public static readonly TOKEN: string = 'token';
  public static readonly COMPANY_ID: string = 'companyId';
  public static readonly AMOUNT: string = 'amount'
  public static readonly PAID_TYPE: string = 'paidType'
  public static readonly EMPLOYEE_ID: string = 'employeeId';
  public static readonly SUBSCRIPTION_ID: string = 'subscriptionId';
  public static readonly ROLE_FOR_SESSION: string = 'login';
  public static readonly ASSIGN_ROLES: string = 'role';
  public static readonly ANNUAL_CTC: string = 'annualCTC';
  public static readonly LOGIN_EMAIL: string = 'loginEmail';
  public static readonly LOGIN_FIRST: string = 'Please log in first to access this module';
  public static readonly CREATE_REGISTRATION_REQUEST: string = 'createRegistrationRequest';
  public static readonly SHOW_EMPLOYEE_LEAVE: string = 'showEmployeeLeave';
  public static readonly DELETE_CONFIRMATION_MASSAGE: string = 'Are you sure to delete this record ?';
  public static readonly EMPLOYEE_LOGIN_SUCCESSFULLY: string = 'Employee Login Successfully.';
  public static readonly ADMIN_LOGIN_SUCCESSFULLY: string = 'Admin Login Successfully.';
  public static readonly OTP_SEND_SUCCESSFULLY: string = 'OTP resent successfully.';
  public static readonly ADD_ATLEAST_ONE_EMPLOYEE: string = 'Please add atleast one employee.';
  public static readonly PLEASE_PROVIDE_VALID_DETAILS: string = 'Please provide valid details.';
  public static readonly PLEASE_PROVIDE_EARNING_TOTAL_UPTO_100: string = 'Please provide earnings total upto 100%';
  public static readonly RESEND_OTP_ERROR: string = 'Error occure to resent otp';
  public static readonly YEARLY: string = 'yearly';
  public static readonly BUY_NEW_SUBSCRIPTION: string = 'Please buy new subscription.';
  public static readonly SUBSCRIPTION_MODULE_ACCESS: string = 'subscriptionModuleAccess';
  public static readonly SUBSCRIPTION_UPDATE_SUCCESSFULLY: string = 'Subscription updated successfully.';
  public static readonly LOGOUT: string = "Logout";
  public static readonly SESSION_EXPIRE: string = "Your session is about to expire. Do you want to continue the session ?";
  public static readonly MOUSE_MOVE: string = "mousemove";
  public static readonly KEY_DOWN: string = "keydown";
  public static readonly WHEEL: string = "wheel";
  public static readonly TOUCHSTART: string = "touchstart";
  public static readonly BAREAR: string = "Bearer";
  public static readonly AUTHORIZATION: string = "Authorization";
  public static readonly ERROR: string = "An error occurred";
  public static readonly ADMIN_AND_EMPLOYEE: string = "Admin,User";
  public static readonly LOGIN: string = "/login";
  public static readonly VIEW_INTEGRATED_BANK: string = "view-integrated-bank";
  public static readonly EXCEL: string = "excelFile";
  public static readonly POST: string = "POST";
  public static readonly FILE_SIZE: number = 1024;
  public static readonly ERROR_EXCEL: string = "EmployeeErrorData.xlsx";
  public static readonly HOLIDAY_ERROR_EXCEL: string = "HolidayErrorData.xlsx";
  public static readonly SALARY_COMPONENT: string = 'Salary Component';
  public static readonly CALCULATION_TYPE: string = 'Calculation Type';
  public static readonly MONTHLY_AMOUNT: string = 'Monthly Amount';
  public static readonly ANNUAL_AMOUNT: string = 'Annual Amount';
  public static readonly FILE_NAME: string = 'All_Employees_Upload.xlsx';
  public static readonly HOLIDAY_FILENAME: string = "All_Holidays_Upload.xlsx";
  public static readonly PASTE: string = 'paste';
  public static readonly CUT: string = 'cut';
  public static readonly COPY: string = 'copy';
  public static readonly GST_NUMBER: string = "gstNumber";
  public static readonly IFSC_CODE: string = "ifscCode";
  public static readonly LEAVE_DURATION_MSG: string = "Please leave duration upto ";
  public static readonly LEAVE_COUNT_ERROR_MSG = "Your ";
  public static readonly LEAVE_COUNT_ID = " count is ";
  public static readonly LEAVE_TYPE_ERROR_MSG = ". You cannot apply for leave using this leave type.";
  public static readonly DAYS: string = " days";
  public static readonly PLEASE_SELECT_PAYMENT_METHOD = "Please select payment method.";
  public static readonly CHOOSE_PAYMENT: string = "choose-payment";
  public static readonly PAYMENT_DISCRIPTION: string = "payment-description";
  public static readonly SUBSCRIPTIONNAME: string = "subscriptionName";
  public static readonly SECTION_ID: string = "sectionId";
  public static readonly REGIME_SLAB: string = "regime-slab";
  public static readonly VIEW_TAX_DECLARATION: string = "it-declaration";
  public static readonly DECLARED_FORM: string = "declared-form";
  public static readonly START_FRESH: string = "investment-declaration";
  public static readonly GENERAL_SETTING: string = "setting-configuration";
  public static readonly ADD_ROLES: string = "role-configuration";
  public static readonly COMPANY_REGISTRATION_DATE: string = "companyRegistrationDate";
  public static readonly ASSIGN_MANAGER: string = "assign-manager";
  public static readonly EXIT_PROCESS: string = "exit-process";
  public static readonly VIEW_RESIGNATION: string = "view-resignation";
  public static readonly EMPLOYEE_RESIGNATIONS: string = "employee-resignations";
  public static readonly VIEW_EMPLOYEE_RESIGNATION_DETAILS: string = "employee-resignation-details";
  public static readonly RESIGNATIONS: string = "resignations";
  public static readonly EXIT_DETAILS: string = "exit-details";
  public static readonly FINAL_SETTLEMENT: string = "final-settlement";
  public static readonly SHOW_POI_DETAILS: string = "final-settlement-payroll";
  public static readonly DECLARATION_APPROVAL: string = "declaration-approval";
  public static readonly ASSET_MANAGEMENT: string = "asset-management";
  public static readonly PROJECT_MANAGEMENT: string = "project-management";
  public static readonly EVENT_MANAGEMENT: string = "event-management";
  public static readonly ADD_EVENT: string = "event-management";
  public static readonly VIEW_SUPPLIER_HISTORY: string = "/admin/view-timeline/";
  public static readonly VIEW_SUPPLIER_INFO: string = "/admin/supplier-info/";
  public static readonly EXIT_EMPLOYEE: string = "isExit";
  public static readonly NO_DATA_TO_SAVE: string = "No data to save";
  public static readonly LOGIN_COMPANY_NAME: string = "companyName";
  public static readonly EMP00: string = "EMP00";
  public static readonly HR_VIEW_EMPLOYEE_RESIGNATION_DETAILS: string = "employees-resignation-details";
  public static readonly EVENT_ERROR_EXCEL: string = "AllEventsUpload_Error.xlsx";
  public static readonly EVENT_SUCCESS: string = "All events added successfully";
  public static readonly UPLOAD_EXCEL: string = "upload-goal-excel";
  public static readonly UPLOAD_APPRISAL_EXCEL: string = "upload-apprisal-excel";
  public static readonly VIEW_EMPLOYEE_GOALS: string = "employee-goal-plan";
  public static readonly EMPLOYEE_LIST_UNDER_MANAGER: string = "employees-list";
  public static readonly EMPLOYEE_BASIC_GOAL = 'employee-basic-goal';
  public static readonly VIEW_EMPLOYEE_APPRISAL: string = "appraisal";
  public static readonly EMPLOYEE_EVOLUTION: string = "evolution";
  public static readonly GOAL_PLAN: string = "goal-plans";
  public static readonly ADD_GOALS: string = "add-goals";
  public static readonly EMPLOYEE_UNDER_MANAGER: string = "viewEmpGoalById";
  public static readonly ANNUAL: string = "Annual";
  public static readonly SEMIANNUAL: string = "Semiannual";
  public static readonly EMPLOYEE_PERFORMANCE_GOAL_PLAN: string = "performance-goal-plan";
  public static readonly PAYRUN_APPROVAL: string = 'payrun_approval';
  public static readonly IS_MANAGER: string = "isManager";
  public static readonly YEAR_HISTORY: string = "showYearHistory";
  public static readonly ACCEPT: string = 'Accept';
  public static readonly REJECT: string = 'Reject';
  public static readonly REJECTED: string = 'reject';
  public static readonly SUPPLIER_DETAILS: string = 'supplier-details';
  public static readonly ADD_SUPPLIER_DETAILS: string = 'add-supplier-details';
  public static readonly OREDR_FOR_ASSET: string = 'order-for-asset';
  public static readonly ASSET_DETAILS: string = 'asset-details';
  public static readonly PERFORMANCE: string = 'Performance';
  public static readonly BEHAVIOUR: string = 'Behavioural';
  public static readonly CATEGOERY: string = 'goals-category';
  public static readonly VOUCHER: string = 'create-voucher';
  public static readonly VIEW_VOUCHER: string = 'view-vouchers';
  public static readonly USE_VOUCHER: string = 'vouchers-details';
  public static readonly AND: string = '&voucherCode=';
  public static readonly SUBSCRIPTION_PRICE: string = 'subscription-price';
  public static readonly INCIDENT_REQUEST: string = 'incident-request';
  public static readonly REQUESTOR_INFORMATION: string = 'requestor-information';
  public static readonly VIEW_REQUESTOR_INFORMATION: string = 'view-request';
  public static readonly RESOLUTION: string = 'resolution';
  public static readonly HELPDESK: string = 'help-desk';
  public static readonly ADD_PROJRCT: string = 'add-project';
  public static readonly UPDATE_PROJECT: string = 'update-project';
  public static readonly PROJECT_COSTING: string = 'project-costing'
  public static readonly PROJECT_START_DATE: string = '?startDate=';
  public static readonly PROJECT_END_DATE: string = '&endDate=';
  public static readonly ADD_APPROVALS: string = "payrun-approvals";
  public static readonly PROJECT_APPROVAL: string = "project-approval";
  public static readonly ADD_HIRARCHICAL_APPROVALS: string = 'payrun-approvals-hirarchy';
  public static readonly VIEW_PROJECT_DETAILS: string = 'view-project-details';
  public static readonly DIRECT_APPROVAL_CONFIRMATION_MASSAGE: string = 'Are you sure to change approval type as Direct Approval ?';
  public static readonly HIERARCHICAL_APPROVAL: string = 'Hierarchical_Approval';
  public static readonly STANDARD_APPROVAL: string = 'Standard_Approval';
  public static readonly DIRECT_APPROVAL: string = 'Direct_Approval';
  public static readonly PAYRUN_APPROVAL_DETAILS: string = 'payrun-approval-details'
  public static readonly VIEW_TRAINING: string = 'view-training';
  public static readonly ADD_TRANING: string = "add-training";
  public static readonly COURSES: string = 'courses_details';
  public static readonly LEARNCOURSES: string = 'learn_courses';
  public static readonly REQ: string = 'REQ00'
  public static readonly VIEW_EMPLOYEE_TRAINING: string = 'view-employee-training/'
  public static readonly BIRTHDAY_LIST: string = 'birthday_list'
  public static readonly REQUEST_ID: string = ':id';
  public static readonly VIEW_DETAILS_OF_TRAINING: string = 'deatils-of-training';
  public static readonly VIEW_EMPLOYEE_TRANING_DETAILS: string = 'employee-assign-training-details';
  public static readonly ASSIGN_TRAINING_TO_THE_EMPLOYEE: string = 'assign-training-to-the-employee';
  public static readonly EMPLOYEE_STATUS: string = 'employeeStatus';
  public static readonly INCOMPLETED: string = 'Incomplete';
  public static readonly SELECT_SUBSCRIPTION: string = 'selectedSubscription';
  public static readonly MANAGER_ID: string = "managerId";
  public static readonly ADMIN_ID: string = "adminId";;
  public static readonly VIEW_REPORT: string = "view-report";
  public static readonly VIEW_TASK: string = "view-task";
  public static readonly VIEW_BACKLOG: string = "view-backlog";
  public static readonly PROJECTS: string = "projects";
  public static readonly DASHBOARD_ID: string = "dashboardId";
  public static readonly TASK_ID: string = "taskId";
  public static readonly PARAMS_DASHBOARD_ID: string = "/:dashboardId";
  public static readonly PARAMS_SPRINT_ID: string = "/:sprintId";
  public static readonly VIEW_TIMELINE: string = "view-timeline";
  public static readonly SUPPLIER_INFO: string = "supplier-info";
  public static readonly MAX_FILE_SIZE = 25 * 1024 * 1024;
  public static readonly SPACE: string = " ";
  public static readonly PRIORITY: string = "priority";
  public static readonly CREATE_TASK = "createTaskDto";
  public static readonly ATTACHMNETS = "attachments";
  public static readonly COMPANY_BANK_DETAIL: string = "company-bank-details";
  public static readonly COMPANY_BANK_INFORMATION: string = "company-bank-information";
  public static readonly EMAIL_QUERY_PARAM_APPENDER: string = "&email=";
  public static readonly IT_DECLARATION: string = "it-declaration";
  public static readonly TASK_MANAGEMENT: string = "task-management";
  public static readonly BOARD_TRACKING: string = "board-tracking";
  public static readonly PROJECT_DASHBOARD: string = "project-dashboard";
  public static readonly VIEW_SPRINT: string = "view-sprint";
  public static readonly ACTIVE_EMPLOYEE: string = "activeEmployee";
  public static readonly PROJECT_ID: string = "projectId";
  public static readonly ISMANAGER: string = "Manager";
  public static readonly PARAMS_ID: string = "/:id";
  public static readonly DELETE_DASHBOARD_CONFIRMATION_MESSAGE: string = "Are you sure to delete this board? Sprints and tasks associate with this board will also be deleted."
  public static readonly OR: string = "&email=";
  public static readonly APPROVE: string = "approve";
  public static readonly CONTACT_ID: string = "contactId";
  public static readonly DOB: string = "dob";
  public static readonly FAMILY_AND_EMERGENCY_CONTACT_INFO: string = "family-and-emergency-contact-information";
  public static readonly VIEW_FAMILY_AND_EMERGENCY_DETAILS: string = "view-family-and-emergency-details";
  public static readonly ADD_FAMILY_AND_EMERGENCY_CONTACTS: string = "add-family-and-emergency-contacts";
  public static readonly VIEW_EMPLOYEE_FAMILY_AND_EMERGENCY_CONTACT_INFO: string = "view-employee-family-and-emergency-info";
  public static readonly SETTING_SIDE_BAR: string = "settingSideBar";
  public static readonly SHOWSIDEBAR: string = "showSidebar";
  public static readonly EMPLOYEE_MANAGEMENT_OVERVIEW: string = "employee-management-overview";
  public static readonly ACESS_MODULES_BASED_ON_ROLE: string = "all-modules";
  public static readonly HELP: string = "help";
  public static readonly EMPLOYEE: string = "employee";
  public static readonly MAINTENANCE: string = "maintenance";
  public static readonly VIEW_BRANCH: string = "view-branch";
  public static readonly ADD_BRANCH: string = "add-branch";
  public static readonly HIGHER_MANAGEMENT: string = "HigherManagement";

  //Mobile 
  public static readonly MOBILE: string = "mobile";
  public static readonly MOBILE_DASHBOARD: string = "dashboard";
  public static readonly SELFIE_CAPTURE: string = "selfie-capture";

  public static getMenuName(name: string) {
    switch (name) {
      case 'Home': {
        return AppConstant.SUBSCRIPTION_DASHBOARD;
      }
      case 'Customer': {
        return AppConstant.CUSTOMER;
      }
      case 'Subscription': {
        return AppConstant.SUBSCRIPTION_PLAN;
      }
      case 'Report': {
        return AppConstant.REPORT;
      }
      case 'Invoice': {
        return AppConstant.INVOICE;
      }
      case 'Setting': {
        return AppConstant.EMAIL_CONFIGURATION;
      }
      case 'Tax Declaration': {
        return AppConstant.TAX_DECLARATION;
      }
      case 'Regime': {
        return AppConstant.REGIME_SLAB;
      }
      case 'Voucher': {
        return AppConstant.VIEW_VOUCHER;
      }

      default: {
        return '';
      }
    }
  }

}