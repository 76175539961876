import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { ApprovalService } from 'src/app/services/approval/approval.service';
import { LoaderService } from 'src/app/services/loader/loader.service';

@Component({
  selector: 'app-payrun-approval-details',
  templateUrl: './payrun-approval-details.component.html',
  styleUrls: ['./payrun-approval-details.component.scss']
})
export class PayrunApprovalDetailsComponent implements OnInit {
  payrun!: FormGroup;
  pagination = true;
  paginationPageSize = 10;
  paginationPageSizeSelector = [10, 20, 50, 100];
  payrunData!: PayrunApprovalData;
  payrunApprovalId: any;
  payruncolumnDefs: any = [
    { headerName: 'Approver Id', field: "employeeId", filter: true, floatingFilter: true, sortable: true, flex: 1, valueGetter: (params: any) => 'EMP00' + params.data.employeeId },
    { headerName: 'Approver Name', field: "employeeName", filter: true, tooltipField: "employeeName", floatingFilter: true, sortable: true, flex: 1 },
    { field: "status", filter: true, floatingFilter: true, sortable: true, tooltipField: "status", flex: 1 },
    { field: "approvalDate", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { field: "Action", cellRenderer: this.actionCellRenderer.bind(this) },];
  payrunsrowData: any = [];

  constructor(private loader: LoaderService, private toasterService: ToastrService, private approvalService: ApprovalService, private renderer: Renderer2, private router: Router, private activatedRoute: ActivatedRoute) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras.state) {
      this.payrunData = navigation.extras.state['data'];
    } else {
      this.activatedRoute.params.subscribe(params => {
        const payrunApprovalId = params['id'];
        this.payrunApprovalId = payrunApprovalId;
        this.getPayRunDetailsById(payrunApprovalId);
      });
    }
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      const payrunApprovalId = params['id'];
      this.payrunApprovalId = payrunApprovalId;
      this.getPayRunApprovalDetails(payrunApprovalId);
    });
  }

  getPayRunDetailsById(payrunApprovalId: any) {
    this.approvalService.getPayRunDetailsById(payrunApprovalId).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.payrunData = data.data;
        }
      }
    )
  }

  getPayRunApprovalDetails(payrunApprovalId: any) {
    this.approvalService.getPayRunApprovalDetails(payrunApprovalId).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.payrunsrowData = data.data;
        }
      }
    )
  }

  goToBack() {
    this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.PAYRUN_APPROVAL])
  }

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');
    const loggedInEmployeeId = Number(sessionStorage.getItem(AppConstant.EMPLOYEE_ID));
    const createButton = (label: string, color: string, clickHandler: () => void) => {
      const button = document.createElement('button');
      button.addEventListener('click', clickHandler);
      button.style.backgroundColor = color;
      button.style.color = 'white';
      button.style.border = 'none';
      button.style.width = 'auto';
      button.style.height = 'auto';
      button.style.borderRadius = '5px';
      button.style.marginRight = '10px';
      button.textContent = label;
      cell.appendChild(button);
    };

    const approveButtonClickHandler = () => {
      this.addPayRunApprovalStatus("Approved", params.data);
    };

    const rejectButtonClickHandler = () => {
      this.addPayRunApprovalStatus("Reject", params.data);
    };

    const shouldShowButtons = Boolean(params.data.showButton) && params.data.status === 'Pending';

    if (params.data.employeeId === loggedInEmployeeId && shouldShowButtons) {
      createButton('Reject', 'red', rejectButtonClickHandler);
      createButton('Approve', 'green', approveButtonClickHandler);
    }

    return cell;
  }

  addPayRunApprovalStatus(status: any, data: any) {
    this.loader.show();
    const dto = {
      employeeId: data.employeeId,
      status: status,
      payRunId: data.payRunId
    }
    this.approvalService.addPayRunApprovalStatus(dto).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.getPayRunApprovalDetails(this.payrunApprovalId);
          this.loader.hide();
          this.toasterService.clear();
          this.toasterService.success(data.message);
        }
        else {
          this.loader.hide();
          this.toasterService.clear();
          this.toasterService.error(data.message);
        }
      }
    )
  }


}

export interface PayrunApprovalData {
  id: string,
  payrunMonth: string,
  amount: number,
  status: string,
  groups: string,
  noofemployees: number
}