<div class="setting__main_container">
    <div class="setting__heading_container">
        <h3>{{ 'designations' | translate }}</h3>
        <div class="setting__header_button_container">
            <button color="primary" (click)="toggleAddPopup()">{{ 'add_designation' | translate
                }}</button>
        </div>
    </div>
    <ag-grid-angular (gridReady)="onGridReady($event)" style="height: 27rem; width: 100%;" class="ag-theme-alpine"
        [rowData]="designationData" [rowSelection]="'multiple'" [animateRows]="true" [columnDefs]="designationTableCol"
        [defaultColDef]="designationColDef" [enableSorting]="true" [enableFilter]="true" [pagination]="true"
        [paginationPageSize]="10">
    </ag-grid-angular>
</div>

<div class="overlay" *ngIf="showAddPopup">
    <div class="add-popup">
        <div class="container">
            <div class="header">
                <h3 class="heading">{{(addDesignationFlag ? 'add_designation' : 'update_designation') | translate}}</h3>
            </div>
            <form [formGroup]="designationForm" id="designationForm">
                <div>
                    <mat-form-field class="form-field" appearance="outline">
                        <mat-label>{{ 'designation' | translate }}</mat-label>
                        <input matInput formControlName="designationName"
                            (keypress)="validateCharacterNumberAndSpace($event)" [maxLength]="50" type="text" />
                        <mat-error *ngIf="!designationForm.controls['designationName'].valid">{{
                            'designation_is_required' |
                            translate }}</mat-error>
                    </mat-form-field>
                </div>
                <app-loader></app-loader>
                <div class="button_div">
                    <button (click)="toggleAddPopup()" class="cancel-btn">
                        {{'cancel' | translate}} </button>
                    <button (click)="isAddMode ? addDesignation() : updateDesignation()">
                        {{ isAddMode ? ('Save' | translate) : ('Update' | translate) }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>