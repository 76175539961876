import { Component, OnInit } from '@angular/core';
import { AppConstant } from 'src/app/constants/app.constants';
import { CompanyProfileService } from 'src/app/services/company-profile/company-profile.service';
import { BoardPopupComponent } from 'src/app/shared/board-popup/board-popup.component';
import { AddTaskComponent } from '../add-task/add-task/add-task.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TaskManagementService } from 'src/app/services/task-management/task-management.service';
import { QuickAccess } from 'src/app/model/task-management/task-management/task-management.module';

@Component({
  selector: 'app-task-management-sidenav',
  templateUrl: './task-management-sidenav.component.html',
  styleUrls: ['./task-management-sidenav.component.scss']
})
export class TaskManagementSidenavComponent implements OnInit {
  companyLogo: any;
  companyName: any;
  isManager!: boolean;
  isExpanded: boolean = true;
  quickAccessList: QuickAccess[] = [];
  adminRole!: boolean;

  constructor(private router: Router, private dialog: MatDialog, private companyProfileService: CompanyProfileService, private taskManagementService: TaskManagementService) { }

  ngOnInit() {
    const roles = sessionStorage.getItem(AppConstant.ASSIGN_ROLES);
    this.isManager = roles?.includes('Manager') ? true : false;
    this.adminRole = sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.ADMIN ? true : false;
    this.getCompanyLogo();
    this.getQuickAccessList();
  }

  getCompanyLogo() {
    this.companyProfileService.getCompanyData(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (companyDetails: any) => {
        this.companyLogo = 'data:image/png;base64,' + companyDetails.data.logo;
        this.companyName = companyDetails.data.companyName;
      }
    );
  }

  getQuickAccessList() {
    this.taskManagementService.getQuickAccessList().subscribe(
      (quickAccessList: any) => {
        if (quickAccessList.status === 200) {
          this.quickAccessList = quickAccessList.data;
        }
      }
    )
  }

  quickAccessSprint(task: QuickAccess) {
    this.router.navigate([AppConstant.TASK_MANAGEMENT_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_TASK],
      {
        queryParams: {
          sprintId: task.sprintId,
          taskId: task.taskId,
          dashboardId: task.dashboardId,
        }
      }
    )
  }

  logout() {
    sessionStorage.clear();
    sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.ADMIN ?
    this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]) :
    this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE])  }

  addBoard() {
    const dialogRef = this.dialog.open(BoardPopupComponent, {
      disableClose: true,
    });
  }

  backToDashboard() {
    sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.ADMIN ?
      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]) :
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE])
  }

  addTask() {
    const dialogRef = this.dialog.open(AddTaskComponent, {
      height: '450px',
      width: '550px',
      disableClose: true,
    });
  }
}
