import { Component } from '@angular/core';
import { LandingPageMasterData } from 'src/app/constants/master-data/landing-page-master-data';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss']
})
export class FeaturesComponent {
  features: FeaturesData[] = LandingPageMasterData.LANDING_PAGE_FEATURES;
}

export interface FeaturesData {
  icon: string;
  title: string;
  content: string;
}
