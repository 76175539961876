<div class="tittle-div">
    <span matTooltip="{{'back_to_dashboard' | translate}}" (click)="goToDashboard()">
        <mat-icon svgIcon="arrow-back-double" class="rewind"></mat-icon>
    </span>
    <div class="declaration_heading_container">
        <h3>{{ 'it_declaration_approval' | translate }}</h3>
    </div>
</div>
<div class="main-container">
    <div class="last-year-summary">
        <div class="last-year-summary-table">
            <div class="last-year-summary-table-row-header">
                <span class="section-heading">{{ 'last_financial_year_summary' | translate }}</span>
                <span>{{ 'maximum_amount' | translate }}</span>
                <span>{{ 'declared_amount' | translate }}</span>
            </div>
            <mat-divider></mat-divider>
            <mat-accordion>
                <mat-expansion-panel *ngFor="let item of rowData">
                    <mat-expansion-panel-header>
                        <div class="last-year-summary-table-row">
                            <span class="section-heading">{{ convertLabel(item.name) }}</span>
                            <span>{{ item.amount | number: '1.2-2' }}</span>
                            <span *ngIf="item.name === 'house rent details'">{{ item.amount | number: '1.2-2' }}</span>
                            <span *ngIf="item.name !== 'house rent details'">{{ item.updatedAmount | number: '1.2-2'
                                }}</span>
                        </div>
                    </mat-expansion-panel-header>
                    <ng-container *ngIf="item.name === 'house rent details'; else otherDetails">
                        <div class="house-rent-details">
                            <div class="detail-row">
                                <strong class="detail-label">{{ 'from_date' | translate }} :</strong>
                                <span class="detail-value">{{ houseRentDetails.fromDate | date }}</span>
                            </div>
                            <div class="detail-row">
                                <strong class="detail-label">{{ 'to_date' | translate }}:</strong>
                                <span class="detail-value">{{ houseRentDetails.toDate | date }}</span>
                            </div>
                            <div class="detail-row">
                                <strong class="detail-label">{{ 'city' | translate }}:</strong>
                                <span class="detail-value">{{ houseRentDetails.city }}</span>
                            </div>
                            <div class="detail-row">
                                <strong class="detail-label">{{ 'landlord_name' | translate }}:</strong>
                                <span class="detail-value">{{ houseRentDetails.landlordName }}</span>
                            </div>
                            <div class="detail-row">
                                <strong class="detail-label">{{ 'landLord_pan' | translate }}:</strong>
                                <span class="detail-value">{{ houseRentDetails.landLordPan }}</span>
                            </div>
                            <div class="detail-row">
                                <strong class="detail-label">{{ 'rented_house_address' | translate }}:</strong>
                                <span class="detail-value">{{ houseRentDetails.rentedHouseAddress }}</span>
                            </div>
                            <div class="detail-row">
                                <strong class="detail-label">{{ 'rent_per_month_declared' | translate }}:</strong>
                                <span class="detail-value">{{ houseRentDetails.rentPerMonthDeclared | number: '1.2-2'
                                    }}</span>
                            </div>
                            <div class="approved-amount-detail-row">
                                <strong class="detail-label">{{ 'approved_amount' | translate }}:</strong>
                                <div class="validation-error">
                                    <input type="number" class="declaration-amount-input"
                                        formControlName="houseRentFormControlName" [max]="item.amount" maxlength="10"
                                        step="1" (change)="saveHouseRentDetails($event, item)">
                                    <mat-error *ngIf="item.approvedAmountError && item.approvedAmountError !== null">
                                        {{item.approvedAmountError | translate }} {{ item.amount }}
                                    </mat-error>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #otherDetails>
                        <div class="sub-table-row-header">
                            <span class="section-name-heading">{{ 'last_financial_year_summary' | translate }}</span>
                            <span>{{ 'maximum_amount' | translate }}</span>
                            <span>{{ 'declared_amount' | translate }}</span>
                            <span>{{ 'approved_amount' | translate }}</span>
                        </div>
                        <div *ngFor="let subItem of item.data">
                            <div class="last-year-summary-table-data-row">
                                <span class="section-heading">{{ convertLabel(subItem.name) }}</span>
                                <span>{{ subItem.amount | number: '1.2-2' }}</span>
                                <span>{{ subItem.updatedAmount | number: '1.2-2' }}</span>
                                <div class="validation-error">
                                    <input type="number" class="declaration-amount-input" [max]="item.amount"
                                        maxlength="10" step="1" (change)="declarationAmount($event, subItem, item)">
                                    <mat-error
                                        *ngIf="subItem.approvedAmountError && subItem.approvedAmountError !== null">
                                        {{subItem.approvedAmountError |translate }}
                                    </mat-error>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                        </div>
                    </ng-template>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div class="button-div">
        <button type="submit" (click)="rejectDeclaration()">{{'reject'|translate}}</button>
        <button type="submit" (click)="approveDeclaration()">{{'approve'|translate}}</button>
    </div>
</div>