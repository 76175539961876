import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { RefreshToken } from 'src/app/model/token/token.module';
import { ActivityMonitorService } from 'src/app/services/activity-monitor/activity-monitor.service';
import { TokenService } from 'src/app/services/token/token.service';

@Component({
  selector: 'app-refresh-token',
  templateUrl: './refresh-token.component.html',
  styleUrls: ['./refresh-token.component.scss']
})
export class RefreshTokenComponent {
  constructor(private tokenRefreshService: TokenService, private activityMonitorService: ActivityMonitorService, private router: Router, private dialogRef: MatDialogRef<RefreshTokenComponent>, @Inject(MAT_DIALOG_DATA) public data: { message: string }) { }

  token() {
    this.dialogRef.close();
    let token: RefreshToken = {
      "token": sessionStorage.getItem(AppConstant.TOKEN)
    };

    this.tokenRefreshService.refreshToken(token).subscribe(
      (data: any) => {
        this.dialogRef.close();
        if (data.status == 401) {
          this.activityMonitorService.stopMonitoring();
          this.router.navigate([AppConstant.LOGIN_PAGE]);
        }
        else {
          const token = data.headers.get(AppConstant.AUTHORIZATION);
          if (token) {
            sessionStorage.setItem(AppConstant.TOKEN, token);
          }
        }
      }
    );
  }
}
