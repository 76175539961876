
<mat-card>
    <mat-card-content>
      <form name="branchForm" [formGroup]="addBranchForm" enctype="multipart/form-data">
        <div>
          <div class="header-container">
            <h3 class="title">
                {{ branchId ? 'Update Branch' : 'Add Branch' | translate }}
              </h3>
  
              <mat-icon *ngIf="branchId" (click)="toggleEditMode()" class="edit-icon">
                {{ isEditMode ? 'save' : 'edit' }}
              </mat-icon>
          </div>
  
          <div class="form-field-gap4">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>{{'country' | translate}}</mat-label>
              <mat-select matInput formControlName="country" class="select_mat_input" required="true"
                (selectionChange)="getState($event.value)">
                <mat-option *ngFor="let country of countryList;" [value]="country">{{ country }}</mat-option>
              </mat-select>
            </mat-form-field>
  
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>{{ 'gst_number' | translate }}</mat-label>
              <div class="input-container">
                <input matInput type="text" class="input" (keypress)="alphanumberOnly($event)" maxlength="15"
                  formControlName="gstNumber" oninput="this.value = this.value.toUpperCase()" />
                <a *ngIf="!verifiedGST && !isLoading && isEditMode" (click)="verifyGST()"
                  [ngClass]="addBranchForm.controls['gstNumber'].value?.length === 15 ? 'verify-blue' : 'verify-gray'">
                  {{ 'verify' | translate }}
                </a>
                <mat-icon *ngIf="verifiedGST" class="success-icon" color="primary">done</mat-icon>
              </div>
            </mat-form-field>
          </div>
  
          <div class="form-field-gap4">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{'branch_name' | translate}}</mat-label>
              <input matInput type="text" class="input" formControlName="branchName" maxlength="50" />
            </mat-form-field>
  
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{'branch_code' | translate}}</mat-label>
              <input matInput type="text" class="input" formControlName="branchCode" maxlength="10"/>
            </mat-form-field>
          </div>
  
          <div class="form-field-gap4">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>{{ 'state' | translate }}</mat-label>
              <mat-select matInput formControlName="state" class="select-mat-input" required="true"
                (selectionChange)="getCity($event.value)" [disabled]="!isEditMode">
                <mat-option *ngFor="let state of stateList;" [value]="state">{{ state }}</mat-option>
              </mat-select>
            </mat-form-field>
  
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>{{ 'city' | translate }}</mat-label>
              <mat-select matInput formControlName="city" class="select-mat-input" [disabled]="!isEditMode">
                <mat-option *ngFor="let city of cityList;" [value]="city">{{ city }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
  
          <div class="form-field-gap4">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{'registration-number' | translate}}</mat-label>
              <input matInput type="text" class="input" formControlName="registrationNo" maxlength="21" />
            </mat-form-field>
  
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{'registration-date' | translate}}</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="registrationDate" [disabled]="!isEditMode" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="form-button">
            <div class="container">
              <button *ngIf="!branchId" class="button-right" type="submit" (click)="submitForm()">
                {{ 'add_branch' | translate }}
              </button>
  
              <button *ngIf="branchId" class="button-right" type="submit" (click)="submitForm()">
                {{ 'update_branch' | translate }}
              </button>
  
              <button class="button-right cancel-btn" (click)="cancel()" type="reset">
                {{ 'cancel' | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
    