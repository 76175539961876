<div class="tax-declaration">
    <h3>{{ 'tax_declaration_configuration' | translate }}</h3>
    <div class="buttonDiv">
        <button color="primary" (click)="openAddSectionPopup()">{{ 'add_section' | translate }}</button>
    </div>
</div>
<div class="section-list-table">
    <ag-grid-angular (gridReady)="onGridReady($event)" style="height: 28rem;  width: 100%;" [rowData]="sectionList"
        class="ag-theme-alpine" [defaultColDef]="defaultColDef" [columnDefs]="columnDefs" [suppressCellSelection]="true"
        [animateRows]="true">
    </ag-grid-angular>
</div>