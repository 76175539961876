
import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AppConstant } from 'src/app/constants/app.constants';
import { UserSubscriptionService } from 'src/app/services/userSubscription/user-subscription.service';
import { InstructionsDialogComponent } from '../../instructions-dialog-component/instructions-dialog.component';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { SendLinkOnEmail } from 'src/app/model/attendance-model/subscription.model';
import { HttpHeaders } from '@angular/common/http';
import { CompanyProfileService } from 'src/app/services/company-profile/company-profile.service';
import { FormatUtil } from 'src/app/utils/format.utils';
import { SubscriptionService } from 'src/app/services/admin-model-services/subscription/subscription.service';
@Component({
  selector: 'app-payment-discription',
  templateUrl: './payment-discription.component.html',
  styleUrls: ['./payment-discription.component.scss']
})
export class PaymentDiscriptionComponent implements OnInit {
  qrID: string | undefined;
  qrCodeBase64: SafeResourceUrl | undefined;
  paymentStatus: any;
  isQRPresent: boolean = true;
  selectedSubscription: any;
  remainingTime: string = '5:00';
  timerInterval: any;
  name: any;
  email: any;
  contact: any;
  sendEmail: any;
  isEmail: boolean = false;
  proceedToPay: any;
  emailPaymentId: any;
  finalSubscriptionAmount: any
  companyDetails: any;
  loginPersonName: any;
  loginEmail: any;
  adminContact: any;
  private stopCheckingPaymentStatus: boolean = false;

  subscriptionId = sessionStorage.getItem(AppConstant.SUBSCRIPTIONID);
  constructor(private scriptionService: SubscriptionService, private dialog: MatDialog, private userSubscriptionService: UserSubscriptionService, private toastrService: ToastrService, private router: Router, private companyProfileService: CompanyProfileService) { }

  ngOnInit(): void {
    const companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
    if (companyId) {
      this.companyProfileService.getCompanyData(companyId).subscribe((data: any) => {
        this.companyDetails = data.data.hrModel;
        this.loginPersonName = data.data.hrModel.name;
        this.loginEmail = data.data.hrModel.email;
        this.adminContact = data.data.hrModel.mobileNumber;
        this.onPaymentOptionSelected(true);
      }, error => {
        this.onPaymentOptionSelected(false);
      });
    } else {
      this.onPaymentOptionSelected(false);
    }
  }

  getCompanyDetails() {
    const companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
    if (companyId) {
      this.companyProfileService.getCompanyData(companyId).subscribe((data: any) => {
        this.companyDetails = data.data.hrModel;
        this.loginPersonName = data.data.hrModel.name;
        this.loginEmail = data.data.hrModel.email;
        this.adminContact = data.data.hrModel.mobileNumber;
        this.onPaymentOptionSelected(true);
      });
    } else {
      this.onPaymentOptionSelected(false);
    }
  }

  onPaymentOptionSelected(companyDetailsLoaded: boolean): void {
    this.selectedSubscription = sessionStorage.getItem("subscriptionName");
    const subscriptionPrice = sessionStorage.getItem(AppConstant.SUBSCRIPTION_PRICE);

    if (subscriptionPrice) {
      this.finalSubscriptionAmount = parseFloat(subscriptionPrice) * 100;
    } else {
      this.finalSubscriptionAmount = 0;
    }

    const registrationRequestString = sessionStorage.getItem("createRegistrationRequest");

    if (registrationRequestString) {
      const registrationRequestData = JSON.parse(registrationRequestString);
      this.name = registrationRequestData.name;
      this.email = registrationRequestData.email;
      this.contact = registrationRequestData.mobileNumber;
    } else if (companyDetailsLoaded && this.companyDetails) {
      this.name = this.companyDetails.name;
      this.email = this.companyDetails.email;
      this.contact = this.companyDetails.mobileNumber;
    }

    let createEmailRequest: SendLinkOnEmail = {
      name: this.name,
      email: this.email,
      contact: this.contact,
      amount: this.finalSubscriptionAmount,
      subscriptionName: this.selectedSubscription
    };

    if (sessionStorage.getItem('upi') === 'upi') {
      this.userSubscriptionService.buySubscription(createEmailRequest).subscribe((data: any) => {
        this.qrID = data.data.id;
        if (data.status === 200 && data.data.QR) {
          this.isQRPresent = false;
          this.convertImageToBase64(data.data.QR);
          this.startTimer();
          setTimeout(() => {
            this.callPaymentStatusAPI();
          }, 10000);
        }
      });
    } else if (sessionStorage.getItem('email') === 'email') {
      this.isEmail = true;
      this.sendEmail = createEmailRequest;
      this.linkPayment();
    }
  }

  sendLinkOnMail(event: MouseEvent) {
    event.preventDefault();
    this.userSubscriptionService.emailLink(this.sendEmail).subscribe((data: any) => {
      this.emailPaymentId = data.data.id;
      if (data.status === 200) {
        this.proceedToPay = data.data.short_url;
        if (this.proceedToPay) {
          window.open(this.proceedToPay, '_blank');
          window.close();
          this.sendLinkOnMails();
        }
      }
    });
  }

  sendLinkOnMails() {
    if (this.stopCheckingPaymentStatus) {
      return;
    }

    const companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);

    this.userSubscriptionService.emailPymanetStatus(this.emailPaymentId).subscribe((secondData: any) => {
      if (secondData.status === 200 && secondData.data.paymentStatus === 'created') {
        setTimeout(() => {
          this.sendLinkOnMails();
        }, 10000);
      } else if (secondData.status === 200 && secondData.data.paymentStatus === 'paid') {
        this.stopCheckingPaymentStatus = true;
        if (!companyId) {
          const formData = new FormData();
          const logoData = sessionStorage.getItem('logo');
          if (logoData) {
            const blob = FormatUtil.dataURItoBlob(logoData);
            formData.append('logo', blob);
          }
          const companyDetailsDto = sessionStorage.getItem('companyDetailsDto');
          if (companyDetailsDto) {
            formData.append("companyDetailsDto", companyDetailsDto);
          }
          const registrationDetailsDto = sessionStorage.getItem(AppConstant.CREATE_REGISTRATION_REQUEST);
          if (registrationDetailsDto) {
            formData.append("registrationDetailsDto", registrationDetailsDto);
          }
          const headers = new HttpHeaders();
          headers.append('Content-Type', 'multipart/form-data');
          this.companyProfileService.postCompanyData(formData, headers)
            .subscribe((data: any) => {
              this.userSubscriptionService.saveUserSubscription(data.data.companyId, sessionStorage.getItem(AppConstant.SUBSCRIPTION_ID)).subscribe(
                (res: any) => {
                  if (res.status === 200) {
                    sessionStorage.setItem(AppConstant.COMPANY_ID, data.data.companyId);
                    sessionStorage.setItem(AppConstant.EMPLOYEE_ID, data.data.adminId);
                    sessionStorage.setItem(AppConstant.ADMIN_ID, data.data.adminId);
                    if (data.status === 200) {
                      const token: any = sessionStorage.getItem(AppConstant.TOKEN);
                      const tokenData = JSON.parse(atob(token.split('.')[1]));
                      if (tokenData.roles && tokenData.roles.includes(AppConstant.ADMIN)) {
                        this.dialog.open(InstructionsDialogComponent, {
                          width: '500px',
                          data: {}
                        });
                        sessionStorage.setItem(AppConstant.ROLE_FOR_SESSION, tokenData.roles);
                        sessionStorage.removeItem(AppConstant.SUBSCRIPTIONID);
                        this.toastrService.clear();
                        this.toastrService.success(data.message);
                        this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.DASHBOARD]);
                      }
                    } else {
                      this.toastrService.clear();
                      this.toastrService.error(data.message);
                      this.router.navigate([AppConstant.VIEW_COMPANY_PROFILE]);
                    }
                  }
                });
            });
        } else {
          this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.USER_SUBSCRIPTION_VIEW]);
        }
      }
    });
  }
  linkPayment() {
    this.userSubscriptionService.emailLink(this.sendEmail).subscribe((data: any) => {
      this.emailPaymentId = data.data.id
      if (data.status === 200) {
        this.sendLinkOnMails();
      }
    });
  }

  startTimer() {
    let minutes = 5;
    let seconds = 0;
    this.timerInterval = setInterval(() => {
      if (minutes === 0 && seconds === 0) {
        clearInterval(this.timerInterval);
        this.router.navigate([AppConstant.SUBSCRIPTION_PLAN]);
      } else {
        if (seconds === 0) {
          minutes--;
          seconds = 59;
        } else {
          seconds--;
        }
        this.remainingTime = `${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
      }
    }, 1000);
  }
  convertImageToBase64(imageData: string) {
    const base64Image = 'data:image/png;base64,' + imageData;
    this.qrCodeBase64 = base64Image;
  }

  callPaymentStatusAPI() {
    let retries = 0;
    const maxRetries = 30;
    const retryInterval = 10000;
    const companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);

    const checkStatus = () => {
      this.userSubscriptionService.paymentStatus(this.qrID).subscribe((data: any) => {
        this.paymentStatus = data.data.paymentStatus;
        if (data.status === 200 && this.paymentStatus === 'pending') {
          retries++;
          if (retries < maxRetries) {
            setTimeout(checkStatus, retryInterval);
          } else {
            this.router.navigate([AppConstant.SUBSCRIPTION_PLAN]);
          }
        } else if (this.paymentStatus === "paid") {
          if (!companyId) {
            const formData = new FormData();
            const logoData = sessionStorage.getItem('logo');
            if (logoData) {
              const blob = FormatUtil.dataURItoBlob(logoData);
              formData.append('logo', blob);
            }
            const companyDetailsDto = sessionStorage.getItem('companyDetailsDto');
            if (companyDetailsDto) {
              formData.append("companyDetailsDto", companyDetailsDto);
            }
            const registrationDetailsDto = sessionStorage.getItem(AppConstant.CREATE_REGISTRATION_REQUEST);
            if (registrationDetailsDto) {
              formData.append("registrationDetailsDto", registrationDetailsDto);
            }
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'multipart/form-data');
            this.companyProfileService.postCompanyData(formData, headers)
              .subscribe((data: any) => {
                this.userSubscriptionService.saveUserSubscription(data.data.companyId, sessionStorage.getItem(AppConstant.SUBSCRIPTION_ID)).subscribe(
                  (res: any) => {
                    if (res.status === 200) {
                      sessionStorage.setItem(AppConstant.COMPANY_ID, data.data.companyId);
                      sessionStorage.setItem(AppConstant.EMPLOYEE_ID, data.data.adminId);
                      sessionStorage.setItem(AppConstant.ADMIN_ID, data.data.adminId);
                      if (res.status === 200) {
                        const token: any = sessionStorage.getItem(AppConstant.TOKEN);
                        const tokenData = JSON.parse(atob(token.split('.')[1]));
                        if (tokenData.roles && tokenData.roles.includes(AppConstant.ADMIN)) {
                          this.dialog.open(InstructionsDialogComponent, {
                            width: '500px',
                            data: {}
                          });
                          sessionStorage.setItem(AppConstant.ROLE_FOR_SESSION, tokenData.roles);
                          sessionStorage.removeItem(AppConstant.SUBSCRIPTIONID);
                          this.toastrService.clear();
                          this.toastrService.success(data.message);
                          this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.DASHBOARD]);
                        }
                      } else {
                        this.toastrService.clear();
                        this.toastrService.error(data.message);
                        this.router.navigate([AppConstant.VIEW_COMPANY_PROFILE]);
                      }
                    }
                  },

                );
              },
              );
          } else {
            this.userSubscriptionService.saveUserSubscription(sessionStorage.getItem(AppConstant.COMPANY_ID), this.subscriptionId).subscribe(
              (res: any) => {
                if (res.status === 200) {
                  this.scriptionService.getSubscriptionAccess().subscribe(
                    (data: any) => {
                      sessionStorage.setItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS, JSON.stringify(data.data));
                      this.toastrService.clear();
                      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.USER_SUBSCRIPTION_VIEW]);
                    }
                  );
                }
              }
            );
          }
        }
      },
      );
    };
    checkStatus();
  }


}