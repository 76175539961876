<div class="container">
  <div class="header">
    <h2>{{'add_bank_details' | translate}}</h2>
  </div>
  <form name="bankDetailsForm" [formGroup]="bankDetailsForm">
    <div>
      <div class="form-field-gap">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{'employee_id' | translate}}</mat-label>
          <input matInput type="text" class="input" formControlName="id" required />
          <mat-error *ngIf="!bankDetailsForm.controls['id'].valid">
            {{'employee_id_required' | translate}}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{'bank_name' | translate}}</mat-label>
          <input matInput class="input" formControlName="bankName" required [maxLength]="30" />
          <mat-error *ngIf="bankDetailsForm.controls['bankName'].hasError('required')">
            {{ 'bank_name_is_required' | translate }}
          </mat-error>
          <mat-error *ngIf="bankDetailsForm.controls['bankName'].hasError('invalidInput')">
            {{ 'invalid_name_input' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-field-gap">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{'branch_name' | translate}}</mat-label>
          <input matInput type="text" class="input" formControlName="branch" required [maxLength]="30"
            (keypress)="validateCharacterNumberAndSpace($event)" />
          <mat-error *ngIf="bankDetailsForm.controls['branch'].hasError('required')">
            {{ 'branch_name_is_required' | translate }}
          </mat-error>
          <mat-error *ngIf="bankDetailsForm.controls['branch'].hasError('invalidInput')">
            {{ 'invalid_name_input' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label class="formLabel"> {{'account_type'| translate}}</mat-label>
          <mat-select class="select-mat-input" formControlName="accountType" required>
            <mat-option *ngFor="let accountType of accountType" [value]="accountType.accountType">
              {{accountType.selectedAccount}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="!bankDetailsForm controls['accountType'].valid">{{'account_type_is_required'|
            translate}}</mat-error>
        </mat-form-field>
      </div>
      <div class="form-field-gap">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{'account_number' | translate}}</mat-label>
          <input class="input" id="password-field1" type="password" (keypress)="validateNumber($event)" minlength="8"
            [maxLength]="18" [type]="hidePassword ? 'password' : 'text'" formControlName="bankAccountNo" matInput
            required />

          <mat-error *ngIf="!bankDetailsForm.controls['bankAccountNo'].valid">
            {{'account_number_required'| translate}}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{'confirm_account_number' | translate}}</mat-label>
          <input class="input" id="password-field" (input)="validateAccountNumberMatch()"
            (keypress)="validateNumber($event)" [type]="hidePassword ? 'password' : 'text'"
            formControlName="confirmBankAccountNo" matInput required />
          <mat-error *ngIf="bankDetailsForm.hasError('mismatch', 'confirmBankAccountNo')">
            {{'account_do_not_match'|translate}}
          </mat-error>
        </mat-form-field>

        <button class="visibility" mat-icon-button (click)="hidePassword = !hidePassword"
          [attr.aria-label]="'Toggle Password Visibility'" [attr.aria-pressed]="!hidePassword">
          <i class="material-icons">{{ hidePassword ? 'visibility_off' : 'visibility' }}</i>
          <span class="password-text">{{(hidePassword ? 'show' : 'hide') | translate}}</span>
        </button>
      </div>
      <div class="form-field-gap">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{'iban_code'| translate}}</mat-label>
          <input matInput type="text" class="input" formControlName="ibanCode" required />
          <mat-error *ngIf="!bankDetailsForm.controls['ibanCode'].valid">
            {{'iban_code_is_required'| translate}}
          </mat-error>
        </mat-form-field>

        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{'ifsc_code'| translate}}</mat-label>
          <input matInput type="text" class="input" [maxLength]="11" (keypress)="alphanumericAllowed($event)"
            formControlName="ifscCode" appUppercase required />
          <mat-error *ngIf="!bankDetailsForm.controls['ifscCode'].valid">
            {{'ifsc_code_is_required'| translate}}
          </mat-error>
        </mat-form-field>
      </div>
      <app-loader></app-loader>
      <button class="button" (click)="addBankDetails()" type="submit" color="primary">{{'save_bank_details'|
        translate}}</button>
    </div>
  </form>
</div>