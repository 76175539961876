<h2 mat-dialog-title class="profile-header">
    <!-- <img src="../../../../../assets/images/avatar/profile-avtar-img.jpg" alt="Profile Image" class="profile-img"> -->
    <span>{{ employee.employeeName }}</span>
  </h2>
  
  <mat-dialog-content class="profile-content">
    <div class="profile-details">
      <div class="profile-row" *ngFor="let field of profileFields">
        <mat-icon class="icon">{{ field.icon }}</mat-icon>
        <!-- <div class="profile-text"> -->
          <span>{{ field.label | translate }}</span>
          <strong>{{ field.value }}</strong>
        <!-- </div> -->
      </div>
    </div>
  </mat-dialog-content>
  
  <mat-dialog-actions align="end">
    <button mat-button color="primary" (click)="closeDialog()">
      Close
    </button>
  </mat-dialog-actions>