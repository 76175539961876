<div class="document-upload">
    <form [formGroup]="documentsUploadForm">
        <div class="form-field-gap">
            <div class="form-group">
                <label for="adharCard" class="label"><strong>{{ 'aadhar_card' | translate}}</strong></label>
                <input type="file" id="aadhaarCard" formControlName="aadhaarCard" style="display: none"
                    #aadhaarCardInput (change)="onFileChange($event, 'aadhaarCard')" accept=".jpg, .png, .pdf, .docx" />
                <button mat-raised-button style="color: black; background-color: white; width: auto"
                    (click)="aadhaarCardInput.click()">
                    <span *ngIf="documentsUploadForm.get('aadhaarCard')?.hasError('null')">{{
                        aadhaarCardInput.files![0].name }}</span>
                    <span *ngIf="!documentsUploadForm.get('aadhaarCard')?.hasError('null')">{{ 'upload_aadhar_card' |
                        translate }}</span>
                </button>
                <mat-error
                    *ngIf="documentsUploadForm.get('aadhaarCard')?.touched && documentsUploadForm.get('aadhaarCard')?.hasError('required')">
                    {{ 'aadhar_is_required' | translate }}
                </mat-error>
                <mat-error *ngIf="documentsUploadForm.get('aadhaarCard')?.hasError('fileTypeInvalid')">
                    {{ 'unsupported_file_type' | translate }}
                </mat-error>
                <mat-error *ngIf="documentsUploadForm.get('aadhaarCard')?.hasError('fileNameInvalid')">
                    {{ 'unsupported_file_name' | translate }}
                </mat-error>
                <mat-error *ngIf="documentsUploadForm.get('aadhaarCard')?.hasError('fileSizeExceeded')">
                    {{ 'unsupported_file_size' | translate }}
                </mat-error>
            </div>

            <div class="form-group">
                <label for="panCard" class="label"><strong>{{ 'pan_card' | translate}}</strong></label>
                <input type="file" id="panCard" formControlName="panCard" style="display: none" #panCardInput
                    (change)="onFileChange($event, 'panCard')" accept=".jpg, .png, .pdf, .docx" />
                <button mat-raised-button style="color: black; background-color: white; width: auto;"
                    (click)="panCardInput.click()">
                    <span *ngIf="documentsUploadForm.get('panCard')?.hasError('null')">{{ panCardInput.files![0].name
                        }}</span>
                    <span *ngIf="!documentsUploadForm.get('panCard')?.hasError('null')">{{'upload_pan_card' |
                        translate}}</span>
                </button>
                <mat-error
                    *ngIf="documentsUploadForm.get('panCard')?.touched && documentsUploadForm.get('panCard')?.hasError('required')">
                    {{ 'pan_card_is_required' | translate }}
                </mat-error>
                <mat-error *ngIf="documentsUploadForm.get('panCard')?.hasError('fileTypeInvalid')">
                    {{ 'unsupported_file_type' | translate }}
                </mat-error>
                <mat-error *ngIf="documentsUploadForm.get('panCard')?.hasError('fileNameInvalid')">
                    {{ 'unsupported_file_name' | translate }}
                </mat-error>
                <mat-error *ngIf="documentsUploadForm.get('panCard')?.hasError('fileInvalid')">
                    {{ 'unsupported_file_size' | translate }}
                </mat-error>
            </div>

            <div class="form-group">
                <label for="passport" class="label"><strong>{{ 'passport' | translate}}</strong></label>
                <input type="file" id="passport" formControlName="passport" style="display: none" #passportInput
                    (change)="onFileChange($event, 'passport')" accept=".jpg, .png, .pdf, .docx" />
                <button mat-raised-button style="color: black; background-color: white; width: auto;"
                    (click)="passportInput.click()">
                    <span *ngIf="documentsUploadForm.get('passport')?.hasError('null')">{{ panCardInput.files![0].name
                        }}</span>
                    <span *ngIf="!documentsUploadForm.get('passport')?.hasError('null')">{{'upload_passport' |
                        translate}}</span>
                </button>
                <mat-error
                    *ngIf="documentsUploadForm.get('passport')?.touched && documentsUploadForm.get('passport')?.hasError('required')">
                    {{ 'passport_is_required' | translate }}
                </mat-error>
                <mat-error *ngIf="documentsUploadForm.get('passport')?.hasError('fileTypeInvalid')">
                    {{ 'unsupported_file_type' | translate }}
                </mat-error>
                <mat-error *ngIf="documentsUploadForm.get('passport')?.hasError('fileNameInvalid')">
                    {{ 'unsupported_file_name' | translate }}
                </mat-error>
                <mat-error *ngIf="documentsUploadForm.get('passport')?.hasError('fileInvalid')">
                    {{ 'unsupported_file_size' | translate }}
                </mat-error>
            </div>
        </div>

        <div class="form-field-gap">
            <div class="form-group">
                <label for="cancelCheque" class="label"><strong>{{ 'cancel_cheque' | translate}}</strong></label>
                <input type="file" id="cancelCheque" formControlName="cancelCheque" style="display: none"
                    #cancelChequeInput (change)="onFileChange($event, 'cancelCheque')"
                    accept=".jpg, .png, .pdf, .docx" />
                <button mat-raised-button style="color: black; background-color: white; width: auto;"
                    (click)="cancelChequeInput.click()">
                    <span *ngIf="documentsUploadForm.get('cancelCheque')?.hasError('null')">{{
                        cancelChequeInput.files![0].name }}</span>
                    <span *ngIf="!documentsUploadForm.get('cancelCheque')?.hasError('null')">{{'upload_cancel_cheque' |
                        translate}}</span>
                </button>
                <mat-error
                    *ngIf="documentsUploadForm.get('cancelCheque')?.touched && documentsUploadForm.get('cancelCheque')?.hasError('required')">
                    {{ 'cancel_cheque_is_required' | translate }}
                </mat-error>
                <mat-error *ngIf="documentsUploadForm.get('cancelCheque')?.hasError('fileTypeInvalid')">
                    {{ 'unsupported_file_type' | translate }}
                </mat-error>
                <mat-error *ngIf="documentsUploadForm.get('cancelCheque')?.hasError('fileNameInvalid')">
                    {{ 'unsupported_file_name' | translate }}
                </mat-error>
                <mat-error *ngIf="documentsUploadForm.get('cancelCheque')?.hasError('fileInvalid')">
                    {{ 'unsupported_file_size' | translate }}
                </mat-error>
            </div>

            <div class="form-group">
                <label for="employeePhoto" class="label"><strong>{{ 'emp_photo' | translate}}</strong></label>
                <input type="file" id="employeePhoto" formControlName="employeePhoto" style="display: none"
                    #employeePhotoInput (change)="onFileChange($event, 'employeePhoto')"
                    accept=".jpg, .png, .pdf, .docx" />
                <button mat-raised-button style="color: black; background-color: white; width: auto;"
                    (click)="employeePhotoInput.click()">
                    <span *ngIf="documentsUploadForm.get('employeePhoto')?.hasError('null')">{{
                        employeePhotoInput.files![0].name }}</span>
                    <span *ngIf="!documentsUploadForm.get('employeePhoto')?.hasError('null')">{{'upload_emp_photo' |
                        translate}}</span>
                </button>
                <mat-error
                    *ngIf="documentsUploadForm.get('employeePhoto')?.touched && documentsUploadForm.get('employeePhoto')?.hasError('required')">
                    {{ 'employee_photo_required' | translate }}
                </mat-error>
                <mat-error *ngIf="documentsUploadForm.get('employeePhoto')?.hasError('fileTypeInvalid')">
                    {{ 'unsupported_file_type' | translate }}
                </mat-error>
                <mat-error *ngIf="documentsUploadForm.get('employeePhoto')?.hasError('fileNameInvalid')">
                    {{ 'unsupported_file_name' | translate }}
                </mat-error>
                <mat-error *ngIf="documentsUploadForm.get('employeePhoto')?.hasError('fileInvalid')">
                    {{ 'unsupported_file_size' | translate }}
                </mat-error>
            </div>

            <div class="form-group">
                <label for="offerLetter" class="label"><strong>{{ 'offer_letter' | translate}}</strong></label>
                <input type="file" id="offerLetter" formControlName="offerLetter" style="display: none"
                    #offerLetterInput (change)="onFileChange($event, 'offerLetter')" accept=".jpg, .png, .pdf, .docx" />
                <button mat-raised-button style="color: black; background-color: white; width: auto;"
                    (click)="offerLetterInput.click()">
                    <span *ngIf="documentsUploadForm.get('offerLetter')?.hasError('null')">{{
                        offerLetterInput.files![0].name }}</span>
                    <span *ngIf="!documentsUploadForm.get('offerLetter')?.hasError('null')">{{'upload_offer_letter' |
                        translate}}</span>
                </button>
                <mat-error
                    *ngIf="documentsUploadForm.get('offerLetter')?.touched && documentsUploadForm.get('offerLetter')?.hasError('required')">
                    {{ 'cancel_cheque_is_required' | translate }}
                </mat-error>
                <mat-error *ngIf="documentsUploadForm.get('offerLetter')?.hasError('fileTypeInvalid')">
                    {{ 'unsupported_file_type' | translate }}
                </mat-error>
                <mat-error *ngIf="documentsUploadForm.get('offerLetter')?.hasError('fileNameInvalid')">
                    {{ 'unsupported_file_name' | translate }}
                </mat-error>
                <mat-error *ngIf="documentsUploadForm.get('offerLetter')?.hasError('fileInvalid')">
                    {{ 'unsupported_file_size' | translate }}
                </mat-error>
            </div>
        </div>
        <button class="button" type="submit" (click)="submitForm()">{{ 'submit' | translate }}</button>
    </form>
</div>
<app-loader></app-loader>