<div class="setting__main_container">
    <span matTooltip="{{'back_to_dashboard' | translate}}" (click)="goToDashboard()">
        <mat-icon svgIcon="arrow-back-double" class="rewind"></mat-icon>
    </span>
    <div class="setting__heading_container">
        <h3>{{ 'project_approval' | translate }}</h3>
    </div>
</div>
<div class="main-container">
    <ag-grid-angular (gridReady)="onGridReady($event)" #agGride style="height: 35rem; width:100%;"
        class="ag-theme-alpine" [rowData]="projectList" [rowSelection]="'multiple'" [animateRows]="true"
        [columnDefs]="projectListCol" [defaultColDef]="projectListColDef" [enableSorting]="true" [enableFilter]="true"
        [pagination]="true" [paginationPageSize]="10">
    </ag-grid-angular>
</div>