import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, debounceTime, distinctUntilChanged, of, switchMap } from 'rxjs';
import { AppConstant } from 'src/app/constants/app.constants';
import { Subscription } from 'src/app/model/admin-model/subscription.model';
import { SubscriptionService } from 'src/app/services/admin-model-services/subscription/subscription.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-create-subscriptions',
  templateUrl: './create-subscriptions.component.html',
  styleUrls: ['./create-subscriptions.component.scss']
})
export class CreateSubscriptionsComponent {
  subscriptionForm: FormGroup | any;
  selectedData: any[] = [];
  selectFormControl = new FormControl();
  showOptions: boolean = false;
  searchControl = new FormControl();
  searchResults!: Observable<string[]>;
  filteredIssues!: string[];

  constructor(private fb: FormBuilder, private router: Router, private toaster: ToastrService, private subscriptionService: SubscriptionService) { }

  ngOnInit(): void {
    this.initSubscriptionForm();
    this.filteredIssues = ['Attendance', 'Leave Management', 'Pay Run', 'Employee Onboarding', 'Tax Declaration', 'Asset Management', 'Help Desk', 'Training Management', 'Performance Management', 'IT Declaration Management' ,'Project Management']
    this.searchResults = of(this.filteredIssues);
    this.searchResults = this.searchControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(query => this.searchGroup(query))
    );
  }

  initSubscriptionForm() {
    this.subscriptionForm = this.fb.group({
      'subscriptionName': ['', Validators.required],
      'subscriptionType': ['', Validators.required],
      'price': ['', Validators.required],
      'validityDays': ['', [Validators.required, this.minimumOneDayValidator()]],
      totalUser: ['', [Validators.required, this.maxValueValidator(100000)]]
    });

  }
  validateUserLimit(event: any) {
    const input = event.target.value;
    if (parseInt(input) > 100000) {
      event.target.value = input.substring(0, input.length - 1);
      this.subscriptionForm.get('totalUser')?.setValue(event.target.value);
    }
  }

  maxValueValidator(max: number) {
    return (control: any) => {
      if (control.value && parseInt(control.value) > max) {
        return { maxValue: true };
      }
      return null;
    };
  }
  minimumOneDayValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isInvalid = control.value !== null && control.value === '0';
      return isInvalid ? { 'minimumOneDay': { value: control.value } } : null;
    };
  }
  onSubmit() {
    if (this.subscriptionForm.valid) {
      let subscription: Subscription = {
        "subscriptionName": this.subscriptionForm.value.subscriptionName,
        "subscriptionType": this.subscriptionForm.value.subscriptionType,
        "price": this.subscriptionForm.value.price,
        "validityDays": this.subscriptionForm.value.validityDays,
        "totalUser": this.subscriptionForm.value.totalUser,
        "featureList": this.selectedData
      }
      this.subscriptionService.createSubscription(subscription).subscribe(
        (data: any) => {
          if (data.status === 200) {
            this.subscriptionForm.reset();
            this.selectedData = [];
            this.toaster.clear();
            this.toaster.success(data.message);
            this.router.navigate([AppConstant.ADMIN_SIDEBAR + AppConstant.SEPERATOR + AppConstant.SUBSCRIPTION_PLAN])
          }
          else {
            this.toaster.clear();
            this.toaster.error(data.message);
          }

        })
    }
  }
  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

  searchGroup(query: string): Observable<string[]> {
    const groupList: string[] = this.filteredIssues;
    const filteredGroupList = groupList.filter(groupList =>
      groupList.toLowerCase().includes(query.toLowerCase())
    );
    if (filteredGroupList.length > 0) {
      this.showOptions = true;
    }
    return of(filteredGroupList.slice(0, 9));
  }

  onOptionClick(group: string) {
    if (!this.selectedData.includes(group)) {
      this.selectedData.push(group);
    }
    this.showOptions = false;
    this.searchControl.reset();
  }


  removeSelectedValue(value: string) {
    const index = this.selectedData.indexOf(value);
    if (index !== -1) {
      this.selectedData.splice(index, 1);
    }
  }

  toggleOptions() {
    this.showOptions = !this.showOptions;
  }


  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumber(event);
  }
  gotToBack() {
    this.router.navigate([AppConstant.ADMIN_SIDEBAR + AppConstant.SEPERATOR + AppConstant.SUBSCRIPTION_PLAN]);
  }
}
