<div id="chart">
    <mat-card>
        <div class="title">
        <b>{{'profit_loss_graph'|translate}}</b>
            <div class="select-container">
                <mat-form-field appearance="outline">
                    <mat-select [(ngModel)]="selectedRange" (ngModelChange)="profitLossData()">
                        <mat-option value="monthly">{{'monthly'|translate}}</mat-option>
                        <mat-option value="yearly">{{'yearly'|translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <apx-chart [series]="profitLoss.series" [chart]="profitLoss.chart" [xaxis]="profitLoss.xaxis"
            [dataLabels]="profitLoss.dataLabels" [grid]="profitLoss.grid" [stroke]="profitLoss.stroke"
            [title]="profitLoss.title" [markers]="profitLoss.markers" [yaxis]="profitLoss.yaxis"
            [fill]="profitLoss.fill"></apx-chart>
        <div class="view"><a (click)="viewReport()">{{'view'|translate}}</a></div>

    </mat-card>
</div>