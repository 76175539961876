import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { MasterData } from 'src/app/constants/master-data/master-data.constant';
import { LeaveData } from 'src/app/model/leave-application/leave-history.model';
import { LeaveService } from 'src/app/services/leave-application/employee-side/leave-history/leave.service';
@Component({
  selector: 'app-leave-history',
  templateUrl: './leave-history.component.html',
  styleUrls: ['./leave-history.component.scss']
})
export class LeaveHistoryComponent {

  isEmployee: any;
  currentUserId = sessionStorage.getItem('role');
  leaveColDef: any;
  public balanceLeave!: LeaveData[];
  public leaveHistoryData!: LeaveData[];
  leaveTableCol = MasterData.COLUMNS_FIELD;
  companyId: string | null = sessionStorage.getItem(AppConstant.COMPANY_ID);
  employeeId: string | null = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
  public leaveHistory!: LeaveData[];
  totalLeaveCount: any;
  @Input() id: any;
  isManagerView!: boolean;
  showApplyLeaveButton: boolean = true;
  showToolbar = true;

  constructor(private router: Router, private leaveService: LeaveService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    this.router.events.subscribe(() => {
      const currentUrl = this.router.url;
      if (currentUrl.startsWith('/approvals/leave-history;id=')) {
        this.showToolbar = false;
      } else {
        this.showToolbar = true;
      }
    });

    if (sessionStorage.getItem(AppConstant.SHOW_EMPLOYEE_LEAVE) === 'true') {
      this.managerEmployeeLeaveData();
      this.showApplyLeaveButton = false;
    }
    else {
      this.getApplyLeaveHistory();
    }
    const currentUrl = this.router.url;

    if (currentUrl.startsWith('/approvals/leave-history;id=')) {
      this.id = this.route.snapshot.params['id'];
      this.employeeId = this.id;
    } else {
      this.employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
    }
    this.getLeaveData();
    this.leaveColDef = {
      sortable: true,
      resizable: true,
    };
  }

  goToDashboard() {
    if (sessionStorage.getItem(AppConstant.MANAGER_SIDENAV) === AppConstant.EMPLOYEE_ROLE) {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.DASHBOARD])
      sessionStorage.setItem(AppConstant.SHOW_EMPLOYEE_LEAVE, 'false');
      sessionStorage.removeItem(AppConstant.IS_MANAGER);

    }
    else {
      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.DASHBOARD])
    }
  }

  getLeaveData() {
    this.leaveService.getLeaveData(this.companyId, this.employeeId).subscribe((data: any) => {
      this.balanceLeave = data;
      this.calculateTotalLeaveCount(); // Calculate the total leave count
    })
  }


  calculateTotalLeaveCount() {
    this.totalLeaveCount = this.balanceLeave.reduce((total, leaveItem) => total + leaveItem.leaveCount, 0);
  }

  getApplyLeaveHistory() {
    this.leaveService.getApplyLeaveHistory(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe((data: any) => {
      this.leaveHistoryData = data.map((row: { id: { toString: () => string; }; startDate: string; endDate: string; createdDate: string; }) => ({
        ...row,
        id: "LR" + row.id.toString().padStart(3, "0"),
        startDate: this.formatDate(row.startDate),
        endDate: this.formatDate(row.endDate),
        createdDate: this.formatDate(row.createdDate)
      }));

      this.leaveHistoryData.sort((a, b) => {
        const idA = parseInt(a.id.slice(2));
        const idB = parseInt(b.id.slice(2));
        return idB - idA;
      });

      this.leaveHistory = this.leaveHistoryData;
    });
  }

  managerEmployeeLeaveData() {
    this.leaveService.getApplyLeaveHistory(sessionStorage.getItem(AppConstant.COMPANY_ID), this.id).subscribe((data: any) => {
      this.leaveHistoryData = data.map((row: { id: { toString: () => string; }; startDate: string; endDate: string; createdDate: string; }) => ({
        ...row,
        id: "LR" + row.id.toString().padStart(3, "0"),
        startDate: this.formatDate(row.startDate),
        endDate: this.formatDate(row.endDate),
        createdDate: this.formatDate(row.createdDate)
      }));

      this.leaveHistory = this.leaveHistoryData;
    });
  }
  private formatDate(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  applyLeave() {
    this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.APPLY_LEAVE]);
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  goBack() {
    (sessionStorage.getItem('login') === 'admin')
      ? this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE])
      : this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
  }

}
