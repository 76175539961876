import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(private http: HttpClient) { }
  probationPeriod(probationperiod: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.PROBATION_PERIOD, probationperiod).pipe(
      map((res) => { return res; })
    );
  }

  LeaveCycle(leaveCycle: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.LEAVE_CYCLE, leaveCycle).pipe(
      map((res) => { return res; })
    );
  }


  createLOPReport(lopReport: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.LOP, lopReport).pipe(
      map((res) => { return res; })
    );
  }


  attendanceConfiguration(attendanceConfiguration: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ATTENDANCE_CONFIGURATION, attendanceConfiguration).pipe(
      map((res) => { return res; })
    );
  }


  getAllSettings(companyId: any) {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_SETTING_DATA + companyId).pipe(
      map(res => { return res })
    )
  }

  saveAppraisal(dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.POST_APPRAISAL_CONFIGURATION, dto).pipe(
      map((res) => { return res; })
    );
  }

  getAppraisal(companyId: any) {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_SETTING_DATA + companyId).pipe(
      map(res => { return res })
    )
  }

  saveNoticePeriod(dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.POST_NOTICE_PERIOD_CONFIGURATION, dto).pipe(
      map((res) => { return res; })
    );
  }

  saveEmployeeIdConfiguration(dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.SAVE_EMPLOYEE_ID_CONFIGURATION, dto).pipe(
      map((res: any) => {
        return res;
      })
    )

  }


}
