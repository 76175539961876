<!-- Admin view start -->
<div class="showPayRun" *ngIf="showPayRun">
  <div class="hr-container" *ngIf="!isEmployee">
    <section class="main-content">
      <div class="grid-container-basic">
        <h4 class="grid-container-h">{{'welcome'| translate}} <b>{{ adminName }}</b></h4>
        <mat-divider></mat-divider>
      </div>
      <div class="dashboard_grid_container">
        <div class="dashboard-left-grid">
          <div class="grid-container-basic">
            <h4>{{'process_pay_run'| translate}}</h4>
          </div>
          <div class="grid-container">
            <mat-card class="dashboard-card">
              <mat-card-content class="dashboard-card-content">
                <div>
                  <div>
                    <h5>{{'employee_net_pay' | translate}}</h5>
                    <h4><i class="fa fa-rupee"></i>{{employeesNetPay | number:'1.2-2'}}</h4>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card class="dashboard-card">
              <mat-card-content class="dashboard-card-content">
                <div>
                  <div>
                    <h5>{{'payment_date' | translate}}</h5>
                    <h4>{{paymentDate}}</h4>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
            <mat-card class="dashboard-card">
              <mat-card-content class="dashboard-card-content">
                <div>
                  <div>
                    <h5>{{'no_of_employee' | translate}}</h5>
                    <h4>{{noOfEmployee }}</h4>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
            <div class="dashboard-card">
              <div class="pay-button-container">
                <button class="button" color="primary" (click)="goToPayRun()">{{'view_details_and_pay'|
                  translate}}</button>
              </div>
            </div>
          </div>
          <div class="grid-container-basic">
            <h4>{{'payroll_cost_summary' | translate}}</h4>
          </div>
          <div class="grid-container2">
            <mat-card class="dashboard-card">
              <mat-card-content class="dashboard-card-content">
                <div><app-bar-chart></app-bar-chart></div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
        <div class="dashboard-right-grid">
          <div class="to-do-div" *ngIf="showTasks">
            <div id="to-do-task">
              <div>
                <h4><strong>{{'to_do_task' | translate}}</strong></h4>
              </div>
              <div class="refresh" (click)="refresh()">
                <mat-icon matTooltip="Refresh" [ngClass]="{ 'spin': taskInProgress }">autorenew</mat-icon>
              </div>
            </div>
            <div *ngFor="let message of messagesList">
              <div class="task">
                <div>
                  {{message.message}}
                  <span>{{message.status}}</span>
                </div>
                <span class="close-button" (click)="deleteMessage(message.id)">
                  <mat-icon>cancel</mat-icon>
                </span>
              </div>
              <mat-divider></mat-divider>
            </div>
          </div>
          <app-events></app-events>
        </div>
      </div>
      <!-- <div class="grid-container-basic">
        <h4>{{'deduction_summary' | translate}}</h4>
      </div>
      <div class="grid-container1">
        <mat-card class="dashboard-card">
          <mat-card-content class="dashboard-card-content">
            <div>
              <div>
                <mat-icon>local_florist</mat-icon>
                <h5>{{'epf' | translate}}</h5>
                <h3><i class="fa fa-rupee"></i>{{epf | number:'1.2-2'}}</h3>
                <h6><a>{{'view_details'| translate}}</a></h6>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="dashboard-card">
          <mat-card-content class="dashboard-card-content">
            <div>
              <div>
                <mat-icon>games</mat-icon>
                <h5>{{'esi'| translate}}</h5>
                <h3><i class="fa fa-rupee"></i>{{esi | number:'1.2-2'}}</h3>
                <h6><a>{{'view_details'| translate}}</a></h6>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="dashboard-card">
          <mat-card-content class="dashboard-card-content">
            <div>
              <div>
                <mat-icon>local_library</mat-icon>
                <h5>{{'tds_deduction'| translate}}</h5>
                <h3><i class="fa fa-rupee"></i>{{taxDeduction | number:'1.2-2'}}</h3>
                <h6><a>{{'view_details'| translate}}</a></h6>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="dashboard-card">
          <mat-card-header>
          </mat-card-header>
          <mat-card-content class="dashboard-card-content">
            <div>
              <div>
                <mat-icon>account_circle</mat-icon>
                <h5>{{'active_employee' | translate}}</h5>
                <h2>{{activeEmployees}}</h2>
                <h6><a routerLink='/{{admin}}/{{activeEmployee}}'>{{'view_employees' |translate}}</a></h6>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div> -->

    </section>
  </div>
</div>
<!-- Admin view end -->

<!-- Employee view start -->
<div *ngIf="isEmployee">
  <section class="main-section">
    <div class="grid-container-basic">
      <h4>{{'welcome'| translate}} <b>{{employeeName}}</b></h4>
    </div>
    <mat-divider></mat-divider>
  </section>

  <div class="dashboard_grid_container">
    <div class="dashboard-left-grid">
      <!-- <div class="emp-container"> -->
      <section class="main-content">
        <div class="grid-container2">
          <mat-card class="dashboard-card">
            <mat-card-header>
              <h3 class="your-payslip">{{'your_payslips' | translate}}</h3>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content class="dashboard-card-content">
              <div><app-pie-chart></app-pie-chart></div>
            </mat-card-content>
          </mat-card>
        </div>
      </section>
      <!-- <aside class="right-sidebar">
    <div class="grid-container2"> -->
      <!-- <mat-card class="dashboard-card"> -->
      <!-- <mat-card-header> -->
      <!-- <h4>{{'reimbursement_summary' | translate}}</h4> -->
      <!-- </mat-card-header> -->
      <!-- <mat-card-content class="dashboard-card-content2"> -->
      <!-- Future used  -->
      <!-- <div><app-reimbursement-summary></app-reimbursement-summary></div> -->
      <!-- </mat-card-content> -->
      <!-- </mat-card> -->
      <!-- </div>
  </aside> -->
      <!-- </div> -->
      <div class="tax-summary-section">
        <section class="main-section">
          <mat-card class="dashboard-card">
            <mat-card-header>
              <h3 class="your-payslip">{{'tax_summary' | translate}}</h3>
            </mat-card-header>
            <mat-divider></mat-divider>

            <mat-card-content class="dashboard-card-content2">
              <div class="division">
                <div class="section small-section">
                  <h5>{{'total_annual_tax'|translate}}</h5>
                  <h4><i class="fa fa-rupee"></i> {{ totalAnnualTax }}</h4>
                </div>
                <div class="section large-section" *ngIf="totalAnnualTax != 0">
                  <div class="progress-container">
                    <mat-progress-bar class="custom-progress-bar" mode="determinate" [value]="progressValue"
                      [color]="progressColor"></mat-progress-bar>
                    <div class="progress-labels">
                      <span class="actual-label">{{'paid'|translate}} {{ paid }}</span>
                      <span class="remaining-label">{{'remaining' | translate }}{{ remainingValue }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <h6 *ngIf="totalAnnualTax != 0"><i class="fa fa-info-circle"></i>{{'tax_amount'|translate}} <i
                  class="fa fa-rupee"></i>{{'next_month_amount'|translate}}</h6>
            </mat-card-content>
          </mat-card>
        </section>
      </div>
    </div>
    <div class="dashboard-right-grid">
      <app-events></app-events>
    </div>
  </div>
</div>
<!-- employee view end -->