import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { AppraisalServiceService } from 'src/app/services/apprisal-service/appraisal-service.service';
@Component({
  selector: 'app-employee-basic-goals',
  templateUrl: './employee-basic-goals.component.html',
  styleUrls: ['./employee-basic-goals.component.scss']
})
export class EmployeeBasicGoalsComponent {
  basicGoalForm: any = FormGroup;
  @Input() goalId!: string;
  goalName: string[] = [];
  Description: string[] = [];
  Status: string[] = [];
  Category: string[] = [];
  StartDate: string[] = [];
  DueDate: string[] = [];
  Weight: string[] = [];
  LastUpdate: string[] = [];
  data: any;
  emplpoyeeGoal: any;
  employeeId= sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  selectedGoalId:any;
  dataArray: { key: string, value: any }[] = [];

  constructor(private appraisalService: AppraisalServiceService, private router: ActivatedRoute, private formbuilder: FormBuilder,private route:Router) { }

  ngOnInit() {

    this.initializeForm();
     this.selectedGoalId = this.router.snapshot.paramMap.get('id');
    this.getEmployeeGoals();
  }

  initializeForm() {
    this.basicGoalForm = this.formbuilder.group({
      id: [''],
      goalName: [''],
      libraryName: [''],
      goalType: [''],
      description: [''],
      category: [''],
      status: [''],
      satrtDate: [''],
      targetDate: [''],
      weigths: [''],
      successCriteria: ['']
    });
  }

  getEmployeeGoals() {
    this.appraisalService.getEmployeeGoalDetails(this.companyId,this.employeeId,this.selectedGoalId).subscribe(
      (data: any) => {
        this.data = data.data;
        this.prepareDataArray();

      }
    );
  }

  
  prepareDataArray() {
    this.dataArray = [
      { key: 'goal_name', value: this.data.goalName },
      { key: 'libary_goal', value: this.data.libraryName },
      { key: 'goal_category', value: this.data.category },
      { key: 'goal_type', value: this.data.goalType },
      { key: 'goal_status', value: this.data.status },
      { key: 'weight', value: this.data.weights },
      { key: 'view_start_date', value: this.data.startDate },
      { key: 'target_completion_date', value: this.data.targetDate },
      { key: 'success_criteria', value: this.data.successCriteria },
      { key: 'goal_description', value: this.data.description }
    ];
  }

  back(){
    this.route.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.GOAL_PLAN])
  }
}
