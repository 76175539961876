import { Component, OnInit } from '@angular/core';
import { state, style, trigger } from '@angular/animations';
import { CountOfData, YetToReceiveDto } from 'src/app/model/asset-management\'/asset-management.model';
import { DatePipe } from '@angular/common';
import { AssetManagementDashboardService } from 'src/app/services/asset-management/asset-management-dashboard/asset-management-dashboard.service';

@Component({
  selector: 'app-asset-management-dashboard',
  templateUrl: './asset-management-dashboard.component.html',
  styleUrls: ['./asset-management-dashboard.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
    ]),
  ],
  providers: [DatePipe]

})
export class AssetManagementDashboardComponent implements OnInit {
  cardData!: CountOfData;
  panelOpenState = false;
  yetToReceiveTable: YetToReceiveDto[] = [];
  assetRequest: YetToReceiveDto[] = [];
  columnsToDisplay = ['employeeName', 'assetName', 'assetCode', 'submittedDate'];
  columnsToRequestAssets = ['employeeId', 'employeeName', 'requestNo', 'assetCode'];

  constructor(private dashboardService: AssetManagementDashboardService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.getCountOfData();
    this.getYetToReceiveTableData();
    this.getAssetRequest();
  }

  getCountOfData() {
    this.dashboardService.getCountOfData().subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.cardData = data.data;
        }
      });
  }

  getYetToReceiveTableData() {
    this.dashboardService.getYetToReceiveTableData().subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.yetToReceiveTable = data.data.map((res: any) => {
            return {
              assetCode: res.assetsCode,
              assetName: res.assetName,
              submittedDate: this.datePipe.transform(res.submittedDate, "dd/MM/yyyy"),
              employeeName: res.employeeName
            }
          })
        }
      });
  }

  getAssetRequest() {
    this.dashboardService.getAssetRequest().subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.assetRequest = data.data.map((res: any) => {
            return {
              employeeId: res.employeeModel.id,
              employeeName: res.employeeName,
              requestNo: res.id,
              assetCode: res.assetCode,
            }
          })
        }
      });
  }

  getTittleCase(s: string) {
    const result = s.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

}
