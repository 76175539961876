<div class="container" *ngIf="hrLogin && !hrFNFShow || isManagerLogin">

    <div class="setting__main_container">
        <span matTooltip="{{'go_back' | translate}}" (click)="goToDashboard()">
            <mat-icon svgIcon="arrow-back-double" class="rewind"></mat-icon>
        </span>
        <div class="setting__heading_container">
            <h3>{{ 'resignation_details' | translate }}</h3>
        </div>
    </div>
    <hr>
    <div class="main-container">

        <div class="resignation-table">
            <table class="table">
                <div>
                    <tr class="row">
                        <th>{{'applied_employee_name'|translate}}</th>
                        <th>{{'submission_date'| translate}}</th>
                        <th>{{'status'|translate}}</th>
                    </tr>
                    <tr class="row">
                        <td>{{ employeeName }}</td>
                        <td>{{ resignationDetails.submissionDate }}</td>
                        <td
                            [ngStyle]="{'color': (resignationDetails.status === 'Rejected') ? 'red' : (resignationDetails.status=== 'Pending') ? 'orange' : (resignationDetails.status === 'Approved') ? 'green' : ''}">
                            {{resignationDetails.status}}
                        </td>
                    </tr>
                </div>
                <div>
                    <tr class="row">
                        <th>{{'required_lwd'|translate}}</th>
                        <th>{{'tentative_lwd'|translate}}</th>
                        <th>{{'shortfall_days'|translate}}</th>
                    </tr>
                    <tr class="row">
                        <td>{{resignationDetails.lastWorkingDay}}</td>
                        <td>{{resignationDetails.tentativeLWD}}</td>
                        <td>{{resignationDetails.shortFallDays}}</td>
                    </tr>
                </div>
                <div>
                    <tr class="row">
                        <th>{{'alternate_mobile_number'|translate}}</th>
                        <th>{{'applying_to'|translate}}</th>
                        <th>{{'alternate_email_address'|translate}}</th>
                    </tr>

                    <tr class="row">
                        <td>{{resignationDetails.alternateMobileNumber}}</td>
                        <td>{{resignationDetails.managerName}}</td>
                        <td>{{resignationDetails.alternateEmail}}</td>
                    </tr>
                </div>
                <div class="reason">
                    <tr>
                        <th class="key">{{'reason'|translate}}</th>
                    </tr>
                    <tr>
                        <td class="value">{{resignationDetails.reason}}</td>
                    </tr>
                </div>
            </table>
            <div class="timeline">
                <div class="contents">
                    <div class="timeline-title"><b>{{'timeline'|translate}}</b></div>
                    <mat-divider class="timeline-divider"></mat-divider>
                    <div class="event">
                        <p>{{employeeName}}</p>
                        <p>{{resignationDetails.submissionDate}}</p>
                        <p><span class="approved">{{'status'|translate}} </span><span class="timeline-content"
                                [ngStyle]="{'color': (resignationDetails.status === 'Rejected') ? 'red' : (resignationDetails.status=== 'Pending') ? 'orange' : (resignationDetails.status === 'Approved') ? 'green' : ''}">({{resignationDetails.status}})</span>
                        </p>
                        <div class="arrow"><mat-icon>arrow_downward</mat-icon></div>
                    </div>
                    <div class="event">
                        <p>{{resignationDetails.managerName}}</p>
                        <p>{{resignationDetails.submissionDate}}</p>
                        <p><span class="approved">{{'status'|translate}} </span><span class="timeline-content"
                                [ngStyle]="{'color': (resignationDetails.managerStatus === 'Rejected') ? 'red' : (resignationDetails.managerStatus=== 'Pending') ? 'orange' : (resignationDetails.managerStatus === 'Approved') ? 'green' : ''}">({{resignationDetails.managerStatus}})</span>
                        </p>
                        <div class="arrow"><mat-icon>arrow_downward</mat-icon></div>
                    </div>
                    <div class="event">
                        <p>{{resignationDetails.hrName}}</p>
                        <p>{{resignationDetails.submissionDate}}</p>
                        <p><span class="approved">{{'status'|translate}} </span><span class="timeline-content"
                                [ngStyle]="{'color': (resignationDetails.hrStatus === 'Rejected') ? 'red' : (resignationDetails.hrStatus=== 'Pending') ? 'orange' : (resignationDetails.hrStatus === 'Approved') ? 'green' : ''}">({{resignationDetails.hrStatus}})</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr class="dividers">
    <div class="additional-details">
        <div *ngIf="isHr">
            <span class="add-details">{{'add_details'|translate}}</span>

            <form [formGroup]="employeeResignationForm">

                <div class="hr-remark">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{'remark_for_employee'|translate}}</mat-label>
                        <input matInput type="text" class="input" formControlName="managerRemarkOfEmployee"
                            (keypress)="validateCharacter($event)" />
                    </mat-form-field>
                   
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{'remark_for_review'|translate}}</mat-label>
                        <input matInput type="text" formControlName="managerRemarkForReview" class="input"
                            (keypress)="validateCharacter($event)"/>
                    </mat-form-field>
                  

                </div>

                <div class="hr-remark" *ngIf="isHr && !isHrApproved">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{'last_working_day'|translate}}</mat-label>
                        <input matInput type="text" class="input" formControlName="lastWorkingDay" matInput
                            (keypress)="$event.preventDefault()" [matDatepicker]="picker" [min]="minDate" required
                            (dateInput)="onLastWorkingDateChange($event)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                    <mat-error class="error"
                        *ngIf="formSubmitted && employeeResignationForm.get('lastWorkingDay')?.hasError('required')">
                        {{ 'last_working_day_required' | translate }}
                    </mat-error>

                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{'final_settlement_date'|translate}}</mat-label>
                        <input matInput type="text" class="input" formControlName="finalSettlementDate" matInput
                            [matDatepicker]="picker1" [min]="minFinalSettlementDate" [max]="maxFinalSettlementDate"
                            (keypress)="$event.preventDefault()" required>
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                    <mat-error class="error"
                        *ngIf="formSubmitted && employeeResignationForm.get('finalSettlementDate')?.hasError('required')">
                        {{ 'final_settlement_date_ts required' | translate }}
                    </mat-error>

                </div>
            </form>
            <div>
                <button class="button-right" (click)="approved()" type="submit"
                    color="primary">{{'approved'|translate}}</button>
                <button class="button-right reject" (click)="reject()" type="submit"
                    color="primary">{{'reject'|translate}}</button>
            </div>

        </div>

        <div *ngIf="isManager">
            <span class="add-details">{{'add_details'|translate}}</span>

            <form [formGroup]="employeeResignationForm">
                <div class="remark-employee">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{'remark_for_employee'|translate}}</mat-label>
                        <input matInput type="text" class="input" formControlName="managerRemarkOfEmployee" 
                            (keypress)="validateCharacter($event)" />
                    </mat-form-field>
                    <mat-error class="error"
                        *ngIf="formSubmitted && employeeResignationForm.get('managerRemarkOfEmployee')?.hasError('required')">
                        {{ 'please_enter_remark_for_employee' | translate }}
                    </mat-error>
                </div>
                <div class="remark">
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{'remark_for_review'|translate}}</mat-label>
                        <input matInput type="text" formControlName="managerRemarkForReview" class="input" 
                            (keypress)="validateCharacter($event)" />
                    </mat-form-field>
                    <mat-error class="error"
                        *ngIf="formSubmitted && employeeResignationForm.get('managerRemarkForReview')?.hasError('required')">
                        {{ 'enter_remark_for_review' | translate }}
                    </mat-error>
                </div>
            </form>
            <div class="buttons">
                <button class="button-right" (click)="approved()" type="submit"
                    color="primary">{{'approved'|translate}}</button>
                <button class="button-right reject" (click)="reject()" type="submit"
                    color="primary">{{'reject'|translate}}</button>
            </div>
        </div>
    </div>
</div>
<!-- //Admin -->
<div *ngIf="hrFNFShow && hrLogin" class="expansion">
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h3>{{ 'resignation_details' | translate }}</h3>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="expansion-main-card">
                <div class="main-card">
                    <div class="main-content">
                        <table class="view-details">
                            <tr>
                                <th>{{'applied_employee_name'|translate}}</th>
                                <th>{{'designation'| translate}}</th>
                                <th>{{'date_of_joining'|translate}}</th>
                            </tr>
                            <tr>
                                <td>{{ employeeName }}</td>
                                <td>{{ resignationDetails.designation}}</td>
                                <td>{{resignationDetails.DOJ}}</td>
                            </tr>

                            <tr>
                                <th>{{'submission_date'| translate}}</th>
                                <th>{{'applying_to'|translate}}</th>
                                <th>{{'status'|translate}}</th>
                            </tr>
                            <tr>
                                <td>{{ resignationDetails.submissionDate }}</td>
                                <td>{{resignationDetails.managerName}}</td>

                                <td
                                    [ngStyle]="{'color': (resignationDetails.status === 'Rejected') ? 'red' : (resignationDetails.status=== 'Pending') ? 'orange' : (resignationDetails.status === 'Approved') ? 'green' : ''}">
                                    {{resignationDetails.status}}
                                </td>
                            </tr>
                            <tr>
                                <th>{{'required_lwd'|translate}}</th>
                                <th>{{'tentative_lwd'|translate}}</th>
                                <th>{{'shortfall_days'|translate}}</th>
                            </tr>
                            <tr>
                                <td>{{resignationDetails.lastWorkingDay}}</td>
                                <td>{{resignationDetails.tentativeLWD}}</td>
                                <td>2</td>
                            </tr>
                            <tr>
                                <th>{{'alternate_mobile_number'|translate}}</th>
                                <th>{{'applying_to'|translate}}</th>
                                <th>{{'alternate_email_address'|translate}}</th>
                            </tr>
                            <tr>
                                <td>{{resignationDetails.alternateMobileNumber}}</td>
                                <td>{{resignationDetails.managerName}}</td>
                                <td>{{resignationDetails.alternateEmail}}</td>
                            </tr>
                        </table>
                    </div>

                    <div class="expansion-additional-content">
                        <div class="expansion-timeline-flow">
                            <span class="expansion-timeline"><b>{{'timeline'|translate}}</b></span><br>
                            <span class="timeline-content">{{employeeName}}</span><br>
                            <span class="timeline-content">{{resignationDetails.submissionDate}}</span><br>
                            <span class="timeline-content">{{'status'|translate}}</span><span class="timeline-content"
                                [ngStyle]="{'color': (resignationDetails.status === 'Rejected') ? 'red' : (resignationDetails.status=== 'Pending') ? 'orange' : (resignationDetails.status === 'Approved') ? 'green' : ''}">({{resignationDetails.status}})
                            </span>
                            <mat-icon class="expansion-bottom-arrow">arrow_downward</mat-icon>
                            <span class="submitted-to">{{resignationDetails.managerName}}</span><br>
                            <span class="timeline-content">{{resignationDetails.submissionDate}}</span><br>
                            <span class="timeline-content">{{'status'|translate}}</span><span class="timeline-content"
                                [ngStyle]="{'color': (resignationDetails.managerStatus === 'Rejected') ? 'red' : (resignationDetails.managerStatus=== 'Pending') ? 'orange' : (resignationDetails.managerStatus === 'Approved') ? 'green' : ''}">({{resignationDetails.managerStatus}})
                            </span>


                            <mat-icon class="expansion-top-arrow">arrow_downward</mat-icon>
                            <span class="submitted-to">{{resignationDetails.hrName}}</span><br>
                            <span class="timeline-content">{{resignationDetails.submissionDate}}</span><br>
                            <span class="timeline-content">{{'status'|translate}}</span><span class="timeline-content"
                                [ngStyle]="{'color': (resignationDetails.hrStatus === 'Rejected') ? 'red' : (resignationDetails.hrStatus=== 'Pending') ? 'orange' : (resignationDetails.hrStatus === 'Approved') ? 'green' : ''}">({{resignationDetails.hrStatus}})
                            </span>
                        </div>
                    </div>

                    <div class="additional-details">
                        <div *ngIf="isHr">
                            <mat-divider class="add-details-divider"></mat-divider>

                            <span class="add-details">{{'add_details'|translate}}</span>

                            <form [formGroup]="employeeResignationForm">

                                <div class="hr-remark">
                                    <mat-form-field appearance="outline" class="full-width">
                                        <mat-label>{{'remark_for_employee'|translate}}</mat-label>
                                        <input matInput type="text" class="input"
                                            formControlName="managerRemarkOfEmployee"
                                            (keypress)="validateCharacterNumberAndSpace($event)" />
                                    </mat-form-field>
                                    <mat-error class="error"
                                        *ngIf="formSubmitted && employeeResignationForm.get('managerRemarkOfEmployee')?.hasError('required')">
                                        {{ 'please_enter_remark_for_employee' | translate }}
                                    </mat-error>

                                    <mat-form-field appearance="outline" class="full-width">
                                        <mat-label>{{'remark_for_review'|translate}}</mat-label>
                                        <input matInput type="text" formControlName="managerRemarkForReview"
                                            class="input" 
                                            (keypress)="validateCharacterNumberAndSpace($event)" />
                                    </mat-form-field>
                                    <mat-error class="error"
                                        *ngIf="formSubmitted && employeeResignationForm.get('managerRemarkForReview')?.hasError('required')">
                                        {{ 'enter_remark_for_review' | translate }}
                                    </mat-error>

                                </div>

                                <div class="hr-remark" *ngIf="isHr && !isHrApproved">
                                    <mat-form-field appearance="outline" class="full-width">
                                        <mat-label>{{'last_working_day'|translate}}</mat-label>
                                        <input matInput type="text" class="input" formControlName="lastWorkingDay"
                                            matInput [matDatepicker]="picker" [min]="minDate"
                                            (keypress)="$event.preventDefault()" required>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                    <mat-error class="error"
                                        *ngIf="formSubmitted && employeeResignationForm.get('lastWorkingDay')?.hasError('required')">
                                        {{ 'last_working_day_required' | translate }}
                                    </mat-error>

                                    <mat-form-field appearance="outline" class="full-width">
                                        <mat-label>{{'final_settlement_date'|translate}}</mat-label>
                                        <input matInput type="text" class="input" formControlName="finalSettlementDate"
                                            matInput [matDatepicker]="picker1" [min]="minDate"
                                            (keypress)="$event.preventDefault()" required>
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                    <mat-error class="error"
                                        *ngIf="formSubmitted && employeeResignationForm.get('finalSettlementDate')?.hasError('required')">
                                        {{ 'final_settlement_date_ts required' | translate }}
                                    </mat-error>

                                </div>
                            </form>
                            <div>
                                <button class="button-right" (click)="approved()" type="submit"
                                    color="primary">{{'approved'|translate}}</button>
                                <button class="button-right reject" (click)="reject()" type="submit"
                                    color="primary">{{'reject'|translate}}</button>
                            </div>

                        </div>

                        <div *ngIf="isHr">
                            <mat-divider class="add-details-divider"></mat-divider>

                            <span class="add-details">{{'add_details'|translate}}</span>

                            <form [formGroup]="employeeResignationForm">

                                <div class="hr-remark">
                                    <mat-form-field appearance="outline" class="full-width">
                                        <mat-label>{{'remark_for_employee'|translate}}</mat-label>
                                        <input matInput type="text" class="input"
                                            formControlName="managerRemarkOfEmployee" 
                                            (keypress)="validateCharacterNumberAndSpace($event)" />
                                    </mat-form-field>
                                    <mat-error class="error"
                                        *ngIf="formSubmitted && employeeResignationForm.get('managerRemarkOfEmployee')?.hasError('required')">
                                        {{ 'please_enter_remark_for_employee' | translate }}
                                    </mat-error>

                                    <mat-form-field appearance="outline" class="full-width">
                                        <mat-label>{{'remark_for_review'|translate}}</mat-label>
                                        <input matInput type="text" formControlName="managerRemarkForReview"
                                            class="input" 
                                            (keypress)="validateCharacterNumberAndSpace($event)" />
                                    </mat-form-field>
                                    <mat-error class="error"
                                        *ngIf="formSubmitted && employeeResignationForm.get('managerRemarkForReview')?.hasError('required')">
                                        {{ 'enter_remark_for_review' | translate }}
                                    </mat-error>

                                </div>

                                <div class="hr-remark" *ngIf="isHr && !isHrApproved">
                                    <mat-form-field appearance="outline" class="full-width">
                                        <mat-label>{{'last_working_day'|translate}}</mat-label>
                                        <input matInput type="text" class="input" formControlName="lastWorkingDay"
                                            matInput [matDatepicker]="picker" [min]="minDate"
                                            (keypress)="$event.preventDefault()" required>
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                    <mat-error class="error"
                                        *ngIf="formSubmitted && employeeResignationForm.get('lastWorkingDay')?.hasError('required')">
                                        {{ 'last_working_day_required' | translate }}
                                    </mat-error>

                                    <mat-form-field appearance="outline" class="full-width">
                                        <mat-label>{{'final_settlement_date'|translate}}</mat-label>
                                        <input matInput type="text" class="input" formControlName="finalSettlementDate"
                                            matInput [matDatepicker]="picker1" [min]="minDate"
                                            (keypress)="$event.preventDefault()" required>
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                    <mat-error class="error"
                                        *ngIf="formSubmitted && employeeResignationForm.get('finalSettlementDate')?.hasError('required')">
                                        {{ 'final_settlement_date_ts required' | translate }}
                                    </mat-error>

                                </div>
                            </form>
                            <div>
                                <button class="button-right" (click)="approved()" type="submit"
                                    color="primary">{{'approved'|translate}}</button>
                                <button class="button-right reject" (click)="reject()" type="submit"
                                    color="primary">{{'reject'|translate}}</button>
                            </div>

                        </div>

                        <div *ngIf="isManager">
                            <mat-divider class="add-details-divider"></mat-divider>

                            <span class="add-details">{{'add_details'|translate}}</span>

                            <form [formGroup]="employeeResignationForm">
                                <div class="remark-employee">
                                    <mat-form-field appearance="outline" class="full-width">
                                        <mat-label>{{'remark_for_employee'|translate}}</mat-label>
                                        <input matInput type="text" class="input"
                                            formControlName="managerRemarkOfEmployee"
                                            (keypress)="validateCharacterNumberAndSpace($event)"/>
                                    </mat-form-field>
                                    <mat-error class="error"
                                        *ngIf="formSubmitted && employeeResignationForm.get('managerRemarkOfEmployee')?.hasError('required')">
                                        {{ 'please_enter_remark_for_employee' | translate }}
                                    </mat-error>
                                </div>
                                <div class="remark">
                                    <mat-form-field appearance="outline" class="full-width">
                                        <mat-label>{{'remark_for_review'|translate}}</mat-label>
                                        <input matInput type="text" formControlName="managerRemarkForReview"
                                            class="input"
                                            (keypress)="validateCharacterNumberAndSpace($event)" />
                                    </mat-form-field>
                                    <mat-error class="error"
                                        *ngIf="formSubmitted && employeeResignationForm.get('managerRemarkForReview')?.hasError('required')">
                                        {{ 'enter_remark_for_review' | translate }}
                                    </mat-error>
                                </div>
                            </form>
                            <div>
                                <button class="button-right" (click)="approved()" type="submit"
                                    color="primary">{{'approve'|translate}}</button>
                                <button class="button-right reject" (click)="reject()" type="submit"
                                    color="primary">{{'reject'|translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>

</div>
<div *ngIf="hrFNFShow && hrLogin">
    <app-final-settlement></app-final-settlement>
</div>