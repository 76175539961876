<div id="registration_container">
  <mat-card>
    <mat-card-content>
      <h3 class="voucher-heading">{{'create_voucher'|translate}}</h3>
      <form name="createVoucherForm" [formGroup]="createVoucherForm">
        <div class="form-field-gap1">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{'company-name'|translate}}</mat-label>
            <input matInput type="text" class="input" formControlName="companyName" required [maxLength]="50"
              (keypress)="validateCharacterNumberAndSpace($event)" />
            <mat-error *ngIf="createVoucherForm.controls['companyName'].hasError('required')">
              {{ 'company_name_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="createVoucherForm.controls['companyName'].hasError('invalidInput')">
              {{ 'invalid_name_input' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ 'mobile_number' | translate }}</mat-label>
            <input matInput id="mobileNo" (keypress)="validateNumber($event)" formControlName="mobileNo" minlength="10"
              maxlength="10" required />
            <mat-error *ngIf="createVoucherForm.controls['mobileNo'].hasError('required')">
              {{ 'mobile_number_required' | translate }}
            </mat-error>
            <mat-error *ngIf="createVoucherForm.controls['mobileNo'].hasError('invalidMobileNumber')">
              {{ 'invalid_mobile_number' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{'email'|translate}}</mat-label>
            <input matInput type="text" class="input" formControlName="email" required />
            <mat-error *ngIf="createVoucherForm.controls['email'].hasError('required')">
              {{ 'email_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="createVoucherForm.controls['email'].hasError('invalidEmail')">
              {{'invalid_email'|translate}}
            </mat-error>
            <mat-error *ngIf="createVoucherForm.controls['email'].hasError('invalidDomainInput')">
              {{'invalid_domain_email'|translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-field-gap1">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{ 'start_date' | translate }}</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="startDate" [min]="minDate"
              (dateChange)="onStartDateChange($event.value)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="createVoucherForm.get('startDate')?.hasError('required')">
              {{ 'voucher_date_required' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{ 'expiry_date' | translate }}</mat-label>
            <input matInput [matDatepicker]="picker1" formControlName="expiredDate" [min]="minEndDate" [max]="maxEndDate">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
            <mat-error *ngIf="createVoucherForm.get('expiredDate')?.hasError('required')">
              {{ 'expiry_date_required' | translate }}
            </mat-error>
            <mat-error *ngIf="createVoucherForm.hasError('dateLessThan')">
              {{ 'expiry_date_must_be_after_start_date' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
       
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>{{'discount_percent'|translate}}</mat-label>
          <input matInput type="text" class="content-width" formControlName="amount" required [maxLength]="3"
            (keypress)="numberOnly($event)" />
          <mat-error *ngIf="createVoucherForm.controls['amount'].hasError('required')">
            {{ 'amount_is_required' | translate }}
          </mat-error>
          <mat-error *ngIf="createVoucherForm.controls['amount'].hasError('min')">
            {{ 'discount_more_than_0' | translate }}
          </mat-error>
          <mat-error *ngIf="createVoucherForm.get('amount')?.hasError('wrongPercentValue')">
            {{ 'discount_more_than_100' | translate }}
          </mat-error>
        </mat-form-field>
        <button class="cancel-btn cancel" type="reset">{{'cancel'|translate}}</button>
        <button class="button generate" (click)="createVoucher()">{{'generate'|translate}}</button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
<app-loader></app-loader>