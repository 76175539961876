import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { SuperAdminDeclationConfigurationServiceService } from 'src/app/services/super-admin-declaration-configuration-service/super-admin-declation-configuration-service.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent {
  sectionId: any;
  section!: FormGroup;

  constructor(private toastrService: ToastrService, private superAdminDeclarationConfigurationService: SuperAdminDeclationConfigurationServiceService, private dialogRef: MatDialogRef<AddCategoryComponent>, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.sectionId = sessionStorage.getItem(AppConstant.SECTION_ID);
    this.section = this.formBuilder.group({
      'categoryName': ['', Validators.required],
      'categoryLimit': [''],
      'categoryDescription': ['', Validators.required],
    })
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  addSection() {
    if (this.section.valid) {
      const dto = {
        categoryName: this.section.get('categoryName')?.value,
        categoryLimit: this.section.get('categoryLimit')?.value,
        description: this.section.get('categoryDescription')?.value,
        sectionId: this.sectionId,
        createdBy: sessionStorage.getItem(AppConstant.LOGIN_EMAIL),
        lastUpdatedBy: sessionStorage.getItem(AppConstant.LOGIN_EMAIL)
      }
      this.superAdminDeclarationConfigurationService.addCategory(dto).subscribe(
        (data: any) => {
          if (data.status === 200) {
            this.toastrService.clear();
            this.toastrService.success(data.message);
            this.dialogRef.close();
          }
        })
    } else {
      this.section.markAllAsTouched();
    }
  }



  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }
}
