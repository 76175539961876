import { ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { AddSectionPopupComponent } from '../add-section-popup/add-section-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { SuperAdminDeclationConfigurationServiceService } from 'src/app/services/super-admin-declaration-configuration-service/super-admin-declation-configuration-service.service';
import { AppConstant } from 'src/app/constants/app.constants';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-tax-declaration-configuration',
  templateUrl: './tax-declaration-configuration.component.html',
  styleUrls: ['./tax-declaration-configuration.component.scss']
})
export class TaxDeclarationConfigurationComponent implements OnInit {
  sectionData: any[] = [];
  sectionList: any[] = [];
  defaultColDef = {
    flex: 1,
    sortable: true,
    filter: true,
    resizable: true,
  };
  columnDefs = [
    { headerName: 'Section Name', field: 'sectionName' },
    { headerName: 'Section Limit', valueGetter: (params: any) => params.data.sectionLimit == null ? 'Nil' : params.data.sectionLimit },
    { headerName: 'Section Description', field: 'sectionDescription' },
    {
      headerName: 'Action',
      field: "action",
      cellRenderer: this.viewCategoryButton.bind(this),
      width: 230
    }
  ];

  constructor(private dialog: MatDialog, private router: Router, private renderer: Renderer2, private superAdminDeclarationConfigurationService: SuperAdminDeclationConfigurationServiceService, private cdr: ChangeDetectorRef, private toastrService: ToastrService) { }

  ngOnInit(): void {
    this.getAllSectionList();
  }

  openAddSectionPopup(): void {
    const dialogRef = this.dialog.open(AddSectionPopupComponent, {
      width: 'auto',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.getAllSectionList();
    });
  }

  getAllSectionList() {
    this.superAdminDeclarationConfigurationService.getAllSectionList().subscribe(
      (data: any) => {
        this.sectionList = data.data;
      })
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  viewCategoryButton(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      if (icon === 'delete') {
        this.renderer.setStyle(iconElement, 'margin-right', '15px');
      }
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };
    const viewIconClickHandler = () => {
      sessionStorage.setItem(AppConstant.SECTION_ID, params.data.id);
      this.router.navigate([`${AppConstant.ADMIN_SIDEBAR + AppConstant.SEPERATOR + AppConstant.TAX_DECLARATION}/${params.data.id}`]);
    };
    const deleteIconClickHandler = () => {
      if (confirm(AppConstant.DELETE_CONFIRMATION_MASSAGE)) {
        this.deleteSection(params.data.id);
      }
    };
    createButton('visibility', 'green', viewIconClickHandler);
    createButton('delete', 'red', deleteIconClickHandler);
    return cell;
  };

  deleteSection(sectionId: any) {
    this.superAdminDeclarationConfigurationService.deleteSection(sectionId).subscribe((data: any) => {
      if (data.status === 200) {
        this.getAllSectionList();
        this.toastrService.clear();
        this.toastrService.success(data.message)
      } else {
        this.toastrService.clear();
        this.toastrService.error(data.message);
      }
    });
  }
}
