<div>
    <div class="tittle">
        <h3>{{ 'support_request' | translate }}</h3>
        <mat-divider></mat-divider>
    </div>
    <mat-tab-group class="tab-groups" [(selectedIndex)]="selectedTabIndex">
        <mat-tab (click)="viewRequest()" label="{{ 'view_request' | translate }}">

            <div class="viewRequestTable" *ngIf="!createRequest">
                <ag-grid-angular (gridReady)="onGridReady($event)" style="width: auto; height: 27rem;"
                    class="ag-theme-alpine" [rowData]="rowData" [columnDefs]="columnDefs" [rowSelection]="'single'"
                    [gridOptions]="gridOptions" [pagination]="true" [paginationPageSize]="10">
                </ag-grid-angular>
            </div>
        </mat-tab>
        <mat-tab label="{{ 'create_support_request' | translate }}" (click)="createSupportRequest()">
            <div>
                <form [formGroup]="supportForm" class="support-form">
                    <div class="form-field-gap1">
                        <mat-form-field appearance="outline">
                            <mat-label>{{ 'name' | translate }}</mat-label>
                            <input matInput type="text" id="name" formControlName="name" required>
                            <mat-error *ngIf="supportForm.get('name')?.hasError('required')">
                                {{ 'name_required' | translate }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{ 'mobile_number' | translate }}</mat-label>
                            <input matInput id="mobileNumber" (keypress)="validateNumber($event)" minlength="10"
                                maxlength="10" formControlName="mobileNumber" required />
                            <mat-error *ngIf="supportForm.controls['mobileNumber'].hasError('required')">
                                {{ 'mobile_number_required' | translate }}
                            </mat-error>
                            <mat-error *ngIf="supportForm.controls['mobileNumber'].hasError('invalidMobileNumber')">
                                {{ 'invalid_mobile_number' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-field-gap1">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{ 'page_name' | translate }}</mat-label>
                            <mat-select formControlName="pageName" required>
                                <mat-option *ngFor="let Page of PageList" [value]="Page">
                                    {{ Page }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="supportForm.get('pageName')?.hasError('required')">
                                {{ 'page_name_is_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{ 'priority' | translate }}</mat-label>
                            <mat-select formControlName="priority" required>
                                <mat-option *ngFor="let priority of priorityOptions" [value]="priority">
                                    {{ priority }}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="supportForm.get('priority')?.hasError('required')">
                                {{ 'priority_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{ 'short_description' | translate }}</mat-label>
                        <input matInput type="text" id="shortDescription" formControlName="shortDescription"
                            [maxLength]="60" required (keypress)="addressVerification($event)">
                        <mat-error *ngIf="supportForm.get('shortDescription')?.hasError('required')">
                            {{ 'short_description_required' | translate }}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{ 'description' | translate }}</mat-label>
                        <textarea matInput id="description" formControlName="description" rows="5" required
                            (keypress)="addressVerification($event)"></textarea>
                        <mat-error *ngIf="supportForm.get('description')?.hasError('required')">
                            {{ 'description_required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="supportForm.get('description')?.hasError('minlength') && 
                            supportForm.get('description')?.dirty">
                            {{ 'minimum_100_characters_required' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <button class="button" type="submit" (click)="onSubmit()">{{ 'submit' | translate }}</button>
                </form>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<app-loader></app-loader>