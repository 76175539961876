import { Component } from '@angular/core';

@Component({
  selector: 'app-shipping-delivery-policy',
  templateUrl: './shipping-delivery-policy.component.html',
  styleUrls: ['./shipping-delivery-policy.component.scss']
})
export class ShippingDeliveryPolicyComponent {

}
