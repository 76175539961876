import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LeaveData } from 'src/app/model/leave-application/leave-history.model';

@Component({
  selector: 'app-leave-details-popup',
  templateUrl: './leave-details-popup.component.html',
  styleUrls: ['./leave-details-popup.component.scss']
})
export class LeaveDetailsPopupComponent {

  constructor(public dialogRef: MatDialogRef<LeaveDetailsPopupComponent>, @Inject(MAT_DIALOG_DATA) public leaveData: any) {}

  onApprove(): void {
    this.dialogRef.close({ action: 'approve', data: this.leaveData });
  }

  onReject(): void {
    this.dialogRef.close({ action: 'reject', data: this.leaveData });
  }

  onClose(): void {
    this.dialogRef.close();
  }
}