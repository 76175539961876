import { Component, Renderer2 } from '@angular/core';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { PayRunService } from 'src/app/services/pay-run/pay-run.service';
import { AppConstant } from 'src/app/constants/app.constants';
import { MatDialog } from '@angular/material/dialog';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PayRunGroupDto, PayRunGroupList } from 'src/app/model/payrun/payrun.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pay-run-group',
  templateUrl: './pay-run-group.component.html',
  styleUrls: ['./pay-run-group.component.scss']
})
export class PayRunGroupComponent {
  selectAllUnselectedChecked = false;
  selectAllSelectedChecked = false;
  unselected!: any[];
  selected: any[] = [];
  pendingSelection: { [key: string]: boolean } = {};
  employeeList: any;
  payRun!: FormGroup;
  data: any[] = [];
  filteredIssues: any[] = [];
  createPayRunGroup: boolean = false;
  viewGroup: boolean = false;
  employeeColDef: any;
  groupName!: any;
  payRunGroupList = [
    { headerName: 'GroupId', field: 'id', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: 'payRunGroupName', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: 'totalEmployee', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, tooltipValueGetter: (params: any) => `Pay Run Groups: ${params.value}` },
    { field: 'status', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, valueGetter: (params: any) => params.data.active ? 'Active' : 'Inactive' },
    { field: "action", cellRenderer: this.actionCellRenderer.bind(this) }
  ];
  columnDefs = [
    { headerName: 'Emp Id', field: 'id', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, width: 70, valueGetter: (params: any) => 'EMP00' + params.data.id },
    { field: 'name', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, width: 200 },
    { field: 'groupName', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, width: 150 },
    { field: 'groupId', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, width: 150 },
    { field: "action", cellRenderer: this.actionCellRendererForEmployee.bind(this) }
  ];
  employeeData: any[] = [];
  count: any;

  constructor(private renderer: Renderer2, private payService: PayRunService, public dialog: MatDialog, private employeeService: EmployeeDetailsService, private formBuilder: FormBuilder, private payRunService: PayRunService, private toastrService: ToastrService) {
    this.payRun = this.formBuilder.group({
      'payrunName': ['', [Validators.required]],
    });
  }

  ngOnInit() {
    this.getPayRunGroupList()
    this.getAllEmployee();
    this.updateMainCheckboxes();
    this.setEmployeeColDef();
  }

  createPayRun() {
    this.createPayRunGroup = true;
    this.getAllEmployee();
  }

  updateMainCheckboxes() {
    this.selectAllUnselectedChecked = this.unselected.length > 0 && this.unselected.every(emp => this.pendingSelection[emp.id]);
    this.selectAllSelectedChecked = this.selected.length > 0 && this.selected.every(emp => this.pendingSelection[emp.id]);
  }

  setEmployeeColDef() {
    this.employeeColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
  }

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      if (icon === 'delete') {
        this.renderer.setStyle(iconElement, 'margin-right', '15px');
      }
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };

    const viewPayRunGroupDetails = () => {
      this.groupName = params.data.payRunGroupName
      this.processToPayrun(params.data.payRunGroupName);
    };

    const deletePayRunGroup = () => {
      if (confirm("This group has " + params.data.totalEmployee + " employee. After deleting this group, all employees will be assigned to the default group.")) {
        this.deletGroupByGroupId(params.data.id);
      }
    };

    createButton('visibility', 'green', viewPayRunGroupDetails);

    createButton('delete', 'red', deletePayRunGroup);

    return cell;
  }

  deletGroupByGroupId(groupId: any) {
    this.payRunService.deletGroupByGroupId(groupId).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.getPayRunGroupList();
          this.toastrService.clear();
          this.toastrService.success(data.message);
        } else {
          this.toastrService.clear();
          this.toastrService.error(data.message);
        }
      }
    )
  }

  deleteEmployeeFromGroup(groupId: any) {
    this.payRunService.deleteEmployeeFromGroup(groupId).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.processToPayrun(this.groupName);
          this.getPayRunGroupList();
          this.toastrService.clear();
          this.toastrService.success(data.message);
        } else {
          this.toastrService.clear();
          this.toastrService.error(data.message);
        }
      }
    )
  }

  actionCellRendererForEmployee(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      if (icon === 'delete') {
        this.renderer.setStyle(iconElement, 'margin-right', '15px');
      }
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };

    const deleteEmployeeFromGroup = () => {
      this.deleteEmployeeFromGroup(params.data.id);
    };

    createButton('delete', 'red', deleteEmployeeFromGroup);

    return cell;
  }

  goBack() {
    this.createPayRunGroup = false;
    this.viewGroup = false;
  }

  processToPayrun(groupName: any) {
    let groupNameList: any[] = [];
    groupNameList.push(groupName);
    this.employeeData = [];
    const dto: PayRunGroupList = {
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      groupNameList: groupNameList
    };
    this.employeeService.processToPayrun(dto).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.count = data.data.length;
          const flattenedData = data.data.flat();
          this.employeeData.push(...flattenedData);
          this.employeeData = this.removeDuplicates(this.employeeData);
          if (this.employeeData.length > 0) {
            this.viewGroup = true;
          } else {
            this.toastrService.clear();
            this.toastrService.error("No row to show");
          }
        } else {
          this.toastrService.clear();
          this.toastrService.error(data.message);
        }
      }
    );
  }

  public closePopup(): void {
    this.dialog.closeAll();
  }

  characterNumberAndSpaceAllowed(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

  removeDuplicates(data: any[]): any[] {
    const uniqueIds = new Set();
    return data.filter(item => {
      const isUnique = !uniqueIds.has(item.id);
      if (isUnique) {
        uniqueIds.add(item.id);
      }
      return isUnique;
    });
  }

  public addToSelected(employee?: any): void {
    if (employee) {
      this.pendingSelection[employee.id] = false;
      this.unselected = this.removeContactsFromCollection(this.unselected, [employee]);
      this.selected.push(employee);
      this.pendingSelection = Object.create(null);
      this.selectAllUnselectedChecked = false
    } else {
      const employee = this.getPendingSelectionFromCollection(this.unselected);
      for (const contact of employee) {
        this.pendingSelection[contact.id] = false;
      }
      this.unselected = this.removeContactsFromCollection(this.unselected, employee);
      this.selected = this.selected.concat(employee);
      this.selectAllUnselectedChecked = false
    }
  }

  selectAllUnselected(event: any): void {
    const isChecked = event.target.checked;
    for (let employee of this.unselected) {
      this.pendingSelection[employee.id] = isChecked;
    }
    this.updateMainCheckboxes();
  }

  selectAllSelected(event: any): void {
    const isChecked = event.target.checked;
    for (let employee of this.selected) {
      this.pendingSelection[employee.id] = isChecked;
    }
    this.updateMainCheckboxes();
  }

  togglePendingSelection(employee: any, event: any): void {
    this.pendingSelection[employee.id] = event.target.checked;
    this.updateMainCheckboxes();
  }

  public removeFromSelectedContacts(employee?: any): void {
    if (employee) {
      this.pendingSelection[employee.id] = false;
      this.selected = this.removeContactsFromCollection(this.selected, [employee]);
      this.unselected.push(employee);
      this.pendingSelection = Object.create(null);
      this.selectAllSelectedChecked = false
    } else {
      const employeeList = this.getPendingSelectionFromCollection(this.selected);
      for (const employee of employeeList) {
        this.pendingSelection[employee.id] = false;
      }
      this.selected = this.removeContactsFromCollection(this.selected, employeeList);
      this.unselected = this.unselected.concat(employeeList);
      this.pendingSelection = Object.create(null);
      this.selectAllSelectedChecked = false
    }
  }

  private getPendingSelectionFromCollection(collection: any): any {
    var selectionFromCollection = collection.filter(
      (employee: any) => {
        return (employee.id in this.pendingSelection);
      }
    );
    return (selectionFromCollection);
  }

  private removeContactsFromCollection(collection: any, contactsToRemove: any): any {
    var collectionWithoutContacts = collection.filter(
      (employee: any) => {
        return (!contactsToRemove.includes(employee));
      });
    return (collectionWithoutContacts);
  }

  getAllEmployee() {
    this.employeeService.getAllEmployeeBasicDetailList(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        this.employeeList = data.data;
        this.unselected = this.employeeList;
      },
    )
  }

  createPayRunApi() {
    if (this.payRun.get('payrunName')?.value !== null && this.payRun.get('payrunName')?.value !== '') {
      if (this.selected.length !== 0) {
        const selectedIds = this.selected.map(employee => employee.id);
        const dto: PayRunGroupDto = {
          companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
          employee: selectedIds,
          payRunGroupName: this.payRun.get('payrunName')?.value
        }
        this.payService.groupByPayRun(dto).subscribe(
          (data: any) => {
            if (data.status === 200) {
              this.payRun.reset();
              this.toastrService.clear();
              this.toastrService.success(data.message);
              this.getPayRunGroupList();
              this.goBack();
            }
            else {
              this.toastrService.clear();
              this.toastrService.error(data.message);
            }
          }
        )
        this.selected = [];
      }
      else {
        this.toastrService.clear();
        this.toastrService.error(AppConstant.ADD_ATLEAST_ONE_EMPLOYEE);
      }
    }
  }

  getPayRunGroupList() {
    this.filteredIssues = [];
    this.data = [];
    this.employeeService.getPayRunGroupList(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (res: any) => {
        if (res.status === 200) {
          this.data = res.data;
        }
      }
    );
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

}

