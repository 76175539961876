export const API_END_POINTS = {
    LOGIN_PAGE: '/login',
    COMPANY_BANK_DETAILS: '/addCompanyBankDetails',
    SENDOTP: '/forgotPasswordOTP',
    REGISTARTION: '/signUp',
    CHECK_EMAIL: '/checkEmailExistOrNot/',
    CHECK_EMPLOYEE_EMAIL: '/checkEmployeeEmailExists/',
    SEND_OTP: '/registrationOTPSend',
    SEND_OTP_PAY_RUN: '/payRunOTPSend/',
    VERIFY_OTP: '/registration-verification',
    VERIFY_PAY_RUN_OTP: '/pay-run-verification',
    DESIGNATION_LIST: '/findAllDesignations/',
    DEPARTMENT_LIST: '/findAllDepartments/',
    UPDATE_DEPARTMENT: '/updateDepartment',
    UPDATE_DESIGNATION: '/updateDesignation',
    ADD_EMPLOYEE: '/employee/addEmployeeSalaryDetails',
    EMPLOYEE_BASIC_DETAILS: '/employee/addEmployeeBasicDetails',
    EMPLOYEE_PERSONAL_DETAILS: '/employee/addEmployeePersonalDetails',
    EMPLOYEE_SALARY: '/employee/addEmployeeSalaryDetails',
    ADD_BANK_DETAILS: '/employee/addEmployeeBankDetails',
    ACTIVE_EMPLOYEE: '/employee/getAllEmployee',
    DELETE_EMPLOYEE: '/employee/',
    PERSONINFORMATION: '/employee/getEmployeeById/',
    PERSONAL_INFORMATION: '/employee/addEmployee',
    UPDATE_EMPLOYEE: '/employee/sendUpdateProfileEmail',
    UPDATE_EMPLOYEE_DETAILS: '/employee/updateEmployee',
    ADD_DEPARTMENT: '/addDepartment',
    ADD_DESIGNATION: '/addDesignation',
    DELETE_DEPARTMENT: '/deleteDepartment',
    DELETE_DESIGNATION: '/deleteDesignation',
    SEND_SUPPORT_REQUEST: '/sendSupportRequest',
    GET_ALL_SUPPORT_LIST: '/getAllSupportRequest/',
    MANAGER_LIST: '/employee/getAllManager/',
    GET_ALL_MANAGER_BY_COMPANY_ID: '/employee/getAllManagerListByCompanyId/',
    PAY_RUN_GROUP_LIST: '/groupByPayrun/getPayRunGroupList/',
    PAY_RUN_GROUP_EMPLOYEE_LIST: '/groupByPayrun/getEmployeeListByGroupName',
    SALARY_DETAILS: '/salary/saveSalaryDetails',
    ACTIVE_EMPLOYEE_DETAILS: '/employee/',
    ACTIVE_EMPLOYEE_SALARY_DETAILS: '/salary/salaryDetails/',
    GENERATE_SALRY_SLIP_PDF: '/pdf/salarySlip/',
    COMPANYPROFILE: '/addRegistrationAndCompanyInfo',
    UPDATE_COMPANY_PROFILE: '/updateCompanyDetails',
    VIEWPROFILE: '/getHrProfileBy/',
    GET_SUBSCRIPTION_DETAILS: '/subscription/getSubscriptionAccess/',
    GET_USER_SUBSCRIPTION_DETAILS: '/user-subscription/getSubscriptionDetails/',
    GET_SUBSCRIPTION_LIST: '/user-subscription/getSubscriptionDetailsList/',
    GET_ALL_SUBSCRIPTION_PLAN: '/subscription/getAllSubscription',
    SAVE_USER_SUBSCRIPTION: '/user-subscription/saveUserSubscription/',
    GET_TRANSACTION_HISTORY: '/user-subscription/getTransactionHistory/',
    CHECK_OTP: '/checkOTP',
    CHANGE_PASSWORD: '/resetPassword',
    SEPERATOR: '/',
    PAY_NOW: '/salary/generateSalaryDetails',
    PAY_NOW_EXCEL: '/payRun/payRunExcel/',
    ALL_ACTIVE_EMPLOYEE: '/employee/getEmployeesByStatus?isActive=',
    GET_EMPLOYEE_LIST: '/employee/getEmployeeList?type=',
    PARAM_COMAPANY_ID: '&companyId=',
    GET_ALL_EMPLOYEE_LIST: '/employee/getAllEmployeeBasicDetailList/',
    GET_SALARY_STRUCTURE: '/salary/structure/getSalaryStructure/',
    GET_DEDUCTION_DEATAILS: '/salary/structure/getDeductionStructure/',
    GET_REIMBURSEMENT_DEATAILS: '/salary/structure/getReimbursementStructure/',
    GET_STATUS: '/salary/structure/getSalaryStructureStatus/',
    ADD_COMPONENT: '/salary/structure/saveSalaryStructure',
    UPDATE_STATUS: '/salary/structure/updateSalaryStructureStatus',
    UPDATE_COLUMN: '/salary/structure/updateSalaryStructure',
    DELETE_COLUMN: '/salary/structure/deleteSalaryStructure',
    SALARY_BREAKUP_DATA: '/salary/getEmpSalaryBreakup/',
    SALARY_EARNING_DETAILS: '/salary/getEarningDetails',
    GET_ALL_SALARY_STRUCTURE_DETAILS: '/salary/structure/getAllSalaryStructureByCompanyId/',
    SALARY_DEDUCTION_DETAILS: '/salary/getDeductionDetails',
    SALARY_REIMBURSEMENT_DETAILS: '/salary/getReimbursementDetails',
    GET_COMPANY_DETAILS: '/getCompanyDetailsById/',
    GET_FUTURE_PAYRUN: '/payRun/futurePayRunDetails/',
    GET_PAST_PAYRUN: '/payRun/pastPayRunDetails/',
    GET_EMPLOYEE_DETAILS: '/employee/getEmployeeById/',
    SAVE_SALARY_SLIP_PDF: '/salary/saveSalaryPdf',
    GET_EMPLOYEE_BASED_ON_COMPNAY_ID: '/employee/getEmployeeById/',
    GET_EMPLOYEE_SALARY_DETAILS: '/salary/findAllSalaryDetails/',
    EMPLOYEE_SALARY_DETAILS: '/salary/findAllSalaryDetails/',
    DOWNLOAD_SALARY_SLIP: '/salary/download-pdf/',
    PUT_EMPLOYEE_IMG: "/employee/putImage/",
    GET_EMPLOYEE_IMG: "/employee/getImage/",
    GET_EMPLOYEE_NOTIFICATION: "/notifications/getEmployeeNotifications/",
    GET_ADMIN_NOTIFICATION: "/notifications/getAdminNotifications/",
    GET_TO_DO_TASK: "/toDoTask/getTask/",
    PAY_RUN_COST_SUMMARY: "/payRun/costSummary/",
    GROUP_BY_PAY_RUN: "/groupByPayrun/createPayRunGroup",
    DELETE_NOTIFICATION: "/notifications/discard/",
    DELETE_TO_DO_TASK: "/toDoTask/deleteTask/",
    REFRESH_TO_DO_TASK: "/refreshToDoTask/",
    DELETE_ALL_ADMIN_NOTIFICATION: "/notifications/deleteAllAdminNotification/",
    DELETE_ALL_EMPLOYEE_NOTIFICATION: "/notifications/deleteAllEmployeeNotification/",
    EMPLOYEE_PROFILE_IMAGE: "/employee/getImage/",
    GST_API: "https://sheet.gstincheck.co.in/check/",
    IFSC_API: "/verifyIFSCNumber/",
    ADD_EMPLOYEE_DOCUMENT: '/employee/addEmployeeDocument/',
    ASSIGN_MANAGER: '/attendance/assignManagerToEmployee',
    SAVE_PF_DETAILS: '/employee/addPFDetails',
    UPDATE_PT_DETAILS: '/employee/updatePTDetails',
    GET_ALL_DOCUMENTS: '/azure_document/getAllEmployeeDocumentById/',
    GET_ALL_DOCUMENTS_BY_ID_NAME: '/azure_document/getEmployeeDocumentByIdAndName/',
    APPLYLEAVE: '/applyLeave',
    LEVAESTRUCTURELIST: '/leave/getAllLeave/',
    DELETELEAVETYPE: "/leave/deleteLeaveType/",
    EMPLOYEE_LEAVE_DATA: '/getLeave/',
    LEAVE: '/leave/saveLeaveStructure/',
    EMPLOYEE_LEAVE_HISTORY: '/getApplyLeave/',
    ALL_EMPLOYEE_LEAVE_DATA: '/getManagerLeave/',
    EMPLOYEE_LEAVE_DATE: '/getLeaveData/',
    UPDATE_LEAVE: '/leaveStatus',
    MANAGER_EMPLOYEE_LEAVE_DATA: '/getEmployeeLeave/',
    LOG_TIME: '/attendance/logTime/',
    CATEGORY_CODE: '/attendance/getCategory/',
    SAVE_CATEGORY: '/attendance/saveCategory',
    SUBMIT_ATTENDANCE: "/attendance/submitAttendance",
    SAVE_ADMIN_CATEGORY: '/attendance/saveAdminCategory',
    GET_TIME_SHEET: '/attendance/recentTimesheet/',
    RECENT_TIMESHEET: '/attendance/recentTimesheetByRange/',
    STARTDATE: "?startDate=",
    ENDDATE: "&endDate=",
    DATE: "?date=",
    GET_CATEGORY: '/attendance/getAttendanceByDate/',
    DELETE_ATTENDANCE_RECORD: '/attendance/deleteRecord/',
    GET_ALL_EMPLOYEE: '/attendance/getEmployeeList/',
    GET_ALL_EMPLOYEE_SALARY_DETAILS: '/attendance/getEmployeeSalaryList/',
    SAVE_CONFIGURE_TIME: '/attendance/timeConfiguration',
    GET_EMPLOYEE_ATTENDANCE_UNDER_MAANGER: '/attendance/getSubmittedEmployeeAttendanceList/',
    APPROVE_ATTENDANCE: '/attendance/approveAttendance',
    REJECT_ATTENDANCE: '/attendance/rejectAttendance',
    GET_ALL_CATEGORY: '/attendance/getAllCategoryList/',
    COUNTRY_STATE_CITY: "https://raw.githubusercontent.com/sagarshirbhate/Country-State-City-Database/master/Contries.json",
    GET_COUNTRY_LIST: "/masterData/getCountryList",
    GET_STATE_LIST: "/masterData/getStateBYCountry/",
    GET_CITY_LIST: "/masterData/getCityBYState/",
    SUPER_ADMIN_LOGIN: '/admin/login',
    COMPANY_STATISTICS: '/dashboard/companyStatistics',
    PAYMENT_GRAPH_DATA: '/dashboard/paymentStatus/',
    RECENTLY_ADDED_CUSTOMERS: '/dashboard/getRecentlyAddedCustomerDetails/',
    SUBSCRIPTION: '/dashboard/subscriptionByCount/',
    PROFIT_LOSS: '/dashboard/getProfitLossDetails/',
    CUSTOMER_INFO: '/customer/getCustomerDetails/',
    CREATE_SUBSCRIPTION: '/subscription/createSubscription',
    VIEW_RECEIVED_PAYMENT: '/dashboard/getPaymentReceivedData/',
    PROFIT_LOSS_DATA: '/dashboard/getProfitLossData/',
    RECENTLY_ADDED_CUSTOMERS_DATA: '/dashboard/getRecentlyAddedCustomerData/',
    BOOK_DEMO: '/admin/bookDemo',
    ADD_EMAIL_CONFIGURATION: "admin/addEmailConfiguration",
    GET_EMAIL_CONFIGURATION: "/admin/getEmailConfiguration",
    CHECK_DOMAIN_NAME_EXIST_OR_NOT: "/checkDomainNameExistOrNot",
    UPLOAD_BULK_EMPLOYEE: '/employee/downloadBulkEmployeeSheet',
    UPLOAD_EMPLOYEE_BULK_DATA: '/employee/addBulkEmployee',
    GET_BANKS_DATA: '/findAllOnboardBankDetails',
    BUYSUBSCRIPTION: '/subscription-payment/createSubscriptionQR',
    PAYMENTSTATUS: '/subscription-payment/findQRTransactionStatus/',
    PORTAL_ACCESS_TOGGLE_API: '/employee/portalAccessToggle',
    EMAIL_LINK: '/subscription-payment/createPaymentLinks',
    PAYMENT_STATUS: '/subscription-payment/findPaymentLinkStatus/',
    REFRESH_TOKEN: '/auth/refresh',
    GET_EMPLOYEE_LAST_THREE_MONTH_SALARY_DATA: "/salary/getEmployeeLastThreeMonthData/",
    GET_SUBMIT_ATTENDANCE_STATUS: "/attendance/getSubmittedAttendanceStatus/",
    ADD_SECTION: '/configurationDeclaration/addSection',
    DELETE_SECTION: '/configurationDeclaration/deleteSectionList/',
    ADD_CATEGORY: '/configurationDeclaration/addCategory',
    GET_ALL_SECTION_LIST: '/configurationDeclaration/getAllSectionList',
    GET_ALL_CATEGORY_LIST: '/configurationDeclaration/getAllCategoryList/',
    ADD_REGIME: '/configurationDeclaration/addRegime',
    GET_ALL_REGIME_LIST: '/configurationDeclaration/getAllRegimeList',
    PROBATION_PERIOD: "/setting-config/addProbationPeriodConfiguration",
    LEAVE_CYCLE: "/setting-config/addLeaveConfiguration",
    LOP: "/setting-config/addLossOfPayConfiguration",
    PAYROLL_APPROVAL: "/setting-config/addPayrollApprovalConfiguration",
    ATTENDANCE_CONFIGURATION: "/setting-config/addAttendanceConfiguration",
    GET_ALL_SETTING_DATA: "/setting-config/getCompanyConfigDetails/",
    ADD_ROLES: "/addRole",
    UPDATE_ROLE: "/updateRole",
    DELETE_ROLE: "/deleteRole/",
    EDIT_ROLE: "/",
    GET_ALL_ROLE: "/getAllRoles/",
    GET_ALL_ROLE_BY_EMPLOYEE_ID: "/getAllRolesByEmployeeId/",
    SAVE_ALL_ROLE_BY_EMPLOYEE_ID: "/saveAllRolesByEmployeeId",
    APPLY_RESIGNAION: '/employee/applyEmployeeResignation',
    GETEMPLOYEE_RESIGNATION: '/employee/getEmployeeResignationDetails/',
    GET_ALL_EMPLOYEEE_RESIGNATION_LIST: '/employee/managerGetAllEmployeeResignationDetails/',
    VIEW_EMPLOYEE_RESIGNATION_BY_ID: '/employee/getEmployeeResignationDetails/',
    UPDATE_RESIGNATION_STATUS: '/employee/managerReview/employeeResignation',
    WITHDRAW_RESIGNATION: '/employee/withdrawEmployeeResignation/',
    GET_RESIGNATION_LIST: '/employee/hr/getEmployeeResignationDetails/',
    UPDATE_HR_RESIGNATION_STATUS: '/employee/hrReview/EmployeeResignation',
    EXIT_PROCESS: '/employee/exitEmployee',
    EXIT_EMPLOYEE_BASED_ON_ID: '/employee/getEmployeeResignationDetails/',
    FINAL_SETTLEMENT: '/employee/proceedToFinalSettlement',
    EMPLOYEE_FINAL_SETTLEMENT_DETAILS: '/employee/getEmployeeFinalSettlementDetails/',
    FINAL_SETTLEMENT_DETAILS: '/employee/getEmployeePaySummaryDetails/',
    GET_ALL_SECTIONS_AND_CATEGORY: '/configurationDeclaration/getAllSectionsAndCategories',
    SUBMIT_HOUSE_RENT_DETAILS: '/declaration/addHouseRentDetails',
    SUBMIT_DECLARATION: '/declaration/submitDeclaration/',
    GET_DECLARED_INVESTMENT_DETAILS: '/declaration/getAllInvestmentDetails/',
    GET_ALL_DECLARATION_LIST: '/declaration/getDeclarationApprovalList/',
    REGIME_POPUP_SHOW: '/declaration/getRegimePopupShow/',
    GET_DECLARATION_BY_ID: '/declaration/getDeclarationById/',
    APPROVE_DECLARATION: '/declaration/approveDeclaration/',
    REJECT_DECLARATION: '/declaration/rejectDeclaration',
    GET_ASSET_LIST: '/assets/getDashboardCountDetails/',
    GET_HOUSE_RENT_DETAILS: '/declaration/getHouseRentDetails/',
    ADD_SECTION_BY_SECTION_NAME: '/declaration/addSectionBySectionName/',
    IS_EMPLOYEE_EXIT: '/employee/getEmployeeResignationDetails/',
    IS_EMPLOYEE_FNF_EXIT: '/employee/getEmployeeFinalSettlementDetails/',
    EMPLOYEE_LIST_UNDER_MANAGER: '/employee/getAllManagerUnderEmployeeList/',
    GET_MATRIX_FILE: '/getEmployeeDocumentByIdAndName',
    GET_EMPLOYEE_GOLES_DATA: '/employee/getEmployeeGoalDetails',
    GET_EMPLOYEE_GOAL_DETAILS: '/employee/getGoalDetails/',
    DELETE_GOAL: '/deleteGoal',
    GET_EMPLOYEE_GOAL_DETAIL: '/employee/getGoalDetails',
    ADD_MANAGER_RATING: '/manager/saveManagerRating',
    GET_MANAGER_RATING: '/manager/getManagerRatingById',
    EMPLOYEE_PERFORMACE_RATING: '/employee/saveEmployeePerformanceRating',
    GET_EMPLOYEE_PERFORMACE_RATING: '/employee/getEmployeeAllPerformanceRatingById/',
    ADD_GOAL: '/employee/addGoal',
    APPRAISAL_SETUP: '/appraisalMatrix/saveAppraisalPeriodDetails',
    GET_APPRISAL_SETUP: 'appraisalMatrix/getAppraisalPeriodDetails/',
    POST_APPRAISAL_CONFIGURATION: '/setting-config/addAppraisalCycleConfiguration',
    POST_NOTICE_PERIOD_CONFIGURATION: '/setting-config/addNoticePeriodConfiguration',
    SAVE_EMPLOYEE_ID_CONFIGURATION: '/setting-config/addEmployeeDisplayId',
    APPRISAL_EXCEL: 'appraisalMatrix/getAppraisalPeriodExcelFile/',
    YET_TO_RECEIVE_ASSET: '/assets/yetToReceivedAssetList/',
    GET_ASSET_REQUEST: '/assets/getAllPendingRequest/',
    GET_All_ASSET_DETAILS: '/assets/getAllAssetList/',
    GET_ASSET_ALLOCATION_LIST: '/assets/getAssignAssetToEmployeeDetails/',
    GET_ASSET_HISTORY: '/assets/getAllAssetsHistory/',
    GET_ALL_ASSET_TYPE_LIST: '/assets/getAssetTypeList/',
    GET_ALL_SUPPLIER_ASSET_TYPE_LIST: '/supplier/getSupplierAssetTypeList/',
    GET_ALL_MODEL_NAME_LIST: '/assets/getAllModelNameList/',
    GET_ALL_SUPPLIER_LIST: '/supplier/getAllSupplierList/',
    GET_ALL_SUPPLIER: '/supplier/getAllSupplier/',
    ADD_ASSET: '/assets/addAsset',
    ASSIGN_ASSET: '/assets/assignAssetsToEmployee',
    DELETE_ASSET: '/assets/deleteAsset/',
    ADD_SUPPLIER: '/supplier/addSupplier',
    GET_ASSET_HISTORY_BY_ID: '/assets/getAssetHistoryTimeline/',
    GET_SUPPLIER_HISTORY_BY_ID: '/supplier/viewSupplierTimeLine/',
    DELETE_SUPPLIER_BY_ID: '/supplier/deleteById/',
    DELETE_ORDER_FOR_ASSET: '/supplier/orderForAsset/',
    GET_SUPPLIER_INFO: '/supplier/getSupplierInformation/',
    EDIT_SUPPLIER: '/supplier/updateSupplier/',
    UPDATE_ASSET_ORDER: '/supplier/updateAssetOrder/',
    REQUEST_ASSET_SUPPLER: '/supplier/requestForAssetsToSupplier/',
    GET_ALL_REQUEST_ASSET: '/supplier/getViewAllRequestedAssetsToSupplier/',
    GET_ALL_ASSET_BY_EMPLOYEE_ID: '/assets/getEmployeeProfileDetails/',
    ADD_PROJECT: '/project/addProject/',
    GET_ALL_PROJECT: '/project/getAllProjectDetails/',
    GET_ALL_MANAGER_LIST: '/project/getAllProjectManager/',
    GET_CURRENCY_LIST: '/masterData/findCurrencyList',
    GET_PROJECT_DETAILS_BY_ID: '/project/getProjectDetailsById/',
    GET_PROJECT_COSTING_DETAILS_BY_ID: '/project/getProjectCostingDetailsById/',
    GET_RESOURCE_COSTING_DETAILS_BY_ID: '/project/getResourceCostingDetailsById/',
    SUMMARY_GET_CALL: 'employee/getEmployeeSummaryDetails',
    SAVE_SUMMARY: 'employee/saveEmployeeSummaryDetails',
    SAVE_MANAGER_SUMMARY: 'employee/saveManagerSummaryDetails',
    GET_ANNUAL_APPRAISAL_SETTING: 'appraisalMatrix/getAppraisalPeriodDetails/',
    GOAL_REVIEW: 'employee/reviewEmployeeGoalDetails',
    ADD_EVENT: '/event/addEvent',
    ADD_EVENT_FILE: '/event/addBulkEvents/',
    GET_ALL_EVENT_LIST: '/event/getEventsList/',
    EDIT_EVENT: '/event/updateEvent',
    EVENT_DELETE: '/event/deleteEvent/',
    DOWNLOAD_SAMPLE_BULK_EVENT_FILE: '/event/downloadSampleBulkEventFile',
    GET_EVENT_CATEGORIES: '/event/getAllEventCategories/',
    GET_EMPLOYEE_GOLES_BY_CATEGORY: '/employee/getEmployeeGoalDetailsByCategory',
    GET_VOUCHER: '/admin/getVoucherDetails',
    USE_SUBSCRIPTION: '/admin/applyVoucher?subscriptionPrice=',
    GET_EMPLOYEES_MANAGERS_LIST: '/employee/getEmployeeManagerList/',
    SEARCH_EMPLOYEE: '/employee/getEmployeeListByName/',
    GET_PAYRUN_APPROVE_DETAILS: '/approval/getPayrollApproverDetails/',
    CREATE_PAYRUN_APPROVAL: '/approval/createPayRunApproval',
    DELETE_APPROVER: '/approval/deletePayrollApprover/',
    CHANGE_TO_DIRECT_APPROVAL: '/approval/changeToDirectApproval/',
    GET_APPROVAL_TYPE_AND_PAYRUN_STATUS: '/approval/getApprovalTypeAndPayrunStatusFlags/',
    CREATE_VOUCHER: '/admin/createVoucher',
    SERVICE_LIST: '/service/service-lists',
    SERVICE_CATEGORY: '/service/categories/',
    SERVICE_SUBCATEGORY: '/service/subcategories/',
    GET_PROJECT_DETAILS_BY_EMPLOYEE_ID: '/project/getAllProjectDetailsByEmployeeId/',
    SUBMIT_APPROVAL_STATUS: '/project/submitApproval',
    ADD_PROJECT_BOARD: '/dashboard/create-dashboard',
    GET_APPROVAL_STATUS: '/project/getApprovalStatus/',
    ADD_COURSE: '/training/addTrainingDetails',
    GET_ALL_TICKETS_BY_EMPLOYEE_ID: '/service/getAllTicketsByEmployeeId/',
    GET_ROLE: '/service/getRole/',
    GET_ALL_ISSUES: '/service/getAllIssues/',
    REQUESTiNFORMATION: '/service/create-service-request',
    GET_ALL_PROJECT_LIST: '/project/getAllProjectDetails/',
    CREATE_INCIDENT: '/service/create-service-request-incident',
    GET_REQUEST_BY_ID: '/service/getEmployeeServiceRequest/',
    ASSIGN_TO: '/service/assignToRequest',
    SHOW_ALL_TRAINING: '/training/getAllTrainingList/',
    GET_TRAINING_DETAILS_BY_TRANING_ID: '/training/getTrainingDetailsById/',
    GET_ALL_TRAINING_DETAILS_OF_EMPLOYEE: '/empTrainingRecord/getAllEmployeeTrainingStatus/',
    ALL_TRAINING_NAME: '/training/getAllTrainingsIdAndName/',
    ASSIGN_TRAINING: '/training/assignTrainings',
    GET_TRAINING_BY_EMPLOYEE_ID: '/training/getTrainingByEmpId/',
    GET_RATING_COMMENT: '/ratingReview/getLatestTrainingReviews/',
    ADD_COMMENT: '/ratingReview/saveRatingReview',
    GET_COMMENT_BY_EMP_ID: '/ratingReview/getEmployeeTrainingRattingAndReview/',
    GET_PAY_RUN_APPROVAL_HISTORY: '/approval/getPayrunApprovalHistory/',
    GET_PAY_RUN_APPROVAL_TYPE: '/approval/getPayRunApprovalType/',
    SEND_TO_APPROVER: '/approval/sendToApprover',
    GET_ALL_PENDING_PAY_RUN: '/payRun/getAllPendingPayRun/',
    DELETE_PAY_RUN_GROUP: '/groupByPayrun/deleteGroupByGroupId/',
    DELETE_EMPLOYEE_FROM_GROUP: '/groupByPayrun/deleteEmployeeFromGroup/',
    GET_PAY_RUN_DETAILS_BY_ID: '/approval/getPayRunDetailsById/',
    GET_ALL_APPROVAL_DETAILS: '/approval/getApprovalList/',
    ADD_PAY_RUN_STATUS: '/approval/addPayRunApprovalStatus/',
    EMPLOYEE_ORG_HIERARCHY: '/employee/getEmployeeOrganizationHierarchy/',
    DELETE_DECLARATION: '/declaration/deleteDeclaration/',
    GET_EMPLOYEE_RESIGNATION_HISTORY: '/employee/getEmployeeResignationHistory/',
    GET_REJECT_RESIGNATION: '/employee/getEmployeeResignationDetailsAdminSide/',
    GET_RESIGNATION_LIST_UNDER_MANAGER: '/employee/manager/getEmployeeResignationDetails/',
    CHNAGEMANAGER: '/employee/assignNewManager/',
    FINAL_SUBMIT_DECLARATION: '/declaration/finalSubmitDeclaration/',
    GET_BIRTHDAY_LIST: '/birthday/getBirthdayList/',
    GET_ASSET_LIST_BY_ASSET_TYPE: '/assets/getAssetListByAssetType/',
    EMPLOYEE_DATA: '/employee/getAllActiveEmployeeList/',
    SUBMIT_POI: '/declaration/submitPOI/',
    REGIME_TYPE: '/declaration/getRegimeType/',
    DELETE_CATEGORY: '/configurationDeclaration/deleteSectionCategory/',
    PAYRUN_GROUP_EMPLOYEE_LIST: '/payRun/getPayRunGroupAllEmployeeList/',
    GET_PAY_RUN_APPROVAL_STATUS: '/payRun/getPayRunApprovalStatus/',
    DOWNLOAD_PAYRUN_EXCEL: '/employee/downloadEmployeeSalaryTransactionFile',
    ADD_FAMILY_AND_EMERGENCY_INFO: '/emergency-contact/saveEmergencyContact',
    GET_ALL_EMERGENCY_CONTACTS: '/emergency-contact/getAllEmergencyContactInfo/',
    DELETE_FAMILY_AND_EMERGENCY_CONTACT: '/emergency-contact/deleteEmergencyContactInfoId/',
    GET_FAMILY_AND_EMERGENCY_INFO_BY_ID: '/emergency-contact/getEmergencyContactInfoId/',
    UPDATE_FAMILY_AND_EMERGENCY_INFO: '/emergency-contact/updateEmergencyContactInfo',
    ADD_BANK_DETAIL: '/setting-config/addCompanyBankDetails',
    COMPANY_BANK_INFO: '/setting-config/getCompanyBankDetails/',
    UPDATE_COMPANY_BANK_DETAILS: '/setting-config/updateCompanyBankDetails',
    CREATE_BOARD: '/dashboard/create-dashboard',
    GET_ALL_PROJECT_DASHBOARD: '/dashboard/allProjectsDashboard',
    GET_DASHBOARD_DETAILS: '/dashboard/getProjectDashboard/',
    UPDATE_PROJECT_BOARD: '/dashboard/updateProjectDashboard',
    GET_SPRINT_DETAILS_BY_ID: '/sprint/getSprintDetails/',
    UPDATE_SPRINT: '/sprint/updateSprint',
    GET_ALL_SPRINT: '/sprint/getAllSprints/',
    CREATE_SPRINT: '/sprint/createSprint',
    ISSUE_LIST: '/sprint-task/getAllTasks/',
    ADD_TASK: '/sprint-task/createTask',
    GET_TASK_DETAILS: '/sprint-task/getTask/',
    ADD_COMMNETS: '/sprint-task/addComment',
    UPDATE_TASK: '/sprint-task/updateTask',
    GET_ALL_TASK_COMMENTS: '/sprint-task/getCommentsList/',
    DELETE_COMMENT: '/sprint-task/deleteComment/',
    GET_BACKLOG_DATA: '/sprint-task/activeSprintsTasksBacklogDetails/',
    DELETE_SPRINT_BY_SPRINTID: '/sprint/deleteSprint/',
    GET_DASHBOARD_LIST: '/dashboard/allProjectsDashboardList',
    SHOW_PROJECT_DETAILS: '/project/getProjectAndResourceDetails/',
    CHANGE_TASK_STATUS: '/sprint/getTasksList/',
    UPDATE_TASK_STATUS: '/sprint-task/updateTaskStatus',
    GET_QUICK_ACCESS_LIST: '/sprint/getQuickAccessList/',
    CHANGE_TASK_PHASES: '/sprint-task/updateTaskStatus',
    NEW_COLLOBORATORS: '/project/addResourceInProject',
    GET_WORKED_ON_DATA: '/sprint/getWorkedOnData/',
    START_STOP_SPRINT: '/sprint/startStopSprint',
    TASK_ORDER_END_POINT: '/sprint-task/updateTaskSequence',
    DELETE_BOARD: '/dashboard/closeProjectDashboard/',
    GET_ALL_SPRINTS: '/sprint-task/getAllTasksList',
    VIEW_REPORTS: '/dashboard/downloadTaskManagementReport',
    VIEW_BACKLOG_REPORTS: '/dashboard/downloadBackLockTaskManagementReport/',
    DELETE_PROJECT: '/project/deleteProject/',
    PROJECT_CODE:'projectCode='
}
