
import { Leave } from "src/app/model/leave-application/leave-stucture.model";

export class MasterData {
    public static readonly USER_DATA: Leave[] = [

        {
            id: 1,
            leaveType: 'Sick Leave',
            leaveDescription: 'Sick Leave ',
            leaveCount: 11,
            companyId: '',
            isEdit: false,
        },
        {
            id: 2,
            leaveType: 'Compensatory Leave',
            leaveDescription: 'Compensatory Leave',
            leaveCount: 3,
            companyId: '',
            isEdit: false,
        },
        {
            id: 3,
            leaveType: 'Anniversary Leave',
            leaveDescription: 'Birthday/Anniversary',
            leaveCount: 2,
            companyId: '',
            isEdit: false,
        },
        {
            id: 4,
            leaveType: 'Maternity Leave',
            leaveDescription: 'Maternity leave ',
            leaveCount: 2,
            companyId: '',
            isEdit: false,
        },
        {
            id: 5,
            leaveType: 'Paternity Leave',
            leaveDescription: 'Paternity leave ',
            leaveCount: 2,
            companyId: '',
            isEdit: false,
        },
    ];

    public static readonly COLUMNS_SCHEMA = [
        {
            key: 'isSelected',
            type: 'isSelected',
            label: '',
        },
        {
            key: 'leaveType',
            type: 'text',
            label: 'Leave Type',
        },
        {
            key: 'leaveDescription',
            type: 'text',
            label: 'Description',
        },

        {
            key: 'leaveCount',
            type: 'number',
            label: 'No. of Days',
        },

        {
            key: 'isEdit',
            type: 'isEdit',
            label: 'Action',
        },
    ];

    public static readonly COLUMNS_FIELD = [{ field: "id", width: 130, floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true},
    { field: "leaveType", width: 170,floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true},
    { field: "leaveStatus", width: 170, floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: "startDate", width: 160, floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true},
    { field: "endDate", width: 160,floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true},
    { field: "noOfDays", width: 160,floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true},
    { field: "createdDate", width: 160,floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true}
    ];

    public static readonly LEAVE_DATA = [{ field: "id", width: 130, unSortIcon: true },
    { field: "name", width: 150, floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: "leaveType", width: 160,floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: "startDate", width: 150,floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true},
    { field: "endDate", width: 150,floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: "noOfDays", width: 100, floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true},
    { field: "leaveReason", width: 160,floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true},
    { field: "status", width: 120,floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },

    {
        field: "Action", cellRenderer: (data: any) => {
            return `
              <mat-icon class="mat-icon material-icons mat-icon-no-color" style="justify-content:center; align-item:center;
              font-size: large; margin-right:20px; margin-top:10px"; role="icon">
              visibility</mat-icon>
             `
        }, width: 120
    }
    ];
}