import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { DepartmentData } from 'src/app/model/employeeDetails';
import { DepartmentService } from 'src/app/services/department/departmentService';
import { Messages } from 'src/app/constants/messages.constant';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-view-department',
  templateUrl: './view-department.component.html',
  styleUrls: ['./view-department.component.scss']
})
export class ViewDepartmentComponent {
  addDepartmentFlag: boolean = true;
  isEmployee: any;
  currentUserId = sessionStorage.getItem('role');
  departmentColDef: any;
  departmentData!: DepartmentData[];
  departmentForm: FormGroup;
  showAddPopup: boolean = false;
  error: any;
  isAddMode: boolean = true;
  departmentId!: any;

  constructor(
    private renderer: Renderer2,
    private departmentService: DepartmentService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private toastrService: ToastrService
  ) {
    this.departmentForm = this.fb.group({
      departmentName: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.getDepartment();
    this.departmentColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
  }

  departmentTableCol = [
    { headerName: 'Department Id', field: 'id', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: Messages.DEPARTMENT, floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: Messages.USERS, floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: Messages.DATE, floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: 'Action', cellRenderer: this.actionCellRenderer.bind(this) },
  ];

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');

    const createButton = (icon: string, colorClass: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      this.renderer.addClass(button, 'mat-icon-button');
      this.renderer.addClass(button, 'ag-grid-custom-button');
      this.renderer.addClass(button, colorClass);
      this.renderer.listen(button, 'click', clickHandler);
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.appendChild(cell, button);
    };
    createButton('edit', 'custom-black', () => this.editDepartment(params.data));
    createButton('delete', 'custom-red', () => this.deleteDepartment(params.data));
    return cell;
  }

  getDepartment() {
    this.departmentService.getDepartmentList(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((data: any) => {
      if (data && Array.isArray(data)) {
        this.departmentData = data.map((item: any) => {
          const dateTimeString = item.createdDate;
          const dateTime = new Date(dateTimeString);
          const dateOnlyString = dateTime.toISOString().split('T')[0];
          return {
            ...item,
            createdDate: dateOnlyString,
          };
        });
      } else {
        this.departmentData = [];
      }
    });
  }

  toggleAddPopup(): void {
    if (this.showAddPopup) {
      this.departmentForm.reset();
      this.addDepartmentFlag = true;
    }
    this.showAddPopup = !this.showAddPopup;
  }

  deleteDepartment(data: any) {
    if (confirm(AppConstant.DELETE_CONFIRMATION_MASSAGE)) {
      this.departmentService.deleteDepartment(sessionStorage.getItem(AppConstant.COMPANY_ID), data.id).subscribe(
        (data) => {
          if (data.status === 404) {
            this.toastrService.clear();
            return this.toastrService.error(data.message);
          }
          const index = this.departmentData.findIndex((item) => item.id === data.id);
          if (index !== -1) {
            this.departmentData.splice(index, 1);
            this.cdr.detectChanges();
          }
          this.getDepartment();
          return this.toastrService.success(data.message);
        }
      );
    }
    this.getDepartment();
  }

  addDepartment() {
    const departmentName = this.departmentForm.value.departmentName;
    if (!departmentName || departmentName.trim() === '') {
      return;
    }
    let departmentDetails: DepartmentData = {
      departmentName: departmentName,
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      id: 0,
    };
    this.departmentService.addDepartment(departmentDetails).subscribe(
      (data: any) => {
        if (data.status === 404) {
          this.toastrService.clear();
          return this.toastrService.error(data.message);
        }
        this.departmentForm.reset();
        this.toastrService.success(data.message);
        this.toastrService.clear();
        this.toggleAddPopup();
        return this.getDepartment();
      },
      (error) => {
      }
    );
  }

  updateDepartment() {
    const departmentName = this.departmentForm.value.departmentName;
    if (!departmentName || departmentName.trim() === '') {
      return;
    }
    let departmentDetails: DepartmentData = {
      departmentName: departmentName,
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      id: this.departmentId,
    };
    this.departmentService.updateDepartment(departmentDetails).subscribe(
      (data: any) => {
        if (data.status === 404) {
          this.toastrService.clear();
          return this.toastrService.error(data.message);
        }
        this.isAddMode = true;
        this.showAddPopup = false;
        this.getDepartment();
        return this.toastrService.success(data.message);
      },
      (error) => {
        this.error = error;
        if (error.status !== '200') {
          this.getDepartment();
        }
      }
    );
    this.departmentForm.get('departmentName')?.setValue('');
    this.showAddPopup = false;
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  editDepartment(data: any) {
    this.departmentId = data.id;
    this.addDepartmentFlag = false;
    this.isAddMode = false;
    this.departmentForm.patchValue({
      departmentName: data.departmentName
    })
    this.toggleAddPopup();
  }

  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

}
