<div class='container' layout="row" flex>
  <div layout="row" layout-xs="column" flex>
    <form name="createPersonalForm" [formGroup]="createPersonalForm" class="form-content">
      <div>
        <div class="form-field-gap">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label class="formLabel">{{'personal_email'|translate}}</mat-label>
            <input matInput type="text" class="input" formControlName="personalEmail" required />
            <mat-error *ngIf="createPersonalForm.controls['personalEmail'].hasError('required')">
              {{'personal_email_is_required'|translate}}
            </mat-error>
            <mat-error *ngIf="createPersonalForm.controls['personalEmail'].hasError('invalidDomain')">
              {{'only_personal_email_allowed'|translate}}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ 'mobile_number' | translate }}</mat-label>
            <input matInput id="mobileNo" (keypress)="validateNumber($event)" minlength="10" maxlength="10"
              formControlName="mobileNo" required />
            <mat-error *ngIf="createPersonalForm.controls['mobileNo'].hasError('required')">
              {{ 'mobile_number_required' | translate }}
            </mat-error>
            <mat-error *ngIf="createPersonalForm.controls['mobileNo'].hasError('invalidMobileNumber')">
              {{ 'invalid_mobile_number' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-field-gap">
          <mat-form-field appearance="outline" class="dob">
            <mat-label class="formLabel">{{ 'date_of_birth' | translate }}</mat-label>
            <input matInput formControlName="dob" class="mat-input" [matDatepicker]="picker" [min]="sixtyYearsOldDate"
              [max]="ageOfMaturityDate" (keypress)="$event.preventDefault()" required />
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="createPersonalForm.get('dob')?.hasError('required')">
              {{ 'date_of_birth_is_required' | translate }}
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label class="formLabel">{{ 'pan' | translate }}</mat-label>
            <input matInput formControlName="panNo" class="mat-input" (keypress)="alphanumericAllowed($event)"
              [maxLength]="10" required appUppercase />
            <mat-error *ngIf="createPersonalForm.get('panNo')?.hasError('required')">
              {{ 'pan_number_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="createPersonalForm.get('panNo')?.hasError('pattern')">
              {{ 'please_enter_valid_pan_no' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="form-field-gap2">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label class="formLabel">{{'address1'|translate}}</mat-label>
            <input matInput type="text" class="input" formControlName="addressLine1" required
              (keypress)="addressVerification($event)" />
            <mat-error>{{'address_line_is_required'|translate}}</mat-error>
          </mat-form-field>

        </div>
        <div class="form-field-gap2">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label class="formLabel">{{'address2'|translate}}</mat-label>
            <input matInput type="text" class="input" formControlName="addressLine2"
              (keypress)="addressVerification($event)">
          </mat-form-field>

        </div>
        <div class="form-field-gap3">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label class="formLabel">{{'country'|translate}}</mat-label>
            <mat-select matInput formControlName="country" class="select_mat_input" required="true"
              (selectionChange)="getState($event.value)">
              <mat-option *ngFor="let country of countryList;" [value]="country">{{country}}
              </mat-option>
            </mat-select>
            <mat-error>{{'country_is_required'|translate}}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="full-width">
            <mat-label class="formLabel">{{'state'|translate}}</mat-label>
            <mat-select matInput formControlName="state" class="select-mat-input" required="true"
              (selectionChange)="getCity($event.value)">
              <mat-option *ngFor="let state of stateList;" [value]="state">
                {{ state }}
              </mat-option>
            </mat-select>
            <mat-error>{{'state_is_required'|translate}}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="full-width">
            <mat-label class="formLabel">{{'city'|translate}}</mat-label>
            <mat-select matInput formControlName="city" class="select-mat-input"
              (selectionChange)="selectedCityName($event.value)">
              <mat-option *ngFor="let city of cityList;" [value]="city">{{ city }}</mat-option>
            </mat-select>

            <mat-error>{{'city_is_required'|translate}}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ 'pincode' | translate }}</mat-label>
            <input (keypress)="validateNumber($event)" matInput class="input" formControlName="pinCode" minlength="6"
              maxlength="6" />
            <mat-error *ngIf="createPersonalForm.get('pinCode')?.hasError('required')">
              {{ 'pincode_required' | translate }}
            </mat-error>
            <mat-error *ngIf="createPersonalForm.get('pinCode')?.hasError('minlength')">
              {{ 'pincode_length' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <mat-divider></mat-divider>
        <app-loader></app-loader>
        <button matStepperPrevious class="back-button">{{ 'back' | translate }}</button>
        <button class="button-right" (click)="addEmployeePersonalInformation()"
          type="submit">{{'save_and_next'|translate}}</button>
        <button class="button-right cancel-btn" type="reset" (click)="backToHome()">{{'cancel'|translate}}</button>
      </div>
    </form>
  </div>
</div>