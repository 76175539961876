<app-navbar class="small_screen-visibility"></app-navbar>

<div class="login_registration_container">
  <mat-card>
    <mat-card-content>
      <div class="mat-mdc-card-content--page">
        <div class="heading">
          <h1 *ngIf="!showOtpVerification">{{ 'create_new_password' | translate }}</h1>
        </div>
      </div>

      <form [formGroup]="forgotPasswordForm" id="forgotPasswordForm" *ngIf="!showOtpVerification">
        <div>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{ 'email' | translate }}</mat-label>
            <input matInput formControlName="email" type="email">
            <mat-error *ngIf="forgotPasswordForm.controls['email'].hasError('required')">
              {{ 'please_enter_email' | translate }}
            </mat-error>
            <mat-error *ngIf="forgotPasswordForm.controls['email'].hasError('invalidDomainInput')">
              {{ 'invalid_domain_email' | translate }}
            </mat-error>
            <mat-error
              *ngIf="forgotPasswordForm.controls['email'].hasError('email') && !forgotPasswordForm.controls['email'].hasError('required')">
              {{ 'invalid_email_format' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="spinner-overlay" *ngIf="isOtpSending">
          <mat-spinner class="mat-spinner"></mat-spinner>
        </div>
      </form>

      <div *ngIf="showOtpVerification" id="otpVerification" class="opt-container">
        <h2>{{ 'enter_otp' | translate }}</h2>
        <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"
          [(ngModel)]="otp"></ng-otp-input>
        <p *ngIf="!isCountdownComplete">{{ 'remaining_time' | translate }}: {{ displayTime }}</p>
        <p *ngIf="isCountdownComplete">
          <u><a class="resend-otp" (click)="resendOtp()" href="javascript:void(0);" [class.clicked]="isResendClicked">{{
              'resend_otp' | translate }}</a></u>
        </p>
      </div>

      <div>
        <span class="links">
          <a routerLink='/{{loginLink}}'>{{ 'back' | translate }}</a>
        </span>
        <button *ngIf="!showOtpVerification" class="button-right" (click)="otpVerification()">{{ 'get_otp' | translate
          }}</button>
      </div>
    </mat-card-content>
  </mat-card>
  <div class="image-div">
    <img src="../../../assets/images/pre-login/forgot_passwordBG.png" class="img-body" alt="side-image">
  </div>
</div>

<div class="copyright">
  <p>&copy; {{'footer' | translate}}</p>
</div>