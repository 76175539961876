<div fxlayout="row" fxLayoutGap="100px">
  <div class="toolbar">
    <mat-toolbar class="toolbar">
      <img class="logo" src="../../../../assets/images/landing-page/logo/hrms-logo.svg" alt="">
      <div>{{'iwingshrms' | translate}}</div>
      <!-- <select class="form-select form-select-sm language-switcher" class="selectLanguage"
        (click)="switchLanguage($event)">
        <option value="en">English</option>
        <option value="ar">{{'arabic'|translate}}</option>
      </select> -->
    </mat-toolbar>
  </div>
</div>