import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { AppConstant } from 'src/app/constants/app.constants';
import { LogTime } from 'src/app/model/attendance/attendance/attendance.module';
import { environment } from 'src/environments/enviroment';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {

  private dateCallback: Function | undefined;

  constructor(private http: HttpClient) { }
  setSelectDateCallback(callback: Function) {
    this.dateCallback = callback;
  }

  callSelectDate() {
    if (this.dateCallback) {
      this.dateCallback();
    }
  }

  createLogTime(companyId: any, employeeId: any, logTime: LogTime): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.LOG_TIME + companyId + AppConstant.SEPERATOR + employeeId, logTime).pipe(
      map((res: any) => {
        return res
      }))
  }


  getCategory(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.CATEGORY_CODE + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res.data;
      })
    )
  }

  getTimeSheet(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_TIME_SHEET + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getTimeSheetByRange(companyId: any, employeeId: any, startDate: any, endDate: any) {
    return this.http.get(environment.apiUrl + API_END_POINTS.RECENT_TIMESHEET + companyId + AppConstant.SEPERATOR + employeeId + API_END_POINTS.STARTDATE + startDate + API_END_POINTS.ENDDATE + endDate).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getCategoryData(companyId: any, employeeId: any, date: Date, projectCode: any): Observable<any> {
    const params = new HttpParams()
      .set('date', date.toString())
      .set('projectCode', projectCode);
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_CATEGORY + companyId + AppConstant.SEPERATOR + employeeId, { params }).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  deleteAttendanceRecord(companyId: any, employeeId: any, date: any): Observable<any> {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_ATTENDANCE_RECORD + companyId + AppConstant.SEPERATOR + employeeId + API_END_POINTS.DATE + date).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getEmployeeList(companyId: any, employeeId: any,): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_EMPLOYEE + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getEmployeeSalaryList(companyId: any, employeeId?: any): Observable<any> {
    let url = environment.apiUrl + API_END_POINTS.GET_ALL_EMPLOYEE_SALARY_DETAILS + companyId;
    if (employeeId) {
      url += AppConstant.EMPLOYEE_ID_REQUEST + employeeId;
    }
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      })
    );
  }


  addCategoryApi(categoryDto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.SAVE_CATEGORY, categoryDto).pipe(
      map((res: any) => {
        return res
      }))
  }

  submitAttendanceApi(monthAttendanceDto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.SUBMIT_ATTENDANCE, monthAttendanceDto).pipe(
      map((res: any) => {
        return res
      }))
  }

  getEmployeeName(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_EMPLOYEE_DETAILS + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getAttendanceHistory(companyId: any, managerId: any, employee: any): Observable<any> {
    let url = environment.apiUrl + API_END_POINTS.GET_EMPLOYEE_ATTENDANCE_UNDER_MAANGER + companyId;
    if (managerId !== null) {
      if (employee === "employee") {
        url += AppConstant.EMPLOYEE_ID_REQUEST + managerId;
      } else {
        url += AppConstant.MANAGER_ID_REQUEST + managerId;
      }
    }
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  attendanceConfiguration(categoryDto: any) {
    return this.http.post(environment.apiUrl + API_END_POINTS.SAVE_ADMIN_CATEGORY, categoryDto).pipe(
      map((res: any) => {
        return res
      }))
  }


  timeConfiguration(setTime: any) {
    return this.http.post(environment.apiUrl + API_END_POINTS.SAVE_CONFIGURE_TIME, setTime).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  approveAteeendance(attendanceDto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.APPROVE_ATTENDANCE, attendanceDto).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  rejectAteeendance(attendanceDto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.REJECT_ATTENDANCE, attendanceDto).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getAllCategoryList(companyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_CATEGORY + companyId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getSubmittedAttendanceStatus(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_SUBMIT_ATTENDANCE_STATUS + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }


}


