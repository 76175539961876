import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent {
  @Input('rating') rating: number = 0;
  starCount: number = 5;
  color: string = 'accent';
  @Output() ratingUpdated = new EventEmitter<number>();

  snackBarDuration: number = 2000;
  ratingArr: number[] = [];

  constructor(private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.ratingArr = Array(this.starCount).fill(0).map((x, i) => i);
  }

  onClick(rating: number) {
    this.rating = rating;
    this.ratingUpdated.emit(rating);
  }

  showIcon(index: number): string {
    if (this.rating >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }
}