<app-navbar class="small_screen-visibility"></app-navbar>

<div class="login_registration_container">
  <mat-card>
    <mat-card-content>
      <div class="mat-mdc-card-content--page">
        <div class="heading">
          <h1>{{'create_your_account' | translate}}</h1>
          <span>{{'your_gateway_to_personalized_access' | translate}}</span>
        </div>
      </div>

      <form name="registartionForm" [formGroup]="registrationForm">
        <div class="form-field-gap2">
          <mat-form-field appearance="outline" class="salutation">
            <mat-label>{{ 'salutation' | translate }}</mat-label>
            <mat-select formControlName="salutation" class="country-code-select">
              <mat-option *ngFor="let salutation of salutations" [value]="salutation.name">
                {{ salutation.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{'name'|translate}}</mat-label>
            <input matInput type="text" class="input" formControlName="name" required
              (keypress)="validateCharacterNumberAndSpace($event)" maxlength="50" />
            <mat-error *ngIf="registrationForm.controls['name'].hasError('required')">
              {{ 'name_required' | translate }}
            </mat-error>
            <mat-error *ngIf="registrationForm.controls['name'].hasError('invalidInput')">
              {{ 'invalid_name_input' | translate }}
            </mat-error>
          </mat-form-field>

        </div>
        <div>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{'work_email'|translate}}</mat-label>
            <input matInput type="text" class="input" formControlName="email" required />
            <mat-error *ngIf="registrationForm.controls['email'].hasError('required')">
              {{ 'email_required' | translate }}
            </mat-error>
            <mat-error *ngIf="registrationForm.controls['email'].hasError('invalidEmail')">
              {{'invalid_email'|translate}}
            </mat-error>
            <mat-error *ngIf="registrationForm.controls['email'].hasError('invalidDomainInput')">
              {{'invalid_domain_email'|translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="note_container">
          <i class="fa fa-info-circle"></i>
          <div class="tooltipMessage">{{'login_email'|translate}}</div>
        </div>

        <div class="form-field-gap1">
          <mat-form-field appearance="outline" class="full-width">
            <mat-select formControlName="countryCode" class="country-code-select">
              <mat-option *ngFor="let country of countries" [value]="country.code">
                {{ country.name }} {{ country.code }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ 'mobile_number' | translate }}</mat-label>
            <input matInput id="mobileNumber" (keypress)="validateNumber($event)" formControlName="mobileNumber"
              minlength="10" maxlength="10" required />
            <mat-error *ngIf="registrationForm.controls['mobileNumber'].hasError('required')">
              {{ 'mobile_number_required' | translate }}
            </mat-error>
            <mat-error *ngIf="registrationForm.controls['mobileNumber'].hasError('invalidMobileNumber')">
              {{ 'invalid_mobile_number' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-field-gap1">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{'password' | translate}}</mat-label>
            <input class="input" id="password-field1" type="password" formControlName="password" matInput
              [type]="hidePassword ? 'password' : 'text'" appBlockCopyPaste required />
            <mat-error *ngIf="!registrationForm.controls['password'].valid">
              {{'strong_password'|translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{'confirm_password'|translate}}</mat-label>
            <input class="input" id="password-field" type="password" formControlName="confirmPassword" matInput
              [type]="hidePassword ? 'password' : 'text'" compare="password" appBlockCopyPaste required />
            <mat-error>
              {{'password_does_not_match'|translate}}
            </mat-error>
          </mat-form-field>

          <button class="visibility" mat-icon-button (click)="hidePassword = !hidePassword"
            [attr.aria-label]="'Toggle Password Visibility'" [attr.aria-pressed]="!hidePassword">
            <i class="material-icons">{{ hidePassword ? 'visibility_off' : 'visibility' }}</i>
            <span class="visibility-text">{{(hidePassword ? 'show' : 'hide') | translate}}</span>
          </button>

          <div class="spinner-overlay" *ngIf="isVerifyingEmail || isOtpSending">
            <mat-spinner></mat-spinner>
          </div>
        </div>

        <div>
          <span class="links">{{'already_account'|translate}} <a class="login-link"
              routerLink='/{{registrationLink}}'>{{'login'|translate}}</a> </span>
          <button class="button-right" (click)="register()" type="submit"
            color="primary">{{'register'|translate}}</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
  <div class="image-div">
    <img src="../../../assets/images/pre-login/registrationBG.png" class="img-body" alt="side-image">
  </div>
</div>

<div class="copyright">
  <p>&copy; {{'footer' | translate}}</p>
</div>