import { Injectable } from '@angular/core';
import { AppConstant } from 'src/app/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private readonly TOKEN_KEY = AppConstant.TOKEN;

  getToken(): string | null {
    return sessionStorage.getItem(AppConstant.TOKEN);
  }

  setToken(token: string): void {
    sessionStorage.setItem(AppConstant.TOKEN, token);
  }

  isTokenExpired(): boolean {
    const token = this.getToken();

    if (!token) {
      return true;
    }

    const expirationTime = this.getExpirationTimeFromToken(token);

    // Compare expiration time with the current time
    const currentTime = new Date().getTime() / 1000; // Convert to seconds
    return expirationTime < currentTime;
  }

  getTimeRemainingForTokenExpiry(): number {
    const token = this.getToken();

    if (!token) {
      return 0;
    }

    const expirationTime = this.getExpirationTimeFromToken(token);
    const currentTime = new Date().getTime() / 1000; // Convert to seconds

    // Calculate the time remaining in seconds
    const timeRemaining = expirationTime - currentTime;

    return timeRemaining > 0 ? timeRemaining : 0;
  }

  private getExpirationTimeFromToken(token: string): number {
    if(token !== "undefined") {
      const tokenData = JSON.parse(atob(token.split('.')[1]));
      return tokenData.exp;
    }
    return 0;
  }

  logOut(): void {
    sessionStorage.removeItem(AppConstant.TOKEN);
  }
}
