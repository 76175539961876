<form [formGroup]="addgoalForm" class="cardcontainer">
  <div class="labelContainer">
    <h3>{{'add_goal'|translate}}</h3>
  </div>
  <div class="goal-content">
    <mat-form-field appearance="outline" class="goalinput">
      <mat-label>{{'goal_name'|translate}}</mat-label>
      <input matInput type="text" formControlName="goalName" maxlength="50" (keypress)="alphanumeric($event)" />
      <mat-error
        *ngIf="addgoalForm.controls['goalName'].errors ?.['required']">{{'goal_name_is_required'|translate}}</mat-error>
      <mat-error *ngIf="addgoalForm.controls['goalName'].errors ?.['pattern']"></mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="libaryInput">
      <mat-label>{{ 'category' | translate }}</mat-label>
      <mat-select formControlName="category">
        <mat-option *ngFor="let category of categories" [value]="category">{{ category }}</mat-option>
      </mat-select>
      <mat-error *ngIf="addgoalForm.controls['category'].errors?.['required']">
        {{ 'category_is_required' | translate }}
      </mat-error>
    </mat-form-field>


  </div>
  <div class="goal-content">
    <mat-form-field appearance="outline" class="startDate">
      <mat-label>{{ 'start_date' | translate }}</mat-label>
      <input matInput [matDatepicker]="picker1" formControlName="startDate" (dateChange)="updateEndDateMin()"
        [min]="startOfFinancialYear" [max]="endOfFinancialYear" (keypress)="$event.preventDefault()">
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
      <mat-error *ngIf="addgoalForm.get('startDate')?.hasError('required')">
        {{ 'start_date_required' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="dueDate">
      <mat-label>{{ 'end_date' | translate }}</mat-label>
      <input matInput [matDatepicker]="picker2" formControlName="targetDate" [min]="addgoalForm.get('startDate')?.value"
        [max]="endOfFinancialYear" (keypress)="$event.preventDefault()">
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
      <mat-error *ngIf="addgoalForm.get('targetDate')?.hasError('required')">
        {{ 'end_date_is_required' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="weigths">
      <mat-label>{{'weighths'|translate}}</mat-label>
      <input matInput type="text" formControlName="weigths" max="100" min="0" [maxLength]="3"
        (keypress)="numberOnly($event)" />
      <mat-error
        *ngIf="addgoalForm.controls['weigths'].errors ?.['required']">{{'weigths_is_required'|translate}}</mat-error>
      <mat-error
        *ngIf="addgoalForm.controls['weigths'].errors ?.['wrongPercentValue']">{{'weighths_validation'|translate}}</mat-error>
    </mat-form-field>
  </div>
  <div class="descripionContainer">
    <mat-form-field appearance="outline" class="descripionInput">
      <mat-label>{{'description'|translate}}</mat-label>
      <textarea matInput formControlName="Description" (keypress)="alphanumeric($event)" maxlength="200"></textarea>
      <mat-error
        *ngIf="addgoalForm.controls['Description'].errors ?.['required']">{{'description_is_required'|translate}}</mat-error>
    </mat-form-field>
  </div>
  <div class="save">
    <button mat-raised-button class="cancel-btn" type="submit" (click)="cancel_form()">
      {{'cancel'|translate}}
    </button>
    <button mat-raised-button type="submit" class="button-right" (click)="onSubmit()">
      {{'save'|translate}}
    </button>
  </div>
</form>