<body class="body">

    <div class="dashboard-container">
        <div class="card-container">
            <mat-card class="dashboard-card">
                <mat-card-title>{{'profit'|translate}}</mat-card-title>
                {{profit}}
                <mat-card-content>
                </mat-card-content>
            </mat-card>

            <mat-card class="dashboard-card">
                <mat-card-title>{{'total_users'|translate}}</mat-card-title>
                {{totalUsers}}
                <mat-card-content>
                </mat-card-content>
            </mat-card>

            <mat-card class="dashboard-card">
                <mat-card-title>{{'rating'|translate}}</mat-card-title>
                <mat-card-content>
                    0
                </mat-card-content>
            </mat-card>

            <mat-card class="dashboard-card">
                <mat-card-title>{{'visitors'|translate}}</mat-card-title>
                <mat-card-content>
                    0
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="main-container">
    <div class="container">
        <div class="app-payment-received-graph">
            <app-payment-received-graph></app-payment-received-graph>
        </div>

        <div class="app-recently-added-customer">
            <app-subscriptions-view></app-subscriptions-view>
        </div>
    </div>

    <div class="container">
        <div class="profit-loss-graph">
            <app-profit-loss-chart></app-profit-loss-chart>
        </div>

        <div class="app-recently-added-customer">
            <app-recently-added-customers></app-recently-added-customers>

        </div>
    </div> 
</div>
</body>