import { DatePipe } from '@angular/common';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';

import { ResignationService } from 'src/app/services/resignation/resignation.service';
@Component({
  selector: 'app-employee-resignations',
  templateUrl: './employee-resignations.component.html',
  styleUrls: ['./employee-resignations.component.scss'],
  providers: [DatePipe]
})
export class EmployeeResignationsComponent implements OnInit {
  admin: boolean = false;
  employeeData = [];
  hrColDef: any;
  visibilityTooltip = 'View Resignations';
  selectedEmployeeResignationType: string = 'Employee Resignations';

  constructor(private datePipe: DatePipe, private resignationService: ResignationService, private renderer: Renderer2, private router: Router) {
  }

  hrTableCol = [
    { field: "id", width: 120, unSortIcon: true, floatingFilter: true },
    { field: "employeeId", floatingFilter: true, unSortIcon: true, width: 190 },
    { field: "firstName", floatingFilter: true, unSortIcon: true, width: 190 },
    { field: "lastName", floatingFilter: true, unSortIcon: true, width: 190 },
    { field: "submissionDate", floatingFilter: true, unSortIcon: true, width: 190 },
    { field: "reason", floatingFilter: true, unSortIcon: true, width: 250 },
    { field: "status", floatingFilter: true, unSortIcon: true, width: 250 },
    { field: "Action", cellRenderer: this.actionCellRenderer.bind(this), width: 230 }
  ];

  ngOnInit() {
    this.hrColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    if (sessionStorage.getItem('role') === 'admin') {
      this.admin = true;
      this.selectedEmployeeResignationType = 'employeeAppliedResignation';
      this.getEmployeeList();
      this.getAllEmployeeResignation();
    } else {
      this.selectedEmployeeResignationType = 'employeeAppliedResignation';
      this.getEmployeeList();
      this.getAllEmployeeResignationUnderManager();
    }

  }
  goToDashboard() {
    if (sessionStorage.getItem(AppConstant.MANAGER_SIDENAV) === AppConstant.EMPLOYEE_ROLE) {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.DASHBOARD])
      sessionStorage.setItem(AppConstant.SHOW_EMPLOYEE_LEAVE, 'false');
      sessionStorage.removeItem(AppConstant.IS_MANAGER);

    }
    else {
      this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.DASHBOARD])
    }
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void, tooltipText: string) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      if (icon === 'delete') {
        this.renderer.setStyle(iconElement, 'margin-right', '15px');
      }
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setAttribute(button, 'MatTooltipModule', tooltipText);
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };
    const viewResignationClickHandler = () => {
      const match = params.data.employeeId.match(/EMP(\d+)/);
      if (match) {
        const employeeId = parseInt(match[1]);
        if (!isNaN(employeeId)) {
          if (sessionStorage.getItem('role') == 'admin') {
            this.router.navigate([
              `${AppConstant.MANAGER_SIDENAV}/${AppConstant.HR_VIEW_EMPLOYEE_RESIGNATION_DETAILS}/${employeeId}`
            ]);
          } else {
            this.router.navigate([
              `${AppConstant.MANAGER_SIDENAV}/${AppConstant.VIEW_EMPLOYEE_RESIGNATION_DETAILS}/${employeeId}`
            ]);
          }
        }
      }
    };

    createButton('visibility', 'green', viewResignationClickHandler, this.visibilityTooltip);
    return cell;
  }

  getAllEmployeeResignation() {
    this.resignationService.getEmployeeResignationList(this.selectedEmployeeResignationType, sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        this.employeeData = data.data.map((employee: any) => {
          return {
            id: employee.id,
            firstName: employee.firstName,
            lastName: employee.lastName,
            reason: employee.reason,
            status: employee.status,
            submissionDate: this?.formatDate(employee.submissionDate),
            employeeId: this.formatEmployeeId(employee.employeeId)
          };
        });
      })
  }

  getAllEmployeeResignationUnderManager() {
    this.resignationService.getEmployeeResignationListUnderManager(this.selectedEmployeeResignationType, sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
      (data: any) => {
        this.employeeData = data.data.map((employee: any) => {
          return {
            id: employee.id,
            firstName: employee.firstName,
            lastName: employee.lastName,
            reason: employee.reason,
            status: employee.status,
            submissionDate: this?.formatDate(employee.submissionDate),
            employeeId: this.formatEmployeeId(employee.employeeId)
          };
        });
      })
  }

  getEmployeeList() {
    this.resignationService.getAllEmployeeResignationList(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
      (data: any) => {
        this.employeeData = data.data.map((employee: any) => {
          return {
            id: employee.id,
            firstName: employee.firstName,
            lastName: employee.lastName,
            reason: employee.reason,
            status: employee.status,
            submissionDate: this?.formatDate(employee.submissionDate),
            employeeId: this.formatEmployeeId(employee.employeeId)
          };
        });
      }
    );
  }
  formatDate(date: string): string {
    const parsedDate = new Date(date);
    return this.datePipe.transform(parsedDate, 'dd-MM-yyyy') || date;
  }

  formatEmployeeId(id: number): string {
    let paddedId: string;

    if (id < 10) {
      paddedId = '00' + id.toString();
    } else if (id < 100) {
      paddedId = '00' + id.toString();
    } else {
      paddedId = id.toString();
    }

    return 'EMP' + paddedId;
  }
}