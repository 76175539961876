<div class="main-container">
    <div class="page-heading">
        <mat-icon *ngIf="viewGroup || createPayRunGroup" svgIcon="arrow-back-double" (click)="goBack()"
            class="rewind"></mat-icon>
        <h3>{{'pay_run_group'|translate}}</h3>
    </div>
    <div class="createPayRun">
        <button (click)="createPayRun()">{{'create_group' | translate}}</button>
    </div>
    <mat-divider id="mat-divider"></mat-divider>
    <div *ngIf="!createPayRunGroup">
        <ag-grid-angular *ngIf="!viewGroup" (gridReady)="onGridReady($event)"
            style="width: 100%; margin-top: 20px; height: 25rem;" class="ag-theme-alpine" [rowData]="data"
            [enableSorting]="true" [enableFilter]="true" [columnDefs]="payRunGroupList" [pagination]="true"
            [paginationPageSize]="10">
        </ag-grid-angular>
        <ag-grid-angular *ngIf="viewGroup" (gridReady)="onGridReady($event)"
            style="width: 100%; margin-top: 20px; height: 25rem" class="ag-theme-alpine" [rowData]="employeeData"
            [defaultColDef]="employeeColDef" [enableSorting]="true" [enableFilter]="true" [columnDefs]="columnDefs"
            [pagination]="true" [paginationPageSize]="10">
        </ag-grid-angular>
    </div>
    <div *ngIf="createPayRunGroup">
        <div>
            <div class="title">
                <i class="fa fa-info-circle"></i>
                {{ 'Pay_Runs' | translate }}
            </div>
            <form [formGroup]="payRun" class="form">
                <mat-form-field appearance="outline" class="form-field">
                    <mat-label>{{ 'payrun_name' | translate }}</mat-label>
                    <input matInput formControlName="payrunName" name="categoryName"
                        (keypress)="characterNumberAndSpaceAllowed($event)" required />
                    <mat-error *ngIf="payRun.get('payrunName')?.hasError('required')">{{ 'enter_payrun_name' |
                        translate
                        }}</mat-error>
                </mat-form-field>
                <button class="button-right" color="primary" (click)="createPayRunApi()">{{
                    'create_payrun' | translate }}</button>
            </form>
        </div>
        <div class="dual-select">
            <div class="dual-select__left">
                <ul class="dual-select__items">
                    <div class="table-head">
                        <input class="table-head-input" type="checkbox" [(ngModel)]="selectAllUnselectedChecked"
                            (change)="selectAllUnselected($event)" />
                        <div class="table-head-id">{{'id'|translate}}</div>
                        <div class="table-head-name">{{'name'|translate}}</div>
                        <div class="table-head-mail">{{'work_email'|translate}}</div>
                    </div>
                    <li *ngFor="let employee of unselected" (change)="togglePendingSelection(employee, $event)"
                        (dblclick)="addToSelected(employee)" class="dual-select__item"
                        [class.dual-select__item--selected]="pendingSelection[employee]">
                        <mat-divider></mat-divider>

                        <div class="employee">
                            <input class="table-head-input" type="checkbox"
                                (change)="togglePendingSelection(employee, $event)"
                                [checked]="pendingSelection[employee.id]" />
                            <div class="table-head-id">{{employee.id}}</div>
                            <div class="table-head-name">{{employee.firstName}} {{employee.lastName}}</div>
                            <div class="table-head-mail">{{employee.workEmail}}</div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="dual-select__controls">
                <button (click)="addToSelected()" class="dual-select__control">&#10503;</button>
                <button (click)="removeFromSelectedContacts()" class="dual-select__control">&#10502;</button>
            </div>
            <div class="dual-select__right">
                <ul class="dual-select__items">
                    <div class="table-head">
                        <input class="table-head-input" type="checkbox" [(ngModel)]="selectAllSelectedChecked"
                            (change)="selectAllSelected($event)" />
                        <div class="table-head-id">{{'id'|translate}}</div>
                        <div class="table-head-name">{{'name'|translate}}</div>
                        <div class="table-head-mail">{{'work_email'|translate}}</div>
                    </div>
                    <mat-divider></mat-divider>
                    <li *ngFor="let employee of selected" (dblclick)="removeFromSelectedContacts(employee)"
                        class="dual-select__item" [class.dual-select__item--selected]="pendingSelection[employee]">
                        <div class="employee">
                            <input class="table-head-input" type="checkbox"
                                (change)="togglePendingSelection(employee, $event)"
                                [checked]="pendingSelection[employee.id]" />
                            <div class="table-head-id">{{employee.id}}</div>
                            <div class="table-head-name">{{employee.firstName}} {{employee.lastName}}</div>
                            <div class="table-head-mail">{{employee.workEmail}}</div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>