import { Component } from '@angular/core';

@Component({
  selector: 'app-grid-cell',
  templateUrl: './grid-cell.component.html',
  styleUrls: ['./grid-cell.component.scss']
})
export class GridCellComponent {
  data: any;
  params: any;
  isCurrentRowEditing: boolean = false;

  constructor() { }

  agInit(params: any) {
    this.params = params;
    this.data = params.Value;
    let editingCells = params.api.getEditingCells();
    this.isCurrentRowEditing = editingCells.some((cell: any) => {
      return cell.rowIndex === params.node.rowIndex;
    });
  }

  cancelUpdate() {
    this.params.context.componentParent.cancelUpdate();
  }

}