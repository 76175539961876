<div *ngIf="rowData.length > 0">
    <div class="last-year-summary">
        <div class="last-year-summary-table">
            <div class="last-year-summary-table-row-header">
                <span class="section-name-heading">{{ 'last_financial_year_summary' | translate }}</span>
                <span>{{ 'maximum_amount' | translate }}</span>
                <span>{{ 'declared_amount' | translate }}</span>
                <span>{{ 'approved_amount' | translate }}</span>
            </div>
            <mat-divider></mat-divider>
            <mat-accordion>
                <mat-expansion-panel *ngFor="let item of rowData">
                    <mat-expansion-panel-header>
                        <div class="last-year-summary-table-row">
                            <span *ngIf="item.name === 'house rent details'" class="section-heading">
                                {{ convertLabel(item.name) }}
                            </span>
                            <span *ngIf="item.name !== 'house rent details'" class="section-heading">
                                {{ item.name }}
                            </span>
                            <span class="attachment-window">{{ item.amount | number: '1.2-2' }}</span>
                            <span class="attachment-window" *ngIf="item.name === 'house rent details'">
                                {{ item.amount | number: '1.2-2' }}
                            </span>
                            <span class="attachment-window" *ngIf="item.name !== 'house rent details'">
                                {{ item.updatedAmount | number: '1.2-2' }}
                            </span>
                            <span class="attachment-window">
                                {{ item.approvedAmount | number: '1.2-2' }}
                            </span>
                        </div>
                    </mat-expansion-panel-header>
                    <ng-container *ngIf="item.name === 'house rent details'; else otherDetails">
                        <div class="house-rent-details">
                            <div class="detail-row">
                                <strong class="detail-label">{{ 'from_date' | translate }} :</strong>
                                <span class="detail-value">{{ houseRentDetails.fromDate | date }}</span>
                            </div>
                            <div class="detail-row">
                                <strong class="detail-label">{{ 'to_date' | translate }} :</strong>
                                <span class="detail-value">{{ houseRentDetails.toDate | date }}</span>
                            </div>
                            <div class="detail-row">
                                <strong class="detail-label">{{ 'city' | translate }} :</strong>
                                <span class="detail-value">{{ houseRentDetails.city }}</span>
                            </div>
                            <div class="detail-row">
                                <strong class="detail-label">{{ 'landlord_name' | translate }} :</strong>
                                <span class="detail-value">{{ houseRentDetails.landlordName }}</span>
                            </div>
                            <div class="detail-row">
                                <strong class="detail-label">{{ 'landLord_pan' | translate }} :</strong>
                                <span class="detail-value">{{ houseRentDetails.landLordPan }}</span>
                            </div>
                            <div class="detail-row">
                                <strong class="detail-label">{{ 'rented_house_address' | translate }} :</strong>
                                <span class="detail-value">{{ houseRentDetails.rentedHouseAddress }}</span>
                            </div>
                            <div class="detail-row">
                                <strong class="detail-label">{{ 'rent_per_month_declared' | translate }} :</strong>
                                <span class="detail-value">{{ houseRentDetails.rentPerMonthDeclared | number: '1.2-2'
                                    }}</span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #otherDetails>
                        <div class="sub-table-row-header">
                            <span class="section-heading">{{ 'last_financial_year_summary' | translate }}</span>
                            <span class="attachment-window">{{ 'maximum_amount' | translate }}</span>
                            <span class="attachment-window">{{ 'declared_amount' | translate }}</span>
                            <!-- <span class="attachment-window" *ngIf="item.approvedAmount > 0.0">{{ 'approved_amount' |
                                translate }}</span> -->
                            <span class="attachment-window" *ngIf="submitionWindow">{{ 'attachment' | translate
                                }}</span>
                            <span class="attachment-window" *ngIf="submitionWindow">{{ 'comment' | translate }}</span>
                        </div>
                        <div *ngFor="let subItem of item.data">
                            <div class="last-year-summary-table-data-row">
                                <span class="section-heading">{{ convertLabel(subItem.name) }}</span>
                                <span class="attachment-window">{{ subItem.amount | number: '1.2-2' }}</span>
                                <span class="attachment-window">{{ subItem.updatedAmount | number: '1.2-2' }}</span>
                                <!-- <span class="attachment-window" *ngIf="subItem.approvedAmount != null">{{
                                    subItem.approvedAmount | number: '1.2-2'
                                    }}</span> -->
                                <div class="attachment-div attachment-window" *ngIf="submitionWindow">
                                    <div class="attachment-button-div">
                                        <input type="file" #fileInput style="display: none"
                                            (change)="onFileSelected($event, item, subItem)">
                                        <button class="attachment-button" (click)="fileInput.click()">
                                            <mat-icon>attachment</mat-icon>
                                        </button>
                                    </div>
                                    <div *ngIf="fileErrors[item.name + '-' + subItem.name]; else showFileName">
                                        <span class="attachment-error">{{ fileErrors[item.name + '-' +
                                            subItem.name] }}</span>
                                    </div>
                                    <ng-template #showFileName>
                                        <span *ngIf="selectedFiles[item.name + '-' + subItem.name]">
                                            {{ selectedFiles[item.name + '-' + subItem.name] }}
                                        </span>
                                    </ng-template>
                                </div>
                                <div class="attachment-window" *ngIf="submitionWindow">
                                    <input type="text" class="declaration-amount-input" maxlength="50"
                                        [(ngModel)]="comments[item.name + '-' + subItem.name]"
                                        placeholder="Add comment" />
                                    <mat-error
                                        *ngIf="subItem.approvedAmountError && subItem.approvedAmountError !== null">
                                        {{subItem.approvedAmountError | translate }}
                                    </mat-error>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                        </div>
                    </ng-template>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
</div>

<div class="button-div">
    <button class="cancel-btn" *ngIf="rowData.length > 0" type="submit" (click)="deleteDeclaration()">
        {{'delete_declaration' | translate }}
    </button>
    <button *ngIf="rowData.length > 0 && isAfter15th() && !submitionWindow" type="submit" (click)="goToFormPage()">
        {{ 'update_declaration' | translate }}
    </button>
    <button *ngIf="rowData.length > 0 && isAfter15th() && submitionWindow" type="submit" (click)="submitPOI()">
        {{ 'submit_poi' | translate }}
    </button>
    <button *ngIf="rowData.length === 0 && isAfter15th()" type="submit" (click)="goToFormPage()">
        {{ 'add_declaration' | translate }}
    </button>
</div>