<mat-tab-group>
  <mat-tab *ngFor="let label of pieChartLabels; let i = index" [label]="label">
    <div class="pieChart">
      <div>
        <div *ngIf="pieChartData && pieChartData.length > 0 && pieChartData[i]?.hasData">
          <canvas baseChart [datasets]="[{ data: pieChartData[i].data, label: pieChartData[i].label }]" [labels]="pieChartLabels" [options]="pieChartOptions" [type]="pieChartType">
          </canvas>
          <h5 class="dashboard-card-content" (click)="viewPaySlip(pieChartLabels[i])">{{ 'view_payslips' | translate }} <i class="fa fa-arrow-right"></i></h5>
        </div>
        <div *ngIf="pieChartData && pieChartData.length > 0 && !pieChartData[i]?.hasData">
          <p>{{'employee_salary_details_not_found'| translate}}</p>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>