import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { AppConstant } from 'src/app/constants/app.constants';
import { Subscription } from 'src/app/model/admin-model/subscription.model';
import { environment } from 'src/environments/enviroment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private http: HttpClient) { }

  getSubscription(filter: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.SUBSCRIPTION + filter).pipe(
      map((res: any) => {
        return res;
      })
    )
  }


  createSubscription(subscription: Subscription): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.CREATE_SUBSCRIPTION, subscription).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getSubscriptionAccess(){
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_SUBSCRIPTION_DETAILS + sessionStorage.getItem(AppConstant.COMPANY_ID) ).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

}
