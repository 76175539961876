import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EventManagementService } from 'src/app/services/event-management/event-management.service';
import { FormatUtil } from 'src/app/utils/format.utils';
import { saveAs } from 'file-saver';
import { AppConstant } from 'src/app/constants/app.constants';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss']
})

export class AddEventComponent implements OnInit {
  addEvents!: FormGroup;
  selectedFile!: File;
  readonly maxFileSize = 3 * 1024 * 1024;
  currentDate!: Date;
  endOfFinancialYear!: Date;
  eventEndDateMin!: Date;
  eventCategories: any[] = [];
  callingType!: string;
  eventDto?: any;

  constructor(private formBuilder: FormBuilder, private eventManagementService: EventManagementService,
    private toasterService: ToastrService, private matDialog: MatDialog, public dialogRef: MatDialogRef<AddEventComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private translateService: TranslateService) {
    //collecting data from calling component
    this.callingType = data.callingType;
    this.eventDto = data.eventDto;

    //initializing date range 
    this.currentDate = new Date();

    this.endOfFinancialYear = new Date();
    this.endOfFinancialYear.setDate(31);
    this.endOfFinancialYear.setMonth(11);
    this.eventEndDateMin = this.currentDate;
  }

  ngOnInit(): void {
    this.addEvents = this.formBuilder.group({
      eventName: ['', [Validators.required]],
      eventStartDate: ['', [Validators.required]],
      eventEndDate: ['', [Validators.required]],
      eventType: ['', [Validators.required]],
      description: ['']
    });

    //initializaing form for update
    if (this.eventDto != null) {
      this.addEvents.patchValue({
        eventName: this.eventDto?.eventName,
        eventStartDate: this.eventDto?.eventStartDate,
        eventEndDate: this.eventDto?.eventEndDate,
        eventType: this.eventDto?.eventType,
        description: this.eventDto?.description
      });
    }

    //get event categories
    this.eventManagementService.getEventCategories().subscribe((data: any) => {
      if (data.status == 200) {
        if (data.data && data.data.length > 0) {
          this.eventCategories = data.data;
        }
      }
    });
  }

  updateEndDateMin() {
    this.eventEndDateMin = this.addEvents.get('eventStartDate')?.value
  }

  downloadSampleEventExcel() {
    this.eventManagementService.getUploadBulkEvent().subscribe((data: Blob) => {
      const downloadLink = document.createElement('a');
      const url = window.URL.createObjectURL(data);
      downloadLink.href = url;
      downloadLink.download = this.translateService.instant('allEventUpload_file_name');
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(url);
    });
  }

  addEvent() {
    let eventStartDate: any;
    if (typeof (this.addEvents.value.eventStartDate) == 'string') {
      eventStartDate = FormatUtil.adjustDateForTimeOffset(new Date(this.addEvents.value.eventStartDate));
    } else {
      eventStartDate = FormatUtil.adjustDateForTimeOffset(this.addEvents.value.eventStartDate);
    }

    let eventEndDate: any;
    if (typeof (this.addEvents.value.eventEndDate) == 'string') {
      eventEndDate = FormatUtil.adjustDateForTimeOffset(new Date(this.addEvents.value.eventEndDate));
    } else {
      eventEndDate = FormatUtil.adjustDateForTimeOffset(this.addEvents.value.eventEndDate);
    }

    const dto = {
      eventName: this.addEvents.value.eventName,
      eventStartDate: eventStartDate,
      eventEndDate: eventEndDate,
      eventType: this.addEvents.value.eventType,
      description: this.addEvents.value.description,
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID)
    };
    this.eventManagementService.addEvent(dto).subscribe((data: any) => {
      this.handleAddUpdateEventResponse(data);
    });
  }

  updateEvent() {
    let eventStartDate: any;
    if (typeof (this.addEvents.value.eventStartDate) == 'string') {
      eventStartDate = FormatUtil.adjustDateForTimeOffset(new Date(this.addEvents.value.eventStartDate));
    } else {
      eventStartDate = FormatUtil.adjustDateForTimeOffset(this.addEvents.value.eventStartDate);
    }

    let eventEndDate: any;
    if (typeof (this.addEvents.value.eventEndDate) == 'string') {
      eventEndDate = FormatUtil.adjustDateForTimeOffset(new Date(this.addEvents.value.eventEndDate));
    } else {
      eventEndDate = FormatUtil.adjustDateForTimeOffset(this.addEvents.value.eventEndDate);
    }

    const dto = {
      eventId: this.eventDto?.eventId,
      eventName: this.addEvents.value.eventName,
      eventStartDate: eventStartDate,
      eventEndDate: eventEndDate,
      eventType: this.addEvents.value.eventType,
      description: this.addEvents.value.description,
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID)
    };
    this.eventManagementService.updateEvent(dto).subscribe((data: any) => {
      this.handleAddUpdateEventResponse(data);
    });
  }

  handleAddUpdateEventResponse(data: any) {
    if (data.status == 200) {
      this.closeDialog();
      this.toasterService.clear();
      this.toasterService.success(data.message);
    }
    else {
      this.toasterService.clear();
      this.toasterService.error(data.message);
    }
  }

  onEventFileUpload(event: any) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      if (file.size <= this.maxFileSize && this.isfileTypeValid(file) && file.name.length <= 25) {
        this.selectedFile = file;
        this.addEvents.get('addEventFile')?.setErrors({ null: true });
      }
      if (file.size > this.maxFileSize) {
        this.addEvents.get('addEventFile')?.setErrors({ fileSizeExceeded: true });
      }
      if (!this.isfileTypeValid(file)) {
        this.addEvents.get('addEventFile')?.setErrors({ fileTypeInvalid: true });
      }
      if (file.name.length > 25) {
        this.addEvents.get('addEventFile')?.setErrors({ fileNameInvalid: true });
      }
      this.selectedFile = file;

      //allEventFile upload api 
      if (this.selectedFile && this.selectedFile != undefined) {
        const formData = new FormData();
        formData.append('excelFile', this.selectedFile);

        this.eventManagementService.addEventFile(formData).subscribe((data: any) => {
          if (data.status == 200 && data.body && data.body.data && data.body.data !== null) { //error file download
            const excelData = FormatUtil.base64ToArrayBuffer(data.body.data);
            const workbook = XLSX.read(excelData, { type: 'array' });
            const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const excelBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
            saveAs(excelBlob, AppConstant.EVENT_ERROR_EXCEL);
            this.closeDialog();
            this.toasterService.clear();
            this.toasterService.error(data.body.message);
          } else if (data.body) { // all events added successfully
            this.closeDialog();
            this.toasterService.clear();
            this.toasterService.success(data.body.message);
          }
        });
      }
    }
  }

  isfileTypeValid(file: File): boolean {
    const allowedFilesTypes = ['xlsx'];
    const extension = file.name.split('.').pop()?.toLowerCase();
    return !!extension && allowedFilesTypes.includes(extension);
  }

  closeDialog() {
    this.matDialog.closeAll();
  }

  characterNumberAndSpaceAllowed(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }
}


