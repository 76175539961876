import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { LeaveData } from 'src/app/model/leave-application/leave-history.model';
import { Leave } from 'src/app/model/leave-application/leave-stucture.model';
import { environment } from 'src/environments/enviroment';

@Injectable({
  providedIn: 'root'
})
export class LeaveStructureService {
  constructor(private http: HttpClient) { }
  private selectedLeave: LeaveData | null = null;

  setSelectedLeave(leave: LeaveData) {
    this.selectedLeave = leave;
  }

  getSelectedLeave(): LeaveData | null {
    return this.selectedLeave;
  }


  leaveData(companyId: any, leave: Leave[]): Observable<Leave> {
    return this.http.post(environment.apiUrl + API_END_POINTS.LEAVE + companyId, leave).pipe(
      map((res: any) => {
        return res;
      }))
  }

  deleteLeaveType(companyid: any, id: any): Observable<Leave[]> {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETELEAVETYPE + companyid + API_END_POINTS.SEPERATOR + id).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  getAllLeave(id: any): Observable<Leave[]> {
    return this.http.get(environment.apiUrl + API_END_POINTS.LEVAESTRUCTURELIST + id).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

}
