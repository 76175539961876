import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment.dev';
import { API_END_POINTS } from '../config/api-endpoint.config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CompanyBankDetailsService {

  constructor(private http: HttpClient) { }

  getCompanyBankDetails(companyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.COMPANY_BANK_INFO + companyId).pipe(
      map((res: any) => {
        return res;
      }));
  }
}
