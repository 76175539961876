<div class="login-container">
    <div class="login-card">
      <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
        <mat-form-field appearance="outline">
            <mat-label>{{'email' | translate}}</mat-label>
            <input matInput formControlName="email" type="email" required>
            <mat-error *ngIf="loginForm.controls['email'].invalid && loginForm.controls['email'].touched">
              {{'please_enter_valid_email' | translate}}
            </mat-error>
          </mat-form-field>
  
        <mat-form-field appearance="outline">
          <mat-label>{{'password' | translate}}</mat-label>
          <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" required>
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="loginForm.controls['password'].invalid && loginForm.controls['password'].touched">
            {{'enter_your_password' | translate}}
          </mat-error>
        </mat-form-field>

        <!-- <a routerLink="/forgot-password">{{'forgot_password' | translate}}</a> -->
  
        <button mat-raised-button class="login-btn"  type="submit" [disabled]="loginForm.invalid">
          {{'login' | translate}}
        </button>

        <span class="no-account-msg">{{'no_account_contact_msg' | translate}}</span>
      </form>
    </div>
  </div>
  