import { Component } from '@angular/core';

@Component({
  selector: 'app-view-integrated-bank',
  templateUrl: './view-integrated-bank.component.html',
  styleUrls: ['./view-integrated-bank.component.scss']
})
export class ViewIntegratedBankComponent {

}
