import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { RevertExitData } from 'src/app/model/registration.model';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { StateService } from 'src/app/services/exit-process/state.service';
import { ResignationService } from 'src/app/services/resignation/resignation.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-revert-exit-process',
  templateUrl: './revert-exit-process.component.html',
  styleUrls: ['./revert-exit-process.component.scss']
})
export class RevertExitProcessComponent {
  firstName: any;
  lastName: any;
  public revertExitForm !: FormGroup;
  revertExit!: RevertExitData;
  @Output() revertSuccess = new EventEmitter<boolean>();

  constructor(private stateService:StateService, private toastrService: ToastrService, private fb: FormBuilder, private dialogRef: MatDialogRef<RevertExitProcessComponent>, private resignationService: ResignationService, private datePipe: DatePipe, private employeeService: EmployeeDetailsService, private router: Router) { }
  ngOnInit(): void {
    this.getActiveEmployeeDetails();
    this.revertProcess();
  }

  revertProcess() {
    this.revertExitForm = this.fb.group({
      'withdrawReason': ['', [Validators.required]],
    })
  }
  getActiveEmployeeDetails() {
    this.employeeService.getActiveEmployeeDetails(sessionStorage.getItem('companyId'), sessionStorage.getItem('employeeId'))
      .subscribe((data: any) => {
        this.firstName = data?.employeeBasicDetail?.firstName;
        this.lastName = data?.employeeBasicDetail?.lastName;
      });
  }

  closepopup(): void {
    this.dialogRef.close();
  }

  validateCharacter(event: any) {
    return ValidationUtil.characterAndSpaceAllowed(event);
  }

  revertEmployee() {
    if (this.revertExitForm.invalid) {
      this.revertExitForm.markAllAsTouched();
      return;
    }

    let revertProcess: RevertExitData = {
      "companyId": sessionStorage.getItem(AppConstant.COMPANY_ID),
      "withdrawReason": this.revertExitForm.value.withdrawReason,
      "employeeId": sessionStorage.getItem(AppConstant.EMPLOYEE_ID)
    };

    this.resignationService.exitProcess(revertProcess).subscribe((res: any) => {
      if (res.status === 200) {
        this.stateService.setApplyResignation(true);  
        this.toastrService.clear();
        this.toastrService.success(res.message);
        this.revertSuccess.emit(true);
        this.dialogRef.close();
      } else {
        this.toastrService.clear();
        this.toastrService.error(res.message);
      }
    });
  }
}
