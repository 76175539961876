import { Component, OnInit } from '@angular/core';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { AppConstant } from 'src/app/constants/app.constants';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';

interface CustomChartDataset extends ChartDataset<'doughnut', (number | null)[]> {
  hasData?: boolean;
  netPay?: number;
  totalDeduction?: number;
}

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit {
  blob: Blob | undefined;

  pieChartData: CustomChartDataset[] = [];
  pieChartOptions: ChartOptions = {
    responsive: true,
  };
  pieChartLabels: string[] = [];
  pieChartType: ChartType = 'doughnut';

  constructor(private empDetailsService: EmployeeDetailsService) { }


  ngOnInit() {
    this.getEmployeeSalaryData();
  }

  getEmployeeSalaryData() {
    this.empDetailsService
      .getEmployeeLastThreeMonthData(
        sessionStorage.getItem(AppConstant.COMPANY_ID),
        sessionStorage.getItem(AppConstant.EMPLOYEE_ID)
      )
      .subscribe(
        (response) => {
          this.processSalaryData(response);
        }
      );
  }

  processSalaryData(salaryData: any) {
    this.pieChartData = [];
    this.pieChartLabels = [];

    if (salaryData) {
      for (const month in salaryData) {
        if (Object.prototype.hasOwnProperty.call(salaryData, month)) {
          const monthYear = month.split(' ');
          const formattedMonth = this.formatMonthOrGetMonthNumber(monthYear[0], true) + ' ' + monthYear[1];
          this.pieChartLabels.push(formattedMonth);
          this.pieChartData.push({
            data: [],
            label: formattedMonth,
            hasData: salaryData[month].length > 0,
            netPay: salaryData[month][0]?.netPay || 0,
            totalDeduction: salaryData[month][0]?.totalDeduction || 0,
          });
        }
      }
    }
  }

  viewPaySlip(selectedMonth: string) {
    const year = selectedMonth.split(' ')[1];
    const month = this.formatMonthOrGetMonthNumber(selectedMonth.split(' ')[0], false);
    this.empDetailsService.downloadSalarySlip(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID), year, month).subscribe((data: Blob) => {
      this.blob = new Blob([data], { type: 'application/pdf' });
      const pdfUrl = window.URL.createObjectURL(this.blob);
      window.open(pdfUrl, '_blank');
    });
  }

  formatMonthOrGetMonthNumber(month: string, isFormat: boolean): string | number {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthIndex = parseInt(month) - 1;
    return isFormat ? months[monthIndex] : monthIndex + 1;
  }
}
