<app-navbar class="small_screen-visibility"></app-navbar>

<div class="login_registration_container">
  <mat-card>
    <mat-card-content>
      <div class="mat-mdc-card-content--page">
        <div class="heading">
          <h1>{{'lets_you_signin' | translate}}</h1>
          <span>{{'unlock_your_world_with_one_click' | translate}}</span>
        </div>
      </div>

      <form name="loginForm" [formGroup]="createLoginForm">
        <div>
          <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{ 'email' | translate }}</mat-label>
            <input matInput type="text" class="form-input" formControlName="email" required />
            <mat-error *ngIf="createLoginForm.controls['email'].hasError('required')">
              {{ 'please_enter_email' | translate }}
            </mat-error>
            <mat-error *ngIf="createLoginForm.controls['email'].hasError('invalidDomainInput')">
              {{'invalid_domain_email'|translate}}
            </mat-error>
            <mat-error
              *ngIf="createLoginForm.controls['email'].hasError('email') && !createLoginForm.controls['email'].hasError('required')">
              {{ 'invalid_email_format' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="forgot_password links">
          <a class="text-link" class="aLink" routerLink="/forgot-password">{{'forgot_password'|translate}}</a>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>{{ 'password' | translate }}</mat-label>
            <input class="form-input" type="{{ hidePasswordInitially ? 'password' : 'text' }}"
              formControlName="password" matInput required (input)="passwordTyped = true" #passwordInput
              (keydown.enter)="loginButton.focus()" appBlockCopyPaste />
            <button mat-icon-button matSuffix (click)="togglePasswordVisibility()"
              [attr.aria-label]="hidePasswordInitially ? 'Show Password' : 'Hide Password'">
              <mat-icon class="eyeIcon">
                {{ hidePasswordInitially ? 'visibility_off' : 'visibility' }}
              </mat-icon>
            </button>
            <mat-error *ngIf="createLoginForm.controls['password'].hasError('required')">
              {{ 'password_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="createLoginForm.controls['password'].hasError('pattern')">
              {{ 'strong_password' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div>
          <span class="links">{{'dont_have_payroll_account'|translate}}
            <a routerLink='/{{registrationLink}}'>{{'register'|translate}}</a>
          </span>
          <button class="button-right" (click)="onLogin()" color="primary" type="submit" #loginButton>{{'signin' |
            translate}}
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
  <div class="image-div">
    <img src="../../../assets/images/pre-login/loginBG.png" class="img-body" alt="side-image">
  </div>
</div>

<div class="copyright">
  <p>&copy; {{'footer' | translate}}</p>
</div>

<!-- popup -->
<div *ngIf="showLoginPopup" class="overlay" (click)="toggleLoginPopup()"></div>
<div class="add-login-popup" *ngIf="showLoginPopup">
  <button mat-icon-button (click)="toggleLoginPopup()" class="closeDialog">
    <mat-icon>close</mat-icon>
  </button>
  <div class="container">
    <p> {{ 'Info' | translate }} </p>
  </div>
  <div class="loginButton">
    <button class="button" (click)="adminLogin()">{{ 'Admin' | translate }}
    </button>
    <button class="button" (click)="employeeLogin()">{{ 'Employee' | translate }}
    </button>
  </div>
</div>

<app-loader></app-loader>