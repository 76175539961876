import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { AppConstant } from 'src/app/constants/app.constants';
import { PayNowList, PayRunGroupDto } from 'src/app/model/payrun/payrun.model';
import { environment } from 'src/environments/enviroment';

@Injectable({
  providedIn: 'root'
})
export class PayRunService {

  constructor(private http: HttpClient) { }

  getFuturePayRun(companyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_FUTURE_PAYRUN + companyId + AppConstant.SEPERATOR + sessionStorage.getItem(AppConstant.ADMIN_ID)).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  getPayrollHistory(companyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_PAST_PAYRUN + companyId).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  payNow(dto: PayNowList): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.PAY_NOW, dto).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  getPayRunExcel(companyId: any, payRunId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.PAY_NOW_EXCEL + companyId + AppConstant.SEPERATOR + payRunId).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  getPayRunCostSummary(companyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.PAY_RUN_COST_SUMMARY + companyId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  groupByPayRun(dto: PayRunGroupDto): Observable<PayRunGroupDto> {
    return this.http.post(environment.apiUrl + API_END_POINTS.GROUP_BY_PAY_RUN, dto).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getPendingPayRunDetailList() {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_PENDING_PAY_RUN + sessionStorage.getItem(AppConstant.COMPANY_ID)).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  deletGroupByGroupId(groupId: any) {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_PAY_RUN_GROUP + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + groupId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  deleteEmployeeFromGroup(employeeId: any) {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_EMPLOYEE_FROM_GROUP + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + employeeId).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  getPayRollEmployee(payRunId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.PAYRUN_GROUP_EMPLOYEE_LIST + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + payRunId).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  getPayRunApprovalStatus(payRunId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_PAY_RUN_APPROVAL_STATUS + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + payRunId).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  onDownload(dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.DOWNLOAD_PAYRUN_EXCEL, dto).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

}
