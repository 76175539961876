import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-select-regime-dialog',
  templateUrl: './select-regime-dialog.component.html',
  styleUrls: ['./select-regime-dialog.component.scss']
})
export class SelectRegimeDialogComponent {
  constructor(private dialogRef: MatDialogRef<SelectRegimeDialogComponent>) { }

  onSelect(regime: string): void {
    this.dialogRef.close(regime);
  }
}
