import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, filter, map, Subject, takeUntil, tap } from 'rxjs';
import { AppConstant } from 'src/app/constants/app.constants';
import { Messages } from 'src/app/constants/messages.constant';
import { ApprovalService } from 'src/app/services/approval/approval.service';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { ProjectManagementService } from 'src/app/services/project-management/project-management.service';
import { TaskManagementService } from 'src/app/services/task-management/task-management.service';
import { ValidationUtil } from 'src/app/utils/validators.util';
@Component({
  selector: 'app-view-task-details',
  templateUrl: './view-task-details.component.html',
  styleUrls: ['./view-task-details.component.scss']
})
export class ViewTaskDetailsComponent {
  @ViewChild('select') select: MatSelect | any;
  @Output() taskUpdated = new EventEmitter<boolean>();
  taskId = sessionStorage.getItem(AppConstant.TASK_ID);
  dashboardId = sessionStorage.getItem(AppConstant.DASHBOARD_ID);
  updateTaskForm!: FormGroup;
  projectCodeOption: any[] = [];
  taskStatus = Messages.TASK_STATUS;
  search: any;
  employeeList: any[] = [];
  issueList: any[] = [];
  sprintList: any[] = [];
  projectId: any;
  filteredServerSide: Subject<any[]> = new Subject<any[]>();
  searching: boolean = false;
  private _onDestroy = new Subject<void>();
  personSearchFilter = this.fb.control('');
  selectedEmployeeType: string = 'allEmployee';
  noRecordsFound: boolean = false;
  documentUrls: any;
  documentsNameList: any[] = [];
  fileSizeError: boolean = false;
  showDocumentError: boolean = false;
  selectedFiles: File[] = [];
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);

  constructor(
    private formBuilder: FormBuilder, private router: Router, private fb: FormBuilder,
    private toastrService: ToastrService, private employeeService: EmployeeDetailsService,
    private projectService: ProjectManagementService,
    private approvalService: ApprovalService,
    private taskService: TaskManagementService
  ) { }

  ngOnInit(): void {
    this.initTaskDetails();
    this.getAllSprintList();
    Promise.all([
      this.getProjectList(),
      this.getEmployeeList(),
      this.getAllIssueList(),
      this.initEmployee()
    ]).then(() => {
      this.getTaskDetails();
    });
  }

  initTaskDetails() {
    this.updateTaskForm = this.formBuilder.group({
      taskName: ['', [Validators.required, ValidationUtil.noOnlySpacesValidator()]],
      projectId: ['', [Validators.required]],
      status: ['', [Validators.required]],
      summary: ['', [Validators.required, ValidationUtil.noOnlySpacesValidator()]],
      description: ['', [ValidationUtil.noOnlySpacesValidator()]],
      taskPointEstimate: ['', [ValidationUtil.greaterThanZeroValidator(),Validators.max(99),Validators.maxLength(2)]],
      assigneeTo: [''],
      parentTaskId: ['',],
      sprintId: [''],
      reporterId: ['', [Validators.required]],
      linkedTasks: [''],
      parentId: ['']  
    });
  }

  getProjectList() {
    this.projectService.getProjectList().subscribe(
      (projectList: any) => {
        if (projectList.status === 200) {
          this.projectCodeOption = projectList.data;
        }
      }
    );
  }

  characterNumberAndSpaceAndHyphenAllowed(event: any) {
    return ValidationUtil.characterNumberAndSpaceAndHyphenAllowed(event)
  }


  initEmployee() {
    this.getEmployeeList();
    const companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
    this.personSearchFilter.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => {
          this.searching = true;
          this.noRecordsFound = false;
        }),
        takeUntil(this._onDestroy),
        debounceTime(500),
        map((search: any) => {
          this.search = search.toLowerCase();
          if (!this.employeeList) {
            return [];
          }
          return this.employeeList.filter(employee =>
            (employee.firstName.toLowerCase() + AppConstant.SPACE + employee.lastName.toLowerCase()).indexOf(this.search) > -1
          );
        }),
        takeUntil(this._onDestroy)
      )
      .subscribe(filteredEmployees => {
        if (filteredEmployees.length !== 0) {
          this.searching = false;
          this.noRecordsFound = false;
          this.filteredServerSide.next(filteredEmployees);
        } else {
          this.approvalService.searchEmployee(companyId, this.search).subscribe((res: any) => {
            if (res.status === 200 && res.data && res.data.length > 0) {
              this.searching = false;
              this.noRecordsFound = false;
              this.filteredServerSide.next(res.data);
            } else {
              this.searching = false;
              this.noRecordsFound = true;
              this.filteredServerSide.next([]);
            }
          });
        }
      }, error => {
        this.searching = false;
        this.noRecordsFound = true;
        this.filteredServerSide.next([]);
      });
  }

  getEmployeeList() {
    const companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
    this.employeeService.getEmployeeList(this.selectedEmployeeType, companyId).subscribe(
      (employeeDetails: any) => {
        if (employeeDetails.status === 200) {
          this.employeeList = employeeDetails.data;
          this.filteredServerSide.next(this.employeeList);
        }
      }
    );
  }

  onProjectChange(event: any): void {
    this.projectId = event.value;
    this.getAllIssueList();
    this.getAllSprintList();
  }

  getAllIssueList(): void {
    if (this.projectId) {
      this.projectService.getIssueList(this.projectId).subscribe(
        (taskData: any) => {
          if (taskData.status === 200) {
            this.issueList = taskData.data;
          }
        });
    }
  }

  getAllSprintList() {
    this.projectService.getAllSprintList(this.projectId).subscribe((sprintList: any) => {
      if (sprintList.status === 200) {
        this.sprintList = sprintList.data;
      }
    })
  }

  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumber(event);
  }

  onChangeFileInput(event: Event): void {
    this.showDocumentError = true;
    this.fileSizeError = false;
    const input = event.target as HTMLInputElement;
    if (input.files) {
      for (let fileSize = 0; fileSize < input.files.length; fileSize++) {
        const file = input.files[fileSize];
        if (file.size > AppConstant.MAX_FILE_SIZE) {
          this.fileSizeError = true;
        } else if (!this.isFileSelected(file)) {
          this.selectedFiles.push(file);
        }
      }
    }
  }

  isFileSelected(file: File): boolean {
    return this.selectedFiles.some(selectedFile => selectedFile.name === file.name);
  }

  removeFile(value: File) {
    const index = this.selectedFiles.findIndex((res: any) => res.name === value.name);
    if (index !== -1) {
      this.selectedFiles.splice(index, 1);
    }
  }

  updateTask() {
    const formData = new FormData();
    const formValue = this.updateTaskForm.value;
    const createTaskDto = {
      taskId: this.taskId,
      dashboardId: this.dashboardId,
      companyId: this.companyId,
      projectId: formValue.projectId,
      status: formValue.status,
      summary: formValue.summary,
      description: formValue.description,
      assigneeTo: formValue.assigneeTo,
      parentTaskId: formValue.parentTaskId,
      sprintId: formValue.sprintId,
      taskPointEstimate: formValue.taskPointEstimate,
      reporterId: formValue.reporterId,
      linkedTasks: formValue.linkedTasks ? formValue.linkedTasks.split(',').map((id: string) => parseInt(id, 10)) : []
    };
    formData.append(AppConstant.CREATE_TASK, JSON.stringify(createTaskDto));
    this.selectedFiles.forEach((file) => {
      formData.append(AppConstant.ATTACHMNETS, file);
    });

    this.projectService.updateTask(formData).subscribe(
      (addTask: any) => {
        if (addTask.status == 200) {
          this.toastrService.clear();
          this.toastrService.success(addTask.message);
          sessionStorage.removeItem(AppConstant.TASK_ID);
          sessionStorage.removeItem(AppConstant.DASHBOARD_ID);
          this.taskUpdated.emit(true);
        } else {
          this.toastrService.clear();
          this.toastrService.error(addTask.message);
        }
      },
    );
  }

  goToDashboard() {
    this.taskService.setEditMode(false);
    this.router.navigate([AppConstant.TASK_MANAGEMENT_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_BACKLOG]);
  }

  getTaskDetails() {
    this.taskService.getTaskDetails(this.dashboardId, this.taskId).subscribe((taskDetails: any) => {
      const projectId = this.projectCodeOption.find(option => option.projectName === taskDetails.data.projectName)?.id;
      const sprintId = this.sprintList.find(sprint => sprint.sprintName === taskDetails.data.sprintName)?.sprintId;
      const assigneeId = this.employeeList.find(emp => emp.firstName + AppConstant.SPACE + emp.lastName === taskDetails.data.assigneeName)?.id;
      const reporterId = this.employeeList.find(emp => emp.firstName + AppConstant.SPACE + emp.lastName === taskDetails.data.reporterName)?.id;
      const parentTaskId = this.issueList.find(issue => issue.taskName === taskDetails.data.parentTaskName)?.taskId;
      const statusValue = this.projectCodeOption.find(status => status.status === taskDetails.data.status)?.status;
      this.updateTaskForm.patchValue({
        taskName: taskDetails.data.taskName,
        projectId: projectId,
        status: taskDetails.data.status,
        summary: taskDetails.data.summary,
        description: taskDetails.data.description,
        taskPointEstimate: taskDetails.data.taskPointEstimate,
        assigneeTo: assigneeId,
        parentTaskId: parentTaskId,
        sprintId: sprintId,
        reporterId: reporterId,
        linkedTasks: taskDetails.data.linkedTasks.map((task: { taskName: any; }) => task.taskName).join(', '),
        parentId: parentTaskId
      });
      this.documentsNameList = taskDetails.data.documentsNameList || [];
    });
  }

  restrictToTwoDigits() {
    const control = this.updateTaskForm.get('taskPointEstimate');
    if (control?.value && control.value.toString().length > 2) {
      control.setValue(control.value.toString().slice(0, 2));
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  greaterThanZeroValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const isValid = control.value > 0;
      return isValid ? null : { greaterThanZero: true };
    };
  }
}
