import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConstant } from 'src/app/constants/app.constants';
import { EmailConfiguration } from 'src/app/model/email-configuration-model/email-configuration-model';
import { EmailConfigurationService } from 'src/app/services/email-configuration/emailConfigurationService';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-email-configuration',
  templateUrl: './email-configuration.component.html',
  styleUrls: ['./email-configuration.component.scss']
})
export class EmailConfigurationComponent implements OnInit {
  emailConfigForm!: FormGroup;
  isEditing: boolean = false;
  editableField: string | null = null;

  constructor(private fb: FormBuilder, private emailConfigurationService: EmailConfigurationService) { }

  ngOnInit(): void {
    this.emailConfigForm = this.fb.group({
      supportEmail: ['', [Validators.required, ValidationUtil.emailValidation()]],
      noReplyEmail: ['', [Validators.required, ValidationUtil.emailValidation()]],
      addSalesEmail: ['', [Validators.required, ValidationUtil.emailValidation()]],

      description: ['']
    });

    this.loadData();
  }

  enableEditing(field: string): void {
    this.isEditing = true;
    this.editableField = field;
  }

  shouldShowPencilIcon(field: string): boolean {
    return !this.isEditing || this.editableField !== field;
  }

  resetForm(): void {
    this.emailConfigForm.reset({
      supportEmail: '',
      noReplyEmail: '',
      addSalesEmail: '',
      description: ''
    });
    this.emailConfigForm.markAsPristine();
    this.emailConfigForm.markAsUntouched();
  }

  saveConfig(): void {
    if (this.isEditing) {
      const emailConfiguration: EmailConfiguration = {
        companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
        supportEmail: this.emailConfigForm.value.supportEmail,
        noReplyEmail: this.emailConfigForm.value.noReplyEmail,
        addSalesEmail: this.emailConfigForm.value.addSalesEmail,
        description: this.emailConfigForm.value.description,
      };
      this.emailConfigurationService.saveEmailConfiguration(emailConfiguration).subscribe(() => {
        this.isEditing = false;
        this.editableField = null;
      });
    }
  }

  private loadData(): void {
    this.emailConfigurationService.getEmailConfiguration().subscribe((data) => {
      this.emailConfigForm.patchValue(data.data[0]);
    });
  }
}