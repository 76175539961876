import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddCategoryComponent } from '../add-category/add-category.component';
import { SuperAdminDeclationConfigurationServiceService } from 'src/app/services/super-admin-declaration-configuration-service/super-admin-declation-configuration-service.service';
import { AppConstant } from 'src/app/constants/app.constants';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-category-by-section-id',
  templateUrl: './view-category-by-section-id.component.html',
  styleUrls: ['./view-category-by-section-id.component.scss']
})
export class ViewCategoryBySectionIdComponent implements OnInit {
  @Input() id: any;
  categoryList: any[] = [];
  defaultColDef = {
    flex: 1,
    sortable: true,
    filter: true,
    resizable: true,
  };
  columnDefs = [
    { headerName: 'Category Name', field: 'categoryName' },
    { headerName: 'Section Name', valueGetter: (params: any) => params.data.sectionModel.sectionName },
    { headerName: 'Category Limit', valueGetter: (params: any) => params.data.categoryLimit == null ? 'Nil' : params.data.categoryLimit },
    { headerName: 'Category Description', field: 'categoryDescription' },
    { headername: 'Action', field: "action", cellRenderer: this.viewCategoryButton.bind(this), width: 230 }
  ];

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  constructor(private toastrService: ToastrService, private dialog: MatDialog, private superAdminDeclarationConfigurationService: SuperAdminDeclationConfigurationServiceService, private router: Router, private renderer: Renderer2,) { }

  ngOnInit(): void {
    this.getAllCategoryList();
  }

  openAddCategoryPopup() {
    const dialogRef = this.dialog.open(AddCategoryComponent, {
      width: 'auto',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.getAllCategoryList();
    });
  }

  getAllCategoryList() {
    this.superAdminDeclarationConfigurationService.getAllCategoryList().subscribe(
      (data: any) => {
        this.categoryList = data.data;
      })
  }

  goToBack() {
    this.router.navigate([AppConstant.ADMIN_SIDEBAR + AppConstant.SEPERATOR + AppConstant.TAX_DECLARATION])
  }

  viewCategoryButton(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      if (icon === 'delete') {
        this.renderer.setStyle(iconElement, 'margin-right', '15px');
      }
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };
    const deleteIconClickHandler = () => {
      sessionStorage.setItem(AppConstant.SECTION_ID, params.data.id);
      this.deleteSection(params.data.sectionModel.id, params.data.id);
    };
    createButton('delete', 'red', deleteIconClickHandler);
    return cell;
  };

  deleteSection(sectionId: any, categoryId: any) {
    this.superAdminDeclarationConfigurationService.deleteCategoryBySectionIdAndCategoryId(sectionId, categoryId).subscribe((data: any) => {
      if (data.status === 200) {
        this.getAllCategoryList();
        this.toastrService.clear();
        this.toastrService.success(data.message)
      } else {
        this.toastrService.clear();
        this.toastrService.error(data.message);
      }
    });
  }


}
