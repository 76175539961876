<div class="title">{{'please_follow_the_below_steps' | translate}}</div>
<div mat-dialog-content>
  <ol>
    <li class="steps-content">{{'add_department' | translate}}</li>
    <li class="steps-content">{{'add_designation' | translate}}</li>
    <li class="steps-content">{{'add_company_leave_sturcture' | translate}}</li>
    <li class="steps-content">{{'add_setting_and_configuration' | translate}}</li>
    <li class="steps-content">{{'add_events' | translate}}</li>
    <li class="steps-content">{{'add_appraisal_cycle' | translate}}</li>
    <li class="steps-content">{{'add_payrun_approvals' | translate}}</li>
    <li class="steps-content">{{'add_trainings' | translate}}</li>
  </ol>
</div>
<div mat-dialog-actions>
  <button class="button-right" (click)="dialogRef.close(true)">{{'ok' | translate}}</button>
</div>