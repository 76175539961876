import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { updateEmployeeExitsDetails } from 'src/app/model/exit-model/exit-model/exit-model.module';
import { Deduction, Earnings, FinalSettlementData, ReimbursementList } from 'src/app/model/finalSettlement/final-settlement/final-settlement.module';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { ExitProcessService } from 'src/app/services/exit-process/exit-process.service';
import { ResignationService } from 'src/app/services/resignation/resignation.service';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-final-settlement',
  templateUrl: './final-settlement.component.html',
  styleUrls: ['./final-settlement.component.scss'],
  providers: [DatePipe]
})
export class FinalSettlementComponent implements OnInit {
  finalSettlementForm!: FormGroup;
  changeLWDForm!: FormGroup;
  isNoticePay: boolean = false;
  showForm: boolean = false;
  days: number = 0;
  addReimbursements: boolean = false;
  addDeductions: boolean = false;
  deductionName: boolean = false;
  reimbursementName: boolean = false;
  deductionList!: FormArray;
  reimbursementList!: FormArray;
  tempDeductionList: Deduction[] = []
  tempReimbursementList: ReimbursementList[] = []
  earningsListData: Earnings[] = [];
  finalSettlementDate: any;
  lastWorkingDate: any;
  isReadOnly: boolean = false;
  fieldIsReasonlyMode: boolean = false;
  payPeriod: any;
  showFinalSettelemtDate: any
  showLastWorkingDate: any;
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  employeeId = sessionStorage.getItem('employeeResignationId');
  employeeName: string | undefined;
  lastName: string | undefined;
  organizationPayableDays: number | undefined;
  lopDays: any;
  isLastWorkigReadonly: boolean = false;
  minDate = new Date();
  maxFinalSettlementDate: Date = new Date();
  minFinalSettlementDate: Date = new Date();
  employeePayableDays: any;
  bonus: any;
  commission: any;
  leaveEncashment: any;
  gratuity: any;
  noticePayAmount: any;
  earningsList!: FormArray;
  earnings: { name: string, amount: number }[] = [];
  deductions: { name: string, amount: number }[] = [];
  reimbursements: { name: string, amount: number }[] = [];
  originalValues: any;

  defaultFinalSettlementDate: any;
  defaultLastWorkingDate: any;
  isResignationDetailsPage: boolean = false;

  constructor(private cdr: ChangeDetectorRef, private employeeService: EmployeeDetailsService, private datePipe: DatePipe, private toastrService: ToastrService, private router: Router, private formBuilder: FormBuilder, private resignationService: ResignationService, private exitProcessService: ExitProcessService) {
    this.minDate.setHours(0, 0, 0, 0);
    this.maxFinalSettlementDate.setHours(0, 0, 0, 0);
    this.minFinalSettlementDate.setHours(0, 0, 0, 0);
  }

  ngOnInit(): void {
    this.checkIfResignationDetailsPage();
    this.router.events.subscribe(() => {
      this.checkIfResignationDetailsPage();
    });
    this.initFNFForm();
    this.deductionList = this.finalSettlementForm.get('deductionList') as FormArray;
    this.reimbursementList = this.finalSettlementForm.get('reimbursementList') as FormArray;
    this.getResignagtionDetails();
    this.employeeFinalSettlementDetails();
    this.getEmployeeExitDetails();
    this.changeLWDAndFNFForm();
    this.addEarningsControls();
  }

  addEarningsControls() {
    this.earningsList = this.finalSettlementForm.get('earningsList') as FormArray;
    this.earningsList.push(this.createEarningControl('bonus', 0));
    this.earningsList.push(this.createEarningControl('commission', 0));
    this.earningsList.push(this.createEarningControl('leaveEncashment', 0));
    this.earningsList.push(this.createEarningControl('noticePayAmount', 0));
  }

  checkIfResignationDetailsPage() {
    const currentUrl = this.router.url;
    this.isResignationDetailsPage = currentUrl.startsWith('/approvals/employee-resignation-details/');
  }
  createEarningControl(name: string, amount: number) {
    return this.formBuilder.group({
      name: [name, Validators.required],
      amount: [amount, Validators.required]
    });
  }

  get earningsData(): FinalSettlementData[] {
    return this.finalSettlementForm.value.earningsList;
  }

  getEmployeeExitDetails() {
    this.employeeService.getActiveEmployeeDetails(this.companyId, this.employeeId).subscribe((data: any) => {
      this.employeeName = data?.employeeBasicDetail?.firstName;
      this.lastName = data?.employeeBasicDetail?.lastName;

    });
  }

  goBack() {
    this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACTIVE_EMPLOYEES]);
  }

  employeeFinalSettlementDetails() {
    this.resignationService.employeeFinalSettlementDetails(sessionStorage.getItem('companyId'), sessionStorage.getItem('employeeResignationId')).subscribe((data: any) => {
      if (data.data.organizationPayableDays !== null && data.data.employeePayableDays !== null) {
        this.fieldIsReasonlyMode = true;
        this.employeePayableDays = data.data.employeePayableDays;
        this.lopDays = data.data.lopDays;
        this.bonus = data.data.bonus;
        this.commission = data.data.commission;
        this.leaveEncashment = data.data.leaveEncashment;
        this.gratuity = data.data.gratuity;
        this.noticePayAmount = data.data.noticePayAmount;
        this.finalSettlementForm.patchValue({
          organizationPayableDays: data.data.organizationPayableDays,
          employeePayableDays: data.data.employeePayableDays,
          lopDays: data.data.lopDays,
          noticePayAmount: data.data.noticePayAmount,

        });
        this.isReadOnly = true;
      }

      if (data.status === 200) {
        this.earnings = data.data.earningList.map((earning: any) => ({
          name: this.formatEarningName(earning.name),
          amount: earning.amount
        }));
        this.deductions = data.data.deductionList.map((earning: any) => ({
          name: this.formatEarningName(earning.name),
          amount: earning.amount
        }));
        this.reimbursements = data.data.reimbursementList.map((earning: any) => ({
          name: this.formatEarningName(earning.name),
          amount: earning.amount
        }));

      }
    });
  }

  formatEarningName(name: string): string {
    if (name.toLowerCase() === 'hra') {
      return name.toUpperCase();
    } else {
      return name.charAt(0).toUpperCase() + name.slice(1).replace(/([a-z])([A-Z])/g, '$1 $2');
    }

  }

  getResignagtionDetails(): void {
    this.resignationService.getEmployeeResignationInfo(sessionStorage.getItem('companyId'), sessionStorage.getItem('employeeResignationId')).subscribe((data: any) => {
      if (data.data.lastWorkingDay && data.data.finalSettlementDate != null) {
        this.isLastWorkigReadonly = true;
      }
      this.lastWorkingDate = new Date(data.data.lastWorkingDay);
      const lastWorkingMonth = this.lastWorkingDate.getMonth();
      const lastWorkingYear = this.lastWorkingDate.getFullYear();
      const daysInMonth = new Date(lastWorkingYear, lastWorkingMonth + 1, 0).getDate();
      this.finalSettlementDate = this.datePipe.transform(data.data.finalSettlementDate, 'dd/MM/yyyy');
      this.lastWorkingDate = this.datePipe.transform(data.data.lastWorkingDay, 'dd/MM/yyyy');
      this.showFinalSettelemtDate = data.data.finalSettlementDate;
      this.showLastWorkingDate = data.data.lastWorkingDay;
      const finalSettlementMonth = this.datePipe.transform(data.data.finalSettlementDate, 'MMMM');
      const finalSettlementYear = this.datePipe.transform(data.data.finalSettlementDate, 'yyyy');
      this.payPeriod = `${finalSettlementMonth} ${finalSettlementYear}`;
      this.finalSettlementForm.patchValue({
        finalSettlementDate: this.finalSettlementDate,
        lastWorkingDay: this.lastWorkingDate,
        organizationPayableDays: daysInMonth
      });
      this.organizationPayableDays = daysInMonth;
    });
  }

  editLastWorkingDateAndFNFDate() {
    this.isLastWorkigReadonly = false;
  }

  initFNFForm() {
    this.finalSettlementForm = this.formBuilder.group({
      finalSettlementDate: ['', Validators.required],
      lastWorkingDay: ['', Validators.required],
      employeePayableDays: ['', Validators.required],
      organizationPayableDays: ['', Validators.required],
      lopDays: [''],
      bonus: [''],
      commission: [''],
      leaveEncashment: [''],
      gratuity: [''],
      earningsList: this.formBuilder.array([]),
      deductionList: this.formBuilder.array([]),
      reimbursementList: this.formBuilder.array([]),
      noticePayAmount: [''],
      deductionName: [''],
      deductionAmount: [''],
      amount: [''],
      name: [''],
    });
  }

  changeLWDAndFNFForm() {
    this.changeLWDForm = this.formBuilder.group({
      finalSettlementDate: ['', Validators.required],
      lastWorkingDay: ['', Validators.required]
    });
  }

  toggleForm() {
    this.showForm = !this.showForm;
  }

  addDeduction() {
    if (!this.addDeductions) {
      this.addDeductions = true;
      this.deductionList.clear();
      this.deductionList.push(this.formBuilder.group({
        deductionName: [''],
        deductionAmount: ['']
      }));
    } else {
      if (this.deductionList.length === 0) {
        this.deductionList.push(this.formBuilder.group({
          deductionName: [''],
          deductionAmount: ['']
        }));
      }
    }
  }

  addDeductionName() {
    this.deductionName = true;
  }

  addDeductionAmount(i: number) {
    this.addDeductions = false;
    const deductionFormGroup = this.deductionList.at(i) as FormGroup;
    deductionFormGroup.patchValue({
      deductionName: this.finalSettlementForm.value.deductionName,
      deductionAmount: this.finalSettlementForm.value.deductionAmount
    });
    const dto = {
      name: deductionFormGroup.get('deductionName')?.value,
      amount: deductionFormGroup.get('deductionAmount')?.value
    }
    this.tempDeductionList.push(dto);

    this.finalSettlementForm.controls['deductionName'].reset();
    this.finalSettlementForm.controls['deductionAmount'].reset();
  }

  addReimbursement() {
    if (!this.addReimbursements) {
      this.addReimbursements = true;
      this.reimbursementList.clear();
      this.reimbursementList.push(this.formBuilder.group({
        name: [''],
        amount: ['']
      }));
    } else {
      if (this.reimbursementList.length === 0) {
        this.reimbursementList.push(this.formBuilder.group({
          name: [''],
          amount: ['']
        }));
      }
    }
  }

  addReimbursementAmount(i: number) {
    this.addDeductions = false;
    this.addReimbursements = false;
    const reimbursementFormGroup = this.reimbursementList.at(i) as FormGroup;
    reimbursementFormGroup.patchValue({
      name: this.finalSettlementForm.value.name,
      amount: this.finalSettlementForm.value.amount
    });
    const dto = {
      name: reimbursementFormGroup.get('name')?.value,
      amount: reimbursementFormGroup.get('amount')?.value
    }
    this.tempReimbursementList.push(dto);
    this.finalSettlementForm.controls['name'].reset();
    this.finalSettlementForm.controls['amount'].reset();
  }

  closeDeduction() {
    this.addDeductions = false;
    this.deductionName = false;
  }

  closeReimbursement() {
    this.addReimbursements = false;
    this.reimbursementName = false;
  }

  showDetails() {
    if (this.finalSettlementForm.valid) {
      const earningsList = this.finalSettlementForm.value.earningsList.map((earning: any) => {
        return {
          name: earning.name,
          amount: parseFloat(this.finalSettlementForm.get(earning.name)?.value) || 0
        };
      });
      const finalSettlement: FinalSettlementData = {
        finalSettlementDate: this.showFinalSettelemtDate,
        lastWorkingDay: this.showLastWorkingDate,
        employeePayableDays: this.finalSettlementForm.value.employeePayableDays,
        organizationPayableDays: this.finalSettlementForm.value.organizationPayableDays,
        lopDays: this.finalSettlementForm.value.lopDays || 0,
        deductionList: this.tempDeductionList || [],
        earningList: earningsList,
        reimbursementList: this.tempReimbursementList || [],
        noticePayAmount: this.finalSettlementForm.value.noticePayAmount || 0,
        companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
        employeeId: sessionStorage.getItem(AppConstant.EMPLOYEE_ID),
        finalSettlementStatus: ''
      };
      this.resignationService.finalSettlement(finalSettlement).subscribe((data: any) => {
        if (data.status == 200) {
          this.toastrService.clear();
          this.toastrService.success(data.message);
          this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.SHOW_POI_DETAILS]);

        } else {
          this.toastrService.clear();
          this.toastrService.error(data.message);
        }
      });
    } else {
      this.finalSettlementForm.markAllAsTouched();
    }
  }

  editLWDAndFNF() {
    if (this.changeLWDForm.valid) {
      let updateEmployeeExitDetails: updateEmployeeExitsDetails = {
        finalSettlementDate: this.changeLWDForm.get('finalSettlementDate')?.value,
        lastWorkingDay: this.changeLWDForm.get('lastWorkingDay')?.value,
        companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
        employeeId: sessionStorage.getItem(AppConstant.EMPLOYEE_ID)
      };
      this.exitProcessService.updateEmployExitProcess(updateEmployeeExitDetails).subscribe((data: any) => {
        if (data.status == 200) {
          this.isLastWorkigReadonly = true;
          this.toastrService.clear();
          this.toastrService.success(data.message);
        } else {
          this.toastrService.clear();
          this.toastrService.error(data.message);
        }
      });
    } else {
      this.changeLWDForm.markAllAsTouched();
    }
  }

  cancel() {
    this.isLastWorkigReadonly = true;
  }

  noticePay() {
    this.isNoticePay = !this.isNoticePay;
  }

  next() {
    this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.SHOW_POI_DETAILS]);
  }

  editFNF() {
    this.isReadOnly = false;
  }

  onlyNumberKey(event: any) {
    return ValidationUtil.validateNumber(event);

  }
  validateCharacter(event: any) {
    return ValidationUtil.characterAndSpaceAllowed(event);
  }

  calculateMaxFinalSettlementDate(lastWorkingDate: Date): Date {
    const maxDate = new Date(lastWorkingDate);
    maxDate.setDate(maxDate.getDate() + 45);
    return maxDate;
  }

  onLastWorkingDateChange(event: any) {
    const selectedDate = new Date(event.target.value);
    this.minFinalSettlementDate = selectedDate;
    this.maxFinalSettlementDate = this.calculateMaxFinalSettlementDate(selectedDate);
  }

  goToBack() {
    (sessionStorage.getItem('login') === 'admin')
      ? this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACTIVE_EMPLOYEES])
      : this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ACTIVE_EMPLOYEES]);
  }
}

