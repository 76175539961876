<div class="setting__main_container">
    <div class="setting__heading_container">
        <h3>{{ 'View details of the voucher' | translate }}</h3>
        <div class="setting__header_button_container">
            <button (click)="addVoucher()" class="button add-voucher">{{'add_voucher'|translate}}</button>
        </div>
    </div>
</div>
<div class="paymentTable">
    <ag-grid-angular (gridReady)="onGridReady($event)" style="height: 27rem; width: 100%;" class="ag-theme-alpine"
    [rowData]="voucherData"  [rowSelection]="'multiple'" [animateRows]="true" [columnDefs]="newUserTableCol"
    [defaultColDef]="newUserColDef" [enableSorting]="true" [enableFilter]="true"  [pagination]="true" [paginationPageSize]="10">
    </ag-grid-angular>
</div>