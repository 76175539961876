import { Component } from '@angular/core';
import { trigger, state, style, animate, transition, animateChild, group } from '@angular/animations';

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.scss'],

  animations: [
    trigger('slideInUp', [
      state('void', style({ opacity: 0, transform: 'translateY(-3000px)' })),
      transition(':enter', [
        animate('9000ms ease', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
    ]),
    trigger('slideInLeft', [
      state('void', style({ transform: 'translateX(-100%)' })),
      transition(':enter', [
        animate('2000ms ease-out', style({ transform: 'translateX(0)' })),
      ]),
    ]),
  ],

})

export class SolutionsComponent {
  panelOpenState1: boolean = false;
  panelLeaveOpenState: boolean = false;
  panelOpenState2:boolean =false;
  panelOpenState3:boolean =false;
  panelOpenState4:boolean =false;
  panelOpenState5:boolean =false;
  panelOpenState6:boolean =false;
  panelOpenState7:boolean =false;
  panelOpenState8:boolean =false;
  panelOpenState9:boolean =false;
  panelOpenState10:boolean =false;
  panelOpenState11:boolean =false;
  panelOpenState12:boolean =false;

}
