 <div class="barChart" *ngIf="barChartData">
  <apx-chart class="apx-chart" 
             [series]="barChartData?.series" 
             [chart]="barChartData?.chart"
             [plotOptions]="barChartData?.plotOptions" 
             [dataLabels]="barChartData?.dataLabels" 
             [xaxis]="barChartData?.xaxis"
             [yaxis]="barChartData?.yaxis" 
             [colors]="barChartData?.colors">
  </apx-chart>
</div>


