<app-navbar></app-navbar>
<div class="container">
    <mat-card class="checkout-card">
        <mat-card-content>
            <div class="item">
                <img src="../../../../assets/images/gift.png" alt="Ralph Lauren Satchel" class="gift">
                <div class="item-details">
                    <div class="item-name">{{'choose_voucher'|translate}}</div>
                </div>
            </div>

            <form [formGroup]="createVoucherForm">
                <mat-form-field appearance="outline" class="promo-code-field">
                    <mat-label>{{'enter_voucher'|translate}}</mat-label>
                    <input matInput formControlName="voucherCode" (ngModelChange)="voucherCodeChanged()">
                    <button *ngIf="!verifiedVoucher" mat-button matSuffix class="apply-button" (click)="applyVoucher()">
                        <span class="apply">{{'apply'|translate}}</span>
                    </button>
                    <mat-icon *ngIf="verifiedVoucher" class="verify" color="primary">done_mat_icon</mat-icon>
                </mat-form-field>
            </form>

            <div class="summary">
                <div class="subtotal">
                    <span>{{'plan_price'|translate}}</span>
                    <span>{{ subscriptionPrice | currency:'INR' }}</span>
                </div>

                <div class="subtotal" *ngIf="isSelectVoucher">
                    <span class="discount">{{'descount_amount'|translate}}</span>
                    <span class="discount">- {{ discountAmount | currency:'INR' }}</span>
                </div>

                <div class="subtotal" *ngIf="isSelectVoucher">
                    <span class="including-discount">{{'after_discount_amount'|translate}}</span>
                    <span class="including-discount">{{ discountedAmount | currency:'INR' }}</span>
                </div>
                <div class="subtotal">
                    <span>{{'gst'|translate}}</span>
                    <span>18%</span>
                    <span>{{ gst| currency:'INR' }}</span>
                </div>
                <mat-divider class=divider></mat-divider>
                <div class="total">
                    <span>{{'total_amount_to_pay'|translate}}</span>
                    <span>{{ finalAmount | currency:'INR' }}</span>
                </div>
            </div>
            <button mat-raised-button color="primary" class="place-order"
                (click)="proceed()">{{'proceed'|translate}}</button>
        </mat-card-content>
    </mat-card>
</div>
<app-loader></app-loader>