<div class="header">
    <div class="back-button">
        <mat-icon svgIcon="arrow-back-double" class="back-arrow-icon" (click)="goToBack()"></mat-icon>
        <h3>{{'training' | translate}}</h3>
    </div>
</div>
<mat-divider class="divider"></mat-divider>
<div class="container">
    <div class="training-info">
        <div class="training-details">
            <div class="content">
                <div>
                    <div>
                        <span class="training-title">{{trainingData?.trainingName}}</span>
                    </div>
                    <div class="short-desc">
                        <span>{{trainingData?.trainingShortDescription}}</span>
                    </div>
                    <div class="overlay" *ngIf="imageLoading">
                        <mat-spinner *ngIf="imageLoading" class="spinner-container"></mat-spinner>
                    </div>

                    <div class="long-desc">
                        <span>{{trainingData?.trainingLongDescription}}</span>
                    </div>
                    <div>
                        <div class="star-rating-container star-rating-animation" fxLayout="row"
                            fxLayoutAlign="start center">
                            <div *ngFor="let star of stars" [ngClass]="star.class">
                                <mat-icon>{{star.icon}}</mat-icon>
                            </div>
                        </div>
                        <span class="rating">{{'average_rating' | translate}} : {{trainingData?.avgRatting}}</span>
                        <span class="total-views">{{'total_reviews' | translate}} ({{trainingData?.totalView}})</span>
                    </div>
                </div>
                <div class="img">

                    <img class="image" [src]="trainingData?.thumbnail.url" (load)="onImageLoad()"
                        (click)="viewTraining()" alt="Training Thumbnail">
                </div>
            </div>
        </div>

    </div>
</div>