<div class="container">
  <div class="leave_heading_container">
    <span matTooltip="{{'back_to_dashboard' | translate}}" (click)="goBack()">
      <mat-icon svgIcon="arrow-back-double" class="rewind"></mat-icon>
    </span>
    <div class="leave">
      <h3>{{'apply-leave' | translate}}</h3>
    </div>
    <mat-divider></mat-divider>
  </div>
  <table class="balance-leave">
    <td>
      <tr>{{'annual_leave_balance' | translate}}</tr>
      <tr>
        <td>{{ totalLeaveCount }}</td>
      </tr>
    </td>
    <ng-container *ngFor="let leaveItem of balanceLeave">
      <td>
        <tr>{{ leaveItem.leaveType }}</tr>
        <tr>
          <td>{{ leaveItem.leaveCount }}</td>
        </tr>
      </td>
    </ng-container>
  </table>

  <mat-divider></mat-divider>

  <mat-card>
    <mat-card-content class="leave-content">
      <form name="applyLeave" [formGroup]="applyLeaveForm">
        <div>
          <div class="form-field-gap">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{ 'leave_type' | translate }}</mat-label>
              <mat-select formControlName="leaveType" required>
                <mat-option *ngFor="let leaveTypeItem of leaveType" [value]="leaveTypeItem.leaveType"
                  [disabled]="leaveTypeItem.leaveType !== 'Sick Leave' && isOnProbation || leaveCountMap[leaveTypeItem.leaveType] === 0">
                  {{ leaveTypeItem.leaveType }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="!applyLeaveForm.controls['leaveType'].valid">
                {{ 'leave_type_is_required' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <input matInput formControlName="name" [ngModel]="employeeName" readonly />
            </mat-form-field>
          </div>
          <div class="form-field-gap"
            *ngIf="leaveCountMap[applyLeaveForm.get('leaveType')?.value] === 0 && applyLeaveForm.get('leaveType')?.dirty">
            <mat-hint>
              You are not able to apply this leave type as the leave count is 0.
            </mat-hint>
          </div>
          <div *ngIf="isOnProbation">
            <h5 class="probation"><i class="fa fa-info-circle"></i> {{'probation-period'|translate}}
            </h5>
          </div>
          <div class="form-field-gap1">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{ 'start_date' | translate }}</mat-label>
              <input matInput formControlName="startDate" [matDatepicker]="picker"
                [matDatepickerFilter]="pastDaysFilter" (dateChange)="calculateDifference()"
                (keypress)="$event.preventDefault()">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="applyLeaveForm.get('startDate')?.hasError('required')">
                {{ 'start_date_required' | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{ 'end_date' | translate }}</mat-label>
              <input matInput formControlName="endDate" [matDatepicker]="picker1"
                [min]="applyLeaveForm.get('startDate')?.value" (dateChange)="calculateDifference()"
                (keypress)="$event.preventDefault()">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
              <mat-error *ngIf="applyLeaveForm.get('endDate')?.hasError('required')">
                {{ 'end_date_required' | translate }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{'duration'|translate}}</mat-label>
              <input matInput type="text" class="input" formControlName="noOfDays" required readonly="" />
            </mat-form-field>
          </div>
          <div class="form-field-gap2">
            <mat-form-field class="full-width" appearance="outline">
              <mat-label>{{'leave_reason'|translate}}</mat-label>
              <input matInput type="text" class="input" formControlName="leaveReason" required
                (keypress)="validateCharacterNumberAndSpace($event)">
              <mat-error *ngIf="!applyLeaveForm.controls['leaveReason'].valid">
                {{'reason_is_required'|translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="apply-leave-content">
            <button (click)="applyLeave()" type="submit">{{'apply_leave'|translate}}</button>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
<app-loader></app-loader>