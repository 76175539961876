import { API_END_POINTS } from "../../config/api-endpoint.config";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DesignationData } from '../../model/employeeDetails';
import { environment } from 'src/environments/enviroment';
import { Observable, map } from "rxjs";
import { ToastrService } from "ngx-toastr";
@Injectable({
  providedIn: 'root'
})
export class DesignationService {
  constructor(private http: HttpClient, private toastrService: ToastrService) { }

  addDesignation(addDesignation: DesignationData): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_DESIGNATION, addDesignation).pipe(
      map((res: any) => {
        return res; 
      })
    );
  }

  getDesignationList(companyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.DESIGNATION_LIST + companyId).pipe(
      map((res: any) => {
        if(res.status === 404){
          this.toastrService.clear();
          return this.toastrService.error(res.message);
        } 
        return res.data; 
      })
    );
  }
  

  deleteDesignation(companyId: any, id: any): Observable<any> {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_DESIGNATION + API_END_POINTS.SEPERATOR + companyId + API_END_POINTS.SEPERATOR + id).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
  
  

  updateDesignation(addDesignationRequest: DesignationData): Observable<any> {
    return this.http.put(environment.apiUrl + API_END_POINTS.UPDATE_DESIGNATION, addDesignationRequest).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
  
}