import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { AppConstant } from 'src/app/constants/app.constants';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class TaskManagementService {

  constructor(private http: HttpClient) { }

  private editModeSubject = new BehaviorSubject<boolean>(false);
  isEditMode$ = this.editModeSubject.asObservable();

  setEditMode(isEditMode: boolean) {
    this.editModeSubject.next(isEditMode);
  }

  getTaskDetails(dashboardId: any, taskId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_TASK_DETAILS + dashboardId + AppConstant.SEPERATOR + taskId).pipe(
      map((taskDetails: any) => {
        return taskDetails;
      })
    )
  }

  addCommnet(commentDetails: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_COMMNETS, commentDetails).pipe(
      map((commentDetails: any) => {
        return commentDetails;
      })
    )
  }

  getBacklogData(dashboardId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_BACKLOG_DATA + dashboardId).pipe(
      map((backlogData: any) => {
        return backlogData;
      })
    )
  }

  deleteSprint(sprintId: any): Observable<any> {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_SPRINT_BY_SPRINTID + sprintId).pipe(
      map((sprintDeleteResponce: any) => {
        return sprintDeleteResponce;
      })
    )
  }

  viewAllComments(taskId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_TASK_COMMENTS + taskId).pipe(
      map((allComments: any) => {
        return allComments;
      })
    )
  }

  deleteComment(taskId: any, commentId: any): Observable<any> {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_COMMENT + taskId + AppConstant.SEPERATOR + commentId).pipe(
      map((allComments: any) => {
        return allComments;
      }));
  }

  getDashboardList(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_DASHBOARD_LIST).pipe(
      map((dashboardList: any) => {
        return dashboardList;
      })
    )
  }

  getTaskList(dashboardId: any, sprintId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.CHANGE_TASK_STATUS + dashboardId + AppConstant.SEPERATOR + sprintId).pipe(
      map((taskStatus: any) => {
        return taskStatus;
      }))
  }

  changeTaskStatus(dto: { taskId: any; status: any; }): Observable<any> {
    return this.http.put(environment.apiUrl + API_END_POINTS.UPDATE_TASK_STATUS, dto).pipe(
      map((dashboardList: any) => {
        return dashboardList;
      })
    )
  }

  getQuickAccessList(): Observable<any> {
    const employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID) === undefined ? sessionStorage.getItem(AppConstant.ADMIN_ID) : sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_QUICK_ACCESS_LIST + employeeId).pipe(
      map((taskDetails: any) => {
        return taskDetails;
      })
    )
  }

  changetaskPhases(chnagePhasesDto: any): Observable<any> {
    return this.http.put(environment.apiUrl + API_END_POINTS.CHANGE_TASK_PHASES, chnagePhasesDto).pipe(
      map((phasesStatus: any) => {
        return phasesStatus;
      }))
  }



  startStopSprint(dto: any): Observable<any> {
    return this.http.put(environment.apiUrl + API_END_POINTS.START_STOP_SPRINT, dto).pipe(
      map((startStopResponse: any) => {
        return startStopResponse;
      })
    )
  }

  updateIssueOrder(taskOrder: any): Observable<any> {
    return this.http.put(environment.apiUrl + API_END_POINTS.TASK_ORDER_END_POINT, taskOrder).pipe(
      map((taskOrderResponse: any) => {
        return taskOrderResponse;
      })
    )
  }

  getWorkedOnData(): Observable<any> {
    const employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID) === undefined ? sessionStorage.getItem(AppConstant.ADMIN_ID) : sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_WORKED_ON_DATA + employeeId).pipe(
      map((workedOnData: any) => {
        return workedOnData;
      })
    )
  }

  showAllSprintdetails(showSprintDetails: { dashboardId: string, sprintIds: any[] }): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.GET_ALL_SPRINTS, showSprintDetails);
  }

  reportDetails(showSprintDetails: any): Observable<Blob> {
    return this.http.post<Blob>(environment.apiUrl + API_END_POINTS.VIEW_REPORTS, showSprintDetails, {
      responseType: 'blob' as 'json'
    });
  }

  showBacklogReport(dashboardId: any, reportType: any): Observable<Blob> {
    return this.http.get<Blob>(`${environment.apiUrl}${API_END_POINTS.VIEW_BACKLOG_REPORTS}${dashboardId}${AppConstant.SEPERATOR}${reportType}`, {
      responseType: 'blob' as 'json'
    });
  }

}
