import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { CompanyDetails } from 'src/app/model/companyProfile.model';
import { CompanyProfileService } from 'src/app/services/company-profile/company-profile.service';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { Messages } from 'src/app/constants/messages.constant';
import { MatDialog } from '@angular/material/dialog';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { DatePipe } from '@angular/common';
import { FormatUtil } from 'src/app/utils/format.utils';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss'],
  providers: [DatePipe]
})

export class CompanyProfileComponent implements OnInit {
  @Input() buttonTitle: string = 'upload logo'
  @Output() fileChange = new EventEmitter<FileList>();
  isLoading: boolean = false;
  countryList: any;
  stateList: any;
  cityList: any;
  currentDate: Date = new Date();

  @ViewChild('fileInput') fileInput: any;
  file: File | null = null;
  public viewCompanyProfile !: FormGroup;
  hide: boolean = true;
  error: string | undefined;
  hrData: any
  id: number | undefined;
  hrId = sessionStorage.getItem('response');
  hrID = JSON.parse(this.hrId ?? '{}');
  fileToUpload: string = "";
  logoName: string = "";
  selectedFile: File | any;
  fileInvalid: boolean = false;
  companyId: any;
  logoError: string | null = null;
  companyLogo: any;
  displayNavbar: boolean = false;
  registrationData: any;
  addCompanyInfo: boolean = false;
  selectedCountry: string = "";
  selectedState: string = "";
  selectedCity: string = "";
  companyDetails: CompanyDetails = {
    companyName: '',
    registrationNo: '',
    country: this.selectedCountry,
    state: this.selectedState,
    city: this.selectedCity,
    addressLine1: '',
    addressLine2: '',
    gstNumber: '',
    pinCode: '',
    companyRegistrationDate: ''
  }
  logo = '';
  hasGSTNumber: boolean = false;
  showInvalidGSTError: boolean = false;
  verifiedGST: boolean = false;
  loading: boolean = false;
  data: any;
  registrationDate: boolean = false;
  updateProfile: boolean = false;
  isEditMode: boolean = false;
  companyRegisterdId: string | null = sessionStorage.getItem('companyId');
  readonlyCompanyData: CompanyDetails = {
    companyName: '',
    registrationNo: '',
    country: '',
    state: '',
    city: '',
    addressLine1: '',
    addressLine2: '',
    gstNumber: '',
    pinCode: '',
    companyRegistrationDate: ''
  };

  constructor(private router: Router, private datePipe: DatePipe, private cdr: ChangeDetectorRef, private fb: FormBuilder, private companyProfileService: CompanyProfileService, private toastrService: ToastrService, private personalInfoService: MasterDataService, private dialog: MatDialog) {
    this.companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
    this.getReadonlyCompanyData();
  }

  ngOnInit() {
    if (!this.companyRegisterdId) {
      this.isEditMode = true;
    } else {
      this.isEditMode = false;
    }
    this.getCountryList();
    this.initCreateCompanyProfileForm();
    this.companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
    if (this.companyId === null) {
      this.displayNavbar = true;
      this.registrationDate = false;
      this.addCompanyDetailsForm();
    }
    const registrationData = JSON.parse(sessionStorage.getItem(AppConstant.CREATE_REGISTRATION_REQUEST) ?? '{}');
    this.registrationData = registrationData;
    if (this.companyId === null) {
      this.viewCompanyProfile.controls['gstNumber'].disable();
    }
  }


  getCountryList() {
    this.personalInfoService.getAllCountries().subscribe(
      (data: any) => {
        if (data.status === 200)
          this.countryList = data.data;
      }
    )
  }

  getState(country: any) {
    this.selectedCountry = country;
    this.personalInfoService.getAllState(country).subscribe(
      (data: any) => {
        this.stateList = data.data;
      }
    )
  }

  getCity(state: any) {
    this.selectedState = state;
    this.personalInfoService.getAllCity(state).subscribe(
      (data: any) => {
        this.cityList = data.data;
      }
    )
  }

  selectedCityName(city: any) {
    this.selectedCity = city;
  }

  changeDisable() {
    if (this.viewCompanyProfile.controls['gstNumber'].disabled) {
      this.viewCompanyProfile.controls['gstNumber'].enable();
    } else if (this.isEditMode) {
      this.viewCompanyProfile.controls['gstNumber'].disable();
    }
  }

  getLogoImage(): string {
    if (this.companyLogo) {
      return this.companyLogo;
    } else {
      return '';
    }
  }

  addCompanyDetailsForm() {
    this.viewCompanyProfile.patchValue({
      companyName: '',
      registrationNo: '',
      country: '',
      state: '',
      city: '',
      addressLine1: '',
      addressLine2: '',
      gstNumber: '',
      pinCode: '',
      companyRegistrationDate: '',
    });
  }

  getReadonlyCompanyData() {
    this.companyProfileService.getCompanyData(this.companyId).subscribe(
      (data: any) => {
        this.companyLogo = 'data:image/' + data.data.logoExtension + ';base64,' + data.data.logo;
        this.selectedCountry = data.data.country;
        this.getState(data.data.country);
        this.selectedState = data.data.state;
        this.getCity(data.data.state);
        this.selectedCity = data.data.city;
        this.readonlyCompanyData = {
          companyRegistrationDate: this.datePipe.transform(new Date(data.data.companyRegistrationDate), "dd/MM/yyyy"),
          companyName: data.data.companyName,
          registrationNo: data.data.registrationNo,
          addressLine1: data.data.addressLine1,
          addressLine2: data.data.addressLine2,
          gstNumber: data.data.gstNumber,
          country: data.data.country,
          state: data.data.state,
          city: data.data.city,
          pinCode: data.data.pinCode
        };
      });
  }

  fetchCompanyData(companyId: string): any {
    this.companyProfileService.getCompanyData(companyId).subscribe(
      (data: any) => {
        this.companyLogo = 'data:image/' + data.data.logoExtension + ';base64,' + data.data.logo;
        this.selectedCountry = data.data.country;
        this.getState(data.data.country);
        this.selectedState = data.data.state;
        this.getCity(data.data.state);
        this.selectedCity = data.data.city;
        this.viewCompanyProfile.patchValue({
          companyName: data.data.companyName,
          registrationNo: data.data.registrationNo,
          addressLine1: data.data.addressLine1,
          addressLine2: data.data.addressLine2,
          gstNumber: data.data.gstNumber,
          country: data.data.country,
          state: data.data.state,
          city: data.data.city,
          pinCode: data.data.pinCode,
          companyRegistrationDate: new Date(data.data.companyRegistrationDate),
        });
      });
  }

  cancel() {
    this.isEditMode = false;
  }

  initCreateCompanyProfileForm() {
    this.viewCompanyProfile = this.fb.group({
      'logo': ['', [Validators.required]],
      'companyName': ['', [Validators.required]],
      'registrationNo': [''],
      'companyRegistrationDate': ['', [Validators.required]],
      'country': ['', Validators.required],
      'state': ['', Validators.required],
      'city': [''],
      'addressLine1': ['', [Validators.required]],
      'addressLine2': [''],
      'gstNumber': [''],
      'pinCode': ['', [Validators.required, Validators.pattern(/^[0-9]{6}$/)]
      ]
    })
  }

  submitForm() {
    if (this.selectedFile === undefined) {
      this.fileInvalid = true;
    }
    if (this.viewCompanyProfile.invalid) {
      this.viewCompanyProfile.markAllAsTouched();
      return;
    }
    if (this.selectedFile) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const base64String = event.target?.result as string;
        sessionStorage.setItem("logo", base64String);

        let registrationDate: any;
        if (typeof (this.viewCompanyProfile.get('companyRegistrationDate')?.value) == 'string') {
          let dateValue = this.viewCompanyProfile.get('companyRegistrationDate')?.value;
          let toDateArray =  dateValue.split("/");
          registrationDate = FormatUtil.adjustDateForTimeOffset(new Date((((toDateArray[1].concat("/")).concat(toDateArray[0])).concat("/")).concat(toDateArray[2])));
        } else {
          registrationDate = FormatUtil.adjustDateForTimeOffset(this.viewCompanyProfile.get('companyRegistrationDate')?.value);
        }

        const companyDetailsDto: CompanyDetails = {
          companyName: this.viewCompanyProfile.get('companyName')?.value,
          registrationNo: this.viewCompanyProfile.get('registrationNo')?.value,
          country: this.selectedCountry,
          state: this.selectedState,
          city: this.selectedCity,
          addressLine1: this.viewCompanyProfile.get('addressLine1')?.value,
          addressLine2: this.viewCompanyProfile.get('addressLine2')?.value,
          gstNumber: this.viewCompanyProfile.get('gstNumber')?.value,
          pinCode: this.viewCompanyProfile.get('pinCode')?.value,
          companyRegistrationDate: registrationDate
        };
        sessionStorage.setItem(AppConstant.COMPANY_REGISTRATION_DATE, registrationDate);
        sessionStorage.setItem("companyDetailsDto", JSON.stringify(companyDetailsDto));
        return this.router.navigate([AppConstant.SUBSCRIPTION_PLAN]);
      };
      reader.readAsDataURL(this.selectedFile);
    }
    return this.router.navigate([AppConstant.VIEW_COMPANY_PROFILE]);
  }


  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumber(event);
  }

  validateGST(event: any): boolean {
    return ValidationUtil.validateGST(event);
  }

  alphanumericAllowed(event: any) {
    ValidationUtil.onlyAlphanumeric(event);
  }

  onClickFileInputButton(): void {
    this.logoError = null
    this.fileInput.nativeElement.click();
  }

  onChangeFileInput(event: any): void {
    const file = event.target.files[0];
    if (file && this.checkLogoFileType(file)) {
      this.selectedFile = file;
      if (this.companyId && this.updateProfile) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const base64String = event.target?.result as string;
          this.companyLogo = base64String;
        };
        reader.readAsDataURL(file);
      }
      this.logoName = this.selectedFile.name;
      this.fileInvalid = false;
    } else {
      this.selectedFile = null;
      this.logoName = '';
    }
  }

  checkLogoFileType(file: File): boolean {
    const allowedExtensions = Messages.FILE_EXTENSION;
    const ext = file.name.toLowerCase().substring(file.name.lastIndexOf('.'));
    if (allowedExtensions.includes(ext)) {
      const fileName = file.name.toLowerCase();
      if (fileName.length > 30) {
        this.logoError = Messages.SUPPORTED_FILE_NAME;
        this.fileInvalid = false;
        return false;
      } else if (file.size <= 2 * 1024 * 1024) {
        this.logoError = null;
        this.fileInvalid = false;
        return true;
      } else {
        this.logoError = Messages.SUPPORTED_FILE_SIZE;
        this.fileInvalid = false;
        return false;
      }
    } else {
      this.logoError = Messages.SUPPORTED_FILE_EXTENSIONS;
      this.fileInvalid = false;
      return false;
    }
  }

  editCompanyProfile() {
    this.updateProfile = true;
    this.isEditMode = !this.isEditMode;
    if (this.isEditMode) {
      this.fetchCompanyData(this.companyId);
    }
  }

  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

  addressVerification(event: any) {
    return ValidationUtil.addressVerification(event);
  }

  companyNameValidation(event: any) {
    return ValidationUtil.companyNameValidation(event);
  }

  alphanumberOnly(event: any) {
    return ValidationUtil.alphanumberOnly(event);
  }

  gstNumberUpdated(event: any) {
    this.verifiedGST = false;
  }

  updateCompany() {
    const formData = new FormData();
    if (this.companyLogo) {
      const blob = FormatUtil.dataURItoBlob(this.companyLogo);
      formData.append('logo', blob);
    }

    let registrationDate: any;
    if (typeof (this.viewCompanyProfile.get('companyRegistrationDate')?.value) == 'string') {
      registrationDate = this.viewCompanyProfile.get('companyRegistrationDate')?.value;
    } else {
      registrationDate = FormatUtil.adjustDateForTimeOffset(this.viewCompanyProfile.get('companyRegistrationDate')?.value);
    }

    const companyDetailsDto = {
      companyName: this.viewCompanyProfile.get('companyName')?.value,
      registrationNo: this.viewCompanyProfile.get('registrationNo')?.value,
      country: this.selectedCountry,
      state: this.selectedState,
      city: this.selectedCity,
      addressLine1: this.viewCompanyProfile.get('addressLine1')?.value,
      addressLine2: this.viewCompanyProfile.get('addressLine2')?.value,
      gstNumber: this.viewCompanyProfile.get('gstNumber')?.value,
      pinCode: this.viewCompanyProfile.get('pinCode')?.value,
      companyRegistrationDate: registrationDate,
      companyId: sessionStorage.getItem('companyId')
    }

    if (companyDetailsDto) {
      formData.append('companyDetailsDto', JSON.stringify(companyDetailsDto));
    }

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');

    this.companyProfileService.updateCompany(formData, headers).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.isEditMode = false;
          this.getReadonlyCompanyData();

            this.companyProfileService.getCompanyData(this.companyId).subscribe((data:any)=>{
              sessionStorage.setItem(AppConstant.COMPANY_REGISTRATION_DATE, data.data.companyRegistrationDate);
              sessionStorage.setItem(AppConstant.LOGIN_COMPANY_NAME, data.data.companyName);
            });

          this.toastrService.clear();
          this.toastrService.success(data.message);
        }
        else {
          this.toastrService.clear();
          this.toastrService.error(data.message);
        }
      }
    )
  }

  allowGSTVerify() {
    return ValidationUtil.validateGST(this.viewCompanyProfile.value.gstNumber);
  }

  verifyGST() {
    this.registrationDate = true
    if (this.viewCompanyProfile.value.gstNumber != null && this.viewCompanyProfile.value.gstNumber != " ") {
      this.isLoading = true;
      sessionStorage.setItem(AppConstant.GST_NUMBER, this.viewCompanyProfile.value.gstNumber)
      this.companyProfileService.getGst(this.viewCompanyProfile.value.gstNumber).subscribe((data: any) => {
        this.isLoading = false;
        if (data.flag == false) {
          this.isLoading = false;
        } else {
          this.data = data;
          this.selectedCountry = "India";
          this.viewCompanyProfile.get('country')?.setValue(this.selectedCountry);
          this.getState(this.selectedCountry);
          this.selectedState = this.data.data.pradr?.addr?.stcd?.trim();
          this.viewCompanyProfile.get('state')?.setValue(data.data.pradr?.addr?.stcd.trim());
          this.getCity(this.selectedState);
          this.selectedCity = this.data.data.pradr?.addr?.dst?.trim();
          this.viewCompanyProfile.get('city')?.setValue(data.data.pradr?.addr?.dst.trim());
          this.viewCompanyProfile.patchValue({
            companyName: this.data.data.tradeNam.trim(),
            registrationNo: this.viewCompanyProfile.value.registrationNo.trim(),
            country: this.selectedCountry.trim(),
            state: this.selectedState.trim(),
            city: this.selectedCity.trim(),
            addressLine1: this.data.data.pradr?.adr.trim(),
            addressLine2: this.data.data.pradr?.addr?.bnm.trim(),
            gstNumber: this.viewCompanyProfile.value.gstNumber,
            pinCode: this.data.data.pradr?.addr?.pncd.trim(),
            companyRegistrationDate: this.data.data.rgdt.trim()
          });
        }
        this.cdr.detectChanges();
        if (data.flag) {
          this.showInvalidGSTError = false;
          this.viewCompanyProfile.controls['gstNumber'].setErrors(null);
          this.verifiedGST = true;
        } else {
          this.showInvalidGSTError = true;
          this.viewCompanyProfile.controls['gstNumber'].setErrors({ 'invalidGST': true });
        }
      });
    } else {
      this.showInvalidGSTError = true;
      this.viewCompanyProfile.controls['gstNumber'].setErrors({ 'invalidGST': true });
    }
  }
}
