
<div class="container">
    <div class="content-left">
        <mat-divider class="divider"></mat-divider>
        <form name="bankIntegrationForm" [formGroup]="bankIntegrationForm">
            <div class="form-field-gap">
                <mat-form-field class="name" appearance="outline">
                    <mat-label class="formLabel">{{'account_holder_name'|translate}}</mat-label>
                    <input matInput type="text" class="input" formControlName="name" required  (keypress)="validateCharacterNumberAndSpace($event)"
                    />
                    <mat-error *ngIf="bankIntegrationForm.controls['name'].hasError('required')">
                        {{ 'name_is_required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="bankIntegrationForm.controls['name'].hasError('invalidInput')">
                        {{ 'invalid_name_input' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="name" appearance="outline">
                    <mat-label class="formLabel">{{'name_on_bank_account'|translate}}</mat-label>
                    <input matInput type="text" class="input" formControlName="name_on_bank" required (keypress)="validateCharacterNumberAndSpace($event)"/>
                    <mat-error *ngIf="bankIntegrationForm.controls['name_on_bank'].hasError('required')">
                        {{ 'name_is_required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="bankIntegrationForm.controls['name_on_bank'].hasError('invalidInput')">
                        {{ 'invalid_name_input' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label class="formLabel">{{'work_email'|translate}}</mat-label>
                    <input matInput type="text" class="input" formControlName="email" required />
                    <mat-error>
                        {{'email_is_required'|translate}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-field-gap2">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'mobile_number' | translate }}</mat-label>
                    <input matInput id="mobileNo" (keypress)="validateNumber($event)" minlength="10" maxlength="10"
                        formControlName="phone" required />
                    <mat-error *ngIf="bankIntegrationForm.controls['phone'].hasError('required')">
                        {{ 'mobile_number_required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="bankIntegrationForm.controls['phone'].hasError('invalidMobileNumber')">
                        {{ 'invalid_mobile_number' | translate }}
                    </mat-error>
                </mat-form-field>


                <mat-form-field appearance="outline" class="full-width">
                    <mat-label class="formLabel">{{ 'pan' | translate }}</mat-label>
                    <input matInput formControlName="pan_number" class="mat-input" (keypress)="alphanumericAllowed($event)" [maxLength]="10" required appUppercase />
                    <mat-error *ngIf="bankIntegrationForm.get('pan_number')?.hasError('required')">
                        {{ 'pan_number_is_required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="bankIntegrationForm.get('pan_number')?.hasError('pattern')">
                        {{ 'please_enter_valid_pan_no' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="name" appearance="outline">
                    <mat-label class="formLabel">{{'address'|translate}}</mat-label>
                    <input matInput type="text" class="input" formControlName="address" required (keypress)="addressVerification($event)" />
                    <mat-error *ngIf="bankIntegrationForm.controls['address'].hasError('required')">
                        {{ 'address_is_required' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>


            <div class="form-field-gap3">

                <mat-form-field class="name" appearance="outline">
                    <mat-label class="formLabel">{{'business_name'|translate}}</mat-label>
                    <input matInput type="text" class="input" formControlName="business_name" required (keypress)="validateCharacterNumberAndSpace($event)"/>
                    <mat-error *ngIf="bankIntegrationForm.controls['business_name'].hasError('required')">
                        {{ 'business_name_is_required' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="name" appearance="outline">
                    <mat-label class="formLabel">{{'business_address'|translate}}</mat-label>
                    <input matInput type="text" class="input" formControlName="business_address" required (keypress)="addressVerification($event)"/>
                    <mat-error *ngIf="bankIntegrationForm.controls['business_address'].hasError('required')">
                        {{ 'business_address_is_required' | translate }}
                    </mat-error>
                </mat-form-field>

            </div>

            <div class="form-field-gap4">
                <mat-form-field class="name" appearance="outline">
                    <mat-label class="formLabel">{{'type_of_business'|translate}}</mat-label>
                    <input matInput type="text" class="input" formControlName="nature_of_business" required (keypress)="validateCharacterNumberAndSpace($event)"/>
                    <mat-error *ngIf="bankIntegrationForm.controls['nature_of_business'].hasError('required')">
                        {{ 'type_of_business_is_required' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="name" appearance="outline">
                    <mat-label class="formLabel">{{'business_type_code'|translate}}</mat-label>
                    <mat-select class="select-mat-input" formControlName="business_type_code" required>
                        <mat-option value="Male">{{'00' | translate}}</mat-option>
                        <mat-option value="Female">{{'01' | translate}}</mat-option>
                        <mat-option value="Other">{{'02' | translate}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="bankIntegrationForm.controls['business_type_code'].hasError('required')">
                        {{ 'business_type_code_is_required' | translate }}
                    </mat-error>
                </mat-form-field>


                <mat-form-field class="full-width" appearance="outline">
                    <mat-label class="formLabel">{{'category_code'|translate}}</mat-label>
                    <mat-select class="select-mat-input" formControlName="category_code" required>
                        <mat-option value="Male">{{'00' | translate}}</mat-option>
                        <mat-option value="Female">{{'01' | translate}}</mat-option>
                        <mat-option value="Other">{{'02' | translate}}</mat-option>
                    </mat-select>
                    <mat-error>
                        {{'category_code_is_required'|translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-field-gap5">

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ 'ifsc_code' | translate }}</mat-label>
                    <input matInput type="text" class="ifscVerify" formControlName="account_ifsc" appUppercase
                        (keypress)="alphanumericAllowed($event)" [maxLength]="11"
                        (ngModelChange)="resetIFSCFieldCheck()" required />
                    <mat-error *ngIf="bankIntegrationForm.get('account_ifsc')?.hasError('required')">
                        {{ 'ifsc_code_is_required' | translate }}
                    </mat-error>
                    <a *ngIf="!verifiedIFSC && !isLoading" (click)="verifyIFSC()" class="verify">{{'verify' |
                        translate}}</a>
                    <mat-icon *ngIf="verifiedIFSC" class="success-icon" color="primary">done</mat-icon>
                    <p class="error-message" *ngIf="errorMessage">{{ "errorMessage" }}</p>
                    <app-spinner *ngIf="isLoading" class="spinner"></app-spinner>
                    <mat-error
                        *ngIf="bankIntegrationForm.get('account_ifsc')?.hasError('invalidIFSC')">{{'please-provide-valid-ifsc'
                        | translate}}</mat-error>
                </mat-form-field>

                <mat-form-field class="name" appearance="outline">
                    <mat-label class="formLabel">{{'bank_name'|translate}}</mat-label>
                    <input matInput type="text" class="input" formControlName="bank_name" [maxLength]="30" required (keypress)="validateCharacterNumberAndSpace($event)" />
                    <mat-error *ngIf="bankIntegrationForm.controls['bank_name'].hasError('required')">
                        {{ 'bank_name_is_required' | translate }}
                    </mat-error>

                </mat-form-field>

                <mat-form-field class="name" appearance="outline">
                    <mat-label class="formLabel">{{'branch_name'|translate}}</mat-label>
                    <input matInput type="text" class="input" formControlName="branch_name" required [maxLength]="30" (keypress)="validateCharacterNumberAndSpace($event)"/>
                    <mat-error *ngIf="bankIntegrationForm.controls['branch_name'].hasError('required')">
                        {{ 'branch_name_is_required' | translate }}
                    </mat-error>
                </mat-form-field>


                <mat-form-field class="full-width" appearance="outline">
                    <mat-label class="formLabel">{{'account_number'|translate}}</mat-label>
                    <input matInput type="text" (keypress)="validateNumber($event)" class="input"
                    minlength="8" [maxLength]="18" formControlName="account_number" required />
                    <mat-error>
                        {{'account_number_is_required'|translate}}
                    </mat-error>

                </mat-form-field>

            </div>

            <div class="form-field-gap5">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label class="formLabel">{{'country'|translate}}</mat-label>
                    <mat-select matInput formControlName="country" class="select_mat_input" required="true"
                        (selectionChange)="getState($event.value)">
                        <mat-option *ngFor="let country of countryList;" [value]="country">{{country}}
                        </mat-option>
                    </mat-select>
                    <mat-error>{{'country_is_required'|translate}}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label class="formLabel">{{'state'|translate}}</mat-label>
                    <mat-select matInput formControlName="state" class="select-mat-input" required="true"
                        (selectionChange)="getCity($event.value)">
                        <mat-option *ngFor="let state of stateList;" [value]="state">
                            {{ state }}
                        </mat-option>
                    </mat-select>
                    <mat-error>{{'state_is_required'|translate}}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label class="formLabel">{{'city'|translate}}</mat-label>
                    <mat-select matInput formControlName="city" class="select-mat-input"
                        (selectionChange)="selectedCityName($event.value)">
                        <mat-option *ngFor="let city of cityList;" [value]="city">{{ city }}</mat-option>
                    </mat-select>

                    <mat-error>{{'city_is_required'|translate}}</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'pincode' | translate }}</mat-label>
                    <input (keypress)="validateNumber($event)" matInput class="input" formControlName="pincode"
                        minlength="6" maxlength="6" />
                    <mat-error *ngIf="bankIntegrationForm.get('pincode')?.hasError('required')">
                        {{ 'pincode_required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="bankIntegrationForm.get('pinCpincodeode')?.hasError('minlength')">
                        {{ 'pincode_length' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-field-gap6">

                <mat-form-field class="name" appearance="outline">
                    <mat-label class="formLabel">{{'gstin'|translate}}</mat-label>
                    <input matInput type="text" class="input" formControlName="gstin" required />
                    <mat-error *ngIf="bankIntegrationForm.controls['gstin'].hasError('required')">
                        {{ 'gstin_is_required' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" class="full-width">
                    <mat-label class="formLabel">{{'entity_type'|translate}}</mat-label>
                    <mat-select matInput formControlName="entity_type" class="select-mat-input"
                        (selectionChange)="selectedCityName($event.value)">
                        <mat-option *ngFor="let city of cityList;" [value]="city">{{ city }}</mat-option>
                    </mat-select>

                    <mat-error>{{'entity_type_is_required'|translate}}</mat-error>
                </mat-form-field>
            </div>
            <div>
                <span class="note">{{'note' | translate}} </span> <span class="note-content">{{'note_content' |
                    translate}}</span><br>
            </div>

            <div class="input-container">
                <button (click)="saveBank()" class="button-right">{{'save' | translate}}</button> <button
                    class="button-right cancel-btn">{{'cancel' | translate}}</button>
            </div>
        </form>

    </div>
</div>