import { Component } from '@angular/core';

@Component({
  selector: 'app-employee-annual-performance',
  templateUrl: './employee-annual-performance.component.html',
  styleUrls: ['./employee-annual-performance.component.scss']
})
export class EmployeeAnnualPerformanceComponent {

}
