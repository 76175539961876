<div class="setting__main_container">
    <div class="setting__heading_container">
        <h3>{{'project_dashboard'|translate}}</h3>
        <div class="setting__header_button_container">
            <button color="primary" (click)="createProjectDashboard()" *ngIf="isManager">{{'create_dashboard' |
                translate}}</button>
        </div>
    </div>
    <mat-divider></mat-divider>

    <ag-grid-angular (gridReady)="onGridReady($event)" #agGride style="height: 29rem; width: 100%;"
        class="ag-theme-alpine" [rowData]="projectDashboardList" [rowSelection]="'multiple'" [animateRows]="true"
        [columnDefs]="projectTableCol" [defaultColDef]="projectColDef" [enableSorting]="true" [enableFilter]="true"
        [pagination]="true" [paginationPageSize]="10">
    </ag-grid-angular>
</div>