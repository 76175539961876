import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { AppConstant } from 'src/app/constants/app.constants';
import { RevertExitData } from 'src/app/model/registration.model';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class ResignationService {

  constructor(private http: HttpClient) { }

  submitResignation(formData: FormData): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.APPLY_RESIGNAION, formData).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  //revert process admin side 
  exitProcess(revertDto: RevertExitData): Observable<any> {
    return this.http.request('delete', environment.apiUrl + API_END_POINTS.WITHDRAW_RESIGNATION, {
      body: revertDto
    }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }



  getResignation(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GETEMPLOYEE_RESIGNATION + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getAllEmployeeResignationList(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_EMPLOYEEE_RESIGNATION_LIST + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }


  viewEmployeeResignationById(companyId: any, id: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.VIEW_EMPLOYEE_RESIGNATION_BY_ID + companyId + AppConstant.SEPERATOR + id).pipe(
      map((res: any) => {
        return res;
      })
    )

  }

  updateStatus(updateStatus: any): Observable<any> {
    return this.http.put(environment.apiUrl + API_END_POINTS.UPDATE_RESIGNATION_STATUS, updateStatus).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  isApprovedByHr(approvedByHr: any): Observable<any> {
    return this.http.put(environment.apiUrl + API_END_POINTS.UPDATE_HR_RESIGNATION_STATUS, approvedByHr).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  withdrawal(companyId: any, employeeId: any): Observable<any> {
    return this.http.delete(environment.apiUrl + API_END_POINTS.WITHDRAW_RESIGNATION + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }


  getEmployeeResignationList(selectedType: any, companyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_RESIGNATION_LIST + selectedType + AppConstant.SEPERATOR + companyId).pipe(map((res: any) => {
      return res;
    }))

  }
  getEmployeeResignationListUnderManager(selectStatus: any, companyId: any, managerId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_RESIGNATION_LIST_UNDER_MANAGER + selectStatus + AppConstant.SEPERATOR + companyId + AppConstant.SEPERATOR + managerId).pipe((
      map((res: any) => {
        return res;
      })
    ))

  }










  finalSettlement(finalSettlement: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.FINAL_SETTLEMENT, finalSettlement).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getEmployeeResignationInfo(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.EXIT_EMPLOYEE_BASED_ON_ID + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }


  employeeFinalSettlementDetails(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.EMPLOYEE_FINAL_SETTLEMENT_DETAILS + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }




  finalSettlementDetails(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.FINAL_SETTLEMENT_DETAILS + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  isEmployeeExit(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.IS_EMPLOYEE_EXIT + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  isEmployeeFNFDone(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.IS_EMPLOYEE_FNF_EXIT + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }


  getEmployeeResignationHistory(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_EMPLOYEE_RESIGNATION_HISTORY + companyId + AppConstant.SEPERATOR + employeeId).pipe((
      map((res: any) => {
        return res;
      })
    ))

  }

  getRejectedResignation(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_REJECT_RESIGNATION + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res;
      })
    )

  }
}
