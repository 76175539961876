<mat-menu #menu="matMenu">
    <button mat-menu-item *ngIf="!adminRole" (click)="openProfilePopup()">
        <mat-icon>person</mat-icon>
        {{ 'profile' | translate }}
    </button>
    <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        {{ 'logout' | translate }}
    </button>
</mat-menu>

<mat-icon [matMenuTriggerFor]="menu" class="menu-icon">more_vert</mat-icon>