import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { AssetManagementDashboardService } from 'src/app/services/asset-management/asset-management-dashboard/asset-management-dashboard.service';
import { GridCellComponent } from '../../event-management/grid-cell/grid-cell.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { OrderForAssetPopupComponent } from '../order-for-asset-popup/order-for-asset-popup.component';

@Component({
  selector: 'app-order-for-asset',
  templateUrl: './order-for-asset.component.html',
  styleUrls: ['./order-for-asset.component.scss']
})
export class OrderForAssetComponent implements OnInit {
  defaultColDef: any = [];
  columnDefs: any = [];
  rowData: any = [];
  gridApi: any;
  gridColumnApi: any;
  currentEditingRow: any = [];
  gridOptions: any;

  constructor(private dialog: MatDialog, private router: Router, private toasterService: ToastrService, private assetManagemanetService: AssetManagementDashboardService, private formBuilder: FormBuilder) {
    this.columnDefs = [
      { field: "requestId", filter: true, floatingFilter: true, sortable: true, editable: false, flex: 1 },
      { field: "supplierName", filter: true, floatingFilter: true, sortable: true, flex: 1 },
      { field: "assetType", filter: true, floatingFilter: true, sortable: true, flex: 1 },
      { field: "modelName", filter: true, floatingFilter: true, sortable: true, flex: 1 },
      { field: "quantity", filter: true, floatingFilter: true, sortable: true, flex: 1 },
      { field: "status", filter: true, floatingFilter: true, sortable: true, flex: 1 },
      { field: "Action", cellRenderer: GridCellComponent, editable: false, colId: "action" },
    ];

    this.defaultColDef = {
      editable: true
    };

    this.gridOptions = {
      context: {
        componentParent: this
      }
    }
  }

  ngOnInit(): void {
  }

  goToBack() {
    this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.ASSET_MANAGEMENT])
  }


  orderAsset() {
    const dialogRef = this.dialog.open(OrderForAssetPopupComponent);
    dialogRef.afterClosed().subscribe(result => {
      this.requestAssetList();
    });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.requestAssetList();
  }

  requestAssetList() {
    this.assetManagemanetService.getAllRequestAsset().subscribe(
      (data: any) => {
        this.rowData = data.data;
      }
    );
  }

  onCellClicked(params: any) {
    if (params.column.colId === "action" && params.event.target.dataset.action) {
      let action = params.event.target.dataset.action;
      if (action === "edit") {
        params.api.startEditingCell({
          rowIndex: params.node.rowIndex,
          colKey: params.columnApi.getDisplayedCenterColumns()[0].colId
        });
      }
      if (action === "delete") {
        this.assetManagemanetService.deleteOrderRequest(params.node.data.requestId).subscribe(
          (data: any) => {
            if (data.status === 200) {
              params.api.applyTransaction({
                remove: [params.node.data]
              });
              this.toasterService.clear();
              this.toasterService.success(data.message);
            } else {
              this.toasterService.clear();
              this.toasterService.success(data.message);
            }
          }
        )
      }
      if (action === "update") {
        params.api.stopEditing(false);
        this.updateRequestForAsset(params.data);
      }
      if (action === "cancel") {
        params.api.stopEditing(true);
      }
    }
  }

  updateRequestForAsset(dto: any) {
    this.assetManagemanetService.updateRequestForAsset(dto).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.toasterService.clear();
          this.toasterService.success(data.message);
        }
        else {
          this.toasterService.clear();
          this.toasterService.error(data.message);
        }
      }
    )
  }

  onRowEditingStarted(params: any) {
    params.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node],
      force: true
    });
  }

  onRowEditingStopped(params: any) {
    this.currentEditingRow = params.data;
    params.api.refreshCells({
      columns: ["action"],
      rowNodes: [params.node],
      force: true
    });
  }

}
