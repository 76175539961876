import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { TraningService } from 'src/app/services/traning/traning.service';
import { __param } from 'tslib';

@Component({
  selector: 'app-employee-assign-training-details',
  templateUrl: './employee-assign-training-details.component.html',
  styleUrls: ['./employee-assign-training-details.component.scss']
})
export class EmployeeAssignTrainingDetailsComponent implements OnInit {
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  employeeId: any;
  trainingData: any[] = [];
  empColDef: any
  constructor(private router: Router, private trainingService: TraningService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.employeeId = params['id'];
    })
    this.getAssignTraningDetails();
    this.empColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
  }

  trainingTableCol = [
    { field: 'trainingId', headerName: 'Training ID', width: 120, unSortIcon: true, floatingFilter: true },
    { field: 'trainingName', headerName: 'Training Name', width: 250, unSortIcon: true, floatingFilter: true },
    { field: 'status', headerName: 'Status', width: 150, unSortIcon: true, floatingFilter: true },
    { field: 'trainingStartDate', headerName: 'Start Date', width: 180, unSortIcon: true, floatingFilter: true },
    { field: 'trainingCompleteDate', headerName: 'Completion Date', width: 180, unSortIcon: true, floatingFilter: true },
    { field: 'trainingCompletePercentage', headerName: 'Completion %', width: 150, unSortIcon: true, floatingFilter: true }
  ];

  getAssignTraningDetails() {
    this.trainingService.getAllTrainingDetailsOfEmp(this.companyId, this.employeeId).subscribe((data: any) => {
      if (data.status === 200) {
        this.trainingData = data.data.map((item: any) => ({
          trainingId: item.trainingId,
          trainingName: item.trainingName,
          status: item.status,
          trainingStartDate: item.trainingStartDate ? this.formatDate(item.trainingStartDate) : 'N/A',
          trainingCompleteDate: item.trainingCompleteDate ? this.formatDate(item.trainingCompleteDate) : 'N/A',
          trainingCompletePercentage: item.trainingCompletePercentage || 'N/A',
        }));
      }
    });
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }
  

  goToDashboard() {
    if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.ADMIN) {
      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.VIEW_TRAINING]);
    }
    else {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_TRAINING]);
    }
  }
}
