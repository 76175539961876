<div class="tab-container">
    <div class="tab-header">
        <h4>{{ 'attendance' | translate }}</h4>
        <app-menu-list></app-menu-list>
    </div>

    <div class="tab-body">
        <div class="tab-body">
            <div class="tab-body">
                <div class="attendance-btn">
                    <button mat-raised-button color="primary"
                        [disabled]="attendanceRecords.length > 0 && attendanceRecords[0]?.status === 'Pending'"
                        [ngClass]="{'disabled-button': attendanceRecords.length > 0 && attendanceRecords[0]?.status === 'Pending'}"
                        (click)="punchInOrOut('PUNCH_IN')">
                        {{ 'punch-in' | translate }}
                    </button>

                    <button mat-raised-button color="warn"
                        [disabled]="attendanceRecords.length === 0 || attendanceRecords[0]?.status !== 'Pending'"
                        [ngClass]="{'disabled-button': attendanceRecords.length === 0 || attendanceRecords[0]?.status !== 'Pending'}"
                        (click)="punchInOrOut('PUNCH_OUT')">
                        {{ 'punch-out' | translate }}
                    </button>
                </div>
            </div>
        </div>

        <div class="attendance-view">
            <div *ngFor="let record of attendanceRecords" class="attendance-chip">
                <div>
                    <div class="attendance-date">
                        <mat-icon>today</mat-icon> {{ record.punchInTime | date: 'MMMM d, yyyy' }}
                    </div>
                    <div>{{ record.time }}</div>
                </div>
                <div class="attendance-status-container">
                    <div [ngClass]="{'pending-status': record.status === 'Pending'}" class="attendance-status">
                        {{ record.status }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>