<app-navbar *ngIf="displayNavbar"></app-navbar>

<div class="setting__main_container">
  <div class="setting__heading_container" *ngIf="!displayNavbar">
    <h3>{{ 'view-company-profile' | translate }}</h3>
  </div>

  <mat-card>
    <div class="logo" *ngIf="!displayNavbar">
      <div>
        <img [src]="getLogoImage()" alt="Company Logo" class="company-logo" *ngIf="companyLogo" />
      </div>
      <div class="edit">
        <mat-icon (click)="editCompanyProfile()" [matTooltip]="'Edit'" matTooltipPosition="right"
          *ngIf="!isEditMode">create</mat-icon>
      </div>
    </div>
    <mat-card-content>
      <div>
        <div class='md-padding' layout="row" flex>
          <div layout="row" layout-xs="column" flex class="container">
            <div>
              <div class="header" *ngIf="displayNavbar">
                <h2 class="profile">{{'company-profile'|translate}}</h2>
              </div>
              <div *ngIf="!isEditMode; else editModeContent">
                <div class="company-details">
                  <div class="details-row">
                    <h4>{{ 'company-name' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                    <span>{{readonlyCompanyData.companyName}}</span>
                  </div>
                  <div class="details-row">
                    <h4>{{ 'registration-number' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                    <span>{{ readonlyCompanyData.registrationNo }}</span>
                  </div>
                  <div class="details-row">
                    <h4>{{ 'registration-date' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                    <span>{{ readonlyCompanyData.companyRegistrationDate }}</span>
                  </div>
                  <div class="details-row">
                    <h4>{{ 'gst_number' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                    <span>{{ readonlyCompanyData.gstNumber }}</span>
                  </div>
                  <div class="details-row">
                    <h4>{{ 'address1' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                    <span>{{ readonlyCompanyData.addressLine1 }}</span>
                  </div>
                  <div class="details-row">
                    <h4>{{ 'address2' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                    <span>{{ readonlyCompanyData.addressLine2 }}</span>
                  </div>
                  <div class="details-row">
                    <h4>{{ 'country' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                    <span>{{ readonlyCompanyData.country }}</span>
                  </div>
                  <div class="details-row">
                    <h4>{{ 'state' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                    <span>{{ readonlyCompanyData.state }}</span>
                  </div>
                  <div class="details-row">
                    <h4>{{ 'city' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                    <span>{{ readonlyCompanyData.city }}</span>
                  </div>
                  <div class="details-row">
                    <h4>{{ 'pincode' | translate }}&nbsp;:&nbsp;&nbsp;</h4>
                    <span>{{ readonlyCompanyData.pinCode }}</span>
                  </div>
                </div>
              </div>
              <ng-template #editModeContent>
                <form name="registartionForm" [formGroup]="viewCompanyProfile" enctype="multipart/form-data">
                  <div>
                    <div class="form-field-gap1">
                      <div class="gst-question">{{'do-you-have-gst-number' | translate}}</div><br>
                      <mat-slide-toggle (change)="changeDisable()"></mat-slide-toggle>
                      <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{ 'gst_number' | translate }}</mat-label>
                        <div class="input-container">
                          <input matInput type="text" class="input" (ngModelChange)="gstNumberUpdated($event)"
                            (keypress)="alphanumberOnly($event)" maxlength="15" formControlName="gstNumber"
                            [(ngModel)]="companyDetails.gstNumber" oninput="this.value = this.value.toUpperCase()"
                            maxlength="15" [disabled]="hasGSTNumber" [readonly]="!isEditMode" />
                          <a *ngIf="!verifiedGST && !isLoading" (click)="verifyGST()"
                            [ngClass]="viewCompanyProfile.controls['gstNumber'].value.length == 15 ? 'verify-blue' : 'verify-gray'">{{'verify'
                            | translate}}</a>
                          <mat-icon *ngIf="verifiedGST" class="success-icon" color="primary">done</mat-icon>
                        </div>
                        <mat-error
                          *ngIf="viewCompanyProfile.controls['gstNumber'].hasError('invalidGST')">{{'please-provide-valid-gst'
                          | translate}}</mat-error>
                        <app-spinner *ngIf="isLoading" class="spinner"></app-spinner>
                      </mat-form-field>
                    </div>

                    <mat-divider></mat-divider>

                    <div class="form-field-gap3">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'company-name'|translate}}</mat-label>
                        <input matInput type="text" class="input" formControlName="companyName" required
                          [(ngModel)]="companyDetails.companyName" [value]="data?.data?.tradeNam" [maxLength]="50"
                          [readonly]="!isEditMode" (keypress)="companyNameValidation($event)" />
                        <mat-error *ngIf="viewCompanyProfile.controls['companyName'].hasError('required')">
                          {{ 'company_name_is_required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="viewCompanyProfile.controls['companyName'].hasError('invalidInput')">
                          {{ 'invalid_name_input' | translate }}
                        </mat-error>
                      </mat-form-field>
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'registration-number' | translate}}</mat-label>
                        <input matInput type="text" class="input" (keypress)="alphanumericAllowed($event)"
                          formControlName="registrationNo" [(ngModel)]="companyDetails.registrationNo" maxlength="21"
                          ngModel [readonly]="!isEditMode" appUppercase />
                      </mat-form-field>

                      <mat-form-field *ngIf="!registrationDate" class="full-width" appearance="outline">
                        <mat-label>{{'registration-date' | translate}}</mat-label>
                        <input matInput type="text" class="input" [matDatepicker]="picker" [max]="currentDate"
                          (keypress)="$event.preventDefault()" formControlName="companyRegistrationDate"
                          [(ngModel)]="companyDetails.companyRegistrationDate" [value]="data?.data?.rgdt" readonly />
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="!viewCompanyProfile.controls['companyRegistrationDate'].valid">
                          {{'registration_date_is_required'|translate}}
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field *ngIf="registrationDate" class="full-width" appearance="outline">
                        <mat-label>{{'registration-date' | translate}}</mat-label>
                        <input matInput type="text" class="input" formControlName="companyRegistrationDate"
                          [(ngModel)]="companyDetails.companyRegistrationDate" [value]="data?.data?.rgdt"
                          [readonly]="!isEditMode" />
                      </mat-form-field>
                    </div>
                    <div class="form-field-gap4">
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'address1' | translate}}</mat-label>
                        <input matInput type="text" class="input" formControlName="addressLine1"
                          [(ngModel)]="companyDetails.addressLine1" ngModel [value]="data?.data?.pradr?.adr"
                          [readonly]="!isEditMode" (keypress)="addressVerification($event)" />
                        <mat-error *ngIf="!viewCompanyProfile.controls['addressLine1'].valid">
                          {{'addressLine1_is_required' | translate}} </mat-error>
                      </mat-form-field>
                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{'address2' | translate}}</mat-label>
                        <input matInput type="text" class="input" formControlName="addressLine2"
                          [(ngModel)]="companyDetails.addressLine2" [value]="data?.data?.pradr?.addr?.bnm"
                          [readonly]="!isEditMode" (keypress)="addressVerification($event)" />
                        <mat-error *ngIf="!viewCompanyProfile.controls['addressLine2'].valid">
                          {{'addressLine2_is_required' | translate}} </mat-error>
                      </mat-form-field>

                    </div>
                    <div class="form-field-gap5">
                      <mat-form-field appearance="outline" class="full-width" [attr.disabled]="!isEditMode">
                        <mat-label class="formLabel">{{'country' | translate}}</mat-label>
                        <mat-select matInput formControlName="country" class="select_mat_input" required="true"
                          (selectionChange)="getState($event.value)">
                          <mat-option *ngFor="let country of countryList;" [value]="country">{{country}}</mat-option>
                        </mat-select>
                        <mat-error>{{'country_is_required' | translate}}</mat-error>
                      </mat-form-field>
                      <mat-form-field appearance="outline" class="full-width" [attr.disabled]="!isEditMode">
                        <mat-label class="formLabel">{{ 'state' | translate }}</mat-label>
                        <mat-select matInput formControlName="state" class="select-mat-input" required="true"
                          (selectionChange)="getCity($event.value)">
                          <mat-option *ngFor="let state of stateList;" [value]="state">
                            {{ state }}
                          </mat-option>
                        </mat-select>
                        <mat-error>{{ 'state_is_required' | translate }}</mat-error>
                      </mat-form-field>

                      <mat-form-field appearance="outline" class="full-width" [attr.disabled]="!isEditMode">
                        <mat-label class="formLabel">{{ 'city' | translate }}</mat-label>
                        <mat-select matInput formControlName="city" class="select-mat-input"
                          (selectionChange)="selectedCityName($event.value)">
                          <mat-option *ngFor="let city of cityList;" [value]="city">{{ city }}</mat-option>
                        </mat-select>
                        <mat-error>{{ 'city_is_required' | translate }}</mat-error>
                      </mat-form-field>

                      <mat-form-field class="full-width" appearance="outline">
                        <mat-label>{{ 'pincode' | translate }}</mat-label>
                        <input matInput type="text" class="input" (keypress)="validateNumber($event)"
                          formControlName="pinCode" minlength="6" maxlength="6" [value]="companyDetails.pinCode"
                          [readonly]="!isEditMode" />

                        <mat-error *ngIf="viewCompanyProfile.get('pinCode')?.hasError('required')">
                          {{ 'pincode_required' | translate }}
                        </mat-error>
                        <mat-error *ngIf="viewCompanyProfile.get('pinCode')?.hasError('minlength')">
                          {{ 'pincode_length' | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="form-button">
                      <div class="button-left" *ngIf="displayNavbar || isEditMode">
                        <input type="file" style="display: none" #fileInput formControlName="logo" [(ngModel)]="logo"
                          (change)="onChangeFileInput($event)" accept="image/*" />
                        <button mat-raised-button style="color: black; background-color: white; width: auto;"
                          class="file-select-button mdc-floating-label--required" (click)="onClickFileInputButton()">
                          <span *ngIf="!companyId">{{'choose-logo' | translate}}</span>
                          <span *ngIf="companyId && updateProfile">{{'update_company_logo' | translate}}</span>
                        </button>
                        <p class="invalid-upload-file" *ngIf="!selectedFile; else fileName"></p>
                        <p class="invalid-upload-file" *ngIf="logoError" [style.color]="'red'">{{ logoError }}</p>
                        <ng-template #fileName>
                          <p class="invalid-upload-file">{{ selectedFile?.name }} </p>
                        </ng-template>
                        <mat-error *ngIf="fileInvalid" class="logo-content">{{ 'logo_required' | translate
                          }}</mat-error>
                        <h6 class="upload-message">{{'company_logo_info_msg' | translate}}</h6>
                      </div>

                      <div class="container">
                        <button class="button-right" type="submit" *ngIf="!companyId"
                          (click)="submitForm()">{{'add-company'
                          | translate}}</button>
                        <button class="button-right" type="submit" *ngIf="companyId && updateProfile"
                          (click)="updateCompany()">{{'update_company' | translate}}</button>
                        <button class="button-right cancel-btn" *ngIf="companyId && updateProfile" (click)="cancel()"
                          type="reset">
                          {{'cancel' | translate}}</button>
                      </div>
                    </div>
                  </div>

                </form>
              </ng-template>

            </div>
          </div>
        </div>
      </div>
      <app-loader></app-loader>
    </mat-card-content>
  </mat-card>
</div>