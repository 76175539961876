<div class="main-header">
    <div class="header">
        <span matTooltip="Back to Employee Dashboard" (click)="goToBack()">
            <mat-icon svgIcon="arrow-back-double" class="rewind"></mat-icon>
        </span>
        <h3 class="tittle">{{ 'my_ticket' | translate }}</h3>
    </div>
    <div class="button-div">
        <button (click)="createRequest()">{{ 'create_request' | translate}}</button>
    </div>
</div>

<div class="setting__main_container">
    <ag-grid-angular (gridReady)="onGridReady($event)" style="height: 27rem; width: 100%;" class="ag-theme-alpine"
        [rowData]="viewAllTickets" [rowSelection]="'multiple'" [animateRows]="true"
        [columnDefs]="viewAllTicketsTableDef" [defaultColDef]="viewAllTicketsColDef" [enableSorting]="true"
        [enableFilter]="true" [pagination]="true" [paginationPageSize]="10">
    </ag-grid-angular>
</div>