<div class="selfie-container">
    <h2>{{ 'selfie_title' | translate }}</h2>
    <video *ngIf="!imageUrl" #video autoplay></video>
    <canvas #canvas style="display: none;"></canvas>
    <img *ngIf="imageUrl" [src]="imageUrl" alt="{{ 'selfie_captured_alt' | translate }}">

    <div *ngIf="punchType === 'PUNCH_IN'" class="attendance-details">
        <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ 'select_project' | translate }}</mat-label>
            <mat-select [(value)]="selectedProject">
                <mat-option *ngFor="let project of projects" [value]="project.id">
                    {{ project.projectCode }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="full-width">
            <mat-label>{{ 'select_shift' | translate }}</mat-label>
            <mat-select [(value)]="selectedShift">
                <mat-option *ngFor="let shift of shifts" [value]="shift">
                    {{ shift }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="selfie-actions">
        <button mat-raised-button color="warn" (click)="cancel()">
            {{ 'button_cancel' | translate }}
        </button>
        <button mat-raised-button color="primary" (click)="capturePhoto()" [disabled]="selfieConfirmed"
            *ngIf="!imageUrl">
            {{ 'button_capture' | translate }}
        </button>
        <button mat-raised-button color="primary" (click)="retakePhoto()" *ngIf="imageUrl && !selfieConfirmed">
            {{ 'button_retake' | translate }}
        </button>
        <button mat-raised-button color="accent" (click)="confirmSelfie()" *ngIf="imageUrl && !selfieConfirmed">
            {{ 'button_confirm' | translate }}
        </button>
        <button mat-raised-button color="primary" (click)="submitPunchInOut()" *ngIf="selfieConfirmed">
            {{ 'button_save' | translate }}
        </button>
    </div>
</div>