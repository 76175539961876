import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { AppConstant } from 'src/app/constants/app.constants';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {

  constructor(private http: HttpClient) { }

  getVoucherDetails(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_VOUCHER).pipe(
      map((resw: any) => {
        return resw;
      })
    )
  }
  createVoucher(createVoucher: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.CREATE_VOUCHER, createVoucher).pipe(map((res: any) => {
      return res;
    })
    )
  }


  applyVoucher(subscriptionPrice: number, voucherCode: string,email:any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.USE_SUBSCRIPTION + subscriptionPrice + AppConstant.AND + voucherCode+ AppConstant.EMAIL_QUERY_PARAM_APPENDER+ email,null).pipe(map((res: any) => {
      return res;
    }))
  }

}
