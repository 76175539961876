import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { VoucherModule } from 'src/app/model/voucher/voucher.model';
import { VoucherService } from 'src/app/services/admin-model-services/voucher.service';
import { FormatUtil } from 'src/app/utils/format.utils';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-create-voucher',
  templateUrl: './create-voucher.component.html',
  styleUrls: ['./create-voucher.component.scss']
})
export class CreateVoucherComponent implements OnInit {
  createVoucherForm!: FormGroup;
  minDate: Date;
  minEndDate: Date;
  maxEndDate: Date;

  constructor(private toasterService: ToastrService, private router: Router, private fb: FormBuilder, private createVoucherService: VoucherService) {
    this.minDate = new Date();
    this.minEndDate = this.minDate;

    const aYearFromNow = new Date();
    aYearFromNow.setDate(aYearFromNow.getDate() - 1);
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
    this.maxEndDate = aYearFromNow;
  }

  ngOnInit(): void {
    this.initCreateVoucher();

    this.createVoucherForm?.get('startDate')?.valueChanges.subscribe(startDate => {
      this.onStartDateChange(startDate);
    });
  }

  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumber(event);
  }

  initCreateVoucher() {
    this.createVoucherForm = this.fb.group({
      'companyName': ['', Validators.required],
      'mobileNo': ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10), ValidationUtil.validMobileNumber()]],
      'startDate': ['', Validators.required],
      'expiredDate': ['', Validators.required],
      'email': ['', [Validators.required, ValidationUtil.emailValidation()]],
      'amount': ['', [Validators.required, ValidationUtil.percentAmountValidation(),Validators.min(1)]]
    }, { validator: this.dateLessThan('startDate', 'expiredDate') })
  }

  dateLessThan(startDateKey: string, endDateKey: string) {
    return (group: FormGroup) => {
      let startDate = group.controls[startDateKey].value;
      let endDate = group.controls[endDateKey].value;
      if (endDate && startDate && endDate < startDate) {
        return { dateLessThan: true };
      }
      return null;
    };
  }

  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

  numberOnly(event: any) {
    return ValidationUtil.numberOnly(event);
  }

  onStartDateChange(startDate: Date): void {
    this.minEndDate = startDate || this.minDate;
    this.createVoucherForm?.get('expiredDate')?.updateValueAndValidity();
  }

  createVoucher() {
    if (this.createVoucherForm.valid) {
      let startDate: any;
      if (typeof (this.createVoucherForm.value.startDate) == 'string') {
        startDate = FormatUtil.adjustDateForTimeOffset(new Date(this.createVoucherForm.value.startDate));
      } else {
        startDate = FormatUtil.adjustDateForTimeOffset(this.createVoucherForm.value.startDate);
      }

      let expiredDate: any;
      if (typeof (this.createVoucherForm.value.expiredDate) == 'string') {
        expiredDate = FormatUtil.adjustDateForTimeOffset(new Date(this.createVoucherForm.value.expiredDate));
      } else {
        expiredDate = FormatUtil.adjustDateForTimeOffset(this.createVoucherForm.value.expiredDate);
      }

      const createVoucher: VoucherModule = {
        "companyName": this.createVoucherForm.value.companyName,
        "mobileNo": this.createVoucherForm.value.mobileNo,
        "startDate": startDate,
        "expiredDate": expiredDate,
        "email": this.createVoucherForm.value.email,
        "amount": this.createVoucherForm.value.amount
      };
      this.createVoucherService.createVoucher(createVoucher).subscribe((data: any) => {
        if (data.status == 200) {
          this.router.navigate([AppConstant.ADMIN_SIDEBAR + AppConstant.SEPERATOR + AppConstant.VIEW_VOUCHER]);
          this.toasterService.clear();
          this.toasterService.success(data.message);
        }
        else {
          this.toasterService.error(data.message);
        }
      })
    }
  }
}
