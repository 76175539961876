<div class="container">
    <div layout="row" layout-xs="column" flex>
    <p>
      <mat-toolbar class="leave-bucket">
        <div>{{'leave_bucket' | translate}}</div>
        <span class="apply-leave-button"></span>
        <button *ngIf="showApplyLeaveButton" class="button" (click)="applyLeave()">
          {{'apply_leave' | translate}}
        </button>
      </mat-toolbar>
      <mat-divider></mat-divider>
    </p>
    <table class="leave-type">
      <td>
        <tr>{{'annual_leave_balance' | translate}}</tr>
        <tr>
          <td>{{ totalLeaveCount }}</td>
        </tr>
      </td>

      <ng-container *ngFor="let leaveItem of balanceLeave">
        <td>
          <tr>{{ leaveItem.leaveType }}</tr>
          <tr>
            <td>{{ leaveItem.leaveCount }}</td>
          </tr>
        </td>
      </ng-container>
    </table>
    <mat-divider></mat-divider>
    <mat-toolbar class="leave-request">
      <div class="leave-history">{{'leave_request_history' | translate}}</div>
    </mat-toolbar>
    <div class="employee-leave-details">
      <ag-grid-angular (gridReady)="onGridReady($event)" style="height: 27rem; width: 100%;" class="ag-theme-alpine"
        [rowData]="leaveHistory" [rowSelection]="'multiple'" [animateRows]="true" [columnDefs]="leaveTableCol"
        [defaultColDef]="leaveColDef" [enableSorting]="true" [enableFilter]="true" [pagination]="true"
        [paginationPageSize]="10">
      </ag-grid-angular>
    </div>
  </div>
</div>