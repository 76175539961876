export class Icons {
    public static readonly SVGICONS: Icons[] = [
        { name: 'arrow-back-double', path: "../../assets/images/svgIcons/arrow-back-double.svg" },
        { name: 'white-back-arrow', path: "../../assets/images/svgIcons/white-back-arrow.svg" }
    ]
}

export interface Icons {
    name: string;
    path: string;
}