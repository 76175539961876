import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { Messages } from 'src/app/constants/messages.constant';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { RolesService } from 'src/app/services/roles/roles.service';
import { RolesModule, udateRolesModule } from 'src/app/model/roles/roles/roles.module';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-add-roles',
  templateUrl: './add-roles.component.html',
  styleUrls: ['./add-roles.component.scss']
})
export class AddRolesComponent {
  addRoleHeader: boolean = true;
  isEmployee: any;
  currentUserId = sessionStorage.getItem('role');
  roleColDef: any;
  public roleData!: RolesModule[];
  addRoleForm: FormGroup;
  showAddPopup: boolean = false;
  error: any;
  isAddMode: boolean = true;
  roleId!: any;
  roleList: any[] = [];

  constructor(private renderer: Renderer2, private roleService: RolesService, private cdr: ChangeDetectorRef, private fb: FormBuilder, private toastrService: ToastrService) {
    this.addRoleForm = this.fb.group({
      roleName: ['', Validators.required],
      roleDescription: ['', Validators.required],
      roleReportTo: ['', Validators.required]
    });
  }

  ngOnInit() {
    this.getRole();
    this.roleColDef = { sortable: true, filter: true, resizable: true, };
  }

  roleTableCol = [
    { headerName: 'Role Id', field: 'id', floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: Messages.ROLE_NAME, floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: Messages.ROLE_DESCRIPTION, floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: Messages.REPORT_TO, floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true },
    { field: 'Action', cellRenderer: this.actionCellRenderer.bind(this) },
  ];

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');

    const createButton = (icon: string, colorClass: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      this.renderer.addClass(button, 'mat-icon-button');
      this.renderer.addClass(button, 'ag-grid-custom-button');
      this.renderer.addClass(button, colorClass);
      this.renderer.listen(button, 'click', clickHandler);
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.appendChild(cell, button);
    };
    createButton('edit', 'custom-black', () => this.editRole(params.data));
    createButton('delete', 'custom-red', () => this.deleteRole(params.data));
    return cell;
  }

  getRole() {
    this.roleService.getAllRole(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((data: any) => {
      this.roleList = data.data;
      if (data.data && Array.isArray(data.data)) {
        this.roleData = data.data.map((item: any) => {
          const dateTimeString = item.createdDate;
          const dateTime = new Date(dateTimeString);
          const dateOnlyString = dateTime.toISOString().split('T')[0];
          return {
            ...item,
            createdDate: dateOnlyString,
          };
        });
      } else { this.roleData = []; }
    });
  }

  toggleAddPopup(): void {
    if (this.showAddPopup) {
      this.addRoleForm.reset();
      this.addRoleHeader = true;
    }
    this.showAddPopup = !this.showAddPopup;
  }

  deleteRole(data: any) {
    if (confirm(AppConstant.DELETE_CONFIRMATION_MASSAGE)) {
      this.roleService.deleteRole(sessionStorage.getItem(AppConstant.COMPANY_ID), data.id).subscribe(
        (data: any) => {
          if (data.status === 404) {
            this.toastrService.clear();
            return this.toastrService.error(data.message);
          }
          const index = this.roleData.findIndex((item: any) => { });
          if (index !== -1) {
            this.roleData.splice(index, 1);
            this.cdr.detectChanges();
          }
          this.getRole();
          return this.toastrService.success(data.data);
        }
      );
    }
  }

  addRoles() {
    const roleName = this.addRoleForm.value.roleName;
    const roleDescription = this.addRoleForm.value.roleDescription;
    const roleReportTo = this.addRoleForm.value.roleReportTo

    if (!roleName || roleName.trim() === '' && !roleDescription || roleDescription.trim() === '' && !roleReportTo || roleReportTo === "") {
      return;
    }

    let roleDetails: RolesModule = {
      roleName: roleName,
      roleDescription: roleDescription,
      roleReportTo: roleReportTo,
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
    };
    this.roleService.addRole(roleDetails).subscribe(
      (data: any) => {
        if (data.status === 404) {
          this.toastrService.clear();
          return this.toastrService.error(data.message);
        }
        this.addRoleForm.reset();
        this.toastrService.success(data.data);
        this.toggleAddPopup();
        return this.getRole();
      },
      (error: any) => {
      }
    );
  }

  updateRole() {
    const roleName = this.addRoleForm.value.roleName;
    const roleDescription = this.addRoleForm.value.roleDescription;
    const roleReportTo = this.addRoleForm.value.roleReportTo;
    if (!roleName || roleName.trim() === '' && !roleDescription || roleDescription.trim() === '' && !roleReportTo || roleReportTo === "") {
      return;
    }
    let roleData: udateRolesModule = {
      roleName: roleName,
      roleDescription: roleDescription,
      roleReportTo: roleReportTo,
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      roleId: this.roleId,
    };
    this.roleService.updateRole(roleData).subscribe(
      (data: any) => {
        if (data.status === 404) {
          this.toastrService.clear();
          return this.toastrService.error(data.message);
        }
        this.isAddMode = true;
        this.showAddPopup = false;
        this.getRole();
        return this.toastrService.success(data.data);
      },
      (error: any) => {
        this.error = error;
        if (error.status !== '200') {
          this.getRole();
        }
      }
    );
    this.addRoleForm.get('roleName')?.setValue('');
    this.addRoleForm.get('roleDescription')?.setValue('');
    this.addRoleForm.get('reportTo')?.setValue('');
    this.showAddPopup = false;
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  editRole(data: any) {
    this.roleId = data.id;
    this.addRoleHeader = false;
    this.isAddMode = false;
    this.addRoleForm.patchValue({

      roleName: data.roleName,
      roleDescription: data.roleDescription,
      roleReportTo: data.roleReportTo
    })
    this.toggleAddPopup();
  }

  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }
}
