<div class="image-overlay">
    <div class="overlay">
        <div class="header">
            <div class="image-title">
                <h4>{{ imageName }}</h4>
            </div>
            <div class="header-icons">
                <div class="downloadIcon" (click)="download()">
                    <mat-icon>save_alt</mat-icon>
                </div>
                <div class="closeIcon" (click)="close()">
                    <mat-icon>close</mat-icon>
                </div>
            </div>
        </div>
        <div class="image">
            <img [src]="imageUrl" alt="Document" class="center-image" />
        </div>
    </div>
</div>