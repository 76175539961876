import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { RolesService } from 'src/app/services/roles/roles.service';

@Component({
  selector: 'app-role-info-stepper',
  templateUrl: './role-info-stepper.component.html',
  styleUrls: ['./role-info-stepper.component.scss']
})

export class RoleInfoStepperComponent implements OnInit {
  roleList: any[] = [];
  selectedRoleList: any[] = [];
  displayedColumns: string[] = ['id', 'roleName', 'roleDescription', 'action'];
  dataSource = new MatTableDataSource<any>(this.selectedRoleList);

  constructor(private toastrService: ToastrService, private roleService: RolesService) { }

  ngOnInit() {
    this.getAllRoleList();
  }

  saveRole() {
    const dto = {
      companyId: Number(sessionStorage.getItem(AppConstant.COMPANY_ID)),
      employeeId: Number(sessionStorage.getItem(AppConstant.BASIC_DETAILS_ID)),
      roles: this.selectedRoleList
    };

    this.roleService.saveAllRoleByEmployeeId(dto).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.toastrService.clear();
          this.toastrService.success(data.message);
        } else {
          this.toastrService.clear();
          this.toastrService.error(data.message);
        }
      },
      (error) => {
        console.error('Error saving roles:', error);
        this.toastrService.clear();
        this.toastrService.error('Failed to save roles.');
      }
    );
  }

  getAllRoleList() {
    this.roleService.getAllRole(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.roleList = data.data;
          this.setDefaultRole();
        } else {
          console.error('Failed to fetch roles:', data.message);
          this.toastrService.error('Failed to fetch roles.');
        }
      },
      (error) => {
        console.error('Error fetching roles:', error);
        this.toastrService.error('Failed to fetch roles.');
      }
    );
  }

  setDefaultRole() {
    const defaultRoleId = 2;
    const defaultRole = this.roleList.find(role => role.id === defaultRoleId);
    if (defaultRole) {
      this.selectedRoleList.push(defaultRole);
      this.dataSource.data = this.selectedRoleList.slice();
    }
  }

  selectRole(event: any) {
    const selectedRoleId = event.value;
    const selectedRole = this.roleList.find(role => role.id === selectedRoleId);
    if (selectedRole && !this.selectedRoleList.some(role => role.id === selectedRole.id)) {
      this.selectedRoleList.push(selectedRole);
      this.dataSource.data = this.selectedRoleList;
    }
  }

  removeSelectedRoleList(index: number) {
    this.selectedRoleList.splice(index, 1);
    this.dataSource.data = this.selectedRoleList;
  }
}
