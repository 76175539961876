import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/enviroment';
import { API_END_POINTS } from '../../config/api-endpoint.config';
import { supportModule } from 'src/app/model/support-module/support.module';

@Injectable({
    providedIn: 'root'
})

export class supportservice {

    constructor(private http: HttpClient) { }

    sendSupportRequest(sendSupportRequestBody: supportModule): Observable<any> {
        return this.http.post(environment.apiUrl + API_END_POINTS.SEND_SUPPORT_REQUEST, sendSupportRequestBody).pipe(
            map((res) => { return res })
        )
    }

    getAllSupportRequestList(companyId: any) {
        return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_SUPPORT_LIST + companyId).pipe(
            map(res => { return res })
        )
    }


}