import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { MasterData } from 'src/app/constants/master-data/master-data.constant';
import { Leave } from 'src/app/model/leave-application/leave-stucture.model';
import { LeaveStructureService } from 'src/app/services/leave-application/leave-structure.service';
import { ConfirmationPopupComponent } from 'src/app/shared/confirmation-popup/confirmation-popup.component';
import { AddEditLeavePopupComponent } from '../add-edit-leave-popup/add-edit-leave-popup.component';
import { MatTable } from '@angular/material/table';

interface ColumnSchema {
  key: string;
  label: string;
  type: string;
}

@Component({
  selector: 'app-company-leave-stucture',
  templateUrl: './company-leave-stucture.component.html',
  styleUrls: ['./company-leave-stucture.component.scss']
})
export class CompanyLeaveStuctureComponent implements OnInit {
  displayedColumns: string[] = MasterData.COLUMNS_SCHEMA.map((col) => col.key);
  dataSource!: Leave[];
  columnsSchema: ColumnSchema[] = MasterData.COLUMNS_SCHEMA;
  totalCount: number = 0;
  formGroup!: FormGroup;
  editMode: Boolean = true;
  @ViewChild(MatTable) table!: MatTable<any>;

  constructor(private leaveService: LeaveStructureService, private formBuilder: FormBuilder, private toastrService: ToastrService, public dialog: MatDialog) { }

  ngOnInit() {
    this.listOfLeaves();
    const formControls: any = {};
    this.columnsSchema.forEach((col: ColumnSchema) => {
      formControls[col.key] = ['', Validators.required];
    });
    this.formGroup = this.formBuilder.group(formControls);
  }

  getAnnualLeaveCount() {
    this.totalCount = 0;
    this.dataSource.map((element: any) => {
      this.totalCount += Number(element.leaveCount)
    });
    return this.totalCount;
  }

  saveLeaveStructure() {
    this.dataSource = this.dataSource.filter((data: any) => data.leaveCount > 0 && data.leaveType.trim() !== '' && data.leaveDescription.trim() !== '');
    this.leaveService.leaveData(sessionStorage.getItem(AppConstant.COMPANY_ID), this.dataSource,).subscribe(
      (res: any) => {
        if (res.status === 404) {
          this.toastrService.clear()
          return this.toastrService.error(res.message);
        }
        this.toastrService.clear()
        return this.toastrService.success(res.message);
      }
    );
  }

  listOfLeaves() {
    this.leaveService.getAllLeave(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((res: any) => {
      if (res.data) {
        this.dataSource = res.data;
      } else {
        this.dataSource = MasterData.USER_DATA;
      }
      this.getAnnualLeaveCount();
    });
  }

  noDuplicateLeave(element: any) {
    return this.dataSource.some((leave: any) => {
      return (leave.leaveType).toLowerCase() == (element.leaveType).toLowerCase()
    });
  }

  openDialog(action: string, leaveRow: any) {
    leaveRow.action = action;
    const dialogRef = this.dialog.open(AddEditLeavePopupComponent, {
      disableClose: true,
      width: "auto",
      minWidth: "500px",
      autoFocus: false,
      data: leaveRow
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'Add') {
        this.addNewLeaveData(result.data);
      } else if (result.event == 'Update') {
        this.updateLeaveData(result.data);
      } else if (result.event == 'Delete') {
        this.deleteLeaveData(result.data);
      }
    });
  }

  addNewLeaveData(newLeaveData: Leave) {
    if (!this.noDuplicateLeave(newLeaveData)) {
      this.dataSource = [newLeaveData, ...this.dataSource];
      this.refreshTable();
    } else {
      const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
        disableClose: true,
        data: "Duplicate leave type found. Are you sure you want to add this leave?"
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result.data) {
          this.dataSource = [newLeaveData, ...this.dataSource];
          this.refreshTable();
        }
      });
    }
  }

  updateLeaveData(updatedLeaveData: Leave) {
    let updateIndex = this.dataSource.findIndex((leaveInfo: any, index: any) => {
      return leaveInfo.id == updatedLeaveData.id
    });
    this.dataSource[updateIndex] = updatedLeaveData;
    this.refreshTable();
  }

  deleteLeaveData(updatedLeaveData: Leave) {
    this.dataSource = this.dataSource.filter((value, key) => {
      return value.id != updatedLeaveData.id;
    });

    this.refreshTable();
  }

  refreshTable() {
    this.getAnnualLeaveCount();
    this.table.renderRows();
  }

}