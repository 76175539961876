<div class="container">
    <div class="exit-history-container">
      <span><h3>{{'revert_exit_process?'|translate}}</h3></span>
    </div>
   <div>
    <mat-toolbar class="toolbar">
        <mat-icon class="icon">error</mat-icon> <span class="revert-process">{{'you_are_about_to_exit_process'|translate}} {{firstName}}  {{lastName}}</span>
    </mat-toolbar>
    
</div>
<form name="registartionForm" [formGroup]="revertExitForm">
<div class="reason"> 
    <span class="reason-label">{{'enter_reason'|translate}}</span>
    <mat-form-field appearance="outline"class="reason-input">
        <textarea formControlName="withdrawReason"  matInput (keypress)="validateCharacter($event)"></textarea>
        <mat-error *ngIf="revertExitForm.get('withdrawReason')?.hasError('required')">
            {{'withdraw_reason_is_required' | translate}}
          </mat-error>
    </mat-form-field>
   
</div>
<div class="buttons">
    <button class="proceed" (click)="revertEmployee()">{{'proceed'|translate}}</button>
<button  (click)="closepopup()" class="cancel">{{'cancel'|translate}}</button>
</div>

</form>
</div> 