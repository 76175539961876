<div class="setting__main_container">
    <div class="setting__heading_container">
        <h3>{{ 'attendance-configuration' | translate }}</h3>
        <div class="setting__header_button_container">
            <button color="primary" (click)="addAttendanceCategory()">{{ 'add_category' | translate }}</button>
        </div>
    </div>
    <ag-grid-angular (gridReady)="onGridReady($event)" style="width: 100%; height: 29rem;" class="ag-theme-alpine"
        [rowData]="categoryData" [columnDefs]="columnDefs" [enableSorting]="true" [enableFilter]="true"
        [pagination]="true" [paginationPageSize]="10">
    </ag-grid-angular>
</div>
