import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, map } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { AppConstant } from 'src/app/constants/app.constants';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class ProjectManagementService {
  private refreshSprintsSubject = new Subject<void>();
  refreshSprints$ = this.refreshSprintsSubject.asObservable();

  constructor(private http: HttpClient) { }

  refreshSprints() {
    this.refreshSprintsSubject.next();
  }

  refreshBoards(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + API_END_POINTS.GET_ALL_PROJECT_DASHBOARD);
  }

  addProject(formData: FormData): Observable<any> {
    const headers = new HttpHeaders({
      'enctype': 'multipart/form-data'
    });
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_PROJECT + sessionStorage.getItem(AppConstant.COMPANY_ID), formData, { headers }).pipe(
      map((addProject: any) => {
        return addProject;
      })
    );
  }

  getAllProject(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_PROJECT + sessionStorage.getItem(AppConstant.COMPANY_ID)).pipe(
      map((projectList: any) => {
        return projectList;
      })
    );
  }

  getResourseList(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_PROJECT + sessionStorage.getItem(AppConstant.COMPANY_ID)).pipe(
      map((resourseList: any) => {
        return resourseList;
      })
    );
  }

  deleteProject(projectId: any): Observable<any> {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_PROJECT + projectId).pipe(
      map((deleteProjectResponse: any) => {
        return deleteProjectResponse;
      })
    )
  }

  getProjectManagerList(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_MANAGER_LIST + sessionStorage.getItem(AppConstant.COMPANY_ID)).pipe(
      map((projectManagerList: any) => {
        return projectManagerList;
      })
    );
  }

  getCurrencyList(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_CURRENCY_LIST).pipe(
      map((currencyList: any) => {
        return currencyList;
      })
    );
  }

  getProjectDetails(projectId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_PROJECT_DETAILS_BY_ID + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + projectId).pipe(
      map((projectDetails: any) => {
        return projectDetails;
      })
    );
  }

  getSprintData(dashboardId: any, sprintId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_SPRINT_DETAILS_BY_ID + dashboardId + AppConstant.SEPERATOR + sprintId).pipe(
      map((sprintData: any) => {
        return sprintData;
      })
    );
  }

  getProjectCostingDetails(projectId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_PROJECT_COSTING_DETAILS_BY_ID + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + projectId).pipe(
      map((costingDetails: any) => {
        return costingDetails;
      })
    );
  }

  getResourceCostingDetails(startDate: any, endDate: any, projectId: any) {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_RESOURCE_COSTING_DETAILS_BY_ID + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + projectId + AppConstant.PROJECT_START_DATE + startDate + AppConstant.PROJECT_END_DATE + endDate).pipe(
      map((costingDetails: any) => {
        return costingDetails;
      })
    );
  }

  getAllProjectDetailsByEmployeeId() {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_PROJECT_DETAILS_BY_EMPLOYEE_ID + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  submitApproval(approvalStatus: any, projectId: any): Observable<any> {
    const dto = {
      approvalStatus: approvalStatus,
      projectId: projectId,
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      employeeId: sessionStorage.getItem(AppConstant.EMPLOYEE_ID)
    }
    return this.http.post(environment.apiUrl + API_END_POINTS.SUBMIT_APPROVAL_STATUS, dto).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getApprovalStatus(projectId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_APPROVAL_STATUS + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + employeeId + AppConstant.SEPERATOR + projectId).pipe(
      map((approvalStatus: any) => {
        return approvalStatus;
      })
    );
  }

  getProjectList(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_PROJECT + sessionStorage.getItem(AppConstant.COMPANY_ID)).pipe(
      map((projectList: any) => {
        return projectList;
      })
    );
  }


  addProjectBoard(newProjectBoardRequest: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_PROJECT_BOARD, newProjectBoardRequest).pipe(
      map((addBoard: any) => {
        return addBoard;
      })
    );
  }

  updateProjectBoard(updateProjectBoardRequest: any): Observable<any> {
    return this.http.put(environment.apiUrl + API_END_POINTS.UPDATE_PROJECT_BOARD, updateProjectBoardRequest).pipe(
      map((updateBoard: any) => {
        return updateBoard;
      })
    );
  }

  getAllProjectDashboard(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_PROJECT_DASHBOARD).pipe(
      map((ProjectDashboard: any) => {
        return ProjectDashboard;
      })
    )
  }

  deleteProjectDashbaord(dashboardId: any): Observable<any> {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_BOARD + dashboardId).pipe(
      map((dashboardDetails: any) => {
        return dashboardDetails;
      })
    )
  }

  getAllSprintList(dashboardId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_SPRINT + dashboardId).pipe(
      map((sprintList: any) => {
        return sprintList;
      })
    )

  }

  createSprint(dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.CREATE_SPRINT, dto).pipe(
      map((createSprint: any) => {
        return createSprint;
      })
    );
  }

  updateSprint(dto: any): Observable<any> {
    return this.http.put(environment.apiUrl + API_END_POINTS.UPDATE_SPRINT, dto).pipe(
      map((updateSprintData: any) => {
        return updateSprintData;
      })
    );
  }

  getProjectDashboard(dashboardId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_DASHBOARD_DETAILS + dashboardId).pipe(
      map((projectDashbord: any) => {
        return projectDashbord;
      })
    )
  }

  getIssueList(projectId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.ISSUE_LIST + projectId).pipe(
      map((issueList: any) => {
        return issueList;
      })
    )
  }

  addTask(addTask: FormData): Observable<any> {
    const headers = new HttpHeaders({
      'enctype': 'multipart/form-data'
    });
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_TASK, addTask, { headers }).pipe(
      map((addTask: any) => {
        return addTask;
      })
    )

  }

  updateTask(addTask: FormData): Observable<any> {
    const headers = new HttpHeaders({
      'enctype': 'multipart/form-data'
    });
    return this.http.put(environment.apiUrl + API_END_POINTS.UPDATE_TASK, addTask, { headers }).pipe(
      map((addTask: any) => {
        return addTask;
      })
    )
  }

  showProjectDetails(projectId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.SHOW_PROJECT_DETAILS + projectId).pipe(
      map((projectDetails: any) => {
        return projectDetails;
      })
    )
  }

  addNewCollaborators(newCollaboratorsDto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.NEW_COLLOBORATORS, newCollaboratorsDto).pipe(
      map((newCollaboratorsdetails: any) => {
        return newCollaboratorsdetails;
      }))
}

}
