<div class="container">
  <div class="grid-container-basic">
    <h3>{{'your_work'| translate}}</h3>
  </div>
  <mat-divider></mat-divider>

  <div class="heading">
    <div class="task-management-header">
      <h4>{{'recent_dashboard'|translate}}</h4>
    </div>
    <div class="group">
      <div (click)="selectBoardBased()">{{ 'view_all_project_dashboards' | translate}}</div>
    </div>
  </div>

  <div class="work_grid_container">
    <div>
      <div class="grid-container">
        <mat-card class="work-card" *ngFor="let board of dashboard; let i = index"
          [ngStyle]="{'border-left': getBorderColor(i)}">
          <mat-card-content class="work-card-content">
            <div>
              <div>
                <mat-label>{{ board.dashboardName }}</mat-label>
                <div>
                  <span class="projectTeam">{{ board.description }}</span>
                </div>
                <div>
                  <h5 class="card-title">{{'quick_links' | translate}}</h5>
                  <div class="open-issue">
                    <span>{{ 'to_task' | translate }}</span>
                    <div class="open-issue-count">{{ board.toDoTask }}</div>
                  </div>
                  <div class="open-issue">
                    <span>{{ 'my_open_task' | translate }}</span>
                    <div class="open-issue-count">{{ board.openTask }}</div>
                  </div>
                  <div class="open-issue">
                    <span>{{ 'done_task' | translate }}</span>
                    <div class="open-issue-count">{{ board.doneTask }}</div>
                  </div>
                </div>
              </div>
              <div [matMenuTriggerFor]="menu" class="board">
                <div class="board-drop-down">
                  <span>{{ board.sprintList.length }} {{'sprint' | translate}}</span>
                  <span>
                    <mat-icon>expand_more</mat-icon>
                  </span>
                </div>
              </div>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="goToSprintBoard(sprint, board)"
                  *ngFor="let sprint of board.sprintList">{{sprint.sprintName}}</button>
              </mat-menu>
            </div>
          </mat-card-content>
        </mat-card>

      </div>
    </div>

    <mat-divider class="tab-divider"></mat-divider>

    <div>
      <div class="tabs">
        <button (click)="getSelectedHistory('worked_on')" [ngClass]="{'active': selectedTab === 'worked_on'}">
          {{ 'worked_on' | translate }}</button>
        <button (click)="getSelectedHistory('assigned_to_me')" [ngClass]="{'active': selectedTab === 'assigned_to_me'}">
          {{'assigned_to_me' | translate }}</button>
      </div>

      <div *ngIf="selectedTab === 'worked_on'">
        <div class="worked-on" *ngIf="workedOnYesterday && workedOnYesterday.length > 0">
          <h3 class="worked-on-title">{{ 'yesterday' | translate }}</h3>
          <div class="item" (click)="gotToTask(item)" *ngFor="let item of workedOnYesterday">
            <div class="item-info">
              <div class="item-title">{{ item.taskName }}</div>
              <div class="item-subtitle">{{ item.taskDescription }}</div>
            </div>
            <div class="item-status">{{ item.status }}</div>
            <div class="item-avatar">{{ item.initial }}</div>
          </div>
        </div>
        <div class="worked-on" *ngIf="workedOnLastWeek && workedOnLastWeek.length > 0">
          <h3 class="worked-on-title">{{ 'in_last_week' | translate }}</h3>
          <div class="item" (click)="gotToTask(item)" *ngFor="let item of workedOnLastWeek">
            <div class="item-info">
              <div class="item-title">{{ item.taskName }}</div>
              <div class="item-subtitle">{{ item.taskDescription }}</div>
            </div>
            <div class="item-status">{{ item.status }}</div>
            <div class="item-avatar">{{ item.initial }}</div>
          </div>
        </div>
      </div>

      <div *ngIf="selectedTab === 'assigned_to_me'">
        <div class="assigned-to-me" *ngIf="assignedToMe && assignedToMe.length > 0">
          <div class="item" (click)="gotToTask(item)" *ngFor="let item of assignedToMe">
            <div class="item-info">
              <div class="item-title">{{ item.taskName }}</div>
              <div class="item-subtitle">{{ item.taskDescription }}</div>
            </div>
            <div class="item-status">{{ item.status }}</div>
            <div class="item-avatar">{{ item.initial }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>