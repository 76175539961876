<div class="container" #container *ngIf="isTrainingAvailable; else noTraining">
  <mat-icon class="left" *ngIf="totalPages > 1" (click)="currentPage != 1 && changePage(-1)"
    [ngClass]="{'disabled': currentPage === 1}">keyboard_arrow_left</mat-icon>
  <div class="cards">
    <div class="overflow" [style.transform]="pagePosition">
      <div class="card" *ngFor="let image of objectData | slice:(currentPage-1)*cardsPerPage:(currentPage*cardsPerPage)"
        (click)="getImage(image)">
        <img [src]="image.image" alt="{{image.title}}" class="card-image" />
        <div class="card-content">
          <h3 class="card-title">{{image.title}}</h3>
          <div class="col-sm-12">
            <div class="rating-heading">
              <span><b>{{'rating' | translate}}:</b></span>
            </div>
            <ul class="list-inline rating-list" *ngFor="let star of stars; let i= index" style="display: inline-block">
              <li (click)="countStar(star)" id="{{'starId'+i}}" [ngClass]="{'selected': (star <= selectedValue)}"
                (mouseover)="addClass(star)" (mouseout)="removeClass(star)">
                ★
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-icon class="right" *ngIf="totalPages > 1" (click)="currentPage != totalPages && changePage(+1)"
    [ngClass]="{'disabled': currentPage === totalPages}">keyboard_arrow_right</mat-icon>
</div>

<app-loader></app-loader>

<ng-template class="container" #noTraining>
  <div class="no-training-message">
    {{ 'no_training_available' | translate }}
  </div>
</ng-template>