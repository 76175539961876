<button class="actionButton" data-action="update" *ngIf="isCurrentRowEditing">
    {{"update" | translate}}
</button>
<button class="actionButton" data-action="cancel" *ngIf="isCurrentRowEditing" (click)="cancelUpdate()">
    {{"cancel" | translate}}
</button>

<button class="actionButton" data-action="edit" *ngIf="!isCurrentRowEditing">
    {{"edit" | translate}}
</button>
<button class="actionButton" data-action="delete" *ngIf="!isCurrentRowEditing">
    {{"delete" |translate}}
</button>