import { Component, OnInit } from '@angular/core';
import { AssetManagementDashboardService } from 'src/app/services/asset-management/asset-management-dashboard/asset-management-dashboard.service';

@Component({
  selector: 'app-employee-asset-details',
  templateUrl: './employee-asset-details.component.html',
  styleUrls: ['./employee-asset-details.component.scss']
})
export class EmployeeAssetDetailsComponent implements OnInit {
  empProfilecolumnDefs: any[] = []
  empProfilerowData: any[] = [];

  constructor(private addAssetService: AssetManagementDashboardService) {
    this.empProfilecolumnDefs = [
      { field: "modelNo", filter: true, floatingFilter: true, sortable: true, flex: 1 },
      { field: "assetType", filter: true, floatingFilter: true, sortable: true, flex: 1 },
      { field: "specification", filter: true, floatingFilter: true, sortable: true, tooltipField: "Specification", flex: 1 },
      { field: "assignDate", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    ];
  }

  ngOnInit(): void {
    this.getAssetDetails();

  }

  getAssetDetails() {
    this.addAssetService.getAssetDetailsById().subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.empProfilerowData = data.data;
        }
      }
    )
  }





}

