import { Component, ElementRef, HostListener, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { LoginPageComponent } from 'src/app/components/login-page/login-page.component';
import { BookDemoComponent } from '../book-demo/book-demo.component';
import { AppConstant } from 'src/app/constants/app.constants';

@Component({
  selector: 'app-main-landing-page',
  templateUrl: './main-landing-page.component.html',
  styleUrls: ['./main-landing-page.component.scss']
})
export class MainLandingPageComponent {
  isMobileView: boolean = false;
  @ViewChild('backgroundVideo') backgroundVideo!: ElementRef<HTMLVideoElement>;
  constructor(private router: Router, private dialog: MatDialog, private renderer: Renderer2) {
    this.checkViewport();
  }

  @HostListener('window:resize', [])
  onResize() {
    this.checkViewport();
  }

  private checkViewport() {
    this.isMobileView = window.innerWidth <= 768; // Define mobile breakpoint
  }

  ngOnInit(): void {
    sessionStorage.clear();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.playVideo();
      }
    });
  }

  ngAfterViewInit(): void {
    const video = this.backgroundVideo.nativeElement;
    video.muted = true;
    video.play().catch((error) => {
      console.error('Autoplay failed:', error);
    });
  }

  private playVideo(): void {
    const video = this.backgroundVideo.nativeElement;

    if (video) {
      video.pause();
      video.currentTime = 0;
      video.play().catch((error) => {
      });
    }
  }

  openLoginDialog() {
    this.dialog.open(LoginPageComponent, {
      width: '400px',
      disableClose: true,
      position: {
        top: '8%',
        right: '1rem'
      }
    });
  }

  sheduleDemo() {
    this.dialog.open(BookDemoComponent, {
      width: '400px',
      height: 'auto',
      disableClose: true
    });
  }

  registration() {
    this.router.navigate([AppConstant.REGISTRATION])
  }
}