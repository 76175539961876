import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { TraningService } from 'src/app/services/traning/traning.service';

@Component({
  selector: 'app-view-training-summary',
  templateUrl: './view-training-summary.component.html',
  styleUrls: ['./view-training-summary.component.scss']
})
export class ViewTrainingSummaryComponent implements OnInit {
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  trainingId: any;
  trainingData: any;
  selectedRating: any;
  imageLoading: boolean = true;
  constructor(private loaderService: LoaderService, private router: Router, private trainingService: TraningService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.trainingId = params['id'];
      this.getTrainingDetails();
    });
  }

  goToBack() {
    this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.VIEW_TRAINING]);
  }

  getTrainingDetails() {
    this.loaderService.show();
    this.trainingService.getTrainingDetailsById(this.companyId, this.trainingId).subscribe((data: any) => {
      this.trainingData = data.data;
      this.selectedRating = this.trainingData.avgRatting;
      this.updateStarRating(this.trainingData.avgRatting);
      this.loaderService.hide();
    }, () => {
      this.loaderService.hide();
    });
  }

  onImageLoad() {
    this.imageLoading = false;
  }

  viewTraining() {
    this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_EMPLOYEE_TRAINING + this.trainingId]);
  }

  updateStarRating(avgRatting: number) {
    this.stars = this.stars.map(star => {
      star.class = star.id <= avgRatting ? 'star-gold star' : 'star-gray star';
      return star;
    });
  }

  stars = [
    { id: 1, icon: 'star', class: 'star-gray star' },
    { id: 2, icon: 'star', class: 'star-gray star' },
    { id: 3, icon: 'star', class: 'star-gray star' },
    { id: 4, icon: 'star', class: 'star-gray star' },
    { id: 5, icon: 'star', class: 'star-gray star' }
  ];

}
