<div id="chart">
    <mat-card>
        <div class="title">
            <b>{{'payment_received_view'|translate}}</b>
            <div class="select-container">
                <mat-form-field appearance="outline">
                    <mat-select [(ngModel)]="selectedTimeRange" (ngModelChange)="montlyYearlyBasis()">
                        <mat-option value="monthly">{{'monthly'|translate}}</mat-option>
                        <mat-option value="yearly">{{'yearly'|translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <apx-chart class="chart-container" [series]="chartOptions.series" [chart]="chartOptions.chart"
            [labels]="chartOptions.labels" [responsive]="chartOptions.responsive"></apx-chart>
        <div class="view-graph"><a (click)="viewReport()">{{'view'|translate}}</a></div>
    </mat-card>
</div>