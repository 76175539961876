<h3 class="headerName">{{'add_asset'|translate}}</h3>
<mat-dialog-content>
    <form [formGroup]="assetForm">
        <div class="assets">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{'asset_type'|translate}}</mat-label>
                <mat-select required="*" formControlName="assetType">
                    <mat-option>{{'none'|translate}}</mat-option>
                    <div *ngFor="let asset of assets">
                        <mat-option [value]="asset">{{asset}}</mat-option>
                    </div>
                </mat-select>
                <mat-error *ngIf="assetForm.get('assetType')?.hasError('required')">
                    {{'asset_type_is_required'|translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{'model_name'|translate}}</mat-label>
                <mat-select required="*" formControlName="modelName">
                    <mat-option>{{'none'|translate}}</mat-option>
                    <div *ngFor="let name of modelName">
                        <mat-option [value]="name">{{name}}</mat-option>
                    </div>
                </mat-select>
                <mat-error *ngIf="assetForm.get('modelName')?.hasError('required')">
                    {{'model_name_is_required'|translate}}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="assets">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'model_no'|translate}}</mat-label>
                <input class="Input-field" matInput type="text"
                    (keypress)="validateNumberAndSpaceAndHyphenAllowed($event)" formControlName="modelNo" required="*">
                <mat-error *ngIf="assetForm.get('modelNo')?.hasError('required')">
                    {{'model_no_is_required'|translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline" *ngIf="isLaptop">
                <mat-label>{{'ram'|translate}}</mat-label>
                <mat-select formControlName="ram">
                    <mat-option>{{'none'|translate}}</mat-option>
                    <div *ngFor="let ram of ramList">
                        <mat-option [value]="ram">{{ram}}</mat-option>
                    </div>
                </mat-select>
                <mat-error *ngIf="assetForm.get('ram')?.hasError('required')">
                    {{'ram_is_required'|translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="!isLaptop" class="full-width" appearance="outline">
                <mat-label>{{'specification'|translate}}</mat-label>
                <input class="Input-field" matInput (keypress)="validateNumberAndSpaceAndHyphenAllowed($event)"
                    type="text" formControlName="specification">
                <mat-error *ngIf="assetForm.get('specification')?.hasError('required')">
                    {{'specification_is_required' |translate}}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="assets" *ngIf="isLaptop">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'ssd'|translate}}</mat-label>
                <mat-select formControlName="ssd">
                    <mat-option>{{'none'|translate}}</mat-option>
                    <div *ngFor="let ssd of ssdList">
                        <mat-option [value]="ssd">{{ssd}}</mat-option>
                    </div>
                </mat-select>
                <mat-error *ngIf="assetForm.get('ssd')?.hasError('required')">
                    {{'ssd_is_required'|translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="outline">
                <mat-label>{{'processor'|translate}}</mat-label>
                <mat-select formControlName="processor">
                    <mat-option>{{'none'|translate}}</mat-option>
                    <div *ngFor="let processor of processorList">
                        <mat-option [value]="processor">{{processor}}</mat-option>
                    </div>
                </mat-select>
                <mat-error *ngIf="assetForm.get('processor')?.hasError('required')">
                    {{'processor_is_required'|translate}}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="buttons">
            <button class="cancel-btn" (click)="closeDialog()">{{'cancel'|translate}}</button>
            <button (click)="save()">{{'save'|translate}}</button>
        </div>

    </form>
</mat-dialog-content>