<div class="container">
        <div class="heading">
                <h4>{{'blow_the_candles' | translate}} </h4>
                <div>
                        <mat-icon class="cakeIcon">cake</mat-icon>
                </div>
        </div>
        <ng-container *ngIf="birthdayList && birthdayList.length > 0; else noBirthday">
                <marquee direction="up" scrolldelay="250" behavior="loop">
                        <div *ngFor="let birthday of birthdayList" class="birthday_message_container">
                                <div class="message"> <img src="../../../../assets/images/svgIcons/party-hat.svg"
                                                class="svgImage">
                                        <div class="birthdaydetails"> {{'happy_birthday' | translate}}
                                                {{birthday.employeeName}} : {{birthday.birthDate |
                                                date:'MMM-dd'}} | {{'EmpId' | translate}} {{birthday.employeeId}} </div>
                                </div>
                        </div>
                </marquee>
        </ng-container>
        <ng-template #noBirthday>
                <p class="template">{{'no_birthday_available' | translate}}</p>
        </ng-template>
</div>