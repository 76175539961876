import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { SendOtpService } from 'src/app/services/send-otp/send-otp.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent {
  changePasswordForm !: FormGroup;
  hidePassword: boolean = true;
  email: any;

  constructor(private router: Router, private fb: FormBuilder, private toastrService: ToastrService, private sendOtpService: SendOtpService) { }

  ngOnInit() {
    this.initChangePasswordForm();
  }

  initChangePasswordForm() {
    this.changePasswordForm = this.fb.group({
      'email': ['', Validators.email],
      'password': ['', [Validators.required, Validators.minLength(8)]],
      'confirmPassword': ['', [Validators.required, Validators.minLength(8)]],
    }, {
      validators: ValidationUtil.mustMatch('password', 'confirmPassword')
    })
  }

  setPassword() {
    let changePasswordRequestBody = {
      email: this.email = sessionStorage.getItem('email'),
      password: this.changePasswordForm.get('password')?.value,
      confirmPassword: this.changePasswordForm.get('confirmPassword')?.value
    }

    if (ValidationUtil.validateRequestBody(changePasswordRequestBody)) {
      this.sendOtpService.resetPassword(changePasswordRequestBody).subscribe((res: any) => {
        if (res.status == 200) {
          this.toastrService.clear()
          this.toastrService.success(res.message)
          sessionStorage.clear();
          this.router.navigate([`/${AppConstant.LOGIN_PAGE}`]);
        }
        else {
          this.toastrService.clear()
          this.toastrService.error(res.message);
          this.router.navigate([`/${AppConstant.CHANGE_PASSWORD}`]);
        }
      });
    } else {
      this.changePasswordForm.markAllAsTouched();
      return;
    }

  }

}
