import { Component } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ProjectManagementService } from 'src/app/services/project-management/project-management.service';
import { TaskManagementService } from 'src/app/services/task-management/task-management.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommentData } from 'src/app/model/task-management/task-management/task-management.module';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { CreateSprintComponent } from '../create-sprint/create-sprint.component';
import { MatDialog } from '@angular/material/dialog';
import { AppConstant } from 'src/app/constants/app.constants';
import { Messages } from 'src/app/constants/messages.constant';

interface Issue {
  id: any;
  title: string;
  status: string;
  description: string;
  storyPointEstimate: number;
  assignee: string;
}

interface Sprint {
  id: any;
  name: string;
  issues: Issue[];
  showSprint: boolean;
  sprintStatus: boolean;
}

@Component({
  selector: 'app-backlog',
  templateUrl: './backlog.component.html',
  styleUrls: ['./backlog.component.scss'],
})

export class BacklogComponent {
  initials: string | any;
  showComments: boolean = false;
  isEditMode: boolean = false;
  selectedSprint: Sprint | null = null;
  selectedIssueIndex: number | null = null;
  newIssueTitle: string = '';
  taskId: number | any = null;
  taskDetails: any;
  commentsForm!: FormGroup;
  createCommentRequest!: CommentData;
  employeeId: any = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
  comments: any;
  showDeleteDialog: boolean = false;
  commentToDelete: any;
  commentId: any;
  selectedBoardId: any;
  boardList: any;
  sprints: Sprint[] = [];
  selectedTaskId: number | any;
  selectedDashboardId: number | any;
  attachments: any[] = [];
  taskStatus = Messages.TASK_STATUS;
  reportType!: string;

  constructor(private dialog: MatDialog, private projectService: ProjectManagementService, private toastrService: ToastrService, private taskService: TaskManagementService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.getboardList();
    const email = sessionStorage.getItem(AppConstant.LOGIN_EMAIL);
    if (email) {
      this.initials = this.getInitials(email);
    }
    this.taskService.isEditMode$.subscribe((isEditMode: boolean) => {
      this.isEditMode = isEditMode;
    });
    this.initComments();
  }

  getboardList() {
    this.projectService.getAllProjectDashboard().subscribe(
      (dashboardList: any) => {
        if (dashboardList.status === 200) {
          this.boardList = dashboardList.data;
          if (this.boardList && this.boardList.length > 0) {
            this.selectedBoardId = this.boardList[0].dashboardId;
            sessionStorage.setItem(AppConstant.DASHBOARD_ID, this.selectedBoardId);
            this.getBacklogData(this.selectedBoardId);
          }
        }
      });
  }

  openEditSprintDialog(sprintId: any) {
    sessionStorage.setItem(AppConstant.DASHBOARD_ID, this.selectedBoardId);
    const dialogRef = this.dialog.open(CreateSprintComponent, {
      disableClose: true,
      data: { sprintId: sprintId }
    });

    dialogRef.afterClosed().subscribe(result => {
      sessionStorage.removeItem(AppConstant.DASHBOARD_ID);
    });
  }

  getBacklogData(dashboardId: any) {
    this.taskService.getBacklogData(dashboardId).subscribe(
      (backlogData: any) => {
        if (backlogData.status === 200) {
          this.sprints = backlogData.data.sprintDetails.map((sprint: any) => ({
            id: sprint.sprintId,
            name: sprint.sprintName,
            sprintStatus: sprint.sprintStatus,
            issues: sprint.taskDetails.map((task: any) => ({
              id: task.taskId,
              title: task.summary,
              status: task.status,
              description: task.description || 'No description provided',
              storyPointEstimate: task.taskPointEstimate || 0,
              assignee: 'Unassigned'
            })),
            showSprint: sprint.status === 'Active'

          }));
          const backlog = {
            id: 'Backlog',
            name: 'Backlog',
            sprintStatus: false,
            issues: backlogData.data.backlogList.map((task: any) => ({
              title: task.summary,
              id: task.taskId,
              status: task.status,
              taskId: task.taskId,
              description: task.description || 'No description provided',
              storyPointEstimate: task.taskPointEstimate || 0,
              assignee: 'Unassigned',
            })),
            showSprint: true
          };
          this.sprints.push(backlog);
        }
      });
  }

  getInitials(email: string): string {
    const parts = email.split('@')[0].split('.');
    return parts.map(part => part.charAt(0).toUpperCase()).join('');
  }

  showCommets() {
    this.showComments = true;
  }

  hideComments() {
    this.showComments = false;
  }

  expansionToggle(sprint: Sprint) {
    sprint.showSprint = !sprint.showSprint;
  }

  selectIssue(sprint: Sprint, index: number) {
    this.selectedSprint = sprint;
    this.selectedIssueIndex = index;
    this.taskId = sprint.issues[index].id;
    this.getTaskDetails(this.taskId);
    this.allComments();
  }

  drop(event: CdkDragDrop<Issue[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
    const targetSprint = this.sprints.find(sprint => `sprint-${sprint.id}` === event.container.id);
    if (targetSprint) {
      this.updateTaskOrder(targetSprint);
    }
  }

  updateTaskOrder(sprint: Sprint) {
    const taskOrder = sprint.issues.map((task, index) => ({
      taskId: task.id,
      order: index + 1,

    }));
    const dto = {
      sprintId: sprint.id,
      dashboardId: this.selectedBoardId,
      taskOrder: taskOrder
    }
    this.taskService.updateIssueOrder(dto).subscribe(
      (taskOrderResponse: any) => {
        if (taskOrderResponse.status === 200) {
          this.toastrService.clear()
          this.toastrService.success(taskOrderResponse.message);
        } else {
          this.toastrService.clear()
          this.toastrService.error(taskOrderResponse.message);
        }
      });
  }

  getConnectedDropListIds(): string[] {
    return this.sprints.map(sprint => `sprint-${sprint.id}`);
  }


  getStatusCount(sprint: Sprint, status: string): number {
    return sprint.issues.filter(issue => issue.status === status).length;
  }

  startSprint(sprint: Sprint) {
    if (!sprint.sprintStatus) {
      const dto = {
        sprintId: sprint.id,
        sprintStatus: !sprint.sprintStatus
      }
      this.taskService.startStopSprint(dto).subscribe(
        (startStopResponse: any) => {
          if (startStopResponse.status === 200) {
            this.getboardList();
            this.toastrService.clear();
            this.toastrService.success(startStopResponse.message);
          } else {
            this.toastrService.clear();
            this.toastrService.error(startStopResponse.message);
          }
        }
      )
    }
  }

  moveIssue(issue: Issue, direction: string) {
    const sprint = this.sprints.find((s) => s.issues.includes(issue));
    if (!sprint) return;

    const index = sprint.issues.indexOf(issue);
    switch (direction) {
      case 'top':
        if (index > 0) {
          sprint.issues.splice(index, 1);
          sprint.issues.unshift(issue);
        }
        break;
      case 'bottom':
        if (index < sprint.issues.length - 1) {
          sprint.issues.splice(index, 1);
          sprint.issues.push(issue);
        }
        break;
      case 'up':
        if (index > 0) {
          const temp = sprint.issues[index - 1];
          sprint.issues[index - 1] = issue;
          sprint.issues[index] = temp;
        }
        break;
      case 'down':
        if (index < sprint.issues.length - 1) {
          const temp = sprint.issues[index + 1];
          sprint.issues[index + 1] = issue;
          sprint.issues[index] = temp;
        }
        break;
    }
  }

  deleteSprint(sprintId: any) {
    this.taskService.deleteSprint(sprintId).subscribe(
      (sprintDeleteResponce: any) => {
        if (sprintDeleteResponce.status === 200) {
          this.getboardList();
          this.toastrService.clear();
          this.toastrService.success(sprintDeleteResponce.message);
        } else {
          this.toastrService.clear();
          this.toastrService.error(sprintDeleteResponce.message);
        }
      });
  }

  closeDetail() {
    this.selectedSprint = null;
    this.selectedIssueIndex = null;
    this.isEditMode = false;
  }

  editTaskDetails() {
    this.isEditMode = true;
  }

  cancelIssue() {
    this.selectedSprint = null;
    this.newIssueTitle = '';
  }

  getTaskDetails(taskId: any) {
    sessionStorage.setItem(AppConstant.TASK_ID, this.taskId);
    console.log(taskId)
    this.taskService.getTaskDetails(this.selectedBoardId, taskId).subscribe((taskDetails: any) => {
      if (taskDetails.status === 200) {
        this.taskDetails = taskDetails.data;
        this.decodeAttachments(this.taskDetails.attachments);
      }
    })
  }

  initComments() {
    this.commentsForm = this.fb.group({
      comment: ['']
    });
  }

  saveComment() {
    if (this.commentsForm.invalid) {
      this.commentsForm.markAllAsTouched();
      return;
    }
    this.createCommentRequest = {
      "comment": this.commentsForm.value.comment,
      "employeeId": this.employeeId,
      "taskId": this.taskId
    };
    this.taskService.addCommnet(this.createCommentRequest).subscribe((addComment: any) => {
      if (addComment.status == 200) {
        this.commentsForm.reset();
        this.allComments();
        this.toastrService.clear();
        this.toastrService.success(addComment.message);
      } else {
        this.toastrService.clear();
        this.toastrService.error(addComment.message);
      }
    })
  }

  getCamelCase(value: string): string {
    return value.replace(/([A-Z])/g, ' $1').replace(/^./, (str: any) => str.toUpperCase());
  }

  handleTaskUpdated(event: any) {
    if (event) {
      this.isEditMode = !this.isEditMode;
    }
  }

  allComments() {
    this.taskService.viewAllComments(this.taskId).subscribe((commentsDetails: any) => {
      this.comments = commentsDetails.data.reverse();
    });
  }

  changeTaskStatus(event: any, issue: any) {
    const dto = {
      taskId: issue.id !== 'Backlog' ? issue.id : issue.taskId,
      status: event.value
    }
    this.taskService.changeTaskStatus(dto).subscribe(
      (taskStatusResponse: any) => {
        if (taskStatusResponse.status === 200) {
          this.toastrService.clear();
          this.toastrService.success(taskStatusResponse.message);
        } else {
          this.toastrService.clear();
          this.toastrService.success(taskStatusResponse.message);
        }
      });
  }

  getRelativeTime(dateString: string): string {
    return moment(dateString).fromNow();
  }

  openDeleteDialog(comment: any) {
    this.commentToDelete = comment;
    this.showDeleteDialog = true;
  }

  confirmDelete(): void {
    if (this.commentToDelete) {
      this.taskService.deleteComment(this.taskId, this.commentToDelete.commentId).subscribe((deleteComment: any) => {
        if (deleteComment.status === 200) {
          this.showDeleteDialog = false;
          this.toastrService.clear();
          this.toastrService.success(deleteComment.message);
          this.comments = this.comments.filter((c: any) => c.commentId !== this.commentToDelete.commentId);
        } else {
          this.toastrService.clear();
          this.toastrService.error(deleteComment.message);
        }
      });
    }
  }

  cancelDelete() {
    this.showDeleteDialog = false;
  }

  getDashboard(board: any) {
    this.selectedBoardId = board.dashboardId;
    this.getBacklogData(this.selectedBoardId);
  }


  decodeAttachments(attachments: any[]) {
    this.attachments = attachments.map(attachment => {
      return {
        fileName: attachment.fileName,
        fileContent: `data:application/octet-stream;base64,${attachment.fileContent}`
      };
    });
  }

  downloadFile(fileContent: string, fileName: string) {
    const link = document.createElement('a');
    link.href = fileContent;
    link.download = fileName;
    link.click();
  }

  downlodExcel() {
    this.reportType = "Excel";
    this.showBacklogReport();
  }

  downlodCSV() {
    this.reportType = "CSV";
    this.showBacklogReport();
  }

  showBacklogReport() {
    this.taskService.showBacklogReport(this.selectedBoardId, this.reportType).subscribe((response: Blob) => {
      const fileExtension = this.reportType === 'Excel' ? 'xlsx' : 'csv';
      const fileName = `report.${fileExtension}`;
      const blob = new Blob([response], { type: response.type });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
  }

}
