import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { BookDemo } from 'src/app/model/book-demo/book-demo.model';
import { environment } from 'src/environments/enviroment';

@Injectable({
  providedIn: 'root'
})
export class BookDemoService {
  constructor(private http: HttpClient) { }

  bookDemo(bookDemo: BookDemo): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.BOOK_DEMO, bookDemo).pipe(
      map((res: any) => {
        return res;
      })
    )

  }
}
