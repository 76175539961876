import { HttpHeaders } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';

@Component({
  selector: 'app-documents-upload',
  templateUrl: './documents-upload.component.html',
  styleUrls: ['./documents-upload.component.scss']
})

export class DocumentsUploadComponent {
  @Input() id: any;
  public documentsUploadForm!: FormGroup;
  isFormSubmitted: boolean = false;
  selectedFiles: { [key: string]: File } = {};
  showDefaultName: { [key: string]: boolean } = {};
  fileInvalid: boolean = false;
  logoError: any;
  selectedFile: any;
  logoName: any;
  employeeId: any;
  readonly maxFileSize = 3 * 1024 * 1024;

  constructor(private fb: FormBuilder, private employeeDetailsService: EmployeeDetailsService, private toastrService: ToastrService) { }

  ngOnInit() {
    this.documentValidation();
  }


  onFileChange(event: any, key: string) {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (file) {
      if (file.size <= this.maxFileSize && this.isFileTypeValid(file) && file.name.length <= 25) {
        this.selectedFiles[key] = file;
        this.documentsUploadForm.get(key)?.setErrors({ null: true });
      }
      if (file.size > this.maxFileSize) {
        this.documentsUploadForm.get(key)?.setErrors({ fileSizeExceeded: true });
      }
      if (!this.isFileTypeValid(file)) {
        this.documentsUploadForm.get(key)?.setErrors({ fileTypeInvalid: true });
      }
      if (file.name.length > 25) {
        this.documentsUploadForm.get(key)?.setErrors({ fileNameInvalid: true });
      }
    }
  }

  isFileTypeValid(file: File): boolean {
    const allowedFileTypes = ['jpg', 'jpeg', 'png', 'pdf'];
    const extension = file.name.split('.').pop()?.toLowerCase();
    return !!extension && allowedFileTypes.includes(extension);
  }

  documentValidation() {
    this.documentsUploadForm = this.fb.group({
      'aadhaarCard': ['',],
      'panCard': ['',],
      'passport': [''],
      'cancelCheque': ['',],
      'employeePhoto': ['',],
      'offerLetter': ['',]
    });
  }


  submitForm() {
    this.isFormSubmitted = true;
    const headers = new HttpHeaders();
    const formData = new FormData();
    for (const [key, file] of Object.entries(this.selectedFiles)) {
      formData.append(key, file, file.name);
    }
    this.employeeDetailsService.uploadEmployeeDocument(sessionStorage.getItem(AppConstant.COMPANY_ID), this.id, formData, headers).subscribe((data: any) => {
      if (data.status === 200) {
        this.toastrService.clear();
        this.toastrService.success(data.message);
      } else {
        this.toastrService.clear();
        this.toastrService.error(data.message);
      }
    });
  }

}
