import { API_END_POINTS } from "../../config/api-endpoint.config";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/enviroment';
import { AddDepartment, DepartmentData } from '../../model/employeeDetails';
import { Observable, map } from "rxjs";
import { ToastrService } from "ngx-toastr";
@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
  constructor(private http: HttpClient, private toastrService: ToastrService) { }

  deleteDepartment(companyId: any, id: any): Observable<any> {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_DEPARTMENT + API_END_POINTS.SEPERATOR + companyId + API_END_POINTS.SEPERATOR + id).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
  

  getDepartmentList(companyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.DEPARTMENT_LIST + companyId).pipe(
      map((res: any) => {
        return res.data;
      })
    );
  }
  

  updateDepartment(addDepartmentRequest: DepartmentData): Observable<any> {
    return this.http.put(environment.apiUrl + API_END_POINTS.UPDATE_DEPARTMENT, addDepartmentRequest).pipe(
      map((res: any) => {
        return res; 
      })
    );
  }
  

  addDepartment(addDepartment: AddDepartment): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_DEPARTMENT, addDepartment).pipe(
      map((res: any) => {
        return res; 
      })
    );
  }
  

}

