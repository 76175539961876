
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { OTP, RegistrationData, VerifyEmail } from 'src/app/model/registration.model';
import { RegistrationService } from 'src/app/services/registration/registrationService';
import { OnInit, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { PayNowList } from 'src/app/model/payrun/payrun.model';
import { PayRunService } from 'src/app/services/pay-run/pay-run.service';
import { LocationStrategy } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { BankAdviceDialogComponent } from 'src/app/shared/bank-advice-dialog/bank-advice-dialog.component';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})

export class VerifyEmailComponent implements OnInit, OnDestroy {
  showSpinner: boolean = false;
  public otpVerificationForm!: FormGroup;
  adminEmail: any;
  private countdownSubscription: Subscription | undefined;
  remainingTime: number = 300;
  displayTime: string = '5:00';
  isCountdownComplete: boolean = false;
  isOtpSending: boolean = false;
  isResendClicked: boolean = false;
  registrationLink: string = AppConstant.REGISTRATION;
  payRun: boolean = false;

  @Input() email: string | any;
  @Output() verifiedOTP = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private payRunService: PayRunService, private router: Router, private toastrService: ToastrService, private registrationService: RegistrationService, public dialog: MatDialog) {
  }

  ngOnInit() {
    if (sessionStorage.getItem("payRunList") != null) {
      this.payRun = true;
    }
    this.initOtpVerificationForm();
    let email = sessionStorage.getItem(AppConstant.CREATE_REGISTRATION_REQUEST);
    let createEmailInfo: RegistrationData = JSON.parse(email || '{}');
    this.adminEmail = createEmailInfo;
    this.startCountdown();
  }

  ngOnDestroy() {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }
  goPayRun() {
    this.dialog.closeAll();
    this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.PAY_RUN]);
  }

  initOtpVerificationForm() {
    this.otpVerificationForm = this.fb.group({
      'otp': ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(6), Validators.maxLength(6)]],
    });
  }

  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumber(event);
  }

  countdownComplete() {
    this.isCountdownComplete = true;
  }

  resendOtp() {
    if (!this.isOtpSending) {
      this.isResendClicked = true;
      this.isOtpSending = true;
      this.isCountdownComplete = false;
      this.remainingTime = 300;
      if (this.countdownSubscription) {
        this.countdownSubscription.unsubscribe();
      }
      this.showSpinner = true;
      if (this.payRun) {
        this.registrationService.verifyEmailForPayrun(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
          (data: any) => {
            if (data.status === 200) {
              this.isOtpSending = false;
              this.startCountdown();
              this.toastrService.success(AppConstant.OTP_SEND_SUCCESSFULLY);
              this.showSpinner = false;
            }
            else {
              this.isOtpSending = false;
              this.toastrService.clear();
              this.toastrService.error(AppConstant.RESEND_OTP_ERROR);
              this.showSpinner = false;
            }
          },
        );
      } else {
        const sendOtp: VerifyEmail = {
          email: this.adminEmail.email,
          name: this.adminEmail.name,
        };
        this.registrationService.verifyEmail(sendOtp).subscribe(
          (data: any) => {
            if (data.status === 200) {
              this.isOtpSending = false;
              this.startCountdown();
              this.toastrService.success(AppConstant.OTP_SEND_SUCCESSFULLY);
              this.showSpinner = false;
            }
            else {
              this.isOtpSending = false;
              this.toastrService.clear();
              this.toastrService.error(AppConstant.RESEND_OTP_ERROR);
              this.showSpinner = false;
            }
          },
        );
      }
    }
  }

  verifyOtp() {
    const otpValue = this.otpVerificationForm.value.otp;
    if (!otpValue || otpValue.length !== 6) {
      return;
    }
    this.showSpinner = true;
    let otpVerification: OTP;
    if (this.payRun) {
      otpVerification = {
        "email": sessionStorage.getItem(AppConstant.LOGIN_EMAIL),
        "otp": otpValue
      };
    } else {
      otpVerification = {
        "email": this.adminEmail.email,
        "otp": otpValue
      };
    }
    this.registrationService.verifyOtp(otpVerification).subscribe(
      (data: any) => {
        if (data.status == 200) {
          this.verifiedOTP.emit(true);
          if (this.payRun) {
            const dialogRef = this.dialog.open(BankAdviceDialogComponent);

            dialogRef.afterClosed().subscribe(result => {
              if (result === 'download') {
                const dto: PayNowList = JSON.parse(sessionStorage.getItem("payRunList") || "{}");
                this.payRunService.payNow(dto).subscribe(
                  (data: any) => {
                    if (data.status === 200) {
                      this.toastrService.clear();
                      this.toastrService.success(data.message);
                    } else {
                      this.toastrService.clear();
                      this.toastrService.error(data.message);
                    }
                  }
                );
              }
            });
          } else {
            this.router.navigate([AppConstant.VIEW_COMPANY_PROFILE]);
          }
        } else {
          this.toastrService.clear();
          this.toastrService.error(data.message);
        }
      });
  }

  startCountdown() {
    this.countdownSubscription = interval(1000).subscribe(() => {
      this.remainingTime--;
      const minutes = Math.floor(this.remainingTime / 60);
      const seconds = this.remainingTime % 60;
      if (this.remainingTime === 0) {
        this.countdownComplete();
        this.countdownSubscription?.unsubscribe();
      }
      this.displayTime = `${minutes}m  ${seconds}s`;
    });
  }

  backToRegistration() {
    sessionStorage.clear();
    this.router.navigate([AppConstant.REGISTRATION]);
  }
}