<div class="header">
    <div class="back-button">
        <mat-icon svgIcon="arrow-back-double" class="rewind" (click)="goBackToEmployeeProfile()"></mat-icon>
        <h3>{{'family_and_emergency_contact_details' | translate}}</h3>
    </div>
</div>
<mat-divider></mat-divider>
<div class="container">
    <mat-card class="card">
        <mat-card-content>
            <div class="form">
                <div class="form-row">
                    <div class="row">
                        <div class="family-info">
                            <div class="heading">
                                <h4 class="data-key">{{'name'|translate}} : </h4>
                            </div>
                            <span class="data-value">
                                {{ capitalizeFirstLetter(familyEmergencyContact.firstName) }}
                                {{ capitalizeFirstLetter(familyEmergencyContact.middleName) }}
                                {{ capitalizeFirstLetter(familyEmergencyContact.lastName) }}
                            </span>
                        </div>

                        <div class="family-info">
                            <div class="heading">
                                <h4 class="data-key">{{'gender'|translate}} : </h4>
                            </div>
                            <span class="data-value">{{familyEmergencyContact.gender}}</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="family-info">
                            <div class="heading">
                                <h4 class="data-key">{{'relationship'|translate}} : </h4>
                            </div>
                            <span class="data-value">{{familyEmergencyContact.relationship}}</span>
                        </div>

                        <div class="family-info">
                            <div class="heading">
                                <h4 class="data-key">{{'relationship_start_date'|translate}} : </h4>
                            </div>
                            <span class="data-value">{{familyEmergencyContact.relationshipStartDate| date:
                                'dd/MM/yyyy'}}</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="family-info">
                            <div class="heading">
                                <h4 class="data-key">{{'contact_number'|translate}} : </h4>
                            </div>
                            <span class="data-value">{{familyEmergencyContact.contactNumber}}</span>
                        </div>

                        <div class="family-info">
                            <div class="heading">
                                <h4 class="data-key">{{'emergency_contact'|translate}} : </h4>
                            </div>
                            <span class="data-value">{{ ( familyEmergencyContact.isEmergencyContact ? 'Yes' : 'No') |
                                translate}}</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="family-info">
                            <div class="heading">
                                <h4 class="data-key">{{'email'|translate}} : </h4>
                            </div>
                            <span class="data-value">{{familyEmergencyContact.email}}</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="family-info">
                            <div class="heading">
                                <h4 class="data-key">{{'address_line_1'|translate}} : </h4>
                            </div>
                            <span class="data-value">{{familyEmergencyContact.addressLine1}}</span>
                        </div>

                        <div class="family-info">
                            <div class="heading">
                                <h4 class="data-key">{{'address_line_2'|translate}} : </h4>
                            </div>
                            <span class="data-value">{{familyEmergencyContact.addressLine2}}</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="family-info">
                            <div class="heading">
                                <h4 class="data-key">{{'country'|translate}} : </h4>
                            </div>
                            <span class="data-value">{{familyEmergencyContact.country}}</span>
                        </div>

                        <div class="family-info">
                            <div class="heading">
                                <h4 class="data-key">{{'state'|translate}} : </h4>
                            </div>
                            <span class="data-value">{{familyEmergencyContact.state}}</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="family-info">
                            <div class="heading">
                                <h4 class="data-key">{{'city'|translate}} : </h4>
                            </div>
                            <span class="data-value">{{familyEmergencyContact.city}}</span>
                        </div>

                        <div class="family-info">
                            <div class="heading">
                                <h4 class="data-key">{{'pincode'|translate}} : </h4>
                            </div>
                            <span class="data-value">{{familyEmergencyContact.pinCode}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>