<div class="setting__main_container">
    <span matTooltip="{{'back_to_dashboard' | translate}}" (click)="goToDashboard()">
        <mat-icon svgIcon="arrow-back-double" class="rewind"></mat-icon>
    </span>
    <div class="setting__heading_container">
        <h3>{{'employees_list'|translate}}</h3>
    </div>
</div>

<div class='md-padding' layout="row" flex>
    <div layout="row" layout-xs="column" flex>
        <div class="employeeList">
            <ag-grid-angular (gridReady)="onGridReady($event)" #agGride style="height: 27rem; width:100%;"
                class="ag-theme-alpine" [rowData]="employeeData" [rowSelection]="'multiple'" [animateRows]="true"
                [columnDefs]="hrTableCol" [defaultColDef]="hrColDef" [enableSorting]="true" [enableFilter]="true"
                [pagination]="true" [paginationPageSize]="10">
            </ag-grid-angular>
        </div>
    </div>
</div>