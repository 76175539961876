import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { DesignationData, } from 'src/app/model/employeeDetails';
import { DesignationService } from 'src/app/services/designation/designationService';
import { ToastrService } from 'ngx-toastr';
import { Messages } from 'src/app/constants/messages.constant';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppConstant } from 'src/app/constants/app.constants';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-view-designation',
  templateUrl: './view-designation.component.html',
  styleUrls: ['./view-designation.component.scss'],
})
export class ViewDesignationComponent {
  addDesignationFlag: boolean = true;
  isEmployee: any;
  currentUserId = sessionStorage.getItem('role');
  designationColDef: any;
  designationData!: DesignationData[];
  designationForm: FormGroup;
  showAddPopup: boolean = false;
  error: any;
  isAddMode: boolean = true;
  designationId: any | undefined;

  constructor(
    private renderer: Renderer2,
    private designationService: DesignationService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private toastrService: ToastrService
  ) {
    this.designationForm = this.fb.group({
      designationName: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.getDesignation();
    this.designationColDef = { sortable: true, filter: true, resizable: true, };
  }

  designationTableCol = [
    { headerName: 'Designation Id', field: 'id', floatingFilter: true, unSortIcon: true },
    { field: Messages.DESIGNATION, floatingFilter: true, unSortIcon: true },
    { field: Messages.USERS, floatingFilter: true, unSortIcon: true },
    { field: Messages.DATE, floatingFilter: true, unSortIcon: true },
    { field: 'Action', cellRenderer: this.actionCellRenderer.bind(this), width: 120, },
  ];

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, colorClass: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      this.renderer.addClass(button, 'mat-icon-button');
      this.renderer.addClass(button, 'ag-grid-custom-button');
      this.renderer.addClass(button, colorClass);
      this.renderer.listen(button, 'click', clickHandler);
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.appendChild(cell, button);
    };
    createButton('edit', 'custom-black', () => this.editDesignation(params.data));
    createButton('delete', 'custom-red', () => this.deleteDesignation(params.data));
    return cell;
  }

  getDesignation() {
    this.designationService.getDesignationList(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((data: any) => {
      if (data && Array.isArray(data)) {
        this.designationData = data.map((item: any) => {
          const dateTimeString = item.createdDate;
          const dateTime = new Date(dateTimeString);
          const dateOnlyString = dateTime.toISOString().split('T')[0];
          return {
            ...item,
            createdDate: dateOnlyString
          };
        });
      } else {
        this.designationData = [];
      }
    });
  }


  toggleAddPopup(): void {
    if (this.showAddPopup) {
      this.designationForm.reset();
      this.addDesignationFlag = true;
    }
    this.showAddPopup = !this.showAddPopup;
  }

  deleteDesignation(data: any) {
    if (confirm(AppConstant.DELETE_CONFIRMATION_MASSAGE)) {
      this.designationService.deleteDesignation(sessionStorage.getItem(AppConstant.COMPANY_ID), data.id).subscribe(
        (data) => {
          if (data.status === 404) {
            this.toastrService.clear();
            return this.toastrService.error(data.message);
          }
          const index = this.designationData.findIndex((item) => item.id === data.id);
          if (index !== -1) {
            this.designationData.splice(index, 1);
            this.cdr.detectChanges();
          }
          this.getDesignation();
          return this.toastrService.success(data.message);
        },

      );
    }
    this.getDesignation();
  }

  addDesignation() {
    const designationName = this.designationForm.value.designationName;

    if (!designationName || designationName.trim() === '') {
      return;
    }

    let designationDetails: DesignationData = {
      designation: designationName,
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      id: 0,
    };

    this.designationService.addDesignation(designationDetails).subscribe(
      (data: any) => {
        if (data.status === 404) {
          this.toastrService.clear();
          return this.toastrService.error(data.message);
        }
        this.designationForm.reset();
        this.toggleAddPopup();
        this.getDesignation();
        return this.toastrService.success(data.message);

      },
      (error) => {
        this.error = error;
        if (error.status !== '200') {
          this.getDesignation();
        }

      }
    );
  }

  updateDesignation() {
    const designationName = this.designationForm.value.designationName;

    if (!designationName || designationName.trim() === '') {
      return;
    }

    let designationDetails: DesignationData = {
      designation: designationName,
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      id: this.designationId,
    };

    this.designationService.updateDesignation(designationDetails).subscribe(
      (data: any) => {
        if (data.status === 404) {
          this.toastrService.clear();
          return this.toastrService.error(data.message);
        }
        this.addDesignationFlag = true;
        this.isAddMode = true;
        this.showAddPopup = false;
        this.getDesignation();
        return this.toastrService.success(data.message);
      },
      (error) => {
        this.error = error;
        if (error.status !== '200') {
          this.getDesignation();
        }
      }
    );
    this.designationForm.get('designationName')?.setValue('');
    this.showAddPopup = false;
  }

  editDesignation(data: any) {
    this.designationId = data.id;
    this.addDesignationFlag = false;
    this.isAddMode = false;
    this.designationForm.patchValue({
      designationName: data.designation
    });
    this.toggleAddPopup();
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

}
