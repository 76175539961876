<div class="setting__main_container">
    <div class="setting__heading_container">
        <mat-icon matTooltip="{{'back_to_dashboard' | translate}}" (click)="goToBack()" svgIcon="arrow-back-double"
            class="rewind"></mat-icon>
        <h3>{{ 'payrun_approval' | translate }}</h3>
    </div>
    <div class="form">
        <div class="main-content">
            <div class="row">
                <div class="title">
                    <h4><strong>{{'pay_run_id' | translate}} :</strong></h4>
                </div>
                <div class="value">
                    <h4>{{payrunData.id}}</h4>
                </div>
            </div>

            <div class="row">
                <div class="title">
                    <h4><strong>{{'pay_run_month' | translate}} :</strong></h4>
                </div>
                <div class="value">
                    <h4>{{payrunData.payrunMonth}}</h4>
                </div>
            </div>
        </div>

        <div class="main-content">
            <div class="row">
                <div class="title">
                    <h4><strong>{{'pay_run_group' | translate}} :</strong></h4>
                </div>
                <div class="value">
                    <h4>{{payrunData.groups}}</h4>
                </div>
            </div>

            <div class="row">
                <div class="title">
                    <h4><strong> {{'pay_run_amount' | translate}} : </strong></h4>
                </div>
                <div class="value">
                    <h4>{{payrunData.amount | number:'1.2-2' }}</h4>
                </div>
            </div>
        </div>
    </div>


    <ag-grid-angular class="ag-theme-alpine" style="height:460px" [columnDefs]="payruncolumnDefs"
        [rowData]="payrunsrowData" [tooltipShowDelay]="0" [tooltipHideDelay]="2000" [floatingFiltersHeight]="40"
        [sideBar]="true" [pagination]="pagination" [paginationPageSize]="paginationPageSize"
        [paginationPageSizeSelector]="paginationPageSizeSelector">
    </ag-grid-angular>
</div>

<app-loader></app-loader>