<div class="setting__main_container">
    <span matTooltip="{{'back_to_dashboard' | translate}}" (click)="goToDashboard()">
        <mat-icon svgIcon="arrow-back-double" class="rewind"></mat-icon>
    </span>
    <div class="setting__heading_container">
        <h3>{{'attendance_request_history' | translate}}</h3>
    </div>
</div>

<div class='md-padding' layout="row" flex>
    <div layout="row" layout-xs="column" flex>

        <div class="employee-attendance-details">
            <ag-grid-angular (gridReady)="onGridReady($event)" style="height: 27rem; width: 100%;"
                class="ag-theme-alpine" [rowData]="attendanceHistory" [rowSelection]="'multiple'" [animateRows]="true"
                [columnDefs]="leaveHistoryTableCol" [defaultColDef]="attendanceColDef" [enableSorting]="true"
                [enableFilter]="true" [pagination]="true" [paginationPageSize]="10">
            </ag-grid-angular>
        </div>
    </div>
</div>