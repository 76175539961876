import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { CreateSprintComponent } from '../create-sprint/create-sprint.component';
import { ProjectManagementService } from 'src/app/services/project-management/project-management.service';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { Messages } from 'src/app/constants/messages.constant';

@Component({
  selector: 'app-view-sprint',
  templateUrl: './view-sprint.component.html',
  styleUrls: ['./view-sprint.component.scss']
})
export class ViewSprintComponent {
  sprintColDef: any
  role = sessionStorage.getItem(AppConstant.LOGIN_PAGE);
  renderer: any;
  sprintList: any[] = [];
  dashboardId: any;
  boardList: any;
  durationList = Messages.DURATION_LIST;

  constructor(private route: ActivatedRoute, private router: Router, private dialog: MatDialog, private projectService: ProjectManagementService) { }

  ngOnInit(): void {
    this.getboardList();
    this.sprintColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
  }

  getboardList() {
    this.projectService.getAllProjectDashboard().subscribe(
      (dashboardList: any) => {
        if (dashboardList.status === 200) {
          this.boardList = dashboardList.data;
          if (this.boardList && this.boardList.length > 0) {
            this.route.snapshot.params['id'] !== undefined ?
              this.dashboardId = Number(this.route.snapshot.params['id']) : this.dashboardId = this.boardList[0].dashboardId;
            this.getAllSprintList(this.dashboardId);
          }
        }
      });
  }

  getDashboard(board: any) {
    this.dashboardId = board.dashboardId;
    this.getAllSprintList(this.dashboardId);
  }

  goToDashboard() {
    this.router.navigate([AppConstant.TASK_MANAGEMENT_SIDENAV + AppConstant.SEPERATOR + AppConstant.PROJECT_DASHBOARD]);
  }

  createSprint() {
    sessionStorage.setItem(AppConstant.DASHBOARD_ID, this.dashboardId);
    const dialogRef = this.dialog.open(CreateSprintComponent, {
      disableClose: true,
      data: {
        dashboardId: this.dashboardId
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      sessionStorage.removeItem(AppConstant.DASHBOARD_ID);
      this.getAllSprintList(this.dashboardId)
    });

  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  sprintTableCol = [
    { field: "sprintName", width: 70, floatingFilter: true, unSortIcon: true, },
    { field: "sprintDuration", floatingFilter: true, unSortIcon: true, width: 50, cellRenderer: (params: any) => this.durationList.find(d => Number(d.days) === Number(params.value))?.projectCode },
    { field: "sprintStartDate", floatingFilter: true, unSortIcon: true, width: 50, cellRenderer: ValidationUtil.dateFormatter },
    { field: "sprintEndDate", floatingFilter: true, unSortIcon: true, width: 50, cellRenderer: ValidationUtil.dateFormatter },
    { field: "sprintGoal", floatingFilter: true, unSortIcon: true, width: 70 },
    {
      field: "Action",
      cellRenderer: this.actionCellRenderer.bind(this),
      width: 7
    }
  ];

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');
    cell.classList.add('action-cell');

    const createButton = (icon: string, color: string, clickHandler: (event: Event) => void) => {
      const button = document.createElement('button');
      const iconElement = document.createElement('span');

      button.addEventListener('click', clickHandler);
      iconElement.setAttribute('role', 'img');
      iconElement.style.fontSize = '14px';
      iconElement.classList.add('material-icons');
      iconElement.style.color = color;
      iconElement.innerText = icon;
      button.style.border = 'none';
      button.style.backgroundColor = 'transparent';
      button.appendChild(iconElement);
      cell.appendChild(button);
    };

    const editIconClickHandler = (event: Event) => {
      event.stopPropagation();
      this.openEditSprintDialog(params.data.sprintId);
    };

    createButton('edit', 'green', editIconClickHandler);

    return cell;
  }

  openEditSprintDialog(sprintId: number) {
    sessionStorage.setItem(AppConstant.DASHBOARD_ID, this.dashboardId);
    const dialogRef = this.dialog.open(CreateSprintComponent, {
      disableClose: true,
      data: { sprintId: sprintId }
    });

    dialogRef.afterClosed().subscribe(result => {
      sessionStorage.removeItem(AppConstant.DASHBOARD_ID);
      this.getAllSprintList(this.dashboardId)
    });
  }

  getAllSprintList(dashboardId: any) {
    this.projectService.getAllSprintList(dashboardId).subscribe((sprintList: any) => {
      this.sprintList = sprintList.data;
    })
  }
}
