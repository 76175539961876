export class LandingPageMasterData {
    public static readonly LANDING_PAGE_FEATURES = [
        // {
        //     icon: 'monetization_on',
        //     title: 'effective_payroll_system',
        //     content: 'effective_payroll_system_content'
        // },
        {
            icon: 'groups',
            title: 'workforce_management',
            content: 'workforce_management_content'
        },
        {
            icon: 'monetization_on',
            title: 'customized_group_payroll',
            content: 'customize_group_payroll_content'
        },
        {
            icon: 'receipt',
            title: 'tax_compliance',
            content: 'tax_compliance_content'
        },
        {
            icon: 'work',
            title: 'project_management',
            content: 'project_management_content'
        },
        {
            icon: 'perm_contact_calendar',
            title: 'attendance_management',
            content: 'attendance_management_content'
        },
        {
            icon: 'event_busy',
            title: 'leave_management',
            content: 'leave_management_content'
        }, {
            icon: 'supervisor_account',
            title: 'efficient_assests_management',
            content: 'efficient_assests_management_content'
        },
        {
            icon: 'school',
            title: 'employee_training_system',
            content: 'employee_training_system_content'
        },
        {
            icon: 'assessment',
            title: 'automated_appraisal_system',
            content: 'automated_appraisal_system_content'
        },
        {
            icon: 'thumb_up',
            title: 'automated_approvals_system',
            content: 'automated_approvals_system_content'
        },
        {
            icon: 'help',
            title: 'employee_service_request',
            content: 'employee_service_request_content'
        },
        {
            icon: 'event',
            title: 'company_event_management',
            content: 'company_event_management_content'
        },
        {
            icon: 'description',
            title: 'document_and_report_generetion',
            content: 'document_and_Repost_Generation_content'
        },
        {
            icon: 'subscriptions',
            title: 'dashbord_billing_and_subscription_management_system',
            content: 'dashboard_billing_and_subscription_management_system_content'
        },
        {
            icon: 'settings_applications',
            title: 'customized_setting',
            content: 'customized_setting_content'
        },


    ]

    public static readonly MASTER_TEAM_DATA = [
        {
            photo: '../../../assets/images/landing-page/team/amol.jpg',
            name: 'Amol Badgujar',
            designation: 'CEO',
            linkedin: 'https://www.linkedin.com/in/amol-badgujar-053ab262/'
        },
        {
            photo: '../../../assets/images/landing-page/team/dipali.jpg',
            name: 'Dipali Badgujar',
            designation: 'Project Lead',
            linkedin: 'https://www.linkedin.com/in/dipali-b-375b282a7/'
        },
        {
            photo: '../../../assets/images/landing-page/team/rushi.jpg',
            name: 'Rushikesh Sapkale',
            designation: 'Sr. Software Engineer',
            linkedin: 'https://www.linkedin.com/in/rushikesh-sapkale-9ba512307/'
        },
        {
            photo: '../../../assets/images/landing-page/team/priyanka.jpg',
            name: 'Priyanka Lalage',
            designation: 'Sr. Software Engineer',
            linkedin: 'https://www.linkedin.com/in/priyanka-lalage-ba602422a/'
        }
    ];
}