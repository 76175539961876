<div *ngIf="!payRun" class="header">
  <app-navbar></app-navbar>
</div>
<mat-card>
  <mat-card-content>
    <div class="otp-verification-container">
      <h2>{{ 'otp_verification' | translate }}</h2>
      <div class="sent-otp">{{ 'enter_otp_code_sent' | translate }}</div>
      <form [formGroup]="otpVerificationForm" (ngSubmit)="verifyOtp()">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>{{ 'otp_verification' | translate }}</mat-label>
          <input matInput id="otp" (keypress)="validateNumber($event)"
            minlength="6" maxlength="6" formControlName="otp" required />
          <mat-error *ngIf="otpVerificationForm.controls['otp'].hasError('required')">
            {{ 'otp_required' | translate }}
          </mat-error>
          <mat-error *ngIf="otpVerificationForm.controls['otp'].hasError('invalidOtp')">
            {{ 'invalid_otp' | translate }}
          </mat-error>
      
        </mat-form-field>
        <p *ngIf="!isCountdownComplete">{{'remaining_time'| translate }}: {{ displayTime }}</p>
        <p *ngIf="isCountdownComplete">
          <u><a class="resend-otp" (click)="resendOtp()" href="javascript:void(0);" [class.clicked]="isResendClicked">{{
              'resend_otp' | translate }}</a></u>
        </p>
        <div><u *ngIf="!payRun"><a class="backToRegistration" routerLink='/{{registrationLink}}'>{{'back'| translate
              }}</a></u>
              <button *ngIf="payRun" (click)="goPayRun()" class="cancel-btn">{{'cancel'|translate}}</button>
          <button class="button-right" type="submit">{{ 'verify_otp' | translate }}</button>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>
<app-loader></app-loader>