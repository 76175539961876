import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { TraningService } from 'src/app/services/traning/traning.service';

@Component({
  selector: 'app-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls: ['./image-slider.component.scss']
})
export class ImageSliderComponent {
  @Input() data!: { type: string, adminRole: boolean };
  totalCards!: number;
  currentPage: number = 1;
  cardsPerPage: number = 5;
  totalPages!: number;
  objectData!: any[];
  pagePosition: string = "0%";
  stars: number[] = [1, 2, 3, 4, 5];
  selectedValue: number = 0;
  isTrainingAvailable: boolean = false;

  @ViewChild("container", { static: true, read: ElementRef })
  container!: ElementRef;

  @HostListener("window:resize") windowResize() {
    this.totalPages = Math.ceil(this.totalCards / this.cardsPerPage);
    if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages;
    }
    this.populatePagePosition();
  }

  constructor(private loader: LoaderService, private route: Router, private trainingservice: TraningService) { }

  ngOnInit() {
    this.loader.show();
    if (this.data.adminRole) {
      this.getTrainingList();
    } else {
      this.getTrainingList();
    }
  }

  getTrainingList() {
    this.trainingservice.getTrainingList(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        this.objectData = (this.data.type === 'compulsary' ? data.data?.Compulsory : data.data?.Optional).map((training: any) => {
          this.selectedValue = 4.8;
          return {
            id: training.id,
            image: 'data:image/jpeg;base64,' + training?.thumbnail?.fileContent,
            thumbImage: 'data:image/jpeg;base64,' + training?.thumbnail?.fileContent,
            title: training.trainingName,
            header: training.trainingType,
            rating: 5
          };
        });
        this.isTrainingAvailable = this.objectData.length > 0;
        this.totalCards = this.objectData.length;
        this.totalPages = Math.ceil(this.totalCards / this.cardsPerPage);
        this.populatePagePosition();
        this.loader.hide();
      });
  }

  getTrainingListByEmployeeId() {
    this.trainingservice.getAllTrainingDetailsOfEmp(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        this.objectData = (this.data.type === 'compulsary' ? data.data?.Compulsory : data.data?.Optional).map((training: any) => {
          this.selectedValue = 4.8;
          return {
            id: training.id,
            image: 'data:image/jpeg;base64,' + training?.thumbnail?.fileContent,
            thumbImage: 'data:image/jpeg;base64,' + training?.thumbnail?.fileContent,
            title: training.trainingName,
            header: training.trainingType,
            rating: 5
          };
        });
        this.isTrainingAvailable = this.objectData.length > 0;
        this.totalCards = this.objectData.length;
        this.totalPages = Math.ceil(this.totalCards / this.cardsPerPage);
        this.populatePagePosition();
        this.loader.hide();
      });
  }

  getImage(imageData: any) {
    this.route.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.VIEW_DETAILS_OF_TRAINING + AppConstant.SEPERATOR + imageData.id]);
  }

  countStar(star: any) {
  }

  addClass(star: any) {
    let ab = "";
    for (let i = 0; i < star; i++) {
      ab = "starId" + i;
      document.getElementById(ab)?.classList.add("selected");
    }
  }

  removeClass(star: any) {
    let ab = "";
    for (let i = star - 1; i >= this.selectedValue; i--) {
      ab = "starId" + i;
      document.getElementById(ab)?.classList.remove("selected");
    }
  }

  changePage(incrementor: number) {
    this.currentPage += incrementor;
    this.currentPage = Math.max(1, Math.min(this.currentPage, this.totalPages));
    this.populatePagePosition();
  }

  populatePagePosition() {
    const offset = (this.currentPage - 1) * this.cardsPerPage;
    this.pagePosition = `calc(${-offset * (100 / this.totalCards)}%)`;
  }
}
