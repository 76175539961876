<div class="section-details">
  <div class="section-details">
    <h4>{{'section_name' | translate}} :- </h4><span class="margin-auto">{{ convertLabel(data.name) }}</span>
  </div>
  <div class="section-details">
    <h4>{{'section_limit' | translate}} :- </h4><span class="margin-auto">{{ data.amount | number: '1.2-2' }}</span>
  </div>
  <div class="margin-auto">
    <button (click)="addCategoryPopup()">{{'add_category' | translate}}</button>
  </div>
</div>

<form [formGroup]="form">
  <div class="last-year-summary">
    <div class="last-year-summary-table">
      <div class="last-year-summary-table-row-header">
        <span class="section-name-heading">{{ 'last_financial_year_summary' | translate }}</span>
        <span>{{ 'maximum_amount' | translate }}</span>
        <span>{{ 'declared_amount' | translate }}</span>
        <span *ngIf="documentSubmit">{{'attachment' | translate }}</span>
      </div>
      <mat-divider></mat-divider>
      <div formArrayName="amounts">
        <div *ngFor="let item of amounts.controls; let i = index" [formGroupName]="i">
          <div class="last-year-summary-table-row">
            <span class="section-heading">{{ convertLabel(sampleData[i].name) }}</span>
            <span>{{ sampleData[i].amount | number: '1.2-2' }}</span>
            <div>
              <input type="number" (keydown)="preventNegativeInput($event)" class="declaration-amount-input" min="0"
                maxlength="10" step="1" (input)="declarationAmount($event, sampleData[i])"
                formControlName="declaredAmount">
              <mat-error *ngIf="amounts.controls[i].get('declaredAmount')?.hasError('maxDigitsExceeded')">
                {{ 'amount_cannot_exceed_10_digits' | translate }}
              </mat-error>
              <mat-error *ngIf="amounts.controls[i].get('declaredAmount')?.hasError('negativeAmount')">
                {{'please_enter_valid_amount'|translate}}
              </mat-error>
            </div>
            <input type="file" #fileInput style="display: none" (change)="onFileSelected($event, sampleData[i])">
            <button *ngIf="documentSubmit" class="attachment-button" (click)="fileInput.click()">
              <i class="material-icons">{{'attachment' | translate}}</i>
            </button>
          </div>
          <mat-divider></mat-divider>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="back-button">
  <div class="back-button-div">
    <button (click)="goBack()">{{ 'back' | translate }}</button>
  </div>
  <div class="button-div">
    <button class="cancel-btn" matStepperNext (click)="skipSection()">{{ 'skip' | translate }}</button>
    <button (click)="saveSection(data)">{{ 'save_and_next' | translate }}</button>
  </div>
</div>

<div *ngIf="showPopup" class="category-list-popup">
  <div class="add-popup">
    <h4 class="tittle" mat-dialog-title>{{'category_list' | translate}}</h4>
    <div class="data-container">
      <mat-dialog-content>
        <div *ngFor="let category of data.data">
          <mat-checkbox [(ngModel)]="selectedCategories[category.name]">{{ category.name }}</mat-checkbox>
        </div>
      </mat-dialog-content>
    </div>
    <mat-dialog-actions class="button-div">
      <button class="cancel-btn" (click)="cancel()">{{ 'cancel' | translate }}</button>
      <button (click)="saveCategoryList()">{{ 'save' | translate }}</button>
    </mat-dialog-actions>
  </div>
</div>