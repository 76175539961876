import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { EmployeeDetails, Model } from 'src/app/model/employeeDetails';
import { DepartmentService } from 'src/app/services/department/departmentService';
import { DesignationService } from 'src/app/services/designation/designationService';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { ResignationService } from 'src/app/services/resignation/resignation.service';
import { FormatUtil } from 'src/app/utils/format.utils';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { ExitHistoryComponent } from '../../exit-process/exit-history/exit-history.component';
import { RevertExitProcessComponent } from '../../exit-process/revert-exit-process/revert-exit-process.component';
import { ImageViewerComponent } from '../image-viewer/image-viewer/image-viewer.component';
import { PdfViewerComponent } from 'src/app/shared/pdf-viewer/pdf-viewer.component';
import { Messages } from 'src/app/constants/messages.constant';

@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss']
})
export class EmployeeDetailsComponent {
  @ViewChild('slideToggleRef') slideToggleRef!: MatSlideToggle;
  activeEmployeeData: any
  @Input() id: any;
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  paymentInfo: any;
  personalInfo: any;
  monthlyCtc: number | undefined;
  annualBasic: number | undefined;
  annualHra: number | undefined;
  annualOtherAllowance: number | undefined;
  annualProvidentFund: number | undefined;
  searchYear: any;
  isDropdownOpen: boolean = false;
  employeeImage !: string;
  isEditMode: boolean = false;
  basicDetails!: FormGroup;
  documentsUploadForm!: FormGroup;
  editPersonalMode: boolean = false;
  employeeDetails !: EmployeeDetails;
  departmentList!: Model[];
  designationList!: Model[];
  managerList!: Model[];
  payRunGroupList!: Model[];
  activeRunGroupList!: Model[];
  branchList: string[] = [];
  countryList: string[] = []
  stateList: string[] = []
  cityList: string[] = []
  documents: string[] = [];
  documentCount: number = 0;
  selectedCountry: any;
  selectedState: any;
  selectedCity: any;
  isLoading: boolean = false;
  verifiedIFSC: boolean = false;
  ifscCode: any;
  errorMessage: any;
  documentUpload: boolean = false;
  pdfViewer: boolean = true;
  pfDetails!: FormGroup;
  createSalaryDetailsForm!: FormGroup;
  pfPopupView: boolean = false;
  pfToggle: boolean = false;
  pTToggle: boolean = false;
  blob: Blob | undefined;
  isExit: boolean = false;
  fnfData: any;
  lastWorkingMonthYear: any;
  isLastWorkingDateValid: boolean = false;
  employeeIsExit: boolean = false;
  employeeFNFIsExit: boolean = false;
  sixtyYearsOldDate: Date = undefined as any;
  futureSixMonths: Date = undefined as any;
  ageOfMaturityDate: Date = undefined as any;
  dojMinLength: Date = undefined as any;
  isIncompleteEmployee: boolean = false;
  hideManagerField: boolean = false;
  employeeTypes: string[] = Messages.EMPLOYEE_TYPES;
  isHideNavbar: boolean = false;
  selectedBranchId: any;
  selectedBranchName: any;
  selectedBranch: string | any = 'main';
  branches: any[] = [];
  constructor(private exitDialog: MatDialog, private employeeService: EmployeeDetailsService, private personalInfoService: MasterDataService, private dialog: MatDialog, private toastrService: ToastrService, private designation: DesignationService, private department: DepartmentService, private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private resignationService: ResignationService) {
    this.initBasicDetailsForm();
    this.initPFDetailsForm();

    //finding 60 years old date for DOB from currect date - DOB
    this.sixtyYearsOldDate = FormatUtil.sixtyYearsOldDate();

    //18 years old employee - DOB
    this.ageOfMaturityDate = FormatUtil.ageOfMaturityDate();

    //finding future 6 months date. HR can only enroll employee before 6 months - DOJ
    this.futureSixMonths = FormatUtil.futureSixMonthsDate();

    //employee DOJ cannot be older than company registration date - DOJ
    this.dojMinLength = FormatUtil.dojMinLenghtCompanyRegistrationdate();

  }

  ngOnInit(): void {
    const hideNavbarValue = sessionStorage.getItem('hideNavBar');
    this.isHideNavbar = hideNavbarValue === 'true';
    if (sessionStorage.getItem(AppConstant.EXIT_EMPLOYEE) === 'true') {
      this.isExit = true;
    } else {
      this.isExit = false;
    }
    if (sessionStorage.getItem(AppConstant.EMPLOYEE_STATUS)) {
      this.isIncompleteEmployee = true;
    }

    this.id = this.route.snapshot.params['id'];
    sessionStorage.setItem(AppConstant.EMPLOYEE_ID, this.id)
    this.getAllDepartmentByCompanyId();
    this.getAllDesignationByCompanyId();
    this.getAllPayRunGroupByCompanyId();
    this.getActivePayRunGroupByCompanyId();

    this.getCountryList();
    this.getAllManagerByCompanyId();
    this.getActiveEmployeeDetails();
    this.getAllDocumentsById(this.id);
    this.getEmployeeProfileImage();
    this.employeeFinalSettlementDetails();
    this.isEmployeeExit();
    this.isFNFExit();
    this.getAllBranches();
  }

  initBasicDetailsForm() {
    this.basicDetails = this.formBuilder.group({
      workEmail: ['', [Validators.required, Validators.email]],
      gender: ['', [Validators.required,]],
      dateOfJoining: ['', [Validators.required,]],
      department: ['', [Validators.required,]],
      employeeType: ['', [Validators.required,]],
      designation: ['', [Validators.required,]],
      manager: ['',],
      payRunGroup: ['',],
      branchId: [''],
      workLocation: ['', [Validators.required,]],
      dateOfBirth: ['', [Validators.required,]],
      firstName: ['', [Validators.required,]],
      middleName: ['',],
      lastName: ['', [Validators.required,]],
      panCardNo: ['', [Validators.required, Validators.pattern(ValidationUtil.getPanCardPattern())]],
      personalEmail: ['', [Validators.required, Validators.email, ValidationUtil.personalEmailDomainValidator]],
      mobileNo: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10), ValidationUtil.validMobileNumber()]],
      bankAccountNo: ['', [Validators.required,]],
      accountHolderName: ['', [Validators.required,]],
      bankName: ['', [Validators.required,]],
      ifscCode: ['', [Validators.required,]],
      accountType: ['', [Validators.required,]],
      paymentMode: ['', [Validators.required,]],
      addressLine1: ['', [Validators.required,]],
      addressLine2: ['',],
      country: ['', [Validators.required,]],
      state: ['', [Validators.required,]],
      city: [''],
      pincode: ['', Validators.required],

    });
  }

  initPFDetailsForm() {
    this.pfDetails = this.formBuilder.group({
      pfNumber: ['', [Validators.required]],
      uanNumber: ['', Validators.required]
    });
  }

  saveEmployeeDetails() {
    if (this.isEditMode) {
      if (this.basicDetails.valid) {
        let dateOfJoining: any;
        if (typeof (this.basicDetails.get('dateOfJoining')?.value) == 'string') {
          dateOfJoining = FormatUtil.adjustDateForTimeOffset(new Date(this.basicDetails.get('dateOfJoining')?.value));
        } else {
          dateOfJoining = FormatUtil.adjustDateForTimeOffset(this.basicDetails.get('dateOfJoining')?.value);
        }
        let dateOfBirth: any;
        if (typeof (this.basicDetails.get('dateOfBirth')?.value) == 'string') {
          dateOfBirth = FormatUtil.adjustDateForTimeOffset(new Date(this.basicDetails.get('dateOfBirth')?.value));
        } else {
          dateOfBirth = FormatUtil.adjustDateForTimeOffset(this.basicDetails.get('dateOfBirth')?.value);
        }

        const dto: EmployeeDetails = {
          workEmail: this.basicDetails.value.workEmail,
          gender: this.basicDetails.value.gender,
          dateOfJoining: dateOfJoining,
          departmentName: this.basicDetails.value.department,
          employeeType: this.basicDetails.value.employeeType,
          designationName: this.basicDetails.value.designation,
          workLocation: this.basicDetails.value.workLocation,
          dateOfBirth: dateOfBirth,
          middleName: this.basicDetails.value.middleName,
          firstName: this.basicDetails.value.firstName,
          lastName: this.basicDetails.value.lastName,
          panCardNo: this.basicDetails.value.panCardNo,
          personalMail: this.basicDetails.value.personalEmail,
          mobileNo: this.basicDetails.value.mobileNo,
          bankAccountNo: this.basicDetails.value.bankAccountNo,
          accountHolderName: this.basicDetails.value.accountHolderName,
          bankName: this.basicDetails.value.bankName,
          ifscCode: this.basicDetails.value.ifscCode,
          accountType: this.basicDetails.value.accountType,
          id: this.id,
          companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
          manager: this.basicDetails.value.manager,
          payRunGroup: this.basicDetails.value.payRunGroup,
          branchId: this.basicDetails.value.branchId,
          addressLine1: this.basicDetails.value.addressLine1,
          addressLine2: this.basicDetails.value.addressLine2,
          country: this.basicDetails.value.country,
          state: this.basicDetails.value.state,
          city: this.basicDetails.value.city,
          pinCode: this.basicDetails.value.pincode,
          paymentMode: this.basicDetails.value.paymentMode
        }
        this.employeeService.updateEmployeeDetails(dto).subscribe(
          (data: any) => {
            if (data.status === 200) {
              this.getActiveEmployeeDetails();
              this.toastrService.clear();
              this.toastrService.success(data.message);
              this.isEditMode = false;
            }
            else {
              this.toastrService.clear();
              this.toastrService.error(data.message);
            }
          }
        )
      } else {
        this.basicDetails.markAllAsTouched();
      }
    }
    this.isEditMode = true;
  }

  cancelChanges() {
    this.isEditMode = false;
  }

  isFNFExit() {
    this.resignationService.isEmployeeFNFDone(sessionStorage.getItem('companyId'), this.id).subscribe((data: any) => {
      this.employeeFNFIsExit = data.data !== null;
    });
  }

  isEmployeeExit() {
    this.resignationService.isEmployeeExit(sessionStorage.getItem('companyId'), this.id).subscribe((data: any) => {
      if (data.data && (data.data.status === 'Approved' || data.data.status === 'Approve')) {
        this.employeeIsExit = true;
        sessionStorage.setItem('employeeResignationId', this.id)

      } else {
        this.employeeIsExit = false;
      }
    });
  }

  employeeFinalSettlementDetails() {
    this.resignationService.finalSettlementDetails(sessionStorage.getItem('companyId'), this.id)
      .subscribe((data: any) => {
        this.fnfData = data.data;
        if (this.fnfData?.lastWorkingDate) {
          this.fnfData.lastWorkingDate = this.formatDate(this.fnfData.lastWorkingDate);
          this.checkLastWorkingDateValidity();
        }
      });
  }

  checkLastWorkingDateValidity() {
    if (this.fnfData && this.fnfData.lastWorkingDate) {
      const lastWorkingDate = new Date(this.fnfData.lastWorkingDate);
      const currentDate = new Date();
      this.isLastWorkingDateValid = lastWorkingDate > currentDate;
    }
  }

  formatDate(dateStr: string): string {
    const date = new Date(dateStr);
    const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'long', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  }

  formatDateForDisplay(dateString: string): string {
    if (!dateString) {
      return '';
    }
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return '';
    }
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  getAllDepartmentByCompanyId() {
    this.department.getDepartmentList(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        this.departmentList = data.map((department: any) => ({
          id: department.id,
          name: department.departmentName,
          companyId: department.companyId
        }));
      })
  }

  getAllDesignationByCompanyId() {
    this.designation.getDesignationList(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        this.designationList = data.map((designation: any) => ({
          id: designation.id,
          name: designation.designation,
          companyId: designation.companyId
        }));
      })
  }

  getAllPayRunGroupByCompanyId() {
    this.employeeService.getPayRunGroupList(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        this.payRunGroupList = data.data.map((payRunGroup: any) => ({
          id: payRunGroup.id,
          name: payRunGroup.payRunGroupName,
          companyId: payRunGroup.companyId
        }));
      })
  }


  getActivePayRunGroupByCompanyId() {
    this.employeeService.getActivePayRunGroupList(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        this.activeRunGroupList = data.data.map((payRunGroup: any) => ({
          id: payRunGroup.id,
          name: payRunGroup.payRunGroupName,
          companyId: payRunGroup.companyId
        }));
      })
  }

  getAllManagerByCompanyId() {
    this.employeeService.getManagerList(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        this.managerList = data.map((manager: any) => ({
          id: manager.id,
          name: manager.firstName + " " + manager.lastName,
          companyId: manager.companyId
        }));
      })
  }

  getActiveEmployeeDetails() {
    this.employeeService.getActiveEmployeeDetails(this.companyId, this.id).subscribe((data: any) => {
      this.activeEmployeeData = data;
      this.selectedBranchId = data?.employeeBasicDetail?.branchId;
      this.selectedBranchName = data?.employeeBasicDetail?.departmentModel?.addBranchModel?.branchName;

      this.basicDetails.patchValue({
        branch: this.selectedBranchId 
      });

      if (this.activeEmployeeData?.employeeBasicDetail?.designationName === 'CEO') {
        this.hideManagerField = true;
      } else {
        this.hideManagerField = false;
      }
      if (data.employeePersonalDetail.pfNumber !== null) {
        this.pfToggle = true;
      }
      this.selectedCountry = data.employeePersonalDetail.country;
      if (data.employeePersonalDetail.country !== null) {
        this.getState(data.employeePersonalDetail.country);
      }
      this.selectedState = data.employeePersonalDetail.state;
      if (data.employeePersonalDetail.state !== null) {
        this.getCity(data.employeePersonalDetail.state);
      }
      this.selectedCity = data.employeePersonalDetail.city;
      this.basicDetails.patchValue({
        workEmail: this.activeEmployeeData?.employeeBasicDetail?.workEmail,
        gender: this.activeEmployeeData?.employeeBasicDetail?.gender,
        dateOfJoining: this.activeEmployeeData?.employeeBasicDetail?.doj,
        department: this.activeEmployeeData.employeeBasicDetail.departmentName,
        employeeType: this.activeEmployeeData?.employeeBasicDetail?.employeeType,
        designation: this.activeEmployeeData.employeeBasicDetail.designationName,
        manager: this.activeEmployeeData?.employeeBasicDetail?.managerId,
        payRunGroup: this.getPayRunGroupIdByName(this.activeEmployeeData?.employeeBasicDetail?.payRunGroupName), // Update this line
        workLocation: this.activeEmployeeData?.employeeBasicDetail?.workLocation,
        dateOfBirth: this.activeEmployeeData.employeePersonalDetail.dob,
        firstName: this.activeEmployeeData?.employeeBasicDetail?.firstName,
        middleName: this.activeEmployeeData.employeeBasicDetail.middleName,
        lastName: this.activeEmployeeData.employeeBasicDetail.lastName,
        panCardNo: this.activeEmployeeData.employeePersonalDetail.panNo,
        personalEmail: this.activeEmployeeData.employeePersonalDetail.email,
        mobileNo: this.activeEmployeeData.employeePersonalDetail.mobileNo,
        bankAccountNo: this.activeEmployeeData.employeeBankDetail.bankAccountNo,
        accountHolderName: this.activeEmployeeData.employeeBankDetail.accountHolderName,
        bankName: this.activeEmployeeData.employeeBankDetail.bankName,
        ifscCode: this.activeEmployeeData.employeeBankDetail.ifscCode,
        accountType: this.activeEmployeeData.employeeBankDetail.accountType,
        addressLine1: this.activeEmployeeData.employeePersonalDetail.addressLine1,
        addressLine2: this.activeEmployeeData.employeePersonalDetail.addressLine2,
        country: this.activeEmployeeData.employeePersonalDetail.country,
        state: this.activeEmployeeData.employeePersonalDetail.state,
        city: this.activeEmployeeData.employeePersonalDetail.city,
        pincode: this.activeEmployeeData.employeePersonalDetail.pinCode,
        paymentMode: this.activeEmployeeData.employeeSalaryDetail.paymentMode
      });
      this.monthlyCtc = this.activeEmployeeData.employeeSalaryDetail.annualCtc / 12;
    });
  }

  getPayRunGroupIdByName(name: string): string | null {
    const payRunGroup = this.payRunGroupList.find(group => group.name === name);
    return payRunGroup ? payRunGroup.id : null;
  }

  getEmployeeProfileImage() {
    this.employeeService.getEmployeeImage(this.companyId, this.id).subscribe((data: any) => {
      this.employeeImage = data?.image;
      this.employeeImage = FormatUtil.convertBase64ToDataUrl(data?.image);
    });
  }

  goToBack() {
    const sessionValue = sessionStorage.getItem('hideNavBar');
    if (sessionValue === 'true') {
      this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.MANAGERS_EMPLOYEE_LIST]);
    } else if (!this.pdfViewer) {
      const container = document.getElementById('pdfContainer');
      if (container) {
        container.innerHTML = '';
        this.pdfViewer = true;
      }
    } else if (sessionStorage.getItem(AppConstant.MANAGER) === AppConstant.EMPLOYEE_ROLE) {
      this.router.navigate([
        AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.MANAGERS_EMPLOYEE_LIST
      ]);
    } else {
      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACTIVE_EMPLOYEES]);
    }
  }

  getAllDocumentsById(employeeId: any) {
    this.employeeService.getAllDocumentsById(employeeId).subscribe(
      (data: any) => {
        this.documentCount = data.length;
        this.documents = data.map((document: string) => {
          const parts = document.split(AppConstant.SEPERATOR);
          const name = parts[2];
          return name;
        })
      }
    )
  }

  getdocumentByIdAndFileName(employeeId: any, documentName: any) {
    this.employeeService.getdocumentByIdAndFileName(employeeId, documentName).subscribe(
      (data: any) => {
        return data;
      }
    )
  }

  loadDocument(documentName: string) {
    this.employeeService.getdocumentByIdAndFileName(this.id, documentName).subscribe({
      next: (response) => {
        if (response.body) {
          const fileType = this.getFileType(documentName);
          const blob = new Blob([response.body], { type: 'application/pdf' });
          const pdfUrl = URL.createObjectURL(blob);

          if (fileType === 'pdf' || fileType === 'docx') {
            this.dialog.open(PdfViewerComponent, {
              width: '100%',
              data: { pdfUrl: pdfUrl }
            });
          } else if (fileType === 'image') {
            const blob = new Blob([response.body], { type: 'image/png' });
            const dialogRef = this.dialog.open(ImageViewerComponent, {
              data: { imageUrl: URL.createObjectURL(blob), documentName: documentName },
            });
            dialogRef.afterClosed().subscribe(() => { });
          } 
        }
      }
    });
  }

  get uanNumber() {
    return this.pfDetails.get('uanNumber');
  }

  showPdfInIframe(pdfUrl: string) {
    const iframe = document.createElement('iframe');
    iframe.style.width = '100%';
    iframe.style.height = '600px';
    const container = document.getElementById('pdfContainer');
    if (container) {
      container.innerHTML = '';
      container.appendChild(iframe);
      iframe.src = pdfUrl;
    } else {
      console.error('Container is null');
    }
  }

  getFileType(fileName: string): string {
    const fileExtensionArray = fileName.split('.');
    if (fileExtensionArray.length > 1) {
      const fileExtension = fileExtensionArray.pop()?.toLowerCase() ?? '';
      if (fileExtension === 'pdf') {
        return 'pdf';
      }
      else if (fileExtension === 'docx') {
        return 'docx';
      }
      else if (['png', 'jpg', 'jpeg', 'gif']?.includes(fileExtension)) {
        return 'image';
      }
      else {
        return 'other';
      }
    }
    return 'unknown';
  }

  getCountryList() {
    this.personalInfoService.getAllCountries().subscribe(
      (data: any) => {
        if (data.status === 200)
          this.countryList = data.data;
      }
    )
  }

  getState(country: any) {
    this.selectedCountry = country;
    this.personalInfoService.getAllState(country).subscribe(
      (data: any) => {
        this.stateList = data.data;
      }
    )
  }

  getCity(state: any) {
    this.selectedState = state;
    this.personalInfoService.getAllCity(state).subscribe(
      (data: any) => {
        this.cityList = data.data;
      }
    )
  }

  selectedCityName(city: any) {
    this.selectedCity = city;
  }

  resetIFSCFieldCheck() {
    this.verifiedIFSC = false;
  }

  verifyIFSC() {
    this.isLoading = true;
    this.errorMessage = '';
    this.employeeService.verifyIFSC(this.basicDetails.value.ifscCode).subscribe(
      (response: any) => {
        if (response.status === 200) {
          this.verifiedIFSC = true;
          this.isLoading = false;
          this.basicDetails.controls['ifscCode'].setErrors(null);
          const data = JSON.parse(response.data);
          this.basicDetails.controls['bankName'].setValue(data.BANK);
        } else {
          this.isLoading = false;
          this.verifiedIFSC = false;
          this.basicDetails.controls['ifscCode'].setErrors({ 'invalidIFSC': true });
        }
      }
    );
  }

  uploadDocument() {
    this.documentUpload = !this.documentUpload;
    if (!this.documentUpload) {
      this.getAllDocumentsById(this.id);
    }
  }

  pfPopupToggle() {
    if (this.isEditMode) {
      if (!this.pfToggle) {
        this.pfDetails.patchValue({
          pfNumber: this.activeEmployeeData.employeePersonalDetail.pfNumber,
          uanNumber: this.activeEmployeeData.employeePersonalDetail.uan,
        })
        this.pfToggle = true;
        this.pfPopupView = true;
      }
      else {
        this.pfToggle = false;
        this.pfPopupView = false;
      }
    }
  }

  pTPopupToggle() {
    if (this.isEditMode) {
      if (!this.pTToggle) {
        this.updatePTDetails(false);
        this.pTToggle = true;
      }
      else {
        this.updatePTDetails(true);
        this.pTToggle = false;
      }
    }
  }

  updatePTDetails(ptStatus: boolean) {
    const dto: any = {
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      employeeId: this.id,
      ptStatus: ptStatus
    }

    this.employeeService.updatePTDetails(dto).subscribe((data: any) => {
      if (data.status === 200) {
        this.toastrService.clear();
        this.toastrService.success(data.message)
      }
      else {
        this.toastrService.clear();
        this.toastrService.error(data.message)
      }
    });
  }

  portalAccessToggle() {
    if (this.isEditMode) {
      this.activeEmployeeData.employeeBasicDetail.portalAccess = !this.activeEmployeeData?.employeeBasicDetail?.portalAccess;
      const dto: any = {
        companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
        employeeId: this.activeEmployeeData.employeeBasicDetail.id,
        portalAccess: this.activeEmployeeData.employeeBasicDetail.portalAccess
      }
      this.employeeService.portalAccessToggle(dto).subscribe((data: any) => {
        if (data.status === 200) {
          this.toastrService.clear();
          this.toastrService.success(data.message)
        }
        else {
          this.toastrService.clear();
          this.toastrService.error(data.message)
        }
      })
    }
  }

  closePopup() {
    this.pfPopupView = false;
    this.pfToggle = false;
  }

  savePfDetails() {
    if (this.pfDetails.valid) {
      const dto: any = {
        companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
        employeeId: this.id,
        pfNumber: this.pfDetails.get('pfNumber')?.value,
        uanNumber: this.pfDetails.get('uanNumber')?.value
      }
      this.employeeService.savePfDetails(dto).subscribe(
        (data: any) => {
          if (data.status === 200) {
            this.pfPopupView = false;
            this.toastrService.clear();
            this.toastrService.success(data.message);
          }
        }
      )
    }
  }

  initiateExitProcess() {
    this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.EXIT_DETAILS]);
  }

  openExitPopup(): void {
    this.exitDialog.open(ExitHistoryComponent, {
      width: '600px',
      panelClass: 'exit-dialog-container'
    });
  }

  revertExitPopup() {
    const dialogRef = this.dialog.open(RevertExitProcessComponent, {
      width: '600px',
      panelClass: 'exit-dialog-container'
    })

    dialogRef.componentInstance.revertSuccess.subscribe((success: boolean) => {
      if (success) {
        this.employeeIsExit = false;
      }
    });
  }


  processFnf() {
    this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.FINAL_SETTLEMENT]);
  }

  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumber(event);
  }

  alphanumericAllowed(event: any) {
    ValidationUtil.onlyAlphanumeric(event);
  }

  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

  validateCharacterAndSpaceAllowed(event: any) {
    return ValidationUtil.characterAndSpaceAllowed(event);
  }

  addressVerification(event: any) {
    return ValidationUtil.addressVerification(event);
  }

  validateCharacter(event: any) {
    return ValidationUtil.validateCharacter(event);
  }

  numberOnly(event: any): void {
    ValidationUtil.numberOnly(event);
  }
  onBranchChange(event: any) {
    this.selectedBranchId = event.value;
  }

  getAllBranches() {
    this.employeeService.getAllBranches().subscribe(
      (allBranchesResponse: any) => {
        if (allBranchesResponse.status === 200) {
          this.selectedBranchName = this.selectedBranchName?.trim();
          this.branches = allBranchesResponse.data.map((branch: any) => ({
            name: branch.branchName,
            value: branch.id,
          }));
          if (this.isEditMode && this.selectedBranchName) {
            this.selectedBranch = this.branches.find(branch => branch.name === this.selectedBranchName) || null;
          }
        }
      }
    );
  }

}