import { Component, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddAssetPopupComponent } from '../add-asset-popup/add-asset-popup.component';
import { AssignAssetsPopupComponent } from '../assign-assets-popup/assign-assets-popup.component';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AssetManagementDashboardService } from 'src/app/services/asset-management/asset-management-dashboard/asset-management-dashboard.service';
import { AppConstant } from 'src/app/constants/app.constants';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-asset-details',
  templateUrl: './asset-details.component.html',
  styleUrls: ['./asset-details.component.scss']
})
export class AssetDetailsComponent implements OnInit {
  tooltipShowDelay: number = 0;
  tooltipHideDelay: number = 2000;
  pagination = true;
  paginationPageSize = 10;
  paginationPageSizeSelector = [10, 20, 50, 100];
  assetsDetails: any;
  assetsAllocation: any;
  assetsHistory: any;
  assets: string[] = ['All'];
  addAssetrowData: any[] = [];
  assetAllocationrowData: any[] = [];
  historyrowData: any[] = [];

  addAssetcolumnDefs: any = [
    { field: "modelNo", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { field: "modelName", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { field: "assetType", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { field: "specification", filter: true, floatingFilter: true, sortable: true, tooltipField: "specification", flex: 1 },
    { field: "Action", cellRenderer: this.assetCellRenderer.bind(this) },
  ];

  assetAllocationColumnDefs: any = [
    { field: "employeeId", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { field: "employeeName", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { field: "modelNo", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { field: "specification", filter: true, floatingFilter: true, sortable: true, tooltipField: "specification", flex: 1, },
    { field: "date", filter: true, floatingFilter: true, sortable: true, flex: 1 },
  ];

  historyColumnDefs: any = [
    { field: "assetId", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { field: "assetName", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { field: "modelNo", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { field: "specification", filter: true, floatingFilter: true, sortable: true, tooltipField: "specification", flex: 1 },
    { field: "fromDate", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { field: "toDate", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { field: "assignEmployeeId", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { field: "Action", cellRenderer: this.actionCellRenderer.bind(this) },

  ];

  constructor(private toasterService: ToastrService, private formBuilder: FormBuilder, private renderer: Renderer2, private router: Router,
    public dialog: MatDialog, private addAssetService: AssetManagementDashboardService) { }

  ngOnInit(): void {
    this.getAssetTypeList();
    this.assetsDetails = this.formBuilder.group({
      assetType: ['', [Validators.required,]],
    });
    this.assetsDetails.patchValue({
      assetType: "All"
    });
    this.getAssetsDetailsTypes(this.assetsDetails.get('assetType').value);

    this.assetsAllocation = this.formBuilder.group({
      assetAllocationType: ['', [Validators.required]]
    });
    this.assetsAllocation.patchValue({
      assetAllocationType: "All"
    });
    this.getAssetAllocationList(this.assetsAllocation.get('assetAllocationType').value);

    this.assetsHistory = this.formBuilder.group({
      assetHistoryType: ['', [Validators.required]]
    });
    this.assetsHistory.patchValue({
      assetHistoryType: "All"
    });
    this.getAssetsHistory(this.assetsHistory.get('assetHistoryType').value);

    this.assetsDetails.get('assetType').valueChanges.subscribe(
      (assetType: any) => {
        this.getAssetsDetailsTypes(assetType);
      })

    this.assetsAllocation.get('assetAllocationType').valueChanges.subscribe(
      (assetType: any) => {
        this.getAssetAllocationList(assetType);
      })

    this.assetsHistory.get('assetHistoryType').valueChanges.subscribe(
      (assetType: any) => {
        this.getAssetsHistory(assetType);
      })
  }

  getAssetTypeList() {
    this.addAssetService.getAssetTypeList().subscribe(
      (data: any) => {
        if (data.status) {
          this.assets.push(...data.data);

        }
      }
    );
  }

  getAssetsDetailsTypes(assetType: any) {
    this.addAssetService.getAllAssetsList(assetType).subscribe(
      (data: any) => {
        this.addAssetrowData = data.data
      }
    );
  }

  getAssetAllocationList(assetType: any) {
    this.addAssetService.getAssetAllocationList(assetType).subscribe(
      (data: any) => {
        this.assetAllocationrowData = data.data
      }
    );
  }

  getAssetsHistory(data: any) {
    this.addAssetService.getAllAssetsHistory(data).subscribe(
      (data: any) => {
        this.historyrowData = data.data
      }
    );
  }

  goToBack() {
    this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.ASSET_MANAGEMENT])
  }

  addassets() {
    const dialogRef = this.dialog.open(AddAssetPopupComponent);
    dialogRef.afterClosed().subscribe(result => {
      this.getAssetsDetailsTypes(this.assetsDetails.get('assetType').value);
    });
  }

  assignAsset() {
    const dialogRef = this.dialog.open(AssignAssetsPopupComponent);
    dialogRef.afterClosed().subscribe(result => {
      this.getAssetAllocationList(this.assetsAllocation.get('assetAllocationType').value);
      this.getAssetsHistory(this.assetsHistory.get('assetHistoryType').value);
    });
  }

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      if (icon === 'delete') {
        this.renderer.setStyle(iconElement, 'margin-right', '15px');
      }
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setAttribute(button, 'MatTooltipModule', 'toolTipText');
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };

    const visibilityClickHandler = () => {
      this.router.navigate([`${AppConstant.VIEW_SUPPLIER_HISTORY}${params.data.assetId}`], {
        queryParams: { type: "asset" }
      });
    };

    createButton('visibility', 'green', visibilityClickHandler);
    return cell;
  }

  assetCellRenderer(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      if (icon === 'delete') {
        this.renderer.setStyle(iconElement, 'margin-right', '15px');
      }
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setAttribute(button, 'MatTooltipModule', 'toolTipText');
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };

    const deleteClickHandler = () => {
      this.deleteAsset(params.data.modelNo);
    };
    createButton('delete', 'red', deleteClickHandler);
    return cell;
  }

  deleteAsset(assetId: any) {
    this.addAssetService.deleteAsset(assetId).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.getAssetsDetailsTypes(this.assetsDetails.get('assetType').value);
          this.toasterService.clear();
          this.toasterService.success(data.message);
        } else {
          this.toasterService.clear();
          this.toasterService.error(data.message);
        }
      }
    )
  }


}
