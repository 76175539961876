<div class="setting__main_container">
    <div class="setting__heading_container">
        <h3>{{'company_leave_structure' | translate}}</h3>
        <div class="setting__header_button_container">
            <button color="primary" (click)="openDialog('Add',{})">{{'add_leave_type' | translate}}</button>
        </div>
    </div>
    <div class="container">
        <table mat-table [dataSource]="dataSource">
            <ng-container [matColumnDef]="col.key" *ngFor="let col of columnsSchema">
                <th mat-header-cell *matHeaderCellDef [ngSwitch]="col.key">
                    <span *ngSwitchDefault><b>{{ col.label }}</b></span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div [ngSwitch]="col.type" *ngIf="!element.isEdit">
                        <div class="btn-edit" *ngSwitchCase="'isEdit'">
                            <mat-icon class="icon_edit_button" (click)="openDialog('Update',element)"
                                matTooltip="Edit">edit</mat-icon>
                            <mat-icon class="icon_delete_button" (click)="openDialog('Delete',element)"
                                matTooltip="Delete">delete</mat-icon>
                        </div>
                        <span *ngSwitchDefault>
                            {{ element[col.key] }}
                        </span>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-divider></mat-divider>
        <div class="total-leave">
            <div class="annual-count"><b> {{'annual_leave'|translate}} : {{ totalCount }} </b></div>
            <div *ngIf="editMode"><button class="button" type="submit" (click)="saveLeaveStructure()">{{'save'|translate}}</button>
            </div>
        </div>
    </div>
</div>