<div class='container'>
  <div>
    <form name="createBasicForm" [formGroup]="createBasicForm" class="form-content">
      <div>
        <div class="form-field-gap5">
          <mat-form-field appearance="outline" class="salutation">
            <mat-label>{{ 'salutation' | translate }}</mat-label>
            <mat-select formControlName="salutation" class="country-code-select">
              <mat-option *ngFor="let salutation of salutations" [value]="salutation.name">
                {{ salutation.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="first-name" appearance="outline">
            <mat-label class="formLabel">{{'first_name'|translate}}</mat-label>
            <input matInput type="text" class="input" (keypress)="validateCharacter($event)" formControlName="firstName"
              required />
            <mat-error *ngIf="createBasicForm.controls['firstName'].hasError('required')">
              {{ 'first_name_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="createBasicForm.controls['firstName'].hasError('invalidInput')">
              {{ 'invalid_name_input' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="middle-name" appearance="outline">
            <mat-label class="formLabel">{{'middle_name'|translate}}</mat-label>
            <input matInput type="text" class="input" formControlName="middleName"
              (keypress)="validateCharacter($event)" />
            <mat-error *ngIf="createBasicForm.controls['middleName'].hasError('required')">
              {{ 'middle_name_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="createBasicForm.controls['middleName'].hasError('invalidInput')">
              {{ 'invalid_name_input' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="last-name" appearance="outline">
            <mat-label class="formLabel">{{'last_name'|translate}}</mat-label>
            <input matInput type="text" class="input" formControlName="lastName" (keypress)="validateCharacter($event)"
              required />
            <mat-error *ngIf="createBasicForm.controls['lastName'].hasError('required')">
              {{ 'last_name_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="createBasicForm.controls['lastName'].hasError('invalidInput')">
              {{ 'invalid_name_input' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-field-gap2">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label class="formLabel">{{'gender'|translate}}</mat-label>
            <mat-select class="select-mat-input" formControlName="gender" required>
              <mat-option value="Male">{{'male' | translate}}</mat-option>
              <mat-option value="Female">{{'female' | translate}}</mat-option>
              <mat-option value="Other">{{'other' | translate}}</mat-option>
            </mat-select>
            <mat-error *ngIf="!createBasicForm.controls['gender'].valid">{{'gender_is_required'|translate}}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label class="formLabel">{{ 'date_of_joining' | translate }}</mat-label>
            <input matInput formControlName="doj" [matDatepicker]="picker" [min]="dojMinLength" [max]="futureSixMonths"
              (keypress)="$event.preventDefault()" required>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error
              *ngIf="createBasicForm.get('doj')?.hasError('required') && !createBasicForm.get('doj')?.hasError('invalidDateOfJoining')">
              {{ 'date_of_joining_is_required' | translate }}
            </mat-error>
            <mat-error *ngIf="createBasicForm.get('doj')?.hasError('invalidDateOfJoining')">
              {{ 'doj_is_greater_than_registration_date' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="employee-type">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label class="formLabel">{{'employee_type'|translate}}</mat-label>
            <mat-select formControlName="employeeType">
              <div *ngFor="let employeeType of employeeTypes">
                <mat-option [value]="employeeType">{{employeeType | translate}}</mat-option>
              </div>
            </mat-select>
            <mat-error
              *ngIf="!createBasicForm.controls['employeeType'].valid">{{'employee_type_is_required'|translate}}</mat-error>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label class="formLabel">{{'work_location'|translate}}</mat-label>
            <input matInput type="text" class="input" formControlName="workLocation" required
              (keypress)="validateCharacterNumberAndSpace($event)" [maxlength]="15" />
            <mat-error>
              {{'work_location_is_required'|translate}}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="contain">
          <mat-checkbox></mat-checkbox>
          <div class="employee-content">
            <i class="fa fa-info-circle"></i>
            <span>{{'employee_is_director_person'|translate}}</span>
          </div>
        </div>

        <div class="form-field-gap3">
          <mat-form-field class="full-width" appearance="outline">
            <mat-label class="formLabel">{{'work_email'|translate}}</mat-label>
            <input matInput type="text" class="input" formControlName="workEmail" required />
            <mat-error>
              {{'work_email_is_required'|translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" class="full-width">
            <mat-label class="formLabel">{{ 'designation' | translate }}</mat-label>
            <mat-select matInput formControlName="designationId" class="select-mat-input">
              <mat-option *ngFor="let designation of designationList" [value]="designation.id">
                {{ designation.designation }}
              </mat-option>
              <mat-option class="custom-button-class" (click)="onAddDesignationClick()">{{'add' |
                translate}}</mat-option>
            </mat-select>
            <mat-error>{{ 'designation_is_required' | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div class="tooltipMessage">
          <i class="fa fa-info-circle"></i> <span>{{'cannot_change_email'|translate}}</span>
        </div>
        <div class="department-form-field-gap" *ngIf="!hideManagerField">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label class="formLabel">{{ 'department' | translate }}</mat-label>
            <mat-select matInput formControlName="departmentId" class="select-mat-input">
              <mat-option *ngFor="let department of departmentList" [value]="department.id">
                {{ department.departmentName }}
              </mat-option>
              <mat-option class="custom-button-class" (click)="onAddDepartmentClick()">
                {{'add' |translate}}</mat-option>
            </mat-select>
            <mat-error>{{ 'department_is_required' | translate }}</mat-error>
          </mat-form-field>
          <div>
            <mat-form-field appearance="outline" class="full-width" (click)="isManagerAvailable()">
              <mat-label class="formLabel">{{ 'manager' | translate }}</mat-label>
              <mat-select matInput formControlName="managerId" class="select-mat-input">
                <mat-option *ngFor="let manager of managerList" [value]="manager.id">{{ manager.firstName }} {{
                  manager.lastName }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-error *ngIf="isAvailable" class="api-errors">{{ 'manager_not_available' | translate
              }}</mat-error>
          </div>
        </div>
        <div class="department">
          <mat-form-field appearance="outline" class="full-width" *ngIf="hideManagerField">
            <mat-label class="formLabel">{{ 'department' | translate }}</mat-label>
            <mat-select matInput formControlName="departmentId" class="select-mat-input">
              <mat-option *ngFor="let department of departmentList" [value]="department.id">
                {{ department.departmentName }}
              </mat-option>
              <mat-option class="custom-button-class" (click)="onAddDepartmentClick()">
                {{'add' |translate}}</mat-option>
            </mat-select>
            <mat-error>{{ 'department_is_required' | translate }}</mat-error>
          </mat-form-field>
        </div>

        <div class="role-form-field-gap">
          <div>

            <mat-form-field appearance="outline" class="full-width" (click)="payrunNotFound()">
              <mat-label class="formLabel">{{'payrun_group'|translate}}</mat-label>
              <mat-select matInput formControlName="payrunGroupName" class="select-mat-input">
                <mat-option *ngFor="let payRunGroup of payRunGroupList" [value]="payRunGroup.id">
                  {{ payRunGroup.payRunGroupName }}
                </mat-option>
              </mat-select>
              <mat-error>{{'employee_type_is_required'|translate}}</mat-error>
            </mat-form-field>
            <mat-error *ngIf="isFound" class="api-errors">{{ 'payrun_group_not_found' | translate}}</mat-error>
          </div>
        </div>
        <mat-divider></mat-divider>
        <button type="submit" class="button-right"
          (click)="addEmployeeBasicInformation()">{{'save_and_next'|translate}}</button>
        <button class="button-right cancel-btn" (click)="goToDashboard()">{{'cancel'|translate}}</button>
      </div>
    </form>
  </div>
</div>

<div class="overlay" *ngIf="showAddDepartmentPopup">
  <div class="add-popup">
    <div class="container">
      <div class="header">
        <button mat-icon-button (click)="toggleAddDepartmentPopup()" class="closeDialog">
          <mat-icon>close</mat-icon>
        </button>
        <h3 class="heading">{{ 'add-department' | translate }}</h3>
      </div>
      <form [formGroup]="departmentForm" id="departmentForm">
        <div>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{ 'department_name' | translate }}</mat-label>
            <input matInput formControlName="departmentName" type="text" />
            <mat-error *ngIf="!departmentForm.controls['departmentName'].valid">{{ 'department_is_required' |
              translate }}</mat-error>
          </mat-form-field>
        </div>
        <div style="text-align: center;">
          <button class="button-right" (click)="addDepartment()">
            {{ ('Save' | translate) }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>


<div class="overlay" *ngIf="showAddDesignationPopup">
  <div class="add-popup">
    <div class="container">
      <div class="header">
        <button mat-icon-button (click)="toggleAddDesignationPopup()" class="closeDialog">
          <mat-icon>close</mat-icon>
        </button>
        <h3 class="heading">{{ 'add_designation' | translate }}</h3>
      </div>
      <form [formGroup]="designationForm" id="departmentForm">
        <div>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{ 'designation' | translate }}</mat-label>
            <input matInput formControlName="designationName" type="text" />
            <mat-error *ngIf="!designationForm.controls['designationName'].valid">{{ 'designation_is_required' |
              translate }}</mat-error>
          </mat-form-field>
        </div>
        <div style="text-align: center;">
          <button class="button-right" (click)="addDesignation()">
            {{ ('Save' | translate) }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>




<!-- Add roles -->
<div class="overlay" *ngIf="showAddRolePopup">
  <div class="add-role-popup">
    <div class="container">
      <div class="header">
        <button mat-icon-button (click)="toggleAddRolesPopup()" class="closeDialog">
          <mat-icon>close</mat-icon>
        </button>
        <h3 class="heading">{{ 'add_role' | translate }}</h3>
      </div>
      <form [formGroup]="createRoleForm" id="createRoleForm">
        <div>
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{ 'role_name' | translate }}</mat-label>
            <input matInput formControlName="roleName" type="text" />
            <mat-error *ngIf="!createRoleForm.controls['roleName'].valid">
              {{ 'role_name_is_required'| translate }}</mat-error>
          </mat-form-field>
        </div>

        <div class="role-container">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{ 'role_description' | translate }}</mat-label>
            <input matInput formControlName="roleDescription" type="text" />
            <mat-error *ngIf="!createRoleForm.controls['roleDescription'].valid">
              {{ 'role_description_is_required'| translate }}</mat-error>
          </mat-form-field>
        </div>

        <div class="role-container">
          <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{ 'report_to' | translate }}</mat-label>
            <mat-select matInput formControlName="roleReportTo" class="select-mat-input">
              <mat-option *ngFor="let roleName of roleList" [value]="roleName.id">
                {{ roleName.roleName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div style="text-align: center;">
          <button class="button-right" (click)="addRole()">
            {{ ('Save' | translate) }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>


<app-loader></app-loader>