import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { AppraisalServiceService } from 'src/app/services/apprisal-service/appraisal-service.service';

@Component({
  selector: 'app-employee-appraisal-evolution',
  templateUrl: './employee-appraisal-evolution.component.html',
  styleUrls: ['./employee-appraisal-evolution.component.scss']
})
export class EmployeeAppraisalEvolutionComponent {


  Title: string = '2024 Annual Performance Document'
  Name: string = 'Rushikesh Sapakale'
  Rating: string = 'Exceeds Expectations';

  Comments: String[] = ['1.I am always punctual and complete all my assigned tasks within time',
    '2.Took ownership of Maryland module responsiveness',
  ]
  id: any = "";
  empRating: any;
  isManager: any;
  constructor(private route: Router, private formbuilder: FormBuilder, private appraisalService: AppraisalServiceService, private router: ActivatedRoute) {
    this.isManager = sessionStorage.getItem(AppConstant.IS_MANAGER);
  }
  EmpRating: string[] = ['ADVANCED']
  url: any;
  ngOnInit() {
    this.isManager = sessionStorage.getItem(AppConstant.IS_MANAGER);
  }

  onSelectFile(event: any) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (event) => {
        this.url = event.target?.result;
      };
    }
  }

  goToGoalPlan() {
    if (this.isManager == 'true') {
      this.route.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.GOAL_PLAN])
    } else {
      this.route.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.GOAL_PLAN])
    }
  }

  goToPerformanceGoalPlan() {
    if (this.isManager == 'true') {
      this.route.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_PERFORMANCE_GOAL_PLAN])
    } else {
      this.route.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_PERFORMANCE_GOAL_PLAN])
    }
  }

  back(){
    this.route.navigate([AppConstant.EMPLOYEE_SIDENAV +AppConstant.SEPERATOR + AppConstant.VIEW_EMPLOYEE_APPRISAL])
  }
}
