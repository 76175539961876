import { Component, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { TraningService } from 'src/app/services/traning/traning.service';

@Component({
  selector: 'app-view-traning',
  templateUrl: './view-traning.component.html',
  styleUrls: ['./view-traning.component.scss']
})
export class ViewTraningComponent {
  employeeView: boolean = false;
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  isTrainingAvailable = true;
  employeeData: any[] = [];
  selectedEmployeeType: string = 'activeEmployee';
  hrColDef: any;
  stars: number[] = [1, 2, 3, 4, 5];
  selectedValue: number = 0;
  viewCompulsoryImageSliderDto: { type: string, adminRole: boolean } = { type: 'compulsary', adminRole: false };
  viewOptionalImageSliderDto: { type: string, adminRole: boolean } = { type: 'optional', adminRole: false };

  constructor(private renderer: Renderer2, private employeeService: EmployeeDetailsService, private route: Router, private trainingservice: TraningService) { }

  ngOnInit(): void {
    this.getAllEmployee();
    if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === 'employee') {
      this.employeeView = true;
      this.viewCompulsoryImageSliderDto.adminRole = false;
      this.viewOptionalImageSliderDto.adminRole = false;
    }
    else {
      this.employeeView = false;
      this.viewCompulsoryImageSliderDto.adminRole = true;
      this.viewOptionalImageSliderDto.adminRole = true;
    }
    this.hrColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
  }

  hrTableCol = [
    { field: 'id', headerName: 'Employee ID', width: 120, unSortIcon: true, floatingFilter: true },
    { field: "departmentName", width: 190, unSortIcon: true, floatingFilter: true },
    { field: "firstName", floatingFilter: true, unSortIcon: true, width: 150 },
    { field: "lastName", floatingFilter: true, unSortIcon: true, width: 140 },
    { field: "workEmail", floatingFilter: true, unSortIcon: true, width: 210 },
    {
      field: "Action",
      cellRenderer: this.actionCellRenderer.bind(this),
      width: 230
    }
  ];

  addTraning() {
    this.route.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.ADD_TRANING]);
  }

  countStar(star: number) {
    this.selectedValue = star;
  }

  addClass(star: number) {
    let ab = "";
    for (let i = 0; i < star; i++) {
      ab = "starId" + i;
      document?.getElementById(ab)?.classList.add("selected");
    }
  }

  removeClass(star: number) {
    let ab = "";
    for (let i = star - 1; i >= this.selectedValue; i--) {
      ab = "starId" + i;
      document?.getElementById(ab)?.classList.remove("selected");
    }
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  getAllEmployee() {
    this.employeeService.getEmployeeList(this.selectedEmployeeType, sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.employeeData = data.data.map((item: any) => ({
            id: AppConstant.EMP00 + item.id,
            firstName: item.firstName,
            lastName: item.lastName,
            workEmail: item.workEmail,
            departmentName: item.departmentName,
            panNo: item.panNo || null,
          }
          ));
        }
      })
  }

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };

    const viewIconClickHandler = () => {
      const employeeId = parseInt(params.data.id.split(AppConstant.EMP00)[1]);
      this.viewEmployeeDetails(employeeId);
    };

    createButton('visibility', 'green', viewIconClickHandler);

    return cell;
  }


  viewEmployeeDetails(employeeId: any): void {
    if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.ADMIN) {
      this.route.navigate([`${AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.VIEW_EMPLOYEE_TRANING_DETAILS}/${employeeId}`]);
    }
    else {
      this.route.navigate([`${AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_EMPLOYEE_TRANING_DETAILS}/${employeeId}`]);
    }
  }

}

