export const environment = {
  production: false,
  maintenanceMode: false,

  // dev url
  apiUrl: "https://iwingshrms.com:8081/api",

  //AES Cipher Keys
  passPhrase: "dhty#@$24536Csdghyj>/@xdg!sfdgfdty456"

}