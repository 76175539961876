import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BankDetails } from '../../model/bankDetails.model';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/enviroment';
import { API_END_POINTS } from '../../config/api-endpoint.config';

@Injectable({
  providedIn: 'root'
})
export class BankDetailsService {

  constructor(private http: HttpClient) { }

  addBankDetails(addBankDetails: BankDetails): Observable<BankDetails> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_BANK_DETAILS, addBankDetails).pipe(
      map((res: any) => {
        return res;
      }))
  }

  companyBankDetails(addBankDetails: BankDetails): Observable<BankDetails> {
    return this.http.post(environment.apiUrl + API_END_POINTS.COMPANY_BANK_DETAILS, addBankDetails).pipe(
      map((res: any) => {
        return res;
      }))
  }

  getBanks(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_BANKS_DATA).pipe(
      map((res: any) => {
        return res;
      })
    )
  }
}
