import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { AppConstant } from 'src/app/constants/app.constants';
import { SendLinkOnEmail } from 'src/app/model/attendance-model/subscription.model';
import { environment } from 'src/environments/enviroment';

@Injectable({
  providedIn: 'root'
})
export class UserSubscriptionService {
  constructor(private http: HttpClient) { }

  getSubscriptionList(companyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_SUBSCRIPTION_LIST + companyId).pipe(
      map((res: any) => {
        return res;
      }));
  }

  getUserSubscriptionDetails(companyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_USER_SUBSCRIPTION_DETAILS + companyId).pipe(
      map((res: any) => {
        return res;
      }));
  }

  geTransactionHistory(companyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_TRANSACTION_HISTORY + companyId).pipe(
      map((res: any) => {
        return res;
      }));
  }

  getAllSubscriptionPlan(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_SUBSCRIPTION_PLAN).pipe(
      map((res: any) => {
        return res;
      }));
  }

  saveUserSubscription(companyId: any, subscriptionId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.SAVE_USER_SUBSCRIPTION + companyId + AppConstant.SEPERATOR + subscriptionId).pipe(
      map((res: any) => {
        return res;
      }));
  }

  emailLink(sendEmail: SendLinkOnEmail): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.EMAIL_LINK, sendEmail).pipe(
      map((res: any) => {
        return res;
      }));
  }

  emailPymanetStatus(paymentId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.PAYMENT_STATUS + paymentId).pipe(
      map((res: any) => {
        return res;
      }));
  }

  paymentStatus(transactionId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.PAYMENTSTATUS + transactionId).pipe(
      map((res: any) => {
        return res;
      }));
  }

  buySubscription(dto: SendLinkOnEmail): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.BUYSUBSCRIPTION, dto).pipe(
      map((res) => {
        return res;
      })
    );
  }

}
