<div class="header-and-toggle">
  <h3>{{ 'add_employee' | translate }}</h3>
  <div class="toggle-container">
    <span class="toggle-content">{{'add_multiple_employee' | translate}}</span>
    <mat-slide-toggle [(ngModel)]="showBulkUpload" (change)="toggleChange()"></mat-slide-toggle>
  </div>
</div>
<div class="add-employee-container">
  <ng-template #bulkUploadView>
    <ol class="sub-section">
      <li>
        {{'step1'|translate}}
        <a (click)="downloadExcel()" class="bulk_upload_link">{{'bulk_employee_upload'|
          translate}}</a>
      </li>
      <li>
        {{'upload_data' | translate}}
        <div class="excelUploadClass">
          <button class="bulk-data" (click)="browseFile()">{{'upload_bulk_employee' | translate}}</button>
          <span *ngIf="fileError" class="error-message">{{'only_allow_excel_file' | translate}}</span>
        </div>
        <input type="file" #fileInput style="display: none;" accept=".xlsx" (change)="onFileBrowse($event)" />
      </li>
    </ol>

  </ng-template>
  <ng-container *ngIf="!showBulkUpload; else bulkUploadView">
    <mat-stepper [linear]="isLinear" #stepper class="stepper-width">

      <mat-step label="{{'basic'|translate}}" [stepControl]="createBasicForm">
        <form [formGroup]="createBasicForm">
          <ng-template matStepperIcon="edit"><mat-icon>done</mat-icon></ng-template>
          <app-basic-details [createBasicForm]="createBasicForm"></app-basic-details>
        </form>
      </mat-step>

      <mat-step label="{{'personal_info'|translate}}" [stepControl]="createPersonalForm">
        <form [formGroup]="createPersonalForm">
          <ng-template matStepperIcon="edit"><mat-icon>done</mat-icon></ng-template>
          <app-personal-information [createPersonalForm]="createPersonalForm"></app-personal-information>
        </form>
      </mat-step>

      <mat-step label="{{'bank_info'|translate}}" [stepControl]="createBankForm">
        <form [formGroup]="createBankForm">
          <ng-template matStepperIcon="edit"><mat-icon>done</mat-icon></ng-template>
          <app-employee-bank-details [createBankForm]="createBankForm"></app-employee-bank-details>
        </form>
      </mat-step>

      <mat-step label="{{'salary_details'|translate}}" [stepControl]="createSalaryDetailsForm">
        <form [formGroup]="createSalaryDetailsForm">
          <ng-template matStepperIcon="edit"><mat-icon>done</mat-icon></ng-template>
          <app-salary-details [createSalaryDetailsForm]="createSalaryDetailsForm"></app-salary-details>
        </form>
      </mat-step>

      <mat-step label="{{'payment_info'|translate}}" [stepControl]="createPaymentInformationForm">
        <form [formGroup]="createPaymentInformationForm">
          <ng-template matStepperIcon="edit"><mat-icon>done</mat-icon></ng-template>
          <app-payment-information
            [createPaymentInformationForm]="createPaymentInformationForm"></app-payment-information>
        </form>
      </mat-step>

      <!-- <mat-step label="{{'roles_and_responsiblity'|translate}}" [stepControl]="roleInfo">
        <form [formGroup]="roleInfo">
          <ng-template matStepperIcon="edit"><mat-icon>done</mat-icon></ng-template>
          <app-role-info-stepper></app-role-info-stepper>
        </form>
      </mat-step> -->

    </mat-stepper>
  </ng-container>
  <app-loader></app-loader>
</div>