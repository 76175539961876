<div class='md-padding' layout="row" flex>
    <div layout="row" layout-xs="column" flex>
        <div class="hrTable">
            <div class="hrMessage">
                <div class="heading-name">
                    <h3 class="employee-name">{{ 'employee_details' | translate }}</h3>
                </div>
                <div class="viewEmployeeDetailsHeader">
                    <div>
                        <mat-form-field appearance="outline" class="employee-type">
                            <mat-label>{{ 'employee_type' | translate }}</mat-label>
                            <mat-select [(ngModel)]="selectedEmployeeType" (selectionChange)="getAllEmployee()">
                                <mat-option value="allEmployee">{{ 'all_employees' | translate }}</mat-option>
                                <mat-option value="activeEmployee">{{ 'active_employees' | translate }}</mat-option>
                                <mat-option value="inactiveEmployee">{{ 'inactive_employees' | translate }}</mat-option>
                                <mat-option value="incompleteEmployee">{{ 'incomplete_employees' | translate
                                    }}</mat-option>
                                <mat-option value="portalEnableEmployees">{{ 'portal_enable_employees' | translate
                                    }}</mat-option>
                                <mat-option value="portalDisableEmployees">{{ 'portal_disable_employees' | translate
                                    }}</mat-option>
                                <mat-option value="exitEmployees">{{ 'exited_employees' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <button (click)="addEmployee()" class="add-employee"
                            matTooltip="{{ 'add_employee' | translate }}">
                            {{ 'add_employee' | translate }}
                        </button>
                    </div>
                </div>
            </div>
            <mat-divider></mat-divider>
            <ag-grid-angular (gridReady)="onGridReady($event)" #agGride style="height: 29rem; width: 100%;"
                class="ag-theme-alpine" [rowData]="employeeData" [rowSelection]="'multiple'" [animateRows]="true"
                [columnDefs]="hrTableCol" [defaultColDef]="hrColDef" [enableSorting]="true" [enableFilter]="true"
                [pagination]="true" [paginationPageSize]="10">
            </ag-grid-angular>
        </div>

    </div>
</div>