import { Component } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-upcomings',
  templateUrl: './upcomings.component.html',
  styleUrls: ['./upcomings.component.scss'],
  animations: [
    trigger('slideInRight', [
      state('void', style({ transform: 'translateX(-100%)' })),
      transition(':enter', [
        animate('2000ms ease-out', style({ transform: 'translateX(0)' })),
      ]),
    ]),
  ],
})
export class UpcomingsComponent {

}
