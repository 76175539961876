import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})

export class ReportComponent {

  constructor(private router: Router) { }

  ProfitLoss() {
    sessionStorage.setItem('report', 'ProfitLoss')
    this.router.navigate([AppConstant.ADMIN_SIDEBAR + AppConstant.SEPERATOR + AppConstant.PROFIT_LOSS_REPORT])
  }

  Payment() {
    sessionStorage.setItem('report', 'paymentReceived')
    this.router.navigate([AppConstant.ADMIN_SIDEBAR + AppConstant.SEPERATOR + AppConstant.PAYMENT_RECEIVED_REPORT])
  }

  customers() {
    sessionStorage.setItem('report', 'newUsers')
    this.router.navigate([AppConstant.ADMIN_SIDEBAR + AppConstant.SEPERATOR + AppConstant.NEW_USERS_REPORT])
  }
}
