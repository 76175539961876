import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { TaxDeclarationServiceService } from 'src/app/services/tax-declaration-service/tax-declaration-service.service';

@Component({
  selector: 'app-view-declared-list',
  templateUrl: './view-declared-list.component.html',
  styleUrls: ['./view-declared-list.component.scss']
})
export class ViewDeclaredListComponent implements OnInit {
  declarationList: any[] = [];
  declarationListColDef: any;
  visibilityTooltip = 'View Resignations';
  declarationListCol = [
    { field: "employeeId", headerName: "Employee ID", width: 120, unSortIcon: true, floatingFilter: true },
    { field: "employeeName", floatingFilter: true, unSortIcon: true, width: 190 },
    { field: "declaredInvestment", floatingFilter: true, unSortIcon: true, width: 190 },
    { field: "annualCtc", headerName: "Annual CTC", floatingFilter: true, unSortIcon: true, width: 250 },
    { field: "status", floatingFilter: true, unSortIcon: true, width: 250 },
    { field: "Action", cellRenderer: this.actionCellRenderer.bind(this), width: 230 }
  ];

  constructor(private renderer: Renderer2, private router: Router, private service: TaxDeclarationServiceService) { }

  ngOnInit(): void {
    this.declarationListColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.getAllApprovalList();
  }

  goToDashboard() {
    if (sessionStorage.getItem(AppConstant.MANAGER_SIDENAV) === AppConstant.EMPLOYEE_ROLE) {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.DASHBOARD])
      sessionStorage.setItem(AppConstant.SHOW_EMPLOYEE_LEAVE, 'false');
      sessionStorage.removeItem(AppConstant.IS_MANAGER);

    }
    else {
      this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.DASHBOARD])
    }
  }

  getAllApprovalList() {
    this.service.getAllApprovalList().subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.declarationList = data.data.map((item: any) => {
            return {
              ...item,
              employeeId: `EMP00${item.employeeId}`
            };
          });
        }
      }
    );
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void, tooltipText: string) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      if (icon === 'delete') {
        this.renderer.setStyle(iconElement, 'margin-right', '15px');
      }
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setAttribute(button, 'MatTooltipModule', 'toolTipText');
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };

    const viewResignationClickHandler = () => {
      const employeeId = params.data.employeeId;
      const numericPart = employeeId.replace(/\D/g, '');
      this.router.navigate([`${AppConstant.MANAGER_SIDENAV}/${AppConstant.DECLARATION_APPROVAL}/${numericPart}`]);
    };

    createButton('visibility', 'green', viewResignationClickHandler, this.visibilityTooltip);
    return cell;
  }

}
