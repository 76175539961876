<div class="email__heading_container">
    <h3 class="title">{{ 'email_configuration' | translate }}</h3>
    <mat-divider></mat-divider>
</div>

<div class="email-config-container">
    <form [formGroup]="emailConfigForm">
        <div>
            <mat-form-field appearance="outline">
                <mat-label>{{ 'support_email' | translate }}</mat-label>
                <input matInput formControlName="supportEmail"
                    [readonly]="!isEditing || editableField !== 'supportEmail'" />
                <button *ngIf="shouldShowPencilIcon('supportEmail')" matSuffix mat-icon-button
                    (click)="enableEditing('supportEmail')">
                    <mat-icon class="edit">edit</mat-icon>
                </button>
                <mat-error *ngIf="emailConfigForm.get('supportEmail')?.hasError('required')">
                    {{ 'email_is_required' | translate }}
                </mat-error>
                <mat-error *ngIf="emailConfigForm.get('supportEmail')?.hasError('email')">
                    {{ 'please_enter_valid_email' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline">
                <mat-label>{{ 'no_reply_email' | translate }}</mat-label>
                <input matInput formControlName="noReplyEmail"
                    [readonly]="!isEditing || editableField !== 'noReplyEmail'" />
                <button *ngIf="shouldShowPencilIcon('noReplyEmail')" matSuffix mat-icon-button
                    (click)="enableEditing('noReplyEmail')">
                    <mat-icon class="edit">edit</mat-icon>
                </button>
                <mat-error *ngIf="emailConfigForm.get('noReplyEmail')?.hasError('required')">
                    {{ 'no_reply_email_is_required' | translate }}
                </mat-error>
                <mat-error *ngIf="emailConfigForm.get('noReplyEmail')?.hasError('email')">
                    {{ 'please_enter_valid_email' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline">
                <mat-label>{{ 'add_sales_email' | translate }}</mat-label>
                <input matInput formControlName="addSalesEmail"
                    [readonly]="!isEditing || editableField !== 'addSalesEmail'" />
                <button *ngIf="shouldShowPencilIcon('addSalesEmail')" matSuffix mat-icon-button
                    (click)="enableEditing('addSalesEmail')">
                    <mat-icon class="edit">edit</mat-icon>
                </button>
                <mat-error *ngIf="emailConfigForm.get('addSalesEmail')?.hasError('required')">
                    {{ 'sales_email_is_required' | translate }}
                </mat-error>
                <mat-error *ngIf="emailConfigForm.get('addSalesEmail')?.hasError('email')">
                    {{ 'please_enter_valid_email' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <mat-form-field class="log-description" appearance="outline">
            <mat-label>{{ 'description' | translate }}</mat-label>
            <textarea matInput maxlength="200" formControlName="description"></textarea>
        </mat-form-field>
        <div class="button-container">
            <button class="cancel-btn" type="button" (click)="resetForm()">{{ 'cancel' | translate }}</button>
            <button class="button" (click)="saveConfig()">{{ 'save' | translate }}</button>
        </div>
    </form>
</div>