import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from "src/app/config/api-endpoint.config";
import { environment } from 'src/environments/enviroment';
import { AppConstant } from 'src/app/constants/app.constants';


@Injectable({
  providedIn: 'root'
})
export class BirthdayListService {

  constructor(private http: HttpClient) { }

  getBirthdayList(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_BIRTHDAY_LIST + sessionStorage.getItem(AppConstant.COMPANY_ID)).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

}
