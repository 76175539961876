
 <div class="snack-bar-container">
    <mat-progress-bar *ngIf="data.FileProgress < 100" class="progressbar" [value]="data.FileProgress"></mat-progress-bar>
    <div *ngIf="data.FileProgress === 100">
      <div class="snackbar-content">
        <span class="parse-file">{{'employee_add_one_by_one' | translate}}</span>
        <mat-spinner diameter="20" class="spinner"></mat-spinner>
      </div>
    </div>
    <div *ngIf="data.FileProgress !== 100">
      <div class="snackbar-content">
        <span>{{ data.FileSize }}</span>
        <span>{{' of ' | translate}}{{ truncateProgress(data.FileProgress) }}%</span>
        <div class="right-content">
          <span>{{ data.FileName }}</span>
        </div>
      </div>
    </div>
  </div>
  