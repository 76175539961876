import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { BankDetailsService } from 'src/app/services/bank-details/bank-details.service';
import { ButtonStateService } from 'src/app/services/button-state.service';

@Component({
  selector: 'app-direct-deposit',
  templateUrl: './direct-deposit.component.html',
  styleUrls: ['./direct-deposit.component.scss']
})
export class DirectDepositComponent implements OnInit {
  showSetUpNowButton: boolean = true;
  bankData: any[] = [];
  constructor(private router: Router, private buttonStateService: ButtonStateService, private bankService: BankDetailsService) {

    this.buttonStateService.buttonState$.subscribe((state: boolean) => {
      this.showSetUpNowButton = state;
    });
  }
  ngOnInit(): void {
    this.initBankDetails();
  }

  initBankDetails() {
    this.bankService.getBanks().subscribe((data: any) => {
      this.bankData = data.data.map((bank: any) => {
        return {
          ...bank,
          logo: 'data:image/jpeg;base64,' + bank.bankLogo
        };
      });
    });
  }

  bankIntegration(bank: any) {
    sessionStorage.setItem("bankName", bank.bankName);
    sessionStorage.setItem("bankLogo", bank.logo);
    this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.BANK_INTEGRATION])
    this.showSetUpNowButton = false;
  }

  viewDetails() {
    this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_INTEGRATED_BANK])
  }
}
