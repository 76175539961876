import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { SideBar } from 'src/app/model/sidebar';

@Component({
  selector: 'app-admin-module-sidebar',
  templateUrl: './admin-module-sidebar.component.html',
  styleUrls: ['./admin-module-sidebar.component.scss']
})
export class AdminModuleSidebarComponent {
  adminModule: boolean = false;
  isExpanded: boolean = false;
  isShowing: boolean = false;
  isExpandedAdminMenu: boolean = false;
  showSubMenu: boolean = false;

  constructor(private router: Router) { }

  menuList: SideBar[] = [
    { "icon": "home", "name": "Home" },
    { "icon": "supervised_user_circle", "name": "Customer" },
    { "icon": "subscriptions", "name": "Subscription" },
    { "icon": "report", "name": "Report" },
    { "icon": "settings", "name": "Setting" },
    { "icon": "monetization_on", "name": "Tax Declaration" },
    { "icon": "archive", "name": "Regime" },
    { 'icon': "redeem", "name": "Voucher" },
    { "icon": "logout", "name": "Logout" }
  ];

  navigate(menuName: string) {
    this.showSubMenu = !this.showSubMenu;
    if (menuName === AppConstant.LOGOUT) {
      sessionStorage.clear();
      this.router.navigate([AppConstant.LOGIN_PAGE]);
    }
    else {
      this.router.navigate([AppConstant.ADMIN_SIDEBAR + AppConstant.SEPERATOR + AppConstant.getMenuName(menuName)]);
    }
  }
}
