<div class="main-container">
   <div class="tittle-div">
      <span matTooltip="{{'back_to_dashboard' | translate}}" (click)="goToBack()">
         <mat-icon svgIcon="arrow-back-double" class="rewind"></mat-icon>
      </span>
      <div class="heading">
         <h3>{{ 'view_training_details' | translate }}</h3>
      </div>
   </div>
   <mat-divider></mat-divider>
   <div class="ppt-frame">
      <object *ngIf="showPdf" [data]="fileUrl" width="100%" height="500px"></object>
      <video width="100%" height="500px" controls controlslist="nodownload" *ngIf="fileUrl != undefined && !showPdf">
         <source [src]="fileUrl" [type]="this.trainingDetails?.file?.fileType">
      </video>
   </div>
   <div class="training">
      <mat-card class="training-details">
         <mat-card-content>
            <mat-tab-group class="tabs">
               <mat-tab label="Overview">
                  <div class="training-detail-card">
                     <div>
                        <span class="training-title">{{
                           trainingDetails?.trainingName }}</span>
                     </div>
                     <div class="short-desc">
                        <span>{{ trainingDetails?.trainingShortDescription
                           }}</span>
                     </div>
                     <div class="long-desc">
                        <span>{{ trainingDetails?.trainingLongDescription
                           }}</span>
                     </div>
                     <div>
                        <div class="star-rating-container star-rating-animation" fxLayout="row"
                           fxLayoutAlign="start center">
                           <div *ngFor="let star of stars" [ngClass]="star.class">
                              <mat-icon>{{ star.icon }}</mat-icon>
                           </div>
                        </div>
                        <span class="rating">{{ 'average_rating' | translate }}
                           : {{ trainingDetails?.avgRatting }}</span>
                        <span class="total-views">{{ 'total_reviews' | translate
                           }} ({{ trainingDetails?.totalView }})</span>
                     </div>
                  </div>
                  <div class="row">
                     <div class="col-sm-12">
                        <form [formGroup]="ratingForm">
                           <div class="rating-component">
                              <mat-label>{{ 'rating' | translate
                                 }}</mat-label>
                              <app-rating [rating]="0" (ratingUpdated)="onRatingChange($event)"></app-rating>
                           </div>
                           <div class="comment-box-button" *ngIf="showComments">
                              <mat-form-field class="full-width" appearance="outline">
                                 <mat-label>{{ 'comment' |
                                    translate
                                    }}</mat-label>
                                 <textarea matInput formControlName="comment"></textarea>
                              </mat-form-field>
                              <div class="button-div">
                                 <button (click)="cancel()" class="cancel-btn" type="reset">
                                    {{ 'cancel' | translate
                                    }}
                                 </button>
                                 <button (click)="onSubmit()" type="submit">
                                    {{ 'submit' | translate
                                    }}
                                 </button>
                              </div>
                           </div>
                        </form>
                     </div>
                  </div>
               </mat-tab>
               <mat-tab label="Review">
                  <div class="comments-container">
                     <div *ngFor="let comment of displayComments">
                        <div class="reviewer-name">
                           <div class="avatar-circle">
                              <span class="initials">{{
                                 comment.initials }}</span>
                           </div>
                           <span><b>{{ comment?.name }}</b></span>
                        </div>
                        <div class="row">
                           <div class="col-sm-12">
                              <div class="rating-heading">
                                 <span><b>Rating:</b></span>
                                 <ul class="list-inline rating-list" style="display: inline-block">
                                    <li *ngFor="let star of stars; let i = index"
                                       [ngClass]="{ 'selected': star <= comment.rating }">
                                       ★
                                    </li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div class="comment-content">
                           <p class="clearfix">
                              {{ comment?.text }}
                           </p>
                        </div>
                     </div>
                     <div class="button">
                        <button class="review-button" (click)="showMoreComments()"><b>See more
                              Reviews</b></button>
                     </div>
                  </div>
               </mat-tab>
            </mat-tab-group>
         </mat-card-content>
      </mat-card>
   </div>
</div>