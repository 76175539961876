
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorPopupComponent } from 'src/app/shared/error-popup/error-popup.component';
import { LoaderService } from '../loader/loader.service';
import { AppConstant } from 'src/app/constants/app.constants';
import { environment } from 'src/environments/environment.dev';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';

@Injectable()
export class HttpErrorResponseInterceptor implements HttpInterceptor {
  constructor(private dialog: MatDialog, private loader: LoaderService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.shouldSkipSpinner(request)) {
      return next.handle(request);
    }

    this.loader.show();
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 405 || error.status === 500) {
            this.showPopup(AppConstant.ERROR, error.message);
            this.loader.hide();
          }
          return throwError(() => error);
        }),
        finalize(() => {
          this.loader.hide();
        }),

        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            const authorizationHeader = event.headers.get(AppConstant.AUTHORIZATION);
            if (authorizationHeader) {
              const token = authorizationHeader.replace(AppConstant.BAREAR, '');
              sessionStorage.setItem(AppConstant.TOKEN, token);
            }
          }
        })
      );
  }

  private showPopup(title: string, message: string): void {
    this.dialog.open(ErrorPopupComponent, {
      disableClose: true,
      data: {
        title,
        message
      }
    });
  }

  private shouldSkipSpinner(request: HttpRequest<any>): boolean {
    const ifscCode = sessionStorage.getItem(AppConstant.IFSC_CODE);
    const gstNumber = sessionStorage.getItem(AppConstant.GST_NUMBER);
    const ifscApiUrl = environment.apiUrl + API_END_POINTS.IFSC_API + ifscCode;
    const uploadEmployeeBulkDataUrl = environment.apiUrl + API_END_POINTS.UPLOAD_EMPLOYEE_BULK_DATA;
    const gstNumberUrl = API_END_POINTS.GST_API + AppConstant.GSTKEY + AppConstant.SEPERATOR + gstNumber;
    const paymentUrl ="http://localhost:8080/Subscription/findQRTransactionStatus/qr_NlojHcjhzdunDj";
    sessionStorage.removeItem(AppConstant.GST_NUMBER);
    sessionStorage.removeItem(AppConstant.IFSC_CODE);
    return request.url.includes(ifscApiUrl) || request.url.includes(uploadEmployeeBulkDataUrl) || request.url.includes(gstNumberUrl) || request.url.includes(paymentUrl);
  }
  
}

