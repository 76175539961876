import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { BranchService } from 'src/app/services/branch.service';
import { CompanyProfileService } from 'src/app/services/company-profile/company-profile.service';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-add-branch',
  templateUrl: './add-branch.component.html',
  styleUrls: ['./add-branch.component.scss']
})
export class AddBranchComponent {

  public addBranchForm!: FormGroup;
  selectedCountry: string = "";
  selectedState: string = "";
  selectedCity: string = "";
  showGSTSection: boolean = false;
  countryList: any;
  stateList: any;
  cityList: any;
  hasGSTNumber: boolean = false;
  showInvalidGSTError: boolean = false;
  verifiedGST: boolean = false;
  isLoading: boolean = false;
  registrationDate: boolean = false;
  data: any;
  branchId: number | null = null;
  isEditMode: boolean = false;
  isUpdating: boolean = false;

  companyId: number | any = Number(sessionStorage.getItem(AppConstant.COMPANY_ID));
  constructor(private route: ActivatedRoute, private router: Router, private toasterService: ToastrService, private branchService: BranchService, private cdr: ChangeDetectorRef, private masterData: MasterDataService, private fb: FormBuilder, private companyProfileService: CompanyProfileService) { }

  ngOnInit() {
    this.initAddBranch();
    this.getCountryList();

    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id) {
        this.branchId = Number(id);
        this.getBranchDetails(this.branchId);
      }
    });

  }

  initAddBranch() {
    this.addBranchForm = this.fb.group({
      branchName: ['', [Validators.required, Validators.maxLength(50)]],
      branchCode: ['', [Validators.required, Validators.maxLength(10)]],
      registrationNo: [''],
      registrationDate: ['', [Validators.required]],
      country: ['', [Validators.required]],
      state: ['', [Validators.required]],
      city: [''],
      pinCode: ['', [Validators.required, Validators.pattern(/^[0-9]{6}$/)]],
      addressLine1: ['', [Validators.required]],
      addressLine2: [''],
      gstNumber: [''],
      isMainbranch: [false]
    });
  }

  getBranchDetails(branchId: number) {
    this.branchService.getBranchById(branchId).subscribe((response: any) => {
      // if (response.status === 200 && response.data) {
      this.patchFormValues(response);
      // } else {
      // this.toasterService.error('Failed to fetch branch details.');
      // }
    });
  }

  getCountryList() {
    this.masterData.getAllCountries().subscribe(
      (data: any) => {
        if (data.status === 200)
          this.countryList = data.data;
      }
    )
  }


  getState(country: any) {
    this.selectedCountry = country;
    this.showGSTSection = (this.selectedCountry === 'India');

    this.masterData.getAllState(country).subscribe(
      (data: any) => {
        this.stateList = data.data;
      }
    )
  }
  getCity(state: any) {
    this.selectedState = state;
    this.masterData.getAllCity(state).subscribe(
      (data: any) => {
        this.cityList = data.data;
      }
    )
  }

  selectedCityName(city: any) {
    this.selectedCity = city;
  }

  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumber(event);
  }

  validateGST(event: any): boolean {
    return ValidationUtil.validateGST(event);
  }

  alphanumericAllowed(event: any) {
    ValidationUtil.onlyAlphanumeric(event);
  }

  alphanumberOnly(event: any) {
    return ValidationUtil.alphanumberOnly(event);
  }

  gstNumberUpdated(event: any) {
    this.verifiedGST = false;
  }

  verifyGST() {
    this.registrationDate = true
    if (this.addBranchForm.value.gstNumber != null && this.addBranchForm.value.gstNumber != " ") {
      this.isLoading = true;
      sessionStorage.setItem(AppConstant.GST_NUMBER, this.addBranchForm.value.gstNumber)
      this.companyProfileService.getGst(this.addBranchForm.value.gstNumber).subscribe((data: any) => {
        this.isLoading = false;
        if (data.flag == false) {
          this.isLoading = false;
        } else {
          this.data = data;
          this.selectedCountry = "India";
          this.addBranchForm.get('country')?.setValue(this.selectedCountry);
          this.getState(this.selectedCountry);
          this.selectedState = this.data.data.pradr?.addr?.stcd?.trim();
          this.addBranchForm.get('state')?.setValue(data.data.pradr?.addr?.stcd.trim());
          this.getCity(this.selectedState);
          this.selectedCity = this.data.data.pradr?.addr?.dst?.trim();
          this.addBranchForm.get('city')?.setValue(data.data.pradr?.addr?.dst.trim());
          this.addBranchForm.patchValue({
            companyName: this.data.data.tradeNam.trim(),
            registrationNo: this.addBranchForm.value.registrationNo.trim(),
            country: this.selectedCountry.trim(),
            state: this.selectedState.trim(),
            city: this.selectedCity.trim(),
            addressLine1: this.data.data.pradr?.adr.trim(),
            addressLine2: this.data.data.pradr?.addr?.bnm.trim(),
            gstNumber: this.addBranchForm.value.gstNumber,
            pinCode: this.data.data.pradr?.addr?.pncd.trim(),
            companyRegistrationDate: this.data.data.rgdt.trim()
          });
        }
        this.cdr.detectChanges();
        if (data.flag) {
          this.showInvalidGSTError = false;
          this.addBranchForm.controls['gstNumber'].setErrors(null);
          this.verifiedGST = true;
        } else {
          this.showInvalidGSTError = true;
          this.addBranchForm.controls['gstNumber'].setErrors({ 'invalidGST': true });
        }
      });
    } else {
      this.showInvalidGSTError = true;
      this.addBranchForm.controls['gstNumber'].setErrors({ 'invalidGST': true });
    }
  }

  cancel() {
    this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.VIEW_BRANCH]);
  }
  toggleEditMode() {
    this.isEditMode = !this.isEditMode;
    if (this.isEditMode) {
      this.addBranchForm.enable();
    } else {
      this.addBranchForm.disable();
    }
  }

  submitForm() {
    const formData = {
      ...this.addBranchForm.value,
      companyDetailsModel: { id: this.companyId }
    };
    if (this.branchId) {
      this.branchService.updateBranch(this.branchId, formData).subscribe(response => {
        if (response.status === 200) {
          this.toasterService.success(response.message);
          this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.VIEW_BRANCH]);
        } else {
          this.toasterService.error(response.message);
        }
      });
    } else {
      this.branchService.addBranch(formData).subscribe(response => {
        if (response.status === 200) {
          this.toasterService.success(response.message);
          this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.VIEW_BRANCH]);
        } else {
          this.toasterService.error(response.message);
        }
      });
    }

  }

  patchFormValues(branchData: any) {
    this.addBranchForm.patchValue({
      branchName: branchData.data.branchName,
      branchCode: branchData.data.branchCode,
      registrationNo: branchData.data.registrationNo,
      registrationDate: branchData.data.registrationDate,
      country: branchData.data.country,
      state: branchData.data.state,
      city: branchData.data.city,
      pinCode: branchData.data.pinCode,
      addressLine1: branchData.data.addressLine1,
      addressLine2: branchData.data.addressLine2,
      gstNumber: branchData.data.gstNumber,
      isMainbranch: branchData.data.isMainbranch
    });
    this.addBranchForm.disable()

    this.selectedCountry = branchData.data.country;
    this.selectedState = branchData.data.state;
    this.selectedCity = branchData.data.city;
    this.getState(branchData.data.country);
    this.getCity(branchData.data.state);
  }


  updateBranch(branchId: number) {
    this.branchService.updateBranch(branchId, this.addBranchForm.value).subscribe(response => {

    });
  }

  setBranchInactive(branchId: number) {
    this.branchService.deleteBranch(branchId).subscribe(response => {

    });
  }

}
