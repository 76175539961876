import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { environment } from 'src/environments/enviroment';

@Injectable({
  providedIn: 'root'
})
export class SalaryService {
  constructor(private http: HttpClient) { }

  getCompanyDetails(companyId: any) {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_COMPANY_DETAILS + companyId);
  }

  getEmployeeDetails(companyId: any, empId: any) {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_EMPLOYEE_DETAILS + companyId + "/" + empId);
  }

  savePdfToDatabase(pdfBlob: any) {
    return this.http.post(environment.apiUrl + API_END_POINTS.SAVE_SALARY_SLIP_PDF, pdfBlob);
  }

  getEarningData(companyId: any, empId: any, year: any, month: any): Observable<any> {
    const url = `${environment.apiUrl}${API_END_POINTS.SALARY_EARNING_DETAILS}?companyId=${companyId}&empId=${empId}&year=${year}&month=${month}`;
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getDeductionData(companyId: any, empId: any, year: any, month: any): Observable<any> {
    const url = `${environment.apiUrl}${API_END_POINTS.SALARY_DEDUCTION_DETAILS}?companyId=${companyId}&empId=${empId}&year=${year}&month=${month}`;
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getReimbursementData(companyId: any, empId: any, year: any, month: any): Observable<any> {
    const url = `${environment.apiUrl}${API_END_POINTS.SALARY_EARNING_DETAILS}?companyId=${companyId}&empId=${empId}&year=${year}&month=${month}`;
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getSalaryStructure(companyId:any): Observable<any> {
    return this.http.get(environment.apiUrl+API_END_POINTS.GET_ALL_SALARY_STRUCTURE_DETAILS+companyId).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

}

