import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { ProfilePopupComponent } from '../profile-popup/profile-popup.component';

@Component({
  selector: 'app-menu-list',
  templateUrl: './menu-list.component.html',
  styleUrls: ['./menu-list.component.scss']
})
export class MenuListComponent implements OnInit {
  adminRole: boolean = sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === 'admin';
  employeeDetails: any;

  constructor(private readonly router: Router, private readonly employeeService: EmployeeDetailsService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getEmployeeDetails();
  }

  getEmployeeDetails() {
    this.employeeService.getEmployeeById(
      Number(sessionStorage.getItem(AppConstant.COMPANY_ID)),
      Number(sessionStorage.getItem(AppConstant.EMPLOYEE_ID)),
    ).subscribe((employeeData: any) => {
      console.log(employeeData);

      if (employeeData) {
        this.employeeDetails = {
          employeeId: employeeData.employeeBasicDetail?.id || 'N/A',
          employeeName: `${employeeData.employeeBasicDetail?.firstName || ''} ${employeeData.employeeBasicDetail?.middleName || ''} ${employeeData.employeeBasicDetail?.lastName || ''}`.trim(),
          designation: employeeData.employeeBasicDetail?.designationName || 'N/A',
          department: employeeData.employeeBasicDetail?.departmentName || 'N/A',
          workEmail: employeeData.employeeBasicDetail?.workEmail || 'N/A',
          personalMail: employeeData.employeePersonalDetail?.email || 'N/A',
          payType: employeeData.employeeSalaryDetail?.paidType || 'N/A',
          amount: employeeData.employeeSalaryDetail?.amount || 0,
          mobileNumber: employeeData.employeePersonalDetail?.mobileNo || 'N/A',
          addressLine1: employeeData.employeePersonalDetail?.addressLine1 || 'N/A',
          addressLine2: employeeData.employeePersonalDetail?.addressLine2 || 'N/A',
          country: employeeData.employeePersonalDetail?.country || 'N/A',
          city: employeeData.employeePersonalDetail?.city || 'N/A',
          state: employeeData.employeePersonalDetail?.state || 'N/A',
          pincode: employeeData.employeePersonalDetail?.pinCode || 'N/A',
          branchId: employeeData.employeeBasicDetail?.branchId || 'N/A'
        };
      }
    });
  }

  openProfilePopup(): void {
    this.dialog.open(ProfilePopupComponent, {
      width: '400px',
      data: this.employeeDetails
    });
  }

  logout() {
    sessionStorage.clear()
    this.router.navigate([AppConstant.MOBILE]);
  }

}
