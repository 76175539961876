<div class="main-container">
    <h3> {{ 'update_task' | translate }} </h3>
    <div class="content">
        <form [formGroup]="updateTaskForm">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'select_project' | translate }}</mat-label>
                <mat-select formControlName="projectId" (selectionChange)="onProjectChange($event)">
                    <mat-option *ngFor="let projectDetails of projectCodeOption" [value]="projectDetails.id">
                        {{ projectDetails.projectName}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="updateTaskForm.controls['projectId'].hasError('required')">
                    {{ 'please_select_project' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{'summary'|translate}}</mat-label>
                <input class="Input-field" matInput type="text" maxlength="100"
                    (keypress)="characterNumberAndSpaceAndHyphenAllowed($event)" formControlName="summary" />
                <mat-error
                    *ngIf="updateTaskForm.get('summary')?.hasError('required') || updateTaskForm.get('summary')?.hasError('noOnlySpaces')">
                    {{ 'summary_is_required' | translate }}
                </mat-error>
            </mat-form-field>

            <div class="project-row">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'description'|translate}}</mat-label>
                    <textarea matInput formControlName="description" rows="2" maxlength="100"></textarea>
                </mat-form-field>
            </div>

            <div class="form-field-gap1">
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'status' | translate }}</mat-label>
                    <mat-select formControlName="status">
                        <mat-option *ngFor="let status of taskStatus" [value]="status.value">
                            {{ status.status }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'story_point' | translate}}</mat-label>
                    <input matInput formControlName="taskPointEstimate" (keypress)="validateNumber($event)" (input)="restrictToTwoDigits()" />
                    <mat-error *ngIf="updateTaskForm.controls['taskPointEstimate'].hasError('greaterThanZero')">
                        {{ 'story_point_must_be_greater_than_zero' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-field-gap1">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ 'assignee' | translate }}</mat-label>
                    <mat-select formControlName="assigneeTo" #singleSelect>
                        <mat-option>
                            <ngx-mat-select-search [placeholderLabel]="'Search'" [formControl]="personSearchFilter"
                                noEntriesFoundLabel="" [searching]="searching" [preventHomeEndKeyPropagation]="true">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngIf="noRecordsFound" disabled>
                            {{'no_matches_found'|translate}}
                        </mat-option>
                        <mat-option *ngFor="let employee of filteredServerSide | async" [value]="employee.id">
                            {{ employee.firstName }} {{ employee.lastName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ 'reporter' | translate }}</mat-label>
                    <mat-select formControlName="reporterId" #singleSelect>
                        <mat-option>
                            <ngx-mat-select-search [placeholderLabel]="'Search'" [formControl]="personSearchFilter"
                                noEntriesFoundLabel="" [searching]="searching" [preventHomeEndKeyPropagation]="true">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngIf="noRecordsFound" disabled>
                            {{'no_matches_found'|translate}}
                        </mat-option>
                        <mat-option *ngFor="let employee of filteredServerSide | async" [value]="employee.id">
                            {{ employee.firstName }} {{ employee.lastName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'select_parent' | translate }}</mat-label>
                <mat-select formControlName="parentId">
                    <mat-option *ngFor="let sprint of sprintList" [value]="sprint.sprintId">
                        {{ sprint.sprintName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field id="projectCode" appearance="outline" class="full-width">
                <mat-label>{{ 'select_issue' | translate }}</mat-label>
                <mat-select formControlName="linkedTasks">
                    <mat-option *ngFor="let taskName of issueList " [value]="taskName.taskName">
                        {{ taskName.taskName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="project-row">
                <mat-form-field id="projectCode" appearance="outline" class="full-width">
                    <mat-label>{{ 'select_sprint' | translate }}</mat-label>
                    <mat-select formControlName="sprintId">
                        <mat-option *ngFor="let sprint of sprintList" [value]="sprint.sprintId">
                            {{ sprint.sprintName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="project-row">
                <div class="attachment-container">
                    <div class="attachment-header">
                        <label>{{ 'attachment' | translate }}</label>
                    </div>
                    <div class="attachment-body">
                        <input type="file" style="display: none" #fileInput multiple formControlName="uploadDocuments"
                            (change)="onChangeFileInput($event)"
                            accept=".ppt, .pptx, .pdf, .doc, .docx, .xls, .xlsx, .csv" required="*" />
                        <div class="attachment-box" (click)="fileInput.click()">
                            <span>
                                <a>{{ 'browse' | translate }}</a>
                            </span>
                        </div>
                        <div class="document-upload-messages">
                            <mat-error *ngIf="fileSizeError" class="logo-content">
                                {{ 'file_size_error_25mb' | translate }}
                            </mat-error>
                        </div>
                    </div>
                    <div *ngIf="selectedFiles.length > 0" class="selected-files">
                        <div *ngFor="let file of selectedFiles" class="file-item">
                            <span class="file-name">{{ file.name }}</span>
                            <mat-icon class="remove-icon" (click)="removeFile(file)">cancel</mat-icon>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <button class="button" (click)="updateTask()">{{'update'|translate}}</button>
        <button class="cancel-btn cancel" (click)="goToDashboard()">{{'cancel'|translate}}</button>
    </div>
    <app-loader></app-loader>
</div>