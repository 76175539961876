import { Injectable } from '@angular/core';
import { environment } from 'src/environments/enviroment';
import * as CryptoJS from "crypto-js";

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  encrypt(plantext: string) {
    return CryptoJS.AES.encrypt(plantext, environment.passPhrase).toString();
  }
}
