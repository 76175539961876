import { Pipe, PipeTransform } from '@angular/core';
import { Messages } from '../constants/messages.constant';

@Pipe({
  name: 'monthName'
})
export class MonthNamePipe implements PipeTransform {

  transform(monthNumber: number): string {
    const months = Messages.MONTHS;
    return months[monthNumber - 1] || '';
  }
}
