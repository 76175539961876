import { Component } from '@angular/core';
import { EventManagementService } from 'src/app/services/event-management/event-management.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent {
  eventList: Events[] = [];
  constructor(private eventManagementService: EventManagementService) {
    this.eventManagementService.getAllEventList().subscribe(events => {
      this.eventList = events.data;
    });
  }
}

export interface Events {
  description: string,
  eventEndDate: Date,
  eventId: number,
  eventName: string,
  eventStartDate: Date,
  eventType: string,
  status: string
}
