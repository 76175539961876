<div class="card-container">

    <mat-toolbar class="view-toolbar">
        <mat-icon class="back" svgIcon="arrow-back-double" (click)="back()"></mat-icon>
        <h1>{{'performance_goal_plan'|translate}}</h1>
        <div class="performance-matrix" (click)="downloadGoalsExcel()">{{'performace_matrix' | translate}}</div>
        <span class="apply-leave-button"></span>
        <button class="button" (click)="addGoal()">
            {{'add_goal' | translate}}
        </button>
    </mat-toolbar>

    <mat-card class="card-content">
        <form>
            <div *ngFor="let data of emplpoyeeGoal">
                <h4 class="goalName">{{'goal_name'|translate}}</h4>
                <p class="dataContainer">{{data.goalName}}</p>
                <mat-icon class="viewIcon delete" (click)="deleteGoal(data.id,data.id)">delete</mat-icon>
                <mat-icon class="viewIcon" (click)="loadGoalDetails(data.id)">visibility</mat-icon>
                <h4 class="description">{{'goal_status'|translate}}</h4>
                <p class="dataContainer">{{data.status}}</p>
                <h4 class="description">{{'weight'|translate}}</h4>
                <p class="dataContainer">{{data.weights}}</p>
                <h4 class="description">{{'goal_description'|translate}}</h4>
                <p class="dataContainer">{{data.description}}</p>
                <mat-divider class="divderclass"></mat-divider>
            </div>
        </form>
    </mat-card>
</div>