<form [formGroup]="formGroup">
    <mat-card class="card-details">
        <div class="mat-form-field-gap">
            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'from_date' | translate }}</mat-label>
                <input matInput [matDatepicker]="fromDatePicker" formControlName="fromDate"
                    (keypress)="$event.preventDefault()" required [matDatepickerFilter]="currentYearDateFilter" />
                <mat-datepicker-toggle matSuffix [for]="fromDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #fromDatePicker></mat-datepicker>
                <mat-error *ngIf="formGroup.controls['fromDate'].hasError('required')">
                    {{ 'from_date_is_required' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'to_date' | translate }}</mat-label>
                <input matInput [matDatepicker]="toDatePicker" formControlName="toDate" required
                    (keypress)="$event.preventDefault()" [min]="getMaximunDate()"
                    [matDatepickerFilter]="currentYearDateFilter" />
                <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #toDatePicker></mat-datepicker>
                <mat-error *ngIf="formGroup.controls['toDate'].hasError('required')">
                    {{ 'to_date_is_required' | translate }}
                </mat-error>
                <mat-error *ngIf="formGroup.controls['toDate'].hasError('matDatepickerMin')">
                    {{ 'to_date_before_from_date' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'city' | translate }}</mat-label>
                <input matInput formControlName="city" required maxlength="60"
                    (keypress)="validateCharacterNumberAndSpace($event)" />
                <mat-error *ngIf="formGroup.controls['city'].hasError('required')">
                    {{ 'city_is_required' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'landlord_name' | translate }}</mat-label>
                <input matInput formControlName="landlordName" required maxlength="60"
                    (keypress)="validateCharacterNumberAndSpace($event)" />
                <mat-error *ngIf="formGroup.controls['landlordName'].hasError('required')">
                    {{ 'landlord_name_is_required' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="mat-form-field-gap">

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'landLord_pan' | translate }}</mat-label>
                <input matInput formControlName="landLordPan" required appUppercase maxlength="10"
                    (keypress)="validateCharacterNumberAndSpace($event)" />
                <mat-error *ngIf="formGroup.controls['landLordPan'].hasError('required')">
                    {{ 'landLord_pan_is_required' | translate }}
                </mat-error>
                <mat-error *ngIf="formGroup.controls['landLordPan'].hasError('pattern')">
                    {{ 'invalid_pan_format' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="house-address">
                <mat-label>{{ 'rented_house_address' | translate }}</mat-label>
                <input matInput formControlName="rentedHouseAddress" required
                    (keypress)="validateCharacterNumberAndSpace($event)" />
                <mat-error *ngIf="formGroup.controls['rentedHouseAddress'].hasError('required')">
                    {{ 'rented_house_address_is_required' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
                <mat-label>{{ 'rent_per_month_declared' | translate }}</mat-label>
                <input matInput formControlName="rentPerMonthDeclared" required maxlength="8"
                    (keypress)="numberValidation($event)" />
                <mat-error *ngIf="formGroup.controls['rentPerMonthDeclared'].hasError('required')">
                    {{ 'rent_per_month_declared_is_required' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="button-div">
            <button class="cancel-btn" matStepperNext>{{ 'skip' | translate }}</button>
            <button (click)="submitHouseDetails()">{{ 'save_and_next' | translate }}</button>
        </div>
    </mat-card>
</form>