<div class="role">
    <div class="mat-form-field">
        <mat-form-field appearance="outline">
            <mat-label>{{ 'select_role' | translate }}</mat-label>
            <mat-select (selectionChange)="selectRole($event)">
                <mat-option *ngFor="let role of roleList" [value]="role.id" [disabled]="isRoleSelected(role.id)">
                    {{ role.roleName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="role-table" *ngIf="selectedRoleList.length > 0">
        <ag-grid-angular style="width: 100%; height: 500px;" class="ag-theme-alpine" [rowData]="selectedRoleList"
            [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"></ag-grid-angular>
    </div>
</div>