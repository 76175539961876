import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { ResignationData, ResignationHrData } from 'src/app/model/resignation/resignation/resignation.module';
import { ResignationService } from 'src/app/services/resignation/resignation.service';
import { FormatUtil } from 'src/app/utils/format.utils';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-hr-view-employee-resignation',
  templateUrl: './hr-view-employee-resignation.component.html',
  styleUrls: ['./hr-view-employee-resignation.component.scss']
})
export class HrViewEmployeeResignationComponent {
  employeeResignationForm !: FormGroup;
  formSubmitted: boolean = false;
  maxFinalSettlementDate: Date = new Date();
  constructor(private toaster: ToastrService, private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private resignationService: ResignationService) {
    this.minDate.setHours(0, 0, 0, 0);
    this.maxFinalSettlementDate.setHours(0, 0, 0, 0);
  }
  resignationDetails: any;
  id: any;
  isAdmin: boolean = false;
  employeeName: any;
  isHr: boolean = true;
  isManager: boolean = true;
  isHrApproved: Boolean = false;
  minDate = new Date();
  hrLogin = false;
  isManagerLogin: boolean = false;
  hrFNFShow = false;
  companyid = sessionStorage.getItem(AppConstant.COMPANY_ID)
  minFinalSettlementDate: Date = new Date();
  lastWorkingDate: any;
  finalSettlemtDate: any;
  ngOnInit(): void {
    if (sessionStorage.getItem('role') === 'admin') {
      this.isAdmin = true;
      this.hrLogin = true;
    }
    this.route.params.subscribe(params => {
      this.id = params['id'];
      sessionStorage.setItem('employeeId', this.id);
      if (this.id) {
        this.getResignation(this.id);
      }
    });
    this.initEmployeeResignation();
  }

  goToDashboard() {
    this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_RESIGNATIONS]);
  }

  calculateMaxFinalSettlementDate(lastWorkingDate: Date): Date {
    const maxDate = new Date(lastWorkingDate);
    maxDate.setDate(maxDate.getDate() + 45);
    return maxDate;
  }
  onLastWorkingDateChange(event: any) {
    const selectedDate = new Date(event.target.value);
    this.minFinalSettlementDate = selectedDate;
    this.maxFinalSettlementDate = this.calculateMaxFinalSettlementDate(selectedDate);
  }


  initEmployeeResignation() {

    this.employeeResignationForm = this.fb.group({
      'managerRemarkOfEmployee': [''],
      'managerRemarkForReview': [''],
      'finalSettlementDate': ['', Validators.required],
      'lastWorkingDay': ['', Validators.required],
    })
  }
  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }
  getResignation(id: any) {
    this.resignationService.viewEmployeeResignationById(sessionStorage.getItem(AppConstant.COMPANY_ID), id).subscribe(
      (data: any) => {
        if (sessionStorage.getItem('role') === 'admin') {
          this.hrLogin = true;
          if (data.data.hrStatus == 'Approved') {
            this.isHrApproved = true;
            this.hrFNFShow = true;
          }
          this.isHr = true;
          if (data.data.hrStatus === 'Pending') {
            this.isHr = true;
            this.isManager = false;
          } else {
            this.isHr = false;
            this.isManager = false;
          }
        }
        this.resignationDetails = data.data;
        this.employeeName = data.data.firstName + ' ' + data.data.lastName;;
        this.resignationDetails.submissionDate = this.formatDate(this.resignationDetails.submissionDate);
        this.resignationDetails.lastWorkingDay = this.formatDate(this.resignationDetails.lastWorkingDay);
        this.resignationDetails.tentativeLWD = this.formatDate(this.resignationDetails.tentativeLWD);
        this.resignationDetails.DOJ = this.formatDate(this.resignationDetails.DOJ);

        const employeeName = this.getInitials(this.employeeName);
        const managerName = this.getInitials(this.resignationDetails.managerName);
        document.querySelectorAll('.circle.PL').forEach(circle => circle.textContent = employeeName);
        document.querySelectorAll('.circle.RD').forEach(circle => circle.textContent = managerName);
      }
    );
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  getInitials(name: string): string {
    if (!name) return '';
    const initials = name.match(/\b(\w)/g);
    return (initials || []).join('').toUpperCase();
  }

  approved() {
    let lastWorkingDay: any;
    if (typeof (this.employeeResignationForm.value.lastWorkingDay) == 'string') {
      lastWorkingDay = FormatUtil.adjustDateForTimeOffset(new Date(this.employeeResignationForm.value.lastWorkingDay));
    } else {
      lastWorkingDay = FormatUtil.adjustDateForTimeOffset(this.employeeResignationForm.value.lastWorkingDay);
    }

    let finalSettlementDate: any;
    if (typeof (this.employeeResignationForm.value.finalSettlementDate) == 'string') {
      finalSettlementDate = FormatUtil.adjustDateForTimeOffset(new Date(this.employeeResignationForm.value.finalSettlementDate));
    } else {
      finalSettlementDate = FormatUtil.adjustDateForTimeOffset(this.employeeResignationForm.value.finalSettlementDate);
    }

    this.formSubmitted = true;
    this.lastWorkingDate = lastWorkingDay;
    this.finalSettlemtDate = finalSettlementDate;

    if (!this.checkRequiredFields()) {
      return;
    }

    if (sessionStorage.getItem('role') === 'admin') {
      const approved: ResignationHrData = {
        "hrRemarkOfEmployee": this.employeeResignationForm.value.hrRemarkOfEmployee,
        "hrRemarkForReview": this.employeeResignationForm.value.hrRemarkForReview,
        "finalSettlementDate": this.finalSettlemtDate,
        "lastWorkingDay": this.lastWorkingDate,
        "hrStatus": "Approved",
        "companyId": sessionStorage.getItem(AppConstant.COMPANY_ID),
        "employeeId": this.id
      };

      if (this.isHr) {
        approved.finalSettlementDate = this.finalSettlemtDate;
        approved.lastWorkingDay = this.lastWorkingDate;
      }

      this.resignationService.isApprovedByHr(approved).subscribe((data: any) => {
        if (data.status == 200) {
          this.isHrApproved = true;
          this.getResignation(this.id);
          this.toaster.success(data.data);
        } else {
          this.toaster.error(data.data);
        }
      });
    }
  }


  reject() {
    this.formSubmitted = true;
    this.employeeResignationForm.get('lastWorkingDay')?.setErrors(null);
    this.employeeResignationForm.get('finalSettlementDate')?.setErrors(null);

    let lastWorkingDay: any;
    if (typeof (this.employeeResignationForm.value.lastWorkingDay) == 'string') {
      lastWorkingDay = FormatUtil.adjustDateForTimeOffset(new Date(this.employeeResignationForm.value.lastWorkingDay));
    } else {
      lastWorkingDay = FormatUtil.adjustDateForTimeOffset(this.employeeResignationForm.value.lastWorkingDay);
    }

    let finalSettlementDate: any;
    if (typeof (this.employeeResignationForm.value.finalSettlementDate) == 'string') {
      finalSettlementDate = FormatUtil.adjustDateForTimeOffset(new Date(this.employeeResignationForm.value.finalSettlementDate));
    } else {
      finalSettlementDate = FormatUtil.adjustDateForTimeOffset(this.employeeResignationForm.value.finalSettlementDate);
    }

    this.lastWorkingDate = lastWorkingDay;
    this.finalSettlemtDate = finalSettlementDate;

    if (sessionStorage.getItem('role') === 'admin') {
      const rejected: ResignationHrData = {
        "hrRemarkOfEmployee": this.employeeResignationForm.value.hrRemarkOfEmployee,
        "hrRemarkForReview": this.employeeResignationForm.value.hrRemarkForReview,
        "hrStatus": "Rejected",
        "companyId": sessionStorage.getItem(AppConstant.COMPANY_ID),
        "employeeId": this.id,
        "finalSettlementDate": this.finalSettlemtDate,
        "lastWorkingDay": this.finalSettlemtDate,
      };


      this.resignationService.isApprovedByHr(rejected).subscribe((data: any) => {
        if (data.status == 200) {
          this.getResignation(this.id);
          this.toaster.success(data.data);
        } else {
          this.toaster.error(data.data);
        }
      });
    }
  }

  checkRequiredFields(): boolean {
    this.employeeResignationForm.markAllAsTouched();

    if (!this.employeeResignationForm.get('lastWorkingDay')?.value) {
      this.employeeResignationForm.get('lastWorkingDay')?.setErrors({ required: true });
    }
    if (!this.employeeResignationForm.get('finalSettlementDate')?.value) {
      this.employeeResignationForm.get('finalSettlementDate')?.setErrors({ required: true });
    }

    return this.employeeResignationForm.valid;
  }


  validateCharacter(event: any) {
    return ValidationUtil.characterAndSpaceAllowed(event);
  }
}
