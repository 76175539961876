import { ChangeDetectorRef, Component, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { Messages } from 'src/app/constants/messages.constant';
import { MonthNamePipe } from 'src/app/pipes/month-name.pipe';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';

@Component({
  selector: 'app-salary-ag-grid',
  templateUrl: './salary-ag-grid.component.html',
  styleUrls: ['./salary-ag-grid.component.scss']
})
export class SalaryAgGridComponent {
  blob: Blob | undefined;

  constructor(private employeeService: EmployeeDetailsService, private renderer: Renderer2, private route: Router, private cdr: ChangeDetectorRef, private monthNamePipe: MonthNamePipe, private toastrService: ToastrService) { }
  employeeColDef: any;
  isActive: any;
  hrData: any;
  salaryDetails: any;
  selectedRows: any[] = [];
  employeeTableCol = [{ field: "year", floatingFilter: true, width: 160, unSortIcon: true, },
  {
    field: "month", floatingFilter: true, unSortIcon: true, width: 160
    , valueFormatter: (params: any) => {
      return this.monthNamePipe.transform(params.value);
    },
  },
  {
    field: "totalEarning", unSortIcon: true, width: 180, cellRenderer: (params: any) => {
      return this.formatNumber(params.value);
    },
  },
  {
    field: "totalDeduction", unSortIcon: true, width: 180, cellRenderer: (params: any) => {
      return this.formatNumber(params.value);
    },
  },
  {
    field: "netPay", unSortIcon: true, width: 180, cellRenderer: (params: any) => {
      return this.formatNumber(params.value);
    },
  },
  {
    field: "Action",
    cellRenderer: this.actionCellRenderer1.bind(this),
    width: 245
  }
  ];


  formatNumber(value: number | undefined): string {
    if (value !== undefined) {
      return value.toFixed(2);
    }
    return "";
  }

  statusCellRenderer(params: any): string {
    let color = "black";
    if (params.value === "Exited") {
      color = "red";
    }
    return `<span style="color:${color}">${params.value}</span>`;
  }


  getMonthName(monthNumber: number): string {
    const months = Messages.MONTHS;
    return months[monthNumber - 1] || '';
  }

  ngOnInit() {
    this.isActive;
    this.employeeColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.getEmployeeSalaryDetails()
  }


  getEmployeeSalaryDetails() {
    this.employeeService.getActiveEmployeeSalaryDetails(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe((data: any) => {
      this.salaryDetails = data;
      this.salaryDetails.forEach((item: any) => {
        item.monthName = this.getMonthName(item.month);
      });
    });
  }

  downloadEmployeeSalaryPdf(row: any) {
    const year = row.year;
    const month = row.month;
    this.employeeService.downloadSalarySlip(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID), year, month).subscribe((data: Blob) => {
      this.blob = new Blob([data], { type: 'application/pdf' });
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "salary_slip.pdf";
      link.click();
    });
  }

  viewEmployeeSalaryPdf(row: any) {
    const year = row.year;
    const month = row.month;
    this.employeeService.downloadSalarySlip(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID), year, month).subscribe((data: Blob) => {
      this.blob = new Blob([data], { type: 'application/pdf' });
      const pdfUrl = window.URL.createObjectURL(this.blob);
      window.open(pdfUrl, '_blank');
    });
  }

  actionCellRenderer1(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      if (icon === 'download') {
        this.renderer.setStyle(iconElement, 'margin-right', '15px');
      }
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };
    const viewIconClickHandler = () => {
      this.viewEmployeeSalaryPdf(params.data);
      const downloadIconClickHandler = () => {
        if (params.node.isSelected()) {
          this.selectedRows.push(params.data);
        }
      };
    };
    const downloadIconClickHandler = () => {
      this.downloadEmployeeSalaryPdf(params.data);
      const downloadIconClickHandler = () => {
        if (params.node.isSelected()) {
          this.selectedRows.push(params.data);
        }
      };
    };
    createButton('visibility', 'green', viewIconClickHandler);
    createButton('download', 'gray', downloadIconClickHandler);
    return cell;
  }

  downloadSelectedSalarySlip() {
    for (const row of this.selectedRows) {
      this.downloadEmployeeSalaryPdf(row);
    }
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  addEmployee() {
    this.route.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.ADD_EMPLOYEE])
  }
}
