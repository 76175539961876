import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { AccountType } from 'src/app/model/bankDetails.model';
import { AddEmployeeInformationData } from 'src/app/model/employeeDetails';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { FormatUtil } from 'src/app/utils/format.utils';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-view-employee-profile',
  templateUrl: './view-employee-profile.component.html',
  styleUrls: ['./view-employee-profile.component.scss']
})
export class ViewEmployeeProfileComponent {
  public viewEmployeeForm !: FormGroup;
  selectedCountry: string = "";
  selectedState: string = "";
  selectedCity: string = "";
  countryList: any;
  stateList: any;
  cityList: any;
  showBasicDetail: boolean = false;
  showPersonalDetail: boolean = false;
  id: number | undefined;
  employeeDetails: any;
  isEditable: boolean = false;
  employeeBasicDetail: any;
  employeeName: any;
  employeeId: any;
  companyId: any;
  doj: any;
  employeeDOB: any;
  dojMinLength: Date = undefined as any;
  futureSixMonths: Date = undefined as any;
  sixtyYearsOldDate: Date = undefined as any;
  ageOfMaturityDate: Date = undefined as any;
  accountType: AccountType[] = [
    { accountType: AppConstant.SALARY, selectedAccount: AppConstant.SALARY },
    { accountType: AppConstant.CURRENT, selectedAccount: AppConstant.CURRENT }
  ];
  readonlyData: any;

  constructor(private router: Router, private masterDataService: MasterDataService, private fb: FormBuilder, private employeeDetailsService: EmployeeDetailsService, private toastrService: ToastrService) {
    //employee DOJ cannot be older than company registration date - DOJ
    this.dojMinLength = FormatUtil.dojMinLenghtCompanyRegistrationdate();

    //finding future 6 months date. HR can only enroll employee before 6 months - DOJ
    this.futureSixMonths = FormatUtil.futureSixMonthsDate();

    //finding 60 years old date for DOB from currect date
    this.sixtyYearsOldDate = FormatUtil.sixtyYearsOldDate();

    //18 years old employee
    this.ageOfMaturityDate = FormatUtil.ageOfMaturityDate();
  }

  ngOnInit() {
    this.companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
    this.employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
    this.getEmployee();
    this.initAddEmployeeForm();
    this.getCountryList();
  }

  updateProfileButton(event: any) {
    if (event.target.form[0].innerText == '') {
      this.getEmployee();
    }
    this.toggleProfileButton();
  }

  toggleProfileButton() {
    this.isEditable = !this.isEditable;
  }

  updateProfile(): void {
    let doj: any;
    if (typeof (this.viewEmployeeForm.value.doj) == 'string') {
      doj = FormatUtil.adjustDateForTimeOffset(new Date(this.viewEmployeeForm.value.doj));
    } else {
      doj = FormatUtil.adjustDateForTimeOffset(this.viewEmployeeForm.value.doj);
    }

    let updateEmployee: AddEmployeeInformationData = {
      "firstName": this.viewEmployeeForm.value.firstName,
      "middleName": this.viewEmployeeForm.value.middleName,
      "lastName": this.viewEmployeeForm.value.lastName,
      "gender": this.viewEmployeeForm.value.gender,
      "dob": this.viewEmployeeForm.value.dob,
      "addressLine1": this.viewEmployeeForm.value.addressLine1,
      "addressLine2": this.viewEmployeeForm.value.addressLine2,
      "city": this.viewEmployeeForm.value.city,
      "state": this.viewEmployeeForm.value.state,
      "country": this.viewEmployeeForm.value.country,
      "pinCode": this.viewEmployeeForm.value.pinCode,
      "mobileNo": this.viewEmployeeForm.value.mobileNo,
      "personalEmail": this.viewEmployeeForm.value.personalEmail,
      "doj": doj,
      "designationId": this.viewEmployeeForm.value.designationId,
      "workEmail": this.viewEmployeeForm.value.workEmail,
      "departmentId": this.viewEmployeeForm.value.departmentId,
      "workLocation": this.viewEmployeeForm.value.workLocation,
      "annualCtc": this.viewEmployeeForm.value.annualCtc,
      "paymentMode": this.viewEmployeeForm.value.paymentMode,
      "panNo": this.viewEmployeeForm.value.panNo,
      "portalAccess": this.viewEmployeeForm.value.portalAccess,
      "createdBy": this.viewEmployeeForm.value.createdBy,
      "id": sessionStorage.getItem(AppConstant.COMPANY_ID)
    };

    this.employeeDetailsService.updateEmployee(updateEmployee).subscribe((data: any) => {
      if (data.status == 200) {
        this.toastrService.clear();
        this.toastrService.success(data.message);
        this.toggleProfileButton();
      }
      else {
        this.toastrService.clear();
        this.toastrService.error(data.message);
      }
    });
  }

  getCountryList() {
    this.masterDataService.getAllCountries().subscribe(
      (data: any) => {
        if (data.status === 200)
          this.countryList = data.data;
      }
    )
  }

  getState(country: any) {
    this.selectedCountry = country;
    this.masterDataService.getAllState(country).subscribe(
      (data: any) => {
        this.stateList = data.data;
      }
    )
  }

  getCity(state: any) {
    this.selectedState = state;
    this.masterDataService.getAllCity(state).subscribe(
      (data: any) => {
        this.cityList = data.data;
      }
    )
  }

  selectedCityName(city: any) {
    this.selectedCity = city;
  }

  onSubmit() {
    this.isEditable = false;
  }

  initAddEmployeeForm() {
    this.viewEmployeeForm = this.fb.group({
      'firstName': ['', ValidationUtil.onlySpacesOrNumbersValidator()],
      'middleName': ['', ValidationUtil.onlySpacesOrNumbersValidator()],
      'lastName': ['', Validators.required, ValidationUtil.onlySpacesOrNumbersValidator()],
      'gender': ['', Validators.required],
      'doj': ['', Validators.required],
      'designationName': ['', Validators.required],
      'workEmail': ['', Validators.required],
      'workLocation': ['', Validators.required],
      'departmentName': [''],
      'mobileNo': ['', Validators.minLength(10)],
      'email': ['', Validators.email],
      'dob': ['', Validators.required],
      'panNo': ['', Validators.required],
      'addressLine1': ['', Validators.required],
      'addressLine2': [''],
      'city': [''],
      'country': [''],
      'state': ['', Validators.required],
      'pinCode': ['', Validators.required],
      'uan': [''],
      'pfNumber': [''],
      'annualCtc': ['', Validators.required],
      'paymentMode': [''],
      'bankAccountNo': ['', Validators.required],
      'ifscCode': ['', Validators.required],
      'bankName': ['', Validators.required],
      'branch': ['', Validators.required],
      'accountType': ['', Validators.required],
    })
  }

  showBasicDetails() {
    this.showBasicDetail = !this.showBasicDetail;
  }

  showPersonalDetails() {
    this.showPersonalDetail = !this.showPersonalDetail;
  }

  getEmployee() {
    this.employeeDetailsService.getEmployeeById(this.companyId, this.employeeId).subscribe((data: any) => {
      this.employeeDetails = data;
      this.doj = this.employeeDetails.employeeBasicDetail.doj
      this.employeeDOB = this.employeeDetails.employeePersonalDetail.dob
      sessionStorage.setItem(AppConstant.DOB, this.employeeDOB)
      this.companyId = this.employeeDetails.employeeBasicDetail.companyId;
      sessionStorage.setItem(AppConstant.COMPANY_ID, this.companyId);
      this.employeeName = this.employeeDetails.employeeBasicDetail.firstName + " " + this.employeeDetails.employeeBasicDetail.lastName;
      sessionStorage.setItem('employeeName', this.employeeName);

      this.initReadonlyData(data);

      this.getState(this.employeeDetails.employeePersonalDetail.country);
      this.getCity(this.employeeDetails.employeePersonalDetail.state);
    });
  }

  initReadonlyData(data: any) {
    this.readonlyData = {
      firstName: data.employeeBasicDetail.firstName,
      middleName: data.employeeBasicDetail.middleName,
      lastName: data.employeeBasicDetail.lastName,
      gender: data.employeeBasicDetail.gender,
      doj: data.employeeBasicDetail.doj,
      designationName: data.employeeBasicDetail.designationName,
      workEmail: data.employeeBasicDetail.workEmail,
      workLocation: data.employeeBasicDetail.workLocation,
      departmentName: data.employeeBasicDetail.departmentName,

      mobileNo: data.employeePersonalDetail.mobileNo,
      email: data.employeePersonalDetail.email,
      dob: data.employeePersonalDetail.dob,
      panNo: data.employeePersonalDetail.panNo,
      addressLine1: data.employeePersonalDetail.addressLine1,
      addressLine2: data.employeePersonalDetail.addressLine2,
      city: data.employeePersonalDetail.city,
      country: data.employeePersonalDetail.country,
      state: data.employeePersonalDetail.state,
      pinCode: data.employeePersonalDetail.pinCode,
      uan: data.employeePersonalDetail.uan,
      pfNumber: data.employeePersonalDetail.pfNumber,

      annualCtc: data.employeeSalaryDetail.annualCtc,
      paymentMode: data.employeeSalaryDetail.paymentMode,

      bankAccountNo: data.employeeBankDetail.bankAccountNo,
      ifscCode: data.employeeBankDetail.ifscCode,
      bankName: data.employeeBankDetail.bankName,
      branch: data.employeeBankDetail.branch,
      accountType: data.employeeBankDetail.accountType,
    };
  }

  alphanumericAllowed(event: any) {
    ValidationUtil.onlyAlphanumeric(event);
  }

  onlyAlphanumericAndBackSlash(event: any) {
    ValidationUtil.onlyAlphanumericAndBackSlash(event);
  }

  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

  addressVerification(event: any) {
    return ValidationUtil.addressVerification(event);
  }

  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumber(event);
  }

  anuualCTCValidation(event: any) {
    return ValidationUtil.anuualCTCValidation(event);
  }

  characterAndSpaceAllowed(event: any) {
    return ValidationUtil.characterAndSpaceAllowed(event);
  }
}
