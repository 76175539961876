<div class="container">
    <form name="bankDetailsForm" [formGroup]="createBankForm">
        <div>
            <div class="form-field-gap1">

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'account_holder_name' | translate}}</mat-label>
                    <input matInput class="input" formControlName="accountHolderName" required
                        (keypress)="validateCharacterNumberAndSpace($event)" [maxLength]="30" />
                    <mat-error *ngIf="createBankForm.get('accountHolderName')?.hasError('required')">
                        {{ 'account_holder_name_is_required' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{ 'ifsc_code' | translate }}</mat-label>
                    <input matInput type="text" class="ifscVerify" formControlName="ifscCode" [(ngModel)]="ifscCode"
                        (keypress)="alphanumericAllowed($event)" [maxlength]="11"
                        (ngModelChange)="resetIFSCFieldCheck()" appUppercase required />
                    <mat-error *ngIf="createBankForm.get('ifscCode')?.hasError('required')">
                        {{ 'ifsc_code_is_required' | translate }}
                    </mat-error>
                    <a *ngIf="!verifiedIFSC && !isLoading" (click)="verifyIFSC()" class="verify">Verify</a>
                    <mat-icon *ngIf="verifiedIFSC" class="success-icon" color="primary">done</mat-icon>
                    <p class="error-message" *ngIf="errorMessage">{{ "errorMessage" }}</p>
                    <app-spinner *ngIf="isLoading" class="spinner"></app-spinner>
                    <mat-error
                        *ngIf="createBankForm.get('ifscCode')?.hasError('invalidIFSC')">{{'please-provide-valid-ifsc'
                        | translate}}</mat-error>
                </mat-form-field>
            </div>
            <div class="form-field-gap">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'bank_name' | translate}}</mat-label>
                    <input matInput class="input" formControlName="bankName" required
                        (keypress)="validateCharacterNumberAndSpace($event)" [maxLength]="30" />
                    <mat-error *ngIf="createBankForm.get('bankName')?.hasError('required')">
                        {{ 'bank_name_is_required' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'branch_name' | translate}}</mat-label>
                    <input matInput type="text" class="input" formControlName="branch" required [maxLength]="30"
                        (keypress)="validateCharacterNumberAndSpace($event)" />
                    <mat-error *ngIf="createBankForm.controls['branch'].hasError('required')">
                        {{ 'branch_name_is_required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="createBankForm.controls['branch'].hasError('invalidInput')">
                        {{ 'invalid_name_input' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'iban_code'| translate}}</mat-label>
                    <input matInput type="text" class="input" formControlName="ibanCode" />
                </mat-form-field>
            </div>
            <div class="form-field-gap">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'account_number' | translate}}</mat-label>
                    <input class="input" id="password-field1" type="password" (keypress)="validateNumber($event)"
                        minlength="10" [maxLength]="18" [type]="hidePassword ? 'password' : 'text'"
                        formControlName="bankAccountNo" appBlockCopyPaste matInput />
                    <mat-error *ngIf="!createBankForm.controls['bankAccountNo'].valid">
                        {{'account_number_required'| translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'confirm_account_number' | translate}}</mat-label>
                    <input class="input" id="password-field" (input)="validateAccountNumberMatch()" [maxLength]="18"
                    minlength="10" (keypress)="validateNumber($event)" formControlName="confirmBankAccountNo" matInput
                        appBlockCopyPaste required />
                    <mat-error *ngIf="createBankForm.hasError('mismatch', 'confirmBankAccountNo')">
                        {{'account_do_not_match'|translate}}
                    </mat-error>
                    <mat-error *ngIf="createBankForm.get('confirmBankAccountNo')?.hasError('required')">
                        {{'confirm_account_number_required'| translate}}</mat-error>
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label class="formLabel"> {{'account_type'| translate}}</mat-label>
                    <mat-select class="select-mat-input" formControlName="accountType" required>
                        <mat-option *ngFor="let accountType of accountType" [value]="accountType.accountType">
                            {{accountType.selectedAccount}}
                        </mat-option>
                    </mat-select>
                    <mat-error>{{ 'account_type_is_required' | translate }}</mat-error>
                </mat-form-field>

                <button class="visibility" mat-icon-button (click)="hidePassword = !hidePassword"
                    [attr.aria-label]="'Toggle Password Visibility'" [attr.aria-pressed]="!hidePassword">
                    <i class="material-icons">{{ hidePassword ? 'visibility_off' : 'visibility' }}</i>
                    <span class="password-text">{{(hidePassword ? 'show' : 'hide') | translate}}</span>
                </button>
            </div>
            <div class="form-field-gap">
            </div>
            <app-loader></app-loader>
            <button matStepperPrevious class="back-button">{{ 'back' | translate }}</button>
            <div class="buttonDiv">
                <button class="cancel-btn" (click)="backToHome()">{{'cancel'|translate}}</button>
                <button (click)="addBankDetails()" type="submit">{{'save_and_next'|translate}}</button>
            </div>
        </div>
    </form>
</div>