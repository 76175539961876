<div class="section-container">
    <div>
        <h1 class="section-title">{{'features' | translate}}</h1>
    </div>
    <div class="features">
        <div class="features-card">
            <div *ngFor="let feature of features" class="feature">
                <mat-icon class="icon" mat-card-avatar>{{feature.icon}}</mat-icon>
                <div class="features-title">{{feature.title | translate}}</div>
                <p class="features-content">{{feature.content | translate}}</p>
            </div>
        </div>
    </div>
</div>