import { Component } from '@angular/core';
import { AppConstant } from 'src/app/constants/app.constants';
import { TransactionHistoryModel } from 'src/app/model/attendance-model/subscription.model';
import { UserSubscriptionService } from 'src/app/services/userSubscription/user-subscription.service';
import { FormatUtil } from 'src/app/utils/format.utils';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})
export class TransactionHistoryComponent {

  transactionHistory!: TransactionHistoryModel[];

  columnDefs = [
    { headerName: 'ID', field: 'id', width: 120,floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true},
    { headerName: 'Subscription', field: 'subscription', width: 250,floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true},
    { headerName: 'Price', field: 'price', width: 250,floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true},
    { headerName: 'Buy Date', field: 'buyDate', width: 220, floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, cellRenderer: (params: any) => { return FormatUtil.formatDateForDisplay(params.value); },},
    { headerName: 'Expire Date', field: 'expireDate', width: 220,floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true,cellRenderer: (params: any) => { return FormatUtil.formatDateForDisplay(params.value); },},
    { headerName: 'Status', field: 'status', width: 250,floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true, cellRenderer: (params: any) => { return params.value.charAt(0).toUpperCase() + params.value.slice(1) },},
  ];

  constructor(private subscriptionService: UserSubscriptionService) { }

  ngOnInit() {
    this.geTransactionHistory();
  }

  onGridReady(params: any) {
    this.transactionHistory?.length ? params.api.hideOverlay() : params.api.showNoRowsOverlay();
    params.api.sizeColumnsToFit();
  }

  geTransactionHistory() {
    this.subscriptionService.geTransactionHistory(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((data: any) => {
        if (data.status == 200) {
          this.transactionHistory = data.data;
          this.transactionHistory.forEach((transaction: any) => ({
            id: transaction.id,
            subscription: transaction.userSubscriptionDetailsModel.subscriptionName,
            status: transaction.status,
            price: transaction.price,
            buyDate: transaction.buyDate,
            expireDate: transaction.expireDate,
          }))
        }
      }
    )
  }

}
