import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Messages } from 'src/app/constants/messages.constant';
import { ProjectManagementService } from 'src/app/services/project-management/project-management.service';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { DateAdapter } from '@angular/material/core';
import { Observable, of } from 'rxjs';
import { CreateSprint, UpdateSprint } from 'src/app/model/task-management/task-management/task-management.module';
import { AppConstant } from 'src/app/constants/app.constants';

@Component({
  selector: 'app-create-sprint',
  templateUrl: './create-sprint.component.html',
  styleUrls: ['./create-sprint.component.scss']
})
export class CreateSprintComponent implements OnInit {
  startDateMin!: Date;
  startDateMax!: Date;
  endDateMin!: Date;
  endDateMax!: Date;
  createSprintForm!: FormGroup;
  projectCodeOptions: any[] = [];
  selectedProjectId: any
  sprintId: number | undefined;
  sprintDuration!: number;
  dashboardId = sessionStorage.getItem(AppConstant.DASHBOARD_ID);
  durationList = Messages.DURATION_LIST;
  addUpdateSprintRequest: any;

  constructor(private toastrService: ToastrService, private projectService: ProjectManagementService, private formBuilder: FormBuilder, private dateAdapter: DateAdapter<Date>,
    public dialogRef: MatDialogRef<CreateSprintComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
    this.dateAdapter.setLocale('en');
    this.sprintId = data ? data.sprintId : null;
    this.createForm();
    const currentDate = new Date();
    const startDateMin = new Date(currentDate);
    startDateMin.setFullYear(startDateMin.getFullYear() - 1);
    this.startDateMin = startDateMin;
    this.startDateMax = new Date(currentDate.getFullYear() + 2, currentDate.getMonth(), currentDate.getDate());
    this.endDateMin = currentDate;
    this.endDateMax = new Date(currentDate.getFullYear() + 10, currentDate.getMonth(), currentDate.getDate());
  }

  ngOnInit(): void {
    if (this.sprintId) {
      this.viewSprint()
    }
  }


  createForm() {
    this.createSprintForm = this.formBuilder.group({
      sprintName: ['', [Validators.required]],
      sprintStartDate: ['', [Validators.required]],
      sprintEndDate: ['', Validators.required],
      sprintGoal: [''],
      sprintDuration: ['custom']
    });
  }

  viewSprint() {
    this.projectService.getSprintData(this.dashboardId, this.sprintId).subscribe((SprintData: any) => {
      this.createSprintForm.patchValue({
        sprintName: SprintData.data.sprintName,
        sprintStartDate: new Date(SprintData.data.sprintStartDate),
        sprintEndDate: new Date(SprintData.data.sprintEndDate),
        sprintGoal: SprintData.data.sprintGoal,
        sprintDuration: this.durationList.find(duration => Number(duration.days) === Number(SprintData.data.sprintDuration))?.id,
      });
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getSprintDurationInDays(id: string): number {
    const duration = this.durationList.find(d => d.id === id);
    return duration ? duration.days : 0;
  }

  createUpdateSprint() {
    this.prepareAddUpdateSprintRequest().subscribe(
      (sprintRequest: CreateSprint | UpdateSprint) => {
        if ('id' in sprintRequest) {
          (sprintRequest as any).sprintId = sprintRequest.id;
          this.projectService.updateSprint(sprintRequest as UpdateSprint).subscribe(
            (data: any) => this.handleSprintResponse(data)
          );
        } else {
          this.projectService.createSprint(sprintRequest as CreateSprint).subscribe(
            (data: any) => this.handleSprintResponse(data)
          );
        }
      }
    );
  }

  prepareAddUpdateSprintRequest(): Observable<CreateSprint | UpdateSprint> {
    this.sprintDuration = this.getSprintDurationInDays(this.createSprintForm.value.sprintDuration);
    const sprintRequest: CreateSprint | UpdateSprint = {
      dashboardId: this.dashboardId,
      sprintName: this.createSprintForm.value.sprintName,
      sprintStartDate: this.createSprintForm.value.sprintStartDate,
      sprintEndDate: this.createSprintForm.value.sprintStartDate,
      sprintGoal: this.createSprintForm.value.sprintGoal,
      sprintDuration: this.sprintDuration,
    };

    if (this.sprintId) {
      (sprintRequest as UpdateSprint).id = this.sprintId;
    }

    return of(sprintRequest);
  }

  handleSprintResponse(addUpdateSprintResponse: any) {
    if (addUpdateSprintResponse.status === 200) {
      this.projectService.refreshSprints();
      this.closeDialog();
      this.toastrService.clear();
      this.toastrService.success(addUpdateSprintResponse.message);
    } else {
      this.toastrService.clear();
      this.toastrService.error(addUpdateSprintResponse.message);
    }
  }

  characterNumberAndSpaceAndHyphenAllowed(event: any) {
    return ValidationUtil.characterNumberAndSpaceAndHyphenAllowed(event);
  }
}
