import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from "src/app/config/api-endpoint.config";
import { environment } from 'src/environments/enviroment';
import { AppConstant } from 'src/app/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class EventManagementService {

  constructor(private http: HttpClient) { }

  addEvent(dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_EVENT, dto).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  addEventFile(formData: FormData,): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_EVENT_FILE + sessionStorage.getItem(AppConstant.COMPANY_ID), formData, { reportProgress: true, observe: 'events' }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getAllEventList(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_EVENT_LIST + sessionStorage.getItem(AppConstant.COMPANY_ID)).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  updateEvent(updateEventRow: any): Observable<any> {
    return this.http.put(environment.apiUrl + API_END_POINTS.EDIT_EVENT, updateEventRow).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  eventDelete(deleteEventRow: any): Observable<any> {
    return this.http.delete(environment.apiUrl + API_END_POINTS.EVENT_DELETE + sessionStorage.getItem(AppConstant.COMPANY_ID) + API_END_POINTS.SEPERATOR +encodeURIComponent(deleteEventRow.eventId)).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getUploadBulkEvent(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.DOWNLOAD_SAMPLE_BULK_EVENT_FILE, { responseType: 'blob' }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getEventCategories(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_EVENT_CATEGORIES + sessionStorage.getItem(AppConstant.COMPANY_ID)).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

}