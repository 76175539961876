export class Messages {
  public static readonly id = '{id}';
  public static readonly PASSWORD_NOT_MATCHED = "Passwords did not match.";
  public static readonly MONTH = ['APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC', 'JAN', 'FEB', 'MAR'];
  public static readonly MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  public static readonly SALARY_COMPONENTS = ['Take Home', 'Deductions', 'Gross Pay'];
  public static readonly DEPARTMENT = "departmentName";
  public static readonly USERS = "totalEmployees";
  public static readonly DATE = "createdDate";
  public static readonly DESIGNATIONID = "id";
  public static readonly DESIGNATION = "designation";
  public static readonly ID = "Id";
  public static readonly SUPPORTED_FILE_SIZE = "File size must be 2MB or smaller.";
  public static readonly PROJECT_DOCUMENT_FILE_SIZE = "File size must be !0MB or smaller.";
  public static readonly MAX_FILE_SIZE = 25 * 1024 * 1024;
  public static readonly SUPPORTED_FILE_EXTENSIONS = "Only JPG and PNG files are allowed for logo upload.";
  public static readonly SUPPORTED_FILE_NAME = "Filename cannot be more than 30 characters.";
  public static readonly PROJECT_FILE_EXTENSIONS_ERROR = "Invalid file extension.";
  public static readonly FILE_EXTENSION = ['.jpg', '.jpeg', '.png'];
  public static readonly PROJECT_DOCUMENT_FILE_EXTENSION = ['.ppt', '.pptx', '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.csv'];
  public static readonly BLOCKED_EMAIL_DOMAINS = ['gmail.com', 'yahoo.com'];
  public static readonly PAGE_NAME_LIST = ['Login', 'Registation', 'Verify OTP', 'Add Company Details', 'Buy Subscription', 'Home Page', 'Employee Details', 'Pay Run', 'Approval', 'View Subscription', 'Transaction History', 'View Company Profile', 'Add Department', 'Add Designation', 'Add Leave Structure', 'Add Employee', 'Attendance Configuration', 'Assign Manager', 'Salary Structure', 'Add Bank Details', 'Support'];
  public static readonly PRIORITY_LIST = ['High', 'Medium', 'Low'];
  public static readonly ROLE_NAME = "roleName";
  public static readonly ROLE_DESCRIPTION = "roleDescription";
  public static readonly REPORT_TO = "roleReportTo";
  public static readonly EMPLOYEE_FAMILY_RELATION = ['spouse', 'mother', 'father', 'brother', 'other'];
  public static readonly EMPLOYEE_TYPES = ['permanent', 'contractor', 'freelancer'];

  public static readonly DURATION_LIST = [
    { id: 'custom', projectCode: 'Custom', days: 0 },
    { id: '1week', projectCode: '1 week', days: 7 },
    { id: '2weeks', projectCode: '2 weeks', days: 14 },
    { id: '3weeks', projectCode: '3 weeks', days: 21 },
    { id: '4weeks', projectCode: '4 weeks', days: 28 }
  ];

  public static readonly TASK_STATUS = [
    { status: 'To Do', value: 'toDo' },
    { status: 'Inprogress', value: 'inProgress' },
    { status: 'Done', value: 'done' },
  ];
  public static readonly PROJECT_HOLIDAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
}