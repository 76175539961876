import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { ApplyLeave, LeaveData } from 'src/app/model/leave-application/leave-history.model';
import { LeaveService } from 'src/app/services/leave-application/employee-side/leave-history/leave.service';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { FormatUtil } from 'src/app/utils/format.utils';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-apply-leave',
  templateUrl: './apply-leave.component.html',
  styleUrls: ['./apply-leave.component.scss']
})

export class ApplyLeaveComponent implements OnInit {
  public applyLeaveForm!: FormGroup;
  hide: boolean = true;
  error: any;
  companyId: string | null = sessionStorage.getItem(AppConstant.COMPANY_ID);
  duration: any;
  employeeId: string | null = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
  public balanceLeave!: LeaveData[];
  leaveHistory: string | undefined;
  leaveType!: LeaveData[] | any;
  employeeName = sessionStorage.getItem('employeeName');
  totalLeaveCount: any;
  isOnProbation: boolean = true;
  dateOfJoining: any;
  leaveCountMap: { [key: string]: number } = {};
  isShow!: boolean;
  pastDaysAllow: any;
  defaultLeave = "Select Leave"
  minEndDate: Date | undefined;
  startDate: Date | undefined;

  constructor(private router: Router, private loader: LoaderService, private fb: FormBuilder, private leaveService: LeaveService, private toastrService: ToastrService) {
    this.applyLeaveForm = this.fb.group({
      'leaveType': [this.defaultLeave, Validators.required],
      'startDate': ['', Validators.required],
      'endDate': ['', Validators.required],
      'leaveReason': [''],
      'name': [''],
      'noOfDays': [''],
    }, { validator: this.checkDates });

    const dojString = sessionStorage.getItem('doj');
    if (dojString) {
      this.dateOfJoining = new Date(dojString);
      this.isOnProbation = this.checkProbationStatus();
    }
  }

  ngOnInit() {
    this.getLeaveData();
    this.leaveList();
    this.pastDaysLeaveApplyAllow();
    if (this.dateOfJoining) {
      this.isOnProbation = this.checkProbationStatus();
    }
  }

  private checkProbationStatus(): boolean {
    const currentDate = new Date();
    const diffInMonths = this.monthDiff(this.dateOfJoining, currentDate);
    return diffInMonths < 6;
  }

  private monthDiff(d1: Date, d2: Date): number {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }

  pastDaysFilter = (date: Date | null): boolean => {
    const today = new Date();
    const allowedDate = new Date(today);
    allowedDate.setDate(allowedDate.getDate() - this.pastDaysAllow);
    return date ? date >= allowedDate : false;
  }

  getLeaveData() {
    this.leaveService.getLeaveData(this.companyId, this.employeeId).subscribe((data: any) => {
      this.balanceLeave = data;
      this.calculateTotalLeaveCount();
      this.updateLeaveCountMap();
    })
  }

  calculateTotalLeaveCount() {
    this.totalLeaveCount = this.balanceLeave.reduce((total, leaveItem) => total + leaveItem.leaveCount, 0);
  }

  updateLeaveCountMap() {
    for (const leaveItem of this.balanceLeave) {
      this.leaveCountMap[leaveItem.leaveType] = leaveItem.leaveCount;
    }
  }

  leaveList() {
    this.leaveService.getAllLeave(this.companyId).subscribe((data: any) => {
      this.leaveType = data.filter((item: any) => item.leaveCount !== 0);
    });
  }

  pastDaysLeaveApplyAllow() {
    this.leaveService.getPastDaysAllowDays(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((data: any) => {
      this.pastDaysAllow = data.data.leaveApplyPastDates;
    });
  }

  applyLeave() {

    if (this.applyLeaveForm.invalid) {
      this.applyLeaveForm.markAllAsTouched();
      return;
    }
    if (this.applyLeaveForm.value.noOfDays <= 0) {
      this.toastrService.clear();
      this.toastrService.error('You cannot apply for leave on Saturdays and Sundays. Please select additional weekdays.');
      return;
    }
    this.loader.show();
    let leaveCount = 0;
    for (const leave of this.balanceLeave) {
      if (leave.leaveType === this.applyLeaveForm.value.leaveType) {
        leaveCount = leave.leaveCount;
      }
    }
    if (this.applyLeaveForm.value.noOfDays > leaveCount) {
      this.loader.hide();
      this.toastrService.clear();
      this.toastrService.error(AppConstant.LEAVE_COUNT_ERROR_MSG + this.applyLeaveForm.value.leaveType + AppConstant.LEAVE_COUNT_ID + leaveCount + AppConstant.LEAVE_TYPE_ERROR_MSG);
    }
    else {
      let startDate: any;
      if (typeof (this.applyLeaveForm.value.startDate) == 'string') {
        startDate = FormatUtil.adjustDateForTimeOffset(new Date(this.applyLeaveForm.value.startDate));
      } else {
        startDate = FormatUtil.adjustDateForTimeOffset(this.applyLeaveForm.value.startDate);
      }

      let endDate: any;
      if (typeof (this.applyLeaveForm.value.endDate) == 'string') {
        endDate = FormatUtil.adjustDateForTimeOffset(new Date(this.applyLeaveForm.value.endDate));
      } else {
        endDate = FormatUtil.adjustDateForTimeOffset(this.applyLeaveForm.value.endDate);
      }

      let applyLeave: ApplyLeave = {
        "leaveType": this.applyLeaveForm.value.leaveType,
        "startDate": startDate,
        "endDate": endDate,
        "leaveReason": this.applyLeaveForm.value.leaveReason,
        "name": this.employeeName,
        "noOfDays": this.applyLeaveForm.value.noOfDays,
        "empId": this.employeeId,
        "companyId": this.companyId,
      };
      this.leaveService.applyLeave(applyLeave).subscribe((data: any) => {
        if (data.status == 200) {
          this.toastrService.clear();
          this.toastrService.success(data.message);
          this.loader.hide();
          this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.LEAVE_HISTORY]);
        }
        else {
          this.toastrService.clear();
          this.toastrService.error(data.message);
        }
      })
    }

  }


  updateEndDateMinDate(): void {
    this.startDate = this.applyLeaveForm?.get('startDate')?.value;
    // Update the minimum selectable date for end date
    this.minEndDate = this.startDate;
  }

  // Filter function to disable dates before the selected start date
  endDateFilter = (date: Date): boolean => {
    return !this.startDate || date >= this.startDate;
  }




  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }


  checkDates(group: FormGroup) {
    const startDate = group?.get('startDate')?.value;
    const endDate = group?.get('endDate')?.value;

    if (startDate && endDate && new Date(endDate) < new Date(startDate)) {
      group?.get('endDate')?.setErrors({ dateError: true });
      return { dateError: true };
    } else {
      group?.get('endDate')?.setErrors(null);
      return null;
    }
  }
  //Future Use
  // calculateDifference() {
  //   const startDate = this.applyLeaveForm?.get('startDate')?.value;
  //   const endDate = this.applyLeaveForm?.get('endDate')?.value;

  //   if (startDate && endDate) {
  //     const diffTime = Math.abs(new Date(endDate).getTime() - new Date(startDate).getTime());
  //     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  //     this.applyLeaveForm.patchValue({
  //       'noOfDays': diffDays + 1
  //     });
  //   }
  // }

  calculateDifference() {
    const startDate = new Date(this.applyLeaveForm?.get('startDate')?.value);
    const endDate = new Date(this.applyLeaveForm?.get('endDate')?.value);

    if (startDate && endDate) {
      let count = 0;
      let currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        const dayOfWeek = currentDate.getDay();
        if (dayOfWeek !== 6 && dayOfWeek !== 0) {
          count++;
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }

      this.applyLeaveForm.patchValue({
        'noOfDays': count
      });
    }
  }

  goBack() {
    this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.LEAVE_HISTORY]);
  }

}
