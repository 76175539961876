<div class='md-padding' layout="row" flex>
    <div layout="row" layout-xs="column" flex>
        <div class="employeeTable">
            <div>
                <h3>{{'salary_details'|translate}}</h3>
            </div>
            <mat-divider></mat-divider>
            <ag-grid-angular (gridReady)="onGridReady($event)" #agGride style="height: 29rem; width:100%;"
                class="ag-theme-alpine" [rowData]="salaryDetails" [rowSelection]="'multiple'" [animateRows]="true"
                [columnDefs]="employeeTableCol" [defaultColDef]="employeeColDef" [enableSorting]="true"
                [enableFilter]="true" [pagination]="true" [paginationPageSize]="10">
            </ag-grid-angular>
        </div>
    </div>
</div>