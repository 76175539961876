import { Component, Inject, Optional } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-add-edit-leave-popup',
  templateUrl: './add-edit-leave-popup.component.html',
  styleUrls: ['./add-edit-leave-popup.component.scss']
})
export class AddEditLeavePopupComponent {
  action: string;
  leaveData: any;
  addUpdateLeave!: FormGroup;
  leaveCountError: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddEditLeavePopupComponent>, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.leaveData = { ...data };
    this.action = this.leaveData.action;
  }

  addUpdateEvent() {
    if (this.leaveData.leaveType && this.leaveData.leaveType.length >= 5
      && this.leaveData.leaveDescription && this.leaveData.leaveDescription.length >= 5
      && this.leaveData.leaveCount && this.leaveData.leaveCount >= 1 && this.leaveData.leaveCount <= 50) {
      this.dialogRef.close({ event: this.action, data: this.leaveData });
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  characterNumberAndSpaceAllowed(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }
}
