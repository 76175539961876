import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { API_END_POINTS } from "src/app/config/api-endpoint.config";
import { AppConstant } from "src/app/constants/app.constants";
import { EmailConfiguration } from "src/app/model/email-configuration-model/email-configuration-model";
import { environment } from "src/environments/enviroment";

@Injectable({
    providedIn: 'root'
})

export class EmailConfigurationService {

    constructor(private http: HttpClient) { }

    saveEmailConfiguration(emailConfigurationRequest: EmailConfiguration): Observable<EmailConfiguration> {
        return this.http.post(environment.apiUrl + AppConstant.SEPERATOR + API_END_POINTS.ADD_EMAIL_CONFIGURATION, emailConfigurationRequest).pipe(
            map((res: any) => {
                return res;
            }));
    }


    getEmailConfiguration() {
        return this.http.get(environment.apiUrl + API_END_POINTS.GET_EMAIL_CONFIGURATION).pipe(
            map((res: any) => res)
        );
    }

}
