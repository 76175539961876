<div class="backlog-container">
  <div class="header">
    <h3>{{ 'backlog' | translate }}</h3>
    <div class="dashboard-dropdown">
      <mat-form-field class="full-width" appearance="outline">
        <mat-label class="formLabel"> {{'dashboards'| translate}}</mat-label>
        <mat-select class="select-mat-input" [(ngModel)]="selectedBoardId">
          <mat-option *ngFor="let board of boardList" (click)="getDashboard(board)" [value]="board.dashboardId">
            {{board.boardName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="button">
      <button class="export-btn" mat-button [matMenuTriggerFor]="menu"><mat-icon>file_upload</mat-icon>{{'export' |
        translate}}</button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="downlodExcel()">{{'download_as_excel' | translate}}</button>
        <button mat-menu-item (click)="downlodCSV()">{{'download_as_csv' | translate}}</button>
      </mat-menu>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="content-container">
    <div class="main-content">
      <div *ngFor="let sprint of sprints" class="sprint-section">
        <div class="sprint-section-header" (click)="expansionToggle(sprint)">
          <div class="sprint-header">
            <div class="toggle-icon">
              <mat-icon *ngIf="!sprint.showSprint">keyboard_arrow_right</mat-icon>
              <mat-icon *ngIf="sprint.showSprint">expand_more</mat-icon>
            </div>
            <h4>{{ sprint.name }} ({{ sprint.issues.length }} {{ 'issues' | translate }})</h4>
            <button *ngIf="sprint.id !== 'Backlog'" (click)="openEditSprintDialog(sprint.id)" mat-button>
              <i class="fa fa-pencil"></i> {{ 'add_dates' | translate }}
            </button>
          </div>
          <div class="right-align">
            <div class="status">
              <span class="status-count">{{ getStatusCount(sprint, 'toDo') }}</span>
              <span class="status-count-inprogress">{{ getStatusCount(sprint, 'inProgress') }}</span>
              <span class="status-count-done">{{ getStatusCount(sprint, 'done') }}</span>
            </div>
            <div class="start-sprint-button">
              <button *ngIf="sprint.id !== 'Backlog'" class="button" [ngClass]="{'disabled': sprint.sprintStatus}"
                (click)="startSprint(sprint)">
                {{ 'start_sprint' | translate }}
              </button>
            </div>
            <div *ngIf="sprint.id !== 'Backlog'">
              <button [matMenuTriggerFor]="menu" class="sprint-menu" (click)="$event.stopPropagation()">
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="openEditSprintDialog(sprint.id)">{{ 'edit_sprint' | translate }}</button>
              <button mat-menu-item (click)="deleteSprint(sprint.id)">{{ 'delete_sprint' | translate }}</button>
            </mat-menu>
          </div>
        </div>
        <div *ngIf="sprint.showSprint">
          <div cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListData]="sprint.issues"
            [cdkDropListConnectedTo]="getConnectedDropListIds()" [id]="'sprint-' + sprint.id" class="issue-list">
            <div *ngFor="let issue of sprint.issues; let i = index" cdkDrag class="issue"
              (click)="selectIssue(sprint, i)">
              <mat-checkbox>{{ issue.title }}</mat-checkbox>
              <div class="issue-drop-down">
                <mat-form-field class="status-dropdown" (click)="$event.stopPropagation()">
                  <mat-select [(ngModel)]="issue.status" (selectionChange)="changeTaskStatus($event, issue)">
                    <mat-option *ngFor="let status of taskStatus" [value]="status.value"
                      [ngClass]="{'blue-option': status.status !== 'Done',}">
                      {{ status.status }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <div>
                  <button [matMenuTriggerFor]="menu" class="issue-menu" (click)="$event.stopPropagation()">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                </div>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="moveIssue(issue, 'top')">{{ 'top_of_backlog' | translate }}</button>
                  <button mat-menu-item (click)="moveIssue(issue, 'bottom')">{{ 'bottom_of_backlog' | translate
                    }}</button>
                  <button mat-menu-item (click)="moveIssue(issue, 'up')">{{ 'move_up' | translate }}</button>
                  <button mat-menu-item (click)="moveIssue(issue, 'down')">{{ 'move_down' | translate }}</button>
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="selectedSprint && selectedIssueIndex !== null" class="issue-detail">
      <div *ngIf="!isEditMode">
        <mat-icon (click)="closeDetail()" class="clear-popup">clear</mat-icon>

        <mat-chip class="issue-title">
          <h4 class="issueName">{{ selectedSprint.issues[selectedIssueIndex].title }}</h4>
        </mat-chip>

        <div class="issue-metadata">
          <mat-icon matTooltip="{{'edit'|translate}}" (click)="editTaskDetails()">edit</mat-icon>
          <div>
          </div>
          <div class="metadata-item">
            <strong>{{'project_name'|translate}} - </strong> <span class="show-task-details">{{
              taskDetails.projectName }}</span>
          </div>
          <div class="metadata-item">
            <strong>{{'summary'|translate}} - </strong> <span class="show-task-details">{{
              taskDetails.summary }}</span>
          </div>
          <div class="metadata-item">
            <strong>{{'description'|translate}} - </strong> <span class="show-task-details">{{
              taskDetails.description }}</span>
          </div>
          <div class="metadata-item">
            <strong>{{'status'|translate}} - </strong> <span class="show-task-details">{{
              getCamelCase(taskDetails.status) }}</span>
          </div>
          <div class="metadata-item">
            <strong>{{'assignee'|translate}} - </strong><span class="show-task-details"> {{
              taskDetails.assigneeName }}</span>
          </div>
          <div class="metadata-item">
            <strong>{{'reporter'|translate}} - </strong> <span class="show-task-details">{{
              taskDetails.reporterName}}</span> <strong class="story-point">{{'story_point'|translate}} - </strong><span
              class="show-task-details">{{
              taskDetails.taskPointEstimate }}</span>
          </div>
          <div class="metadata-item">
            <strong>{{'parent_name'|translate}} - </strong><span class="show-task-details">{{
              taskDetails.parentTaskName }}</span>
          </div>
          <div class="metadata-item">
            <strong>{{'sprint_name'|translate}} - </strong><span class="show-task-details">{{
              taskDetails.sprintName}}</span>
          </div>

          <div class="metadata-item" *ngIf="attachments.length > 0">
            <strong>{{'attachments' | translate}} - </strong>
            <ul>
              <li *ngFor="let attachment of attachments">
                <a [href]="attachment.fileContent" target="_blank">{{ attachment.fileName }}</a>
              </li>
            </ul>
          </div>

          <div class="metadata-item">
            <span class="created-time">{{'created_by'|translate}} - </span>
            <span class="created-time">{{taskDetails.createdBy}}</span> <span
              class="updated-time">{{'created_date'|translate}} -
            </span><span class="created-time">{{taskDetails.createdDate | date: 'yyyy-MM-dd'}}</span>
          </div>
        </div>

      </div>
      <div *ngIf="isEditMode">
        <app-view-task-details (taskUpdated)="handleTaskUpdated($event)"></app-view-task-details>
      </div>
      <div class="activity-content">
        <span class="activity">{{ 'activity' | translate }}</span>
        <mat-chip-set class="show">
          <span>
            <mat-chip cdkDrag class="comment" (click)="showCommets()">{{ 'comments' | translate
              }}</mat-chip>
          </span>
        </mat-chip-set>
      </div>

      <div class="comment-section" *ngIf="showComments">
        <div class="initials-circle">{{ initials }}</div>
        <form [formGroup]="commentsForm">
          <input type="text" class="add-comment" formControlName="comment"
            placeholder="{{ 'add_a_comment' | translate }}" />
        </form>
      </div>
      <div class="save-comment" *ngIf="showComments">
        <button class="button save" (click)="saveComment()">{{ 'save' | translate }}</button>
        <button class="cancel-btn cancel" (click)="hideComments()">{{ 'cancel' | translate }}</button>
        <div *ngFor="let comment of comments" class="comment-container">
          <span class="initials-circle">{{ comment.createdBy | slice:0:1 }}</span>
          <div class="comment-details">
            <div class="header">
              <span class="createdBy">{{ comment.createdBy }}</span>
              <span class="timestamp">{{'created'|translate}} {{ getRelativeTime(comment.createdDate) }}</span>
            </div>
            <span class="comments">{{ comment.comments }}</span>
            <a (click)="openDeleteDialog(comment)">{{'delete'|translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showDeleteDialog" class="dialog-overlay">
    <div class="dialog-content">
      <p>{{'delete_comment_confirmation'|translate}}</p>
      <button (click)="confirmDelete()" class="confirmation-button button">{{'ok'|translate}}</button>
      <button (click)="cancelDelete()" class="delete-comment ">{{'cancel'|translate}}</button>
    </div>
  </div>