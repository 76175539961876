<div class="tittle-div">
    <div class="icon" matTooltip="{{'back_to_dashboard' | translate}}" (click)="goToDashboard()">
        <mat-icon svgIcon="arrow-back-double"></mat-icon>
    </div>
    <h3>{{ 'it_declaration_approval' | translate }}</h3>
</div>
<div class="main-container">
    <ag-grid-angular (gridReady)="onGridReady($event)" #agGride style="height: 35rem; width:100%;"
        class="ag-theme-alpine" [rowData]="declarationList" [rowSelection]="'multiple'" [animateRows]="true"
        [columnDefs]="declarationListCol" [defaultColDef]="declarationListColDef" [enableSorting]="true"
        [enableFilter]="true" [pagination]="true" [paginationPageSize]="10">
    </ag-grid-angular>
</div>