<h2 mat-dialog-title class="headerName">{{(callingType=='add'? 'add_event' : 'update_event') | translate}}</h2>
<mat-dialog-content>
    <form [formGroup]="addEvents">
        <div #addTraining>
            <div class="eventsInput">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'event_name' | translate}}</mat-label>
                    <input class="input-field" matInput type="text" formControlName="eventName"
                        (keypress)="characterNumberAndSpaceAllowed($event)" />
                    <mat-error
                        *ngIf="addEvents.get('eventName')?.hasError('required')">{{'event_name_is_required'|translate}}</mat-error>
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'event_type' | translate}}</mat-label>
                    <input class="input-field" matInput type="text" formControlName="eventType"
                        (keypress)="characterNumberAndSpaceAllowed($event)" />
                    <mat-error *ngIf="addEvents.get('eventType')?.hasError('required')">
                        {{'event_type_is_required' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="eventsInput">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'event_start_date' | translate}}</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="eventStartDate"
                        (ngModelChange)="updateEndDateMin()" [min]="currentDate" [max]="endOfFinancialYear"
                        (keypress)="$event.preventDefault()">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="addEvents.get('eventStartDate')?.hasError('required')">{{'choose_event_start_date'
                        | translate}}</mat-error>
                </mat-form-field>

                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'event_end_date' | translate}}</mat-label>
                    <input matInput [matDatepicker]="endDatePicker" formControlName="eventEndDate"
                        [min]="eventEndDateMin" [max]="endOfFinancialYear" (keypress)="$event.preventDefault()">
                    <mat-datepicker-toggle matIconSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #endDatePicker></mat-datepicker>
                    <mat-error *ngIf="addEvents.get('eventEndDate')?.hasError('required')">{{'choose_event_end_date' |
                        translate}}</mat-error>
                </mat-form-field>
            </div>
            <div class="eventsInput">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>{{'event_description' | translate}}</mat-label>
                    <textarea matInput MinRows="1" MaxRows="5" formControlName="description"></textarea>
                </mat-form-field>
            </div>

            <div class="buttons">
                <button class="cancel-btn" type="reset" (click)="closeDialog()">{{'cancel' | translate}}</button>
                <button *ngIf="callingType=='add'" (click)="addEvent()">{{'save' | translate}}</button>
                <button *ngIf="callingType=='update'" (click)="updateEvent()">{{'update' | translate}}</button>
            </div>
        </div>
    </form>
    <div *ngIf="callingType=='add'" class="add_bulk_trainings">
        <hr class="line_left" />
        <p>{{'or' | translate}}</p>
        <hr class="line_right" />
    </div>

    <div *ngIf="callingType=='add'" class="bulkUploadFile">
        <p>{{'please_download_the_sample_template' | translate}}
            <a (click)="downloadSampleEventExcel()" class="bulk_upload_link">{{'allEventUpload_file_name' |
                translate}}</a>
        </p>
        <p>{{'fill_the_information_carefully_and_upload_back' | translate}}
            <input type="file" id="addEventFile" [(ngModel)]="selectedFile" #fileInput style="display:none"
                (change)="onEventFileUpload($event)" accept=".xlsx" />

            <button mat-raised-button class="file-select-button mdc-floating-label--required"
                (click)="fileInput.click()">
                <span *ngIf="addEvents.get('addEventFile')?.hasError('null')">{{fileInput.files![0].name}}</span>
                <span *ngIf="!addEvents.get('addEventFile')?.hasError('null')">{{'upload_event_file' |
                    translate}}</span>
            </button>

            <mat-error
                *ngIf="addEvents.get('addEventFile')?.touched && addEvents.get('addEventFile')?.hasError('required')">
                {{'xlsx file is required'}}
            </mat-error>

            <mat-error *ngIf="addEvents.get('addEventFile')?.hasError('fileTypeInvalid')">
                {{'unsupported file type'}}
            </mat-error>

            <mat-error *ngIf="addEvents.get('addEventFile')?.hasError('fileInvalid')">
                {{'unsupported file size'}}
            </mat-error>
        </p>
    </div>
</mat-dialog-content>
<app-loader></app-loader>