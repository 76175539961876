<div class="setting__main_container">
    <span matTooltip="{{'back_to_dashboard' | translate}}" (click)="goToDashboard()">
        <mat-icon svgIcon="arrow-back-double" class="rewind"></mat-icon>
    </span>
    <div class="setting__heading_container">
        <h3>{{'view_all_sprint'|translate}}</h3>
        <div class="dashboard-dropdown">
            <mat-form-field class="full-width" appearance="outline">
                <mat-label class="formLabel"> {{'dashboards'| translate}}</mat-label>
                <mat-select class="select-mat-input" [(ngModel)]="dashboardId">
                    <mat-option *ngFor="let board of boardList" (click)="getDashboard(board)"
                        [value]="board.dashboardId">
                        {{board.boardName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="setting__header_button_container">
            <button color="primary" (click)="createSprint()">{{'create_sprint' | translate}}</button>
        </div>
    </div>
    <mat-divider></mat-divider>

    <ag-grid-angular (gridReady)="onGridReady($event)" #agGride style="height: 29rem; width: 100%;"
        class="ag-theme-alpine" [rowData]="sprintList" [rowSelection]="'multiple'" [animateRows]="true"
        [columnDefs]="sprintTableCol" [defaultColDef]="sprintColDef" [enableSorting]="true" [enableFilter]="true"
        [pagination]="true" [paginationPageSize]="10">
    </ag-grid-angular>
</div>