import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { CompanyProfileService } from 'src/app/services/company-profile/company-profile.service';

@Component({
  selector: 'app-manager-sidenav',
  templateUrl: './manager-sidenav.component.html',
  styleUrls: ['./manager-sidenav.component.scss']
})
export class ManagerSidenavComponent implements OnInit {
  isManager!: boolean
  companyLogo: any;
  companyName: any;
  is_Manager: boolean = false;
  hrRole: boolean = false;

  constructor(private companyProfileService: CompanyProfileService, private router: Router, private toastrService: ToastrService) { }

  ngOnInit() {
    const roles = sessionStorage.getItem(AppConstant.ASSIGN_ROLES)
    this.isManager = roles?.includes('Manager') ? true : false;
    this.hrRole = roles?.includes('HR') ? true : false;
    this.getCompanyLogo();
  }
  
  getCompanyLogo() {
    this.companyProfileService.getCompanyData(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        this.companyLogo = 'data:image/png;base64,' + data.data.logo;
        this.companyName = data.data.companyName
      }
    )
  }

  approveAttendanceButton() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.attendance) {
      sessionStorage.setItem('viewAttendanceButton', 'true');
      this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_EMPLOYEE_ATTENDANCE]);
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }

  leave() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.leaveManagement) {
      this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_LEAVE_DETAILS]);
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }

  projectApproval() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.projectManagement) {
      this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.PROJECT_APPROVAL]);
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }

  employeeLogout() {
    sessionStorage.clear();
  }

  appraisalProcess() {
    this.is_Manager = true;
    if (this.is_Manager == true) {
      sessionStorage.setItem(AppConstant.IS_MANAGER, 'true');
    }

  }
  training() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.trainingManagement) {
      this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.ASSIGN_TRAINING_TO_THE_EMPLOYEE]);
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }
  appraisal() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.performanceManagement) {
      this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_LIST_UNDER_MANAGER]);
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }

  }
  leaveDetails() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.leaveManagement) {
      this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_LEAVE_DETAILS]);
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }
  attendanceDetails() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.attendance) {
      this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_EMPLOYEE_ATTENDANCE]);
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }
}
