<footer class="footer">
    <div class="container">
        <div class="contact_info">
            <div><mat-icon>location_on</mat-icon>{{iwingsHRMS_location}}</div>
            <div class="email-phone">
                <div><mat-icon>email</mat-icon>{{iwingsHRMS_support_email}}</div>
                <div><mat-icon>phone</mat-icon>{{iwingsHRMS_support_phone}}</div>
            </div>
        </div>
    </div>
</footer>
<div class="copyright-container">
    <div class="copyright-container-links">
        <p><a href="/privacy-policy" id="privacyPolicyLink">{{'privacy_policy' | translate}}</a></p>
        <p><a href="/terms-condition" id="termsConditionLink">{{'terms_conditions' | translate}}</a></p>
        <p><a href="/cancellation-refund-policy" id="cancellationRefundPolicyLink">{{'cancellation_refund_policy' |
                translate}}</a>
        </p>
        <p><a href="/shipping-delivery-policy" id="shippingDeliveryLink">{{'shipping_delivery_policy' | translate}}</a>
        </p>
    </div>
    <p class="copyright">&copy; {{'footer' | translate}}</p>
</div>