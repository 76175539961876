<div class="setting__main_container">
  <span matTooltip="{{'back_to_dashboard' | translate}}" (click)="goToDashboard()">
    <mat-icon svgIcon="arrow-back-double" class="rewind"></mat-icon>
  </span>
  <div class="setting__heading_container">
    <h3>{{ 'training_details' | translate }}</h3>
  </div>
</div>
<mat-divider class="divider"></mat-divider>

<mat-tab-group class="group">
  <mat-tab label="Assign Training to Employee">
    <div class="assign-trainings">
      {{ 'assign_training_to_employee' | translate }}
    </div>
    <div class="main-container">
      <div class="table-container">
        <div class="Employee-list">
          <div class="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>
                    <input class="table-head-input" type="checkbox" [(ngModel)]="isAllEmployeesSelected"
                      (change)="toggleSelectAllEmployees()" [indeterminate]="isEmployeeCheckboxIndeterminate" />
                    {{ 'id' | translate }}
                  </th>
                  <th>{{ 'employee_name' | translate }}</th>
                  <th>{{ 'work_email' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let employee of employees">
                  <td>
                    <input class="table-head-input" type="checkbox" [(ngModel)]="employee.selected"
                      (change)="toggleEmployeeSelection()" />
                    {{ employee.id }}
                  </td>
                  <td>{{ employee.firstName + ' ' + employee.lastName }}</td>
                  <td>{{ employee.workEmail }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="course-category">
          <div class="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>
                    <input class="table-head-input" type="checkbox" [(ngModel)]="isAllTrainingsSelected"
                      (change)="toggleSelectAllTrainings()" [indeterminate]="isTrainingCheckboxIndeterminate" />
                    {{'id'|translate}}
                  </th>
                  <th>{{ 'training_list' | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let training of trainings">
                  <td>
                    <input class="table-head-input" type="checkbox" [(ngModel)]="training.selected"
                      (change)="toggleTrainingSelection()" />
                    {{ training.id }}
                  </td>
                  <td>{{ training.trainingName }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="button-container">
      <button class="cancel-btn" type="reset" (click)="goBack()">{{'cancel'|translate}}</button>
      <button class="button" (click)="onSubmit()">{{'submit'|translate}}</button>
    </div>
  </mat-tab>
  <mat-tab label="Training Summary" class="summary-tab">
    <div class="header">
      <div class="training-summary">{{'training_summary' | translate}}</div>
    </div>
    <div class='md-padding' layout="row" flex>
      <div layout="row" layout-xs="column" flex>
        <div class="training-table">
          <ag-grid-angular (gridReady)="onGridReady($event)" #agGride style="height: 29rem; width:100%;"
            class="ag-theme-alpine" [rowData]="employeeData" [rowSelection]="'multiple'" [animateRows]="true"
            [columnDefs]="hrTableCol" [defaultColDef]="empColDef" [enableSorting]="true" [enableFilter]="true"
            [pagination]="true" [paginationPageSize]="10">
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>