import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';

@Component({
  selector: 'app-choose-payment',
  templateUrl: './choose-payment.component.html',
  styleUrls: ['./choose-payment.component.scss']
})
export class ChoosePaymentComponent {

  selectedPaymentMethod: string | any;

  constructor(private router: Router) { }

  usePaymentMethod() {
    if (this.selectedPaymentMethod.toLowerCase().trim() === 'upi') {
      sessionStorage.setItem('upi', 'upi');
      sessionStorage.removeItem('email');
    } else if (this.selectedPaymentMethod.toLowerCase().trim() === 'email') {
      sessionStorage.removeItem('upi');
      sessionStorage.setItem('email', 'email')
    }
    this.router.navigate([AppConstant.PAYMENT_DISCRIPTION]);
  }
}
