import { HttpClient, HttpEventType} from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { AddBulkEmployeeComponent } from '../add-bulk-employee/add-bulk-employee.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConstant } from 'src/app/constants/app.constants';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { FormatUtil } from 'src/app/utils/format.utils';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.scss']
})

export class AddEmployeeComponent implements OnInit {
  @ViewChild('fileInput') fileInput: any;
  showBulkUpload: boolean = false;
  isLinear: boolean = true;
  createBasicForm!: FormGroup;
  createSalaryDetailsForm!: FormGroup;
  createPersonalForm!: FormGroup;
  createPaymentInformationForm!: FormGroup;
  createBankForm!: FormGroup;
  roleInfo!: FormGroup;
  fileError: boolean = false;
  fileSizeUnit = AppConstant.FILE_SIZE;
  public isApiSetup = false;
  showSpinner: boolean = false;
  constructor(private toastrService: ToastrService, private snackBar: MatSnackBar, private employeeService: EmployeeDetailsService, private formBuilder: FormBuilder, private router: Router, private http: HttpClient, private translateService: TranslateService) { }
  uploadedMedia: Array<any> = [];

  ngOnInit(): void {
    this.createPaymentInformationForm = this.formBuilder.group({
      documentsUploadForm: String,
      'paymentMode': ['', Validators.required]
    });
  }

  toggleChange() {
    if (!this.showBulkUpload) {
      setTimeout(() => { 
        this.showBulkUpload = false;
      });
    }
  }
  
  downloadExcel(): void {
    this.employeeService.getUploadBulkEmployee().subscribe((data: Blob) => {
      const downloadLink = document.createElement('a');
      const url = window.URL.createObjectURL(data);
      downloadLink.href = url;
      downloadLink.download = this.translateService.instant('bulk_employee_upload');
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(url);
    });
  }

  browseFile() {
    this.fileInput.nativeElement.value = null;
    this.fileInput.nativeElement.click();
    this.fileError = false;
  }

  onFileBrowse(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files;

    if (files && files.length > 0) {
      const file = files[0];
      const fileName = file.name.toLowerCase();
      const extension = fileName.split('.').pop();

      if (extension !== 'xlsx' || fileName !== AppConstant.FILE_NAME.toLocaleLowerCase()) {
        this.fileError = true;
        this.fileInput.nativeElement.value = '';
        return;
      }

      const formData = new FormData();
      formData.append(AppConstant.COMPANY_ID, sessionStorage.getItem(AppConstant.COMPANY_ID) || '');
      formData.append(AppConstant.EXCEL, file);

      this.employeeService.uploadBulkEmployeeData(formData).subscribe(event => {
        if (event['loaded'] && event['total']) {
          const percentDone = Math.round(event['loaded'] / event['total'] * 100);
        }

        if (event.type === HttpEventType.UploadProgress) {

          const percentDone = Math.round(100 * event.loaded / event.total);
          this.showUploadSnackBar(percentDone);
          this.processFiles(target.files);
        }

        if (event.body) {
          this.closeUploadSnackBar();
        }

        if (event.body.data !== null) {
          const excelData = FormatUtil.base64ToArrayBuffer(event.body.data);
          const workbook = XLSX.read(excelData, { type: 'array' });
          const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
          const excelBlob = new Blob([excelBuffer], { type: 'application/octet-stream' });
          saveAs(excelBlob, AppConstant.ERROR_EXCEL);
        }

        this.toastrService.success(event.body.message);

      });
    }
  }

  processFiles(files: any) {
    for (const file of files) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event: any) => {
        const uploadedFile = {
          FileName: file.name,
          FileSize: `${this.getFileSize(file.size)} ${this.getFileSizeUnit(file.size)}`,
          FileType: file.type,
          FileUrl: event.target.result,
          FileProgessSize: '0 bytes of',
          FileProgress: 0,
          ngUnsubscribe: new Subject<void>(),
          TotalBytesUploaded: 0,
          TotalBytes: file.size
        };
        this.uploadedMedia.push(uploadedFile);
        this.startProgress(uploadedFile, this.uploadedMedia.length - 1);
        this.showUploadSnackBar(uploadedFile);
      };
    }
  }
  async startProgress(uploadedFile: any, index: number) {
    let fileSize = uploadedFile.TotalBytes;
    let fileSizeInWords = uploadedFile.FileSize.split(" ")[1];
    let progress = 0;
    const interval = setInterval(() => {
      progress += Math.random() * 10;
      if (progress > 100) {
        clearInterval(interval);
        uploadedFile.FileProgress = 100;
        uploadedFile.FileProgessSize = `${this.getFileSize(uploadedFile.TotalBytes)} ${fileSizeInWords}`;

      } else {
        uploadedFile.TotalBytesUploaded = (fileSize * (progress / 100)).toFixed(2);
        uploadedFile.FileProgessSize = `${this.getFileSize(uploadedFile.TotalBytesUploaded)} bytes of ${this.getFileSize(uploadedFile.TotalBytes)} ${fileSizeInWords}`;
        uploadedFile.FileProgress = progress;
      }
    }, 500)
  }

  getFileSize(fileSize: number): number {
    if (fileSize > 0) {
      if (fileSize < this.fileSizeUnit * this.fileSizeUnit) {
        fileSize = parseFloat((fileSize / this.fileSizeUnit).toFixed(2));
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSize = parseFloat(
          (fileSize / this.fileSizeUnit / this.fileSizeUnit).toFixed(2)
        );
      }
    }

    return fileSize;
  }

  getFileSizeUnit(fileSize: number) {
    let fileSizeInWords = 'bytes';

    if (fileSize > 0) {
      if (fileSize < this.fileSizeUnit) {
        fileSizeInWords = 'bytes';
      } else if (fileSize < this.fileSizeUnit * this.fileSizeUnit) {
        fileSizeInWords = 'KB';
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSizeInWords = 'MB';
      }
    }

    return fileSizeInWords;
  }
  closeUploadSnackBar() {
    this.snackBar.dismiss();
  }

  showUploadSnackBar(uploadedFile: any) {
    const duration = uploadedFile.FileProgress === 100 ? 4000 : undefined;
    const snackBarRef = this.snackBar.openFromComponent(AddBulkEmployeeComponent, {
      data: uploadedFile,
      duration: duration,
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: 'snackbar-green',
    });

  }
}

