import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';

@Component({
  selector: 'app-godaddy-ssl',
  templateUrl: './godaddy-ssl.component.html',
  styleUrls: ['./godaddy-ssl.component.scss']
})
export class GodaddySslComponent  {
  fileContent!: string;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.fetchFileContent();
  }

  fetchFileContent() {
    const fileUrl = '../../../..//../assets/SSL/E6542D287AF53E7D93A6CA178E6D8E3A.txt';
    this.http.get(fileUrl, { responseType: 'text' })
      .subscribe((data) => {
        this.fileContent = data;
      });
  }

}
