
<div class="title">
  <h3 >  <mat-icon class="back" (click)="back()">keyboard_arrow_left</mat-icon> {{'annual_performance_document'|translate}}</h3>
</div>

<div class="expansion-panel">
    <form> <mat-accordion>
        </mat-accordion>
        <div class="expansion">
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h3 class="heading">{{'evoluation_topic'|translate}}</h3>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <a (click)="goToGoalPlan()" class="goal-plan">{{'performance_goal_plan'|translate}}</a>
                <div class="Calculated-Rating">
                    <h4>{{'employees_calculated_rating'|translate}}</h4>
                    <p class="Text">{{Rating}}</p>
                </div>
                <mat-divider></mat-divider>
                <div class="Behavioral">
                    <a  (click)="goToPerformanceGoalPlan()" class="goal-plan">{{'behavioral_competency'|translate}}</a>
                </div>

                <div class="Behavioral-competency">
                    <div>
                        <h4>{{'employee_self_rating'|translate}}</h4>
                        <p class="Text">{{EmpRating}}</p>
                    </div>
                    <div>
                        <h4>{{'employee_calculated_rating'|translate}}</h4>
                        <p class="Text">{{EmpRating}}</p>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </mat-expansion-panel>
        </mat-accordion>
   </div>
    </form>
</div>