<div class="attendance-configuration">
    <h3 class="add-section-category">{{ 'add_regime' | translate }}</h3>
    <form [formGroup]="section">
        <div class="category-details">
            <mat-form-field appearance="outline" class="category-code">
                <mat-label>{{ 'regime_type' | translate }}</mat-label>
                <mat-select formControlName="regimeType" name="regimeType" required>
                    <mat-option value="oldRegime">{{ "old_regime" | translate }}</mat-option>
                    <mat-option value="newRegime">{{ "new_regime" | translate }}</mat-option>
                </mat-select>
                <mat-error *ngIf="section.controls['regimeType'].hasError('required')">
                    {{ 'regime_type_is_required' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="category-name">
                <mat-label>{{ 'slab_name' | translate }}</mat-label>
                <input matInput formControlName="regimeName" name="regimeName" required
                    (keypress)="validateCharacterNumberAndSpace($event)" />
                <mat-error *ngIf="section.controls['regimeName'].hasError('required')">
                    {{ 'regime_name_is_required' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="category-details" *ngIf="showAgeFilter">
            <mat-form-field appearance="outline" class="category-code">
                <mat-label>{{ 'minimum_age' | translate }}</mat-label>
                <input matInput formControlName="regimeMinimumAge" name="regimeMinimumAge"
                    (keypress)="validateCharacterNumberAndSpace($event)" />
                <mat-error *ngIf="section.controls['regimeMinimumAge'].hasError('required')">
                    {{ 'minimum_age_is_required' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="category-name">
                <mat-label>{{ 'maximum_age' | translate }}</mat-label>
                <input matInput formControlName="regimeMaximumAge" name="regimeMaximumAge"
                    (keypress)="validateCharacterNumberAndSpace($event)" />
                <mat-error *ngIf="section.controls['regimeMaximumAge'].hasError('required')">
                    {{ 'maximum_age_is_required' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="category-details">
            <mat-form-field appearance="outline" class="category-name">
                <mat-label>{{ 'from_year' | translate }}</mat-label>
                <mat-select formControlName="fromYear" name="fromYear" required>
                    <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                </mat-select>
                <mat-error *ngIf="section.controls['fromYear'].hasError('required')">
                    {{ 'from_year_is_required' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="category-code">
                <mat-label>{{ 'to_year' | translate }}</mat-label>
                <mat-select formControlName="toYear" name="toYear" required>
                    <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                </mat-select>
                <mat-error *ngIf="section.controls['toYear'].hasError('required')">
                    {{ 'to_year_is_required' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="category-name">
                <mat-label>{{ 'percentage' | translate }}</mat-label>
                <input matInput formControlName="regimePercentage" name="regimePercentage" />
                <mat-error *ngIf="section.controls['regimePercentage'].hasError('required')">
                    {{ 'percentage_is_required' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="category-details">
            <mat-form-field appearance="outline" class="category-name">
                <mat-label>{{ 'minimum_amount' | translate }}</mat-label>
                <input matInput formControlName="regimeMinimumAmount" name="regimeMinimumAmount" required
                    (keypress)="validateCharacterNumberAndSpace($event)" />
                <mat-error *ngIf="section.controls['regimeMinimumAmount'].hasError('required')">
                    {{ 'minimum_amount_is_required' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="category-code">
                <mat-label>{{ 'maximum_amount' | translate }}</mat-label>
                <input matInput formControlName="regimeMaximumAmount" name="regimeMaximumAmount" required
                    (keypress)="validateCharacterNumberAndSpace($event)" />
                <mat-error *ngIf="section.controls['regimeMaximumAmount'].hasError('required')">
                    {{ 'maximum_amount_is_required' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
    </form>
    <div class="button-container">
        <button class="cancel-btn" (click)="onCancelClick()">{{ 'cancel' | translate }}</button>
        <button (click)="addSection()">{{ 'add_regime' | translate }}</button>
    </div>
</div>