import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { SuperAdminDeclationConfigurationServiceService } from 'src/app/services/super-admin-declaration-configuration-service/super-admin-declation-configuration-service.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-add-section-popup',
  templateUrl: './add-section-popup.component.html',
  styleUrls: ['./add-section-popup.component.scss']
})
export class AddSectionPopupComponent implements OnInit {

  section!: FormGroup;

  constructor(private toastrService: ToastrService, private superAdminDeclarationConfigurationService: SuperAdminDeclationConfigurationServiceService, private dialogRef: MatDialogRef<AddSectionPopupComponent>, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.section = this.formBuilder.group({
      'sectionName': ['', Validators.required],
      'sectionLimit': [''],
      'sectionDescription': ['', Validators.required],
    })
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  addSection() {
    if (this.section.valid) {
      const dto = {
        sectionName: this.section.get('sectionName')?.value,
        sectionLimit: this.section.get('sectionLimit')?.value,
        sectionDescription: this.section.get('sectionDescription')?.value
      }
      this.superAdminDeclarationConfigurationService.addSection(dto).subscribe(
        (data: any) => {
          if (data.status === 200) {
            this.toastrService.clear();
            this.toastrService.success(data.message);
            this.dialogRef.close();
          }
        })
    } else {
      this.section.markAllAsTouched();
    }
  }
  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

}
