import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { AppConstant } from 'src/app/constants/app.constants';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class TaxDeclarationServiceService {


  constructor(private http: HttpClient) { }

  getAllSectionList(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_SECTIONS_AND_CATEGORY).pipe(
      map((res: any) => {
        return res;
      }))
  }

  submitHouseDetails(dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.SUBMIT_HOUSE_RENT_DETAILS, dto).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  addDeclaration(dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.SUBMIT_DECLARATION + sessionStorage.getItem(AppConstant.COMPANY_ID) + API_END_POINTS.SEPERATOR + sessionStorage.getItem(AppConstant.EMPLOYEE_ID), dto).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getDeclaredInvestmentDetails(employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_DECLARED_INVESTMENT_DETAILS + sessionStorage.getItem(AppConstant.COMPANY_ID) + API_END_POINTS.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res;
      }))
  }

  getRegimeType(employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.REGIME_TYPE + sessionStorage.getItem(AppConstant.COMPANY_ID) + API_END_POINTS.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res;
      }))
  }

  getDeclarationService(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.REGIME_POPUP_SHOW + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).pipe(
      map((res: any) => {
        return res;
      }))
  }

  getAllApprovalList(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_DECLARATION_LIST + sessionStorage.getItem(AppConstant.COMPANY_ID)).pipe(
      map((res: any) => {
        return res;
      }))
  }

  getDeclarationById(employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_DECLARATION_BY_ID + sessionStorage.getItem(AppConstant.COMPANY_ID) + API_END_POINTS.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res;
      }))
  }

  approveDeclaration(employeeId: any, dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.APPROVE_DECLARATION + sessionStorage.getItem(AppConstant.COMPANY_ID) + API_END_POINTS.SEPERATOR + employeeId, dto).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  rejectDeclaration(dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.REJECT_DECLARATION, dto).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getHouseRentDetails(employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_HOUSE_RENT_DETAILS + sessionStorage.getItem(AppConstant.COMPANY_ID) + API_END_POINTS.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res;
      }))
  }

  submitDeclaration(selectedRegimeType: any) {
    return this.http.get(environment.apiUrl + API_END_POINTS.FINAL_SUBMIT_DECLARATION + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + sessionStorage.getItem(AppConstant.EMPLOYEE_ID) + AppConstant.SEPERATOR + selectedRegimeType).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  addSectionBySectionName(dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_SECTION_BY_SECTION_NAME + sessionStorage.getItem(AppConstant.COMPANY_ID) + API_END_POINTS.SEPERATOR + sessionStorage.getItem(AppConstant.EMPLOYEE_ID), dto).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  deleteDeclaration(): Observable<any> {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_DECLARATION + sessionStorage.getItem(AppConstant.COMPANY_ID) + API_END_POINTS.SEPERATOR + sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).pipe(
      map((res: any) => {
        return res;
      }))
  }

  submitPOI(formData: FormData): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.SUBMIT_POI + sessionStorage.getItem(AppConstant.COMPANY_ID) + API_END_POINTS.SEPERATOR + sessionStorage.getItem(AppConstant.EMPLOYEE_ID), formData).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

}
