import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { AppConstant } from 'src/app/constants/app.constants';
import { ApplyLeave, LeaveData, LeaveApprovalData } from 'src/app/model/leave-application/leave-history.model';
import { environment } from 'src/environments/enviroment';

@Injectable({
  providedIn: 'root'
})
export class LeaveService {

  constructor(private http: HttpClient) { }

  getLeaveData(companyId: any, employeeId: any): Observable<LeaveData[]> {
    return this.http.get(environment.apiUrl + API_END_POINTS.EMPLOYEE_LEAVE_DATA + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res.data;
      }),
    );
  }

  getApplyLeaveHistory(companyId: any, employeeId: any): Observable<LeaveData[]> {
    return this.http.get(environment.apiUrl + API_END_POINTS.EMPLOYEE_LEAVE_HISTORY + companyId + API_END_POINTS.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res.data;
      }),
    );
  }

  applyLeave(applyLeave: ApplyLeave): Observable<ApplyLeave> {
    return this.http.post(environment.apiUrl + API_END_POINTS.APPLYLEAVE, applyLeave).pipe(
      map((res: any) => {
        return res;
      }))
  }

  getAllLeaveHistory(companyId: any, employeeId: any): Observable<LeaveData[]> {
    return this.http.get(environment.apiUrl + API_END_POINTS.ALL_EMPLOYEE_LEAVE_DATA + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res.data;
      }),
    );
  }

  getAllEmployeeLeaveHistory(companyId: any): Observable<LeaveData[]> {
    return this.http.get(environment.apiUrl + API_END_POINTS.ALL_EMPLOYEE_LEAVE_DATA + companyId).pipe(
      map((res: any) => {
        return res.data;
      }),
    );
  }

  getEmployeeUnderManagerLeaveData(companyId: any, employeeId: any): Observable<LeaveData[]> {
    return this.http.get(environment.apiUrl + API_END_POINTS.MANAGER_EMPLOYEE_LEAVE_DATA + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res.data;
      }),
    );
  }

  getAllLeave(companyId: any): Observable<LeaveData[]> {
    return this.http.get(environment.apiUrl + API_END_POINTS.LEVAESTRUCTURELIST + companyId).pipe(
      map((res: any) => {
        return res.data;
      }),
    );
  }


  getPastDaysAllowDays(companyId: any) {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_SETTING_DATA + companyId).pipe(
        map(res => { return res })
    )
}

  getLeave(companyId: any, employeeId: any, leaveId: any): Observable<LeaveData> {
    return this.http.get(environment.apiUrl + API_END_POINTS.EMPLOYEE_LEAVE_DATE + companyId + AppConstant.SEPERATOR + employeeId + AppConstant.SEPERATOR + leaveId).pipe(
      map((res: any) => {
        return res.data;
      })
    )

  }

  updateLeaveStatus(leaveData: LeaveApprovalData): Observable<any> {
    return this.http.put(environment.apiUrl + API_END_POINTS.UPDATE_LEAVE, leaveData).pipe(
      map((res: any) => {
        return res;
      })
    )
  }
}
