import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { CreateLoginRequest } from 'src/app/model/login.model';
import { LoginService } from 'src/app/services/login/loginService';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { SubscriptionService } from 'src/app/services/admin-model-services/subscription/subscription.service';
import { CompanyProfileService } from 'src/app/services/company-profile/company-profile.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})

export class LoginPageComponent implements OnInit {
  submitted: boolean = false;
  error: string = "";
  public createLoginForm!: FormGroup;
  registrationLink = AppConstant.REGISTRATION;
  formSubmitted: boolean = false;
  hidePasswordError: boolean = true;
  passwordTyped: boolean = false;
  showLoginPopup: boolean = false;
  selectedRole: any;
  subscriptionId: any;
  companyId: any;
  employeeId: any;
  adminId: any;
  spinner: boolean = false;
  hidePasswordInitially: boolean = true;
  loginEmail: any;
  domain: any;
  emailDomain: any;
  emmployeePortalAccess!: boolean;
  constructor(public dialogRef: MatDialogRef<LoginPageComponent>, private companyProfileService: CompanyProfileService, private router: Router, private loader: LoaderService, private subscriptionService: SubscriptionService, private employeeService: EmployeeDetailsService, private fb: FormBuilder, private loginService: LoginService, private toastrService: ToastrService, private translate: TranslateService) { }

  ngOnInit() {
    this.initCreateLoginForm();
  }

  togglePasswordVisibility() {
    this.hidePasswordInitially = !this.hidePasswordInitially;
  }

  switchLanguage = (event: any) => {
    this.translate.use(event.target.value);
  }

  initCreateLoginForm() {
    this.createLoginForm = this.fb.group({
      'email': ['', [Validators.required, Validators.email, ValidationUtil.emailValidation()]],
      'password': ['', ValidationUtil.getPasswordPattern()]
    });
  }

  onLogin() {
    sessionStorage.clear();
    this.submitted = true;
    this.formSubmitted = true;
    const loginEmail = this.createLoginForm.value.email;
    sessionStorage.setItem('loginEmail', loginEmail);
    if (this.createLoginForm.invalid) {
      this.createLoginForm.markAllAsTouched();
      return;
    }
    let createLoginRequest: CreateLoginRequest = {
      "email": this.createLoginForm.value.email,
      "password": this.createLoginForm.value.password,
    };

    if (this.createLoginForm.value.email === 'admin@iwexe.com') {
      this.loginService.adminLogin(createLoginRequest).subscribe((data: any) => {
        const token: any = sessionStorage.getItem(AppConstant.TOKEN)
        const tokenData = JSON.parse(atob(token.split('.')[1]));

        if (data.status === 200) {
          this.dialogRef.close();
          this.router.navigate([AppConstant.ADMIN_SIDEBAR + AppConstant.SEPERATOR + AppConstant.SUBSCRIPTION_DASHBOARD]);
          return this.toastrService.success(data.message);
        }

        return this.toastrService.error(data.message);
      });
    } else {
      this.loginService.login(createLoginRequest).subscribe(
        (res: any) => {
          if (res.status === 200) {
            const token: any = sessionStorage.getItem(AppConstant.TOKEN)
            const tokenData = JSON.parse(atob(token.split('.')[1]));
            console.log(tokenData.roles.includes(AppConstant.ADMIN))
            const rolesFromToken = tokenData.roles;
            const requiredRoles = AppConstant.ADMIN_AND_EMPLOYEE.split(',');
            const hasRequiredRoles = requiredRoles.every(role => rolesFromToken.includes(role));
            if (hasRequiredRoles) {
              this.companyId = res.data.companyId;
              this.employeeId = res.data.employeeId;
              this.adminId = res.data.adminId;
              this.subscriptionId = res.data.subscriptionId;
              this.showLoginPopup = true;
              this.emmployeePortalAccess = res.data.employeePortalAccess;
              this.companyDetails(res.data.companyId);
            } else {
              if (tokenData.roles.includes(AppConstant.ADMIN)) {
                sessionStorage.setItem(AppConstant.COMPANY_ID, res.data.companyId);
                sessionStorage.setItem(AppConstant.ADMIN_ID, res.data.adminId);
                sessionStorage.setItem(AppConstant.SUBSCRIPTION_ID, res.data.subscriptionId);
                sessionStorage.setItem(AppConstant.EMPLOYEE_ID, res.data.employeeId);
                sessionStorage.setItem(AppConstant.ROLE_FOR_SESSION, AppConstant.ADMIN);
                this.toastrService.clear();
                this.toastrService.success(res.message);
                this.getSubscriptionAccess();
                this.companyDetails(res.data.companyId);
                this.dialogRef.close();
                this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
              } else {
                sessionStorage.setItem(AppConstant.COMPANY_ID, res.data.companyId);
                sessionStorage.setItem(AppConstant.EMPLOYEE_ID, res.data.employeeId);
                sessionStorage.setItem(AppConstant.ROLE_FOR_SESSION, AppConstant.EMPLOYEE);
                this.employeeService.getEmployeeById(res.data.companyId, res.data.employeeId).subscribe(
                  (data: any) => {
                    sessionStorage.setItem(AppConstant.MANAGER_ID, data.employeeBasicDetail.managerId);
                    if (data.employeeBasicDetail.manager) {
                      sessionStorage.setItem(AppConstant.MANAGER_SIDENAV, AppConstant.EMPLOYEE_ROLE);
                    }
                    sessionStorage.setItem(AppConstant.ASSIGN_ROLES, data.employeeBasicDetail.roles);
                    this.getSubscriptionAccess();
                    this.dialogRef.close();
                    this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
                  });
              }
            }
          } else {
            this.dialogRef.close();
            this.toastrService.clear();
            this.toastrService.error(res.message);
            this.router.navigate([AppConstant.LANDING_PAGE]);
          }
        });
    }
  }

  goToForgetPassword() {
    this.dialogRef.close();
  }

  onPasswordInputClick() {
    this.hidePasswordError = false;
  }

  toggleLoginPopup() {
    this.showLoginPopup = !this.showLoginPopup;
  }

  companyDetails(companyId: string): any {
    this.companyProfileService.getCompanyData(companyId).subscribe(
      (data: any) => {
        sessionStorage.setItem(AppConstant.COMPANY_REGISTRATION_DATE, data.data.companyRegistrationDate)
        sessionStorage.setItem(AppConstant.LOGIN_COMPANY_NAME, data.data.companyName)
      },
      (error: any) => {
      }
    );
  }

  adminLogin() {
    this.loader.show();
    this.toggleLoginPopup();
    sessionStorage.setItem(AppConstant.SUBSCRIPTION_ID, this.subscriptionId);
    sessionStorage.setItem(AppConstant.COMPANY_ID, this.companyId);
    sessionStorage.setItem(AppConstant.EMPLOYEE_ID, this.employeeId);
    sessionStorage.setItem(AppConstant.ADMIN_ID, this.adminId)
    sessionStorage.setItem(AppConstant.ROLE_FOR_SESSION, AppConstant.ADMIN);
    this.getSubscriptionAccess();
    this.loader.hide();
    this.dialogRef.close();
    this.router.navigate([AppConstant.ADMIN + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
  }

  employeeLogin() {
    if (this.emmployeePortalAccess) {
      this.loader.show();
      this.toggleLoginPopup();
      sessionStorage.setItem(AppConstant.COMPANY_ID, this.companyId);
      sessionStorage.setItem(AppConstant.EMPLOYEE_ID, this.employeeId);
      sessionStorage.setItem(AppConstant.ROLE_FOR_SESSION, AppConstant.EMPLOYEE_ROLE);
      this.employeeService.getEmployeeById(this.companyId, this.employeeId).subscribe(
        (data: any) => {
          if (data.employeeBasicDetail.manager) {
            sessionStorage.setItem(AppConstant.MANAGER_SIDENAV, AppConstant.EMPLOYEE_ROLE);
          }
          sessionStorage.setItem(AppConstant.ASSIGN_ROLES, data.employeeBasicDetail.roles);
          this.toastrService.clear();
          this.getSubscriptionAccess();
          this.toastrService.success(AppConstant.EMPLOYEE_LOGIN_SUCCESSFULLY);
          this.dialogRef.close();
          this.router.navigate([AppConstant.EMPLOYEE + AppConstant.SEPERATOR + AppConstant.ACESS_MODULES_BASED_ON_ROLE]);
        },)
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.INVALID_ACCESSS);
    }
  }

  getSubscriptionAccess() {
    this.subscriptionService.getSubscriptionAccess().subscribe(
      (data: any) => {
        sessionStorage.setItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS, JSON.stringify(data.data));
      }
    )
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  registration() {
    this.dialogRef.close();
    this.router.navigate([AppConstant.REGISTRATION]);
  }
}