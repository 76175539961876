<div class="main-header">
    <div class="header">
        <span matTooltip="Back to Help Desk" (click)="goToBack()">
            <mat-icon class="rewind" svgIcon="arrow-back-double"></mat-icon>
        </span>
        <h3 class="tittle">{{ 'resolution' | translate }}</h3>
    </div>
    <div class="approval-selection">
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="type"
            (click)="getAllTickets(type)">
            <mat-button-toggle value="all">{{'all' | translate}}</mat-button-toggle>
            <mat-button-toggle value="none">{{'assign_to_me' | translate}}</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>
<div class="setting__main_container">
    <ag-grid-angular (gridReady)="onGridReady($event)" style="height: 30rem; width: 100%;" class="ag-theme-alpine"
        [rowData]="viewAllTickets" [rowSelection]="'multiple'" [animateRows]="true"
        [columnDefs]="viewAllTicketsTableDef" [defaultColDef]="viewAllTicketsColDef" [enableSorting]="true"
        [enableFilter]="true" [pagination]="true" [paginationPageSize]="10">
    </ag-grid-angular>
</div>