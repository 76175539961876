import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, startWith } from 'rxjs';
import { AppConstant } from 'src/app/constants/app.constants';
import { ProjectManagementService } from 'src/app/services/project-management/project-management.service';
import { ValidationUtil } from 'src/app/utils/validators.util';
import * as moment from 'moment';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { FormatUtil } from 'src/app/utils/format.utils';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { Messages } from 'src/app/constants/messages.constant';


interface Currency {
  id: any;
  countryName: any;
  currency: any;
}

@Component({
  selector: 'app-add-project-popup',
  templateUrl: './add-project-popup.component.html',
  styleUrls: ['./add-project-popup.component.scss']
})
export class AddProjectPopupComponent implements OnInit {
  @ViewChild('fileInput') fileInput: any;
  projectForm !: FormGroup;
  resourceList: any[] = [];
  resourceSelectedList: any[] = [];
  approverSelectedList: { id: any, name: any, role: any, approvalSequence: any, approvalStatus: any, priority: any }[] = [];
  historicalProjectCodes: string[] = ['yes', 'no']
  showHistoricalProjectCode: boolean = false;
  readonly maxFileSize = 3 * 1024 * 1024;
  selectedFiles: File[] = [];
  fileInvalid: boolean = false;
  logoError: string | null = null;
  startDateMin!: Date;
  startDateMax!: Date;
  endDateMin!: Date;
  endDateMax!: Date;
  currencies: Currency[] = [];
  projectManagers: any[] = [];
  duration!: any;
  showDocumentError: boolean = false;
  approverList: { id: any, name: any, role: any }[] = [];
  approvalTypes = ['notNeeded', 'direct', 'hierarchy'];
  displayedColumns: string[] = ['name', 'role', 'action'];
  headingName: string = "add_project";
  projectDetails: any;
  resourceFilterCtrl: FormControl = new FormControl();
  selectedResourceName!: string;
  enteredValue!: string;
  formControlsArray: AbstractControl[] = [];
  editMode: boolean = false;
  updateView: boolean = false;
  viewProject: boolean = false;
  documentUrls: any;
  documentsNameList: any[] = [];
  projectId!: number;
  showApprovalButton: boolean = false;
  fileSizeError: boolean = false;
  @ViewChild('select') select: MatSelect | any;
  allSelected: boolean = false;
  projectHolidays: string[] = Messages.PROJECT_HOLIDAYS;
  searchControl: FormControl = new FormControl();
  filteredResources: any[] = [];

  constructor(private route: ActivatedRoute, private router: Router, private employeeService: EmployeeDetailsService, private toasterService: ToastrService, private formBuilder: FormBuilder, private projectService: ProjectManagementService) {
    this.getResourseList();
    this.route.params.subscribe(params => {
      this.projectId = params['id'];
      if (this.projectId !== undefined) {
        this.editMode = true;
        this.updateView = true;
        this.headingName = 'update_project'
        this.getProjectDetails(this.projectId);
      }
    });
    this.route.queryParamMap.subscribe(params => {
      const employeeId = params.get('employeeId');
      if (employeeId !== null && employeeId !== undefined) {
        this.headingName = 'view_project';
        this.viewProject = true;
        this.getApprovalStatus(this.projectId, employeeId);
      }
    })
    this.projectForm = this.formBuilder.group({
      projectName: ['', [Validators.required, ValidationUtil.noOnlySpacesValidator()]],
      projectCode: ['', [Validators.required, ValidationUtil.noOnlySpacesValidator()]],
      customer: ['', [Validators.required, ValidationUtil.noOnlySpacesValidator()]],
      projectGoal: ['', [Validators.required, ValidationUtil.noOnlySpacesValidator()]],
      objective: [''],
      deliverables: [''],
      projectManager: ['', Validators.required],
      priority: ['Low', Validators.required],
      projectBudget: ['', [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
      currency: ['101', Validators.required],
      startDate: ['', Validators.required],
      endDate: [''],
      duration: [null],
      projectInitial: ['', [Validators.required]],
      potentialRisk: [''],
      communicationChannel: [''],
      technology: [''],
      uploadDocuments: [null, [Validators.required]],
      resource: [''],
      historicalProjectCode: ['no', Validators.required],
      holidayList: ['', Validators.required],
      approval: ['notNeeded'],
      approver: [''],
      budgetType: ['', Validators.required]
    });

    this.projectForm.get('historicalProjectCode')?.valueChanges.subscribe(
      (res: any) => {
        this.showHistoricalProjectCode = res === 'yes';
      }
    )

    this.projectForm.get('approval')?.valueChanges.subscribe(
      (res: any) => {
        this.updateApproverValidators(res);
      }
    );

    this.formControlsArray = Object.values(this.projectForm.controls);
  }

  ngOnInit(): void {
    this.getCurrencyList();
    this.getResourseList();
    this.getProjectManagerList();
    const currentDate = new Date();
    const startDateMin = new Date(currentDate);
    startDateMin.setFullYear(startDateMin.getFullYear() - 1);
    this.startDateMin = startDateMin;
    this.startDateMax = new Date(currentDate.getFullYear() + 2, currentDate.getMonth(), currentDate.getDate());
    this.endDateMin = currentDate;
    this.endDateMax = new Date(currentDate.getFullYear() + 10, currentDate.getMonth(), currentDate.getDate());
    this.duration = this.projectForm.get('startDate')?.valueChanges.pipe(
      startWith(this.projectForm.get('startDate')?.value)
    ).subscribe(() => this.calculateDuration());
    this.projectForm.get('endDate')?.valueChanges.subscribe(() => this.calculateDuration());


    this.projectForm.get('resource')?.valueChanges.subscribe(val => {
      const selectedResource = this.resourceList.find(r => r.id === val);
      this.selectedResourceName = selectedResource ? selectedResource.name : '';
    });

    this.searchControl.valueChanges.subscribe(searchText => {
      this.filterResources(searchText);
    });

  }

  filterResources(searchText: string): void {
    if (!searchText) {
      this.filteredResources = this.resourceList;
    } else {
      searchText = searchText.toLowerCase();
      this.filteredResources = this.resourceList.filter(resource =>
        resource.name.toLowerCase().includes(searchText)
      );
    }
  }

  updateApproverValidators(approvalValue: string): void {
    const approverControl = this.projectForm.get('approver');
    if (approvalValue === 'direct') {
      approverControl?.setValidators([Validators.required]);
      this.approverSelectedList = [];
    } else {
      approverControl?.clearValidators();
    }
    approverControl?.updateValueAndValidity();
  }

  get approvalHierarchy(): FormArray {
    return this.projectForm.get('approvalHierarchy') as FormArray;
  }

  adjustDocumentValidators() {
    const uploadDocumentsControl = this.projectForm.get('uploadDocuments');
    if (!this.updateView) {
      uploadDocumentsControl?.setValidators([Validators.required]);
    } else {
      uploadDocumentsControl?.clearValidators();
    }
    uploadDocumentsControl?.updateValueAndValidity();
  }

  getLabel(control: AbstractControl): string {
    let label = '';
    if (control && control.parent) {
      const formGroup = control.parent.controls as { [key: string]: AbstractControl };
      Object.keys(formGroup).forEach(key => {
        if (control === formGroup[key]) {
          label = this.formatLabel(key);
        }
      });
    }
    return label;
  }

  getFormattedName(name: any): string {
    switch (name) {
      case 'notNeeded':
        return 'Not Needed';
      case 'hierarchy':
        return 'Hierarchy';
      case 'direct':
        return 'Direct';
      default:
        return name;
    }
  }

  getApprovalStatus(projectId: any, employeeId: any) {
    this.projectService.getApprovalStatus(projectId, employeeId).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.showApprovalButton = data.data;
        }
      }
    )
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  formatLabel(label: string): string {
    const words = label.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');
    const formattedLabel = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    return formattedLabel;
  }

  getProjectDetails(projectId: any) {
    this.projectService.getProjectDetails(projectId).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.projectDetails = data.data;
          this.documentUrls = this.projectDetails.documents;
          this.documentsNameList = this.documentUrls.map((url: any) => {
            const parts = url.split('/');
            return parts[parts.length - 1];
          });
          this.resourceSelectedList = this.projectDetails.resourseList;
          if (this.projectDetails.approval !== 'notNeeded') {
            this.approverSelectedList = this.projectDetails.projectApprovalList;
          }
          this.projectForm.patchValue({
            projectInitial: this.projectDetails.projectInitial,
            projectName: this.projectDetails.projectName,
            projectCode: this.projectDetails.projectCode,
            customer: this.projectDetails.customer,
            projectGoal: this.projectDetails.projectGoal,
            objective: this.projectDetails.objective,
            deliverables: this.projectDetails.deliverables,
            projectManager: this.projectDetails.projectManager.trim(),
            priority: this.projectDetails.priority.trim(),
            projectBudget: this.projectDetails.projectBudget,
            currency: this.projectDetails.currency,
            startDate: this.projectDetails.startDate,
            endDate: this.projectDetails.endDate,
            duration: this.projectDetails.duration,
            potentialRisk: this.projectDetails.potentialRisk,
            communicationChannel: this.projectDetails.communicationChannel,
            technology: this.projectDetails.technology,
            uploadDocuments: this.projectDetails.uploadDocuments,
            resource: this.projectDetails.resource,
            historicalProjectCode: this.projectDetails.historicalProjectCode,
            approval: this.projectDetails.approval,
            budgetType: this.projectDetails.budgetType,
            approver: this.projectDetails.approver,
            holidayList: this.projectDetails.holidayDays,
          });
          this.projectForm.get('currency')?.setValue(this.projectDetails.currency.trim());
        } else {
          this.goToBack();
        }
      }
    );
  }

  getCurrencyList() {
    this.projectService.getCurrencyList().subscribe(
      (data: any) => {
        if (data.status === 200) {
          const uniqueCurrencies = new Set<string>();
          this.currencies = data.data.filter((item: any) => {
            const trimmedCurrency = item.currency.trim();
            if (trimmedCurrency !== '' && !uniqueCurrencies.has(trimmedCurrency)) {
              uniqueCurrencies.add(trimmedCurrency);
              return true;
            }
            return false;
          });
        }
      }
    );
  }

  getResourseList() {
    this.employeeService.getEmployeeList('activeEmployee', sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.resourceList = data.data.map((res: any) => {
            return {
              id: res.id,
              name: res.firstName + " " + res.lastName
            }
          })
          this.filteredResources = this.resourceList;
        }
      }
    )
  }

  getProjectManagerList() {
    this.projectService.getProjectManagerList().subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.projectManagers = data.data;
          this.approverList = data.data;
        }
      })
  }

  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

  characterNumberAndSpaceAndHyphenAllowed(event: any) {
    return ValidationUtil.characterNumberAndSpaceAndHyphenAllowed(event);
  }

  editToggle() {
    this.editMode = !this.editMode;
  }

  calculateDuration() {
    const startDate = this.projectForm.get('startDate')?.value;
    const endDate = this.projectForm.get('endDate')?.value;
    if (startDate && endDate) {
      const duration = this.getDuration(new Date(startDate), new Date(endDate));
      this.projectForm.get('duration')?.setValue(duration);
    }
  }

  goBack() {
    this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.PROJECT_MANAGEMENT]);
  }

  getDuration(startDate: Date, endDate: Date): string {
    const start = moment(startDate);
    const end = moment(endDate);
    const years = end.diff(start, 'years');
    start.add(years, 'years');
    const months = end.diff(start, 'months');
    start.add(months, 'months');
    const days = end.diff(start, 'days');
    return `${years} years, ${months} months, ${days} days`;
  }

  removeFile(value: File) {
    const index = this.selectedFiles.findIndex((res: any) => res.name === value.name);
    if (index !== -1) {
      this.selectedFiles.splice(index, 1);
    }
  }

  removeDocumentNameList(value: any) {
    const index = this.documentsNameList.findIndex((res: any) => res === value);
    if (index !== -1) {
      this.documentsNameList.splice(index, 1);
    }
  }

  removeResource(value: any) {
    const index = this.resourceSelectedList.findIndex((res: any) => res === value);
    if (index !== -1) {
      this.resourceSelectedList.splice(index, 1);
    }
  }

  onResourceClick(event: any): void {
    const selectedResource = this.resourceList.find((res: any) => res.id === event.value);
    if (!this.resourceSelectedList.includes(selectedResource)) {
      this.resourceSelectedList.push(selectedResource);
      this.resourceList = this.resourceList.filter((res: any) => res.id !== event.value);
      this.projectForm.controls['resource'].setValue(null);
    }
  }

  onChangeFileInput(event: Event): void {
    this.showDocumentError = true;
    this.fileSizeError = false;
    const input = event.target as HTMLInputElement;

    if (input.files) {
      for (let i = 0; i < input.files.length; i++) {
        const file = input.files[i];
        if (file.size > AppConstant.MAX_FILE_SIZE) {
          this.fileSizeError = true;
        } else if (!this.isFileSelected(file)) {
          this.selectedFiles.push(file);
        }
      }
    }
  }

  isFileSelected(file: File): boolean {
    return this.selectedFiles.some(selectedFile => selectedFile.name === file.name);
  }


  goToBack() {
    if (this.viewProject) {
      this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.PROJECT_APPROVAL])
    }
    else {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.PROJECT_MANAGEMENT])
    }
  }

  addProject() {
    if (this.updateView) {
      this.projectForm.get('uploadDocuments')?.clearValidators();
    }
    const error: boolean = this.updateView ? this.updateView && this.projectForm.valid : this.projectForm.valid && this.selectedFiles.length > 0;
    const approval: boolean = this.projectForm.value.projectApproval === 'hierarchy' ? this.approverSelectedList.length > 0 : true;
    if (approval) {
      let startDate: any;
      if (typeof (this.projectForm.value.startDate) == 'string') {
        startDate = FormatUtil.adjustDateForTimeOffset(new Date(this.projectForm.value.startDate));
      } else {
        startDate = FormatUtil.adjustDateForTimeOffset(this.projectForm.value.startDate);
      }
      const projectDto = {
        projectInitial: this.projectForm.value.projectInitial,
        projectName: this.projectForm.value.projectName,
        projectCode: this.projectForm.value.projectCode,
        customer: this.projectForm.value.customer,
        projectGoal: this.projectForm.value.projectGoal,
        objective: this.projectForm.value.objective,
        deliverables: this.projectForm.value.deliverables,
        startDate: startDate,
        endDate: this.projectForm.value.endDate,
        duration: this.projectForm.value.duration,
        projectBudget: this.projectForm.value.projectBudget,
        currency: this.currencies.find(currency => currency.id === Number(this.projectForm.value.currency))?.currency,
        projectManager: this.projectManagers.find(manager => manager.name === this.projectForm.value.projectManager).id,
        priority: this.projectForm.value.priority,
        communicationChannel: this.projectForm.value.communicationChannel,
        technology: this.projectForm.value.technology,
        potentialRisk: this.projectForm.value.potentialRisk,
        holidayDays: this.projectForm.value.holidayList,
        historicalProjectCode: this.projectForm.value.historicalProjectCode,
        resourseList: this.resourceSelectedList,
        approval: this.projectForm.value.approval,
        budgetType: this.projectForm.value.budgetType,
        projectApprovalList: this.approverSelectedList,
      };
      const formData: FormData = new FormData();
      formData.append('project', new Blob([JSON.stringify(projectDto)], { type: 'application/json' }));

      if (!this.updateView) {
        for (const file of this.selectedFiles) {
          formData.append('files', file);
        }
      } else {
        formData.append('files', new Blob([]));
      }

      this.projectService.addProject(formData).subscribe(
        (response: any) => {
          if (response.status === 200) {
            this.goToBack();
            this.toasterService.clear();
            this.toasterService.success(response.message);
          } else {
            this.toasterService.clear();
            this.toasterService.error(response.message);
          }
        },
        (error: any) => {
          console.error(error);
        }
      );
    } else {
      this.fileInvalid = true;
    }
  }

  cancel() {
    this.editMode = false;
  }


  selectApprover(event: any) {
    const selectedApprover = this.approverList.find((res: any) => res.id === event.value);
    if (selectedApprover !== undefined) {
      const approver = {
        id: selectedApprover.id,
        name: selectedApprover.name,
        role: selectedApprover.role,
        approvalSequence: this.approverSelectedList.length + 1,
        approvalStatus: null,
        priority: null
      };
      const approverExists = this.approverSelectedList.some((existingApprover: any) => existingApprover.id === approver.id);
      if (!approverExists) {
        this.approverSelectedList.push(approver);
        if (this.projectForm.get('approval')?.value === 'hierarchy') {
          this.approverList = this.approverList.filter((res: any) => res.id !== selectedApprover.id);
          this.projectForm.controls['approver'].setValue(null);
        }
        this.updateApprovalSequences();
      }
    }
  }

  removeApproverFromList(index: number) {
    const removedApprover = this.approverSelectedList.splice(index, 1)[0];
    this.updateApprovalSequences();
    const approverExists = this.approverList.some((existingApprover: any) => existingApprover.id === removedApprover.id);
    if (!approverExists) {
      this.approverList.push({
        id: removedApprover.id,
        name: removedApprover.name,
        role: removedApprover.role
      });
    }
  }


  updateApprovalSequences() {
    this.approverSelectedList.forEach((approver, index) => {
      approver.approvalSequence = index + 1;
    });
    this.updateApproverPriority();
  }

  updateApproverPriority() {
    const maxSequence = this.approverSelectedList.length;
    this.approverSelectedList.forEach((approver) => {
      approver.priority = maxSequence - approver.approvalSequence + 1;
    });
  }



  approve() {
    this.submitApproval("Approved");
  }

  reject() {
    this.submitApproval("Rejected");
  }

  onCurrencySelected(currency: Currency) {
    this.projectForm.get('currency')?.setValue(currency.currency);
  }

  positiveNumberValidator(control: FormControl) {
    const value = control.value;
    return value && value > 0 ? null : { notPositive: true };
  }

  submitApproval(approvalStatus: any) {
    this.projectService.submitApproval(approvalStatus, this.projectId).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.showApprovalButton = false;
          this.toasterService.clear();
          this.toasterService.success(data.message);
        }
        else {
          this.toasterService.clear();
          this.toasterService.error(data.message);
        }
      }
    )
  }

  selectAllProjectHolidays() {
    this.select.options.forEach((item: MatOption) =>
      this.allSelected ? item.select() : item.deselect()
    );
  }

  selectProjectHolidays() {
    let holidayStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        holidayStatus = false;
      }
    });
    this.allSelected = holidayStatus;
  }

  validateCharacter(event: any) {
    return ValidationUtil.validateCharacter(event);
  }
}
