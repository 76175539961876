import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UpdateDto } from 'src/app/model/salary-structure.model';
import { SalaryStructureService } from 'src/app/services/salary-structure/salary-structure.service';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { AppConstant } from 'src/app/constants/app.constants';

@Component({
  selector: 'app-salary-structure',
  templateUrl: './salary-structure.component.html',
  styleUrls: ['./salary-structure.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SalaryStructureComponent implements OnInit {
  @ViewChild('editInput') editInput!: ElementRef;
  public earningsData: any[] = [];
  public deductionData: any[] = [];
  public reimbursementData: any[] = [];
  componentForm!: FormGroup;
  showPopup: boolean = false;
  isInputFocused: boolean = false;
  saveButtonStatus: boolean = false;
  totalPercentage!: any;
  earningFormControl!: FormControl;

  constructor(private fb: FormBuilder, private salaryStructureService: SalaryStructureService, private toastrService: ToastrService) { }

  ngOnInit() {
    this.getEarningsData();
    this.getDeductionData();
    this.getReimbursementData();

    this.earningFormControl = new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*$')
    ]);

    this.componentForm = this.fb.group({
      key: ['', [Validators.required, ValidationUtil.onlySpacesOrNumbersValidator()]],
      value: ['', [Validators.required, ValidationUtil.maxValueValidator(200000000), ValidationUtil.onlyDigitsValidator()]],
      collectionType: ['', Validators.required],
      type: ["", [Validators.required]],
    });

    this.componentForm.get('type')?.valueChanges.subscribe(value => {
      const valueControl = this.componentForm.get('value');
      if (value === 'percentage') {
        valueControl?.setValidators([Validators.required, ValidationUtil.percentageValidator(), ValidationUtil.onlyDigitsValidator()]);
      } else {
        valueControl?.setValidators([Validators.required, ValidationUtil.maxValueValidator(200000000), ValidationUtil.onlyDigitsValidator()]);
      }
      valueControl?.updateValueAndValidity();
    });
  }

  openMyMenu(menuTrigger: MatMenuTrigger) {
    menuTrigger.openMenu();
  }


  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

  onlyNumberKey(event: any) {
    return ValidationUtil.validateNumber(event);
  }

  getEarningsData() {
    const companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
    const typeOfStructure = AppConstant.EARNING;
    this.salaryStructureService.getSalaryStructure(companyId, typeOfStructure).subscribe(
      (data: any) => {
        const processedData: any[] = [];
        for (const item of data) {
          const earningItem = {
            type: this.formatKeyName(item.type),
            status: this.getStatus(item.status),
            value: item.value,
            editing: false,
            updatedAmount: item.value,
            amountType: item.amountType
          };
          processedData.push(earningItem);
        }
        processedData.sort((a, b) => a.type.localeCompare(b.type));
        this.earningsData = processedData;
        this.totalPercentage = 0;
        for (const item of this.earningsData) {
          if (item.value < 100 && item.status === "active") {
            if (item.type === 'Hra') {
              item.type = 'HRA'
            }
            this.totalPercentage += item.value;
          }
        }
        this.earningsData.push({
          type: 'Total',
          status: null,
          value: this.totalPercentage,
          editing: false,
          updatedAmount: 12,
          amountType: 'percentage'
        })
      }
    );
  }

  getDeductionData() {
    const companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
    const typeOfStructure = "deduction";
    this.salaryStructureService.getSalaryStructure(companyId, typeOfStructure).subscribe(
      (data: any) => {
        const processedData: any[] = [];
        for (const item of data) {
          const deductionItem = {
            type: this.formatKeyName(item.type),
            status: this.getStatus(item.status),
            value: item.value,
            editing: false,
            updatedAmount: item.value,
            amountType: item.amountType
          };
          processedData.push(deductionItem);
        }
        processedData.sort((a, b) => a.type.localeCompare(b.type));
        this.deductionData = processedData;
      }
    );
  }

  getReimbursementData() {
    const companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
    const typeOfStructure = "reimbursement";
    this.salaryStructureService.getSalaryStructure(companyId, typeOfStructure).subscribe(
      (data: any) => {
        const processedData: any[] = [];
        for (const item of data) {
          const reimbursementItem = {
            type: this.formatKeyName(item.type),
            status: this.getStatus(item.status),
            value: item.value,
            editing: false,
            amountType: item.amountType,
            updatedAmount: item.value
          };
          processedData.push(reimbursementItem);
        }
        processedData.sort((a, b) => a.type.localeCompare(b.type));
        this.reimbursementData = processedData;
      }
    );
  }

  getStatus(Status: any) {
    if (Status === true) {
      return 'active';
    } else {
      return 'inactive';
    }
  }

  booleanValueConverter(status: any) {
    if (status === 'active') {
      return false;
    }
    return true;
  }

  toggleStatus(earning: any, typeOfStructure: string) {
    const dto: UpdateDto = {
      id: sessionStorage.getItem(AppConstant.COMPANY_ID),
      type: this.convertToCamelCase(earning.type),
      value: null,
      status: this.booleanValueConverter(earning.status),
      typeOfStructure: typeOfStructure,
      amountType: earning.amountType
    };
    this.salaryStructureService.toggleStatus(dto).subscribe((data: any) => {
      if (data.status === 200) {
        earning.status = earning.status === 'active' ? 'inactive' : 'active';
        this.toastrService.clear();
        this.getEarningsData();
        return this.toastrService.success(this.formatKeyName(data.message) + " " + this.formatKeyName(earning.status));
      }
      this.toastrService.clear()
      return this.toastrService.error(this.formatKeyName(data.message));
    });
  }

  formatKeyName(key: string) {
    const words = key.split(/(?=[A-Z])/).map((word: string) => word.charAt(0).toUpperCase() + word.slice(1));
    return words.join(' ');
  }

  convertToCamelCase(inputString: string): string {
    const words = inputString
      .toLowerCase()
      .split(' ')
      .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
      .join('');
    return words.charAt(0).toLowerCase() + words.slice(1);

  }

  deleteEarning(earning: any) {
    if (confirm(AppConstant.DELETE_CONFIRMATION_MASSAGE)) {
      const updateData: UpdateDto = {
        id: sessionStorage.getItem(AppConstant.COMPANY_ID),
        type: this.convertToCamelCase(earning.type),
        value: null,
        typeOfStructure: "earning",
        status: false,
        amountType: earning.amountType
      };
      this.salaryStructureService.delete(updateData).subscribe((res: any) => {
        if (res.status === 200) {
          this.toastrService.clear();
          this.getEarningsData();
          return this.toastrService.success(this.formatKeyName(res.message));
        }
        this.toastrService.clear();
        return this.toastrService.error(this.formatKeyName(res.message));
      })
      this.getEarningsData();
    }
  }

  onInputBlur(earning: any) {
    this.cancelEdit(earning);
  }


  editEarning(earning: any) {
    earning.editing = false;
    setTimeout(() => {
      this.editInput.nativeElement.focus();
    });
    this.toastrService.clear();
    if (!earning.editing) {
      earning.editing = true;
    }
  }


  saveEarning(earning: any) {
    if (this.earningFormControl.valid) {
      const updateData: UpdateDto = {
        id: sessionStorage.getItem(AppConstant.COMPANY_ID),
        type: this.convertToCamelCase(earning.type),
        value: earning.updatedAmount,
        typeOfStructure: AppConstant.EARNING,
        status: false,
        amountType: earning.amountType
      };
      this.salaryStructureService.updateColumnValue(updateData).subscribe(
        (res: any) => {
          if (res.status === 200) {
            earning.editing = true;
            earning.amount = earning.updatedAmount;
            this.toastrService.clear();
            this.getEarningsData();
            return this.toastrService.success(this.formatKeyName(res.message));
          }
          this.toastrService.clear();
          return this.toastrService.error(this.formatKeyName(res.message));
        });
    }
  }


  deleteDeduction(deduction: any) {
    if (confirm(AppConstant.DELETE_CONFIRMATION_MASSAGE)) {
      const updateData: UpdateDto = {
        id: sessionStorage.getItem(AppConstant.COMPANY_ID),
        type: this.convertToCamelCase(deduction.type),
        value: null,
        typeOfStructure: "deduction",
        status: false,
        amountType: deduction.amountType
      };
      this.salaryStructureService.delete(updateData).subscribe(
        (res: any) => {
          if (res.status === 200) {
            deduction.editing = true;
            deduction.amount = deduction.updatedAmount;
            this.toastrService.clear();
            this.getDeductionData();
            return this.toastrService.success(this.formatKeyName(res.message));
          }
          this.toastrService.clear();
          return this.toastrService.error(this.formatKeyName(res.message));
        },)
    }

  }

  editDeduction(deduction: any) {
    deduction.editing = false;
    setTimeout(() => {
      this.editInput.nativeElement.focus();
    });
    this.toastrService.clear();
    if (!deduction.editing) {
      deduction.editing = true;
    }
  }

  saveDeduction(deduction: any) {
    const updateData: UpdateDto = {
      id: sessionStorage.getItem(AppConstant.COMPANY_ID),
      type: this.convertToCamelCase(deduction.type),
      value: deduction.updatedAmount,
      typeOfStructure: "deduction",
      status: false,
      amountType: deduction.amountType
    };
    this.salaryStructureService.updateColumnValue(updateData).subscribe(
      (res: any) => {
        if (res.status === 200) {
          deduction.editing = true;
          deduction.amount = deduction.updatedAmount;
          this.toastrService.clear();
          this.getDeductionData();
          return this.toastrService.success(this.formatKeyName(res.message));
        }
        this.toastrService.clear();
        return this.toastrService.error(this.formatKeyName(res.message));
      });
  }

  deleteReimbursement(reimbursement: any) {
    if (confirm(AppConstant.DELETE_CONFIRMATION_MASSAGE)) {
      const updateData: UpdateDto = {
        id: sessionStorage.getItem(AppConstant.COMPANY_ID),
        type: this.convertToCamelCase(reimbursement.type),
        value: null,
        typeOfStructure: "reimbursement",
        status: false,
        amountType: reimbursement.amountType
      };
      this.salaryStructureService.delete(updateData).subscribe(
        (res: any) => {
          if (res.status === 200) {
            reimbursement.editing = true;
            reimbursement.amount = reimbursement.updatedAmount;
            this.toastrService.clear();
            this.getReimbursementData();
            return this.toastrService.success(this.formatKeyName(res.message));
          }
          this.toastrService.clear();
          return this.toastrService.error(this.formatKeyName(res.message));
        });
    }
  }

  editReimbursement(reimbursement: any) {
    reimbursement.editing = false;
    setTimeout(() => {
      this.editInput.nativeElement.focus();
    });
    if (!reimbursement.editing) {
      reimbursement.editing = true;
    }
  }

  saveReimbursement(reimbursement: any) {
    if (reimbursement.updatedAmount > 0) {
      const updateData: UpdateDto = {
        id: sessionStorage.getItem(AppConstant.COMPANY_ID),
        type: this.convertToCamelCase(reimbursement.type),
        value: reimbursement.updatedAmount,
        typeOfStructure: "reimbursement",
        status: false,
        amountType: reimbursement.amountType
      };
      this.salaryStructureService.updateColumnValue(updateData).subscribe(
        (res: any) => {
          if (res.status === 200) {
            reimbursement.editing = true;
            reimbursement.amount = reimbursement.updatedAmount;
            this.toastrService.clear();
            this.getReimbursementData();
            return this.toastrService.success(this.formatKeyName(res.message));
          }
          this.toastrService.clear();
          return this.toastrService.error(this.formatKeyName(res.message));
        });
    }
  }

  cancelEdit(earning: any) {
    earning.editing = false;
  }

  updateEditedAmount(earning: any, newValue: any) {
    earning.updatedAmount = newValue;
  }

  disableEditMode(earning: any) {
    earning.editing = false;
  }

  togglePopup(): void {
    this.saveButtonStatus = false;
    this.componentForm.reset();
    this.showPopup = !this.showPopup;
  }

  getData(typeOfStructure: string) {
    let data = null;
    if (typeOfStructure === AppConstant.EARNING) {
      data = this.getEarningsData();
    }
    else if (typeOfStructure === AppConstant.DEDUCTION) {
      data = this.getDeductionData();
    }
    else if (typeOfStructure === AppConstant.REIMBURSEMENT) {
      data = this.getReimbursementData();
    }
    return data;
  }


  addComponents() {
    if (this.componentForm.valid) {
      this.totalPercentage = 0;
      for (const item of this.earningsData) {
        if (item.value <= 100 && item.type !== "Total") {
          this.totalPercentage += item.value;
        }
      }
      const componentData: UpdateDto = {
        id: sessionStorage.getItem(AppConstant.COMPANY_ID),
        type: this.convertToCamelCase(this.componentForm.value.key),
        value: this.componentForm.value.value,
        typeOfStructure: this.componentForm.value.collectionType,
        status: true,
        amountType: this.componentForm.value.type
      };
      if (componentData.typeOfStructure === "earning" && componentData.value < 100) {
        this.totalPercentage = this.totalPercentage + componentData.value;
      }
      if (this.totalPercentage <= 100) {
        this.saveButtonStatus = true;
        this.salaryStructureService.addComponents(componentData).subscribe((data: any) => {
          if (data.status === 200) {
            this.getData(componentData.typeOfStructure);
            this.componentForm.reset();
            this.toastrService.clear()
            this.toastrService.success(this.formatKeyName(data.message));
            return this.togglePopup();
          }
          this.toastrService.clear()
          return this.toastrService.error(this.formatKeyName(data.message));
        });
      }
      else {
        this.toastrService.clear()
        if (componentData.typeOfStructure === "earning") {
          this.toastrService.error(AppConstant.PLEASE_PROVIDE_EARNING_TOTAL_UPTO_100);
        } else {
          this.toastrService.error(AppConstant.PLEASE_PROVIDE_VALID_DETAILS);
        }
      }
    }
  }

}
