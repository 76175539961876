import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyDetails } from '../../model/companyProfile.model';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/enviroment';
import { API_END_POINTS } from '../../config/api-endpoint.config';
import { AppConstant } from 'src/app/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class CompanyProfileService {


  constructor(private http: HttpClient) { }

  updateCompany(formData: FormData, headers: HttpHeaders): Observable<any> {
    return this.http.put(environment.apiUrl + API_END_POINTS.UPDATE_COMPANY_PROFILE, formData, { headers: headers })
      .pipe(map((res: any) => {
        return (res);
      }));
  }

  getActiveHrDetails(id: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.VIEWPROFILE + id).pipe(
      map((res: any) => { return (res) })
    );
  }

  allCountries(): Observable<any> {
    return this.http.get(API_END_POINTS.COUNTRY_STATE_CITY);
  }

  getGst(gstNumber: any): Observable<any> {
    return this.http.get(API_END_POINTS.GST_API + AppConstant.GSTKEY + AppConstant.SEPERATOR + gstNumber)
  }


  postCompanyData(formData: FormData, headers: HttpHeaders): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.COMPANYPROFILE, formData, { headers: headers })
      .pipe(map((res: any) => {
        return (res);
      }));
  }

  getCompanyData(id: any) {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_COMPANY_DETAILS + id)
      .pipe(map((res: any) => {
        return (res)
      }));
  }
}
