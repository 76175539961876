import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { AppConstant } from 'src/app/constants/app.constants';
import { CreateApproval } from 'src/app/model/create-approval/create-approval.model';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class ApprovalService {

  constructor(private http: HttpClient) { }

  getEmployees(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_EMPLOYEES_MANAGERS_LIST + companyId + API_END_POINTS.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res;
      }),
    )
  }

  getPayrunApproveDetails(companyId: any, approvalType: string): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_PAYRUN_APPROVE_DETAILS + companyId + API_END_POINTS.SEPERATOR + approvalType).pipe(
      map((res: any) => {
        return res;
      }),
    )
  }

  createPayrunApproval(createPayrunApprovalRequest: CreateApproval): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.CREATE_PAYRUN_APPROVAL, createPayrunApprovalRequest).pipe(
      map((res: any) => {
        return res;
      }),
    )
  }

  searchEmployee(companyId: any, employeeName: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.SEARCH_EMPLOYEE + companyId + AppConstant.SEPERATOR + employeeName).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  deleteApprover(companyId: any, approverId: number): Observable<any> {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_APPROVER + companyId + API_END_POINTS.SEPERATOR + approverId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  changeToDirectApproval(companyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.CHANGE_TO_DIRECT_APPROVAL + companyId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getApprovalTypeAndPayrunStatusFlags(companyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_APPROVAL_TYPE_AND_PAYRUN_STATUS + companyId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getPayrunApprovalHistory(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_PAY_RUN_APPROVAL_HISTORY + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getPayRunApproval(): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_PAY_RUN_APPROVAL_TYPE + sessionStorage.getItem(AppConstant.COMPANY_ID)).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  sendToApprover(dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.SEND_TO_APPROVER, dto).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getPayRunDetailsById(payRunApprovalId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_PAY_RUN_DETAILS_BY_ID + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + sessionStorage.getItem(AppConstant.EMPLOYEE_ID) + AppConstant.SEPERATOR + payRunApprovalId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getPayRunApprovalDetails(payRunApprovalId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_APPROVAL_DETAILS + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + payRunApprovalId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  addPayRunApprovalStatus(dto: any) {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_PAY_RUN_STATUS + sessionStorage.getItem(AppConstant.COMPANY_ID), dto).pipe(
      map((res: any) => {
        return res;
      })
    )
  }


}
