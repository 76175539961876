import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { ReplaySubject, Subject, debounceTime, delay, filter, map, takeUntil, tap } from 'rxjs';
import { ApprovalService } from 'src/app/services/approval/approval.service';
import { HelpDeskService } from 'src/app/services/help-desk/help-desk.service';
import { Employees } from '../../admin/add-approver/add-approver.component';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.dev';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-incident-request',
  templateUrl: './incident-request.component.html',
  styleUrls: ['./incident-request.component.scss']
})

export class IncidentRequestComponent {
  requestorsInfo !: FormGroup;
  selectedFile!: File;
  readonly maxFileSize = 3 * 1024 * 1024;
  http: any;
  constructor(private toasterService: ToastrService, private formBuilder: FormBuilder, private router: Router, private approvalService: ApprovalService, private helpDeskService: HelpDeskService) { }
  selectedFiles: File[] = [];

  search = false;
  employeeList: Employees[] = [];
  filterEmployeeList: Employees[] = [];
  personSearchFilter: FormControl<any> = new FormControl<any>('');
  _onDestroy = new Subject<string>();
  searching: boolean = false;
  serviceList: any;
  categoryId: any;
  categoryList: any;
  selectedServiceId: number | null = null;
  public filteredServerSide: ReplaySubject<Employees[]> = new ReplaySubject<Employees[]>(1);
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  serviceCode: any;
  type: any;
  selectedCategoryId: any;
  subCategoriesList: any;
  isServiceNotSelected: boolean = false;
  isCategoryNotSelected: boolean = false;
  projectList: any;
  ngOnInit(): void {
    this.requestorsInfo = this.formBuilder.group({
      serviceRequestType: [''],
      requestFor: ['', [Validators.required]],
      contactNo: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10), ValidationUtil.validMobileNumber()]],
      location: ['', [Validators.required]],
      projectId: [''],
      serviceId: ['', [Validators.required]],
      serviceCategoryId: [''],
      subCategoryId: [''],
      shortDescription: ['', [Validators.required]],
      detailedDescription: [''],
      attachment: [''],
    });
    const companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
    const employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
    this.approvalService.getEmployees(companyId, employeeId).subscribe((res: any) => {
      if (res.status == 200 && res.data) {
        this.employeeList = res.data;
        this.filterEmployeeList = res.data;
      }
    });

    this.personSearchFilter.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searching = true),
        takeUntil(this._onDestroy),
        debounceTime(2000),
        map(search => {
          this.search = search;
          if (!this.employeeList) {
            return [];
          }
          return this.employeeList.filter(employee => employee.employeeName.toLowerCase().indexOf(search) > -1);
        }),
        delay(500),
        takeUntil(this._onDestroy)
      )
      .subscribe(filteredEmployees => {
        if (filteredEmployees.length != 0) {
          this.searching = false;
          this.filteredServerSide.next(filteredEmployees);
        } else {
          this.approvalService.searchEmployee(companyId, this.search).subscribe((res: any) => {
            if (res.status == 200 && res.data && res.data.length > 0) {
              this.searching = false;
              this.filteredServerSide.next(filteredEmployees);
            } else {
              this.searching = false;
            }
          })
        }

      },
        error => {
          this.searching = false;
        });

    this.getServiceList();
    this.getSubCategoryList();
    this.getProjectList();
  }
  isDropdownOpen = false;
  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onFileChange(event: any) {
    const files = (event.target as HTMLInputElement).files;
    if (files) {
      const newFiles = Array.from(files);
      newFiles.forEach(file => {
        if (!this.isfileTypevalid(file)) {
          this.requestorsInfo.get('attachment')?.setErrors({ fileTypeInvalid: true });
        } else if (file.size > this.maxFileSize) {
          this.requestorsInfo.get('attachment')?.setErrors({ fileSizeExceeded: true });
        } else if (file.name.length > 25) {
          this.requestorsInfo.get('attachment')?.setErrors({ fileNameInvalid: true });
        } else {
          this.selectedFiles.push(file);
          this.requestorsInfo.get('attachment')?.setErrors(null);
        }
      });
    }
  }

  removeFile(index: number) {
    this.selectedFiles.splice(index, 1);
    if (this.selectedFiles.length === 0) {
      this.requestorsInfo.get('attachment')?.setErrors({ required: true });
    } else {
      this.requestorsInfo.get('attachment')?.setErrors(null);
    }
  }

  isfileTypevalid(file: File): boolean {
    const allowedFilesTypes = ['xlsx', 'pdf'];
    const extension = file.name.split('.').pop()?.toLowerCase();
    return !!extension && allowedFilesTypes.includes(extension);
  }

  validateNumber(event: { which: any; keyCode: any; }): boolean {
    return ValidationUtil.validateNumber(event);
  }

  getProjectList() {
    this.helpDeskService.getAllProjectList(this.companyId).subscribe((data: any) => {
      this.projectList = data.data;
    })
  }

  getServiceList() {
    this.helpDeskService.getserviceList(this.companyId).subscribe((data: any) => {
      this.serviceList = data.data;
    })
  }

  getCategoryList() {
    this.helpDeskService.getCategoryList(this.companyId, this.selectedServiceId).subscribe((data: any) => {
      this.categoryList = data.data;
    })
  }

  getSubCategoryList() {
    this.helpDeskService.getSubCategory(this.companyId, this.selectedCategoryId).subscribe((data: any) => {
      this.subCategoriesList = data.data;
      if (data.data == '') {
        this.isCategoryNotSelected = false;
      }
      else {
        this.isCategoryNotSelected = true;
      }
    })
  }

  onServiceSelectionChange(serviceId: number) {
    this.selectedServiceId = serviceId;
    if (this.selectedServiceId == null) {
      this.isServiceNotSelected = false;
    } else {
      this.isServiceNotSelected = true;

    }
    this.getCategoryList();
  }

  onServiceCategoryChange(categoryId: number) {
    this.selectedCategoryId = categoryId;
    if (this.selectedCategoryId == null || this.subCategoriesList == null) {
      this.isCategoryNotSelected = false;
    }
    this.getSubCategoryList();
  }

  submit() {
    if (this.requestorsInfo.valid) {
      const formData: FormData = new FormData();

      const employeeId = this.requestorsInfo.value.requestFor.replace('EMP', '');

      const payload = {
        type: 'Incident',
        companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
        serviceRequestType: this.requestorsInfo.value.serviceRequestType === 'true',
        requestFor: employeeId,
        contactNo: this.requestorsInfo.value.contactNo,
        location: this.requestorsInfo.value.location,
        projectId: this.requestorsInfo.value.projectId,
        serviceId: this.requestorsInfo.value.serviceId,
        serviceCategoryId: this.requestorsInfo.value.serviceCategoryId,
        subCategoryId: this.requestorsInfo.value.subCategoryId,
        shortDescription: this.requestorsInfo.value.shortDescription,
        detailedDescription: this.requestorsInfo.value.detailedDescription,

      };

      formData.append('createServiceRequestDto', JSON.stringify(payload));
      this.selectedFiles.forEach((file, index) => {
        formData.append('attachment', file, file.name);
      });

      this.helpDeskService.createIncident(formData).subscribe((data: any) => {
        if (data.status == 200) {
          this.toasterService.success(data.message);
          this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_REQUESTOR_INFORMATION]);
        }
      });
    }
  }

  reset() {
    this.selectedFiles = [];
  }

  goBack() {
    this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.HELPDESK]);
  }

}
