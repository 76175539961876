<div class="main-container">
  <div class="card-container">
    <div class="banner-container">
      <div class="heading-container">
        <span class="heading">{{headingName}} {{'24/7_Support' | translate}}</span>
        <div [ngClass]="{'two-card-grid': adminRole, 'three-card-grid': !adminRole}" class="cards">
          <mat-card class="reportCards" (click)="incident()">
            <mat-card-title>{{'incident' | translate}}</mat-card-title>
            <mat-card-content>
              <div class="content">
                <mat-icon>report_problem</mat-icon>
                <span class="card-text">{{'create_incident_for_any_Non_working_application' | translate}}</span>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card class="reportCards" (click)="request()">
            <mat-card-title>{{'request'|translate}}</mat-card-title>
            <mat-card-content>
              <div class="content">
                <mat-icon>assignment</mat-icon>
                <span class="card-text">{{'create_new_asset,Doc_etc_request' | translate}}</span>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card class="reportCards" (click)="viewTicket()">
            <mat-card-title>{{'my_ticket' | translate}}</mat-card-title>
            <mat-card-content>
              <div class="content">
                <mat-icon>dashboard</mat-icon>
                <span class="card-text">{{"track_incident_request_status" | translate}}</span>
              </div>
            </mat-card-content>
          </mat-card>
          <mat-card *ngIf="adminRole" class="reportCards" (click)="resolution()">
            <mat-card-title>{{'resolution' | translate}}</mat-card-title>
            <mat-card-content>
              <div class="content">
                <mat-icon>support_agent</mat-icon>
                <span class="card-text">{{"resolution_comment" | translate}}</span>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <div class="image-container">
        <img class="img" src="../../assets/images/support.svg">
      </div>
    </div>
  </div>
  <app-birthday-list></app-birthday-list>
</div>