import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RevertExitProcessComponent } from 'src/app/components/admin/exit-process/revert-exit-process/revert-exit-process.component';
import { AppConstant } from 'src/app/constants/app.constants';
import { StateService } from 'src/app/services/exit-process/state.service';
import { ResignationService } from 'src/app/services/resignation/resignation.service';

@Component({
  selector: 'app-view-resignation',
  templateUrl: './view-resignation.component.html',
  styleUrls: ['./view-resignation.component.scss']
})
export class ViewResignationComponent implements OnInit {

  constructor(private stateService: StateService, private dialog: MatDialog, private router: Router, private resignationService: ResignationService, private toastrService: ToastrService) { }
  resignationDetails: any;
  showWithdrawConfirmation: boolean = false;
  isShowWithdraw: boolean = false;
  employeeName = sessionStorage.getItem('employeeName') || '';
  ngOnInit(): void {
    this.getResignation()
  }
  getResignation() {
    this.resignationService.getResignation(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
      (data: any) => {
        if (data.data.managerStatus === 'Pending') {
          this.isShowWithdraw = true
        } else {
          this.isShowWithdraw = false;
        }
        this.resignationDetails = data.data;
        this.resignationDetails.submissionDate = this.formatDate(this.resignationDetails.submissionDate);
        this.resignationDetails.lastWorkingDay = this.formatDate(this.resignationDetails.lastWorkingDay);
        this.resignationDetails.tentativeLWD = this.formatDate(this.resignationDetails.tentativeLWD);
        const employeeName = this.getInitials(this.employeeName);
        const managerName = this.getInitials(this.resignationDetails.managerName);
        document.querySelectorAll('.circle.PL').forEach(circle => circle.textContent = employeeName);
        document.querySelectorAll('.circle.RD').forEach(circle => circle.textContent = managerName);
      }
    );
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  getInitials(name: string): string {
    if (!name) return '';
    const initials = name.match(/\b(\w)/g);
    return (initials || []).join('').toUpperCase();
  }
  withdrawal() {
  }

  openWithdrawConfirmation() {
    this.showWithdrawConfirmation = true;
  }

  cancelWithdraw() {
    this.showWithdrawConfirmation = false;
  }

  confirmWithdraw() {
    const dialogRef = this.dialog.open(RevertExitProcessComponent, {
      width: '600px',
      panelClass: 'exit-dialog-container',
    });

    dialogRef.componentInstance.revertSuccess.subscribe((success: boolean) => {
      if (success) {
        this.stateService.setApplyResignation(true);
        this.router.navigate([
          AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.EXIT_PROCESS,
        ]).then(() => {
          window.location.reload();
        });
      } else {
        this.router.navigate([
          AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_RESIGNATION,
        ]);
      }
    });
  }


}
