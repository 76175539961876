import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { SalaryService } from 'src/app/services/salary-details/salaryService';
import { SettingService } from 'src/app/services/setting/setting.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-update-employee-salary-details',
  templateUrl: './update-employee-salary-details.component.html',
  styleUrls: ['./update-employee-salary-details.component.scss']
})
export class UpdateEmployeeSalaryDetailsComponent {
  @Input() createSalaryDetailsForm!: FormGroup;
  @Input() id!: string;
  earningData: any;
  deductionData: any[] = [];
  reimbursementData: any;
  amount: any;
  grossPay: any;
  totalEarning: any;
  totalDeduction: any;
  pFcheckboxValue: boolean = false;
  showButton: boolean = false;
  showEditIcon: boolean = false;
  annaulCtcEditable: boolean = false;
  resData: any;
  deductioToggleData: any;
  manditoryHours:any;

  constructor(private readonly fb: FormBuilder, private readonly settingService: SettingService, private readonly toaster: ToastrService, private readonly service: SalaryService, private readonly employeeDetailsService: EmployeeDetailsService) { }

  displayedColumns: string[] = [AppConstant.SALARY_COMPONENT, AppConstant.CALCULATION_TYPE, AppConstant.MONTHLY_AMOUNT, AppConstant.ANNUAL_AMOUNT];

  OnlyNumbersAllowed(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  ngOnInit() {
    this.amount = 0;
    if (sessionStorage.getItem(AppConstant.BASIC_DETAILS_ID) !== null || sessionStorage.getItem(AppConstant.BASIC_DETAILS_ID) !== " ") {
      this.showButton = true;
    }
    else {
      this.showButton = false;
    }
    this.salaryDetails();
    this.getSalaryStructure();
    this.getAnnualCtc();
    const daysInCurrentMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate();
    this.createSalaryDetailsForm.get('amount')?.valueChanges.subscribe((value) => {
      if (this.createSalaryDetailsForm.get('paidType')?.value === 'monthly') {
        this.amount = value * 12;
      } else if (this.createSalaryDetailsForm.get('paidType')?.value === 'hourly') {
        this.getManditoryHours().then((minHrsPerDay) => {
          this.manditoryHours = minHrsPerDay;
          this.amount = (this.manditoryHours * value * daysInCurrentMonth * 12);
          this.getSalaryCalculation();
        });
      } else {
        this.amount = value;
      }
      this.getSalaryCalculation();
    });
    this.createSalaryDetailsForm.get('paidType')?.valueChanges.subscribe((value) => {
      const annualCtcValue = this.createSalaryDetailsForm.get('amount')?.value || 0;
      if (value === 'monthly') {
        this.amount = annualCtcValue * 12;
      } else if (value === 'hourly') {
        this.getManditoryHours().then((minHrsPerDay) => {
          this.manditoryHours = minHrsPerDay;
          this.amount = (this.manditoryHours * annualCtcValue * daysInCurrentMonth * 12);
          this.getSalaryCalculation();
        });
      } else {
        this.amount = annualCtcValue;
      }
      this.getSalaryCalculation();
    });

    if (this.id !== null && this.id > '0') {
      this.showEditIcon = true;
    }
  }

  getManditoryHours(): Promise<number> {
    return new Promise((resolve) => {
      this.settingService.getAllSettings(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((data: any) => {
        if (data.status == 200 && data.data != null) {
          resolve(data.data.minHrsPerDay);
        } else {
          resolve(0);
        }
      });
    });
  }

  getSalaryCalculation() {
    this.totalEarning = 0;
    this.earningData = this.resData
      .filter((earning: any) => earning.typeOfStructure === AppConstant.EARNING)
      .map((earning: any) => {
        let monthlyAmount;
        let annualAmount;
        if (earning.value < 100) {
          monthlyAmount = (this.amount / 100 * earning.value) / 12 || 0;
          annualAmount = (this.amount / 100 * earning.value) || 0;
        }
        else {
          monthlyAmount = earning.value || 0;
          annualAmount = earning.value || 0;
        }
        this.totalEarning += monthlyAmount;
        if (earning.type.toLowerCase() === 'bonus') {
          this.totalDeduction += earning.value;
          return {
            "Salary Component": this.convertCamelToTitleCase(earning.type),
            "Calculation Type": this.addPercentageIcon(earning.value),
            "Monthly Amount": this.addPercentageIcon(0),
            "Annual Amount": this.addPercentageIcon(this.formatToTwoDecimals(annualAmount))
          };
        }
        return {
          "Salary Component": this.convertCamelToTitleCase(earning.type),
          "Calculation Type": this.addPercentageIcon(earning.value),
          "Monthly Amount": this.addPercentageIcon(this.formatToTwoDecimals(monthlyAmount)),
          "Annual Amount": this.addPercentageIcon(this.formatToTwoDecimals(annualAmount))
        }
      });
    this.totalDeduction = 0;
    this.deductionData = this.resData
      .filter((deduction: any) => deduction.typeOfStructure === AppConstant.DEDUCTION)
      .map((deduction: any) => {
        const monthlyAmount = (this.amount / 100 * deduction.value) / 12 || 0;
        const annualAmount = (this.amount / 100 * deduction.value) || 0;
        if (deduction.value > 100) {
          this.totalDeduction += deduction.value;
          return {
            "Salary Component": this.convertCamelToTitleCase(deduction.type),
            "Calculation Type": this.addPercentageIcon(deduction.value),
            "Monthly Amount": this.addPercentageIcon(deduction.value),
            "Annual Amount": this.addPercentageIcon(deduction.value * 12 + 100)
          };
        }
        else {
          if (this.amount < 900000 && deduction.type === "providentFund") {
            this.totalDeduction += 1800;
            return {
              "Salary Component": this.convertCamelToTitleCase(deduction.type),
              "Calculation Type": this.addPercentageIcon(1800),
              "Monthly Amount": this.addPercentageIcon(this.formatToTwoDecimals(1800)),
              "Annual Amount": this.addPercentageIcon(this.formatToTwoDecimals(1800 * 12))
            };
          }
          else {
            this.totalDeduction += monthlyAmount;
            return {
              "Salary Component": this.convertCamelToTitleCase(deduction.type),
              "Calculation Type": this.addPercentageIcon(deduction.value),
              "Monthly Amount": this.addPercentageIcon(this.formatToTwoDecimals(monthlyAmount)),
              "Annual Amount": this.addPercentageIcon(this.formatToTwoDecimals(annualAmount))
            };
          }
        }
      });
    this.reimbursementData = this.resData
      .filter((reimbursement: any) => reimbursement.typeOfStructure === AppConstant.REIMBURSEMENT)
      .map((reimbursement: any) => {
        this.totalEarning += reimbursement.value
        return {
          "Salary Component": this.convertCamelToTitleCase(reimbursement.type),
          "Calculation Type": this.addPercentageIcon(reimbursement.value),
          "Monthly Amount": this.addPercentageIcon(this.formatToTwoDecimals(reimbursement.value)),
          "Annual Amount": this.addPercentageIcon(this.formatToTwoDecimals(0))
        }
      });
    this.earningData.push(...this.reimbursementData);
    this.grossPay = this.formatToTwoDecimals(this.getDecimalValue());
  }

  getDecimalValue() {
    const netPay = this.totalEarning - this.totalDeduction;
    if (netPay < 0) {
      return 0;
    }
    return netPay;
  }

  editToggle() {
    this.annaulCtcEditable = !this.annaulCtcEditable;
    if (this.annaulCtcEditable) {
      this.updateAnnualCtc();
    }
  }

  addPercentageIcon(value: any) {
    if (value > 0 && value < 100) {
      return value + " %";
    }
    return "₹ " + value;

  }

  anuualCTCValidation(event: any) {
    return ValidationUtil.anuualCTCValidation(event);
  }

  getSalaryStructure() {
    this.service.getSalaryStructure(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (res: any) => {
        if (res.status === 200) {
          this.resData = res.data;
          this.getSalaryCalculation();
        }
      }
    )
  }

  convertCamelToTitleCase(inputString: string): string {
    if (inputString === 'hra') {
      return inputString.toUpperCase();
    }
    else {
      return inputString.replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
        .replace(/^./, (str) => str.toUpperCase());
    }
  }

  formatToTwoDecimals(value: number): string {
    const floatValue = Number(value);
    const hasDecimal = floatValue % 1 !== 0;
    if (hasDecimal) {
      return floatValue.toFixed(2);
    } else {
      return floatValue.toFixed(1);
    }
  }

  salaryDetails() {
    this.createSalaryDetailsForm = this.fb.group({
      'amount': ['', [Validators.required, Validators.max(200000000)]],
      'paidType': ['yearly', Validators.required]
    });
  }

  updateAnnualCtc() {
    const dto: any = {
      id: this.id,
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      amount: this.createSalaryDetailsForm.get('amount')?.value,
      paidType: this.createSalaryDetailsForm.get('paidType')?.value
    }
    this.employeeDetailsService.addEmployeeSalaryDetails(dto).subscribe((data: any) => {
      if (data.status === 200) {
        this.annaulCtcEditable = false;
        this.toaster.clear();
        this.getAnnualCtc();
        this.toaster.success(data.message);
      }
      else {
        this.toaster.clear();
        this.toaster.error("Select CTC is greater than dedution.");
      }
    })
  }

  getAnnualCtc() {
    if (this.id !== null && this.id > '0') {
      this.service.getEmployeeDetails(sessionStorage.getItem(AppConstant.COMPANY_ID), this.id).subscribe(
        (data: any) => {
          if (data.status === 200) {
            this.annaulCtcEditable = true;
            this.createSalaryDetailsForm.patchValue({
              amount: data.data.employeeSalaryDetail.amount
            })
            this.amount = data.data.employeeSalaryDetail.amount;
            this.getSalaryStructure();
          }
        }
      )
    }
  }

  maxValueValidator(max: number) {
    return (control: { value: any; }) => {
      const value = control.value;
      if (value > max) {
        return { max: true };
      }
      return null;
    };
  }

  allowOnlyNumbers(event: KeyboardEvent) {
    const input = event.key;
    const isNumber = /^\d+$/.test(input);

    if (!isNumber && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(input)) {
      event.preventDefault();
      return;
    }

    const inputValue = parseFloat((<HTMLInputElement>event.target).value + input);
    if (inputValue > 200000000) {
      event.preventDefault();
      return;
    }
    return;
  }

}