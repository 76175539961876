import { Component, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { AppConstant } from 'src/app/constants/app.constants';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';

@Component({
  selector: 'app-employee-list-under-manager',
  templateUrl: './employee-list-under-manager.component.html',
  styleUrls: ['./employee-list-under-manager.component.scss']
})
export class EmployeeListUnderManagerComponent {
  Title: string = 'Employee Goal Details'
  Name: string = 'Dipali Badgujar'
  url: any;
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
  viewEmployeeGoal: any;
  constructor(private router: Router, private renderer: Renderer2, private route: Router, private employeeService: EmployeeDetailsService) { }
  EmpTable: any = FormGroup;
  rowData: any;
  columnDefs: any = [
    { HeaderName: 'id', field: "id", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { HeaderName: 'firstName', field: "firstName", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { HeaderName: 'lastName', field: "lastName", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { HeaderName: 'designation', field: "designation", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { field: "action", cellRenderer: this.actionCellRenderer.bind(this), }
  ];

  public defaultColDef: ColDef = {
    flex: 1,
    minWidth: 150,
  };

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      if (icon === 'delete') {
        this.renderer.setStyle(iconElement, 'margin-right', '25px');
      }
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setAttribute(button, 'MatTooltipModule', 'toolTipText');
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };

    const viewResignationClickHandler = () => {
      this.route.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_EMPLOYEE_APPRISAL + AppConstant.SEPERATOR + params.data.id])
      this.viewEmployeeGoal = params.data.id;
      sessionStorage.setItem(AppConstant.EMPLOYEE_UNDER_MANAGER, this.viewEmployeeGoal);
    };

    createButton('visibility', 'black', viewResignationClickHandler);
    return cell;
  }

  ngOnInit() {
    this.employeeList();
  }

  employeeList() {
    this.employeeService.employeeListUnderManager(this.companyId, this.employeeId).subscribe((data: any) => {
      this.rowData = data.data;
    })
  }
  goToDashboard() {
    this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.DASHBOARD])
  }
}
