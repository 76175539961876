import { Component, OnInit } from '@angular/core';
import { AppConstant } from 'src/app/constants/app.constants';
import { Messages } from 'src/app/constants/messages.constant';
import { SalaryStructureService } from 'src/app/services/salary-structure/salary-structure.service';

@Component({
  selector: 'app-salary-breakup',
  templateUrl: './salary-breakup.component.html',
  styleUrls: ['./salary-breakup.component.scss']
})
export class SalaryBreakupComponent implements OnInit {
  salaryBreakupData: string[] = [];
  displayedColumns: string[] = [];
  pageSize = 10;

  constructor(private salaryStructure: SalaryStructureService) { }

  ngOnInit() {
    this.getSalaryData();
  }

  monthNames: string[] = Messages.MONTHS;

  columnDefs = [
    { headerName: 'Month', field: 'formattedMonth' },
    { headerName: 'Year', field: 'formattedYear' },
    { headerName: 'Base Salary', field: 'baseSalary' },
    { headerName: 'HRA', field: 'hra' },
    { headerName: 'Other Allowance', field: 'otherAllowance' },
    { headerName: 'Professional Tax', field: 'professionalTax' },
    { headerName: 'Total Earning', field: 'totalEarning' },
    { headerName: 'Total Deduction', field: 'totalDeduction' },
    { headerName: 'Net Pay', field: 'netPay' },
  ];

  defaultColDef = {
    flex: 1,
    minWidth: 100,
    sortable: true,
    resizable: true,
  };

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }


  getdisplayedColumns(data: any[]) {
    this.displayedColumns = ['formattedYear', 'formattedMonth'];
    for (const key in data[0]) {
      if (
        data[0].hasOwnProperty(key) &&
        key !== 'month' &&
        key !== 'year' &&
        key !== 'payDate' &&
        !this.displayedColumns.includes(key)
      ) {
        if (data.some(row => row[key] !== 0 && row[key] !== null)) {
          this.displayedColumns.push(key);
        }
      }
    }
  }

  getMonthName(monthNumber: number): string {
    if (monthNumber >= 1 && monthNumber <= 12) {
      return this.monthNames[monthNumber - 1];
    }
    return '';
  }


  getSalaryData() {
    this.salaryStructure.salaryBreakupData(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
      (data: any) => {
        this.salaryBreakupData = data.filter((row: any) => {
          row.paidDays = null;
          for (const key in row) {
            if (row.hasOwnProperty(key) && row[key] !== 0 && row[key] !== null) {
              return true;
            }
          }
          return false;
        });
        this.salaryBreakupData.forEach((row: any) => {
          row.formattedMonth = this.getMonthName(+row['month']);
          row.formattedYear = row['year'];
        });

        this.getdisplayedColumns(this.salaryBreakupData);
      });
  }


  public formatHeaderName(header: string): string {
    return header
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, str => str.toUpperCase());
  }

  public formatNumber(value: number): string {
    return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }
}