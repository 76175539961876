<div class="setting__main_container">
  <div class="setting__heading_container">
    <h3>{{ 'assign_manager' | translate }}</h3>
  </div>
</div>

<form [formControl]="selectFormControl" class="assign-manager-form">
  <mat-form-field class="full-width" appearance="outline" (click)="isManagerAvailable()">
    <mat-icon class="search-icon" matPrefix>search</mat-icon>
    <mat-label>{{'manager' | translate}}</mat-label>
    <input matInput [formControl]="searchControl" class="input-field" [value]="selectedManager"
      (keypress)="validateCharacterNumberAndSpace($event)" (input)="onSearchChange($event)">
    <mat-icon class="dropdown-icon" matSuffix (click)="toggleOptions()">arrow_drop_down</mat-icon>
  </mat-form-field>
  <mat-error *ngIf="isAvailable" class="search-manager">{{ 'manager_not_available' | translate}}</mat-error>

  <div class="overlay-container">
    <div class="custom-mat-option-overlay" *ngIf="showOptions">
      <mat-option *ngFor="let group of filteredManagerList" [value]="group" (click)="onOptionClick(group)">
        {{ group }}
      </mat-option>
    </div>
  </div>

  <mat-form-field class="full-width search-employee" appearance="outline" (click)="isEmployeeFound()">
    <mat-icon class="search-icon" matPrefix>search</mat-icon>
    <mat-label>{{'employee' | translate}}</mat-label>
    <input matInput [formControl]="searchEmployeeControl" class="input-field" [value]="selectedEmployee"
      (keypress)="validateCharacterNumberAndSpace($event)" (input)="onEmployeeSearchChange($event)">
    <mat-icon class="dropdown-icon" matSuffix (click)="toggleEmployeeOptions()">arrow_drop_down</mat-icon>
  </mat-form-field>
  <mat-error *ngIf="isEmployeeAvailable" class="search-manager">{{ 'employee_not_available' | translate }}</mat-error>

  <div class="overlay-container">
    <div class="custom-mat-option-overlay" *ngIf="showEmployeeOptions">
      <mat-option *ngFor="let group of filteredEmployeeList" [value]="group" (click)="onOptionEmployeeClick(group)">
        {{ group }}
      </mat-option>
    </div>
  </div>
  <button class="assign-manager" (click)="assignManagerApi()">{{ 'assign_manager' | translate }}</button>
  <div>
    <button class="cancel cancel-btn" (click)="hideAssignManagerPopup()" type="reset">{{'cancel' | translate}}</button>
  </div>
</form>

<div class="confirmation-dialog" *ngIf="showConfirmationDialog">
  <div class="dialog-content">
    <p>{{"change_manager"|translate}}</p>
    <div class="dialog-buttons">
      <button class="btn-confirm" (click)="confirmChange()">{{'yes'|translate}}</button>
      <button class="no-btn" type="reset" (click)="managerNotChange()">{{"no"|translate}}</button>
    </div>
  </div>
</div>
<app-loader></app-loader>