<div class="exit-container">
    {{employeeName}} {{lastName}}{{"s" | translate}} {{"exit_details" |translate}}
    <mat-icon matTooltip="{{'edit'|translate}}" *ngIf="isAlreasdyExit" class="edit-button" (click)="toggleEdit()">{{
        'edit' | translate }}</mat-icon>
</div>
<div class="container"></div>
<div class="exit-form-container">
    <div class="exit-form">
        <form [formGroup]="exitsForm" class="exit-field">
            <div>
                <mat-form-field appearance="outline" class="full-width">
                    <mat-label>{{ 'last_working_day' | translate }}</mat-label>
                    <input matInput type="text" class="input" formControlName="lastWorkingDay" matInput
                        [matDatepicker]="picker" (keypress)="$event.preventDefault()" [readonly]="isReadOnly"
                        [min]="minDate" required (dateInput)="onLastWorkingDateChange($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="isReadOnly"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error class="error" *ngIf="exitsForm.get('lastWorkingDay')?.errors?.['required']">
                        {{ 'last_working_day_required' | translate }}
                    </mat-error>
                </mat-form-field>
                
                <mat-form-field appearance="outline" class="exits_reason">
                    <mat-label>{{ 'exits_reason' | translate }}</mat-label>
                    <mat-select formControlName="reason" class="country-code-select">
                        <mat-option [disabled]="isReadOnly" *ngFor="let reasonOfExit of reasonOfExits"
                            [value]="reasonOfExit.name">
                            {{ reasonOfExit.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div class="exit-field">
            
 <mat-form-field appearance="outline" class="full-width">
    <mat-label>{{ 'final_settlement_date' | translate }}</mat-label>
    <input matInput type="text" class="mat-input" formControlName="finalSettlementDate" matInput
        [readonly]="isReadOnly" [matDatepicker]="picker1" [min]="minFinalSettlementDate"
        [max]="maxFinalSettlementDate" (keypress)="$event.preventDefault()" required>
    <mat-datepicker-toggle matSuffix [for]="picker1" [disabled]="isReadOnly"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
    <mat-error class="error" *ngIf="exitsForm.get('finalSettlementDate')?.errors?.['required']">
        {{ 'final_settlement_date_ts required' | translate }}
    </mat-error>
</mat-form-field>

                <mat-form-field appearance="outline" class="exits_reason">
                    <mat-label class="formLabel">{{'personal_email'|translate}}</mat-label>
                    <input matInput type="text" class="input" [value]="employeeEmail" formControlName="alternateEmail"
                        readonly />
                </mat-form-field>
            </div>
            <div class="exit-field">
                <mat-form-field appearance="outline" class="note">
                    <mat-label>{{ 'remark' | translate }}</mat-label>
                    <input matInput [readonly]="isReadOnly" formControlName="remark"
                        (keypress)="validateCharacterNumberAndSpace($event)">
                </mat-form-field>
            </div>
            <mat-card class="note-content">
                <div><b>{{'note'|translate}}</b>{{'exit-note' | translate}}</div>
            </mat-card>
            <div class="form-button">
                <button class="button-right" (click)="process()">{{'procced' | translate}}</button>
                <button class="cancel-btn" type="reset">{{'cancel' | translate}}</button>
            </div>

        </form>
        <div class="overlay" *ngIf="showPopUP">
            <div class="popup">
                <div mat-dialog-content>
                    <div class="icon-content-wrapper">
                        <mat-icon>error</mat-icon>
                        <div>
                            <span class="proceed-content">{{'employee_exit_process' | translate}} {{employeeName}}
                                {{lastName}}.</span><br>
                            <span class="proceed-confirmation">{{'shure_proceed' | translate}}</span>
                        </div>
                    </div>
                </div>

                <div mat-dialog-actions class="buttons">
                    <button class="yes-button" (click)="proceed()">Yes</button>
                    <button class="no-button" mat-stroked-button (click)="closePopup()">No</button>
                </div>
            </div>
        </div>
    </div>

    <div class="profile-container">
        <div class="profile-info">
            <div class="employee-image">
                <img class="avatar mat-elevation-z8" *ngIf="employeeImage" [src]="employeeImage" alt="Employee Image" />
            </div>
            <div class="profile-details">
                <p>{{ 'date_of_joinings' | translate }} {{ doj | date:'dd / M / yyyy' }}</p>
                <p>{{"employee_designations" | translate}} {{designationName}}</p>
                <p>{{"employee_department"|translate}}{{departmentName}}</p>
                <p>{{employeeName}} {{lastName}} ({{'empid'|translate}}{{employeeId}})</p>
            </div>
        </div>
    </div>
</div>
<app-loader></app-loader>