import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { LastSummeryData } from 'src/app/model/tax-declaration/tax-declaration.model';
import { LoaderService } from 'src/app/services/loader/loader.service';
import { TaxDeclarationServiceService } from 'src/app/services/tax-declaration-service/tax-declaration-service.service';
import { ConfirmationPopupComponent } from 'src/app/shared/confirmation-popup/confirmation-popup.component';
import { SelectRegimeDialogComponent } from 'src/app/shared/select-regime-dialog/select-regime-dialog.component';

@Component({
  selector: 'app-declared-form',
  templateUrl: './declared-form.component.html',
  styleUrls: ['./declared-form.component.scss']
})
export class DeclaredFormComponent implements OnInit {

  rowData: LastSummeryData[] = [];
  submitionWindow: Boolean = true;
  houseRentDetails: any;
  selectedFiles: any = {};
  fileErrors: { [key: string]: string } = {};
  comments: { [key: string]: string } = {};

  constructor(private toastrService: ToastrService, private loader: LoaderService, private taxDeclarationService: TaxDeclarationServiceService, private dialog: MatDialog, private toasterService: ToastrService, private router: Router, private taxDeclarationSerice: TaxDeclarationServiceService) { }

  ngOnInit(): void {
    this.submitionWindow = new Date().getMonth() === 11;
    this.getAllSectionList();
    this.getHouseRentDetails();
  }

  getHouseRentDetails() {
    this.taxDeclarationSerice.getHouseRentDetails(sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.houseRentDetails = data.data;
        }
      }
    )
  }

  goToFormPage() {
    this.taxDeclarationSerice.getDeclarationService().subscribe(
      (data: any) => {
        if (data.status === 200) {
          const dialogRef = this.dialog.open(SelectRegimeDialogComponent);
          dialogRef.afterClosed().subscribe(result => {
            if (result === 'new') {
              const dialogRef = this.dialog.open(ConfirmationPopupComponent, {
                disableClose: true,
                data: 'After selecting regime you cannot be apply for Regime.'
              });
              dialogRef.afterClosed().subscribe(result => {
                if (result.data) {
                  this.loader.show();
                  this.taxDeclarationService.submitDeclaration("newRegime").subscribe(
                    (data: any) => {
                      if (data.status === 200) {
                        this.loader.hide();
                        this.toastrService.clear();
                        this.toastrService.success(data.message);
                        this.router.navigate([AppConstant.EMPLOYEE_ROLE + AppConstant.SEPERATOR + AppConstant.VIEW_TAX_DECLARATION + AppConstant.SEPERATOR + AppConstant.DECLARED_FORM]);
                      } else {
                        this.loader.hide();
                        this.toastrService.clear();
                        this.toastrService.error(data.message);
                      }
                    }
                  );
                }
              });
            } else if (result === 'old') {
              this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_TAX_DECLARATION + AppConstant.SEPERATOR + AppConstant.START_FRESH]);
            }
          });
        } else {
          this.toasterService.clear();
          this.toasterService.error(data.message);
        }
      })
  }

  convertLabel(label: string): string {
    let formattedLabel = label.replace(/([a-z])([A-Z])/g, '$1 $2');
    formattedLabel = formattedLabel.replace(/([a-zA-Z\s])([0-9])/g, '$1 $2');
    formattedLabel = formattedLabel.split(' ').map(word =>
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    ).join(' ');
    return formattedLabel;
  }

  onFileSelected(event: Event, item: any, subItem: any) {
    const input = event.target as HTMLInputElement;
    const key = `${item.name}-${subItem.name}`;
    this.fileErrors[key] = '';
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const maxSize = 2 * 1024 * 1024;
      if (file.size > maxSize) {
        this.fileErrors[key] = 'File size exceeds 2MB.';
        return;
      }
      const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png'];
      if (!allowedTypes.includes(file.type)) {
        this.fileErrors[key] = 'Invalid file type. Only PDF and image files are allowed.';
        return;
      }
      this.selectedFiles[key] = file.name;
    } else {
      this.fileErrors[key] = 'No file selected.';
    }
  }

  getAllSectionList() {
    this.taxDeclarationSerice.getDeclaredInvestmentDetails(sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
      (data: any) => {
        this.rowData = data.data;
      });
  }

  deleteDeclaration() {
    this.taxDeclarationSerice.deleteDeclaration().subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.getAllSectionList();
          this.toasterService.clear();
          this.toasterService.success(data.message);
        } else {
          this.toasterService.clear();
          this.toasterService.success(data.message);
        }
      });
  }

  isAfter15th(): boolean {
    const today = new Date();
    return today.getDate() < 15;
  }

  submitPOI() {
    const formData = new FormData();
    formData.append('comments', JSON.stringify(this.comments));
    for (const key in this.selectedFiles) {
      if (this.selectedFiles.hasOwnProperty(key)) {
        const fileName = this.selectedFiles[key];
        const file = new File([fileName], fileName);
        formData.append(key, file, fileName);
      }
    }
    this.taxDeclarationSerice.submitPOI(formData).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.toasterService.success(data.message);
          this.getAllSectionList();
        } else {
          this.toasterService.error(data.message);
        }
      }
    );
  }



}
