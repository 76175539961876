import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { TaskManagementService } from 'src/app/services/task-management/task-management.service';

@Component({
  selector: 'app-task-management',
  templateUrl: './task-management.component.html',
  styleUrls: ['./task-management.component.scss']
})
export class TaskManagementComponent implements OnInit {
  boardBased: boolean = false;
  excelBased: boolean = false;
  showClickAccessTable: boolean = false;
  role = sessionStorage.getItem(AppConstant.LOGIN_PAGE);
  borderColors = ['#C0B6F2', '#FFBDAD', '#B3D4FF', '#B3F5FF'];
  dashboard: any[] = [];
  selectedTab: string = 'worked_on';
  assignedToMe: any[] = [];
  workedOnYesterday: any[] = [];
  workedOnLastWeek: any[] = [];

  constructor(private router: Router, private taskService: TaskManagementService,) { }

  ngOnInit(): void {
    this.getDashboardList();
    this.getWorkedOnData();
  }

  getWorkedOnData() {
    this.taskService.getWorkedOnData().subscribe(
      (workedOnData: any) => {
        if (workedOnData.status === 200) {
          this.assignedToMe = workedOnData.data.assignToMe;
          this.workedOnYesterday = workedOnData.data.workedOnYesterday,
            this.workedOnLastWeek = workedOnData.data.workedOnLastWeek
        }
      }
    )
  }

  gotToTask(task: any) {
    this.router.navigate([AppConstant.TASK_MANAGEMENT_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_TASK],
      {
        queryParams: {
          sprintId: task.sprintId,
          taskId: task.taskId,
          dashboardId: task.dashboardId,
          taskName: task.taskName
        }
      }
    )
  }

  getDashboardList() {
    this.taskService.getDashboardList().subscribe(
      (dashboardList: any) => {
        if (dashboardList.status === 200) {
          this.dashboard = dashboardList.data;
        }
      }
    )
  }

  showProjectDetails(project: any) {
    sessionStorage.setItem('selectedProject', project);
    this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.BOARD_TRACKING]);
  }

  selectBoardBased() {
    this.router.navigate([AppConstant.TASK_MANAGEMENT_SIDENAV + AppConstant.SEPERATOR + AppConstant.PROJECT_DASHBOARD]);
  }

  showQuickAccessTable() {
    this.showClickAccessTable = true;
  }

  selectExcelBased() {
    this.boardBased = false;
  }

  getBorderColor(index: number): string {
    return `20px solid ${this.borderColors[index % this.borderColors.length]}`;
  }

  getSelectedHistory(filterOption: string) {
    this.selectedTab = filterOption;
  }

  goToSprintBoard(sprint: any, board: any) {
    this.router.navigate([AppConstant.TASK_MANAGEMENT_SIDENAV + AppConstant.SEPERATOR + AppConstant.BOARD_TRACKING + AppConstant.SEPERATOR + board.dashboardId + AppConstant.SEPERATOR + sprint.id])
  }
}
