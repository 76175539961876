<mat-toolbar>
    <button (click)="isExpanded = !isExpanded">
        <mat-icon>menu</mat-icon>
    </button>
    <h1>{{ 'task-management' | translate }}</h1>
    <div class="header-button">
        <div class="header-button-quick-access" [matMenuTriggerFor]="quickAccess">
            <span>{{"quick_access" | translate}}</span>
            <mat-icon>arrow_drop_down</mat-icon>
        </div>
        <div>
            <button class="cancel-btn" [matMenuTriggerFor]="menu">{{"create" | translate}}</button>
        </div>
        <button [matMenuTriggerFor]="profile" matTooltip="Profile">
            <span class="material-icons">account_circle</span>
        </button>
    </div>

    <mat-menu #profile="matMenu">
        <button mat-menu-item (click)="logout()">
            <mat-icon class="logoutIcon">logout</mat-icon>
            <span class="profile-menu">{{ 'logout' | translate }}</span>
        </button>
    </mat-menu>
    <mat-menu #quickAccess="matMenu">
        <button *ngFor="let task of quickAccessList" mat-menu-item (click)="quickAccessSprint(task)">
            <span class="menu">{{ task.taskName }}</span>
        </button>
    </mat-menu>
    <mat-menu #menu="matMenu">
        <button *ngIf="isManager || adminRole" mat-menu-item (click)="addBoard()">
            <mat-icon>developer_board</mat-icon>
            <span class="menu">{{ 'add_board' | translate }}</span><br>
            <span class="board-content">{{ 'add_board_content' | translate }}</span>
        </button>
        <button mat-menu-item (click)="addTask()">
            <mat-icon >add</mat-icon>
            <span class="menu">{{ 'add_task' | translate }}</span><br>
            <span class="board-content">{{'add_task_content'|translate}}</span>
        </button>
    </mat-menu>
</mat-toolbar>

<mat-sidenav-container class="container" autosize>
    <mat-sidenav #sidenav class="sidenav" mode="side" opened="true">
        <mat-nav-list>
            <mat-list-item (click)="backToDashboard()" [routerLinkActive]="['is-active']"
                [matTooltip]="isExpanded ? '' : ('back' | translate)" matTooltipPosition="right">
                <mat-icon class="svg-back-icon" svgIcon="white-back-arrow"></mat-icon>
                <span *ngIf="isExpanded" class="back-button">{{ 'back' | translate }}</span>
            </mat-list-item>

            <mat-list-item routerLink="/task-management-sidenav/home" [routerLinkActive]="['is-active']"
                [matTooltip]="isExpanded ? '' : ('home' | translate)" matTooltipPosition="right">
                <mat-icon class="icon">home</mat-icon>
                <span *ngIf="isExpanded" class="full-width">{{ 'home' | translate }}</span>
            </mat-list-item>

            <!-- <mat-list-item routerLink="/task-management-sidenav/projects" [routerLinkActive]="['is-active']"
                [matTooltip]="isExpanded ? '' : ('projects' | translate)" matTooltipPosition="right">
                <mat-icon class="icon">card_travel</mat-icon>
                <span *ngIf="isExpanded" class="full-width">{{ 'projects' | translate }}</span>
            </mat-list-item> -->

            <mat-list-item routerLink="/task-management-sidenav/board-tracking" [routerLinkActive]="['is-active']"
                [matTooltip]="isExpanded ? '' : ('dashboards' | translate)" matTooltipPosition="right">
                <mat-icon class="icon">dashboard</mat-icon>
                <span *ngIf="isExpanded" class="full-width">{{ 'dashboards' | translate }}</span>
            </mat-list-item>

            <mat-list-item routerLink="/task-management-sidenav/view-backlog" [routerLinkActive]="['is-active']"
                [matTooltip]="isExpanded ? '' : ('backlog' | translate)" matTooltipPosition="right">
                <mat-icon class="icon">library_books</mat-icon>
                <span *ngIf="isExpanded" class="full-width">{{ 'backlog' | translate }}</span>
            </mat-list-item>

            <mat-list-item routerLink="/task-management-sidenav/view-sprint" [routerLinkActive]="['is-active']"
                [matTooltip]="isExpanded ? '' : ('sprints' | translate)" matTooltipPosition="right">
                <mat-icon class="icon">all_inclusive</mat-icon>
                <span *ngIf="isExpanded" class="full-width">{{ 'sprints' | translate }}</span>
            </mat-list-item>

            <mat-list-item routerLink="/task-management-sidenav/view-report" [routerLinkActive]="['is-active']"
                [matTooltip]="isExpanded ? '' : ('reports' | translate)" matTooltipPosition="right">
                <mat-icon class="icon">trending_up</mat-icon>
                <span *ngIf="isExpanded" class="full-width">{{ 'reports' | translate }}</span>
            </mat-list-item>
        </mat-nav-list>
    </mat-sidenav>
    <router-outlet></router-outlet>
</mat-sidenav-container>