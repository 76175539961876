import { Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { EmployeeData } from 'src/app/model/employeeDetails';
import { AttendanceService } from 'src/app/services/attendance/attendance.service';

@Component({
  selector: 'app-managers-employee-list',
  templateUrl: './managers-employee-list.component.html',
  styleUrls: ['./managers-employee-list.component.scss']
})
export class ManagersEmployeeListComponent implements OnInit {
  employeeData: EmployeeData[] = [];
  hrColDef: any;

  visibilityTooltip = 'Attendance';
  calendarMonthTooltip = 'Attendance';
  historyTooltip = 'Leave History';
  constructor(private attendanceService: AttendanceService, private renderer: Renderer2, private router: Router) {

  }
  goToDashboard() {
    if (sessionStorage.getItem(AppConstant.MANAGER_SIDENAV) === AppConstant.EMPLOYEE_ROLE) {
      this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.DASHBOARD])
      sessionStorage.setItem(AppConstant.SHOW_EMPLOYEE_LEAVE, 'false');
      sessionStorage.removeItem(AppConstant.IS_MANAGER);
    }
    else {
      this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.DASHBOARD])
    }
  }

  hrTableCol = [
    { field: "id", width: 120, unSortIcon: true, floatingFilter: true },
    { field: "departmentName", width: 210, unSortIcon: true, floatingFilter: true },
    { field: "firstName", floatingFilter: true, unSortIcon: true, width: 190 },
    { field: "lastName", floatingFilter: true, unSortIcon: true, width: 190 },
    { field: "workEmail", floatingFilter: true, unSortIcon: true, width: 250 },
    { field: "Action", cellRenderer: this.actionCellRenderer.bind(this), width: 230 }
  ];

  ngOnInit() {
    this.hrColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.getEmployeeList()
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  getEmployeeList() {
    this.attendanceService.getEmployeeSalaryList(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.ADMIN ? null : sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
      (data: any) => {
        this.employeeData = data.data.map((employee: any) => {
          return {
            id: employee.id,
            departmentName: employee.employeeBasicDetailsModel.departmentModel.departmentName,
            firstName: employee.employeeBasicDetailsModel.firstName,
            lastName: employee.employeeBasicDetailsModel.lastName,
            workEmail: employee.employeeBasicDetailsModel.workEmail,
            annualCtc: employee.annualCtc,
          };
        });
      }
    );
  }

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');
    const createButton = (icon: string, color: string, clickHandler: () => void, tooltipText: string) => {
      const button = this.renderer.createElement('button');
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.listen(button, 'click', clickHandler);
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.setStyle(iconElement, 'font-size', 'large');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setStyle(iconElement, 'color', color);
      if (icon === 'delete') {
        this.renderer.setStyle(iconElement, 'margin-right', '15px');
      }
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.setStyle(button, 'border', 'none');
      this.renderer.setAttribute(button, 'MatTooltipModule', 'toolTipText');
      this.renderer.setStyle(button, 'background-color', 'transparent');
      this.renderer.appendChild(cell, button);
    };
    const attendanceIconClickHandler = () => {
      sessionStorage.setItem(AppConstant.SHOW_EMPLOYEE_LEAVE, 'true');
      sessionStorage.setItem('viewAttendanceButton', 'true');
      const navigationExtras: NavigationExtras = {
        queryParams: {
          employeeId: params.data.id
        }
      }
      this.router.navigate([
        AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_ATTENDANCE_DETAILS,
        { id: params.data.id }
      ], navigationExtras);
    };
    const leaveIconClickHandler = () => {
      sessionStorage.setItem(AppConstant.SHOW_EMPLOYEE_LEAVE, 'true');
      this.router.navigate([
        AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.LEAVE_HISTORY,
        { id: params.data.id }
      ]);
    };
    const viewIconClickHandler = () => {
      this.viewEmployeeDetails(params.data.id);
    };
    createButton('visibility', 'green', viewIconClickHandler, this.visibilityTooltip);
    createButton('calendar_month', 'blue', attendanceIconClickHandler, this.calendarMonthTooltip);
    createButton('history', 'gray', leaveIconClickHandler, this.historyTooltip);
    return cell;
  }

  viewEmployeeDetails(employeeId: any): void {
    this.router.navigate([`${AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_EMPLOYEE}/${employeeId}`]);
  }

  ViewEmployeeLeaveDetails() {
    this.router.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.LEAVE_HISTORY])
  }
}
