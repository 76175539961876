import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from "rxjs";
import { AppConstant } from 'src/app/constants/app.constants';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { PayRunService } from 'src/app/services/pay-run/pay-run.service';
import { ToDoTaskServiceService } from 'src/app/services/to-do-task-service/to-do-task-service.service';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit {

  private query = new BehaviorSubject({
    measures: ["Orders.count"],
    timeDimensions: [{ dimension: "Orders.createdAt", granularity: "month", dateRange: "This year" }],
    dimensions: ["Orders.status"],
    filters: [{ dimension: "Orders.status", operator: "notEquals", values: ["completed"] }]
  });
  cards: any[] = [];
  isEmployee: any;
  currentUserId = AppConstant.ROLE;
  progressValue: number = 0;
  progressColor: string = 'red';
  remainingValue: number;
  paid: number;
  totalAnnualTax: number = 0;
  employeesNetPay: number | undefined;
  paymentDate: number | undefined;
  noOfEmployee: number | undefined;
  epf: number | undefined;
  esi: number | undefined;
  taxDeduction: number | undefined;
  activeEmployees: number | undefined;
  adminName: number | undefined;
  showNotification: boolean = true;
  companyId: any;
  showPayRun: boolean = true;
  data: any;
  admin = AppConstant.SIDENAV;
  activeEmployee = AppConstant.ACTIVE_EMPLOYEES;
  showTasks: boolean = false;
  messagesList: any[] = [];
  employeeName: any;
  payRunSubscription: boolean = false;
  taskInProgress: boolean = false;

  constructor(private payRun: PayRunService, private service: ToDoTaskServiceService, private toastrService: ToastrService, private router: Router, private employeeService: EmployeeDetailsService,) {
    this.remainingValue = this.totalAnnualTax - this.progressValue;
    this.paid = this.progressValue;
  }

  ngOnInit() {
    this.getEmployeeImage();
    this.companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
    this.showPayRun = true;
    this.getFuturePayRun();
    this.initRole();
    this.query.subscribe(data => {
      this.cards[0] = {
        chart: "bar", cols: 1, rows: 1,
        query: data
      };
    });
    this.getTaskList();
  }

  getTaskList() {
    if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.HR_ROLE) {
      this.service.getTaskList(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.ADMIN_ID)).subscribe(
        (data: any) => {
          this.messagesList = data.data;
          if (this.messagesList.length > 0) {
            this.showTasks = true;
          }
          else {
            this.showTasks = false;
          }
        }
      )
    }
  }

  deleteMessage(id: any) {
    this.service.deleteById(id).subscribe(
      (data: any) => {
        this.messagesList = this.messagesList.filter(message => message.id !== id);
        if (this.messagesList.length === 0) {
          this.showTasks = false;
        }
      }
    )
  }

  getEmployeeImage() {
    if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.EMPLOYEE_ROLE) {
      this.employeeService.getEmployeeImage(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
        (res: any) => {
          this.employeeName = res.firstName + " " + res.lastName;
          sessionStorage.setItem('employeeName', this.employeeName);
        });
    }
  }

  closeNotification() {
    this.showNotification = false;
  }

  initRole() {
    sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) == AppConstant.EMPLOYEE_ROLE ? this.isEmployee = sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) : ''
  }

  getFuturePayRun() {
    if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === AppConstant.EMPLOYEE_ROLE) {
      return;
    }
    this.payRun.getFuturePayRun(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((data: any) => {
      this.data = data.data;
      if (data.status === 404) {
        this.toastrService.clear();
        this.toastrService.error(data.message)
      }
      this.employeesNetPay = this.data.employeesNetPay;
      this.taxDeduction = this.data.taxDeduction;
      this.epf = this.data.epf;
      this.esi = this.data.esi;
      this.adminName = this.data.adminName;
      this.activeEmployees = this.data.activeEmployees;
      this.paymentDate = this.data.paymentDate;
      this.noOfEmployee = this.data.noOfEmployee;
    });
  }

  goToPayRun() {
    if (JSON.parse(sessionStorage.getItem(AppConstant.SUBSCRIPTION_MODULE_ACCESS) || '')?.payRun) {
      this.showPayRun = false;
      this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.PAY_RUN]);
    }
    else {
      this.toastrService.clear();
      this.toastrService.error(AppConstant.BUY_NEW_SUBSCRIPTION);
    }
  }

  refresh() {
    this.taskInProgress = true;
    this.service.refresh(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.ADMIN_ID)).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.getTaskList();
        }
        this.taskInProgress = false;

      })

  }
}