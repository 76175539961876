import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/enviroment';
import { API_END_POINTS } from '../../config/api-endpoint.config';

@Injectable({
  providedIn: 'root'
})
export class SendOtpService {

  constructor(private http: HttpClient) { }
  sendOtp(sendOtpRequestBody: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.SENDOTP, sendOtpRequestBody).pipe(
      map(res => { return res })
    )
  }

  otpVarification(sendOtpRequestBody: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.CHECK_OTP, sendOtpRequestBody).pipe(
      map(res => { return res })
    )
  }
  resetPassword(sendOtpRequestBody: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.CHANGE_PASSWORD, sendOtpRequestBody).pipe(
      map(res => { return res })
    )
  }

}