import { Component, OnInit } from '@angular/core';
import { PayRunService } from 'src/app/services/pay-run/pay-run.service';
import { AppConstant } from 'src/app/constants/app.constants';
import { Messages } from 'src/app/constants/messages.constant';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {
  barChartData: any;
  barChartOptions: any;

  constructor(private service: PayRunService) { }

  ngOnInit() {
    this.service.getPayRunCostSummary(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (response: any) => {
        const data = response.data;
        this.barChartData = {
          series: [
            {
              name: AppConstant.NETPAY,
              data: data
            }
          ],
          chart: {
            id: 'PayrollCostSummary',
            type: 'bar',
            height: 350,
        }
          ,
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: Messages.MONTH
          },
          yaxis: {
            title: {
              text: AppConstant.NETPAY
            }
          },
          colors: ['#357ddd']
        };

        this.barChartOptions = {
          series: this.barChartData.series,
          chart: this.barChartData.chart,
          plotOptions: this.barChartData.plotOptions,
          dataLabels: this.barChartData.dataLabels,
          xaxis: this.barChartData.xaxis,
          yaxis: this.barChartData.yaxis,
          colors: this.barChartData.colors
        };
      }
    );
  }

  

}
