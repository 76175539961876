<div class="container">
    <h3 class="tittle">{{ 'it_declaration' | translate }}</h3>
    <mat-card class="horizontal-card">
        <div class="profile">
            <div class="name">
                <span class="circle-icon">{{ getInitials(employeeDetails?.employeeName) }}</span>
                <div class="employee-details">
                    <h2 class="employee-name">{{ employeeDetails?.employeeName }}</h2>
                    <mat-divider class="divider"></mat-divider>
                    <div class="sub-profile">
                        <div>
                            <div class="sub-profile-heading-name"><span>{{ 'date_of_joining' | translate }}</span></div>
                            <strong>{{ employeeDetails?.dateOfJoining }}</strong>
                        </div>
                        <div>
                            <div class="sub-profile-heading-name"><span>{{ 'date_of_birth' | translate }}</span></div>
                            <strong>{{ employeeDetails?.dateOfBirth }}</strong>
                        </div>
                        <div>
                            <div class="sub-profile-heading-name"><span>{{ 'gender' | translate }}</span></div>
                            <strong>{{ employeeDetails?.gender }}</strong>
                        </div>
                        <div>
                            <div class="sub-profile-heading-name"><span>{{'pan' | translate }}</span></div>
                            <strong>{{ employeeDetails?.pan }}</strong>
                        </div>
                        <div>
                            <div class="sub-profile-heading-name"><span>{{ 'employement_status' | translate }}</span>
                            </div>
                            <strong>{{ employeeDetails?.employementStatus ? 'Active' : 'Inactive' }}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card>

    <router-outlet></router-outlet>

</div>