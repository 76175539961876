<div class="policy-container">
    <h2>{{'shipping_delivery_policy' | translate}}:</h2>

    <h4>Last updated: March 4th, 2024</h4>

    <p>For International buyers, orders are shipped and delivered through registered international courier companies
        and/or International speed post only. For domestic buyers, orders are shipped through registered domestic
        courier companies and /or speed post only. Orders are shipped within 8-14 days or as per the delivery date
        agreed at the time of order confirmation and delivering of the shipment subject to Courier Company / post office
        norms. IntegrationWings Consulting India Private Limited is not liable for any delay in delivery by the courier
        company / postal authorities and only guarantees to hand over the consignment to the courier company or postal
        authorities within 8-14 days from the date of the order and payment or as per the delivery date agreed at the
        time of order confirmation. Delivery of all orders will be to the address provided by the buyer. Delivery of our
        services will be confirmed on your mail ID as specified during registration. For any issues in utilizing our
        services you may contact our helpdesk on +91 9325394926 or support&#64;integrationwings.com</p>
</div>