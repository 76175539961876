<div class="main-container">
    <div class="header">
        <h3>{{'selected_sprint_name'|translate}}</h3>
        <div class="header-button">
            <div class="dashboard-dropdown">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label class="formLabel"> {{ 'dashboards' | translate }}</mat-label>
                    <mat-select class="select-mat-input" [(ngModel)]="selectedBoardId">
                        <mat-option *ngFor="let board of boardList" (click)="getDashboard(board)"
                            [value]="board.dashboardId">
                            {{ board.boardName }}
                        </mat-option>
                        <mat-divider class="divider"></mat-divider>

                        <mat-option (click)="viewAllDashboards()">
                            <span class="add-dashboard">{{'view_all_boards'|translate}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="sprint-dropdown">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label class="formLabel">{{ 'select_sprint' | translate }}</mat-label>
                    <mat-select class="select-mat-input" [(ngModel)]="selectedSprintId">
                        <mat-option *ngFor="let sprint of sprints" [value]="sprint.sprintId"
                            (click)="getSprint(sprint)">
                            {{ sprint.sprintName }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="button">
                <button class="export-btn" mat-button
                    [matMenuTriggerFor]="menu"><mat-icon>file_upload</mat-icon>{{'export' |
                    translate}}</button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="downloadExcel()">{{'download_as_excel' | translate}}</button>
                    <button mat-menu-item (click)="downloadCSV()">{{'download_as_csv' | translate}}</button>
                </mat-menu>
            </div>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div class="card-container" cdkDropListGroup>
        <div class="phases-container" *ngFor="let phase of phases">
            <div class="phase-heading">{{ phase.name }}</div>
            <div cdkDropList [cdkDropListData]="phase.cardData" class="phase-list"
                (cdkDropListDropped)="drop($event, phase)">
                <div *ngFor="let item of phase.cardData">
                    <div class="phase-box" cdkDrag (click)="openTaskDialog(item)">
                        <div class="card-content">
                            <div class="card-details">
                                <div class="card-title">{{ item.summary }}</div>

                                <div class="card-description">{{ item.description }}</div>
                                <div class="card-description">{{'story_point'|translate}}: <b>{{
                                        item.storyPointEstimate}}</b></div>
                            </div>
                            <div class="assignee-circle" matTooltip="{{'assignee'|translate}}: {{ item.assigneeName }}">
                                {{ getInitials(item.assigneeName) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>