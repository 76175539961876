import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { UserSubscriptionModel } from 'src/app/model/attendance-model/subscription.model';
import { UserSubscriptionService } from 'src/app/services/userSubscription/user-subscription.service';

@Component({
  selector: 'app-user-subscription-view',
  templateUrl: './user-subscription-view.component.html',
  styleUrls: ['./user-subscription-view.component.scss']
})
export class UserSubscriptionViewComponent {
  subscriptionCardDetails: UserSubscriptionModel = {
    id: '',
    name: '',
    price: '',
    type: '',
    subscribeDate: '',
    expireDate: '',
  };

  subscriptionHistoryData!: UserSubscriptionModel[];

  columnDefs = [
    { headerName: 'Id', field: 'id', width: 120,floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true},
    { headerName: 'Name', field: 'name', width: 250,floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true},
    { headerName: 'Price', field: 'price', width: 250,floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true},
    { headerName: 'Type', field: 'type', width: 250,floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true},
    { headerName: 'Subscribe Date', field: 'subscribeDate', width: 220,floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true},
    { headerName: 'Expire Date', field: 'expireDate', width: 220,floatingFilter: true, unSortIcon: true, sortable: true, filter: true, resizable: true},
  ];

  constructor(private subscriptionService: UserSubscriptionService, private router: Router) { }

  ngOnInit() {
    this.getSubscriptionDetails();
    this.getSubscriptionDetailsList();
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  getSubscriptionDetails() {
    this.subscriptionService.getUserSubscriptionDetails(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.subscriptionCardDetails.id = data.data.id
          this.subscriptionCardDetails.name = data.data.subscriptionName
          this.subscriptionCardDetails.price = data.data.price
          this.subscriptionCardDetails.type = data.data.subscriptionType
          this.subscriptionCardDetails.subscribeDate = this.formatDate(data.data.subscribeDate);
          this.subscriptionCardDetails.expireDate = this.formatDate(data.data.expireDate);
        }
      })
  }

  getSubscriptionDetailsList() {
    this.subscriptionService.getSubscriptionList(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.subscriptionHistoryData = data.data.map((subscription: any) => ({
            id: subscription.id,
            name: subscription.subscriptionName,
            price: subscription.price,
            type: subscription.subscriptionType,
            subscribeDate: this.formatDate(subscription.subscribeDate),
            expireDate: this.formatDate(subscription.expireDate),
          }));
        }
      })
  }

  formatDate(inputDate: string): string {
    return new Date(inputDate).toLocaleDateString('en-GB');
  }

  buySubscription() {
    this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.SUBSCRIPTION_PLAN])
  }

}
