<div class="salary-details">
  <div layout="row" layout-xs="column" flex>
    <form name="createSalaryDetailsForm" [formGroup]="createSalaryDetailsForm" class="form-content">
      <div>
        <div class="salary-details-heading">
          <div class="form-field-gap2">
            <mat-form-field class="input-width" appearance="outline">
              <span class="input-currency"><i class="fa fa-rupee"></i></span>
              <input matInput type="text" class="ruppe-input">
            </mat-form-field>

            <mat-form-field *ngIf="!annaulCtcEditable" class="input-width" appearance="outline">
              <mat-label class="formLabel">{{ 'amount' | translate }}</mat-label>
              <input matInput formControlName="amount" class="input" required
                (keypress)="anuualCTCValidation($event)" />
              <mat-error *ngIf="createSalaryDetailsForm.get('amount')?.errors?.['max']">{{
                'annual_ctc_max_limit_exceeded' | translate }}</mat-error>
              <mat-error *ngIf="createSalaryDetailsForm.get('amount')?.errors?.['required']">{{
                'annual_ctc_is_required' | translate }}</mat-error>
            </mat-form-field>
            <mat-form-field *ngIf="annaulCtcEditable" class="input-width" appearance="outline">
              <mat-label class="formLabel">{{'annual_ctc'|translate}}</mat-label>
              <input matInput type="text" formControlName="amount" class="input" value="0" required readonly />
              <mat-error>{{'annual_ctc_is_required'|translate}}</mat-error>
            </mat-form-field>
            <mat-form-field class="input-width" appearance="outline">
              <mat-label class="formLabel">{{ 'paid_type' | translate }}</mat-label>
              <mat-select formControlName="paidType">
                <mat-option value="yearly">{{ 'yearly' | translate }}</mat-option>
                <mat-option value="monthly">{{ 'monthly' | translate }}</mat-option>
                <mat-option value="hourly">{{ 'hourly' | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="editIcon" *ngIf="showEditIcon" (click)="editToggle()">
            <mat-icon>
              <ng-container *ngIf="annaulCtcEditable">edit</ng-container>
              <ng-container class="saveButton" *ngIf="!annaulCtcEditable">save</ng-container>
            </mat-icon>
          </div>
        </div>
        <caption><b>{{'earning'|translate}}</b></caption>
        <table mat-table [dataSource]="earningData" class="mat-table">
          <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
            <th class="header" mat-header-cell *matHeaderCellDef [class.left-align]="column === 'Salary Component'"> {{
              column | titlecase }} </th>
            <td mat-cell *matCellDef="let element" [class.left-align]="column === 'Salary Component'"> {{
              element[column] }} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-divider></mat-divider>
        <div *ngIf="deductionData && deductionData.length > 0">
          <caption>{{'deduction'|translate}}</caption>
          <mat-divider></mat-divider>
          <table mat-table [dataSource]="deductionData" class="mat-table">
            <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
              <th class="header" mat-header-cell *matHeaderCellDef [class.left-align]="column === 'Salary Component'">
                {{ column | titlecase }} </th>
              <td mat-cell *matCellDef="let element" [class.left-align]="column === 'Salary Component'"> {{
                element[column] }} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <mat-divider></mat-divider>
        <div class="grossPay">
          <h4 class="grossPayHeading">{{'gross_pay'|translate}}</h4>
          <div class="grossPayValue"><b>{{addPercentageIcon(grossPay)}}</b></div>
        </div>
        <button matStepperPrevious class="back-button">{{ 'back' | translate }}</button>

        <div class="buttonDiv">
          <button (click)="backToHome()" class="cancel-btn">{{'cancel'|translate}}</button>
          <button type="submit" (click)="saveSalary()">{{'save_and_next'|translate}}</button>
        </div>
      </div>
    </form>
  </div>
</div>