<mat-card class="subscription-card">
    <h3 class="title">{{'create_subscription'|translate}}</h3>
    <form [formGroup]="subscriptionForm">
        <div class="form-field-container">
            <mat-form-field class="flex-item" appearance="outline">
                <mat-label>{{'subscription_name'|translate}}</mat-label>
                <input matInput formControlName="subscriptionName" (keypress)="validateCharacterNumberAndSpace($event)"
                    [maxLength]="30">
                <mat-error *ngIf="subscriptionForm.get('subscriptionName').hasError('required')">
                    {{'subscription_name_required'|translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="flex-item" appearance="outline">
                <mat-label>{{'subscription_type'|translate}}</mat-label>
                <input matInput [maxLength]="30" formControlName="subscriptionType"
                    (keypress)="validateCharacterNumberAndSpace($event)">
                <mat-error *ngIf="subscriptionForm.get('subscriptionType').hasError('required')">
                    {{'subscription_type_required'|translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="flex-item" appearance="outline">
                <mat-label>{{'subscription_price'|translate}}</mat-label>
                <input matInput type="text" formControlName="price" (keypress)="validateNumber($event)" maxlength="6">
                <mat-error *ngIf="subscriptionForm.get('price').hasError('required')">
                    {{'subscription_price_required'|translate}}
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-field-container">

            <mat-form-field class="flex-item" appearance="outline">
                <mat-label>{{ 'validity_in_days' | translate }}</mat-label>
                <input (keypress)="validateNumber($event)" matInput type="text" maxlength="5"
                    formControlName="validityDays" (keypress)="validateCharacterNumberAndSpace($event)">
                <mat-error *ngIf="subscriptionForm.get('validityDays').hasError('required')">
                    {{ 'validity_required' | translate }}
                </mat-error>
                <mat-error *ngIf="subscriptionForm.get('validityDays').hasError('minimumOneDay')">
                    {{'minimum_one_day_required'|translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="flex-item" appearance="outline">
                <mat-label>{{ 'users_limit' | translate }}</mat-label>
                <input maxlength="6" (keypress)="validateCharacterNumberAndSpace($event)" matInput type="text"
                  formControlName="totalUser" (input)="validateUserLimit($event)">
                <mat-error *ngIf="subscriptionForm.get('totalUser').hasError('required')">
                  {{ 'users_limit_required' | translate }}
                </mat-error>
                <mat-error *ngIf="subscriptionForm.get('totalUser').hasError('maxValue')">
                  {{ 'users_limit_max_value' | translate }}
                </mat-error>
              </mat-form-field>

            <mat-form-field class="flex-item" appearance="outline">
                <mat-label>{{'choose_modules'|translate}}</mat-label>
                <mat-icon class="searchIcon" matPrefix>search</mat-icon>
                <input matInput [formControl]="searchControl" class="custom-mat-input">
                <mat-icon class="dropDownIcon" matSuffix (click)="toggleOptions()">arrow_drop_down</mat-icon>
            </mat-form-field>

            <div class="overlay-container">
                <div class="overlay" *ngIf="!showOptions">
                  <mat-option
                    *ngFor="let feature of (searchResults | async); let i = index"
                    [value]="feature"
                    class="custom-mat-option custom-mat-option-overlay"
                    (click)="onOptionClick(feature)"
                    [class.fixed]="i < 5"
                  >
                    {{ feature }}
                  </mat-option>
                </div>
                <div class="overlay" *ngIf="showOptions">
                  <mat-option
                    *ngFor="let feature of filteredIssues; let i = index"
                    [value]="feature"
                    class="custom-mat-option custom-mat-option-overlay"
                    (click)="onOptionClick(feature)"
                    [class.fixed]="i < 5"
                  >
                    {{ feature }}
                  </mat-option>
                </div>
              </div>
              
        </div>
        <div class="selected-values-wrapper">
            <div class="selected-values-container">
                <div *ngFor="let value of selectedData" class="selected-value">
                    {{ value }}
                    <mat-icon class="cancel-icon" (click)="removeSelectedValue(value)">cancel</mat-icon>
                </div>
            </div>
        </div>
        <button class="button" type="submit" (click)="onSubmit()">{{'submit'|translate}}</button>
        <button (click)="gotToBack()" class="cancel-btn">{{'cancel'|translate}}</button>
    </form>
</mat-card>