import { Component } from '@angular/core';
import { ActivityMonitorService } from './services/activity-monitor/activity-monitor.service';
import { Icons } from './constants/icons.costants';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title: string = "iWingsHR";
  constructor(private activityMonitorService: ActivityMonitorService, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    //adding svg to mat-icon
    let svgIocns = Icons.SVGICONS;
    svgIocns.forEach(icon => {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path)
      );
    });
  }


  ngOnInit(): void {
    this.activityMonitorService.startMonitoring();
  }
}
