<div class="attendance-configuration">
    <h3 class="add-section-category">{{ 'add_category' | translate }}</h3>
    <form [formGroup]="section">

        <div class="category-details">
            <mat-form-field appearance="outline" class="category-name">
                <mat-label>{{ 'category_name' | translate }}</mat-label>
                <input matInput formControlName="categoryName" name="categoryName"
                    (keypress)="validateCharacterNumberAndSpace($event)" required />
                <mat-error *ngIf="section.controls['categoryName'].hasError('required')">{{
                    'category_name_is_required' | translate }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="category-name">
                <mat-label>{{ 'category_limit' | translate }}</mat-label>
                <input matInput formControlName="categoryLimit" name="categoryLimit"
                    (keypress)="validateCharacterNumberAndSpace($event)" />
                <mat-error *ngIf="section.controls['categoryLimit'].hasError('required')">{{
                    'category_limit_is_required' | translate }}</mat-error>
            </mat-form-field>
        </div>
        <mat-form-field class="log-description" appearance="outline">
            <mat-label>{{ 'category_description' | translate }}</mat-label>
            <input matInput type="text" maxlength="150" formControlName="categoryDescription"
                (keypress)="validateCharacterNumberAndSpace($event)" required />
            <mat-error *ngIf="section.get('categoryDescription')?.hasError('required')">
                {{ 'category_description_is_required' | translate }}</mat-error>
        </mat-form-field>
    </form>
    <div class="button-container">
        <button class="cancel-btn" type="reset" (click)="onCancelClick()">{{ 'cancel' | translate }}</button>
        <button (click)="addSection()">{{ 'add_category' | translate }}</button>
    </div>
</div>