import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent {
  confirmationMessage!: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationPopupComponent>,  @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.confirmationMessage = data;
    }

  onNoClick(): void {
    this.dialogRef.close({data:false});
  }

  onYesClick(): void {
    this.dialogRef.close({data:true});
  }
}
