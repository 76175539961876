<div class="container">
    <div class="header">
        <div class="name">
            <span class="circle-icon">{{ getInitials(employeeName) }}</span>
            <h3 class="employee-name">{{ name }}</h3>
        </div>
        <div *ngIf="showTablePopup">
            <table class="show-details">
                <button mat-icon-button (click)="closePopup()" class="closeDialog">
                    <mat-icon>close</mat-icon>
                </button>
                <tr>
                    <th *ngFor="let logDetails of tableHead">{{ logDetails }}</th>
                </tr>
                <mat-divider class="header-divider"></mat-divider>
                <tr>
                    <td>{{ logDetails.date }}</td>
                    <td>{{name}}</td>
                    <td>{{ logDetails.categoryCode }}</td>
                    <td>{{ logDetails.categoryDescription }}</td>
                    <td>{{ logDetails.logHours }}</td>
                    <td><mat-icon class="delete-icon"
                            (click)="deleteAttendanceRecord(logDetails.date)">delete</mat-icon></td>
                </tr>
            </table>
        </div>

        <app-log-time *ngIf="showLogTimePopup" [logDetails]="logDetailsToEdit" (closePopup)="closeLogTimePopup()"
            (logTimeUpdated)="closeLogTimePopup()"></app-log-time>

        <div class="employee-details">
            <button class="button" (click)="submitLastPeriod()" [disabled]="isProgressBarIncomplete()"
                [ngClass]="{ 'disabled-button': isProgressBarIncomplete() }">
                {{'submit-last-period' | translate}}
            </button>
            <button (click)="submmitionHistoryToggle()">
                {{ showSubmmitionHistory ? ('attendance' | translate) : ('submission_history' | translate) }}
            </button>
        </div>
    </div>
    <div *ngIf="!showSubmmitionHistory">
        <form [formGroup]="selectDateForm">
            <div>
                <mat-form-field appearance="outline" class="date">
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate formControlName="startDate" (keypress)="$event.preventDefault()"
                            [(ngModel)]="startDateValue">
                        <input matEndDate formControlName="endDate" [(ngModel)]="endDateValue"
                            (keypress)="$event.preventDefault()">
                    </mat-date-range-input>
                    <div class="calender">
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </div>
                </mat-form-field>

                <div class="log-time">
                    <button mat-stroked-button (click)="toggleAddPopup()">{{'log-time' | translate}}</button>
                    <button *ngIf="!employeeAttendance" class="approve-attendance"
                        (click)="approveAttendance()">{{'approve' |translate}}</button>
                    <button *ngIf="!employeeAttendance" class="reject-attendance"
                        (click)="rejectAttendance()">{{'reject'|translate}}</button>
                </div>
            </div>
            <ag-grid-angular (gridReady)="onGridReady($event)" style="width: 100%; height: 25rem"
                class="ag-theme-alpine" [rowData]="rowData" [columnDefs]="columnDefs"
                (cellClicked)="onCellClicked($event)"></ag-grid-angular>
        </form>
    </div>

    <div class="attendanceHistory" *ngIf="showSubmmitionHistory">
        <ag-grid-angular (gridReady)="onGridReady($event)" style="height: 27rem; width: 100%;" class="ag-theme-alpine"
            [rowData]="attendanceHistory" [rowSelection]="'multiple'" [animateRows]="true"
            [columnDefs]="attendanceTableCol" [defaultColDef]="attendanceColDef" [enableSorting]="true"
            [pagination]="true" [paginationPageSize]="10" [enableFilter]="true">
        </ag-grid-angular>

    </div>

    <app-loader></app-loader>