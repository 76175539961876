import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ChartOptions } from 'chart.js';
import { ChartComponent } from 'ng-apexcharts';
import { MONTHS } from 'src/app/constants/admin-module-constant/months.constants';
import { AppConstant } from 'src/app/constants/app.constants';
import { RecentlyAddedCustomersService } from 'src/app/services/admin-model-services/recently-added-customers/recently-added-customers.service';

@Component({
  selector: 'app-recently-added-customers',
  templateUrl: './recently-added-customers.component.html',
  styleUrls: ['./recently-added-customers.component.scss']
})
export class RecentlyAddedCustomersComponent {
  @ViewChild("chart") chart: ChartComponent | undefined;
  public newCustomer: Partial<ChartOptions> | any;

  selectedTimeRange: string = 'monthly';
  newUser: any;
  yearsList: number[] = [];

  constructor(private recentlyAddedCustomer: RecentlyAddedCustomersService, private router: Router) {
    this.newAddedCustomer();
    this.generateYearsList();
  }

  viewReport() {
    sessionStorage.setItem('report', 'newUsers')
    this.router.navigate([AppConstant.ADMIN_SIDEBAR + AppConstant.SEPERATOR + AppConstant.NEW_USERS_REPORT])
  }

  generateYearsList() {
    const currentYear = new Date().getFullYear();
    for (let i = currentYear - 10; i <= currentYear; i++) {
      this.yearsList.push(i);
    }
  }

  newAddedCustomer() {
    this.newCustomer = {
      series: [
        {
          name: "New Customers",
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      ],
      chart: {
        height: 350,
        type: "bar"
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top"
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: "10px",
          colors: ["#304758"]
        }
      },
      xaxis: {
        categories: this.selectedTimeRange === 'monthly' ? [
          MONTHS.JAN, MONTHS.FEB, MONTHS.MARCH, MONTHS.APRIL, MONTHS.MAY, MONTHS.JUNE,
          MONTHS.JULY, MONTHS.AUG, MONTHS.SEPT, MONTHS.OCT, MONTHS.NOV, MONTHS.DEC
        ] : this.yearsList.map(String),
        position: "top",
        labels: {
          offsetY: -18
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5
            }
          }
        },
        tooltip: {
          enabled: true,
          offsetY: -35
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [50, 0, 100, 100]
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          formatter: function (val: any) {
            return val + "%";
          }
        }
      },
      title: {
        text: AppConstant.RECENTLY_ADDED_CUSTOMERS,
        offsetY: 320,
        align: "center",
        style: {
          color: "#444"
        }
      }
    };
  }

  ngOnInit() {
    this.recentlyAddedCustomers();
  }

  onTimeRangeChange() {
    this.newAddedCustomer();
    this.recentlyAddedCustomers();
  }

  recentlyAddedCustomers() {
    this.recentlyAddedCustomer.getAddedCustomers(this.selectedTimeRange).subscribe((data: any) => {
      this.newUser = data.data;
      this.newCustomer.series[0].data = this.newUser;
      if (this.selectedTimeRange.toLowerCase() === 'monthly') {
        this.newCustomer.series[0].data = [
          this.newUser.JANUARY, this.newUser.FEBRUARY, this.newUser.MARCH, this.newUser.APRIL,
          this.newUser.MAY, this.newUser.JUNE, this.newUser.JULY, this.newUser.AUGUST,
          this.newUser.SEPTEMBER, this.newUser.OCTOBER, this.newUser.NOVEMBER, this.newUser.DECEMBER
        ];
      } else if (this.selectedTimeRange.toLowerCase() === 'yearly') {
        this.newCustomer.series[0].data = this.yearsList.map(year => this.newUser[year.toString()] || 0);
        this.chart?.updateOptions({
          xaxis: {
            categories: this.yearsList.map(String),
          },
        });
      }
    })
  }
}
