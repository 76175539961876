import { Component, OnInit } from '@angular/core';
import { CompanyStatisticsService } from 'src/app/services/admin-model-services/company-statistics/company-statistics.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})

export class AdminDashboardComponent  implements OnInit{

  profit: any;
  totalUsers: any;

  constructor(public companyStatisticsService: CompanyStatisticsService) { }

  ngOnInit() {
    this.companyStatistics();
  }

  companyStatistics() {
    this.companyStatisticsService.getCompanyStatistics().subscribe((data: any) => {
      this.profit = data.data.profit;
      this.totalUsers = data.data.totalUsers;
    })
  }
  
}
