<div class="main-container">
    <div class="button-container" *ngIf="showAddButton">
        <button class="button" color="primary" (click)="addFamilyAndEmergencyContacts()">{{ 'add' | translate}}</button>
    </div>
    <div class="familyAndEmergencyDetails">
        <ag-grid-angular style="height: 460px; width:  100%;" class="ag-theme-alpine"
            [rowData]="familyAndEmergencyContactInformation" [rowSelection]="'multiple'" [animateRows]="true"
            [columnDefs]="familyAndEmergencycolumnDefs" [enableSorting]="true" [enableFilter]="true" [pagination]="true"
            [paginationPageSize]="10">
        </ag-grid-angular>
    </div>