import { Component } from '@angular/core';
import { AppConstant } from 'src/app/constants/app.constants';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';

@Component({
  selector: 'app-org-hierarchy',
  templateUrl: './org-hierarchy.component.html',
  styleUrls: ['./org-hierarchy.component.scss']
})
export class OrgHierarchyComponent {
  nodes: any = [];

  constructor(private employeeDetails: EmployeeDetailsService) {
    let companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
    let employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);

    this.employeeDetails.employeeOrgHierarchy(companyId, employeeId).subscribe((res: any) => {
      if (res.status == 200 && res.data)
        this.nodes = res.data;
    });
  }
}
