import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DepartmentService } from 'src/app/services/department/departmentService';
import { DesignationService } from 'src/app/services/designation/designationService';
import { CreateEmployeeDetails, DepartmentData, DesignationData } from 'src/app/model/employeeDetails';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';
import { AppConstant } from 'src/app/constants/app.constants';
import { SalutationData } from 'src/app/constants/master-data/salutation.constant';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { ToastrService } from 'ngx-toastr';
import { MatStepper } from '@angular/material/stepper';
import { RolesModule } from 'src/app/model/roles/roles/roles.module';
import { RolesService } from 'src/app/services/roles/roles.service';
import { FormatUtil } from 'src/app/utils/format.utils';
import { Router } from '@angular/router';
import { Messages } from 'src/app/constants/messages.constant';

@Component({
  selector: 'app-basic-details',
  templateUrl: './basic-details.component.html',
  styleUrls: ['./basic-details.component.scss']
})

export class BasicDetailsComponent implements OnInit {
  createBasicForm!: FormGroup;
  designationList: any;
  departmentList: any;
  managerList: any;
  showAddDepartmentPopup: boolean = false;
  showAddRolePopup: boolean = false;
  managerListLoaded: boolean = false;
  showAddDesignationPopup: boolean = false;
  isAvailable: boolean = false;
  isFound: boolean = false;
  departmentForm!: FormGroup;
  createRoleForm!: FormGroup;
  designationForm!: FormGroup;
  salutations: any = SalutationData.SALUTATIONS;
  payRunGroupList: any;
  companyRegistrationDate: any;
  taxSection = this.fb.group({
    providentFund: false,
    professionalTax: false,
  });
  dojMinLength: Date = undefined as any;
  futureSixMonths: Date = undefined as any;
  roleList: any[] = [];
  hideManagerField: boolean = false;
  employeeTypes: string[] = Messages.EMPLOYEE_TYPES;

  constructor(private router: Router, private roleService: RolesService, private stepper: MatStepper, private toastrService: ToastrService, private designationService: DesignationService, private departmentService: DepartmentService, private fb: FormBuilder, private employeeDetailsService: EmployeeDetailsService) {
    this.departmentForm = this.fb.group({
      departmentName: ['', Validators.required],
    });

    this.designationForm = this.fb.group({
      designationName: ['', Validators.required],
    });


    this.createRoleForm = this.fb.group({
      roleName: ['', Validators.required],
      roleDescription: ['', Validators.required],
      roleReportTo: ['']
    });

    //employee DOJ cannot be older than company registration date - DOJ
    this.dojMinLength = FormatUtil.dojMinLenghtCompanyRegistrationdate();

    //finding future 6 months date. HR can only enroll employee before 6 months
    this.futureSixMonths = FormatUtil.futureSixMonthsDate();
  }

  ngOnInit() {
    this.initDesignationList();
    this.initDepartmentList();
    this.initManagerList();
    this.employeeBasicInformation();
    this.getPayRunGroupList();
    this.initRoleList();
    this.companyRegistrationDate = sessionStorage.getItem(AppConstant.COMPANY_REGISTRATION_DATE)
  }

  isSameDate = (date1: Date, date2: Date): boolean => {
    return date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();
  }

  employeeBasicInformation() {
    this.createBasicForm = this.fb.group({
      'firstName': ['', ValidationUtil.onlySpacesOrNumbersValidator()],
      'middleName': ['', ValidationUtil.onlySpacesOrNumbersValidator()],
      'lastName': ['', ValidationUtil.onlySpacesOrNumbersValidator()],
      'gender': ['', [Validators.required]],
      'doj': ['', [Validators.required, this.validateDateOfJoining.bind(this)]],
      'designationId': ['', [Validators.required]],
      'workEmail': ['', [Validators.required, ValidationUtil.emailValidation()]],
      'departmentId': ['', Validators.required],
      'workLocation': [''],
      'employeeType': ['permanent', Validators.required],
      'managerId': [''],
      'salutation': ['Mr.', Validators.required],
      'payrunGroupName': ['', Validators.required]
    });
    this.createBasicForm?.get('designationId')?.valueChanges.subscribe(value => {
      this.onDesignationChange(value);
    });

  }
  onDesignationChange(designationId: string) {
    const selectedDesignation = this.designationList.find((designation: { id: string; }) => designation.id === designationId);
    if (selectedDesignation && selectedDesignation.designation === 'CEO') {
      this.hideManagerField = true;
    } else {
      this.hideManagerField = false;
    }
  }
  addEmployeeBasicInformation() {
    if (this.createBasicForm.valid) {
      let doj: any;
      if (typeof (this.createBasicForm.get('doj')?.value) == 'string') {
        doj = FormatUtil.adjustDateForTimeOffset(new Date(this.createBasicForm.get('doj')?.value));
      } else {
        doj = FormatUtil.adjustDateForTimeOffset(this.createBasicForm.get('doj')?.value);
      }

      let createEmployeeDetails: CreateEmployeeDetails = {
        firstName: this.createBasicForm.value.firstName,
        salutation: this.createBasicForm.value.salutation,
        middleName: this.createBasicForm.value.middleName,
        lastName: this.createBasicForm.value.lastName,
        gender: this.createBasicForm.value.gender,
        doj: doj,
        designationId: this.createBasicForm.value.designationId,
        workEmail: this.createBasicForm.value.workEmail,
        departmentId: this.createBasicForm.value.departmentId,
        workLocation: this.createBasicForm.value.workLocation,
        employeeType: this.createBasicForm.value.employeeType,
        managerId: this.createBasicForm.value.managerId,
        payRunGroupId: this.createBasicForm.value.payrunGroupName,
        companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
        portalAccess: true,
        createdBy: "null"
      }
      this.employeeDetailsService.employeeBasicDetails(createEmployeeDetails).subscribe(
        (data: any) => {
          if (data.status === 200) {
            sessionStorage.setItem(AppConstant.BASIC_DETAILS_ID, data?.data?.id);
            this.toastrService.clear();
            this.toastrService.success(data.message);
            return this.stepper.next();
          }
          else {
            this.toastrService.clear();
            return this.toastrService.error(data.message);
          }
        });
    }

  }

  validateCharacter(event: any) {
    return ValidationUtil.validateCharacter(event);
  }


  initDesignationList() {
    this.designationService.getDesignationList(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((res: any) => {
      this.designationList = res;
    });
  }

  initDepartmentList() {
    this.departmentService.getDepartmentList(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((res: any) => {
      this.departmentList = res;
    });
  }

  initRoleList() {
    this.roleService.getAllRole(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((data: any) => {
      this.roleList = data.data;
    });
  }

  onAddDesignationClick(): void {
    this.showAddDesignationPopup = true;
  }

  onAddDepartmentClick(): void {
    this.showAddDepartmentPopup = true;
  }


  onAddRoleClick(): void {
    this.showAddRolePopup = true;
  }

  getPayRunGroupList() {
    this.employeeDetailsService.getPayRunGroupList(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
      (data: any) => {
        this.payRunGroupList = data.data;
      }
    )
  }

  initManagerList() {
    this.employeeDetailsService.getManagerList(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((res: any) => {
      this.managerList = res;
    });
  }

  isManagerAvailable() {
    if (this.managerList.length == 0) {
      this.isAvailable = true;
    }
  }

  payrunNotFound() {
    if (this.payRunGroupList.data.length == 0) {
      this.isFound = true;
    }
  }

  toggleAddDepartmentPopup() {
    this.showAddDepartmentPopup = !this.showAddDepartmentPopup;
    this.createBasicForm.get('departmentId')?.reset();
  }

  toggleAddRolesPopup() {
    this.showAddRolePopup = !this.showAddRolePopup;
    this.createRoleForm.get('roleId')?.reset();
  }

  toggleAddDesignationPopup() {
    this.showAddDesignationPopup = !this.showAddDesignationPopup;
    this.createBasicForm.get('designationId')?.reset();
  }

  addDepartment() {
    const departmentName = this.departmentForm.value.departmentName;
    if (!departmentName || departmentName.trim() === '') {
      return;
    }
    let departmentDetails: DepartmentData = {
      departmentName: departmentName,
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      id: 0,
    };
    this.departmentService.addDepartment(departmentDetails).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.initDepartmentList();
          this.createBasicForm.get('departmentId')?.setValue(departmentDetails.departmentName);
          this.departmentForm.reset();
          this.showAddDepartmentPopup = false;
        }
      }
    );
  }

  addDesignation() {
    const designationName = this.designationForm.value.designationName;
    if (!designationName || designationName.trim() === '') {
      return;
    }
    let designationDetails: DesignationData = {
      designation: designationName,
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      id: 0,
    };
    this.designationService.addDesignation(designationDetails).subscribe(
      (data: any) => {
        this.initDesignationList();
        this.createBasicForm.get('designationId')?.setValue(designationDetails.designation);
        this.designationForm.reset();
        this.showAddDesignationPopup = false;
      }
    );
  }
  addRole() {
    const roleName = this.createRoleForm.value.roleName;
    const roleDescription = this.createRoleForm.value.roleDescription;
    const roleReportTo = this.createRoleForm.value.roleReportTo
    let roleDetails: RolesModule = {
      roleName: roleName,
      roleDescription: roleDescription,
      roleReportTo: roleReportTo,
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
    };
    this.roleService.addRole(roleDetails).subscribe(
      (data: any) => {
        this.initRoleList();
        if (data.status === 404) {
          this.toastrService.clear();
          this.toastrService.error(data.message);
        }
        this.createRoleForm.reset();
        this.toastrService.success(data.message);
        this.toastrService.clear();
      });
    this.showAddRolePopup = false;
  }

  validateDateOfJoining(control: any) {
    const selectedDate = new Date(control.value);
    const normalizedSelectedDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate());

    if (isNaN(normalizedSelectedDate.getTime())) {
      return { invalidDate: true };
    }

    const companyRegistrationDate = new Date(this.companyRegistrationDate);
    const normalizedCompanyRegistrationDate = new Date(companyRegistrationDate.getFullYear(), companyRegistrationDate.getMonth(), companyRegistrationDate.getDate());

    if (normalizedSelectedDate < normalizedCompanyRegistrationDate) {
      return { invalidDateOfJoining: true };
    }

    return null;
  }

  formatDate(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day < 10 ? '0' + day : day}-${month < 10 ? '0' + month : month}-${year}`;
  }

  validateCharacterNumberAndSpace(event: any) {
    return ValidationUtil.characterNumberAndSpaceAllowed(event);
  }

  // addressVerification(event: any) {
  //   return ValidationUtil.addressVerification(event);
  // }

  resetForm() {
    this.createBasicForm.reset();
  }


  goToDashboard() {
    this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.DASHBOARD]);
  }
}

