import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Environment } from 'ag-grid-community';
import { Observable, map } from 'rxjs';
import { EmployeeDetailsComponent } from 'src/app/components/admin/add-employee/employee-details/employee-details.component';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { AppConstant } from 'src/app/constants/app.constants';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class AppraisalServiceService {

  constructor(private http: HttpClient) { }

  getMatrixFile(companyId: any, empId: any, fileName: any): Observable<HttpResponse<Blob>> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_MATRIX_FILE + AppConstant.SEPERATOR + companyId + AppConstant.SEPERATOR + empId + AppConstant.SEPERATOR + fileName).pipe(
      map((res: any) => {
        return res;
      }),
    )
  }

  getEmployeeGoalsData(companyId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_EMPLOYEE_GOLES_DATA + AppConstant.SEPERATOR + companyId + AppConstant.SEPERATOR + employeeId).pipe(
      map((res: any) => {
        return res;
      }),
    )
  }

  getEmployeeGoalsCategory(companyId: any, employeeId: any, category: any,year:any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_EMPLOYEE_GOLES_BY_CATEGORY + AppConstant.SEPERATOR + companyId + AppConstant.SEPERATOR + employeeId + AppConstant.SEPERATOR + category + AppConstant.SEPERATOR + year).pipe(
      map((res: any) => {
        return res;
      }),
    )
  }

  employeeGoalDetails(companyId: any, employeeId: any, id: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_EMPLOYEE_GOAL_DETAILS + companyId + AppConstant.SEPERATOR + employeeId + AppConstant.SEPERATOR + id).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  deleteGoal(employeeId: number, goalId: any): Observable<any> {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_GOAL + AppConstant.SEPERATOR + employeeId + AppConstant.SEPERATOR + goalId).pipe(
      map((res: any) => {
        return res;
      }),
    )
  }


  getEmployeeGoalDetails(companyId: any, employeeId: any, goalId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_EMPLOYEE_GOAL_DETAIL + AppConstant.SEPERATOR + companyId + AppConstant.SEPERATOR + employeeId + AppConstant.SEPERATOR + goalId).pipe(
      map((res: any) => {
        return res;
      }),
    )
  }


  addManagerRating(dto: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_MANAGER_RATING, dto).pipe(
      map((res: any) => {
        return res;
      }),
    )
  }

  getManagerRating(companyId: any, id: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_MANAGER_RATING + AppConstant.SEPERATOR + companyId + AppConstant.SEPERATOR + id).pipe(
      map((res: any) => {
        return res;
      }),
    )
  }

  AddEmployeeRating(dot: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.EMPLOYEE_PERFORMACE_RATING, dot).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getEmployeeRating(companyId: any, id: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_EMPLOYEE_PERFORMACE_RATING + companyId + AppConstant.SEPERATOR + id).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  addGoal(dot: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_GOAL, dot).pipe(
      map((res: any) => {
        return res;
      }));
  }

  uploadAppraisalData(formData: FormData): Observable<any> {
    const headers = new HttpHeaders({
    });
    return this.http.post<any>(environment.apiUrl + API_END_POINTS.APPRAISAL_SETUP, formData, { headers });
  }

  getAnnualApprisalSetup(companyId: any, year: any): Observable<any> {
    return this.http.get(environment.apiUrl + AppConstant.SEPERATOR + API_END_POINTS.GET_APPRISAL_SETUP + companyId + AppConstant.SEPERATOR + year).pipe(
      map((res: any) => {
        return res;
      })
    );
  }


  getAnnualSetting(companyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_SETTING_DATA + companyId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  downloadApprisalExcel(companyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + AppConstant.SEPERATOR + API_END_POINTS.APPRISAL_EXCEL + companyId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  addSummary(sumaryDto: any): Observable<any> {
    return this.http.post(environment.apiUrl + AppConstant.SEPERATOR + API_END_POINTS.SAVE_SUMMARY, sumaryDto).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  addManagerSummary(sumaryDto: any): Observable<any> {
    return this.http.post(environment.apiUrl + AppConstant.SEPERATOR + API_END_POINTS.SAVE_MANAGER_SUMMARY, sumaryDto).pipe(
      map((res: any) => {
        return res;
      })
    )
  }
  approvedGoalsByManager(goalReview: any): Observable<any> {
    return this.http.post(environment.apiUrl + AppConstant.SEPERATOR + API_END_POINTS.GOAL_REVIEW, goalReview).pipe(
      map((res: any) => {
        return res;
      })
    )

  }






  getSummary(companyId: any, employeeId: any, year: any): Observable<any> {
    return this.http.get(environment.apiUrl + AppConstant.SEPERATOR + API_END_POINTS.SUMMARY_GET_CALL + AppConstant.SEPERATOR + companyId + AppConstant.SEPERATOR + employeeId + AppConstant.SEPERATOR + year).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  getAppraisalSetting(companyId: any, year: any): Observable<any> {
    return this.http.get(environment.apiUrl + AppConstant.SEPERATOR + API_END_POINTS.GET_ANNUAL_APPRAISAL_SETTING + companyId + AppConstant.SEPERATOR + year).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

}
