<div class="container">
    <div class="logo-container">
        <img class="bank-image" src="../../../assets/images/bank-images/yes-bank.svg" alt="">
        <div class="text">{{'direct_deposits' | translate}}</div>
        <span class="active">{{'active' | translate}}</span>
    </div>
    <mat-divider></mat-divider>

    <div class="view-text">
        {{'view-content' | translate}}  YES Bank
    </div>
    <div class="configuration">
        <b>{{'bank_configuration' | translate}}  YES Bank {{'integration' | translate}}</b>
    </div>
    <table>
        <tr>
            <td>{{'bank_name' |translate}}</td>
            <td>YES Bank</td>
        </tr>
        <tr>
            <td class="bank-name">{{'corp_id' |translate}}</td>
            <td>YES Bank</td>

        </tr>
        <tr>
            <td class="bank-name">{{'user_id' |translate}}</td>
            <td>{{'001'}}</td>

        </tr>
        <tr>
            <td class="bank-name">{{'account_status' |translate}}</td>
            <td>{{'active'}}</td>
        </tr>
    </table>

    <mat-divider class="divider"></mat-divider>


    <div class="configuration">
        <b>{{'link_account' | translate}}</b>
    </div>

    <table class="link-account">
        <tr>
            <td>{{'bank_account_name' |translate}}</td>
            <td>{{'IntergationWings' | translate}}</td>
        </tr>
        <tr>
            <td class="bank-name">{{'bank_name' |translate}}</td>
            <td>YES Bank</td>

        </tr>
        <tr>
            <td class="bank-name">{{'account_number' |translate}}</td>
            <td>{{'AX2345678'}}</td>

        </tr>
        <tr>
            <td class="bank-name">{{'account_type' |translate}}</td>
            <td>{{'active'}}</td>
        </tr>
    </table>

    <footer>
        <p>{{'footer_content' | translate}}
            {{'or_mail' |
            translate}}<a> {{'bank_email' | translate}}</a>
        </p>
    </footer>
</div>