import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { AppConstant } from 'src/app/constants/app.constants';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class FamilyAndEmergencyContactsService {

  constructor(private http: HttpClient) { }

  //get All emergency contacts of employee
  getEmployeeFamilyAndEmergencyContacts(employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_EMERGENCY_CONTACTS + employeeId).pipe(
      map((res: any) => {
        return res;
      }))
  }

  //get single emergency conatct info
  getFamilyAndEmergencyContacts(contactId: any, employeeId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_FAMILY_AND_EMERGENCY_INFO_BY_ID + employeeId + AppConstant.SEPERATOR + contactId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

  //add new contact info
  addFamilyAndEmergencyContactInfo(addContactRequest: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_FAMILY_AND_EMERGENCY_INFO, addContactRequest).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  //update contact info
  updateFamilyAndEmergencyContactInfo(updateContactRequest: any): Observable<any> {
    return this.http.put(environment.apiUrl + API_END_POINTS.UPDATE_FAMILY_AND_EMERGENCY_INFO, updateContactRequest).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  //delete emergency contact
  deleteEmergencyContact(contactId: any) {
    return this.http.delete(environment.apiUrl + API_END_POINTS.DELETE_FAMILY_AND_EMERGENCY_CONTACT + sessionStorage.getItem(AppConstant.EMPLOYEE_ID) + AppConstant.SEPERATOR + contactId).pipe(
      map((res: any) => {
        return res;
      })
    )
  }
}
