export class SalutationData {
    public static readonly SALUTATIONS = [
        { name: 'Mr.' },
        { name: 'Mrs.' },
        { name: 'Miss.' },
        { name: 'Dr.' },
        { name: 'Ms.' },
        { name: 'Prof.' },
        { name: 'Other' },
    ]
}