import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnConfig } from 'src/app/config/coloum-config';
import { AppConstant } from 'src/app/constants/app.constants';
import { PaymentDataService } from 'src/app/services/admin-model-services/payment-data/payment-data.service';
import { ProfitLossService } from 'src/app/services/admin-model-services/profit-loss/profit-loss.service';
import { RecentlyAddedCustomersService } from 'src/app/services/admin-model-services/recently-added-customers/recently-added-customers.service';

@Component({
  selector: 'app-payment-received-view',
  templateUrl: './payment-received-view.component.html',
  styleUrls: ['./payment-received-view.component.scss']
})
export class PaymentReceivedViewComponent implements OnInit {
  paymentData!: any;
  paymentColDef: any;
  profitLossColDef: any;
  newUserColDef: any;
  paymentTableCol: any;
  profitLossTableCol: any;
  newUserTableCol: any;
  selectedTimeRange: string = 'monthly';
  profitLossData: any;
  newUserData: any;
  showPayment: boolean = true;
  showProfitLoss: boolean = false;
  showNewUser: boolean = false;
  constructor(private router: Router, private paymentService: PaymentDataService, private datePipe: DatePipe, private profitLossService: ProfitLossService, private recentlyAddedCustomerService: RecentlyAddedCustomersService) {
  }
  ngOnInit(): void {

    const report = sessionStorage.getItem(AppConstant.REPORT);

    if (report === 'ProfitLoss') {
      this.showProfitLossTable();
      this.getProfitLossData();

    } else if (report === 'paymentReceived') {
      this.showPaymentTable();
      this.getPaymentReceivedData();

    } else if (report === 'newUsers') {
      this.showNewUserTable();
      this.getRecentlyAddedCustomer();

    } else {
      this.showPaymentTable();
      this.getPaymentReceivedData();

    }

    this.paymentColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.profitLossColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };

    this.newUserColDef = {
      sortable: true,
      filter: true,
      resizable: true,
    };
    this.paymentTableCol = [...ColumnConfig.paymentColumns];
    this.profitLossTableCol = [...ColumnConfig.profitLossColumns];
    this.newUserTableCol = [...ColumnConfig.newUserColumns];
  }

  showPaymentTable(): void {
    this.showPayment = true;
    this.showProfitLoss = false;
    this.showNewUser = false;
  }

  showProfitLossTable(): void {
    this.showPayment = false;
    this.showProfitLoss = true;
    this.showNewUser = false;
  }

  showNewUserTable(): void {
    this.showPayment = false;
    this.showProfitLoss = false;
    this.showNewUser = true;
  }

  montlyYearlyBasis() {
    const report = sessionStorage.getItem(AppConstant.REPORT);
    if (report === 'ProfitLoss') {
      this.getProfitLossData();
    } else if (report === 'paymentReceived') {
      this.getPaymentReceivedData();
    } else if (report === 'newUsers') {
      this.getRecentlyAddedCustomer();
    } else {
      this.getPaymentReceivedData();
    }
  }

  getPaymentReceivedData() {
    this.paymentService.getPaymentReceivedData(this.selectedTimeRange).subscribe((data: any) => {
      this.formatDataForAgGrid(data.data);
    });
  }

  formatDataForAgGrid(data: any) {
    const formattedData = [];
    for (const month in data) {
      if (data.hasOwnProperty(month) && data[month].length > 0) {
        for (const item of data[month]) {
          formattedData.push({
            month: this.selectedTimeRange === 'yearly' ? month : month,
            companyName: item.companyName,
            adminName: item.adminName,
            byDate: this.formatDate(item.byDate),
            subscriptionType: item.subscriptionType,
            expireDate: this.formatDate(item.expireDate),
          });
        }
      }
    }

    this.paymentData = formattedData;
    this.createPaymentTableCol();
  }


  createPaymentTableCol() {
    this.paymentTableCol = [...ColumnConfig.paymentColumns];
    const monthColumn = this.paymentTableCol.find((column: { field: string; }) => column.field === 'month');
    if (monthColumn) {
      monthColumn.headerName = this.selectedTimeRange === 'yearly' ? 'Year' : 'Month';
    }
  }


  private formatDate(dateString: string): string {
    const date = new Date(dateString);
    return this.datePipe.transform(date, 'yyyy-MM-dd') || '';
  }

  getProfitLossData() {
    this.profitLossService.getProfitLossData(this.selectedTimeRange).subscribe((data: any) => {
      this.formatProfitLossDataForAgGrid(data.data);
    });
  }

  formatProfitLossDataForAgGrid(data: any) {
    const formattedData = [];
    for (const month in data) {
      if (data.hasOwnProperty(month) && data[month].length > 0) {
        for (const item of data[month]) {
          formattedData.push({
            month: this.selectedTimeRange === 'yearly' ? month : month,
            companyName: item.companyName,
            adminName: item.adminName,
            email: item.email,
            totalAmount: item.totalAmount,
          });
        }
      }
    }
    this.profitLossData = formattedData;
    this.createProfitLossTableCol();
  }


  createProfitLossTableCol() {
    this.profitLossTableCol = [...ColumnConfig.profitLossColumns];
    const monthColumn = this.profitLossTableCol.find((column: { field: string; }) => column.field === 'month');

    if (monthColumn) {
      monthColumn.headerName = this.selectedTimeRange === 'yearly' ? 'Year' : 'Month';

      if (this.selectedTimeRange === 'monthly' || 'yearly') {
        monthColumn.cellRenderer = (params: any) => {
          return params.value;
        };
      }
    }
  }

  getRecentlyAddedCustomer() {
    this.recentlyAddedCustomerService.getRecentlyAddedCustomerData(this.selectedTimeRange).subscribe((data: any) => {
      this.newUser(data.data);
    })
  }

  newUser(data: any) {
    const formattedData = [];
    for (const month in data) {
      if (data.hasOwnProperty(month) && data[month].length > 0) {
        for (const item of data[month]) {
          formattedData.push({
            month: this.selectedTimeRange === 'yearly' ? month : month,
            companyName: item.companyName,
            adminName: item.adminName,
            email: item.email,
            totalAmount: item.totalAmount,
          });
        }
      }
    }
    this.newUserData = formattedData;
    this.newUsersTableCol();
  }

  newUsersTableCol() {
    this.newUserTableCol = [...ColumnConfig.newUserColumns];
    const monthColumn = this.newUserTableCol.find((column: { field: string; }) => column.field === 'month');
    if (monthColumn) {
      monthColumn.headerName = this.selectedTimeRange === 'yearly' ? 'Year' : 'Month';
      if (this.selectedTimeRange === 'monthly' || 'yearly') {
        monthColumn.cellRenderer = (params: any) => {
          return params.value;
        };
      }
    }
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

}
