import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { AppConstant } from 'src/app/constants/app.constants';
import { employeeExitsDetails, updateEmployeeExitsDetails } from 'src/app/model/exit-model/exit-model/exit-model.module';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class ExitProcessService {

  constructor(private http:HttpClient) { }

  employExitProcess(exitProces:employeeExitsDetails):Observable<employeeExitsDetails>{
    return this.http.post(environment.apiUrl + API_END_POINTS.EXIT_PROCESS,exitProces).pipe(
      map((res:any)=>{
        return res;
      })
    )
  }

  updateEmployExitProcess(updateExitDetails:updateEmployeeExitsDetails):Observable<updateEmployeeExitsDetails>{
    return this.http.post(environment.apiUrl + API_END_POINTS.EXIT_PROCESS,updateExitDetails).pipe(
      map((res:any)=>{
        return res;
      })
    )
  }

  getExitEmployeeById(companyId:any,employeeId:any):Observable<any>{
    return this.http.get(environment.apiUrl + API_END_POINTS .GETEMPLOYEE_RESIGNATION +companyId + AppConstant.SEPERATOR+ employeeId).pipe(
      map((res:any)=>{
        return res;
      })
    )

  }
}
