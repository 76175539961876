<div class="section-container" [@slideInRight]>
  <div>
    <h1 class="section-title">{{'whats_upcoming' | translate}}</h1>
  </div>

  <div class="upcoming-card-container">
    <div class="icon-item">
      <mat-icon class="icon" aria-hidden="false">public</mat-icon>
      <div class="icon-name">{{'worldwide_coverage' | translate}}</div>
    </div>

    <div class="icon-item">
      <mat-icon class="icon" aria-hidden="false">dashboard</mat-icon>
      <div class="icon-name">{{'agile_board' | translate}}</div>
    </div>

    <div class="icon-item">
      <mat-icon class="icon" aria-hidden="false">mobile_friendly</mat-icon>
      <div class="icon-name">{{'mobile_app' | translate}}</div>
    </div>
  </div>
</div>