import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { API_END_POINTS } from 'src/app/config/api-endpoint.config';
import { AppConstant } from 'src/app/constants/app.constants';
import { environment } from 'src/environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class TraningService {

  constructor(private http: HttpClient) { }

  getTrainingList(companyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.SHOW_ALL_TRAINING + companyId).pipe((
      map((res: any) => {
        return res;
      })
    ))
  }

  addCourse(formData: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_COURSE, formData).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getTrainingDetails(trainingId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_TRAINING_DETAILS_BY_TRANING_ID + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + trainingId).pipe((
      map((res: any) => {
        return res;
      })
    ))
  }

  getTrainingDetailsById(companyId: any, trainingId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_TRAINING_DETAILS_BY_TRANING_ID + companyId + AppConstant.SEPERATOR + trainingId).pipe((
      map((res: any) => {
        return res;
      })
    ))
  }

  getAllTrainingDetailsOfEmp(companyId: any, employeeId: any) {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_ALL_TRAINING_DETAILS_OF_EMPLOYEE + companyId + AppConstant.SEPERATOR + employeeId).pipe((
      map((res: any) => {
        return res;
      })
    ))
  }

  getAllTrainingName(companyId: any): Observable<any> {
    return this.http.get(environment.apiUrl + API_END_POINTS.ALL_TRAINING_NAME + companyId).pipe((
      map((res: any) => {
        return res;
      })
    ))
  }

  assignTrainings(payload: any): Observable<any> {
    return this.http.post(environment.apiUrl + API_END_POINTS.ASSIGN_TRAINING, payload).pipe((
      map((res: any) => {
        return res;
      })
    ))
  }
  getComments(trainingId: any, showMoreComment: boolean) {
    if (showMoreComment) {
      return this.http.get(environment.apiUrl + API_END_POINTS.GET_RATING_COMMENT + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + trainingId).pipe((
        map((res: any) => {
          return res;
        })
      ))
    } else {
      return this.http.get(environment.apiUrl + API_END_POINTS.GET_RATING_COMMENT + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + trainingId).pipe((
        map((res: any) => {
          return res;
        })
      ))

    }
  }

  saveRatingAndComment(dto: any) {
    return this.http.post(environment.apiUrl + API_END_POINTS.ADD_COMMENT, dto).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getCommentByEmployeeId(trainingId: any) {
    return this.http.get(environment.apiUrl + API_END_POINTS.GET_COMMENT_BY_EMP_ID + sessionStorage.getItem(AppConstant.COMPANY_ID) + AppConstant.SEPERATOR + sessionStorage.getItem(AppConstant.EMPLOYEE_ID) + AppConstant.SEPERATOR + trainingId).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

}
