import { Component } from '@angular/core';
import { AppConstant } from 'src/app/constants/app.constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  iwingsHRMS_location: string = AppConstant.IWINGSHRMS_LOCATION;
  iwingsHRMS_support_email: string = AppConstant.IWINGSHRMS_SUPPORT_EMAIL;
  iwingsHRMS_support_phone: string = AppConstant.IWINGSHRMS_SUPPORT_PHONE;
}
