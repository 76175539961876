import { HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { Subscription } from 'src/app/model/attendance-model/subscription.model';
import { UserSubscriptionService } from 'src/app/services/userSubscription/user-subscription.service';
import { InstructionsDialogComponent } from '../../instructions-dialog-component/instructions-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { CompanyProfileService } from 'src/app/services/company-profile/company-profile.service';
import { SubscriptionService } from 'src/app/services/admin-model-services/subscription/subscription.service';
import { FormatUtil } from 'src/app/utils/format.utils';

@Component({
  selector: 'app-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss']
})

export class SubscriptionPlanComponent {
  displayNavbar: boolean = true;
  displaySubscription: boolean = true;
  subscriptionPlans: Subscription[] = [];
  domain: any;
  freeSubscription: boolean = false;

  constructor(private scriptionService: SubscriptionService, private userSubscriptionService: UserSubscriptionService, private companyProfileService: CompanyProfileService, private toastrService: ToastrService, private dialog: MatDialog, private subscriptionService: UserSubscriptionService, private router: Router) { }

  ngOnInit() {
    const email = sessionStorage.getItem(AppConstant.LOGIN_EMAIL);
    if (email !== null) {
      this.domain = email.split('@')[1];
    }
    if (this.domain === AppConstant.DOMAIN) {
      this.displayNavbar = false;
      this.displaySubscription = true;
    } else {
      if (sessionStorage.getItem(AppConstant.COMPANY_ID) !== null) {
        this.displayNavbar = false;
        this.displaySubscription = false;
      } else {
        this.displayNavbar = true;
        this.displaySubscription = false;
      }
    }
    this.getSubscriptionList();
  }

  addSubscription() {
    this.router.navigate([AppConstant.ADMIN_SIDEBAR + AppConstant.SEPERATOR + AppConstant.SUBSCRIPTION])
  }

  getSubscriptionList() {
    this.subscriptionService.getAllSubscriptionPlan().subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.subscriptionPlans = data.data;
          this.freeSubscription = this.subscriptionPlans.some(plan => plan.subscriptionName.toLowerCase() === 'free');
        }
      }
    );
  }

  subscribe(plan: Subscription) {
    sessionStorage.setItem(AppConstant.SELECT_SUBSCRIPTION, JSON.stringify(plan));
    const selectedSubscription = JSON.parse(sessionStorage.getItem(AppConstant.SELECT_SUBSCRIPTION) || '{}');
    sessionStorage.setItem(AppConstant.SUBSCRIPTION_PRICE, selectedSubscription.price.toString());
    sessionStorage.setItem(AppConstant.SUBSCRIPTIONNAME, selectedSubscription.subscriptionName);
    sessionStorage.setItem(AppConstant.SUBSCRIPTIONID, selectedSubscription.id);

    if (plan.price === 0) {
      this.subscribeForFreePlan(plan);
    } else {
      this.navigateToPayment(plan);
    }
  }

  subscribeForFreePlan(plan: Subscription) {
    if (sessionStorage.getItem(AppConstant.COMPANY_ID) === null || sessionStorage.getItem(AppConstant.COMPANY_ID) === undefined) {
      const formData = new FormData();
      const logoData = sessionStorage.getItem('logo');
      if (logoData) {
        const blob = FormatUtil.dataURItoBlob(logoData);
        formData.append('logo', blob);
      }
      const companyDetailsDto = sessionStorage.getItem('companyDetailsDto');
      if (companyDetailsDto) {
        formData.append('companyDetailsDto', companyDetailsDto);
      }
      const registrationDetailsDto = sessionStorage.getItem(AppConstant.CREATE_REGISTRATION_REQUEST);
      if (registrationDetailsDto) {
        formData.append('registrationDetailsDto', registrationDetailsDto);
      }

      const headers = new HttpHeaders();
      headers.append('Content-Type', 'multipart/form-data');

      this.companyProfileService.postCompanyData(formData, headers).subscribe((data: any) => {
        this.userSubscriptionService.saveUserSubscription(data.data.companyId, plan.id).subscribe(
          (res: any) => {
            if (res.status === 200) {
              sessionStorage.setItem(AppConstant.COMPANY_ID, data.data.companyId);
              sessionStorage.setItem(AppConstant.EMPLOYEE_ID, data.data.adminId);
              sessionStorage.setItem(AppConstant.ADMIN_ID, data.data.adminId);
              if (data.status === 200) {
                const token: any = sessionStorage.getItem(AppConstant.TOKEN);
                const tokenData = JSON.parse(atob(token.split('.')[1]));
                if (tokenData.roles == AppConstant.HR_ROLE) {
                  this.dialog.open(InstructionsDialogComponent, {
                    width: '500px',
                    data: {}
                  });
                  sessionStorage.removeItem(AppConstant.SUBSCRIPTIONID);
                  sessionStorage.setItem(AppConstant.ROLE_FOR_SESSION, tokenData.roles);
                  this.toastrService.clear();
                  this.toastrService.success(data.message);
                  this.companyDetails(data.data.companyId);
                  this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.DASHBOARD]);
                }
              } else {
                this.toastrService.clear();
                this.toastrService.error(data.message);
                this.router.navigate([AppConstant.VIEW_COMPANY_PROFILE]);
              }
            }
          });
      });
    } else {
      this.userSubscriptionService.saveUserSubscription(sessionStorage.getItem(AppConstant.COMPANY_ID), plan.id).subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.toastrService.clear();
            this.toastrService.success("Subscription updated successfully");
            this.router.navigate([AppConstant.SIDENAV + AppConstant.SEPERATOR + AppConstant.USER_SUBSCRIPTION_VIEW]);
          } else {
            this.toastrService.clear();
            this.toastrService.success(res.message);
          }
        });
    }
  }

  navigateToPayment(plan: Subscription) {
    const email = sessionStorage.getItem(AppConstant.LOGIN_EMAIL);
    if (email !== null) {
      this.domain = email.split('@')[1];
    }
    if (sessionStorage.getItem(AppConstant.COMPANY_ID) !== null) {
      this.router.navigate([AppConstant.USE_VOUCHER])

    } else if (AppConstant.DOMAIN === this.domain) {
      return;
    } else {
      sessionStorage.setItem(AppConstant.SUBSCRIPTIONNAME, plan.subscriptionName);
      sessionStorage.setItem(AppConstant.SUBSCRIPTION_PRICE, plan.price);
      this.router.navigate([AppConstant.USE_VOUCHER]);
    }
  }

  companyDetails(companyId: string): any {
    this.companyProfileService.getCompanyData(companyId).subscribe(
      (data: any) => {
        sessionStorage.setItem(AppConstant.COMPANY_REGISTRATION_DATE, data.data.companyRegistrationDate)
      },
      (error: any) => {
      }
    );
  }

  applyFadeUpAnimation(event: MouseEvent, columnRef: any) {
    const index = columnRef.index;
    const columnCells = document.querySelectorAll(`.column-${index}`);
    columnCells.forEach(cell => cell.classList.add('fadeUpAnimation'));
  }

  removeFadeUpAnimation(event: MouseEvent, columnRef: any) {
    const index = columnRef.index;
    const columnCells = document.querySelectorAll(`.column-${index}`);
    columnCells.forEach(cell => cell.classList.remove('fadeUpAnimation'));
  }

  getColspan(): number {
    return this.subscriptionPlans.length;
  }
}
