import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { AppraisalServiceService } from 'src/app/services/apprisal-service/appraisal-service.service';
@Component({
  selector: 'app-employee-goals-plan',
  templateUrl: './employee-goals-plan.component.html',
  styleUrls: ['./employee-goals-plan.component.scss']
})
export class EmployeeGoalsPlanComponent {
  goalPlanForm: any = FormGroup;
  url: any;
  data: any;
  id: any;
  emplpoyeeGoal: any = [];
  goalId: any = "";
  public edit = false;
  employeeId = sessionStorage.getItem('employeeId');
  blob!: Blob;
  pdfViewer: boolean = true;
  isManager: any;
  excelFile!:Blob;
  constructor(private formbuilder: FormBuilder, private route: Router, private appraisalService: AppraisalServiceService, private router: ActivatedRoute, private toasterService: ToastrService, private dialog: MatDialog) { }
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID)
  ngOnInit() {
    this.isManager = sessionStorage.getItem(AppConstant.IS_MANAGER);
    const viewEmployeeGoalsById = sessionStorage.getItem(AppConstant.EMPLOYEE_UNDER_MANAGER)
    if (this.isManager == 'true') {
      this.employeeId = viewEmployeeGoalsById;
    }
    else {
      this.employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
    }

    this.initializeForm();
    this.getEmployeeGoalsData()

    this.router.params.subscribe((params: { [x: string]: any; }) => {
      this.id = params['id'];
    })
    this.employeeGoalDetails(this.id);
  }
  initializeForm() {
    this.goalPlanForm = this.formbuilder.group({
      id: [''],
      goalName: [''],
      libraryName: [''],
      goalType: [''],
      description: [''],
      category: [''],
      status: [''],
      satrtDate: [''],
      targetDate: [''],
      weigths: [''],
      successCriteria: ['']
    });
  }

  onClick() {
    const companyId = 1;
    const empId = '1';
    const fileName = 'Passport.pdf';
    this.appraisalService.getMatrixFile(companyId, empId, fileName).subscribe(
      (data: any) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        var downloadURL = window.URL.createObjectURL(data);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = "Passport.pdf";
        link.click();
      });
  }

  getEmployeeGoalsData() {
    this.appraisalService.getEmployeeGoalsData(this.companyId, this.employeeId).subscribe(
      (data: any) => {
        this.emplpoyeeGoal = data.data;
      }
    );
  }

  loadGoalDetails(id: any) {
    if (this.isManager == 'true') {
      this.route.navigate([AppConstant.MANAGER_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_BASIC_GOAL + AppConstant.SEPERATOR + id]);
    } else {
      this.route.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_BASIC_GOAL + AppConstant.SEPERATOR + id]);
    }
  }

  employeeGoalDetails(id: any) {
    this.appraisalService.employeeGoalDetails(this.companyId, this.employeeId, id).subscribe((data: any) => {
      this.emplpoyeeGoal = data.data;
    })
  }

  deleteGoal(employeeId: number, goalId: any) {
    this.appraisalService.deleteGoal(employeeId, goalId).subscribe(
      (data: any) => {
        if (data.status == 200) {
          this.getEmployeeGoalsData();
          this.toasterService.clear();
          this.toasterService.success(data.message);
        }
      });
  }

  addGoal() {
    this.route.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.ADD_GOALS]);
  }

  back(){
    this.route.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_EVOLUTION])
  }


  downloadGoalsExcel(): void {
    this.appraisalService.downloadApprisalExcel(this.companyId).subscribe((data: any) => {
      const base64String = data.data.file;
      const binaryString = window.atob(base64String);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const downloadLink = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = 'goals.xlsx'; // Specify the desired file name
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(url);
    }, error => {
      console.error('Error downloading the file', error);
    });
  }
}
