
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { AppraisalServiceService } from 'src/app/services/apprisal-service/appraisal-service.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-add-goals',
  templateUrl: './add-goals.component.html',
  styleUrls: ['./add-goals.component.scss']
})
export class AddGoalsComponent {
  addgoalForm: any = FormGroup
  categories: string[] = ['Performance', 'Behavioural'];
  endOfFinancialYear: Date;
  startOfFinancialYear: Date;

  constructor(
    public dialogRef: MatDialogRef<AddGoalsComponent>,
    private fb: FormBuilder,
    private toasterService: ToastrService,
    private router: Router,
    private appraisalService: AppraisalServiceService
  ) {
    this.startOfFinancialYear = new Date(new Date().getFullYear(), 0, 1);
    this.endOfFinancialYear = new Date(new Date().getFullYear(), 11, 31);
  }

  ngOnInit(): void {
    this.addgoalForm = this.fb.group({
      goalName: ['', [Validators.required]],
      Description: ['', [Validators.required]],
      category: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      targetDate: ['', [Validators.required]],
      weigths: ['', [Validators.required, ValidationUtil.percentAmountValidation()]],
    });
  }

  onSubmit(): void {
    if (this.addgoalForm.invalid) {
      return;
    }
    
    const dto = {
      goalName: this.addgoalForm.value.goalName,
      description: this.addgoalForm.value.Description,
      category: this.addgoalForm.value.category,
      startDate: this.addgoalForm.value.startDate,
      targetDate: this.addgoalForm.value.targetDate,
      weights: this.addgoalForm.value.weigths,
      companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
      employeeId: sessionStorage.getItem(AppConstant.EMPLOYEE_ID),
    };

    this.appraisalService.addGoal(dto).subscribe(
      (data: any) => {
        const currentUrl = this.router.url;

        if (data.status == 200 && currentUrl === '/employee/appraisal') {
          this.dialogRef.close();
          this.toasterService.clear();
          this.toasterService.success(data.message);
          this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_EMPLOYEE_APPRISAL]);
        } else if (data.status == 200 && currentUrl === '/employee/performance-goal-plan') {
          this.dialogRef.close();
          this.toasterService.clear();
          this.toasterService.success(data.message);
          this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.EMPLOYEE_PERFORMANCE_GOAL_PLAN]);
        } else {
          this.toasterService.clear();
          this.toasterService.error(data.message);
        }
      },
      (error: any) => {
        this.toasterService.clear();
        this.toasterService.error('Failed to add goal. Please try again.');
      }
    );
  }

  cancel_form(): void {
    this.addgoalForm.reset();
    this.dialogRef.close();
  }

  alphanumeric(event: any): void {
    ValidationUtil.alphanumberOnly(event);
  }

  numberOnly(event: any): void {
    ValidationUtil.numberOnly(event);
  }

  updateEndDateMin(): void {
    const startDate = this.addgoalForm.get('startDate')?.value;
    if (startDate) {
      this.addgoalForm.get('targetDate')?.setValidators([
        Validators.required,
        Validators.min(new Date(startDate).getTime()) 
      ]);
    } else {
      this.addgoalForm.get('targetDate')?.clearValidators();
    }
    this.addgoalForm.get('targetDate')?.updateValueAndValidity();
  }

  areFormValuesNull(): boolean {
    const formValues = this.addgoalForm.value;
    for (const key in formValues) {
      if (formValues.hasOwnProperty(key) && formValues[key] === null) {
        return true;
      }
    }
    return false;
  }
}
