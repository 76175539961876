import { Component, ViewChild } from '@angular/core';
import { ApexNonAxisChartSeries, ApexPlotOptions, ApexChart, ApexFill, ChartComponent, ApexStroke } from "ng-apexcharts";
import { MasterData } from 'src/app/constants/admin-module-constant/admin-master-data.constant';
import { SubscriptionService } from 'src/app/services/admin-model-services/subscription/subscription.service';

export type ChartOptions = {
  series: ApexNonAxisChartSeries | undefined;
  chart: ApexChart | undefined;
  labels: string[] | undefined;
  plotOptions: ApexPlotOptions | undefined;
  fill: ApexFill | undefined;
  stroke: ApexStroke | undefined;
};

@Component({
  selector: 'app-subscriptions-view',
  templateUrl: './subscriptions-view.component.html',
  styleUrls: ['./subscriptions-view.component.scss']
})
export class SubscriptionsViewComponent {
  @ViewChild("chart") chart: ChartComponent | undefined;
  selectedTimeRange: string = 'monthly';
  totalSubscription: any;
  subscription: any;
  constructor(private subscriptionDataService: SubscriptionService) {
    this.subscription = MasterData.subscription
  }

  ngOnInit() {
    this.subscriptionData();
  }

  onTimeRangeChange() {
    this.subscriptionData();
  }

  subscriptionData() {
    this.subscriptionDataService.getSubscription(this.selectedTimeRange).subscribe((data: any) => {
      this.totalSubscription = data.data;
      this.subscription.series = [this.totalSubscription.totalSubscription];
    })
  }
}
