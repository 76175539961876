import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstant } from 'src/app/constants/app.constants';
import { ProjectManagementService } from 'src/app/services/project-management/project-management.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-costing-details',
  templateUrl: './view-costing-details.component.html',
  styleUrls: ['./view-costing-details.component.scss']
})
export class ViewCostingDetailsComponent implements OnInit {
  costingDetails: { label: any, value: any }[] = [];
  projectCostingForm: FormGroup;
  displayedColumns: string[] = ['resourceName', 'hourlyRate', 'totalWorkingHours', 'payableAmount'];
  resourceCostingDetail: { resourceName: string, hourlyRate: number, totalWorkingHours: number, payableAmount: number }[] = [];
  totalExpense!: any;
  totalBudget!: any;
  projectId!: any;
  remainingBudget!: number;

  constructor(private toastrService: ToastrService, private router: Router, private route: ActivatedRoute, private projectService: ProjectManagementService, private fb: FormBuilder) {
    const currentDate = new Date();
    this.projectCostingForm = this.fb.group({
      startDate: [''],
      endDate: [currentDate]
    });
  }

  ngOnInit(): void {
    if (this.projectCostingForm.get('startDate')?.value !== '') {
      this.projectCostingForm.get('endDate')?.valueChanges.subscribe(value => {
        if (value) {
          this.getResourceCostingDetails(this.projectCostingForm.get('startDate')?.value, value, this.projectId);
        }
      });
    }
    this.route.params.subscribe(params => {
      const projectId = params['id'];
      if (projectId !== undefined) {
        this.projectId = projectId
        this.getProjectCostingDetails(projectId);
      }
    });
  }

  goToBack() {
    this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.PROJECT_MANAGEMENT])
  }

  getProjectCostingDetails(projectId: any) {
    this.projectService.getProjectCostingDetails(projectId).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.costingDetails = data.data;
          this.totalBudget = this.costingDetails.find((res: any) => res.label === 'projectBudget')?.value;
          const startDateStr = this.costingDetails.find((res: any) => res.label === 'projectStartDate')?.value;
          const startDate = startDateStr ? this.parseDate(startDateStr) : null;
          const endDate = new Date();
          this.projectCostingForm.patchValue({
            startDate: startDate,
            endDate: endDate
          });
          this.getResourceCostingDetails(startDate, endDate, projectId);
        }
      }
    );
  }

  formatDateToBackend(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    const milliseconds = date.getMilliseconds().toString().padStart(3, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  getResourceCostingDetails(startDate: any, endDate: any, projectId: any) {
    if (this.totalBudget > 0) {
      this.totalExpense = 0;
      this.remainingBudget = 0;
      this.projectService.getResourceCostingDetails(this.formatDateToBackend(startDate), this.formatDateToBackend(endDate), projectId).subscribe(
        (data: any) => {
          if (data.status === 200) {
            this.resourceCostingDetail = data.data;
            for (let resource of this.resourceCostingDetail) {
              this.totalExpense = this.totalExpense + resource.payableAmount;
            }
            this.remainingBudget = this.totalBudget - this.totalExpense;
          }
          else if (data.status === 404) {
            this.toastrService.clear();
            this.toastrService.error(data.message);
          }
        }
      )
    }
  }

  parseDate(dateString: string): Date | null {
    if (!dateString) return null;
    const [day, month, year] = dateString.split('/').map(part => parseInt(part, 10));
    return new Date(year, month - 1, day);
  }

  convertLabel(label: string): string {
    let formattedLabel = label.replace(/([a-z])([A-Z])/g, '$1 $2');
    formattedLabel = formattedLabel.replace(/([a-zA-Z\s])([0-9])/g, '$1 $2');
    return formattedLabel.charAt(0).toUpperCase() + formattedLabel.slice(1);
  }

  formatDateToDDMMYYYY(date: Date): string {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  onDateChange() {
    const startDate = this.projectCostingForm.get('startDate')?.value;
    const endDate = this.projectCostingForm.get('endDate')?.value;
    if (startDate && endDate) {
      const formattedStartDate = this.formatDateToDDMMYYYY(startDate);
      const formattedEndDate = this.formatDateToDDMMYYYY(endDate);
    }
  }
}
