import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AssetManagementDashboardService } from 'src/app/services/asset-management/asset-management-dashboard/asset-management-dashboard.service';
import { MasterDataService } from 'src/app/services/master-data/master-data.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-supplier-info',
  templateUrl: './supplier-info.component.html',
  styleUrls: ['./supplier-info.component.scss']
})
export class SupplierInfoComponent implements OnInit {
  notEditMode: boolean = true;
  supplierInfo !: FormGroup;
  supplierdata: any;
  editMode: any;
  id: any;
  countryList: any;
  stateList: any;
  cityList: any;
  selectedCountry: string = "";
  selectedState: string = "";
  selectedCity: string = "";
  supplierList: any[] = [];
  assets: string[] = [
    'Laptop',
    'Mouse',
    'Keyboard',
    'Monitor',
    'PC'
  ];
  businessTypes: string[] = [
    'Sole proprietorship',
    'Partnership',
    'Service businesses',
    'Retailer',
    'Dealer',
    'Distributor',
  ];

  constructor(private masterDataService: MasterDataService, private formBuilder: FormBuilder, private assetManagementService: AssetManagementDashboardService, private router: Router,
    private activeRoute: ActivatedRoute, private toasterService: ToastrService,) {
    this.supplierInfo = this.formBuilder.group({
      supplierId: ["", [Validators.required]],
      supplierName: ["", [Validators.required]],
      businessName: ["", [Validators.required]],
      businessType: ["", [Validators.required]],
      assetType: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      supplierAddress2: [""],
      state: ["", [Validators.required]],
      pincode: ["", [Validators.required]],
      bankName: ["", [Validators.required]],
      ifscCode: ["", [Validators.required]],
      contactNumber: ["", [Validators.required]],
      supplierAddress: ["", [Validators.required]],
      country: ["", [Validators.required]],
      city: ["",],
      gstNumber: ["", [Validators.required]],
      accountNumber: ["", [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.getCountryList();
    this.activeRoute.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.supplierinformation(this.id);
    });
  }

  getCountryList() {
    this.masterDataService.getAllCountries().subscribe(
      (data: any) => {
        if (data.status === 200)
          this.countryList = data.data;
      }
    )
  }

  getState(country: any) {
    this.selectedCountry = country;
    this.masterDataService.getAllState(country).subscribe(
      (data: any) => {
        this.stateList = data.data;
      }
    )
  }

  getCity(state: any) {
    this.selectedState = state;
    this.masterDataService.getAllCity(state).subscribe(
      (data: any) => {
        this.cityList = data.data;
      }
    )
  }

  selectedCityName(city: any) {
    this.selectedCity = city;
  }

  onEditMode() {
    this.notEditMode = !this.notEditMode;
    if (this.notEditMode) {
      const dto = {
        supplierId: this.supplierInfo.value.supplierId,
        supplierName: this.supplierInfo.value.supplierName,
        businessName: this.supplierInfo.value.businessName,
        businessType: this.supplierInfo.value.businessType,
        assetType: this.supplierInfo.value.assetType,
        email: this.supplierInfo.value.email,
        supplierAddress2: this.supplierInfo.value.supplierAddress2,
        state: this.supplierInfo.value.state,
        pinCode: this.supplierInfo.value.pincode,
        bankName: this.supplierInfo.value.bankName,
        ifscCode: this.supplierInfo.value.ifscCode,
        contactNo: this.supplierInfo.value.contactNumber,
        supplierAddress1: this.supplierInfo.value.supplierAddress,
        country: this.supplierInfo.value.country,
        city: this.supplierInfo.value.city,
        gstNumber: this.supplierInfo.value.gstNumber,
        accountNo: this.supplierInfo.value.accountNumber,
      };
      this.assetManagementService.editSupplier(this.id, dto).subscribe(
        (data: any) => {
          if (data.status == 200) {
            this.supplierinformation(this.id);
            this.toasterService.clear();
            this.toasterService.success(data.message);
          }
          else {
            this.toasterService.clear();
            this.toasterService.error(data.message);
          }
        }
      )
    }
  }

  validateNumberAndSpaceAndHyphenAllowed(event: any) {
    return ValidationUtil.characterNumberAndSpaceAndHyphenAllowed(event);
  }

  addressVerification(event: any) {
    return ValidationUtil.addressVerification(event)
  }


  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  supplierinformation(supplierId: any) {
    this.assetManagementService.getSupplierById(supplierId).subscribe(
      (data: any) => {
        if (data.status == 200) {
          this.getState(data.data.country);
          this.getCity(data.data.state);
          this.selectedCity = data.data.city;
          this.supplierInfo.patchValue({
            supplierId: data.data.id,
            supplierName: data.data.supplierName,
            supplierAddress: data.data.supplierAddress1,
            supplierAddress2: data.data.supplierAddress2,
            businessName: data.data.businessName,
            businessType: data.data.businessType,
            assetType: data.data.assetType,
            email: data.data.email,
            country: this.selectedCountry,
            state: this.selectedState,
            city: this.selectedCity,
            pincode: data.data.pinCode,
            gstNumber: data.data.gstNumber,
            bankName: data.data.bankName,
            accountNumber: data.data.accountNo,
            contactNumber: data.data.contactNo,
            ifscCode: data.data.ifscCode,

          })
          this.supplierdata = data.data;
        }
        else {
          this.toasterService.clear()
          this.toasterService.error(data.message);
        }
      }
    );
  }
}

