import { ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { employeeRatingData, getSummaryData, goalReviewDate, managerRatingData, managerSummaryData, ratingData, summaryData } from 'src/app/model/appraisal/appraisal.model';
import { AppraisalServiceService } from 'src/app/services/apprisal-service/appraisal-service.service';
import { ValidationUtil } from 'src/app/utils/validators.util';
import { AddGoalsComponent } from '../add-goals/add-goals.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-employee-performance-goal',
  templateUrl: './employee-performance-goal.component.html',
  styleUrls: ['./employee-performance-goal.component.scss']
})

export class EmployeePerformanceGoalComponent {
  ratingForm: any = FormGroup;
  EmployeeRating: any;
  EmployeeComments: any;
  data: any[] = [];
  employeeRatingData: any[] = [];
  ratingInput: boolean = true
  url: any;
  employeeGoal: any;
  data1: any;
  emplpoyeeRating: any;
  calculatedRating: any;
  progressValue: number = 0;
  isRating: boolean = false;
  progressBarValue: number = 0;
  progressIncrementValue: number = 0;
  managerRatingProgressValue: number = 0;
  managerSideRatingProgressBar: number = 0;
  display: boolean = false;
  isManager: boolean = false;
  employeeRatingForm!: FormGroup;
  goalReviewByManagerForm!: FormGroup;
  summaryForm!: FormGroup;
  managerSummaryForm!: FormGroup;
  managerRatingForm!: FormGroup;
  employeeRating!: employeeRatingData;
  employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
  companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
  viewEmployeeGoal = sessionStorage.getItem(AppConstant.EMPLOYEE_UNDER_MANAGER);
  totalGoals: number = 0;
  ratedGoals: number = 0;
  isSummary: boolean = true;
  employeeCalculatedRating: any;
  employeeComments: any;
  getSummaryDetail: any;
  isMangerSummaryComment = true;
  isGoalButtonVisible: boolean = false;
  isViewGoals: boolean = false;
  isGoalReviewButtonVisible: boolean = false;
  isGoalRatingButtonVisible: boolean = false;
  isGoalRatingReviewButtonVisible: boolean = false;
  isRatingReleaseButtonVisible: boolean = false;
  historyYear = sessionStorage.getItem(AppConstant.YEAR_HISTORY);
  viewEmpGoalsByManager = sessionStorage.getItem(AppConstant.EMPLOYEE_UNDER_MANAGER);
  year = new Date().getFullYear()
  isRejectPopupVisible: boolean = false;
  isPerformance: boolean = false;
  currentYear: any;
  category = sessionStorage.getItem(AppConstant.CATEGOERY);
  constructor(private dialog: MatDialog, private cdr: ChangeDetectorRef, private router: Router, private formBuilder: FormBuilder, private appraisalService: AppraisalServiceService, private toasterService: ToastrService) {
    const now = new Date();
    this.currentYear = now.getFullYear();
  }

  ngOnInit() {
    const isManager = sessionStorage.getItem(AppConstant.IS_MANAGER);
    const viewEmployeeGoalsById = sessionStorage.getItem(AppConstant.EMPLOYEE_UNDER_MANAGER)
    if (isManager == 'true') {
      this.employeeId = viewEmployeeGoalsById;
    }

    else {
      this.employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
    }

    this.ratingForm = this.formBuilder.group({
      EmployeeRating: ['', [Validators.required, ValidationUtil.allowUptoFive()]],
      EmployeeComments: ['', [Validators.required]],
      ManagerRating: ['', [Validators.required, ValidationUtil.allowUptoFive()]],
      ManagerComments: ['', [Validators.required]]
    });
    this.getEmployeeGolesCategory();
    if (sessionStorage.getItem(AppConstant.IS_MANAGER = 'true')) {
      this.isManager = true;
    }
    else {
      this.isManager = false;
    }
    this.initEmployeeRatingForm()
    this.initManagerRating();
    this.initSummaryForm();
    this.getSummaryDetails();
    this.initManagerSummaryForm();
    this.annualSettingConfiguration();
    this.initgoalsReviewByManager();
  }

  initManagerRating() {
    this.managerRatingForm = this.formBuilder.group({
      'rating': ['', [Validators.required, ValidationUtil.allowUptoFive()]],
      'comments': ['', [Validators.required]]
    })
  }

  initgoalsReviewByManager() {
    this.goalReviewByManagerForm = this.formBuilder.group({
      "status": [''],
      'description': ['']
    })
  }

  initEmployeeRatingForm() {
    this.employeeRatingForm = this.formBuilder.group({
      'empComments': ['', [Validators.required]],
      'empRating': ['', [Validators.required, ValidationUtil.allowUptoFive()]]
    })
  }

  initSummaryForm() {
    this.summaryForm = this.formBuilder.group({
      'commonGoalComments': ['', [Validators.required]],
      'year': ['']
    })
  }


  initManagerSummaryForm() {
    this.managerSummaryForm = this.formBuilder.group({
      'managerComments': ['', [Validators.required]],
      'year': ['']
    })
  }

  getEmployeeGolesCategory() {
    if (this.category == AppConstant.PERFORMANCE) {
      this.isPerformance = true;
    }
    this.appraisalService.getEmployeeGoalsCategory(this.companyId, this.employeeId, this.category, this.currentYear).subscribe(
      (data: any) => {
        this.employeeGoal = data.data;
        this.initProgressBar(this.employeeGoal);
        if (this.isManager) {
          this.initManagerProgressBar(this.employeeGoal);
        }
      }
    );
  }
  getManagerRating() {
    this.appraisalService.getManagerRating(this.companyId, this.viewEmpGoalsByManager).subscribe(
      (data: any) => {
        this.data = data.data;
      }
    )
  }

  initProgressBar(employeeGoal: any[]) {
    this.totalGoals = employeeGoal.length;
    this.ratedGoals = employeeGoal.filter(goal => goal.empRating !== null).length;
    this.progressIncrementValue = 100 / this.totalGoals;
    this.progressBarValue = (this.ratedGoals / this.totalGoals) * 100;
  }

  initManagerProgressBar(employeeGoal: any[]) {
    this.totalGoals = employeeGoal.length;
    this.ratedGoals = employeeGoal.filter(goal => goal.managerRatting != null).length;
    this.managerRatingProgressValue = (this.ratedGoals / this.totalGoals) * 100;
  }



  hasEmployeeRated(goalName: string): boolean {
    return !!this.employeeRatingData.find(item => item.goalName === goalName);
  }

  employeeRatingSave(data: any) {
    if (this.employeeRatingForm.invalid) {
      this.employeeRatingForm.markAllAsTouched();
      return;
    }
    const employeeRating: employeeRatingData = {
      "empRating": this.employeeRatingForm.value.empRating,
      "empComments": this.employeeRatingForm.value.empComments,
      "employeeId": this.employeeId,
      "goalName": data.goalName,
      companyId: this.companyId
    };
    this.appraisalService.AddEmployeeRating(employeeRating).subscribe(
      (data: any) => {
        this.employeeRatingForm.reset();
        if (data.status == 200) {
          this.toasterService.clear();
          this.toasterService.success(data.message);
          this.updateProgressBar();
          this.getEmployeeGolesCategory();
        }
        else {
          this.toasterService.clear();
          this.toasterService.error(data.message);
        }
      }
    )
  }

  managerRatingSave(data: any) {
    if (this.managerRatingForm.invalid) {
      this.managerRatingForm.markAllAsTouched();
      return;
    }

    const managerRating: managerRatingData = {
      "companyId": this.companyId,
      "employeeId": this.employeeId,
      "goalName": data.goalName,
      "rating": this.managerRatingForm.value.rating,
      "comments": this.managerRatingForm.value.comments
    };

    this.appraisalService.addManagerRating(managerRating).subscribe(
      (data: any) => {
        if (data.status == 200) {
          this.managerRatingForm.reset();
          this.toasterService.clear();
          this.toasterService.success(data.message);
          this.updateProgressBar();
          this.getManagerRating();
          this.getEmployeeGolesCategory();
        }
        else {
          this.toasterService.clear();
          this.toasterService.error(data.message);
        }
      }
    )
  }
  getSummaryDetails() {
    this.appraisalService.getSummary(this.companyId, this.employeeId, this.year).subscribe(
      (data: any) => {
        if (data.data !== null) {
          this.getSummaryDetail = data.data;
          if (this.getSummaryDetail.managerAvgRatting === null || this.getSummaryDetail.managerSummaryComments === null) {
            this.isMangerSummaryComment = true;
          } else {
            this.isMangerSummaryComment = false;
          }
          this.employeeCalculatedRating = data.data.avgRatingEmpGoal;
          this.employeeComments = data.data.empSummaryComments;
        }
      }
    );
  }

  saveSummary() {
    const summaryRating: summaryData = {
      "companyId": this.companyId,
      "employeeId": this.employeeId,
      "commonGoalComments": this.summaryForm.value.commonGoalComments,
      "year": new Date().getFullYear()
    };

    this.appraisalService.addSummary(summaryRating).subscribe(
      (data: any) => {
        if (data.status == 200) {
          this.getSummaryDetails();
          this.toasterService.clear();
          this.toasterService.success(data.message);
        } else {
          this.toasterService.clear();
          this.toasterService.error(data.message);
        }
      },

    );

  }

  saveManagerSummary() {
    const summaryRating: managerSummaryData = {
      "companyId": this.companyId,
      "employeeId": this.employeeId,
      "managerComments": this.summaryForm.value.managerComments,
      "year": new Date().getFullYear()
    };

    this.appraisalService.addManagerSummary(summaryRating).subscribe(
      (data: any) => {
        if (data.status == 200) {
          this.getSummaryDetails();
          this.toasterService.clear();
          this.toasterService.success(data.message);
        } else {
          this.toasterService.clear();
          this.toasterService.error(data.message);
        }
      },

    );

  }

  back() {
    this.router.navigate([AppConstant.EMPLOYEE_SIDENAV + AppConstant.SEPERATOR + AppConstant.VIEW_EMPLOYEE_APPRISAL])
  }

  updateProgressBar() {
    this.progressBarValue += this.progressIncrementValue;
  }

  updateManagerRatingProgressBar() {
    this.managerSideRatingProgressBar += this.managerRatingProgressValue;
  }

  addGoals(): void {
    const dialogRef = this.dialog.open(AddGoalsComponent, {
      width: '680px',
      maxHeight: '90vh',
      panelClass: 'custom-dialog-container'
    });

    dialogRef.afterClosed().subscribe((data: any) => {
      this.getEmployeeGolesCategory();
    });
  }

  finalRatingOfEmployee() {
  }

  annualSettingConfiguration(): void {
    this.appraisalService.getAppraisalSetting(this.companyId, this.historyYear).subscribe((data: any) => {
      if (data.status === 200) {
        const periodType = data.data.periodType;
        const currentDate = new Date();
        let goalButtonVisible = false;
        let goalReviewButtonVisible = false;
        let goalRatingButtonVisible = false;
        let goalRatingReviewButtonVisible = false;
        let ratingReleaseButtonVisible = false;

        if (periodType === 'Annual') {
          const goalSettingStartDate = new Date(data.data.goalSettingStartDate);
          const goalSettingEndDate = new Date(data.data.goalSettingEndDate);
          const goalReviewStartDate = new Date(data.data.goalReviewStartDate);
          const goalReviewEndDate = new Date(data.data.goalReviewEndDate);
          const goalRatingStartDate = new Date(data.data.goalRatingStartDate);
          const goalRatingEndDate = new Date(data.data.goalRatingEndDate);
          const goalRatingReviewStartDate = new Date(data.data.goalRatingReviewStartDate);
          const goalRatingReviewEndDate = new Date(data.data.goalRatingReviewEndDate);
          const ratingReleaseStartDate = new Date(data.data.ratingReleaseStartDate);
          const ratingReleaseEndDate = new Date(data.data.ratingReleaseEndDate);

          goalButtonVisible = currentDate >= goalSettingStartDate && currentDate <= goalSettingEndDate;
          goalReviewButtonVisible = currentDate >= goalReviewStartDate && currentDate <= goalReviewEndDate;
          goalRatingButtonVisible = currentDate >= goalRatingStartDate && currentDate <= goalRatingEndDate;
          goalRatingReviewButtonVisible = currentDate >= goalRatingReviewStartDate && currentDate <= goalRatingReviewEndDate;
          ratingReleaseButtonVisible = currentDate >= ratingReleaseStartDate && currentDate <= ratingReleaseEndDate;
        } else if (periodType === 'Semiannual') {
          const firstHalfGoalSettingStartDate = new Date(data.data.firstHalfGoalSettingStartDate);
          const firstHalfGoalSettingEndDate = new Date(data.data.firstHalfGoalSettingEndDate);
          const secondHalfGoalSettingStartDate = new Date(data.data.secondHalfGoalSettingStartDate);
          const secondHalfGoalSettingEndDate = new Date(data.data.secondHalfGoalSettingEndDate);

          const firstHalfGoalReviewStartDate = new Date(data.data.firstHalfGoalReviewStartDate);
          const firstHalfGoalReviewEndDate = new Date(data.data.firstHalfGoalReviewEndDate);
          const secondHalfGoalReviewStartDate = new Date(data.data.secondHalfGoalReviewStartDate);
          const secondHalfGoalReviewEndDate = new Date(data.data.secondHalfGoalReviewEndDate);

          const firstHalfGoalRatingStartDate = new Date(data.data.firstHalfGoalRatingStartDate);
          const firstHalfGoalRatingEndDate = new Date(data.data.firstHalfGoalRatingEndDate);
          const secondHalfGoalRatingStartDate = new Date(data.data.secondHalfGoalRatingStartDate);
          const secondHalfGoalRatingEndDate = new Date(data.data.secondHalfGoalRatingEndDate);

          const firstHalfGoalRatingReviewStartDate = new Date(data.data.firstHalfGoalRatingReviewStartDate);
          const firstHalfGoalRatingReviewEndDate = new Date(data.data.firstHalfGoalRatingReviewEndDate);
          const secondHalfGoalRatingReviewStartDate = new Date(data.data.secondHalfGoalRatingReviewStartDate);
          const secondHalfGoalRatingReviewEndDate = new Date(data.data.secondHalfGoalRatingReviewEndDate);

          const firstHalfRatingReleaseStartDate = new Date(data.data.firstHalfRatingReleaseStartDate);
          const firstHalfRatingReleaseEndDate = new Date(data.data.firstHalfRatingReleaseEndDate);
          const secondHalfRatingReleaseStartDate = new Date(data.data.secondHalfRatingReleaseStartDate);
          const secondHalfRatingReleaseEndDate = new Date(data.data.secondHalfRatingReleaseEndDate);

          goalButtonVisible = (currentDate >= firstHalfGoalSettingStartDate && currentDate <= firstHalfGoalSettingEndDate) ||
            (currentDate >= secondHalfGoalSettingStartDate && currentDate <= secondHalfGoalSettingEndDate);

          goalReviewButtonVisible = (currentDate >= firstHalfGoalReviewStartDate && currentDate <= firstHalfGoalReviewEndDate) ||
            (currentDate >= secondHalfGoalReviewStartDate && currentDate <= secondHalfGoalReviewEndDate);

          goalRatingButtonVisible = (currentDate >= firstHalfGoalRatingStartDate && currentDate <= firstHalfGoalRatingEndDate) ||
            (currentDate >= secondHalfGoalRatingStartDate && currentDate <= secondHalfGoalRatingEndDate);

          goalRatingReviewButtonVisible = (currentDate >= firstHalfGoalRatingReviewStartDate && currentDate <= firstHalfGoalRatingReviewEndDate) ||
            (currentDate >= secondHalfGoalRatingReviewStartDate && currentDate <= secondHalfGoalRatingReviewEndDate);

          ratingReleaseButtonVisible = (currentDate >= firstHalfRatingReleaseStartDate && currentDate <= firstHalfRatingReleaseEndDate) ||
            (currentDate >= secondHalfRatingReleaseStartDate && currentDate <= secondHalfRatingReleaseEndDate);
        }

        this.isGoalButtonVisible = goalButtonVisible;
        this.isGoalReviewButtonVisible = goalReviewButtonVisible;
        this.isGoalRatingButtonVisible = goalRatingButtonVisible;
        this.isGoalRatingReviewButtonVisible = goalRatingReviewButtonVisible;
        this.isRatingReleaseButtonVisible = ratingReleaseButtonVisible;
      }
    });
  }

  goalsApprovedByManager() {
    const goalReview: goalReviewDate = {
      "companyId": this.companyId,
      "employeeId": this.viewEmployeeGoal,
      "description": this.goalReviewByManagerForm.value.description,
      "status": AppConstant.ACCEPT,
      "year": new Date().getFullYear()
    };

    this.appraisalService.approvedGoalsByManager(goalReview).subscribe(
      (data: any) => {
        if (data.status == 200) {
          this.toasterService.clear();
          this.toasterService.success(data.message);
        } else {
          this.toasterService.clear();
          this.toasterService.error(data.message);
        }
      },
    );
  }

  goalsRejectedByManager() {
    this.isRejectPopupVisible = true;
  }

  submitRejection(): void {

    const goalReview: goalReviewDate = {
      "companyId": this.companyId,
      "employeeId": this.viewEmployeeGoal,
      "description": this.goalReviewByManagerForm.value.description,
      "status": AppConstant.REJECT,
      "year": new Date().getFullYear()
    };

    this.appraisalService.approvedGoalsByManager(goalReview).subscribe(
      (data: any) => {
        if (data.status == 200) {
          this.isRejectPopupVisible = false;
          this.toasterService.clear();
          this.toasterService.success(data.message);
        } else {
          this.toasterService.clear();
          this.toasterService.error(data.message);
        }
      },
    );

  }

  closePopup(): void {
    this.isRejectPopupVisible = false;
  }

}
