import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Messages } from "../constants/messages.constant";

export class ValidationUtil {

    public static validateNumber(event: { which: any; keyCode: any; }): boolean {
        const charCode = this.getCharCode(event);
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    public static validateNumbers(event: any) {
        const inputChar = String.fromCharCode(event.charCode);
        const pattern = /^[a-zA-Z0-9]+$/;

        if (!pattern.test(inputChar)) {
            event.preventDefault();
            return false;
        }
        return true;
    }

    public static quantityGreaterThanZero(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            const isValid = value > 0 && value <= 1000000000;
            return isValid ? null : { quantityInRange: true };
        };
    }

    public static validateGST(gstNumber: string) {
        var reggst = /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([0-9]){1}?$/;
        if (!reggst.test(gstNumber)) {
            return false
        }

        return true;
    }

    public static validateIFSC(ifscCode: string) {
        var regIfsc = /^[A-Z]{4}0[A-Z0-9]{6}$/;
        if (!regIfsc.test(ifscCode)) {
            return false;
        }

        return true;
    }


    public static passwordValidation(password: string) {
        if (password != "" && password.length >= 8) {
            return true;
        }
        return false;
    }

    public static confirmPasswordValidation(confirmPassword: string) {
        if (confirmPassword != "" && confirmPassword.length >= 8) {
            return true;
        }
        return false;
    }

    static validateCharacter(event: KeyboardEvent): void {
        const allowedChars = /[a-zA-Z]/;
        if (!allowedChars.test(event.key)) {
            event.preventDefault();
        }
    }

    public static onlyAlphanumeric(event: any) {
        const inputChar = String.fromCharCode(event.keyCode || event.which);
        const pattern = /^[a-zA-Z0-9]+$/;
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }

    public static onlyAlphanumericAndBackSlash(event: any) {
        const inputChar = String.fromCharCode(event.keyCode || event.which);
        const charCode = this.getCharCode(event);
        const pattern = /^[a-zA-Z0-9]+$/;
        if (!pattern.test(inputChar) && charCode != 47) {
            event.preventDefault();
        }
    }

    public static allowedAlphNumericAndSomeSymbols(event: any) {
        const allowedKeys = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789 .:|{}&<>/()'.split('');
        if (!allowedKeys.includes(event.key) && event.key !== 'Backspace' && event.key !== 'Tab') {
            event.preventDefault();
        }
    }

    public static validateRequestBody(changePasswordRequestBody: any) {
        if (ValidationUtil.passwordValidation(changePasswordRequestBody.password) &&
            ValidationUtil.passwordValidation(changePasswordRequestBody.confirmPassword) &&
            changePasswordRequestBody.password === changePasswordRequestBody.confirmPassword) {
            return true;
        }
        return false;
    }

    public static mustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName]
            if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
                return;
            }
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true })
            }
            else {
                matchingControl.setErrors(null);
            }
        }
    }

    public static validateMobileNumber(mobileNumber: string) {
        if (RegExp(/^0+$/).exec(mobileNumber)) {
            return false;
        }
        return true;
    }

    public static onlySpacesOrNumbersValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const inputValue = control.value;
            if (inputValue && !/^[a-zA-Z0-9\s]*$/.test(inputValue)) {
                return { invalidInput: true };
            }
            return null;
        };
    }

    public static maxValueValidator(maxValue: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value;
            if (value && parseFloat(value) > maxValue) {
                return { 'maxValueExceeded': true };
            }
            return null;
        };
    }

    public static percentageValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            if (control.value && (control.value < 0 || control.value > 100)) {
                return { invalidPercentage: true };
            }
            return null;
        };
    }

    public static onlyDigitsValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value = control.value;
            if (value && !/^\d+$/.test(value)) {
                return { 'onlyDigits': true };
            }
            return null;
        };
    }

    public static positiveNumberValidator(): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = parseFloat(control.value);
            return !isNaN(value) && value > 0 ? null : { notPositive: true };
        };
    }

    public static validMobileNumber(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const mobileNumber = control.value;

            if (!mobileNumber) {
                return { required: true };
            }
            if (mobileNumber && mobileNumber.length !== 10) {
                return { invalidMobileNumber: true };
            }
            return null;
        };
    }

    public static emailValidation(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            let inputValue = control.value;
            inputValue = inputValue.toLowerCase();

            if (inputValue) {
                if (!inputValue.match("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$")) {
                    return { invalidEmail: true }
                } 
            }
            return null;
        }
    }

    public static getPanCardPattern() {
        return "^([A-Z]){5}([0-9]){4}([A-Z]){1}$";
    }

    public static getPasswordPattern(): RegExp {
        return /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/;
    }

    public static getRegistrationPasswordPattern(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const pattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/;
            const valid = pattern.test(control.value);
            return valid ? null : { pattern: true };
        };
    }

    public static ageValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: boolean } | null => {
            const birthDate = new Date(control.value);
            const currentDate = new Date();
            const age = currentDate.getFullYear() - birthDate.getFullYear();
            if (age < 18 || age > 60) {
                return { 'invalidAge': true };
            }
            return null;
        };
    }

    static personalEmailDomainValidator(control: FormControl) {
        const allowedDomains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com'];
        let email = control.value;
        email = email.toLowerCase();

        if (email && email.indexOf('@') !== -1) {
            const domain = email.split('@')[1];
            if (allowedDomains.includes(domain)) {
                return null; // Valid domain
            } else {
                return { invalidDomain: true };
            }
        }

        return null;

    }

    public static fileSizeValidator(control: any): { [key: string]: any } | null {
        const file = control.value;
        if (file && file.size > 1 * 1024 * 1024) {
            return { fileSizeExceeded: false };
        }
        return null;
    }

    public static dateFormatter(params: any) {
        const date = new Date(params.value);
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    public static toDateBeforeFromDateValidator(fromDateControlName: string, toDateControlName: string): ValidatorFn {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            const fromDate = formGroup.get(fromDateControlName)?.value;
            const toDate = formGroup.get(toDateControlName)?.value;
            if (fromDate && toDate) {
                const fromDateObj = new Date(fromDate);
                const toDateObj = new Date(toDate);
                const oneMonthAfterFromDate = new Date(fromDateObj);
                oneMonthAfterFromDate.setMonth(oneMonthAfterFromDate.getMonth() + 1);
                if (toDateObj < oneMonthAfterFromDate) {
                    return { toDateBeforeFromDate: true };
                }
            }
            return null;
        };
    }

    static characterAndSpaceAllowed(event: KeyboardEvent): void {
        const allowedChars = /[a-zA-Z\s]/;
        if (!allowedChars.test(event.key)) {
            event.preventDefault();
        }
    }
    static characterAndSymbolAllowed(event: KeyboardEvent): void {
        const allowedChars = /[a-zA-Z\s,<>/?|()&:;.0-9!'\\-]/;
        if (!allowedChars.test(event.key)) {
            event.preventDefault();
        }
    }

    static characterNumberAndSpaceAllowed(event: KeyboardEvent): void {
        const allowedChars = /[A-Za-z0-9 _ -\s]/;
        if (!allowedChars.test(event.key)) {
            event.preventDefault();
        }
    }

    //characters, numbers, spaces, hyphen, comma, underscore, fullstop, &, @, ' allowed
    static companyNameValidation(event: KeyboardEvent): void {
        const allowedChars = /[A-Za-z0-9 _\s]/;
        const charCode = this.getCharCode(event);
        if (!allowedChars.test(event.key) && charCode != 32 && charCode != 45 && charCode != 44 && charCode != 95
            && charCode != 46 && charCode != 38 && charCode != 64 && charCode != 39) {
            event.preventDefault();
        }
    }

    static addressVerification(event: KeyboardEvent): void {
        const allowedChars = /[A-Za-z0-9 _\s]/;
        const charCode = this.getCharCode(event);

        if (!allowedChars.test(event.key) && charCode != 45 && charCode != 46 && charCode != 44) {
            event.preventDefault();
        }
    }

    static characterNumberAndSpaceAndHyphenAllowed(event: KeyboardEvent): void {
        const allowedChars = /[A-Za-z0-9 _\s]/;
        const charCode = this.getCharCode(event);
        if (!allowedChars.test(event.key) && charCode != 45) {
            event.preventDefault();
        }
    }

    static alternateEmailValidation(control: FormControl) {
        const allowedDomains = ['gmail.com', 'yahoo.com', 'hotmail.com', 'outlook.com'];
        let email = control.value;
        email = email.toLowerCase();

        if (!email) {
            return { required: true };
        }

        if (!email.match("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$")) {
            return { invalidEmail: true };
        }

        const domain = email.split('@')[1];
        if (!allowedDomains.includes(domain)) {
            return { invalidDomain: true };
        }

        return null;
    }

    public static alternateEmailIsRequired(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            let inputValue = control.value;
            inputValue = inputValue.toLowerCase();

            if (!inputValue) {
                return { required: true };
            }

            if (!inputValue.match("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$")) {
                return { invalidEmail: true };
            }

            return null;
        };
    }

    public static pfValidation(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const inputValue = control.value;
            if (!inputValue) {
                return { required: true };
            }
            // if (!inputValue.match(/^[A-Z]{2}[A-Z]{3}[0-9]{7}$/)) {
            //     return { invalidPF: true };
            // }
            return null;
        }
    }


    public static uanNumberValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const uan = control.value;
            if (!uan) {
                return null;
            }

            const isValid = /^\d{12}$/.test(uan);

            return isValid ? null : { invalidUanNumber: true };
        };

    }

    public static allowUptoFive(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const weigths = control.value;
            if (weigths && !/^([1-5]|[1-5][1-5]|5)$/.test(weigths)) {
                return { invalidInput: true };
            }
            return null;
        };
    }

    public static allowUptoHundered(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const weigths = control.value;
            if (weigths && !/^([1-9]|[1-9][0-9]|100)$/.test(weigths)) {
                return { invalidInput: true };
            }
            return null;
        };
    }

    public static alphabetOnly($event: any) {
        const userName = String.fromCharCode($event.keycode || $event.which);
        const pattern = /^[a-zA-Z _]+$/;
        if (!pattern.test(userName)) {
            $event.preventDefault();
        }
    }

    public static numberOnly($event: any) {
        const mobileNo = String.fromCharCode($event.keycode || $event.which);
        const pattern = /^[0-9]+$/;
        if (!pattern.test(mobileNo)) {
            $event.preventDefault();
        }
    }

    public static alphanumberOnly($event: any) {
        const mobileNo = String.fromCharCode($event.keycode || $event.which);
        const pattern = /^[a-zA-Z0-9 ]+$/;
        if (!pattern.test(mobileNo)) {
            $event.preventDefault();
        }
    }

    public static emptyField(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const inputValue = control.value;

            if (!inputValue) {
                return { required: true };
            }

            if (inputValue.value == undefined) {
                return { emptyField: true };
            }

            return null;
        }
    }

    public static anuualCTCValidation(event: KeyboardEvent) {
        const input = event.key;
        const isNumber = /^\d+$/.test(input);

        if (!isNumber && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(input)) {
            event.preventDefault();
            return;
        }

        const inputValue = parseFloat((<HTMLInputElement>event.target).value + input);
        if (inputValue > 200000000) {
            event.preventDefault();
            return;
        }
        return;
    }

    public static noOnlySpacesValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const isValid = control.value && control.value.trim().length > 0;
            return isValid ? null : { noOnlySpaces: true };
        };
    }

    public static percentAmountValidation(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const isValid = (control.value > 100) ? false : true;
            return isValid ? null : { wrongPercentValue: true };
        };
    }

    static getCharCode(event: { which: any; keyCode: any; }) {
        return (event.which) ? event.which : event.keyCode;
    }

    public static allowedOnlyCharacterAndNumber(event: {
        preventDefault(): unknown;
        target: any; which: any; keyCode: any; key: string
    }): boolean {
        const charCode = event.which || event.keyCode;
        const key = event.key.toUpperCase();
        const target = event.target as HTMLInputElement;
        if (charCode >= 48 && charCode <= 57) {
            if (target.value.length >= 8) {
                event.preventDefault();
                return false;
            }
            return true;
        }
        if (key >= 'A' && key <= 'Z') {
            if (target.value.length >= 6) {
                event.preventDefault();
                return false;
            }
            target.value = target.value + key;
            event.preventDefault();
            return false;
        }
        event.preventDefault();
        return false;
    }

    public static greaterThanZeroValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            return value > 0 ? null : { greaterThanZero: true };
        };
    }
}