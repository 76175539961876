import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { AssetManagementDashboardService } from 'src/app/services/asset-management/asset-management-dashboard/asset-management-dashboard.service';
import { ValidationUtil } from 'src/app/utils/validators.util';

@Component({
  selector: 'app-add-asset-popup',
  templateUrl: './add-asset-popup.component.html',
  styleUrls: ['./add-asset-popup.component.scss']
})
export class AddAssetPopupComponent implements OnInit {
  assetForm !: FormGroup;
  isLaptop: Boolean = false;
  supplierList: any[] = [];
  assets: string[] = ['Laptop', 'Mouse', 'Keyboard', 'Monitor'];
  ramList: string[] = ['2', '4', '8', '16', '32', '64', '128', '256', '512'];
  processorList: string[] = ['i3', 'i5', 'i7', 'i9', 'Ryzen 3', 'Ryzen 5', 'Ryzen 7', 'Ryzen 9', 'Threadripper'];
  modelName: string[] = ['HP', 'Dell', 'Lenovo', 'Acer', 'Apple', 'Asus',];
  ssdList: string[] = ['128GB', '256GB', '512GB', '1TB', '2TB', '4TB'];

  constructor(private formBuilder: FormBuilder, private matDialog: MatDialog,
    private addAssetService: AssetManagementDashboardService, private toasterService: ToastrService,) { }

  ngOnInit(): void {
    this.getSupplierList();
    this.assetForm = this.formBuilder.group({
      assetType: ["", [Validators.required]],
      modelName: ["", [Validators.required]],
      modelNo: ["", [Validators.required]],
      ram: ["",],
      ssd: ["",],
      processor: ["",],
      specification: ["",],
    });

    this.assetForm.get('assetType')?.valueChanges.subscribe
      ((value: any) => {
        if (value === 'Laptop') {
          this.isLaptop = true;
        }
        else {
          this.isLaptop = false;
        }
      })
  }

  getSupplierList() {
    this.addAssetService.getSupplierList().subscribe(
      (data: any) => {
        if (data.status) {
          this.supplierList = data.data;
        }
      }
    );
  }

  closeDialog() {
    this.matDialog.closeAll();
  }

  save() {
    if (this.assetForm.valid) {
      const dto = {
        companyId: sessionStorage.getItem(AppConstant.COMPANY_ID),
        assetType: this.assetForm.value.assetType,
        modelName: this.assetForm.value.modelName,
        ram: this.assetForm.value.ram,
        ssd: this.assetForm.value.ssd,
        processor: this.assetForm.value.processor,
        specification: this.assetForm.value.specification,
        modelNo: this.assetForm.value.modelNo,
      };
      this.addAssetService.assetForm(dto).subscribe(
        (data: any) => {
          if (data.status == 200) {
            this.closeDialog();
            this.getSupplierList();
            this.toasterService.clear();
            this.toasterService.success(data.message);
          }
          else {
            this.toasterService.clear()
            this.toasterService.error(data.message);
          }
        }
      );
    }
  }

  validateNumberAndSpaceAndHyphenAllowed(event: any) {
    return ValidationUtil.characterNumberAndSpaceAndHyphenAllowed(event);
  }

}
