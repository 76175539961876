import { ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Subject, debounceTime, delay, filter, map, takeUntil, tap } from 'rxjs';
import { AppConstant } from 'src/app/constants/app.constants';
import { CreateApproval } from 'src/app/model/create-approval/create-approval.model';
import { ApprovalService } from 'src/app/services/approval/approval.service';

@Component({
  selector: 'app-add-approver',
  templateUrl: './add-approver.component.html',
  styleUrls: ['./add-approver.component.scss']
})
export class AddApproverComponent implements OnInit {
  showAddApproverBtn: boolean = false;
  selectedApprovalType!: any;
  oldSelectedApprovalType!: any;
  payrunInProgress: boolean = false;
  departmentColDef: any;
  payrunRowData: any = [];
  payrunColumnDefs: any = [
    { field: "employeeId", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { field: "employeeName", filter: true, floatingFilter: true, sortable: true, tooltipField: "Name", flex: 1 },
    { field: "priority", filter: true, floatingFilter: true, sortable: true, flex: 1 },
    { field: "Action", cellRenderer: this.actionCellRenderer.bind(this) },
  ];
  showAddPopup: boolean = false;
  addApproverFlag: boolean = true;
  hierarchicalApproval: boolean = false;
  approverForm: FormGroup;
  search: boolean = false;
  searching: boolean = false;
  personSearchFilter: FormControl<any> = new FormControl<any>('');
  public filteredServerSide: ReplaySubject<Employees[]> = new ReplaySubject<Employees[]>(1);
  _onDestroy = new Subject<string>();
  employeeList: Employees[] = [];
  filterEmployeeList: Employees[] = [];
  priorities: number[] = [1, 2, 3, 4, 5,];

  constructor(private renderer: Renderer2, private fb: FormBuilder, private approvalService: ApprovalService, private toaster: ToastrService, private cdr: ChangeDetectorRef,) {
    this.approverForm = this.fb.group({
      approverName: ['', Validators.required],
      priority: ['', Validators.required,]
    });
  }

  ngOnInit(): void {
    this.approvalService.getApprovalTypeAndPayrunStatusFlags(sessionStorage.getItem('companyId')).subscribe((res: any) => {
      if (res.status == 200 && res.data) {
        this.selectedApprovalType = res.data.approvalType;
        if (this.selectedApprovalType != AppConstant.DIRECT_APPROVAL) {
          this.showAddApproverBtn = true;
        }
        if (res.data.isPayRunInProgress == null) {
          this.payrunInProgress = false;
        } else {
          this.payrunInProgress = true;
        }

        this.initEmployeeAndApproverList();
      }
    });
  }

  initEmployeeAndApproverList() {
    if (!this.payrunInProgress) {
      this.getEmployeeList();
      const companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);

      if (this.selectedApprovalType != AppConstant.DIRECT_APPROVAL) {
        this.getApproverList();
      }

      //employee search
      this.personSearchFilter.valueChanges
        .pipe(
          filter(search => !!search),
          tap(() => this.searching = true),
          takeUntil(this._onDestroy),
          debounceTime(500),
          map(search => {
            this.search = search;
            if (!this.employeeList) {
              return [];
            }
            return this.employeeList.filter(employee => employee.employeeName.toLowerCase().indexOf(search) > -1);
          }),
          delay(500),
          takeUntil(this._onDestroy)
        )
        .subscribe(filteredEmployees => {
          if (filteredEmployees.length != 0) {
            this.searching = false;
            this.filteredServerSide.next(filteredEmployees);
          } else {
            this.approvalService.searchEmployee(companyId, this.search).subscribe((res: any) => {
              if (res.status == 200 && res.data && res.data.length > 0) {
                this.searching = false;
                this.filteredServerSide.next(filteredEmployees);
              } else {
                this.searching = false;
              }
            })
          }
        },
          error => {
            this.searching = false;
          });
    }
  }

  getEmployeeList() {
    const companyId = sessionStorage.getItem(AppConstant.COMPANY_ID);
    const employeeId = sessionStorage.getItem(AppConstant.EMPLOYEE_ID);
    this.approvalService.getEmployees(companyId, employeeId).subscribe((res: any) => {
      if (res.status == 200 && res.data) {
        this.employeeList = res.data;
        this.filterEmployeeList = res.data;
      }
    });
  }

  getApproverList() {
    let approvalType = this.selectedApprovalType;
    if (approvalType != AppConstant.DIRECT_APPROVAL) {
      this.approvalService.getPayrunApproveDetails(sessionStorage.getItem(AppConstant.COMPANY_ID), approvalType).subscribe((res: any) => {
        if (res && res.data && Array.isArray(res.data)) {
          this.payrunRowData = res.data.map((item: any) => {
            let empId = item.employeeId;
            empId = AppConstant.EMP00.concat(empId);
            return {
              ...item,
              employeeId: empId,
            };
          });
        } else {
          this.payrunRowData = [];
        }

      });
    }
  }

  onApprovalTypeChange() {
    if (this.selectedApprovalType == AppConstant.DIRECT_APPROVAL) {
      if (confirm(AppConstant.DIRECT_APPROVAL_CONFIRMATION_MASSAGE)) {
        this.approvalService.changeToDirectApproval(sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe((res: any) => {
          if (res.status == 200 && res.message) {
            this.showAddApproverBtn = false;
            this.toaster.clear();
            this.toaster.success(res.message);
          }
        });
      } else {
        this.selectedApprovalType = this.oldSelectedApprovalType;
      }
    } else {
      this.showAddApproverBtn = true;
      this.getApproverList();
    }

    this.oldSelectedApprovalType = this.selectedApprovalType;

  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  actionCellRenderer(params: any) {
    const cell = document.createElement('div');

    const createButton = (icon: string, colorClass: string, clickHandler: () => void) => {
      const button = this.renderer.createElement('button');
      this.renderer.addClass(button, 'mat-icon-button');
      this.renderer.addClass(button, 'ag-grid-custom-button');
      this.renderer.addClass(button, colorClass);
      this.renderer.listen(button, 'click', clickHandler);
      const iconElement = this.renderer.createElement('mat-icon');
      this.renderer.addClass(iconElement, 'material-icons');
      this.renderer.setAttribute(iconElement, 'role', 'img');
      this.renderer.appendChild(iconElement, this.renderer.createText(icon));
      this.renderer.appendChild(button, iconElement);
      this.renderer.appendChild(cell, button);
    };
    createButton('delete', 'custom-red', () => this.deleteApprover(params.data));
    return cell;
  }

  deleteApprover(data: any) {
    if (confirm(AppConstant.DELETE_CONFIRMATION_MASSAGE)) {
      let empId = data.employeeId;
      empId = empId.replace('EMP', '');

      this.approvalService.deleteApprover(sessionStorage.getItem(AppConstant.COMPANY_ID), empId).subscribe(
        (data: any) => {
          if (data.status === 404) {
            this.toaster.clear();
            return this.toaster.error(data.message);
          }
          const index = this.payrunRowData.findIndex((item: any) => item.id === data.id);
          if (index !== -1) {
            this.payrunRowData.splice(index, 1);
            this.cdr.detectChanges();
          }
          this.getApproverList();
          return this.toaster.success(data.message);
        }
      );
    }
    this.getApproverList();
  }

  toggleAddPopup(): void {
    this.approverForm.reset();
    if (this.selectedApprovalType == AppConstant.HIERARCHICAL_APPROVAL) {
      this.hierarchicalApproval = true;
    } else {
      this.hierarchicalApproval = false;
    }
    this.showAddPopup = !this.showAddPopup;
  }

  addApprover() {
    if (this.selectedApprovalType == AppConstant.STANDARD_APPROVAL) {
      let selectedName = this.approverForm.get('approverName')?.value;
      selectedName = selectedName.replace('EMP', '');

      let createPayrunApprovalRequest: CreateApproval = {
        companyId: sessionStorage.getItem('companyId'),
        employeeId: selectedName,
        payRunApprovalHierarchy: false,
        priority: 1
      }
      this.addPayRunApproval(createPayrunApprovalRequest);
    } else if (this.selectedApprovalType == AppConstant.HIERARCHICAL_APPROVAL) {
      let selectedName = this.approverForm.get('approverName')?.value;
      selectedName = selectedName.replace('EMP', '');

      let createPayrunApprovalRequest: CreateApproval = {
        companyId: sessionStorage.getItem('companyId'),
        employeeId: selectedName,
        payRunApprovalHierarchy: true,
        priority: this.approverForm.get(AppConstant.PRIORITY)?.value
      }
      this.addPayRunApproval(createPayrunApprovalRequest);
    }
  }

  addPayRunApproval(createPayrunApprovalRequest: CreateApproval) {
    this.approvalService.createPayrunApproval(createPayrunApprovalRequest).subscribe((res: any) => {
      if (res.status == 200 && res.message != null) {
        this.toaster.clear();
        this.toaster.success(res.message);
      } else {
        this.toaster.clear();
        this.toaster.error(res.message);
      }
      this.showAddPopup = false;
      this.getApproverList();
    });
  }
}

export interface Employees {
  departmentName: string;
  designationName: string;
  employeeId: number;
  employeeName: string;
}