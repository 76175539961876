<app-navbar></app-navbar>
<div class="container">
    <div class="card-container">
        <mat-card class="mat-card">
            <mat-card-content>
                <h4 class="title">{{'select-paymnet-method'| translate}}</h4>
                <mat-divider></mat-divider>
                <div class="icon-wrapper">
                    <mat-radio-group [(ngModel)]="selectedPaymentMethod" required>
                        <mat-radio-button value="upi">{{'upi_payment' | translate}}</mat-radio-button>
                    </mat-radio-group>
                    <mat-icon class="payment-icon">payment</mat-icon>
                </div>
                <div class="icon-wrapper">
                    <mat-radio-group [(ngModel)]="selectedPaymentMethod" required>
                        <mat-radio-button value="email">{{'email_link' | translate}}</mat-radio-button>
                    </mat-radio-group>
                    <mat-icon class="email-icon">email</mat-icon>
                </div>
                <div class="button-wrapper">
                    <button class="use-button" (click)="usePaymentMethod()">{{'choose_payment_method' |
                        translate}}</button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>