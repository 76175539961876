export class FormatUtil {
    public static getDaysInMonth = (year: number, month: number) => new Date(year, month, 0).getDate()

    public static sixtyYearsOldDate(): Date {
        return new Date(new Date().getTime() - ((365 * 60) * 24 * 60 * 60 * 1000));
    }

    //18 years old employee
    public static ageOfMaturityDate(): Date {
        return new Date(new Date().getTime() - ((365 * 18) * 24 * 60 * 60 * 1000));
    }

    public static futureSixMonthsDate(): Date {
        const date = new Date()
        date.setDate(1)
        date.setMonth(date.getMonth() + 6)
        date.setDate(this.getDaysInMonth(date.getFullYear(), date.getMonth() + 1));

        return date;
    }

    public static dojMinLenghtCompanyRegistrationdate(): Date {
        let companyRegistrationdate = sessionStorage.getItem("companyRegistrationDate");
        return new Date(companyRegistrationdate || new Date());
    }

    public static adjustDateForTimeOffset(dateToAdjust: Date) {
        var offsetMs = dateToAdjust.getTimezoneOffset() * 60000;
        return new Date(dateToAdjust.getTime() - offsetMs);
    }

    public static base64ToArrayBuffer(base64: string) {
        const binaryString = window.atob(base64);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes;
    }

    public static convertBase64ToDataUrl(base64Image: string): string {
        return `data:image/png;base64,${base64Image}`;
    }

    public static formatDateForDisplay(dateString: string): string {
        if (!dateString) {
            return '';
        }
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return '';
        }
        const day = ('0' + date.getDate()).slice(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    public static dataURItoBlob(dataURI: string): Blob {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let index = 0; index < byteString.length; index++) {
            uint8Array[index] = byteString.charCodeAt(index);
        }
        return new Blob([arrayBuffer], { type: mimeString });
    }

    public static capitalizeFirstLetter(value: string): string {
        if (!value) return value;
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

}