<mat-card class="demo-card">

    <div class="demo-form">
        <form [formGroup]="BookDemoForm" (ngSubmit)="onSubmit()">
            <h3 class="center-text">{{'book_demo' | translate}}</h3>
            <mat-form-field class="demo-text" appearance="outline">
                <mat-label>{{'companyName'|translate}}</mat-label>
                <input matInput formControlName="companyName" (keypress)="validateCharacterNumberAndSpace($event)">
                <mat-error *ngIf="BookDemoForm.get('companyName').hasError('required')">
                    {{ 'company_name_is_required' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="demo-text" appearance="outline">
                <mat-label>{{'email'|translate}}</mat-label>
                <input matInput  formControlName="email">
                <mat-error *ngIf="BookDemoForm.get('email').hasError('required')">
                    {{ 'landing_page_email_required' | translate }}
                </mat-error>
                <mat-error
                    *ngIf="BookDemoForm.controls['email'].hasError('email') && !BookDemoForm.controls['email'].hasError('required')">
                    {{ 'landing_page_email_required' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="demo-text" appearance="outline">
                <mat-label>{{'mobile_number'|translate}}</mat-label>
                <input matInput type="text" (keypress)="validateNumber($event)"
                   formControlName="mobileNumber"
                    minlength="10" maxlength="10">
                <mat-error *ngIf="BookDemoForm.get('mobileNumber').hasError('required')">
                    {{ 'mobile_number_required' | translate }}
                </mat-error>
                <mat-error *ngIf="BookDemoForm.controls['mobileNumber'].hasError('invalidMobileNumber')">
                    {{ 'invalid_mobile_number' | translate }}
                </mat-error>
            </mat-form-field>
            <br>
            <button class="demo-btn">{{'request_demo' | translate}}</button>
        </form>
        <app-loader></app-loader>
    </div>
</mat-card>