<div class="setting__main_container">
  <span matTooltip="{{'back_to_dashboard' | translate}}" (click)="goToDashboard()">
    <mat-icon svgIcon="arrow-back-double" class="rewind"></mat-icon>
  </span>
  <div class="setting__heading_container">
    <h3>{{ 'employees_list' | translate }}</h3>
  </div>
</div>

<div class="container">
  <form [formGroup]="EmpTable">
    <ag-grid-angular class="ag-theme-alpine" style="height:400px" [rowData]="rowData" [defaultColDef]="defaultColDef"
      [columnDefs]="columnDefs" [floatingFiltersHeight]="40" [animateRows]="true" [pagination]="true"
      [paginationPageSize]="10" [enableAdvancedFilter]="true">
    </ag-grid-angular>
  </form>
</div>