import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { AttendanceService } from 'src/app/services/attendance/attendance.service';
import { EmployeeDetailsService } from 'src/app/services/employee-details/employee-details.service';

@Component({
  selector: 'app-employee-attendance',
  templateUrl: './employee-attendance.component.html',
  styleUrls: ['./employee-attendance.component.scss']
})

export class EmployeeAttendanceComponent implements OnInit {
  employeeAttendanceList: any[] = [];
  attendanceRecords: any[] = [];
  isEmployeeSelected: boolean;
  selectedEmployee: any = null;
  employeeId: any;

  constructor(private readonly attendanceService: AttendanceService, private readonly employeeDetailsService : EmployeeDetailsService, private readonly toasterService: ToastrService) {
    this.isEmployeeSelected = false;
  }

  ngOnInit(): void {
    this.getEmployeeList();
  }

  getEmployeeList() {
    if (sessionStorage.getItem(AppConstant.ROLE_FOR_SESSION) === 'admin') {
      this.employeeDetailsService.getEmployeeList("activeEmployee", sessionStorage.getItem(AppConstant.COMPANY_ID)).subscribe(
        (data: any) => {
          if (data.status === 200) {
            this.employeeAttendanceList = data.data.map((item: any) => ({
              employeeId: `EMP${String(item.id).padStart(3, '0')}`,
              employeeName: item.firstName + " " + item.lastName
            }));
          }
        })
    } else {
      this.attendanceService.getEmployeeList(sessionStorage.getItem(AppConstant.COMPANY_ID), sessionStorage.getItem(AppConstant.EMPLOYEE_ID)).subscribe(
        (employeeListResponse: any) => {
          if (employeeListResponse.status === 200) {
            console.log(employeeListResponse.data);
            this.employeeAttendanceList = employeeListResponse.data.map((item: any) => ({
              employeeId: `EMP${String(item.id).padStart(3, '0')}`,
              employeeName: item.firstName + " " + item.lastName
            }));
          }
        })
    }
  }

  fetchAttendance(employeeId: any) {
    const employeeIdNumber = /^\d+$/.test(employeeId) ? employeeId : employeeId.replace(/\D/g, '');
    this.isEmployeeSelected = true;
    this.attendanceService.getAllPunchingByEmployeeId(employeeIdNumber).subscribe(
      (allPunchingResponse: any) => {
        if (allPunchingResponse.status === 200 && allPunchingResponse.data) {
          this.attendanceRecords = allPunchingResponse.data
            .filter((record: any) => ["Submitted", "Approved", "Rejected"].includes(record.status)) // Filter valid statuses
            .map((record: any) => ({
              date: new Date(record.punchInTime).toLocaleDateString(),
              timeIn: new Date(record.punchInTime).toLocaleTimeString(),
              timeOut: new Date(record.PunchOutTime).toLocaleTimeString(),
              totalHours: record.totalWorkingHours,
              projectCode: record.projectCode || 'N/A',
              shift: record.shift || 'General',
              locationIn: record.punchInLiveLocation,
              locationOut: record.punchOutLiveLocation,
              formattedTime: record.time,
              status: this.mapStatus(record.status),
              employeeId: record.employeeId,
              id: record.id
            }));
        }
      }
    );
  }

  mapStatus(apiStatus: string): string {
    const statusMapping: { [key: string]: string } = {
      "Submitted": "Submitted",
      "Approved": "approved",
      "Rejected": "rejected"
    };
    return statusMapping[apiStatus] || "NA";
  }


  goBack() {
    this.isEmployeeSelected = false;
    this.selectedEmployee = null;
  }

  approveRejectAttendance(employee: any, type: any) {
    const dto = {
      punchId: employee.id,
      type: type
    }
    this.attendanceService.approveRejectAttendance(dto).subscribe(
      (approveRejectResponse: any) => {
        if (approveRejectResponse.status === 200) {
          this.fetchAttendance(employee.employeeId);
          this.toasterService.clear();
          this.toasterService.success(approveRejectResponse.message);
        } else {
          this.toasterService.clear();
          this.toasterService.success(approveRejectResponse.message);
        }
      }
    )
  }

}
