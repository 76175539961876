<div class="container">
        <h4><strong>{{'upcoming_events' | translate}}</strong></h4>
        <ng-container *ngIf="eventList && eventList.length > 0; else noEvents">
                <marquee direction="up" scrolldelay="250" behavior="loop">
                        <div *ngFor="let event of eventList" class="event_messgae_container">
                                <div>{{event.description}}: {{event.eventStartDate | date:'dd-M-yyyy'}} -
                                        {{event.eventEndDate | date:'dd-M-yyyy'}}</div>
                        </div>
                </marquee>
        </ng-container>
        <ng-template #noEvents>
                <p class="template">{{'no-event-available' | translate}}</p>
        </ng-template>
</div>