import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppConstant } from 'src/app/constants/app.constants';
import { ProjectManagementService } from 'src/app/services/project-management/project-management.service';
import { TaskManagementService } from 'src/app/services/task-management/task-management.service';

@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrls: ['./view-report.component.scss']
})
export class ViewReportComponent implements OnInit {
  selectedBoardId: any;
  boardList: any;
  sprints: any[] = [];
  selectedSprintId: Number[] = [];
  phases: any[] = [];
  reportsData: any[] = [];
  allSelected: boolean = false;
  @ViewChild('select') select: MatSelect | any;
  showAllSprintDetails: any;
  showAllReportData: any;
  reportType!: string;

  reportscolumnDefs = [
    { field: "dashboardName", headerName: "Dashboard Name", filter: true, floatingFilter: true, unSortIcon: true, resizable: true, flex: 1 },
    { field: "projectName", headerName: "Project Name", filter: true, floatingFilter: true, unSortIcon: true, resizable: true, flex: 1 },
    { field: "sprintName", headerName: "Sprint Name", filter: true, floatingFilter: true, unSortIcon: true, resizable: true, flex: 1 },
    { field: "taskNo", headerName: "Task No", filter: true, floatingFilter: true, unSortIcon: true, resizable: true, flex: 1 },
    { field: "summary", headerName: "Summary", filter: true, floatingFilter: true, unSortIcon: true, resizable: true, flex: 1 },
    { field: "status", headerName: "Status", filter: true, floatingFilter: true, unSortIcon: true, resizable: true, flex: 1, valueGetter: (params: any) => params.data.status.replace(/([A-Z])/g, ' $1').replace(/^./, (str: any) => str.toUpperCase()), },
    { field: "assignee", headerName: "Assignee", filter: true, floatingFilter: true, unSortIcon: true, resizable: true, flex: 1 },
    { field: "storyPoints", headerName: "Story Points", filter: true, floatingFilter: true, unSortIcon: true, flex: 1 },
  ];


  constructor(private toasterService: ToastrService, private taskService: TaskManagementService, private route: ActivatedRoute, private router: Router, private projectService: ProjectManagementService) { }

  ngOnInit(): void {
    this.getboardList();
  }

  onGridReady(params: any) {
    params.api.sizeColumnsToFit();
  }

  getCamelCase(value: string): string {
    return value.replace(/([A-Z])/g, ' $1').replace(/^./, (str: any) => str.toUpperCase());
  }

  viewAllDashboards() {
    this.router.navigate([AppConstant.TASK_MANAGEMENT_SIDENAV + AppConstant.SEPERATOR + AppConstant.PROJECT_DASHBOARD]);
  }

  getDashboard(board: any) {
    this.selectedBoardId = board.dashboardId;
    this.getBacklogData(this.selectedBoardId);
  }

  getboardList() {
    this.projectService.getAllProjectDashboard().subscribe(
      (dashboardList: any) => {
        if (dashboardList.status === 200) {
          this.boardList = dashboardList.data;
          if (this.boardList && this.boardList.length > 0) {
            this.selectedBoardId = this.boardList[0].dashboardId;
            this.getBacklogData(this.selectedBoardId);
          }
        }
      })
  }

  getBacklogData(dashboardId: any) {
    this.taskService.getBacklogData(dashboardId).subscribe(
      (backlogData: any) => {
        if (backlogData.status === 200) {
          this.sprints = backlogData.data.sprintDetails;
          this.selectedSprintId.push(this.sprints[0].sprintId);
          this.getAllSprintDetails();
        }
      });
  }

  getAllDashboardList() {
    this.projectService.getAllProjectDashboard().subscribe((reportData: any) => {
      this.reportsData = reportData.data;
    });
  }

  onDashboardChange(event: any) {
    this.selectedBoardId = event.value;
    this.getBacklogData(this.selectedBoardId);
    if (this.selectedSprintId.length > 0) {
      this.getAllSprintDetails();
    }

  }

  onSprintChange(event: any) {
    this.selectedSprintId = event.value;
    this.selectAllSprints(this.selectedSprintId);
  }

  selectAllSprints(sprintIds: Number[]) {
    if (this.allSelected) {
      this.selectedSprintId = this.sprints.map(sprint => sprint.sprintId);
    } else {
      this.selectedSprintId = sprintIds;
    }
    this.getAllSprintDetails();
  }

  getAllSprintDetails() {
    if (this.selectedBoardId && this.selectedSprintId.length > 0) {
      const showSprintDetails = {
        dashboardId: this.selectedBoardId,
        sprintIds: this.selectedSprintId
      };
      this.taskService.showAllSprintdetails(showSprintDetails).subscribe(
        (sprintDetails: any) => {
          if (sprintDetails.status === 200) {
            this.showAllReportData = sprintDetails.data;
          }
        });
    }
  }

  downloadExcel() {
    this.reportType = "Excel";
    this.exportReport();
  }

  downloadCSV() {
    this.reportType = "CSV"
    this.exportReport();
  }

  exportReport() {
    if (this.selectedBoardId && this.selectedSprintId.length > 0) {
      const showSprintDetails = {
        dashboardId: this.selectedBoardId,
        sprintIds: this.selectedSprintId,
        reportType: this.reportType
      };

      this.taskService.reportDetails(showSprintDetails).subscribe(
        (response: Blob) => {
          const fileExtension = this.reportType === 'Excel' ? 'xlsx' : 'csv';
          const fileName = `report.${fileExtension}`;
          const blob = new Blob([response], { type: response.type });
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        },
      );
    }
  }

}
