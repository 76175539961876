<div class="attendance-configuration">
    <h3 class="add-section-category">{{ 'add_section' | translate }}</h3>
    <form [formGroup]="section">
        <div class="category-details">
            <mat-form-field appearance="outline" class="category-name">
                <mat-label>{{ 'section_name' | translate }}</mat-label>
                <input matInput formControlName="sectionName" name="sectionName" required
                    (keypress)="validateCharacterNumberAndSpace($event)" />
                <mat-error *ngIf="section.controls['sectionName'].hasError('required')">{{
                    'section_name_is_required' | translate }}</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="category-name">
                <mat-label>{{ 'section_limit' | translate }}</mat-label>
                <input matInput formControlName="sectionLimit" name="sectionLimit"
                    (keypress)="validateCharacterNumberAndSpace($event)" />
                <mat-error *ngIf="section.controls['sectionLimit'].hasError('required')">{{
                    'section_limit_is_required' | translate }}</mat-error>
            </mat-form-field>

        </div>

        <mat-form-field class="log-description" appearance="outline">
            <mat-label>{{ 'section_description' | translate }}</mat-label>
            <input matInput type="text" maxlength="150" formControlName="sectionDescription" required
                (keypress)="validateCharacterNumberAndSpace($event)" />
            <mat-error *ngIf="section.get('sectionDescription')?.hasError('required')">
                {{ 'section_description_is_required' | translate }}</mat-error>
        </mat-form-field>
    </form>
    <div class="button-container">
        <button class="cancel-btn" (click)="onCancelClick()" type="reset">{{ 'cancel' | translate }}</button>
        <button (click)="addSection()">{{ 'add_section' | translate }}</button>
    </div>
</div>